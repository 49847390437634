import React, { useState } from 'react';
import UploaderSection from '../UploaderSection';
import { CallbackFunction } from '../exportAndDownload';
import { useAllowNavigate } from '../useAllowNavigate';
import { TertiaryButton } from 'system/base/Button';
import Icon, { IconStyle } from 'system/base/Icon';
import { Spinner } from 'system/elements';

type downloadState = 'not-started' | 'in-progress' | 'failure' | 'successful';

type ReferenceManagerFileExporterProps = {
  // Sets the window to warn users before navigating
  downloadStudies: (callback: CallbackFunction) => void;
  setErrorMessage: (message: string) => void;
};

const buttonContent = (inProgress: boolean) => {
  if (inProgress) {
    return <Spinner data-testid={'spinner'} style={{ margin: 'auto' }} />;
  }

  return (
    <>
      <Icon name="cloud-download" iconStyle={IconStyle.Solid} />
      Download studies
    </>
  );
};

const ReferenceManagerFileExporter = (
  props: ReferenceManagerFileExporterProps
) => {
  const { downloadStudies, setErrorMessage } = props;

  const { setAllowNavigate } = useAllowNavigate(true);
  const [
    currentDownloadState,
    setCurrentDownloadState,
  ] = useState<downloadState>('not-started');

  const inProgress = currentDownloadState === 'in-progress';

  return (
    <UploaderSection error={currentDownloadState === 'failure'}>
      Step 1: Download studies missing full text
      <TertiaryButton
        onClick={() => {
          setAllowNavigate(false);
          setCurrentDownloadState('in-progress');
          downloadStudies((result) => {
            setAllowNavigate(true);
            if (result.status === 'success') {
              setCurrentDownloadState('not-started');
            }
            if (result.status === 'failure') {
              setCurrentDownloadState('failure');
              setErrorMessage(result.message);
            }
          });
        }}
        disabled={inProgress}
      >
        {buttonContent(inProgress)}
      </TertiaryButton>
    </UploaderSection>
  );
};

export default ReferenceManagerFileExporter;
