import React, { useRef, useState } from 'react';
import { TertiaryButton as Button } from '../../../system/base/Button';
import styles from './DocumentUrlUploader.module.css';

interface Props {
  onUpload: (url: string) => void;
}

export default function DocumentUrlUploader({ onUpload }: Props) {
  const documentUrlRef = useRef<HTMLInputElement>(null);
  const [error, setError] = useState<string>('');

  return (
    <div className={error ? styles['DocumentUrlUploader--error'] : ''}>
      <label
        className={
          error ? styles['DocumentUrlUploader__inputGroup--error'] : ''
        }
      >
        <div className={styles.DocumentUrlUploader__labelText}>
          Link to full text
        </div>
        <div className={styles.DocumentUrlUploader__inputGroup}>
          <input
            type="text"
            ref={documentUrlRef}
            name="document_url"
            data-testid="DocumentUrlUploader-input"
          />
          <Button
            onClick={() => {
              let document_url = documentUrlRef.current?.value?.trim();
              if (!document_url) {
                return setError('Please enter a link');
              }
              if (!document_url.startsWith('http')) {
                document_url = 'https://' + document_url;
              }

              setError('');
              onUpload(document_url);
            }}
            data-testid="DocumentUrlUploader-button"
          >
            Add link
          </Button>
        </div>
      </label>
      <span className={styles.DocumentUrlUploader__supportText}>
        {error
          ? error
          : 'Only add links if you are unable to upload PDFs. Only one link can be added.'}
      </span>
    </div>
  );
}
