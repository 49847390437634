import { BaseBlock, TemplateBlock } from '../BaseBlock';
import { TableBlock } from './TableBlock';

export type TableHeaderType = 'row' | 'column';
export type TableHeaderTypePlural = 'rows' | 'columns';

export type TableHeaderLabel = string;
export interface TableHeader extends BaseBlock {
  type: 'TableHeader';
  id: string;
  label: TableHeaderLabel;
}

export const isTableBlock = (
  templateBlock: TemplateBlock
): templateBlock is TableBlock => templateBlock.type === 'CustomTable';
