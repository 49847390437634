import React, { useEffect, useState } from 'react';
import MultiSelect from 'react-select';
import { gql, useQuery } from '@apollo/client';
import { StudyListItem } from './StudyListItem/StudyListItem';
import { Study } from './StudyTypes';
import styles from './StudyList.module.css';
import MergeStudiesModal from './MergeStudiesModal';
import Select, { Option } from 'system/base/Select';
import {
  PrimaryButton,
  NakedButton,
  SecondaryButton,
} from 'system/base/Button';
import {
  ExtractionStudiesStatus,
  ExtractionStudySort,
  ReviewersQueryQuery,
  User,
} from 'types/graphql';
import SelectAllStudies from 'pages/StudyList/SelectAllStudies';

export const SELECTABLE_REVIEWERS_QUERY = gql`
  query SelectableReviewers($id: ID!) {
    review: node(id: $id) {
      ... on Review {
        memberships {
          nodes {
            reviewer {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export interface StudyListProps {
  status?: ExtractionStudiesStatus | null;
  studies: Array<Study>;
  availableTags: Array<string>;
  filteringByTags: Array<string>;
  onFilterTagChange: (tag: Array<string>) => void;
  hasNextPage: boolean;
  onNextPage: () => void;
  selectedStudyIDs: Array<Study['id']>;
  onUpdateSelectedStudyIDs: (studyIDs: Array<Study['id']>) => void;
  refetchStudyList: () => void;
  onMoveBackToFullText: (studyId: Study['id']) => void;
  currentUserId: string;
  startDiscussionPath?: string;
  changeSort: (sortOption: ExtractionStudySort) => void;
  sortBy: ExtractionStudySort;
  reviewId: string;
  updateStudyInStudies: (study: Study) => void;
}

interface NoStudiesProps {
  status?: ExtractionStudiesStatus | null;
}

const NoStudies = ({ status }: NoStudiesProps) => {
  let message: string;
  switch (status) {
    case ExtractionStudiesStatus.NotStarted:
      message = 'There are no studies that are currently available to start.';
      break;
    case ExtractionStudiesStatus.InProgress:
      message =
        'There are no studies that are currently undergoing extraction.';
      break;
    case ExtractionStudiesStatus.ConsensusRequired:
      message = 'There are no studies requiring consensus.';
      break;
    case ExtractionStudiesStatus.Complete:
      message = 'There are no studies that have completed extraction.';
      break;
    default:
      message = 'There are no studies to show.';
  }

  return (
    <section aria-label="Studies" className={styles.container}>
      <div className={styles.empty}>{message}</div>
    </section>
  );
};

export function StudyList({
  status,
  studies,
  availableTags,
  filteringByTags,
  onFilterTagChange,
  selectedStudyIDs,
  onUpdateSelectedStudyIDs,
  hasNextPage,
  onNextPage,
  onMoveBackToFullText,
  refetchStudyList,
  currentUserId,
  startDiscussionPath,
  changeSort,
  sortBy,
  reviewId,
  updateStudyInStudies,
}: StudyListProps): JSX.Element {
  const [showMergeStudiesDialog, setShowMergeStudiesDialog] = useState(false);
  const [selectableReviewers, setSelectableReviewers] = useState<Array<User>>(
    []
  );

  const { loading, error, data } = useQuery<ReviewersQueryQuery>(
    SELECTABLE_REVIEWERS_QUERY,
    {
      variables: {
        id: reviewId,
      },
    }
  );

  /*
   Control whether merge modal button is disabled:
   (a) if merge dialog is open (triggered when merge modal button is clicked),
   and the merge has not yet completed (state gets reset on merge success/failure, or modal dismissed), OR
   (b) if less than two studies are selected under the Study List UI (making a merge action impossible)
  */
  const [isModalButtonDisabled, setIsModalButtonDisabled] = useState(
    showMergeStudiesDialog || selectedStudyIDs.length < 2
  );

  useEffect(() => {
    setIsModalButtonDisabled(
      showMergeStudiesDialog || selectedStudyIDs.length < 2
    );
  }, [showMergeStudiesDialog, selectedStudyIDs]);

  useEffect(() => {
    if (data?.review?.memberships != undefined) {
      const reviewers: Array<User> = data?.review?.memberships?.nodes
        ?.map((node) => node?.reviewer)
        .filter((reviewer) => reviewer != undefined) as User[];

      setSelectableReviewers(reviewers);
    }
  }, [data]);

  const mergeStudyModal = (
    <MergeStudiesModal
      isOpen={showMergeStudiesDialog}
      onCancel={() => {
        setShowMergeStudiesDialog(false);
      }}
      onMergeSuccess={() => {
        setShowMergeStudiesDialog(false);
        refetchStudyList();
      }}
      onMergeFailure={() => {
        setShowMergeStudiesDialog(false);
      }}
      studies={studies.filter((study) => selectedStudyIDs.includes(study.id))}
    />
  );

  let sortByValue = 'Author';
  switch (sortBy) {
    case 'author':
      sortByValue = 'Author';
      break;
    case 'title':
      sortByValue = 'Title';
      break;
    case 'most_recent':
      sortByValue = 'MostRecent';
      break;
  }

  const tagToOption = (tagName: string) => ({
    label: tagName,
    value: tagName,
  });

  const startDiscussion = () => {
    window.location.assign(
      startDiscussionPath + '?study_gql_ids=' + selectedStudyIDs.join(',')
    );
  };

  return (
    <>
      {mergeStudyModal}
      <div data-testid="study-list-actions" className={styles.userActions}>
        <div className={styles.userActionsLeft}>
          <div className={styles.selectAllLabel}>
            <SelectAllStudies
              isSelected={selectedStudyIDs.length === studies.length}
              handleStudySelection={(event) => {
                if (event.target.checked) {
                  onUpdateSelectedStudyIDs(studies.map((study) => study.id));
                } else {
                  onUpdateSelectedStudyIDs([]);
                }
              }}
              isDataExtraction2={true}
            />
          </div>

          <PrimaryButton
            ml={3}
            disabled={isModalButtonDisabled}
            onClick={() => {
              setShowMergeStudiesDialog(true);
            }}
          >
            Merge as study
          </PrimaryButton>
          {startDiscussionPath && (
            <SecondaryButton
              ml={3}
              disabled={selectedStudyIDs.length < 1}
              onClick={() => {
                startDiscussion();
              }}
            >
              Start discussion
            </SecondaryButton>
          )}
        </div>
        <div className={styles.userActionsRight}>
          <div className={styles.filterByTagLabel}>Filter by tags</div>
          <div className={styles.filterByTagContainer}>
            <MultiSelect
              placeholder=""
              options={availableTags.map(tagToOption)}
              defaultValue={filteringByTags.map(tagToOption)}
              openMenuOnFocus={true}
              isSearchable={true}
              isMulti={true}
              styles={{
                container: (style) => ({
                  ...style,
                  marginRight: '10px',
                  width: '300px',
                }),
                control: (style) => ({
                  ...style,
                  overflow: 'hidden',
                  height: '32px',
                  minHeight: '32px',
                  margin: 0,
                  padding: 0,
                  alignItems: 'center',
                }),
                indicatorsContainer: (style) => ({
                  ...style,
                  padding: '0px',
                  alignItems: 'center',
                  height: 'inherit',
                }),
                valueContainer: (style) => ({
                  ...style,
                  flexWrap: 'nowrap',
                  padding: '0px',
                  alignItems: 'center',
                  height: 'inherit',
                }),
                placeholder: () => ({
                  padding: '4px 8px 4px 0',
                }),
                input: (style) => ({
                  ...style,
                  margin: 0,
                  padding: 0,
                  paddingLeft: '8px',
                }),
              }}
              onChange={(selectedOptions: any) => {
                if (selectedOptions) {
                  onFilterTagChange(
                    selectedOptions.map((option: any) => option.value)
                  );
                } else {
                  onFilterTagChange([]);
                }
              }}
            />
          </div>
          <div className={styles.sortByContainer}>
            <Select
              className={styles.sortBySelector}
              colors="primary"
              value={sortByValue}
              onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                const selectedValue = event.target.value;
                const selectedOption =
                  ExtractionStudySort[
                    selectedValue as keyof typeof ExtractionStudySort
                  ];

                changeSort(selectedOption);
              }}
            >
              <Option value={'Author'}>Author</Option>
              <Option value={'Title'}>Title</Option>
              <Option value={'MostRecent'}>Most recent</Option>
            </Select>
          </div>
        </div>
      </div>

      {studies.length == 0 ? (
        <NoStudies status={status} />
      ) : (
        <>
          <section
            aria-label="Studies"
            data-testid="studies-list"
            className={styles.container}
          >
            {studies.map((study) => {
              // TODO: why would study be undefined?
              if (study) {
                return (
                  <div key={study.id} className={styles.item}>
                    <StudyListItem
                      onStudyUpdate={refetchStudyList}
                      study={study}
                      availableTags={availableTags}
                      currentUserId={currentUserId}
                      selected={selectedStudyIDs.includes(study.id)}
                      selectableReviewers={selectableReviewers}
                      selectableReviewersLoading={loading}
                      fetchSelectableReviewersError={error}
                      updateStudyInStudies={updateStudyInStudies}
                      onSelect={() => {
                        onUpdateSelectedStudyIDs(
                          [...selectedStudyIDs, study.id].sort()
                        );
                      }}
                      onDeselect={() => {
                        onUpdateSelectedStudyIDs(
                          selectedStudyIDs.filter((id) => id != study.id)
                        );
                      }}
                      onMoveBackToFullText={() => {
                        onMoveBackToFullText(study.id);
                      }}
                    />
                  </div>
                );
              }
            })}
            {hasNextPage ? (
              <div className={styles.loadMore}>
                <NakedButton onClick={onNextPage}>Load more</NakedButton>
              </div>
            ) : null}
          </section>
        </>
      )}
    </>
  );
}
