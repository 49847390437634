import React from 'react';
import styles from '../../PrimaryNavigation.module.scss';

import { Reviewer } from '../../PrimaryNavigation';
import PlanBadge from './components/PlanBadge';
import OrganizationSection from './components/OrganizationSection';
import AdminSection from './components/AdminSection';
import {
  Heading,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  Text,
} from 'components/core';

interface Props {
  reviewer: Reviewer;
  children: React.ReactNode;
}

const ProfileDropdown = ({ reviewer, children }: Props) => {
  const account = reviewer.account;

  return (
    <Menu>
      <MenuButton
        type="brand"
        size="md"
        className={styles.PrimaryNavigation_iconButton}
        activeClass={styles.PrimaryNavigation_active}
        iconOnly
        data-testid="profileDropdownButton"
      >
        {children}
      </MenuButton>
      <MenuList align="right">
        <div>
          <Heading
            type="h5"
            component="h2"
            className={styles.PrimaryNavigation_menu_heading}
          >
            {reviewer.first_name} {reviewer.last_name}
          </Heading>
          <Text size="sm" variant="weak">
            {reviewer.email}
          </Text>
        </div>
        <MenuGroup className={styles.xsHidden}>
          <MenuItem to="/profile">Profile</MenuItem>
          {['Trial', 'Short Trial'].indexOf(account.plan.name) >= 0 ? (
            <MenuItem to="/plans">
              Choose a plan
              <PlanBadge plan={account.plan} />
            </MenuItem>
          ) : (
            <MenuItem to="/account">
              Billing and plan
              <PlanBadge plan={account.plan} />
            </MenuItem>
          )}
        </MenuGroup>
        {reviewer?.organizations?.length ? (
          <OrganizationSection
            organizations={reviewer.organizations}
            className={styles.xsHidden}
          />
        ) : undefined}
        {reviewer.admin ? (
          <AdminSection
            canProxy={!!reviewer.can_proxy}
            className={styles.xsHidden}
          />
        ) : undefined}
        <MenuGroup>
          <MenuItem to="/sign_out" rel="nofollow">
            Sign out
          </MenuItem>
        </MenuGroup>
      </MenuList>
    </Menu>
  );
};

export default ProfileDropdown;
