import React from 'react';
import styles from './DemoAlert.module.scss';
import icon from 'assets/images/DemoAlertIcon.svg';

const DemoAlert = ({ path }: { path: string }) => {
  return (
    <div className={styles.container}>
      <img className={styles.icon} src={icon} alt="funky shapes" />
      Try the{' '}
      <a href={path} target="_blank" className={styles.link} rel="noreferrer">
        Demo Review.
      </a>{' '}
      Have a play and get familiar with Covidence.
    </div>
  );
};

export default DemoAlert;
