import React from 'react';
import { Text, Message, IconList } from 'components/core';

interface props {
  dePublishVersion: string;
  qaPublishVersion: string;
  deMoveBackVersion: string;
  qaMoveBackVersion: string;
  qaPublishBy: string;
  dePublishBy: string;
  currentUser: string;
  review: string;
}

// Generates the key used for local storage to track the template versions.
const acknowledgementKey = (
  templateType: 'de' | 'de_mb' | 'qa' | 'qa_mb',
  props: props
): string => {
  // The key uses both current Review & current Reviewer so that users sharing same browser session
  // track acknowledgements separately. This also means that aliasing as users works as expected
  // for Covidence admins.
  return `${templateType}-template-acknowledged-version[${props.review}][${props.currentUser}]`;
};

const handleIfCurrentUserIsPublisher = (props: props) => {
  // If the current user and the published user are the same then update
  // the acknowledgementKey as we don't need to show them the message.
  if (props.currentUser === props.dePublishBy) {
    localStorage.setItem(
      acknowledgementKey('de', props),
      props.dePublishVersion
    );
    localStorage.setItem(
      acknowledgementKey('de_mb', props),
      props.deMoveBackVersion
    );
  }
  if (props.currentUser === props.qaPublishBy) {
    localStorage.setItem(
      acknowledgementKey('qa', props),
      props.qaPublishVersion
    );
    localStorage.setItem(
      acknowledgementKey('qa_mb', props),
      props.qaMoveBackVersion
    );
  }
};

const getPublishState = (props: props) => {
  const deLastAcknowledged = localStorage.getItem(
    acknowledgementKey('de', props)
  );
  const qaLastAcknowledged = localStorage.getItem(
    acknowledgementKey('qa', props)
  );

  // The template has changed since "last" acknowledged if the version
  // is different to what was recorded and there is a version value for
  // the template.
  const deChanged =
    props.dePublishVersion.length > 0 &&
    deLastAcknowledged !== props.dePublishVersion;
  const qaChanged =
    props.qaPublishVersion.length > 0 &&
    qaLastAcknowledged !== props.qaPublishVersion;
  const showMessage = deChanged || qaChanged;

  return { showMessage, deChanged, qaChanged };
};

const getMoveBackState = (props: props) => {
  const deLastMovedBack = localStorage.getItem(
    acknowledgementKey('de_mb', props)
  );
  const qaLastMoveBack = localStorage.getItem(
    acknowledgementKey('qa_mb', props)
  );

  // Studies were moved back since "last" acknowledged if the version
  // is different to what was recorded and there is a version value for
  // the move back action.
  const deMoveBackChanged =
    props.deMoveBackVersion.length > 0 &&
    deLastMovedBack !== props.deMoveBackVersion;
  const qaMoveBackChanged =
    props.qaMoveBackVersion.length > 0 &&
    qaLastMoveBack !== props.qaMoveBackVersion;
  const showMoveBackMessage = deMoveBackChanged || qaMoveBackChanged;

  return showMoveBackMessage;
};

const acknowledgeMessage = (props: props): void => {
  localStorage.setItem(acknowledgementKey('de', props), props.dePublishVersion);
  localStorage.setItem(acknowledgementKey('qa', props), props.qaPublishVersion);
};

const createMessage = (
  showDe: boolean,
  showQa: boolean,
  showMoveBackMessage: boolean
) => {
  if (showMoveBackMessage) {
    let template = 'data extraction and quality assessment templates were';
    if (!showDe) template = 'quality assessment template was';
    if (!showQa) template = 'data extraction template was';
    return `Some studies were moved back to incomplete when the ${template} published`;
  } else {
    let template = 'Data extraction and quality assessment templates have';
    if (!showDe) template = 'Quality assessment template has';
    if (!showQa) template = 'Data extraction template has';
    return `${template} been published since your last visit`;
  }
};

const StudyListMessage = (props: props) => {
  handleIfCurrentUserIsPublisher(props);

  const { showMessage, deChanged, qaChanged } = getPublishState(props);
  const showMoveBackMessage = getMoveBackState(props);

  return (
    <>
      {showMessage && (
        <Message
          onClose={() => acknowledgeMessage(props)}
          type={showMoveBackMessage ? 'warning' : 'info'}
          icon={
            showMoveBackMessage
              ? IconList.solid.faTriangleExclamation
              : IconList.solid.faFileCircleCheck
          }
        >
          <Text>
            {createMessage(deChanged, qaChanged, showMoveBackMessage)}
          </Text>
        </Message>
      )}
    </>
  );
};

export default StudyListMessage;
