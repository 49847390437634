import React, { useState, useRef } from 'react';
import {
  matchPDFs,
  pollImportJobStatus,
  sendPendoEvent,
  saveReviewReferenceDocument,
  uploadPDFToS3,
} from '../../query';
import { ImportJob } from '../../index';
import UploaderSection from '../UploaderSection';

import DragAndDropUploader from '../DragAndDropUploader';
import ProgressBar from '../ProgressBar';
import styles from './BulkPDFUploader.module.css';
import { TertiaryButton } from 'system/base/Button';
import Icon, { IconStyle } from 'system/base/Icon';

interface Props {
  reviewId: string;
  importJob: ImportJob | null;
  onProcessed?: (importJob: ImportJob) => void;
  text?: string | React.ReactNode;
}

// estimated progress percentage for each upload stage
// deliberately stop short of 100%
const progressByStage = {
  initialProgress: 0.05,
  uploadToS3: 0.9,
};

const BulkPDFUploader = ({
  reviewId,
  importJob,
  onProcessed = () => {
    /*noop*/
  },
  text,
}: Props) => {
  const fileRef = useRef<HTMLInputElement>(null);
  const [progress, setProgress] = useState<number>(0);
  const [error, setError] = useState<string>('');

  const onFileChange = (files: File[], byDragAndDrop: boolean) => {
    if (!importJob || importJob.expected_files.length === 0) return; // shouldn't happen
    setError('');

    const fileArray = Array.from(files).filter(
      (file) => file.type.indexOf('pdf') > 0 // accept application/x-pdf
    );
    const matchedPDFs = matchPDFs(fileArray, importJob.expected_files);
    if (matchedPDFs.length === 0) {
      setError('Please choose a folder containing PDFs still to upload');
      return;
    }
    setProgress(progressByStage.initialProgress);

    let totalUploadBytes = 0;
    let totalProgressBytes = 0;
    let currentUploadedFiles = 0;
    let processedFiles = 0;
    let totalUploaded = importJob.files_uploaded;

    const attachableIds: string[] = [];

    const loadingPromise = new Promise((resolve) => {
      matchedPDFs.forEach((file) => {
        totalUploadBytes += file.file.size;
        let prevProgress = 0;

        uploadPDFToS3(file, reviewId, importJob.id, {
          onProgress: (progress) => {
            totalProgressBytes = totalProgressBytes + (progress - prevProgress);
            prevProgress = progress;
            setProgress(
              progressByStage.initialProgress +
                (totalProgressBytes / totalUploadBytes) *
                  progressByStage.uploadToS3
            );
          },
          onError: () => {
            processedFiles += 1;
            if (processedFiles === matchedPDFs.length)
              resolve(currentUploadedFiles);
          },
          onFinish: () => {
            saveReviewReferenceDocument(
              file.file,
              reviewId,
              importJob.id,
              file.attachable_id
            )
              .then(() => {
                attachableIds.push(file.attachable_id);
                currentUploadedFiles += 1;
                totalUploaded += 1;
              })
              .catch(() => {
                // do nothing
              })
              .finally(() => {
                processedFiles += 1;

                if (processedFiles === matchedPDFs.length)
                  resolve(totalUploaded);
              });
          },
        });
      });
    });

    loadingPromise
      .then(() => {
        const pendoEvent = byDragAndDrop
          ? 'bulk-uploader-drop'
          : 'bulk-uploader-select';
        sendPendoEvent(pendoEvent, currentUploadedFiles);
        return pollImportJobStatus(reviewId, importJob.id);
      })
      .then((job: ImportJob) => {
        setProgress(0);
        onProcessed(job);
      });
  };

  return (
    <>
      <DragAndDropUploader
        allowDirectories
        acceptUpload=".pdf"
        onFileChange={onFileChange}
        ref={fileRef}
        data-testid="BulkPDFUploader"
        onDragEnterClassName={styles.dragged}
      >
        <UploaderSection dashed error={!!error}>
          {text || 'Step 4: Drag and drop folder containing all PDFs here'}
          <TertiaryButton onClick={() => fileRef.current?.click()}>
            <Icon name="cloud-upload" iconStyle={IconStyle.Solid} />
            Choose folder
          </TertiaryButton>
          <ProgressBar uploadProgress={progress} />
        </UploaderSection>
      </DragAndDropUploader>
      {error && (
        <div
          data-testid="BulkPDFUploader__error"
          className={styles.BulkPDFUploader__error}
        >
          {error}
        </div>
      )}
    </>
  );
};

export default BulkPDFUploader;
