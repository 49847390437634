import React from 'react';

const svg = (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.5 5H7V0.5C7 0.25 6.75 0 6.5 0H5.5C5.21875 0 5 0.25 5 0.5V5H0.5C0.21875 5 0 5.25 0 5.5V6.5C0 6.78125 0.21875 7 0.5 7H5V11.5C5 11.7812 5.21875 12 5.5 12H6.5C6.75 12 7 11.7812 7 11.5V7H11.5C11.75 7 12 6.78125 12 6.5V5.5C12 5.25 11.75 5 11.5 5Z"
      fill="#999999"
    />
  </svg>
);

export function AddIcon(): JSX.Element {
  return svg;
}
