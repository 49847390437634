import { useQuery } from '@tanstack/react-query';
import { getReviewId } from './Review';
import { Study } from 'types';
import {
  AUTO_EXCLUSIONS,
  LATEST_AUTO_EXCLUSION_BATCH,
  tokenizeRoute,
} from 'query/routes';

export type AutoExclusionBatch = {
  review_id: number;
  id: number;
  citation_import_id: number | null;
  auto_exclusion_type: string;
};

export interface LatestAutoExclusionBatchResponse {
  latest_batch: AutoExclusionBatch | null;
  review_total_auto_exclusions_count: number;
}

/**
 * Get the latest auto exclusion batch information
 */

export const getLatestAutoExclusionBatch = async (): Promise<
  LatestAutoExclusionBatchResponse | undefined
> => {
  const reviewId = getReviewId();
  if (!reviewId)
    throw new Error("Couldn't get Review ID. Is this part of a review?");

  const url = tokenizeRoute(LATEST_AUTO_EXCLUSION_BATCH, {
    review_id: reviewId,
  });

  const response = await fetch(url.toString(), {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });

  if (!response.ok) {
    throw new Error('Network response was not ok');
  }

  return await response.json();
};

export type AutoExclusionStudiesResponse = {
  totalStudies: number;
  studies: Study[];
};

/**
 * Gets a list auto excluded studies
 */

export const AUTO_EXCLUSION_STUDIES_PER_PAGE = 50;

export const getAutoExclusionStudies = async (
  page: number
): Promise<AutoExclusionStudiesResponse> => {
  const reviewId = getReviewId();
  if (!reviewId)
    throw new Error("Couldn't get Review ID. Is this part of a review?");

  const url = tokenizeRoute(AUTO_EXCLUSIONS, {
    sort_order: 'desc',
    review_id: reviewId,
    page,
    studies_per_page: AUTO_EXCLUSION_STUDIES_PER_PAGE,
  });

  return fetch(url.toString(), {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  })
    .then((res) => res.json())
    .then(({ total, studies }) => ({
      totalStudies: total,
      studies,
    }));
};

type BatchConfig = {
  refetchInterval: number;
  enabled: boolean;
};

// for polling auto exclude batches and total number of auto excluded studies
export const useGetLatestAutoExclusionBatchQuery = (
  reviewId: number | string,
  configOverrides?: Partial<BatchConfig>
) => {
  const defaults = { enabled: true, refetchInterval: 10000 };
  const config = { ...defaults, ...configOverrides };

  return useQuery({
    queryKey: [reviewId],
    queryFn: getLatestAutoExclusionBatch,
    refetchInterval: config.refetchInterval,
    enabled: config.enabled,
    select: (data) => ({
      ...data,
      isBatching: !!data?.latest_batch?.id,
      review_total_auto_exclusions_count:
        data?.review_total_auto_exclusions_count || 0,
    }),
  });
};
