import React from 'react';
import styles from './HelpPanel.module.scss';
import {
  Message,
  MessageControls,
  Heading,
  Text,
  Button,
  Icon,
  IconList,
} from 'components/core';

const HelpPanel = () => {
  return (
    <Message className={styles.HelpSection} dismissible={false}>
      <Heading type="h5" component="h2">
        Need help setting up your template?
      </Heading>
      <Text>Save time setting up your template by following our guide.</Text>
      <MessageControls>
        <Button
          type="brand"
          to={'https://youtu.be/8CGe1QJfAfg'}
          data-pendo-key="template-video-guide"
          target="_blank"
        >
          Watch the video guide
          <Icon icon={IconList.light.faCirclePlay} />
        </Button>
        <Button
          variant="outline"
          to={
            'https://support.covidence.org/help/how-to-create-a-data-extraction-template-in-extraction-1'
          }
          data-pendo-key="template-kb-guide"
          external
        >
          Read the step-by-step guide
        </Button>
      </MessageControls>
    </Message>
  );
};

export default HelpPanel;
