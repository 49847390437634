import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import BulletedTextarea from '../BulletedTextarea';
import styles from './EligibilityCriteriaForm.module.scss';
import { labels } from './definitions';
import { PICOHelperContent } from './PICOHelperContent';
import {
  Button,
  FormLabel,
  Icon,
  IconList,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Text,
  TextArea,
  Toast,
  ToastContainer,
  createToast,
} from 'components/core';
import { Criteria, CriteriaCategory } from 'types/Criteria';
import { updateCriteria } from 'query/review/Criteria';
import { useConfirmUnsaved } from 'hooks/useConfirmUnsaved';
import { Stack } from 'components/layout';

const defaultValues = (
  sections: CriteriaCategory[],
  initialData: Criteria = {}
) => {
  const defaultValues: Criteria = {};

  sections.forEach((key) => {
    defaultValues[key] = {
      // the include/exclude keys refer to the include/exclude criteria we get from the database
      include: initialData[key]?.include || [],
      exclude: initialData[key]?.exclude || [],
    };
  });

  return defaultValues;
};

// New values that are entered will come through as a string and existing values will be an array
const valueAsArray = (value: string | string[]) => {
  if (typeof value === 'string') {
    return [value].filter((value) => Boolean(value.trim()));
  }

  return value;
};

type Props = {
  initialData?: Criteria;
};

const EligibilityCriteriaForm = ({ initialData }: Props) => {
  const [clean, dirty] = useConfirmUnsaved();
  const sections = [
    CriteriaCategory.Population,
    CriteriaCategory.InterventionExposure,
    CriteriaCategory.ComparatorContext,
    CriteriaCategory.Outcome,
    CriteriaCategory.StudyCharacteristics,
    CriteriaCategory.Other,
  ];
  const {
    register,
    control,
    handleSubmit,
    formState,
    reset,
  } = useForm<Criteria>({
    defaultValues: defaultValues(sections, initialData),
  });

  const saveCriteria = (formData: Criteria) => {
    return updateCriteria(formData)
      .then(() => {
        reset(formData);
        createToast(<Toast type="success">Your changes have been saved</Toast>);
      })
      .catch(() => {
        createToast(
          <Toast type="danger">
            Unable to save criteria, please try again later
          </Toast>
        );
      });
  };

  const onSubmit = saveCriteria;

  useEffect(() => {
    // This form is lazily loaded on the page, meaning if the page
    // is visited with an anchor in the url, by the time the form is
    // loaded, the browser has already tried (and failed) to visit
    // said  anchor.
    // Manually scroll to the element when this component has loaded
    // as a workaround.
    const elementId = window.location.hash.split('#')[1];

    if (elementId) {
      document.getElementById(elementId)?.scrollIntoView();
    }
  }, []);

  useEffect(() => {
    formState.isDirty ? dirty() : clean();
  }, [formState.isDirty, dirty, clean]);

  return (
    <Stack component="form" spacing={6} onSubmit={handleSubmit(onSubmit)}>
      {sections.map((section) => {
        const includeId = `${section}Include`;
        const includeName = `${section}.include`;
        const excludeId = `${section}Exclude`;
        const excludeName = `${section}.exclude`;
        const HelperContent = PICOHelperContent[section];

        return (
          <fieldset key={section}>
            <div className={styles.section}>
              <Text
                id={section}
                size="md"
                component="legend"
                className={styles.legend}
              >
                {labels[section]}
              </Text>
              <Popover>
                <PopoverTrigger>
                  <Button
                    htmlType="button"
                    variant="ghost"
                    type="neutral"
                    iconOnly
                    aria-label={`Learn more about ${labels[section]}`}
                  >
                    <Icon icon={IconList.light.faCircleQuestion} />
                  </Button>
                </PopoverTrigger>
                <PopoverContent className={styles.popoverContent}>
                  <div className={styles.popoverContentSpacer}>
                    <HelperContent />
                  </div>
                </PopoverContent>
              </Popover>
            </div>

            <div className={styles.row}>
              <div className={styles.rowItem}>
                <FormLabel
                  aria-label={`${labels[section]} include`}
                  className={styles.includeLabel}
                  id={includeId}
                >
                  Include
                </FormLabel>

                {section === CriteriaCategory.Other && (
                  <TextArea
                    id={includeId}
                    aria-labelledby={includeId}
                    className={styles.textarea}
                    {...register(includeName, {
                      setValueAs: valueAsArray,
                    })}
                  />
                )}

                {section !== CriteriaCategory.Other && (
                  <BulletedTextarea
                    name={includeName}
                    control={control}
                    ariaLabelledBy={includeId}
                  />
                )}
              </div>

              <div className={styles.rowItem}>
                <FormLabel
                  aria-label={`${labels[section]} exclude`}
                  className={styles.excludeLabel}
                  id={excludeId}
                >
                  Exclude
                </FormLabel>

                {section === CriteriaCategory.Other && (
                  <TextArea
                    id={excludeId}
                    aria-labelledby={excludeId}
                    className={styles.textarea}
                    {...register(excludeName, {
                      setValueAs: valueAsArray,
                    })}
                  />
                )}

                {section !== CriteriaCategory.Other && (
                  <BulletedTextarea
                    name={excludeName}
                    control={control}
                    ariaLabelledBy={excludeId}
                  />
                )}
              </div>
            </div>
          </fieldset>
        );
      })}

      <div>
        <Button size="md" type="brand" isLoading={formState.isSubmitting}>
          Save
        </Button>
      </div>

      <ToastContainer />
    </Stack>
  );
};

export default EligibilityCriteriaForm;
