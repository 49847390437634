import React from 'react';

const svg = (
  <svg
    role="img"
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.25 0H1.75C0.784766 0 0 0.784766 0 1.75V9.625C0 10.5902 0.784766 11.375 1.75 11.375H4.375V13.6719C4.375 13.9398 4.68125 14.0957 4.89727 13.9371L8.3125 11.375H12.25C13.2152 11.375 14 10.5902 14 9.625V1.75C14 0.784766 13.2152 0 12.25 0Z"
      fill="#999999"
    />
  </svg>
);

export function CommentIcon(): JSX.Element {
  return svg;
}
