import React from 'react';
import { Menu, MenuItem } from '../../FormBuilder/Widgets/Menu';
import Icon from 'system/base/Icon';

export interface SelectFullTextProps {
  fullTexts: Array<{ url: string; title: string }>;
  selectedIndex?: number;
  onSelect: (index: number) => void;
}
export function SelectFullText({
  fullTexts,
  selectedIndex,
  onSelect,
}: SelectFullTextProps): JSX.Element {
  return (
    <>
      <Menu
        buttonTitle={
          <>
            Select Full Text
            <Icon name="caret-down" />
          </>
        }
        itemsHeading="Change preview"
      >
        {fullTexts.map(({ url, title }, index) => (
          <MenuItem
            key={url}
            title={title}
            checked={index === selectedIndex}
            onSelect={() => {
              onSelect(index);
            }}
          />
        ))}
      </Menu>
    </>
  );
}
