import React, { useState, useRef } from 'react';
import { Input } from 'theme-ui';

import styles from './CityLookup.module.css';
import { useDebouncedFunction } from 'hooks/useDebouncedFunction';

import Spinner from 'system/elements/Spinner';
import Box from 'system/base/Box';
import { PrimaryButton, DangerButton } from 'system/base/Button';

import Icon from 'system/base/Icon';

interface City {
  name: string;
  state: string;
  country: string;
}

export const CityLookup = ({ objectName }: { objectName: string }) => {
  const [query, setQuery] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [city, setCity] = useState<City | null>(null);
  const [cityConfirmed, setCityConfirmed] = useState<boolean>(false);

  const loadCity = useDebouncedFunction((query) => {
    fetch(`/api/v1/city?query=${query}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((response) => {
        setLoading(false);
        setCity(response);
      });
  }, 500);

  const cityInput = useRef<HTMLInputElement>(null);

  const renderCityPrompt = () => {
    if (!city) return;

    const { name, state, country } = city;
    let fullName: string;

    if (state) {
      fullName = `${name}, ${state}, ${country}`;
    } else {
      fullName = `${name}, ${country}`;
    }

    return (
      <Box marginY={3}>
        <Box marginY={1}>
          Did you mean: <br />
          <em>{fullName}</em>
        </Box>
        <div className={styles.buttons}>
          <PrimaryButton
            iconName="check"
            className={styles.yesButton}
            onClick={(e) => {
              e.preventDefault();
              setQuery(fullName);
              setCityConfirmed(true);
            }}
          >
            Yes
          </PrimaryButton>
          <DangerButton
            iconName="times"
            className={styles.noButton}
            onClick={(e) => {
              e.preventDefault();
              setQuery('');
              setCity(null);
              if (cityInput.current) {
                cityInput.current.value = '';
                cityInput.current.focus();
              }
            }}
          >
            No
          </DangerButton>
        </div>
      </Box>
    );
  };

  const renderNoResults = () => {
    return (
      <Box marginY={3}>
        Nothing found, please check your spelling and try again
      </Box>
    );
  };

  const renderLoadingOrResult = () => {
    if (cityConfirmed) {
      return;
    }

    if (loading) {
      return <Spinner />;
    }

    if (city) {
      return renderCityPrompt();
    }

    if (query != '') return renderNoResults();
  };

  const renderFields = () => {
    if (city && cityConfirmed) {
      return (
        <>
          <HiddenField objectName={objectName} name="city" value={city.name} />
          <HiddenField
            objectName={objectName}
            name="state"
            value={city.state}
          />
          <HiddenField
            objectName={objectName}
            name="country"
            value={city.country}
          />
        </>
      );
    }
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCityConfirmed(false);
    setQuery(e.target.value);

    if (e.target.value == '') {
      setCity(null);
    } else {
      setLoading(true);
      loadCity(e.target.value);
    }
  };

  return (
    <div>
      <label htmlFor="city_lookup">City</label>
      <div className={styles.inputWrapper}>
        <Input
          p={2}
          ref={cityInput}
          value={query}
          onChange={onChange}
          id="city_lookup"
          type="text"
          placeholder="Melbourne, Victoria, Australia"
        />
        {cityConfirmed && <Icon name="check" className={styles.check} />}
      </div>
      {renderLoadingOrResult()}

      {renderFields()}
    </div>
  );
};

const HiddenField = ({
  objectName,
  name,
  value,
}: {
  objectName: string;
  name: string;
  value: string;
}) => {
  return <input type="hidden" name={`${objectName}[${name}]`} value={value} />;
};
