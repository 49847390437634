import React from 'react';
import {
  NavigationTabItem,
  NavigationTabItemWithBadge,
  NavigationTabs,
} from 'components/NavigationTabs/NavigationTabs';
import { Badge } from 'components/core';

type Props = {
  activeTab: 'manual' | 'auto-exclude' | 'system';
  autoExcludeFeatureEnabled: boolean;
  reviewId: number;
  totalAutoExcludedStudies: number;
  totalManualDuplicateStudies: number;
  totalSystemDuplicateStudies: number;
};

type AutoExclusionNavigationTabProps = {
  url: string;
  active: boolean;
  count: number;
};

export const AutoExclusionNavigationTab = ({
  url,
  active,
  count,
}: AutoExclusionNavigationTabProps) => {
  return (
    <NavigationTabItemWithBadge
      name="Auto-marked as ineligible"
      active={active}
      url={url}
      count={count}
      countAriaLabel={`Auto-marked as ineligible - ${count} records`}
      badge={() => (
        <Badge type="primary" variant="solid">
          Early access
        </Badge>
      )}
    />
  );
};

const AutomationNavigation = ({
  reviewId,
  totalAutoExcludedStudies,
  totalManualDuplicateStudies,
  totalSystemDuplicateStudies,
  autoExcludeFeatureEnabled,
  activeTab,
}: Props) => {
  const paths = {
    systemDuplicates: `/reviews/${reviewId}/duplicates`,
    manualDuplicates: `/reviews/${reviewId}/duplicates/manual_duplicates`,
    autoExclusions: `/reviews/${reviewId}/auto_exclusions`,
  };
  return (
    <NavigationTabs>
      <NavigationTabItem
        name="Auto-marked duplicates"
        url={paths.systemDuplicates}
        active={activeTab === 'system'}
        count={totalSystemDuplicateStudies}
        countAriaLabel={`Auto-marked duplicates - ${totalSystemDuplicateStudies} records`}
      />
      <NavigationTabItem
        name="Manually marked duplicates"
        url={paths.manualDuplicates}
        active={activeTab === 'manual'}
        count={totalManualDuplicateStudies}
        countAriaLabel={`Manually marked duplicates - ${totalManualDuplicateStudies} records`}
      />
      {autoExcludeFeatureEnabled && (
        <AutoExclusionNavigationTab
          active={activeTab === 'auto-exclude'}
          url={paths.autoExclusions}
          count={totalAutoExcludedStudies || 0}
        />
      )}
    </NavigationTabs>
  );
};

export default AutomationNavigation;
