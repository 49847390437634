import React from 'react';
import { Input, InputProps } from 'theme-ui';
import LabelFor from './LabelFor';

interface TextFieldProps extends InputProps {
  inputID?: string;
  name?: string;
  labelClassName?: string;
  inputClassName?: string;
  value?: string;
  required?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  defaultValue?: string;
  placeholder?: string;
  label: string | JSX.Element;
}

const TextField = ({
  label,
  inputID,
  name,
  defaultValue,
  labelClassName,
  inputClassName,
  onChange,
  required = false,
  value,
  placeholder,
  ...otherInputProps
}: TextFieldProps) => (
  <LabelFor
    flexDirection="column-reverse"
    labelProps={{ mb: 1 }}
    className={labelClassName}
    input={
      <Input
        {...otherInputProps}
        name={name}
        className={inputClassName}
        defaultValue={defaultValue}
        id={inputID}
        value={value}
        type="text"
        required={required}
        onChange={onChange}
        placeholder={placeholder}
      />
    }
  >
    {label}
  </LabelFor>
);

export default TextField;
