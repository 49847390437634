import React, { useState, useRef } from 'react';
import { TertiaryButton as Button } from '../../../system/base/Button';
import Icon from '../../../system/base/Icon';
import { Reference, decodeHTMLEntities } from '..';
import styles from './DragAndDropFileUploader.module.css';

interface Props {
  reference: Reference;
  onFileUpload: (file: File) => void;
}

const acceptedContentTypes = ['application/pdf'];

export default function DragAndDropFileUploader({
  reference,
  onFileUpload,
}: Props) {
  const [isDragged, setDragged] = useState(false);
  const fileRef = useRef<HTMLInputElement>(null);

  const onFileChange = () => {
    const files = fileRef.current?.files;

    if (files && files.length) {
      if (acceptedContentTypes.includes(files[0].type)) onFileUpload(files[0]);
      if (fileRef.current) fileRef.current.value = '';
    }
  };

  return (
    <div
      className={`${styles.DragAndDropFileUploader__form} ${
        isDragged ? styles.DragAndDropFileUploader__dragged : ''
      }`}
      onDragEnter={() => setDragged(true)}
    >
      {/* The drag event makes the file input take up the entire form, so we let that handle the exit events */}
      <label className={styles.DragAndDropFileUploader__uploader}>
        <input
          ref={fileRef}
          type="file"
          accept=".pdf"
          className={styles.DragAndDropFileUploader__input}
          onDragLeave={() => setDragged(false)}
          onDrop={() => setDragged(false)}
          onChange={onFileChange}
          data-testid="DragAndDropFileUploader-input"
        />
        <div className={styles.DragAndDropFileUploader__label}>
          <Icon name="cloud-upload" />
          <div className={styles.DragAndDropFileUploader__text}>
            Drag and drop your PDF here
            <br />
            <small>{decodeHTMLEntities(reference.title)}</small>
          </div>
          <Button
            className={styles.DragAndDropFileUploader__button}
            onClick={(e) => {
              e.preventDefault();
              fileRef.current?.click();
            }}
          >
            Choose a file
          </Button>
        </div>
      </label>
    </div>
  );
}
