import React from 'react';
import styles from './BulkUploadHistory.module.css';
import FullTextImportJobHeader from 'components/FullTextImportJobHeader/FullTextImportJobHeader';
import Icon, { IconStyle } from 'system/base/Icon';

interface Props {
  records: Array<RecordProps>;
}

interface RecordProps {
  file_name: string;
  uploaded_by: string;
  uploaded_when: string;
  files_uploaded?: number;
  files_to_upload?: number;
  total: number;
}

interface ProgressProps {
  total: number;
  current?: number;
  progressType: 'ok' | 'warning';
}

const Progress = (props: ProgressProps) => {
  const OkIcon = () => (
    <>
      <Icon
        name="check-circle"
        iconStyle={IconStyle.Regular}
        className={styles.circleCheck}
      />{' '}
    </>
  );

  const WarningIcon = () => (
    <>
      <Icon
        name="exclamation-circle"
        iconStyle={IconStyle.Regular}
        className={styles.warningIcon}
      />{' '}
    </>
  );

  const { progressType, total, current } = props;

  if (!current && progressType === 'warning') {
    return null;
  }

  return (
    <div className={styles.progress}>
      <div className={styles.progressData}>
        <div>
          {progressType === 'ok' && <OkIcon />}
          {progressType === 'warning' && <WarningIcon />}
          <span className={styles.boldNumber}>{current}</span> <br />
        </div>
        {progressType === 'ok' && `out of ${total} PDFs uploaded`}
        {progressType === 'warning' && `out of ${total} PDFs not uploaded`}
      </div>
    </div>
  );
};

const EmptyState = () => {
  return (
    <div className={styles.card}>
      <div className={styles.emptyState}>
        <h4>No bulk uploads</h4>
        Completed bulk uploads will appear on this page
      </div>
    </div>
  );
};

const BulkUploadHistory = (props: Props) => {
  const { records } = props;

  if (records.length === 0) {
    return <EmptyState />;
  }

  return (
    <div>
      {records.map((record, i) => {
        const {
          uploaded_by: uploadedBy,
          uploaded_when: uploadedWhen,
          file_name: fileName,
          files_uploaded: filesUploaded,
          total: total,
        } = record;
        return (
          <div className={styles.card} key={`record-${i}`}>
            <div className={styles.header}>
              <FullTextImportJobHeader
                fileName={fileName}
                uploadedWhen={uploadedWhen}
                uploadedBy={uploadedBy}
              />
            </div>
            <div className={styles.status}>
              <Progress
                progressType="ok"
                total={total}
                current={filesUploaded}
              />
              <Progress
                progressType="warning"
                total={total}
                current={total - (filesUploaded || 0)}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default BulkUploadHistory;
