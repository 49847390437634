import React, { useState } from 'react';
import { formatDistanceToNow } from 'date-fns';
import { Box } from 'theme-ui';

import styles from './Memberships.module.css';
import { RemoveReviewerFn } from './queries';

import { ReviewMembership as MembershipType } from 'types/graphql';
import Dialog from 'system/base/Dialog';

import {
  DangerButton,
  PrimaryButton,
  TertiaryButton,
} from 'system/base/Button';

interface MembershipsProps {
  removeReviewer: RemoveReviewerFn;
  memberships: Array<MembershipType>;
}

interface MembershipProps extends MembershipType {
  removeReviewer: RemoveReviewerFn;
}

const Membership = ({
  reviewer: { id, name, primaryEmail },
  owner,
  createdAt,
  removeReviewer,
}: MembershipProps) => {
  const [showModal, setShowModal] = useState<boolean>(false);

  const remove = () => {
    removeReviewer(id).then(() => {
      setShowModal(false);
    });
  };

  return (
    <li className={styles.membership} data-testid="reviewer">
      <Dialog
        title="Remove reviewer?"
        isOpen={showModal}
        onDismiss={() => setShowModal(false)}
      >
        <p>
          Are you sure you would like to remove {name} ({primaryEmail})?
        </p>

        <PrimaryButton onClick={remove} mr={2}>
          Yes
        </PrimaryButton>
        <TertiaryButton onClick={() => setShowModal(false)}>No</TertiaryButton>
      </Dialog>
      <h3>
        <span>{name}</span>
        <div className={`${styles.owner}`}>{owner ? 'Review Owner' : ''}</div>
      </h3>
      <DangerButton
        className={styles.removeReviewer}
        onClick={() => setShowModal(true)}
      >
        Remove reviewer
      </DangerButton>
      <div>{primaryEmail}</div>
      <Box sx={{ fontSize: 1, color: 'grey' }} className="hide-in-percy">
        Added to review:{' '}
        <time dateTime={createdAt}>
          {formatDistanceToNow(new Date(createdAt), { addSuffix: true })}
        </time>
      </Box>
    </li>
  );
};

const Memberships = ({ removeReviewer, memberships }: MembershipsProps) => {
  return (
    <Box mr={5}>
      <ul>
        {memberships.map((membership) => (
          <Membership
            {...membership}
            removeReviewer={removeReviewer}
            key={membership.reviewer.id}
          />
        ))}
      </ul>
    </Box>
  );
};
export default Memberships;
