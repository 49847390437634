import React from 'react';
import { TertiaryButton } from 'system/base/Button';

export interface DeleteBlockProps {
  onRemove: () => void;
}

export function DeleteBlock({ onRemove }: DeleteBlockProps): JSX.Element {
  return <TertiaryButton onClick={onRemove}>Delete</TertiaryButton>;
}
