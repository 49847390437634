import fromEntries from 'fromentries';
import deepmerge from 'deepmerge';
import { useEffect, useRef } from 'react';
import { recordToQueryString } from 'util/queryString';

interface FilterableVariables<TFilter> {
  filter?: TFilter;
}

type AllowedKeys<
  TVariables extends FilterableVariables<TVariables['filter']>
> = Omit<keyof TVariables | keyof TVariables['filter'], 'filter'>;

function useSyncVariablesToQueryString<
  TVariables extends FilterableVariables<TVariables['filter']>
>(
  variables: TVariables,
  allowedKeys: AllowedKeys<TVariables>[],
  skipInitialSync = true
) {
  // Don't sync initial variables to the querystring
  const skipInitialSyncRef = useRef(skipInitialSync);

  // just cache the initial allowedKeys, to prevent double effects on render
  const initialAllowedKeys = useRef(allowedKeys);

  return useEffect(() => {
    if (skipInitialSyncRef.current) {
      skipInitialSyncRef.current = false;
      return;
    }

    if (window.history && window.history.replaceState) {
      const mergedVariables = variables.filter
        ? deepmerge(variables, variables.filter)
        : variables;

      const queryString = recordToQueryString(
        fromEntries(
          Object.entries(mergedVariables).filter(
            ([key, value]) =>
              initialAllowedKeys.current.includes(key as any) &&
              value !== undefined
          )
        )
      );

      if (queryString.length === 0) {
        window.history.replaceState(null, document.title, location.pathname);
      } else {
        window.history.replaceState(null, document.title, queryString);
      }
    }
  }, [variables, initialAllowedKeys]);
}

export default useSyncVariablesToQueryString;
