import React, { FunctionComponent } from 'react';

import styles from './History.module.scss';
import { Export } from './index';
import { Button, Heading, Icon, IconList, Text } from 'components/core';

interface HistoryProps {
  exports: Export[];
}

const History: FunctionComponent<HistoryProps> = (props: HistoryProps) => {
  const { exports } = props;
  const renderExport = (ex: Export) => {
    return <ExportRow key={ex.id} {...ex} />;
  };

  const renderExportTable = () => {
    if (exports.length == 0) {
      return <Text>There have been no exports in the last 7 days</Text>;
    }

    return (
      <>
        <Text>In the last 7 days</Text>
        <table className={styles.exportHistory}>
          <thead>
            <tr>
              <Text size="sm" component="th" variant="neutral">
                Exported
              </Text>
              <th></th>
              <Text size="sm" component="th" variant="neutral">
                Type
              </Text>
              <Text size="sm" component="th" variant="neutral">
                Description
              </Text>
              <th></th>
            </tr>
          </thead>
          <tbody>{exports.map(renderExport)}</tbody>
        </table>
      </>
    );
  };

  return (
    <div id="export-history" className={styles.exportHistoryContainer}>
      <Heading type="h5">Recent exports</Heading>
      {renderExportTable()}
    </div>
  );
};

class ExportRow extends React.Component<Export> {
  renderStatus() {
    const { completedAt, authenticatedUrl, failed } = this.props;
    if (completedAt) {
      return (
        <Button
          variant="outline"
          size="sm"
          type="brand"
          onClick={() => (window.location.href = authenticatedUrl)}
        >
          Download
          <Icon icon={IconList.light.faArrowDownToLine} />
        </Button>
      );
    }

    if (failed) {
      return 'Failed';
    }

    return (
      <div>
        <Icon icon={IconList.light.faClock} /> Pending...
      </div>
    );
  }

  render() {
    const { createdAt, description, format, type } = this.props;
    return (
      <tr className="export">
        <Text size="sm" component="td" variant="neutral">
          {createdAt}
        </Text>
        <td></td>
        <Text size="sm" component="td" variant="neutral">
          {type} <small>({format})</small>
        </Text>
        <Text size="sm" component="td" variant="neutral">
          {description}
        </Text>
        <Text size="sm" component="td" variant="neutral">
          {this.renderStatus()}
        </Text>
      </tr>
    );
  }
}

export default History;
