import React from 'react';
import styles from './Select.module.scss';
import { Icon, IconList } from 'components/core';

export interface Props
  extends Omit<React.SelectHTMLAttributes<HTMLSelectElement>, 'type' | 'size'> {
  /**
   * Specifies the style context for the input. Default is `primary`
   */
  type?: 'neutral' | 'success' | 'danger' | 'warning' | 'primary';
  /**
   * Specifies the input style variant. Default is `outline`
   */
  variant?: 'outline';
  /**
   * Sets the input to a specific size, overriding inherited sizes.
   */
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  /**
   * Sets an error state on the input
   */
  isError?: boolean;
}

const Select = (
  {
    type = 'primary',
    variant = 'outline',
    size,
    isError,
    className,
    children,
    ...props
  }: Props,
  ref: React.ForwardedRef<HTMLSelectElement>
) => {
  const classes = [styles.Select, styles[type], styles[variant]];
  if (size) classes.push(styles[size]);
  if (isError) classes.push(styles.isError);
  if (className) classes.push(className);

  const iconClasses = [styles.SelectArrow];
  if (props.disabled) iconClasses.push(styles.SelectArrowDisabled);

  return (
    <div className={styles.SelectContainer}>
      <select
        className={classes.join(' ')}
        ref={ref}
        aria-invalid={isError}
        {...props}
      >
        {children}
      </select>
      <Icon
        className={iconClasses.join(' ')}
        icon={IconList.solid.faAngleDown}
      />
    </div>
  );
};

const forwardedInput = React.forwardRef<any, Props>(Select) as React.FC<Props>;

export default forwardedInput;
export { forwardedInput as Input };
