import React from 'react';
import MenuItemContainer from './components/MenuItemContainer';
import MenuItemLink from './components/MenuItemLink';
import { Props } from 'components/core/Link/Link.d';

const MenuItem = ({ className, ...props }: Props) => {
  return (
    <MenuItem.Container>
      <MenuItem.Link className={className} {...props} />
    </MenuItem.Container>
  );
};

MenuItem.Container = MenuItemContainer;
MenuItem.Link = MenuItemLink;

export default MenuItem;
