import React from 'react';
import { FloatingArrow } from '@floating-ui/react';
import { usePopoverContext } from '../../Popover';
import styles from './PopoverArrow.module.scss';

export const PopoverArrow = () => {
  const { arrowRef, context } = usePopoverContext();

  return (
    <FloatingArrow
      className={styles.arrow}
      ref={arrowRef}
      context={context}
      fill="white"
      stroke="currentColor"
      strokeWidth={1}
    />
  );
};
