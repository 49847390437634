import React from 'react';
import ActivityDetailBox from '../ActivityDetailBox';
import { CATEGORY_NAMES } from 'schema/enums';
import { ImportState, ImportFragment } from 'types/graphql';
import { Text } from 'components/core';

interface ImportInProgressProps {
  currentImport?: ImportFragment | null;
}

const mayTakeAWhileMessage =
  'This can take a while, depending on the number of new references in the currently importing file and the existing references in your review.';

const ImportInProgress = ({ currentImport = null }: ImportInProgressProps) => {
  if (currentImport === null) {
    return null;
  }

  const categoryName = CATEGORY_NAMES[currentImport.defaultCategory];
  const total = currentImport.detectedCitationsCount;
  const imported =
    currentImport.importedStudiesCount + currentImport.duplicateCitationsCount;

  switch (currentImport.state) {
    case ImportState.New:
      return <ActivityDetailBox status="Starting..." />;
    case ImportState.Sourcing:
    case ImportState.Sourced:
      return <ActivityDetailBox isActive status="Finding references..." />;
    case ImportState.Preparing:
    case ImportState.Prepared:
      return (
        <ActivityDetailBox
          isActive
          status={`Checking ${total} references for duplicates and study details...`}
        >
          <Text variant="weak">{mayTakeAWhileMessage}</Text>
        </ActivityDetailBox>
      );
    case ImportState.Importing:
    case ImportState.Imported: {
      const current = Math.min(total, imported + 1);

      const status = `
            Importing ${current} of ${total} references and studies
            to ${categoryName}...`;

      return (
        <ActivityDetailBox isActive status={status}>
          <Text variant="weak">{mayTakeAWhileMessage}</Text>
        </ActivityDetailBox>
      );
    }
    case ImportState.Finalizing:
      return <ActivityDetailBox isActive status="Finalizing import..." />;
    default:
      return null;
  }
};

export default ImportInProgress;
