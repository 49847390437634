import React from 'react';
import styles from './SingleSignOnForms.module.scss';

import CovidenceLogoSvg from 'assets/images/logos/logo-background-strong.svg';

export const CovidenceLogo = () => {
  return (
    <img className={styles.logo} src={CovidenceLogoSvg} alt="Covidence Logo" />
  );
};
