import { BlockRecords } from '../BlocksData';
import { TextFieldBlock } from '../Blocks/TextField/TextFieldBlock';
import { MultipleChoiceBlock } from '../Blocks/MultipleChoice/MultipleChoiceBlock';
import {
  CheckboxesBlock,
  CheckboxesBlockValue,
} from '../Blocks/Checkboxes/CheckboxesBlock';
import { TemplateBlock } from '../Blocks/BaseBlock';
import { TemplateModel } from '../TemplateModel';

export interface ExtractViewModel {
  blocks: Array<TemplateBlock>;
  blockRecords: BlockRecords;
}

export function fromModel(model: TemplateModel): ExtractViewModel {
  return { blocks: model.blocks, blockRecords: {} };
}

export function toModel(viewModel: ExtractViewModel): TemplateModel {
  return { blocks: viewModel.blocks };
}

export type ExtractBlockTypeAndValue =
  | {
      type: TextFieldBlock['type'];
      value: string;
    }
  | {
      type: MultipleChoiceBlock['type'];
      value: string;
    }
  | {
      type: CheckboxesBlock['type'];
      value: CheckboxesBlockValue;
    };
