import React from 'react';
import { Box } from 'theme-ui';
import { NonEditableCellProps } from '../types';

/**
 * A regular non-editable cell for displaying table cell values.
 */
export const Cell = ({ value }: NonEditableCellProps) => (
  <Box sx={{ padding: 1, height: '100%' }}>{value ?? ''}</Box>
);
