import React, { useState } from 'react';
import { Box } from 'theme-ui';

import Memberships from './Memberships';
import Invites from './Invites';
import InviteNewReviewer from './InviteNewReviewer';
import {
  useReviewersQuery,
  useInviteReviewerMutation,
  useRemoveReviewerMutation,
  useCancelInviteMutation,
} from './queries';

import Panel from 'system/elements/Panel';
import Spinner from 'system/elements/Spinner';
import ErrorMessage from 'components/ErrorMessage';
import Flex from 'system/base/Flex';
import Notice from 'system/elements/Notice';
import { validateEmail } from 'util/validateEmail';

interface ReviewersProps {
  additionalReviewers: string;
  cochraneReview: boolean;
  ownerName: string;
  planName: string;
  reviewId: string;
  reviewerCapReached: boolean;
}

const Reviewers = ({
  additionalReviewers,
  cochraneReview,
  ownerName,
  planName,
  reviewId,
  reviewerCapReached,
}: ReviewersProps) => {
  const { data, loading, error } = useReviewersQuery(reviewId);
  const [notice, setNotice] = useState<string>('');
  const [failure, setFailure] = useState<string>('');

  const invite = useInviteReviewerMutation();
  const remove = useRemoveReviewerMutation();
  const cancel = useCancelInviteMutation();

  const removeReviewer = (reviewerId: string) => {
    return remove({
      variables: { reviewId, reviewerId },
      refetchQueries: ['ReviewersQuery'],
    });
  };

  const inviteReviewer = (name: string, email: string) => {
    if (!validateEmail(email)) {
      setFailure(`The provided email address "${email}" was not valid.`);
      return;
    }

    return invite({
      variables: { reviewId, name, email },
      refetchQueries: ['ReviewersQuery'],
    }).then(({ data }) => {
      if (!data || !data.invitePersonToReview) {
        return;
      }

      const { success, errors } = data.invitePersonToReview;
      if (success) {
        setNotice(`Your invitation has been sent to: ${email}.`);
        return;
      }

      const errorMessages: Record<string, string> = {
        max_capacity: `You are at the ${planName} plan limit for reviewers (${additionalReviewers}). You must first upgrade your plan before adding new reviewers.`,
        maximum_reviewers:
          "You've reached the limit of invitations for a Trial Account.",
        invalid_email: `The provided email address "${email}" was not valid.`,
        record_invalid: 'The provided details are not valid.',
        already_collaborator: `${email} is already a reviewer.`,
      };

      const message = errors && errorMessages[errors[0].code];

      if (message) {
        setFailure(message);
      }
    });
  };

  const cancelInvite = (invitationId: string, email: string) => {
    return cancel({
      variables: { invitationId },
      refetchQueries: ['ReviewersQuery'],
    }).then(() => {
      setNotice(`You have canceled the invitation for ${email}.`);
    });
  };

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <ErrorMessage>{error.message}</ErrorMessage>;
  }

  if (!data) {
    return null;
  }

  const {
    review: { memberships, invites },
  } = data;

  const renderNotice = () => {
    if (notice === '') {
      return null;
    }
    return (
      <Notice type="success" mb={2}>
        {notice}
      </Notice>
    );
  };

  const renderFailure = () => {
    if (failure === '') {
      return null;
    }
    return (
      <Notice type="error" mb={2}>
        {failure}
      </Notice>
    );
  };

  const renderPendingInvites = () => {
    const pendingInvites = invites.nodes.filter((invite) => invite.pending);
    if (pendingInvites.length === 0) {
      return (
        <Box sx={{ color: 'grey' }}>
          <em>There are currently no pending invites for this review.</em>
        </Box>
      );
    }
    return <Invites cancelInvite={cancelInvite} invites={pendingInvites} />;
  };

  const renderExpiredOrDeclinedInvites = () => {
    const expiredOrDeclinedInvites = invites.nodes.filter(
      (invite) => !invite.accepted && (invite.expired || invite.declined)
    );
    if (expiredOrDeclinedInvites.length === 0) {
      return (
        <Box sx={{ color: 'grey' }}>
          <em>
            There have been no expired or declined invites for this review.
          </em>
        </Box>
      );
    }
    return (
      <Invites cancelInvite={cancelInvite} invites={expiredOrDeclinedInvites} />
    );
  };

  return (
    <>
      {renderNotice()}
      {renderFailure()}
      <Panel primary>
        <Flex flexWrap="wrap">
          <Box sx={{ width: ['100%', '66%'] }}>
            <h1>Reviewers</h1>
            <Memberships
              memberships={memberships.nodes}
              removeReviewer={removeReviewer}
            />
            <div>
              <h1>Invites</h1>
              {renderPendingInvites()}
            </div>

            <div>
              <h1>Expired or Declined Invites</h1>
              {renderExpiredOrDeclinedInvites()}
            </div>
          </Box>
          <Box sx={{ width: ['100%', '33%'] }}>
            {cochraneReview ? (
              <p>
                This review is part of <strong>Cochrane</strong> for{' '}
                <strong>{ownerName}</strong> which allows unlimited reviewers.
              </p>
            ) : (
              <p>
                This review is part of a <strong>{planName}</strong> plan for{' '}
                <strong>{ownerName}</strong> which allows {additionalReviewers}.
              </p>
            )}
            <br />
            <InviteNewReviewer
              inviteReviewer={inviteReviewer}
              reviewerCapReached={reviewerCapReached}
            />
            <br />
            <p>
              Invited reviewers will receive an email notifying them of your
              invitation to join your review.
            </p>
          </Box>
        </Flex>
      </Panel>
    </>
  );
};

export default Reviewers;
