import React, { useState, useCallback } from 'react';
import idx from 'idx';
import { gql, useMutation } from '@apollo/client';
import { ID } from 'util/types';
import DataTable, { Columns } from 'system/data/DataTable';
import useCollectionQuery from 'hooks/useCollectionQuery';
import {
  AdminOrganizationMembersQuery,
  AdminOrganizationMembersQueryVariables,
  AdminRevokeMembershipMutation,
  AdminRevokeMembershipMutationVariables,
  AdminPromoteOrganizationMemberMutation,
  AdminPromoteOrganizationMemberMutationVariables,
  AdminDemoteOrganizationMemberMutation,
  AdminDemoteOrganizationMemberMutationVariables,
  MembershipStatus,
} from 'types/graphql';
import { Link } from 'system/base/Link';
import FormattedDate from 'components/FormattedDate';
import { DangerButton, SecondaryButton } from 'system/base/Button';
import DataWithControls from 'system/layout/DataWithControls';
import SearchInput from 'system/elements/SearchInput';

interface Props {
  organizationId: ID;
}

const GET_MEMBERS = gql`
  query AdminOrganizationMembers(
    $organizationId: ID!
    $after: String
    $filter: OrganizationMembershipsFilter
  ) {
    organization: node(id: $organizationId) {
      id

      ... on Organization {
        memberships(first: 25, after: $after, filter: $filter)
          @connection(key: "memberships", filter: ["filter"]) {
          pageInfo {
            hasNextPage
            endCursor
          }

          nodes {
            id
            status
            memberSince

            member {
              dbId
              id
              name
            }
          }
        }
      }
    }
  }
`;

const REVOKE_MEMBERSHIP = gql`
  mutation AdminRevokeMembership($organizationId: ID!, $memberId: ID!) {
    revokeMembership(
      input: { organizationId: $organizationId, memberId: $memberId }
    ) {
      success
      errors {
        message
      }
    }
  }
`;

const GRANT_ADMIN = gql`
  mutation AdminPromoteOrganizationMember(
    $organizationId: ID!
    $memberId: ID!
  ) {
    promoteOrganizationMemberToAdministrator(
      input: { organizationId: $organizationId, memberId: $memberId }
    ) {
      success
      errors {
        message
      }

      membership {
        id
        status
      }
    }
  }
`;

const REVOKE_ADMIN = gql`
  mutation AdminDemoteOrganizationMember($organizationId: ID!, $memberId: ID!) {
    demoteOrganizationAdministratorToMember(
      input: { organizationId: $organizationId, administratorId: $memberId }
    ) {
      success
      errors {
        message
      }

      membership {
        id
        status
      }
    }
  }
`;

interface ActionsProps {
  organizationId: ID;
  memberId: ID;
  status: MembershipStatus;
}

const Actions = ({ organizationId, memberId, status }: ActionsProps) => {
  const [busy, setBusy] = useState(false);

  const [revokeMembershipMutation] = useMutation<
    AdminRevokeMembershipMutation,
    AdminRevokeMembershipMutationVariables
  >(REVOKE_MEMBERSHIP);
  const revokeMembership = useCallback(() => {
    setBusy(true);
    revokeMembershipMutation({
      variables: { organizationId, memberId },
      refetchQueries: ['AdminOrganizationMembers'],
    });
  }, [setBusy, revokeMembershipMutation, organizationId, memberId]);

  const [grantAdminMutation] = useMutation<
    AdminPromoteOrganizationMemberMutation,
    AdminPromoteOrganizationMemberMutationVariables
  >(GRANT_ADMIN);
  const grantAdmin = useCallback(() => {
    setBusy(true);
    grantAdminMutation({
      variables: { organizationId, memberId },
    }).then(() => setBusy(false));
  }, [setBusy, grantAdminMutation, organizationId, memberId]);

  const [revokeAdminMutation] = useMutation<
    AdminDemoteOrganizationMemberMutation,
    AdminDemoteOrganizationMemberMutationVariables
  >(REVOKE_ADMIN);
  const revokeAdmin = useCallback(() => {
    setBusy(true);
    revokeAdminMutation({
      variables: { organizationId, memberId },
    }).then(() => setBusy(false));
  }, [setBusy, revokeAdminMutation, organizationId, memberId]);

  return (
    <>
      <DangerButton
        iconName="user-times"
        onClick={revokeMembership}
        disabled={busy}
        style={{ marginRight: 4 }}
      >
        Remove
      </DangerButton>
      {status == 'MEMBER' ? (
        <SecondaryButton
          iconName="arrow-circle-up"
          onClick={grantAdmin}
          disabled={busy}
        >
          Grant admin
        </SecondaryButton>
      ) : (
        <SecondaryButton
          iconName="arrow-circle-down"
          onClick={revokeAdmin}
          disabled={busy}
        >
          Revoke admin
        </SecondaryButton>
      )}
    </>
  );
};

const OrganizationMembers = ({ organizationId }: Props) => {
  const {
    data,
    loadMore,
    error,
    loading,
    variables,
    setVariable,
  } = useCollectionQuery<
    AdminOrganizationMembersQuery,
    AdminOrganizationMembersQueryVariables
  >({
    query: GET_MEMBERS,
    variables: { organizationId },
    path: ['organization', 'memberships'],
  });
  const collection = idx(data, (_) => _.organization.memberships.nodes) || [];

  /* eslint-disable react/display-name */
  const columns: Columns<typeof collection> = [
    {
      heading: 'Name',
      primary: true,
      render: ({ member: { dbId, name } }) => (
        <Link href={`/admin/reviewers/${dbId}`}>{name}</Link>
      ),
    },
    {
      heading: 'Type',
      render: ({ status }) => status.toLowerCase(),
    },
    {
      heading: 'Member since',
      render: ({ memberSince }) => (
        <>
          <FormattedDate date={memberSince} format="relative" /> ago
        </>
      ),
    },
    {
      heading: '',
      render: ({ status, member: { id: memberId } }) => (
        <Actions
          organizationId={organizationId}
          memberId={memberId}
          status={status}
        />
      ),
    },
  ];
  /* eslint-enable react/display-name */

  return (
    <DataWithControls
      actions={
        <SearchInput
          defaultValue={idx(variables, (_) => _.filter.search) || ''}
          onSearch={(value) => setVariable(['filter', 'search'], value)}
          placeholder="Find a member"
        />
      }
    >
      <DataTable
        collection={collection}
        columns={columns}
        loadMore={loadMore}
        error={error}
        loading={loading}
      />
    </DataWithControls>
  );
};

export default OrganizationMembers;
