import React, { useState } from 'react';
import ConfirmationModal from 'components/ConfirmationModal';
import { DangerButton } from 'system/base/Button';
import Space from 'system/utility/Space';

interface Props {
  isCurrentUser: boolean;
  authorName: string;
  organizationName: string;
  revokeMembership: () => void;
  busy: boolean;
}

const Member = ({
  isCurrentUser,
  authorName,
  organizationName,
  revokeMembership,
  busy,
}: Props) => {
  const [modalOpen, setModalOpen] = useState(false);

  let modal: React.ReactNode = '';
  if (modalOpen) {
    const onConfirm = () => {
      setModalOpen(false);
      revokeMembership();
    };
    modal = (
      <ConfirmationModal
        title="Revoke Membership"
        confirmationLabel="Revoke Membership"
        onConfirm={onConfirm}
        onClose={() => setModalOpen(false)}
      >
        <p>
          Are you sure you wish to revoke the membership of{' '}
          <strong>{authorName}</strong>? This will remove the ability to create
          reviews on the <strong>{organizationName}</strong> subscription.
        </p>
      </ConfirmationModal>
    );
  }

  return (
    <>
      <p>
        {authorName} is a member and can create reviews on the{' '}
        {organizationName} subscription.
      </p>
      {isCurrentUser || (
        <Space my={2}>
          <DangerButton onClick={() => setModalOpen(true)} disabled={busy}>
            Revoke Membership
          </DangerButton>
        </Space>
      )}
      {modal}
    </>
  );
};

export default Member;
