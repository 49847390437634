import { TableData, Header, RowData } from '../types';

/**
 * getReactTableData prepares react-table-friendly data.
 *
 * @param data Cell data
 * @param rowHeaders If there are rowHeaders, add them to data so they row
 * labels are rendered even for empty cells.
 * @returns react-table-friendly data to render tables
 */
export const getReactTableData = (
  data: TableData,
  rowHeaders: Header[]
): TableData => {
  if (!rowHeaders.length) return data;

  const dataWithRowLabels: TableData = rowHeaders.map(({ id }, index) => {
    const rowData = data.find((rowData) => rowData.rowID === id);

    if (rowData) return rowData;

    const emptyRowData: RowData = {
      rowID: id,
      rowIndex: index,
      columns: [],
    };

    return emptyRowData;
  });

  return dataWithRowLabels;
};
