import React from 'react';
import TextField from 'system/elements/TextField';

interface OtherStudyLevelTextFieldProps {
  objectName: string;
  defaultOtherStudyLevel: string;
}

export const OtherStudyLevelTextField = ({
  defaultOtherStudyLevel,
  objectName,
}: OtherStudyLevelTextFieldProps) => (
  <div className="input form-group">
    <TextField
      defaultValue={defaultOtherStudyLevel}
      // form-control used here to provide consistent outline on this field to other Signup form fields
      inputClassName="form-control"
      name={`${objectName}[study_level_other]`}
      label="Please specify your level of study:"
    ></TextField>
  </div>
);
