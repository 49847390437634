import React, { ReactNode } from 'react';
import classNames from 'classnames';
import styles from './ModalFooter.module.scss';

interface ModalFooterProps {
  children: ReactNode;
  className?: string;
}

const ModalFooter = ({ children, className }: ModalFooterProps) => {
  return (
    <footer className={classNames(className, styles.ModalFooter)}>
      {children}
    </footer>
  );
};

export default ModalFooter;
