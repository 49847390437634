import React from 'react';
import { DomainBlock } from '../DomainBlock';
import { InstructionsToExtractors } from '../../shared/InstructionsToExtractors';

export interface DomainEditorSettingsProps {
  block: DomainBlock;
  onChange: (updatedBlock: DomainBlock) => void;
}

export function DomainEditorSettings({
  block,
  onChange,
}: DomainEditorSettingsProps): JSX.Element {
  return (
    <>
      <InstructionsToExtractors block={block} onChange={onChange} />
    </>
  );
}
