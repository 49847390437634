import React, { useState } from 'react';
import { CommentIcon } from '../Icons/Comment';
import { Note as NoteProps } from './StudyTypes';
import styles from './NotesModal.module.css';
import RemoveNoteButton from './RemoveNoteButton';
import EditNoteButton from './EditNoteButton';
import { SecondaryButton, NakedButton } from 'system/base/Button';
import Textbox from 'system/base/Textbox';

interface NotePropsWithUser extends NoteProps {
  currentUserId: string;
  onSuccess: () => void;
  onFailure: () => void;
}

export default function Note({
  id,
  createdAt,
  updatedAt,
  author,
  note,
  currentUserId,
  onSuccess,
  onFailure,
}: NotePropsWithUser) {
  const [editNote, setEditNote] = useState(false);
  const [editNoteContent, setEditNoteContent] = useState('');
  const [text, setText] = useState(note);

  const noteFormOrNote = () => {
    if (editNote) {
      return (
        <Textbox
          className={styles.noteEditForm}
          value={editNoteContent}
          data-testid="edit-note-textbox"
          onChange={(e: any) => {
            setEditNoteContent(e.target.value);
          }}
        ></Textbox>
      );
    } else {
      return <div className={styles.noteContent}>{text}</div>;
    }
  };

  const createdAtOrUpdatedAt = () => {
    if (createdAt === updatedAt) {
      return `${createdAt}`;
    } else {
      return `${updatedAt} EDITED`;
    }
  };

  const editSaveOrDeleteButtons = () => {
    if (editNote) {
      return (
        <div>
          <EditNoteButton
            id={id}
            content={editNoteContent}
            onSuccess={() => {
              setText(editNoteContent);
              setEditNote(!editNote);
              onSuccess();
            }}
            onFailure={onFailure}
          />
          <NakedButton onClick={() => setEditNote(!editNote)}>
            Cancel
          </NakedButton>
        </div>
      );
    } else {
      return (
        <div>
          <SecondaryButton
            className={styles.editButton}
            onClick={() => {
              setEditNote(!editNote);
              setEditNoteContent(note);
            }}
          >
            Edit
          </SecondaryButton>
          <RemoveNoteButton
            id={id}
            onSuccess={onSuccess}
            onFailure={onFailure}
          />
        </div>
      );
    }
  };
  const renderActionButtons = () => {
    if (author.id == currentUserId) {
      return <div className={styles.actions}>{editSaveOrDeleteButtons()} </div>;
    } else {
      return <div className={styles.actions}></div>;
    }
  };

  return (
    <div key={id} className={styles.note}>
      <div className={styles.details}>
        <CommentIcon />
        <div className={styles.authorName}>{author.name.toUpperCase()}</div>
        <div className={styles.timestamp}>{createdAtOrUpdatedAt()}</div>
      </div>
      {noteFormOrNote()}
      {renderActionButtons()}
    </div>
  );
}
