import React, { useRef, useImperativeHandle } from 'react';
import styles from './Avatar.module.scss';

interface Props {
  /**
   * Name of the person or organisation the Avatar belongs to
   */
  name: string;
  /**
   * Named string to override randomized color
   */
  color?: 'green' | 'yellow' | 'grey' | 'teal';
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  className?: string;
}

// Doubling up colours to create placeholders slots for additional future colours without shifting *everyone's* colors
const AVATAR_COLORS = [
  styles.grey,
  styles.grey,
  styles.green,
  styles.green,
  styles.yellow,
  styles.yellow,
  styles.teal,
  styles.teal,
];

export function chooseAvatarColor(name: string) {
  let hash = 0;
  for (let i = 0; i < name.length; i++) {
    hash += name.charCodeAt(i);
  }

  return AVATAR_COLORS[hash % AVATAR_COLORS.length];
}

const Avatar = (
  { name, className, size = 'md', color }: Props,
  ref: React.ForwardedRef<any>
) => {
  const colorClass = color ? styles[color] : chooseAvatarColor(name);
  const classes = [styles.Avatar, styles[size], colorClass];
  if (className) classes.push(className);

  const forwardedRef = useRef<HTMLSpanElement>(null);
  useImperativeHandle(ref, () => forwardedRef.current);

  const initials = (name: string) => {
    const splitName = name.trim().split(' ');

    if (splitName.length >= 2) {
      // use fist and last name to as initials
      return splitName[0][0] + splitName[splitName.length - 1][0];
    }

    if (splitName.length == 1) {
      // use the first character of the first name
      return splitName[0][0];
    }
  };

  return (
    <span ref={forwardedRef} className={classes.join(' ')} data-testid="Avatar">
      <div role="img" aria-label={`${name}`}>
        {initials(name)}
      </div>
    </span>
  );
};

const forwardedAvatar = React.forwardRef<any, Props>(Avatar);
export default forwardedAvatar;
export { forwardedAvatar as Avatar };
