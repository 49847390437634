import React, { useState } from 'react';
import { Select, FormControl } from 'components/core';

export interface Props {
  name: string;
  options: string[];
  error?: string;
}

const CitationImportSourceDropDown = ({ name, options, error }: Props) => {
  const [value, setValue] = useState<string>('');

  const handleOnChange = (e: React.FormEvent<HTMLSelectElement>) => {
    setValue(e.currentTarget.value);
  };

  return (
    <FormControl error={error}>
      <Select
        value={value}
        name={name}
        onChange={handleOnChange}
        data-testid="citationSourcesDropdown"
      >
        <option value="">Select source...</option>
        {options.map((source, index) => (
          <option key={index} value={source}>
            {source}
          </option>
        ))}
      </Select>
    </FormControl>
  );
};

export default CitationImportSourceDropDown;
