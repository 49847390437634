import { useMemo } from 'react';
import { v4 as uuid } from 'uuid';

function useUniqueID(): string {
  const uniqueID = useMemo(uuid, [uuid]);
  return `uuid-${uniqueID}`;
}

export function useLabelledBy(): readonly [
  string,
  { 'aria-labelledby': string }
] {
  const uniqueID = useUniqueID();

  return [uniqueID, { 'aria-labelledby': uniqueID }];
}

export function useDescribedBy(): readonly [
  string,
  { 'aria-describedby': string }
] {
  const uniqueID = useUniqueID();

  return [uniqueID, { 'aria-describedby': uniqueID }];
}

export function useControls(): readonly [string, { 'aria-controls': string }] {
  const uniqueID = useUniqueID();

  return [uniqueID, { 'aria-controls': uniqueID }];
}
