interface WindowLocation {
  href: string;
  assign: (url: string | URL) => void;
}

export const searchStudies = (
  term: string,
  searchUrl?: string,
  location?: WindowLocation
) => {
  if (!searchUrl) return;

  const actualLocation = location || window.location;
  const currentUrl = new URL(searchUrl, actualLocation.href);
  const params = new URLSearchParams(currentUrl.search);
  params.set('search[term]', term);

  actualLocation.assign(currentUrl.pathname + '?' + params.toString());
};
