import React from 'react';
import styles from './StudyListExtractionMessage.module.scss';
import {
  Text,
  Message,
  IconList,
  MessageControls,
  Button,
  Heading,
} from 'components/core';

interface props {
  studiesInExtraction: number;
  extractionHelpUrl: string;
  startExtractionUrl: string;
}

const acknowledgementKey = 'de-hide-extraction-prompt';
const acknowledgedValue = '1';

const getAcknowledgementState = () => {
  return sessionStorage.getItem(acknowledgementKey);
};

const setAcknowledgementState = () => {
  sessionStorage.setItem(acknowledgementKey, acknowledgedValue);
};

const StudyListExtractionMessage = ({
  studiesInExtraction,
  extractionHelpUrl,
  startExtractionUrl,
}: props) => {
  const showMessage =
    studiesInExtraction > 0 && getAcknowledgementState() != acknowledgedValue;

  const hideMessage = () => {
    setAcknowledgementState();
  };

  const messageHeadingText = () => {
    return `Ready to extract data from ${studiesInExtraction} ${
      studiesInExtraction > 1 ? 'studies' : 'study'
    }?`;
  };

  return (
    <>
      {showMessage && (
        <Message onClose={hideMessage} icon={IconList.solid.faFiles}>
          <Heading type="h5" className={styles.heading}>
            {messageHeadingText()}
          </Heading>
          <Text>
            Use our Extraction 1 tool, designed around the PICO framework to
            guide extraction. It increases accuracy and consistency for ease of
            meta-analysis.
          </Text>
          <MessageControls>
            <Button type="brand" to={startExtractionUrl}>
              Start extraction
            </Button>
            <Button variant="ghost" to={extractionHelpUrl} target={'_blank'}>
              Learn more
            </Button>
          </MessageControls>
        </Message>
      )}
    </>
  );
};

export default StudyListExtractionMessage;
