import React, { ReactNode, isValidElement } from 'react';
import { usePopoverContext } from '../../Popover';

interface PopoverTriggerProps {
  children: ReactNode;
}

export const PopoverTrigger = ({ children, ...props }: PopoverTriggerProps) => {
  const context = usePopoverContext();

  if (!isValidElement(children)) {
    throw new Error(
      'Invalid popover trigger, please pass a valid element such as a button'
    );
  }

  return React.cloneElement(
    children,
    context.getReferenceProps({
      ref: context.refs.setReference,
      ...props,
      ...children.props,
      'data-state': context.open ? 'open' : 'closed',
    })
  );
};
