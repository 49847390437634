import React from 'react';
import cn from 'classnames';
import styles from './SearchBar.module.scss';
import { Icon, IconList } from 'components/core/Icon';

export type SearchBarProps = {
  onSearch?: (term: string) => void;
  className?: string;
};

export const SearchBar = (props: SearchBarProps) => {
  const { onSearch, className } = props;

  return (
    <div className={cn(styles.container, className)}>
      <div className={styles.iconContainer}>
        <Icon icon={IconList.light.faMagnifyingGlass} className={styles.icon} />
      </div>
      <input
        className={styles.searchBar}
        type="text"
        placeholder="Search studies"
        onKeyDown={(event) => {
          if (event.key !== 'Enter') {
            return;
          }

          const value = (event.target as HTMLInputElement).value;
          const hasChars = value.length != 0;
          onSearch && hasChars && onSearch(value);
        }}
      />
    </div>
  );
};
