import React from 'react';
import PropTypes from 'prop-types';

import { useEffectMeasureModel } from './state';

import { PrimaryButton, NakedButton } from 'system/base/Button';
import Errors from 'components/Errors';
import Field from 'components/NewEffectMeasureField';
import Modal from 'components/Modal';

const title = <h2 className="modal--title">New effect measure</h2>;

export default function NewEffectMeasure(props) {
  const [{ creating, dataTypes, errors }, actions] = useEffectMeasureModel();

  function addDataType(e) {
    e.preventDefault();
    actions.addDataType();
  }

  function createDataTypes(e) {
    e.preventDefault();

    actions.createDataTypes(
      props.outcomeType,
      props.reviewId,
      dataTypes,
      props.onSave
    );
  }

  // Ensure that there is always at least one field row visible
  const canRemoveField = dataTypes.length > 1;

  const renderFields = dataTypes.map((field, index) => (
    <Field
      key={index}
      field={field}
      onRemove={
        canRemoveField ? (data) => actions.removeDataType(index, data) : null
      }
      onUpdate={(data) => actions.updateDataType(index, data)}
    />
  ));

  const footer = (
    <div>
      <PrimaryButton onClick={createDataTypes}>
        {creating ? 'Saving...' : 'Save'}
      </PrimaryButton>

      <NakedButton onClick={props.onCancel}>Cancel</NakedButton>
    </div>
  );

  /* eslint-disable jsx-a11y/anchor-is-valid */
  return (
    <form onSubmit={createDataTypes}>
      <Modal
        title={title}
        footer={footer}
        onHideModal={props.onCancel}
        className=""
      >
        <div className="alert alert-warning">
          Psst, just so you know, Covidence can’t export custom effect measures
          to RevMan just yet but your data can be still be exported to CSV/Excel
          format.
        </div>

        <Errors
          errors={errors}
          title="Your new effect measure has not been saved"
        />
        <table className="table table-striped">
          <caption>Enter labels for the variables you want to record</caption>

          <thead>
            <tr>
              <th>Label</th>
              <th>Format</th>
              <th />
            </tr>
          </thead>

          <tbody>{renderFields}</tbody>
        </table>

        <br />

        <p className="text-left">
          <button
            onClick={addDataType}
            className="btn btn-default new_effect_measure--new-variable"
          >
            <i
              className="fas fa-plus-circle new_effect_measure--new-variable--icon"
              aria-hidden="true"
            />
            Add label
          </button>
        </p>
      </Modal>
    </form>
  );
  /* eslint-enable jsx-a11y/anchor-is-valid */
}

NewEffectMeasure.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  outcomeType: PropTypes.string.isRequired,
  reviewId: PropTypes.number.isRequired,
};
