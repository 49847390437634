import {
  withRequestRetry,
  defaultFetchOptions,
  _handleJsonResponse,
  RequestErrorHandler,
  OfflineError,
  CheckExistingExtractionDataResponse,
} from './DataExtraction';
import {
  CREATE_STUDY_ARM_ROUTE,
  STUDY_ARM_ROUTE,
  CHECK_INTERVENTION_EXTRACTED_DATA_ROUTE,
  GET_ARMS_EXISTING_EXTRACTION_ROUTE,
  UPDATE_STUDY_ARM_POSITIONS_ROUTE,
  tokenizeRoute,
} from 'query/routes';
import { Arm } from 'types/DataExtraction';

export interface ArmCreatedResponse {
  id: string;
  intervention_id: string;
  intervention_group?: string;
}

export type ArmPromise = Promise<Arm | void>;

export const createStudyArm = async (
  reviewStudyId: string,
  interventionId: string,
  interventionGroup?: string | null,
  onError?: RequestErrorHandler
): Promise<ArmCreatedResponse> => {
  return withRequestRetry(
    () =>
      _createStudyArmRequest(reviewStudyId, interventionId, interventionGroup),
    onError
  );
};

const _createStudyArmRequest = async (
  reviewStudyId: string,
  interventionId: string,
  interventionGroup?: string | null
): Promise<ArmCreatedResponse> => {
  if (!navigator.onLine)
    throw new OfflineError('No network connection detected');

  const url = tokenizeRoute(CREATE_STUDY_ARM_ROUTE, {
    review_study_id: reviewStudyId,
  });

  const body: any = {
    intervention_id: interventionId,
  };

  if (interventionGroup) {
    body.intervention_group = interventionGroup;
  }

  return fetch(url.toString(), {
    method: 'POST',
    body: JSON.stringify(body),
    ...defaultFetchOptions(),
  }).then((response) => {
    return _handleJsonResponse(response);
  });
};

export const destroyStudyArm = async (
  armId: string,
  onError?: RequestErrorHandler
): Promise<Response> => {
  return withRequestRetry(() => _destroyStudyArm(armId), onError);
};

const _destroyStudyArm = async (armId: string): Promise<Response> => {
  if (!navigator.onLine)
    throw new OfflineError('No network connection detected');

  const url = tokenizeRoute(STUDY_ARM_ROUTE, {
    arm_id: armId,
  });

  return fetch(url.toString(), {
    method: 'DELETE',
    ...defaultFetchOptions(),
  }).then((response) => {
    return _handleJsonResponse(response);
  });
};

export const checkInterventionExtractedStatus = async (
  reviewStudyId: string,
  interventionId: string,
  onError?: RequestErrorHandler
): Promise<CheckExistingExtractionDataResponse> => {
  return withRequestRetry(
    () =>
      _checkInterventionExtractedStatusRequest(reviewStudyId, interventionId),
    onError
  );
};

const _checkInterventionExtractedStatusRequest = async (
  reviewStudyId: string,
  interventionId: string
): Promise<CheckExistingExtractionDataResponse> => {
  const url = tokenizeRoute(CHECK_INTERVENTION_EXTRACTED_DATA_ROUTE, {
    review_study_id: reviewStudyId,
    intervention_id: interventionId,
  });

  return fetch(url.toString(), {
    method: 'GET',
    ...defaultFetchOptions(),
  }).then((response) => {
    return _handleJsonResponse(response);
  });
};

export const getArmHasExtractions = async (
  armId: string,
  onError?: RequestErrorHandler
): Promise<CheckExistingExtractionDataResponse> => {
  return withRequestRetry(() => _getArmHasExtractions(armId), onError);
};

const _getArmHasExtractions = async (
  armId: string
): Promise<CheckExistingExtractionDataResponse> => {
  const url = tokenizeRoute(GET_ARMS_EXISTING_EXTRACTION_ROUTE, {
    arm_id: armId,
  });

  return fetch(url.toString(), {
    method: 'GET',
    ...defaultFetchOptions(),
  }).then((response) => {
    return _handleJsonResponse(response);
  });
};

export interface ArmPositionUpdatedResponse {
  id: string;
  position: number;
}

export const updateArmPositions = (reviewStudyId: string, arms: Arm[]) => {
  const url = tokenizeRoute(UPDATE_STUDY_ARM_POSITIONS_ROUTE, {
    review_study_id: reviewStudyId,
  });

  return fetch(url.toString(), {
    method: 'PUT',
    body: JSON.stringify({
      positions: arms.map((arm) => arm.id),
    }),
    ...defaultFetchOptions(),
  }).then((response) => {
    return response.json();
  });
};
