import React from 'react';
import Icon, { IconStyle } from '../../../../system/base/Icon';
import styles from './ImportResults.module.css';
import { PrimaryLink } from 'system/base/Link';

interface Props {
  uploadedFiles: number;
  totalFiles: number;
  onCompletePath: string;
}

const successIcon = (
  <Icon
    name={'check-circle'}
    iconStyle={IconStyle.Regular}
    className={styles.ImportResults__SuccessIcon}
  />
);

const warningIcon = (
  <Icon
    name={'exclamation-circle'}
    iconStyle={IconStyle.Regular}
    className={styles.ImportResults__WarningIcon}
  />
);

const ImportResults = ({
  uploadedFiles,
  totalFiles,
  onCompletePath,
}: Props) => {
  const filesToUpload = totalFiles - uploadedFiles;
  return (
    <>
      <section data-testid="ImportResults" className={styles.ImportResults}>
        <div className={styles.ImportResults__ResultsSection}>
          <h3 data-testid="ImportResults-uploaded">
            {successIcon}
            {uploadedFiles}
          </h3>
          <p>out of {totalFiles} PDFs uploaded</p>
        </div>
        {!!filesToUpload && (
          <div className={styles.ImportResults__ResultsSection}>
            <h3 data-testid="ImportResults-toUpload">
              {warningIcon}
              {filesToUpload}
            </h3>
            <p>out of {totalFiles} PDFs still to upload</p>
          </div>
        )}
      </section>
      {!filesToUpload && (
        <PrimaryLink
          data-testid="ImportResults-returnToScreeningButton"
          className={styles.ImportResults__returnToScreeningButton}
          href={onCompletePath}
        >
          Return to full text screening
        </PrimaryLink>
      )}
    </>
  );
};

export default ImportResults;
