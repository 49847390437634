import React, { useMemo } from 'react';
import Select from 'react-select';
import { Controller, useFormContext } from 'react-hook-form';
import styles from '../CitationSourcesForm.module.scss';
import { FormValues } from '../CitationSourcesForm';
import { Button, FormControl, Icon, IconList, Input } from 'components/core';
import { CitationImportSourceFormData } from 'types';

interface Props {
  sourceOptions: string[];
  usedSourceOptions: CitationImportSourceFormData[];
  rowIndex: number;
  removeRow: () => void;
}

export const CitationSourceFormRow = ({
  sourceOptions,
  usedSourceOptions,
  rowIndex,
  removeRow,
}: Props) => {
  const {
    register,
    control,
    trigger,
    formState: { errors },
  } = useFormContext<FormValues>();

  const usedSources = usedSourceOptions.map((source) => source.sourceName);
  const sourceOptionList = useMemo(() => {
    return sourceOptions.map((option) => ({
      label: option,
      value: option,
    }));
  }, [sourceOptions]);

  // Filter options based on whats used
  const filteredSourceOptions = useMemo(() => {
    return sourceOptionList.filter(
      (source) => !usedSources.includes(source.value)
    );
  }, [sourceOptionList, usedSources]);

  return (
    <>
      <div className={styles.CitationSourcesForm_formCol}>
        <Controller
          control={control}
          name={`citationImportSources.${rowIndex}.sourceName` as const}
          rules={{ required: 'Please select a source' }}
          render={({ field }) => {
            const error =
              errors.citationImportSources?.[rowIndex]?.sourceName?.message;
            // Need to pass through undefined when no default value, otherwise placeholder text does not show
            const currentSelection = sourceOptionList.find(
              (option) => option.value === field.value
            );
            return (
              <FormControl error={error}>
                <Select
                  {...field}
                  isSearchable
                  value={currentSelection}
                  placeholder="Select source..."
                  options={filteredSourceOptions}
                  onChange={(event) => field.onChange(event?.value)}
                  classNamePrefix="select"
                  aria-label="Import Citation Source Select"
                  className={[
                    styles.CitationSourcesForm_formSelect,
                    error
                      ? styles.CitationSourcesForm_formSelectError
                      : undefined,
                  ].join(' ')}
                />
              </FormControl>
            );
          }}
        />
      </div>

      <div className={styles.CitationSourcesForm_formCol}>
        <FormControl
          error={
            errors.citationImportSources?.[rowIndex]?.numberOfCitations?.message
          }
        >
          <Input
            className={styles.CitationSourcesForm_formInput}
            size="sm"
            type="neutral"
            inputType="number"
            min={0}
            aria-label="Number of references"
            isError={!!errors.citationImportSources?.root?.message}
            {...register(
              `citationImportSources.${rowIndex}.numberOfCitations` as const,
              {
                valueAsNumber: true,
                required: 'Please enter a count',
                onChange: () => {
                  // Manually retrigger the top level validation onChange
                  // May not be needed in future if we use a resolver validation schema
                  // Solution to use trigger found here https://github.com/react-hook-form/react-hook-form/issues/2369
                  trigger('citationImportSources');
                },
              }
            )}
          />
        </FormControl>
      </div>

      <div className={styles.CitationSourcesForm_removeButton}>
        <Button
          iconOnly
          htmlType="button"
          variant="ghost"
          aria-label="Remove citation source"
          onClick={removeRow}
        >
          <Icon icon={IconList.light.faTrashCan} />
        </Button>
      </div>
    </>
  );
};
