import React from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { TemplateBlock } from '../BaseBlock';
import { Label } from './BlockWidgets';
import styles from './InstructionsToExtractors.module.css';
import { InfoIcon } from 'system/base/Icons';

type BlockWithDescription = TemplateBlock & { description: string };
export interface InstructionsToExtractorsProps<
  Block extends BlockWithDescription
> {
  block: Block;
  onChange: (updatedBlock: Block) => void;
}

export function InstructionsToExtractors<Block extends BlockWithDescription>({
  block,
  onChange,
}: InstructionsToExtractorsProps<Block>): JSX.Element {
  return (
    <Label>
      <div className={styles.LabelTextContainer}>
        <InfoIcon />{' '}
        <span className={styles.LabelText}>Instructions to extractors</span>
      </div>
      <TextareaAutosize
        minRows={5}
        value={block.description}
        className={styles.Input}
        onChange={(event) => {
          const newDescription = event.target.value;
          onChange({ ...block, description: newDescription });
        }}
      />
    </Label>
  );
}
