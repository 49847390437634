import React from 'react';
import { EditorTextbox } from '../../../FormBuilder/Widgets/EditorTextbox';
import styles from './SubheadingEditor.module.css';
import {
  SubheadingBlock,
  updateLabel,
} from 'concepts/Extraction/Blocks/Subheading/SubheadingBlock';
import { TemplateBlock } from 'concepts/Extraction/Blocks/BaseBlock';

export interface SubheadingEditorProps {
  block: SubheadingBlock;
  placeholder?: string;
  onChange: (updatedSubheadingBlock: TemplateBlock) => void;
  onComplete: () => void;
  onRemove: () => void;
}

export default function SubheadingEditor({
  block,
  onChange,
  onRemove,
  onComplete,
  placeholder,
}: SubheadingEditorProps): JSX.Element {
  return (
    <div className={styles.container}>
      <EditorTextbox
        value={block.label}
        placeholder={placeholder || 'Subheading'}
        onChange={(event) => {
          const newLabel = event.target.value;
          onChange(updateLabel(block, newLabel));
        }}
        onKeyDown={(event) => {
          switch (event.key) {
            case 'Backspace':
              if (block.label == '') {
                event.preventDefault();
                onRemove();
              }
              break;
            case 'Enter':
              event.preventDefault();
              onComplete();
          }
        }}
      />
    </div>
  );
}
