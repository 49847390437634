import React, { useState, useRef } from 'react';
import Popover from '@reach/popover';
import { PRect } from '@reach/rect';
import { VisuallyHidden } from '@reach/visually-hidden';
import { Label, TextInput } from './BlockWidgets';
import styles from './Comment.module.css';
import { useLabelledBy } from 'hooks/useAccessibleName';
import { NakedButton, PrimaryButton } from 'system/base/Button';

const emptyCommentsPath = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M4 2H20C21.1 2 22 2.9 22 4V16C22 17.1 21.1 18 20 18H6L2 22V4C2 2.9 2.9 2 4 2ZM6 16H19C19.55 16 20 15.55 20 15V5C20 4.45 19.55 4 19 4H5C4.45 4 4 4.45 4 5V18L6 16Z"
    fill="black"
    fillOpacity="0.54"
  />
);

const filledCommentsPath = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M4 2H20C21.1 2 22 2.9 22 4V16C22 17.1 21.1 18 20 18H6L2 22L2.01 4C2.01 2.9 2.9 2 4 2ZM17 9H7C6.45 9 6 9.45 6 10C6 10.55 6.45 11 7 11H17C17.55 11 18 10.55 18 10C18 9.45 17.55 9 17 9ZM13 14H7C6.45 14 6 13.55 6 13C6 12.45 6.45 12 7 12H13C13.55 12 14 12.45 14 13C14 13.55 13.55 14 13 14ZM7 8H17C17.55 8 18 7.55 18 7C18 6.45 17.55 6 17 6H7C6.45 6 6 6.45 6 7C6 7.55 6.45 8 7 8Z"
    fill="black"
    fillOpacity="0.54"
  />
);

interface CommentIconProps {
  filledOut: boolean;
  blockLabel: string;
}
function CommentIcon({ filledOut, blockLabel }: CommentIconProps): JSX.Element {
  const title = filledOut
    ? `Edit comments for ${blockLabel}`
    : `Add comments for ${blockLabel}`;
  const [labelID, labelledBy] = useLabelledBy();

  return (
    <svg
      role="img"
      {...labelledBy}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title id={labelID}>{title}</title>
      {filledOut ? filledCommentsPath : emptyCommentsPath}
    </svg>
  );
}

const positionRight = (
  targetRect?: PRect | null,
  popoverRect?: PRect | null
) => {
  if (!targetRect || !popoverRect) {
    return {};
  }

  const xSpacing = 16;
  const verticalOffset = (popoverRect.height - targetRect.height) / 2.0;

  return {
    left: `${
      targetRect.left - popoverRect.width + window.pageXOffset - xSpacing
    }px`,
    top: `${targetRect.top - verticalOffset + window.pageYOffset}px`,
  };
};

export interface CommentButtonProps {
  comments: string | null;
  blockLabel: string;
  onChangeComments: (newComments: string) => void;
}
export function CommentButton({
  comments,
  blockLabel,
  onChangeComments,
}: CommentButtonProps) {
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const [commentsExpanded, updateCommentsExpanded] = useState(false);

  function renderCommentsInput(): JSX.Element {
    return (
      <Popover
        targetRef={buttonRef}
        position={positionRight}
        className={styles.commentsPopover}
      >
        <div
          ref={(el) => {
            if (!el) {
              return;
            }

            // Focus on the textbox when this popover is opened.
            // I tried _many_ other ways and this is the only one that worked.
            requestAnimationFrame(() => {
              el.querySelector('textarea')?.focus();
            });
          }}
          className={styles.labelWrapper}
        >
          <Label>
            <VisuallyHidden>{`Add a comment…`}</VisuallyHidden>
            <TextInput
              className={styles.commentsTextbox}
              placeholder="Add a comment…"
              value={comments || ''}
              onChange={(event) => {
                onChangeComments(event.target.value);
              }}
              onBlur={() => {
                updateCommentsExpanded(false);
              }}
            />
          </Label>
        </div>
        <PrimaryButton
          onClick={() => {
            updateCommentsExpanded(false);
          }}
        >
          Save
        </PrimaryButton>
      </Popover>
    );
  }

  return (
    <>
      <NakedButton
        tabIndex={-1}
        ref={buttonRef}
        onClick={() => {
          updateCommentsExpanded((commentsExpanded) => !commentsExpanded);
        }}
      >
        <CommentIcon
          filledOut={(comments || '').length > 0}
          blockLabel={blockLabel}
        />
      </NakedButton>
      {commentsExpanded ? renderCommentsInput() : null}
    </>
  );
}
