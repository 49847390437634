import React from 'react';

import { Tabs, TabList, Tab, TabPanels, TabPanel } from '@reach/tabs';
import { ExtractionFormType } from '../ExtractionFormType';
import styles from './ExtractionTabs.module.css';

export interface ExtractionTabsProps {
  selected: ExtractionFormType;
  renderPanel: (type: ExtractionFormType) => JSX.Element | null;
  renderBadge?: (type: ExtractionFormType) => React.ReactElement | null;
  onSelect: (type: ExtractionFormType) => void;
}

const tabs = ['data_extraction', 'quality_assessment'] as const;

export function ExtractionTabs({
  selected,
  renderPanel,
  renderBadge,
  onSelect,
}: ExtractionTabsProps): JSX.Element {
  const index = tabs.indexOf(selected);

  const qaContent = renderPanel('quality_assessment');
  const deContent = renderPanel('data_extraction');

  return (
    <Tabs
      index={index}
      onChange={(index) => {
        const selectedTab = tabs[index];
        onSelect(selectedTab);
      }}
      className={styles.tabs}
    >
      <TabList className={styles.tabList}>
        {deContent ? (
          <Tab
            className={styles.tab}
            aria-describedby={`ExtractionTabs-data_extraction-badge`}
          >
            Data Extraction
            {renderBadge && renderBadge('data_extraction')}
          </Tab>
        ) : (
          ''
        )}
        {qaContent ? (
          <Tab
            className={styles.tab}
            aria-describedby={`ExtractionTabs-quality_assessment-badge`}
          >
            Quality Assessment
            {renderBadge && renderBadge('quality_assessment')}
          </Tab>
        ) : (
          ''
        )}
      </TabList>

      <TabPanels>
        {deContent ? (
          <TabPanel className={styles.tabPanel}>{deContent}</TabPanel>
        ) : (
          ''
        )}
        {qaContent ? (
          <TabPanel className={styles.tabPanel}>{qaContent}</TabPanel>
        ) : (
          ''
        )}
      </TabPanels>
    </Tabs>
  );
}
