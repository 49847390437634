import React from 'react';

import Action from './Action';
import { Stage } from './Home';
import { Permissions } from './Home';
import styles from './StageIncomplete.module.css';

export interface StageIncompleteProps {
  reviewerName: string;
  available: number;
  consensusRequired: number;
  contribution: number;
  stage: Stage;
  permissions: Permissions;
  studyCategoryLink: string;
}

interface ConsensusRequiredAction {
  title: string;
  text: string;
  filter?: 'votes_in_conflict' | 'comparison_required';
  dataTestId?: string;
}

const StageIncomplete = ({
  stage,
  reviewerName,
  consensusRequired,
  available,
  contribution,
  permissions,
  studyCategoryLink,
}: StageIncompleteProps) => {
  let actionStyle;
  if (consensusRequired > 0 && available > 0) {
    actionStyle = styles.twoActions;
  } else {
    actionStyle = styles.oneAction;
  }

  const studyCategoryLinkWithFilter = (filter: string) => {
    if (!studyCategoryLink) {
      return '';
    }
    const url = new URL(studyCategoryLink);
    url.searchParams.set('filter', filter);
    return url.toString();
  };

  const ConsensusRequiredAction = ({
    filter = 'votes_in_conflict',
    ...props
  }: ConsensusRequiredAction) => {
    return (
      <Action
        {...props}
        stage={stage}
        type="tertiary"
        url={studyCategoryLinkWithFilter(filter)}
        count={consensusRequired}
      />
    );
  };

  const renderAvailableAction = (available: number, title: string) => {
    if (available === 0) {
      return;
    }

    let url: string;
    if (stage === 'included') {
      url = studyCategoryLink;
    } else {
      url = studyCategoryLinkWithFilter('vote_required_from');
    }

    return (
      <Action
        stage={stage}
        type="primary"
        url={url}
        count={available}
        title={title}
        text="Continue"
      />
    );
  };

  const renderExtractionConsensusRequiredAction = () => {
    if (consensusRequired === 0) {
      return;
    }

    return (
      <ConsensusRequiredAction
        title="check"
        text={'Continue'}
        filter="comparison_required"
        dataTestId="consensus-required-action"
      />
    );
  };

  const renderConsensusRequiredAction = () => {
    if (consensusRequired === 0) {
      return;
    }

    if (permissions.consensusRequired) {
      return (
        <ConsensusRequiredAction title="resolve" text="Resolve conflicts" />
      );
    }

    return <ConsensusRequiredAction title="view" text="View conflicts" />;
  };

  const renderActions = () => {
    let actions: JSX.Element;
    let title = 'Screen';
    switch (stage) {
      case 'screen':
      case 'select':
        actions = (
          <>
            {renderConsensusRequiredAction()}
            {renderAvailableAction(available, title)}
          </>
        );
        break;
      case 'included':
        title = 'Extract';
        actions = (
          <>
            {renderExtractionConsensusRequiredAction()}
            {renderAvailableAction(available, title)}
          </>
        );
        break;
    }

    return actions;
  };

  const action = stage == 'included' ? 'extracted' : 'screened';
  const contributionCount = contribution == 1 ? 'study' : 'studies';

  return (
    <div className={styles.reviewerProgress}>
      <div className={styles.header}>
        <div>{reviewerName},</div> you can still
      </div>
      <div className={`${actionStyle} ${styles.actions}`}>
        {renderActions()}
      </div>
      <div className={styles.contribution}>
        <span className={`ss-barchart chart ${styles.barChart}`}></span>
        You&apos;ve {action} <strong>{contribution}</strong> {contributionCount}{' '}
        so far
      </div>
    </div>
  );
};

export default StageIncomplete;
