import { gql } from '@apollo/client';

const REVOKE_MEMBERSHIP = gql`
  mutation revokeMembership($organizationId: ID!, $memberId: ID!) {
    revokeMembership(
      input: { organizationId: $organizationId, memberId: $memberId }
    ) {
      success
      errors {
        message
      }

      membership {
        id
        status
        memberSince
      }
    }
  }
`;

export default REVOKE_MEMBERSHIP;
