import React from 'react';
import { NakedButton } from '../../../system/base/Button';
import { FileInfo } from '../index';

interface Props {
  file: FileInfo;
  deleteFile: (fileId: string, deleteFromBackend: boolean) => void;
  unsetDocumentUrl: () => void;
}

const DeleteFileButton = ({ file, deleteFile, unsetDocumentUrl }: Props) => (
  <NakedButton
    onClick={() => {
      switch (file.type) {
        case 's3_file':
          deleteFile(file.id, file.uploadComplete || false);
          break;
        case 'link':
          unsetDocumentUrl();
      }
    }}
    iconName="times"
    iconOnly
  >
    Remove the file
  </NakedButton>
);

export default DeleteFileButton;
