import React from 'react';
import { DropdownSelectorProps, DropdownSelector } from './DropdownSelector';

type ResearchAreaValue =
  | 'arts_and_humanities'
  | 'agricultural_veterinary_and_food_sciences'
  | 'biological_and_chemical_sciences'
  | 'earth_and_environmental_sciences'
  | 'economic_business_and_social_sciences'
  | 'education'
  | 'engineering_maths_physics_and_technology'
  | 'medical_and_health_sciences'
  | 'psychology'
  | 'other';

export type ResearchAreaOption = { value: ResearchAreaValue; label: string };

export const researchAreaOptions: ResearchAreaOption[] = [
  { value: 'arts_and_humanities', label: 'Arts and humanities' },
  {
    value: 'agricultural_veterinary_and_food_sciences',
    label: 'Agricultural, veterinary and food sciences',
  },
  {
    value: 'biological_and_chemical_sciences',
    label: 'Biological and chemical sciences',
  },
  {
    value: 'earth_and_environmental_sciences',
    label: 'Earth and environmental sciences',
  },
  {
    value: 'economic_business_and_social_sciences',
    label: 'Economic, business and social sciences',
  },
  { value: 'education', label: 'Education' },
  {
    value: 'engineering_maths_physics_and_technology',
    label: 'Engineering, maths, physics and technology',
  },
  {
    value: 'medical_and_health_sciences',
    label: 'Medical and health sciences',
  },
  { value: 'psychology', label: 'Psychology' },
  { value: 'other', label: 'Other' },
];

export interface ResearchAreaSelectorProps {
  selectedArea: DropdownSelectorProps<ResearchAreaOption>['selectedOption'];
  setArea: React.Dispatch<React.SetStateAction<ResearchAreaOption | null>>;
  otherAreaLabel: string | null;
  setOtherAreaLabel: React.Dispatch<React.SetStateAction<string | null>>;
}

export const ResearchAreaSelector = ({
  selectedArea,
  setArea,
  otherAreaLabel,
  setOtherAreaLabel,
  ...otherProps
}: ResearchAreaSelectorProps) => (
  <DropdownSelector<ResearchAreaOption>
    options={researchAreaOptions}
    selectedOption={selectedArea}
    setOption={setArea}
    name="research-area"
    label="Area of research"
    missingError="Please select the area of research"
    otherLabel={{
      value: otherAreaLabel,
      setOtherLabel: setOtherAreaLabel,
      label: 'Please specify the area of research:',
      missingError: 'Please name the area of research',
    }}
    {...otherProps}
  />
);
