import React from 'react';
import classnames from 'classnames';
import styles from './CompactPagination.module.scss';
import { Button, Icon, IconList, Input, Text } from 'components/core';

type extraClasses = {
  box?: string;
};

export type CompactPaginationProps = {
  currentPage: number;
  totalPages: number;
  onChangePage: (pageNumber: number) => void;
  extraClasses?: extraClasses;
};

export const CompactPagination = ({
  currentPage,
  totalPages,
  onChangePage,
  extraClasses,
}: CompactPaginationProps) => {
  const [inputPageNumber, setInputPageNumber] = React.useState<string>(
    currentPage.toString()
  );

  const onSubmitChange = () => {
    if (inputPageNumber !== '') {
      onChangePage(parseInt(inputPageNumber));
    } else {
      setInputPageNumber(currentPage.toString());
    }
  };

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e?.currentTarget?.value;
    if (newValue === '') {
      setInputPageNumber(newValue);
      return;
    }

    const newPageNumber = parseInt(e?.currentTarget?.value);
    setInputPageNumber(
      Math.min(Math.max(newPageNumber, 1), totalPages).toString()
    );
  };

  // handle when the current page is changed outside the input field
  React.useEffect(() => {
    if (currentPage) {
      setInputPageNumber(currentPage.toString());
    }
  }, [currentPage]);

  return (
    <nav
      role="navigation"
      aria-label="pagination"
      className={classnames(styles.box, extraClasses?.box)}
    >
      <Button
        iconOnly
        onClick={() => onChangePage(Math.max(1, currentPage - 1))}
        disabled={currentPage <= 1}
        aria-label="Previous Page"
        variant="ghost"
      >
        <Icon size="lg" icon={IconList.light.faAngleLeft} />
      </Button>
      <Input
        aria-valuenow={currentPage}
        aria-valuemin={1}
        aria-valuemax={totalPages}
        className={styles.pageInput}
        inputType={'number'}
        aria-label="Choose Page"
        aria-current="page"
        value={inputPageNumber}
        onBlur={onSubmitChange}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            onSubmitChange();
          }
        }}
        onChange={handleChangeInput}
      />
      <Text className={styles.slashText}>/</Text>
      <Text aria-label="total pages">{totalPages.toString()}</Text>
      <Button
        iconOnly
        onClick={() => onChangePage(Math.min(totalPages, currentPage + 1))}
        aria-label="Next Page"
        disabled={currentPage >= totalPages}
        variant="ghost"
      >
        <Icon size="lg" icon={IconList.light.faAngleRight} />
      </Button>
    </nav>
  );
};
