import React from 'react';

import styles from './Progress.module.css';

export interface ProgressProps {
  complete: number;
  partiallyComplete: number;
  consensusRequired: number;
  notStarted: number;
}

const Progress = ({
  complete,
  partiallyComplete,
  consensusRequired,
  notStarted,
}: ProgressProps) => {
  const totalProgress =
    complete + partiallyComplete + consensusRequired + notStarted;

  const calculatePercentage = (number: number) =>
    Math.round((number / totalProgress) * 10000) / 100;

  return (
    <div className={styles.progress} data-testid="ProgressBar">
      <Bar className={styles.complete} width={calculatePercentage(complete)} />
      <Bar
        className={styles.partiallyComplete}
        width={calculatePercentage(partiallyComplete)}
      />
      <Bar
        className={styles.consensusRequired}
        width={calculatePercentage(consensusRequired)}
      />
      <Bar
        className={styles.notStarted}
        width={calculatePercentage(notStarted)}
      />
    </div>
  );
};

const Bar = (props: any) => {
  return (
    <div
      className={`${props.className} ${styles.bar}`}
      style={{ width: `${props.width}%` }}
    />
  );
};

export default Progress;
