import React from 'react';
import styles from '../../ExportToFile.module.scss';
import { Message, Text, Link } from 'components/core';

const MORE_INFO_TARGET =
  'https://support.covidence.org/help/exporting-your-extracted-data';

export const IndividualReviewerDataWarning = (): JSX.Element => (
  <Message
    type="warning"
    variant="prominent"
    dismissible={false}
    className={styles.warning}
  >
    <Text size="xs">
      This export has individual reviewer data which may introduce bias in your
      review.{' '}
      <Link to={MORE_INFO_TARGET} variant="neutral" external>
        <strong>Learn more</strong>
      </Link>
    </Text>
  </Message>
);
