import React, { AriaAttributes } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconLookup } from '@fortawesome/fontawesome-common-types';
import classNames from 'classnames';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import styles from './Icon.module.scss';

export type IconType = IconLookup;
interface Props extends AriaAttributes {
  /**
   * Icon reference
   */
  icon: IconType;
  /**
   * Classes to be applied to the object
   */
  className?: string;
  size?: 'xxl' | 'xl' | 'lg' | 'md' | 'sm' | 'xs';
  children?: string;
}

export const Icon = ({
  icon,
  className,
  size,
  children,
  ...AriaProps
}: Props) => {
  return (
    <FontAwesomeIcon
      role="img"
      icon={icon as IconProp}
      className={classNames(styles.Icon, className, size && styles[size])}
      aria-hidden={AriaProps['aria-hidden'] || !children}
      aria-label={AriaProps['aria-label'] || children || ''}
      {...AriaProps}
    />
  );
};

export { Props as IconProps };
export default Icon;
