import React, { useState } from 'react';
import { Box } from 'theme-ui';

import { formatDistanceToNow, millisecondsInHour } from 'date-fns';
import styles from './Invites.module.css';
import { CancelInviteFn } from './queries';

import {
  PrimaryButton,
  TertiaryButton,
  DangerButton,
} from 'system/base/Button';
import Dialog from 'system/base/Dialog';
import { Invite as InviteType } from 'types/graphql';

export interface InvitesProps {
  invites: Array<InviteType>;
  cancelInvite: CancelInviteFn;
}
const Invites = ({ invites, cancelInvite }: InvitesProps) => {
  const invitesCategory = (pending: boolean) => {
    if (pending) {
      return 'pending-invites';
    }
    return 'expired-declined-invites';
  };

  return (
    <Box mr={5} mb={4}>
      <ul data-testid={invitesCategory(invites[0]?.pending)}>
        {invites.map((invite) => (
          <Invite {...invite} cancelInvite={cancelInvite} key={invite.id} />
        ))}
      </ul>
    </Box>
  );
};

interface InviteProps extends InviteType {
  cancelInvite: CancelInviteFn;
}

const Invite = ({
  id,
  createdAt,
  sentAt,
  name,
  email,
  cancelInvite,
  declined,
  expired,
  expiresAt,
  declinedAt,
}: InviteProps) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const formattedDate = (date: Date) =>
    formatDistanceToNow(date, { addSuffix: true });

  const cancel = () => {
    cancelInvite(id, email);
  };

  const renderDeclined = () => {
    if (declined) {
      return (
        <Box sx={{ fontSize: 1, color: 'grey' }}>
          Declined: {formattedDate(new Date(declinedAt))}
        </Box>
      );
    }
  };

  const renderExpired = () => {
    if (expired) {
      return (
        <Box sx={{ fontSize: 1, color: 'grey' }}>
          Expired: {formattedDate(new Date(expiresAt))}
        </Box>
      );
    }
  };

  const hours = (milliseconds: number) => {
    return milliseconds / millisecondsInHour;
  };

  const sentMessage = (sentAt: Date | undefined, createdAt: Date) => {
    if (sentAt) {
      return 'Sent: ' + formattedDate(sentAt);
    } else {
      const now = Date.now();
      const createdAtMillis = new Date(createdAt).getTime();
      const hoursSinceCreated = hours(now - createdAtMillis);
      if (hoursSinceCreated < 1) {
        return 'Email queued for sending.';
      } else {
        return 'The invite email has not been sent. Please check the email address and try again.';
      }
    }
  };

  return (
    <li
      className={`${styles.invite} ${
        declined || expired ? styles.declined : ''
      }`}
    >
      <Dialog
        isOpen={showModal}
        title="Remove an invite"
        onDismiss={() => setShowModal(false)}
      >
        <p>
          Are you sure you want to remove the invite for {name} ({email})?
        </p>

        <PrimaryButton mr={2} onClick={cancel}>
          Yes
        </PrimaryButton>
        <TertiaryButton onClick={() => setShowModal(false)}>No</TertiaryButton>
      </Dialog>
      <h3>{name}</h3>
      <DangerButton sx={{ float: 'right' }} onClick={() => setShowModal(true)}>
        Remove invite
      </DangerButton>
      {email}
      <Box sx={{ fontSize: 1, color: 'grey' }}>
        {sentMessage(sentAt && new Date(sentAt), createdAt)}
      </Box>
      {renderDeclined()}
      {renderExpired()}
    </li>
  );
};

export default Invites;
