import React from 'react';
import { DeleteBlock } from '../Blocks/shared/DeleteBlock';
import { ExtractionFormType } from 'concepts/Extraction/ExtractionFormType';
import {
  TemplateViewModel,
  updateBlock,
  remove,
} from 'concepts/Extraction/ViewModel/TemplateViewModel';
import { Factory } from 'concepts/Extraction/Blocks/factory';
import { Stack } from 'system/base/Stack';

type ChangeHandler = (prevViewModel: TemplateViewModel) => TemplateViewModel;

export interface BuildSettingsProps {
  formType: ExtractionFormType;
  viewModel: TemplateViewModel;
  blockID: string | null;
  onChange: (changer: ChangeHandler) => void;
}

export default function BuildSettings({
  onChange,
  viewModel,
  blockID,
}: BuildSettingsProps): JSX.Element | null {
  if (blockID === null) {
    return null;
  }

  const block = viewModel.blocks.find((block) => block.id == blockID);
  if (block == null) {
    return null;
  }

  const EditorSettings = Factory(block).EditorSettings;

  const onRemove = () => {
    onChange((viewModel) => remove(viewModel, block.id));
  };

  return (
    <Stack component="aside" gap>
      {EditorSettings && (
        <EditorSettings
          block={block}
          onChange={(block) => {
            onChange((viewModel) => updateBlock(viewModel, block));
          }}
          onRemove={onRemove}
        />
      )}
      <DeleteBlock onRemove={onRemove} />
    </Stack>
  );
}
