import React from 'react';

import styles from './BannerIcon.module.scss';
import { Icon } from 'components/core';

import { IconProps } from 'components/core/Icon/Icon';

const BannerIcon = ({ className, ...props }: IconProps) => (
  <div className={`${styles.IconContainer} ${className}`}>
    <Icon {...props} />
  </div>
);

export default BannerIcon;
