import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { Table } from './control/Table';
import { TableBlock } from './TableBlock';
import { TableComparisonRow } from './control/TableComparisonRow';
import { TableEditor } from './editor/TableEditor';
import { TableEditorSettings } from './editor/TableEditorSettings';
import {
  PreviewProps,
  ControlProps,
  EditorProps,
  EditorSettingsProps,
  ComparisonRowProps,
} from 'concepts/Extraction/Blocks/BlockRenderer';

const typeIconSVG = (
  <svg
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    width="56"
    height="32"
    fill="none"
    viewBox="0 0 56 32"
  >
    <title>Table</title>
    <rect width="56" height="32" fill="#F19D0D" rx="4" />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M7 15c0-1.105.895-2 2-2h10c1.105 0 2 .895 2 2v10c0 1.105-.895 2-2 2H9c-1.105 0-2-.895-2-2V15zm1.167.333h5.25V20h-5.25v-4.667zm5.25 5.834h-5.25v4.666h5.25v-4.666zm1.166-5.834h5.25V20h-5.25v-4.667zm5.25 5.834h-5.25v4.666h5.25v-4.666z"
      clipRule="evenodd"
    />
  </svg>
);

export const TableBlockRenderer = {
  Editor({ block, onChange, onComplete, onRemove }: EditorProps) {
    return (
      <TableEditor
        block={block as TableBlock}
        onChange={onChange}
        onComplete={onComplete}
        onRemove={onRemove}
      />
    );
  },
  EditorSettings({ block, onChange }: EditorSettingsProps) {
    return (
      <TableEditorSettings block={block as TableBlock} onChange={onChange} />
    );
  },
  Control({ block, ...props }: ControlProps) {
    const { setValue, control } = useFormContext();
    const blockValue = useWatch({
      control,
      name: block.id,
    });

    return (
      <Table
        editable
        block={block as TableBlock}
        value={blockValue?.value}
        comments={blockValue?.comment}
        onChange={(newValue) => {
          setValue(
            block.id,
            { ...blockValue, value: newValue },
            { shouldDirty: true }
          );
        }}
        onChangeComments={(newComment) => {
          setValue(
            block.id,
            { ...blockValue, comment: newComment },
            { shouldDirty: true }
          );
        }}
        {...props}
      />
    );
  },
  Preview({ block }: PreviewProps) {
    return <Table block={block as TableBlock} />;
  },
  ComparisonRow({ block, ...props }: ComparisonRowProps) {
    return (
      <TableComparisonRow
        block={block as TableBlock}
        {...props}
        renderOnVisible
      />
    );
  },
  TypeIcon: () => typeIconSVG,
  keepResponse() {
    return true;
  },
  displayOnly: false,
};
