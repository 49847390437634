import React from 'react';
import Select, { Option } from 'system/base/Select';

export interface OptionType<T> {
  value: T;
  label: string;
}
export const OptionType = {}; // dummy value export to suppress webpack warning

interface Props<T> {
  options: OptionType<T>[];
  onChange: (selectedValue: T) => void;
  selected: T;
  comparator?: (l: T, r: T) => boolean;
}

function Dropdown<T>(props: Props<T>) {
  const {
    options,
    onChange,
    selected,
    comparator = (l: T, r: T) => l === r,
  } = props;

  const selectedIndex = options.findIndex((opt) =>
    comparator(opt.value, selected)
  );

  if (selectedIndex === -1) {
    // eslint-disable-next-line no-console
    console.log('Unable to find dropdown option:', selected);
  }

  const optElements = options.map(({ label }, index) => (
    <Option key={label} value={index}>
      {label}
    </Option>
  ));

  return (
    <Select
      colors="primary"
      value={selectedIndex}
      onChange={(e: any) =>
        onChange(options[parseInt(e.target.value, 10)].value)
      }
    >
      {optElements}
    </Select>
  );
}

export default Dropdown;
