import React from 'react';
import { GuidelineStudy } from '../../GuidelineStudy/GuidelineStudy';
import { SingleQuestionAssignment } from 'concepts/Guidelines/Triage/SingleQuestionAssignment/SingleQuestionAssignment';
import { QuestionAssignmentModel } from 'containers/Guidelines/Triage/ViewImportedAssignments';
import { StudyModel } from 'containers/Guidelines/Triage/StudyModel';
import { ImportedAssignment } from 'concepts/Guidelines/Triage/ImportedAssignment/ImportedAssignment';
import styles from 'containers/Guidelines/Triage/Triage.module.css';
import {
  QuestionAssignmentOptions,
  QuestionsAssignmentActions,
} from 'containers/Guidelines/Triage/QuestionAssignmentOptions';

export interface ImportedAssignmentsProps {
  study: StudyModel;
  questionAssignments: QuestionAssignmentModel[];
  questionOptions: QuestionAssignmentOptions;
  actions: QuestionsAssignmentActions;
}

export function ImportedAssignments({
  study,
  questionAssignments,
  questionOptions,
  actions,
}: ImportedAssignmentsProps): JSX.Element {
  return (
    <div
      key={study.id}
      data-testid={`imported-study-${study.id}`}
      className={styles.assignmentPanel}
    >
      <div className={styles.assignmentPanelLeft}>
        <GuidelineStudy study={study} showAbstract={false} />
      </div>
      <div className={styles.assignmentPanelRight}>
        {questionAssignments.map((questionAssignment) => (
          <ImportedAssignment
            key={questionAssignment.id}
            {...questionAssignment}
          />
        ))}
        <SingleQuestionAssignment
          study={study}
          questionOptions={questionOptions}
          actions={actions}
        />
      </div>
    </div>
  );
}
