/** @jsxImportSource theme-ui */
import React, { ReactNode } from 'react';
import classNames from 'classnames';
import { CommentPopover } from '../CommentPopover/CommentPopover';
import { Comment } from '../CommentPopover/types';
import styles from './CompareButton.module.css';

type ButtonProps = React.ComponentPropsWithoutRef<'button'>;

export interface CompareButtonProps extends ButtonProps {
  onClick: NonNullable<ButtonProps['onClick']>;
  children: ReactNode;
  comment?: Comment;
  resolved?: boolean;
  consensus?: boolean;
}

export const CompareButton = ({
  onClick,
  children,
  comment,
  resolved,
  consensus,
  ...buttonProps
}: CompareButtonProps) => {
  const classes = classNames(styles.button, {
    [styles.resolved]: resolved,
    [styles.consensus]: consensus,
  });

  return (
    <button
      sx={{
        position: 'relative',
      }}
      {...buttonProps}
      onClick={onClick}
      className={classes}
    >
      {children}
      {!!comment && (
        <CommentPopover
          comments={[comment]}
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            paddingTop: 1,
            paddingLeft: 1,
            paddingRight: 1,
          }}
        />
      )}
    </button>
  );
};
