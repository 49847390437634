import { getCsrfToken } from '../../util/authenticityToken';
import { ExpectedFile } from './types';
import { MatchedFile } from './index';

const checkResponse = (response: Response) => {
  if (!response.ok) throw new Error(response.statusText);
  return response.json();
};

const createImportJob = (reviewId: string) => {
  return fetch(`/reviews/${reviewId}/fulltext_imports`, {
    method: 'POST',
    headers: { 'X-CSRF-Token': getCsrfToken() },
  }).then((response) => checkResponse(response));
};

const pollImportJobStatus = (reviewId: string, importJobId: string) => {
  return fetch(
    `/reviews/${reviewId}/fulltext_imports/${importJobId}`
  ).then((response) => checkResponse(response));
};

export interface UploadHooks {
  onProgress?: (progress: number) => void;
  onError?: () => void;
  onFinish?: () => void;
}

const getPresignedUrl = (
  reviewId: string,
  attachable_type: string,
  attachable_id: string,
  fileName: string
) => {
  const encodedFileName = encodeURIComponent(fileName.trim());
  const url =
    `/documents/s3_upload_config` +
    `?review_id=${reviewId}&attachable_type=${attachable_type}` +
    `&attachable_id=${attachable_id}&file_name=${encodedFileName}`;

  return fetch(url)
    .then((response) => {
      if (!response.ok) {
        throw new Error('Failed to get pre-signed URL for S3 upload');
      }
      return response.json();
    })
    .then(({ url }) => url);
};

const uploadXMLToS3 = (
  file: File,
  reviewId: string,
  importJobId: string,
  { onProgress, onError, onFinish }: UploadHooks
) => {
  getPresignedUrl(reviewId, 'Import::FulltextImport', importJobId, file.name)
    .then((s3_url) => {
      const xhr = new XMLHttpRequest();
      xhr.open('PUT', s3_url, true);

      if (onProgress) {
        xhr.upload.addEventListener('progress', (e: ProgressEvent) => {
          onProgress(e.loaded / e.total);
        });
      }

      if (onError) {
        xhr.addEventListener('error', () => {
          onError();
        });
      }

      if (onFinish) {
        xhr.addEventListener('load', () => {
          onFinish();
        });
      }

      xhr.send(file);
    })
    .catch(() => {
      if (onError) onError();
    });
};

const matchPDFs = (
  files: File[],
  expectedFiles: ExpectedFile[]
): MatchedFile[] => {
  const fileMap = new Map<string, File>();
  files.forEach((file) => fileMap.set(file.name.normalize(), file));

  return expectedFiles.reduce((list: MatchedFile[], file) => {
    const matchedFile = fileMap.get(file.file_name.normalize());
    if (matchedFile) list.push({ file: matchedFile, ...file });
    return list;
  }, []);
};

const uploadPDFToS3 = (
  matchedFile: MatchedFile,
  reviewId: string,
  importJobId: string,
  { onProgress, onError, onFinish }: UploadHooks
) => {
  getPresignedUrl(
    reviewId,
    matchedFile.attachable_type,
    matchedFile.attachable_id,
    matchedFile.file.name
  )
    .then((s3_url) => {
      const xhr = new XMLHttpRequest();
      xhr.open('PUT', s3_url, true);

      if (onProgress) {
        xhr.upload.addEventListener('progress', (e: ProgressEvent) => {
          onProgress(e.loaded);
        });
      }

      const handleUploadError = (message: string) => {
        const error = {
          exception: `'${matchedFile.file.name}' upload failed`,
          data: message,
        };
        recordPDFToS3Errors(reviewId, importJobId, error);

        onError && onError();
      };

      xhr.addEventListener('error', () => {
        // Keeping the same errors as the old bulk uploader for consistency.
        handleUploadError('File upload failed, please try again later');
      });

      xhr.addEventListener('load', () => {
        if (xhr.status >= 200 && xhr.status < 300) {
          onFinish && onFinish();
        } else {
          handleUploadError(xhr.responseText);
        }
      });

      xhr.send(matchedFile.file);
    })
    .catch(() => {
      if (onError) onError();
    });
};

const recordPDFToS3Errors = (
  reviewId: string,
  importId: string,
  error: { exception: string; data: string }
): Promise<Response> => {
  const url = `/reviews/${reviewId}/fulltext_imports/${importId}/import_errors`;
  const body = {
    exception: error.exception,
    data: error.data,
  };

  return fetch(url, {
    method: 'POST',
    headers: {
      'X-CSRF-Token': getCsrfToken(),
      'content-type': 'application/json',
    },
    body: JSON.stringify(body),
  });
};

const saveFulltextImportDocument = (
  file: File,
  attachable_type: string,
  attachable_id: string
): Promise<Response> => {
  const requestBody = {
    attachable_type: attachable_type,
    attachable_id: attachable_id,
    upload_content_type: file.type,
    upload_file_name: file.name,
    upload_file_size: file.size,
    upload_updated_at: new Date(file.lastModified),
  };

  return fetch('/documents', {
    method: 'POST',
    body: JSON.stringify(requestBody),
    headers: {
      'X-CSRF-Token': getCsrfToken(),
      'content-type': 'application/json',
    },
  });
};

const saveReviewReferenceDocument = (
  file: File,
  reviewId: string,
  importJobId: string,
  attachable_id: string
): Promise<Response> => {
  const requestBody = {
    upload_content_type: file.type,
    upload_file_name: file.name,
    upload_file_size: file.size,
    upload_updated_at: new Date(file.lastModified),
  };
  const createDocumentEndpoint = `/reviews/${reviewId}/fulltext_imports/${importJobId}/review_references/${attachable_id}/documents`;
  return fetch(createDocumentEndpoint, {
    method: 'POST',
    body: JSON.stringify(requestBody),
    headers: {
      'X-CSRF-Token': getCsrfToken(),
      'content-type': 'application/json',
    },
  }).then((response) => checkResponse(response));
};

const sendPendoEvent = (
  full_text_upload_method: string,
  total_full_text_uploaded: number
) => {
  (<any>window).pendo?.track('covidence.review.full_text_upload', {
    full_text_upload_method,
    total_full_text_uploaded,
  });
};

export {
  createImportJob,
  matchPDFs,
  uploadXMLToS3,
  uploadPDFToS3,
  saveFulltextImportDocument,
  saveReviewReferenceDocument,
  sendPendoEvent,
  pollImportJobStatus,
};
