import React from 'react';
import styles from './PlanModal.module.css';
import Plan from './Plan';
import { BillableAccount, Plan as PlanType } from './reviewInnerProps';
import Dialog from 'system/base/Dialog';
import { PrimaryLink } from 'system/base/Link';

export interface PlanModalProps {
  isOpen: boolean;
  accountID: string;
  plans: BillableAccount['availablePlans'];
  hasTrialed: boolean;
  onDismiss: () => void;
}

const PlanModal = ({
  isOpen,
  onDismiss,
  accountID,
  plans,
  hasTrialed,
}: PlanModalProps) => {
  return (
    <Dialog
      data-testid="planModal"
      isOpen={isOpen}
      title="Upgrade your plan"
      onDismiss={onDismiss}
      className={styles.planModal}
    >
      <div className={styles.plans}>
        {plans.map((plan: PlanType) => (
          <Plan
            accountID={accountID}
            plan={plan}
            key={plan.id}
            hasTrialed={hasTrialed}
          />
        ))}
        <div className={styles.plan}>
          <div className={styles.name}>ORGANIZATION</div>
          <div className={styles.price}>
            <div className={styles.priceAmount}>CUSTOM</div>
          </div>

          <div className={styles.choosePlan}>
            <PrimaryLink href="https://www.covidence.org/organizations/">
              More info
            </PrimaryLink>
          </div>
          <div className={styles.customPlanPrompt}>Need more reviews?</div>
          <div className={styles.customPlanDescription}>
            Join 200+ universities, hospitals and societies offering Covidence
            to their staff and students.
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default PlanModal;
