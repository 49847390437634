import { gql } from '@apollo/client';

const IMPORT_FRAGMENT = gql`
  fragment Import on Import {
    id
    defaultCategory
    state
    isFinished
    error {
      message
    }
    source
    createdAt
    importedStudiesCount
    detectedCitationsCount
    duplicateCitationsCount
  }
`;

export const IMPORT_ACTIVITY_QUERY = gql`
  query ImportActivity($reviewId: ID!) {
    review: node(id: $reviewId) {
      ... on Review {
        duplicates {
          totalDuplicates
        }
        currentImport {
          ...Import
        }
        imports(first: 5, finalized: true) {
          nodes {
            ...Import
          }
        }
      }
    }
  }
  ${IMPORT_FRAGMENT}
`;
