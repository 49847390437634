import React from 'react';
import Heading from './control/Heading';
import { HeadingBlock } from './HeadingBlock';
import { HeadingComparisonRow } from './control/HeadingComparisonRow';
import {
  PreviewProps,
  ControlProps,
  EditorProps,
  ComparisonRowProps,
} from 'concepts/Extraction/Blocks/BlockRenderer';
import HeadingEditor from 'concepts/Extraction/Blocks/Heading/editor/HeadingEditor';

const typeIconSVG = (
  <svg
    role="img"
    fill="none"
    height="32"
    viewBox="0 0 55 32"
    width="55"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Heading</title>
    <path
      d="M0 5C0 2.23858 2.23858 0 5 0H50C52.7614 0 55 2.23858 55 5V27C55 29.7614 52.7614 32 50 32H5C2.23857 32 0 29.7614 0 27V5Z"
      fill="#337AB7"
    />
    <path
      d="M7.495 22V21.16L8.875 20.83V12.67L7.495 12.34V11.5H12.7V12.34L11.32 12.67V16.12H15.055V12.67L13.675 12.34V11.5H18.88V12.34L17.5 12.67V20.83L18.88 21.16V22H13.675V21.16L15.055 20.83V17.275H11.32V20.83L12.7 21.16V22H7.495ZM19.6639 15.521L21.9849 14.3H22.9419V21.241L23.8769 21.417V22H19.8949V21.417L21.1599 21.197V15.521L19.8399 16.082L19.6639 15.521Z"
      fill="white"
    />
  </svg>
);

export const HeadingBlockRenderer = {
  Editor({ block, onChange, onComplete, onRemove }: EditorProps) {
    return (
      <HeadingEditor
        block={block as HeadingBlock}
        onChange={onChange}
        onComplete={onComplete}
        onRemove={onRemove}
      />
    );
  },
  Control({ block }: ControlProps) {
    return <Heading block={block as HeadingBlock} />;
  },
  Preview({ block }: PreviewProps) {
    return <Heading block={block as HeadingBlock} />;
  },
  TypeIcon: () => typeIconSVG,

  ComparisonRow({ block, reviewerRecords }: ComparisonRowProps) {
    return (
      <HeadingComparisonRow
        label={block.label}
        numberReviewers={reviewerRecords.length}
      />
    );
  },
  keepResponse() {
    return true;
  },
  displayOnly: true,
};
