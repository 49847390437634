import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/dedupe';

import Avatar from 'components/Avatar';

const disabledCaption = 'Cannot remove an existing reviewer from a review';

function renderLabel(member, cardSize) {
  if (cardSize === 'medium' || member.admin === undefined) {
    return undefined;
  }

  return <small className="account_card--label label label-info">Admin</small>;
}

class AccountCard extends Component {
  constructor() {
    super();
    this.onClick = this.onClick.bind(this);
  }

  onClick(e) {
    const { selected } = this.props;

    const callIfDefined = (fn) => {
      if (typeof fn === 'function') {
        e.preventDefault();
        fn();
      }
    };

    if (selected) {
      callIfDefined(this.props.offClick);
    } else {
      callIfDefined(this.props.onClick);
    }
  }

  render() {
    const {
      account,
      caption,
      cardSize,
      disabled,
      link,
      onClick,
      selected,
    } = this.props;

    /* eslint-disable @typescript-eslint/naming-convention */
    const wrapperClasses = classNames({
      account_card: true,
      'account_card--clickable': onClick && !disabled,
      'account_card--link': link && !disabled,
      'account_card--selected': selected,
      'account_card--disabled': disabled,
    });
    /* eslint-enable @typescript-eslint/naming-convention */

    const avatarClasses = `account_option--avatar avatar--${cardSize} pull-left`;

    const titleClasses = classNames({
      'account_card--name': true,
      [`account_card--name-${cardSize}`]: true,
      'account_card--name-no-caption': !caption,
    });

    /* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
    return (
      <div
        onClick={this.onClick}
        className={wrapperClasses}
        title={disabled ? disabledCaption : ''}
      >
        {selected ? (
          <i className="account_card--checked fas fa-check-square pull-right" />
        ) : null}
        <Avatar
          name={account.owner.name || ''}
          className={avatarClasses}
          greyscale={!selected}
        />
        <h3 className={titleClasses}>
          <a href={link} title={account.owner.name} className="pii">
            {account.owner.name}
          </a>
          {renderLabel(account.owner, cardSize)}
        </h3>
        {caption ? (
          <p className="account_card--caption">{caption}</p>
        ) : undefined}
        <div className="account_card--clearfix" />
      </div>
    );
    /* eslint-enable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
  }
}

AccountCard.defaultProps = {
  cardSize: 'medium',
  selected: false,
  disabled: false,
  link: null,
  caption: null,
  onClick: undefined,
  offClick: undefined,
};

AccountCard.propTypes = {
  account: PropTypes.object.isRequired,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  offClick: PropTypes.func,
  link: PropTypes.string,
  caption: PropTypes.string,
  cardSize: PropTypes.oneOf(['medium', 'large']),
};

export default AccountCard;
