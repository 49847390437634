import React from 'react';
import { FileInfo } from '../index';
import styles from './FileList.module.css';

interface FileStatusProps {
  file: FileInfo;
  uploadProgress: number;
}

interface StatusLabelProps {
  className: string;
  children: string;
}

const StatusLabel = ({ className, children }: StatusLabelProps) => (
  <span className={styles[className]}>{children}</span>
);

const ProgressBar = ({ progress }: { progress: number }) => (
  <div className={styles['FileList__status--progress']}>
    <div style={{ width: Math.floor(progress * 100) + '%' }} />
  </div>
);

export default function FileStatus({ file, uploadProgress }: FileStatusProps) {
  if (!file.uploadComplete && !file.uploadError) {
    return <ProgressBar progress={uploadProgress} />;
  }

  const className = file.uploadError
    ? 'FileList__status--error'
    : 'FileList__status--label';
  return (
    <StatusLabel className={className}>
      {file.uploadError || 'Primary'}
    </StatusLabel>
  );
}
