import { MutableRefObject, RefObject } from 'react';

export class AccordionAnimationController {
  ref: RefObject<HTMLElement> | MutableRefObject<HTMLElement>;
  startingHeight: number;

  constructor(ref: RefObject<HTMLElement> | MutableRefObject<HTMLElement>) {
    this.ref = ref;
    this.startingHeight = 0;
  }

  calculateStartingHeight() {
    if (!this.ref.current) return;
    this.startingHeight = Number.parseInt(
      window.getComputedStyle(this.ref.current).height
    );
  }

  startAnimation() {
    // This is going to cause some layout thrashing, but not layout thrashing is much more complicated
    if (!this.ref.current) return;
    const elem = this.ref.current;

    // If there's no transition set on this element, just do nothing
    if (window.getComputedStyle(elem).transitionDuration === '0s') return;
    elem.style.transition = 'none';

    const cleanup = () => {
      elem.style.height = '';
      elem.removeEventListener('transitionend', cleanup);
    };
    elem.addEventListener('transitionend', cleanup);

    // wait a tick for the new height to be calculated
    requestAnimationFrame(() => {
      elem.style.height = ''; // recover from a stuck height value
      const toHeight = window.getComputedStyle(elem).height;
      elem.style.height = `${this.startingHeight}px`;
      elem.style.transition = '';

      requestAnimationFrame(() => {
        elem.style.height = toHeight;
      });
    });
  }
}
