import React, { useContext, useRef, useLayoutEffect } from 'react';

type FocusTarget = HTMLElement | string | null;

export type FocusOptions = {
  when: 'now' | 'nextRender' | 'nextAnimationFrame';
};

type FocusManagerCallback = (
  target: FocusTarget,
  options?: FocusOptions
) => void;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const FocusContext = React.createContext<FocusManagerCallback>((target) => {
  return null;
});

export function useFocusManager(): FocusManagerCallback {
  return useContext(FocusContext);
}

interface FocusProviderProps {
  children: React.ReactNode;
}

type FocusTargetStruct = {
  target: FocusTarget;
  when: 'nextRender' | 'nextAnimationFrame';
};

export function FocusManagerProvider({
  children,
}: FocusProviderProps): JSX.Element {
  const rootRef = useRef<HTMLDivElement>(null);
  const focusRef = useRef<FocusTargetStruct | null>(null);

  function focusOnTarget(target: FocusTarget) {
    const rootEl = rootRef.current;
    if (rootEl === null) {
      return;
    }

    if (target === null) {
      return;
    }

    if (typeof target === 'string') {
      target = rootEl.querySelector<HTMLElement>(target);
    }

    if (target instanceof HTMLElement) {
      target.focus();
      // Move caret consistent to the end of the input. Was observing that:
      // <input> moves to the end by default
      // <textarea> moves to the start by default, making typing clumsy
      // So we make them behave the same.
      if (
        target instanceof HTMLInputElement ||
        target instanceof HTMLTextAreaElement
      ) {
        const end = target.value.length;
        target.setSelectionRange(end, end);
      }
    }
  }

  function requestFocus(
    target: FocusTarget,
    options: FocusOptions = { when: 'nextRender' }
  ) {
    if (options.when == 'now') {
      focusOnTarget(target);
    } else {
      focusRef.current = { target, when: options.when };
    }
  }

  useLayoutEffect(() => {
    const current = focusRef.current;
    if (current) {
      if (current.when == 'nextAnimationFrame') {
        requestAnimationFrame(() => {
          focusOnTarget(current.target);
          focusRef.current = null;
        });
      } else {
        focusOnTarget(current.target);
        focusRef.current = null;
      }
    }
  });

  return (
    <FocusContext.Provider value={requestFocus}>
      <div ref={rootRef} data-focus-manager>
        {children}
      </div>
    </FocusContext.Provider>
  );
}
