/*
Basically a re-export of Reach UI's MenuButton, but with our app styles passed
in, along with Reach's required styles for MenuButton

https://ui.reach.tech/menu-button

*/
import {
  MenuButton as ReachMenuButton,
  MenuItem as ReachMenuItem,
  MenuList as ReachMenuList,
  MenuListProps,
  MenuButtonProps,
  MenuItemProps,
} from '@reach/menu-button';
import React from 'react';
import { Box } from 'theme-ui';

const MenuList = Box.withComponent(ReachMenuList);
const MenuButton = Box.withComponent(ReachMenuButton);
const MenuItem = Box.withComponent(ReachMenuItem);

const StyledMenuList = ({ ...props }) => (
  <MenuList
    {...(props as MenuListProps)}
    sx={{ bg: '#fff', outline: 'none', border: 'panel' }}
  />
);

const StyledMenuItem = ({ ...props }) => (
  <MenuItem
    p={2}
    {...(props as MenuItemProps)}
    sx={{ borderBottom: 'panel', bg: '#fff' }}
  />
);

const StyledMenuButton = ({ ...props }) => (
  <MenuButton
    {...(props as MenuButtonProps)}
    variant="buttons.raised"
    px={2}
    sx={{
      bg: '#fff',
      borderLeft: 0,
      borderRight: 0,
      borderTop: 0,
      fontSize: 3,
    }}
  />
);

// Menu doesn't output DOM, no styling needed, we'll just re-export it
export { Menu } from '@reach/menu-button';
export {
  StyledMenuButton as MenuButton,
  StyledMenuList as MenuList,
  StyledMenuItem as MenuItem,
};
