import React from 'react';
import PropTypes from 'prop-types';
import { getCsrfToken } from 'util/authenticityToken';

const RailsForm = (props) => {
  const { children, formRef, method, ...rest } = props;

  let virtualMethod;
  let actualMethod;

  if (method.match(/^(get|post)$/i)) {
    actualMethod = method.toLowerCase();
    virtualMethod = null;
  } else {
    actualMethod = 'post';
    virtualMethod = (
      <input name="_method" type="hidden" value={method.toLowerCase()} />
    );
  }

  return (
    <form {...rest} ref={formRef} method={actualMethod}>
      <input type="hidden" name="authenticity_token" value={getCsrfToken()} />
      {virtualMethod}
      {children}
    </form>
  );
};

RailsForm.propTypes = {
  children: PropTypes.node.isRequired,
  formRef: PropTypes.func,
  method: PropTypes.oneOf(['delete', 'get', 'patch', 'post', 'put']),
};

RailsForm.defaultProps = {
  formRef: (e) => e,
  method: 'get',
};

export default RailsForm;
