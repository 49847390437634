import React from 'react';
import { Box } from 'theme-ui';
import { CellProps } from 'react-table';
import { RowData, CellValue } from '../types';

// Non-editable cell
export const Cell = ({
  value,
}: Pick<CellProps<RowData, CellValue>, 'value'>) => (
  <Box sx={{ padding: 1, height: '100%' }}>{value ?? ''}</Box>
);
