import { TableHeaderType, TableHeaderTypePlural } from '../types';

const headerTypePluralMap: Record<TableHeaderType, TableHeaderTypePlural> = {
  column: 'columns',
  row: 'rows',
};

/**
 * Convenience utility which pluralizes 'column' & 'row'
 *
 * @remarks
 *
 * Used because TableBlock type (used for table block data) has keys named
 * 'columns' & 'rows' whereas TableHeaderType type (used for labelling column/
 * row input fields and the "Add column/row" button uses 'column' & 'row'.
 */
export const getHeaderTypePlural = (
  headerType: TableHeaderType
): TableHeaderTypePlural => headerTypePluralMap[headerType];
