import React from 'react';
import { PENDO_REVIEW_SETTING_PREFIX } from '../ExtractionToolSelector';
import styles from './ComingSoonModal.module.scss';
import {
  Button,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Text,
} from 'components/core';

interface Props {
  onConfirm: () => void;
  onClose: () => void;
}

const ComingSoonModal = ({ onConfirm, onClose }: Props) => {
  return (
    <Modal isOpen={true} onClose={onClose}>
      <ModalHeader>Single reviewer is coming soon!</ModalHeader>
      <ModalBody className={styles.ModalBody}>
        <Text>
          We&apos;re planning to introduce a single reviewer workflow to
          Extraction 1 to enhance your experience.
        </Text>
        This will allow you to:
        <List>
          <ListItem>Extract data as a single reviewer</ListItem>
          <ListItem>
            Remove the need for consensus or checking of each study form
          </ListItem>
        </List>
        <Text>Would you like to be notified when it&apos;s available?</Text>
      </ModalBody>
      <ModalFooter>
        <Button type="neutral" variant="outline" onClick={onClose}>
          No thanks
        </Button>
        <Button
          type="brand"
          onClick={onConfirm}
          data-pendo-key={
            PENDO_REVIEW_SETTING_PREFIX +
            'single_extractor_notification_confirmation'
          }
        >
          Notify me
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ComingSoonModal;
