import React, { useState, FormEvent, useCallback } from 'react';
import { gql, useMutation } from '@apollo/client';
import { Input } from 'theme-ui';

import { ID } from 'util/types';
import Spinner from 'system/elements/Spinner';
import {
  ChangeStudyIdentifierInput,
  ChangeStudyIdentifierMutation,
} from 'types/graphql';
import { NakedButton } from 'system/base/Button';

const CHANGE_STUDY_IDENTIFIER = gql`
  mutation changeStudyIdentifier($studyId: ID!, $newStudyIdentifier: String!) {
    changeStudyIdentifier(
      input: { studyId: $studyId, newStudyIdentifier: $newStudyIdentifier }
    ) {
      success
      errors {
        message
      }

      study {
        id
        studyId
      }
    }
  }
`;

interface Props {
  studyId: ID;
  identifier: string;
}

const StudyIdentifier = ({ studyId, identifier }: Props) => {
  const [mode, setMode] = useState<'display' | 'edit' | 'saving'>('display');
  const [changeStudyIdentifier] = useMutation<
    ChangeStudyIdentifierMutation,
    ChangeStudyIdentifierInput
  >(CHANGE_STUDY_IDENTIFIER);

  const changeStudyId = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      setMode('saving');

      const formData = new FormData(event.currentTarget);
      changeStudyIdentifier({
        variables: {
          studyId,
          newStudyIdentifier: formData.get('identifier') as string,
        },
      }).then(() => setMode('display'));
    },
    [setMode, changeStudyIdentifier, studyId]
  );

  if (mode === 'display') {
    return (
      <NakedButton
        style={{
          cursor: 'text',
          width: '100%',
          textAlign: 'start',
          height: '100%',
          paddingLeft: 8,
          paddingRight: 8,
          color: 'inherit',
        }}
        onClick={() => setMode('edit')}
      >
        {identifier}
      </NakedButton>
    );
  } else if (mode === 'edit') {
    return (
      <form style={{ display: 'inline-block' }} onSubmit={changeStudyId}>
        <Input name="identifier" defaultValue={identifier} />
      </form>
    );
  } else {
    return <Spinner />;
  }
};

export default StudyIdentifier;
