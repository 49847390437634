import { createContext, useContext } from 'react';
import { PathPattern } from '@remix-run/router/utils';
import { matchPath, useInRouterContext } from 'react-router-dom';

export const RoutesContext = createContext<{
  routes: PathPattern[];
}>({
  routes: [],
});

export const convertUrlToPath = (url: string | URL | undefined): string => {
  if (!url) return '';
  if (typeof url === 'string') return url;

  return (url?.pathname || '') + (url?.search || '');
};

export const useReactRouterLink = (to: URL | string | undefined) => {
  const isInRouter = useInRouterContext();
  const { routes } = useContext(RoutesContext);

  let shouldUseRouterLink = false;
  const path = convertUrlToPath(to);

  if (isInRouter && to) {
    for (const key in routes) {
      if (
        routes[key] &&
        matchPath(
          { path: routes[key].path, caseSensitive: false, end: false },
          path
        )
      ) {
        shouldUseRouterLink = true;
        break;
      }
    }
  }

  return shouldUseRouterLink;
};
