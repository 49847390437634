import { REVIEW_FEATURE_FLAG_ROUTE, tokenizeRoute } from '../routes';
import {
  RequestErrorHandler,
  _handleJsonResponse,
  defaultFetchOptions,
  withRequestRetry,
} from 'query/study/DataExtraction'; // TODO: Move to a generic file in future

export const getReviewFeatures = async (
  reviewId: string,
  onError?: RequestErrorHandler
) => {
  return withRequestRetry(() => _getReviewFeatures(reviewId), onError);
};

const _getReviewFeatures = async (reviewId: string) => {
  const url = tokenizeRoute(REVIEW_FEATURE_FLAG_ROUTE, { review_id: reviewId });

  return fetch(url.toString(), {
    method: 'GET',
    ...defaultFetchOptions(),
  }).then((response) => {
    return _handleJsonResponse(response);
  });
};
