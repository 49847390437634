/*
# Metric

A Text, but for rendering numbers in a formatted way

*/
import React, { FunctionComponent, ComponentProps } from 'react';
import Text, { InlineText } from 'system/base/Text';

const localeFormatNumber = (input: number) => Number(input).toLocaleString();

interface Props extends ComponentProps<typeof Text> {
  children: number;
  formatNumber?: (input: number) => string;
  inline?: boolean;
}

const Metric: FunctionComponent<Props> = ({
  children,
  inline,
  formatNumber = localeFormatNumber,
  ...props
}: Props) => {
  if (inline) {
    return <InlineText {...props}>{formatNumber(children)}</InlineText>;
  } else {
    return <Text {...props}>{formatNumber(children)}</Text>;
  }
};

export default Metric;
