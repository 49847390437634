import React, { useCallback, useContext, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { BaseCheckboxes } from '../../BaseCheckboxes';
import { getInitialCheckboxesBlockValue } from '../../utils';
import checkboxesStyles from './CheckboxesComparisonRow.module.css';
import { CompareDecision } from 'concepts/Extraction/Compare/CompareDecision';
import { ComparisonRowProps } from 'concepts/Extraction/Blocks/BlockRenderer';
import styles from 'concepts/Extraction/Compare/ComparisonTable/ComparisonTable.module.css';
import { BlockValue } from 'concepts/Extraction/BlocksData';
import {
  CheckboxesBlock,
  CheckboxesBlockValue,
} from 'concepts/Extraction/Blocks/Checkboxes/CheckboxesBlock';
import { CompareButton } from 'concepts/Extraction/Compare/CompareButton';
import { ComparisonTableContext } from 'concepts/Extraction/Compare/ComparisonTable/ComparisonTable';
import { CompareNotificationBorder } from 'concepts/Extraction/Compare/CompareNotificationBorder';
import { useDebouncedFunction } from 'hooks/useDebouncedFunction';

export interface CheckboxesComparisonRowProps extends ComparisonRowProps {
  block: CheckboxesBlock;
}

export const CheckboxesComparisonRow = ({
  block,
  reviewerRecords,
  onResolveValue,
  readonly,
}: CheckboxesComparisonRowProps): JSX.Element => {
  const formContext = useFormContext();
  const { reviewerNames } = useContext(ComparisonTableContext);
  const [userResolved, setUserResolved] = useState<boolean>(false);

  const resolvedDecidedValue = useWatch({
    control: formContext.control,
    name: `resolvedData.${block.id}.value`,
  });

  const resolutionState = useWatch({
    control: formContext.control,
    name: `blockResolutions.${block.id}`,
  });
  const [value, setValue] = useState(resolvedDecidedValue);

  const debouncedResolve = useDebouncedFunction(onResolveValue, 300);

  const handleResolveValue = useCallback(
    (value: BlockValue) => {
      setValue(value);
      debouncedResolve(block.id, value);
    },
    [block.id, debouncedResolve]
  );

  const handleClick = useCallback(
    (updatedValue: CheckboxesBlockValue) => {
      handleResolveValue(updatedValue);
      if (!userResolved) setUserResolved(true);
    },
    [handleResolveValue, userResolved]
  );

  // This method is a work around for a product bug
  // where consensus was saving checkboxes with no selection
  // as an empty array. COV-2435
  const cleanValue = (value: any) => {
    if (value instanceof Array) {
      return undefined;
    }
    return value;
  };

  return (
    <tr className={styles.row}>
      <th role="rowheader" className={styles.labelCell}>
        {block.label}
      </th>
      <td className={styles.decisionCell}>
        <CompareNotificationBorder
          notificationMessage="Check Decision"
          showNotification={
            !userResolved && resolutionState === 'supersededByExtractor'
          }
        >
          <CompareDecision
            variant="checkboxes"
            block={block}
            readonly={readonly}
            label={block.label}
            resolutionState={resolutionState}
            value={
              (cleanValue(value) as CheckboxesBlockValue | undefined) ??
              getInitialCheckboxesBlockValue(block.options)
            }
            onResolveValue={handleResolveValue}
          />
        </CompareNotificationBorder>
      </td>
      {reviewerRecords.map((reviewer, index) => {
        const record = reviewer?.[block.id];
        const value = record?.value as CheckboxesBlockValue;
        const comment = record?.comment ?? '';
        const changed = !!record?.changed;

        return (
          <td className={styles.reviewerCell} key={index}>
            <CompareNotificationBorder
              notificationMessage="Data edited"
              showNotification={changed}
            >
              <CompareButton
                disabled={readonly}
                onClick={() => handleClick(value)}
                comment={
                  comment
                    ? {
                        comment,
                        commenter: reviewerNames[index],
                      }
                    : undefined
                }
              >
                <BaseCheckboxes
                  className={checkboxesStyles.compareButton_checkboxes}
                  block={block}
                  value={value}
                  isDisabled
                />
              </CompareButton>
            </CompareNotificationBorder>
          </td>
        );
      })}
    </tr>
  );
};
