import { makeTheme, makeThemeSizesScale } from './utils/make-theme';
import { colors, colorStyles, actionableColorStyles } from './colors';
import { fontSizes, fontWeights, textStyles } from './text';
import { breakpoints } from './breakpoints';
import borders from './borders';
import buttons from './buttons';
import forms from './forms';
import links from './links';
import shadows from './shadows';
import space from './space';

const sizes = makeThemeSizesScale(
  Object.assign([], space, { container: breakpoints })
);

// ref: https://theme-ui.com/theming
export const theme = makeTheme({
  breakpoints,
  // theme ui
  space,
  colors,
  fontSizes,
  sizes,
  shadows,
  borders,
  fontWeights,
  // variants
  buttons,
  links,
  colorStyles,
  actionableColorStyles,
  textStyles,
  // custom
  forms,
});

export type Theme = typeof theme;
