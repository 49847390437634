import { useCallback, useEffect, useState } from 'react';
import { getReviewId, getReviewFeatures } from 'query/review';

export function useReviewFeatures(onError?: (error: any) => any) {
  const reviewId = getReviewId() || '';
  const [features, setFeatures] = useState<string[]>([]);
  const [settings, setSettings] = useState<{ [index: string]: any }>({});

  useEffect(() => {
    getReviewFeatures(reviewId)
      .then((response) => {
        setFeatures(response.features);
        setSettings(response.settings);
      })
      .catch((error) => {
        if (onError) {
          onError(error);
        }
      });
  }, [reviewId]);

  const hasFeature = useCallback(
    (feature: string) => {
      return features.includes(feature);
    },
    [features]
  );

  const getSetting = useCallback(
    (setting: string) => {
      return settings[setting];
    },
    [settings]
  );

  const hasSelectableMeasures = !!settings?.selectable_measures;
  const useSuggestions = !!settings?.use_suggestions;

  return {
    hasFeature,
    getSetting,
    hasSelectableMeasures,
    useSuggestions,
  };
}
