/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useQuery } from '@apollo/client';
import useDocumentVisibility from '@rehooks/document-visibility';
import idx from 'idx';
import classNames from 'classnames';
import { IMPORT_ACTIVITY_QUERY } from './queries';
import ImportHistory from './components/ImportHistory';
import ImportInProgress from './components/ImportInProgress';
import styles from './ImportReferencesSummary.module.css';
import BatchInProgress from './components/BatchInProgress';
import LoadingSpinner from 'system/elements/Spinner';
import ErrorMessage from 'components/ErrorMessage';
import { ImportActivityQuery, ImportFragment } from 'types/graphql';
import { ID } from 'util/types';
import Panel from 'system/elements/Panel';
import Flex from 'system/base/Flex';
import { SummaryTitle } from 'system/base/Heading';
import { Link } from 'system/base/Link';
import Box from 'system/base/Box';
import Text from 'system/base/Text';
import { PrimaryLink } from 'system/base/Link';
import { useControls, useLabelledBy } from 'hooks/useAccessibleName';
import Icon, { IconStyle } from 'system/base/Icon';
import { useGetLatestAutoExclusionBatchQuery } from 'query/review';
import { usePrevious } from 'hooks/usePrevious';
import pollingInterval from 'util/pollingInterval';
import { useReviewerFeatures } from 'hooks/reviewers/useReviewerFeatures';

const LoadingView = () => (
  <Panel marginBottom={4}>
    <Flex justifyContent="space-between" alignItems="center">
      <SummaryTitle unpadded fontWeight="lighter" color="greyDarker">
        Import references
      </SummaryTitle>
      <LoadingSpinner />
    </Flex>
  </Panel>
);

export interface ImportReferencesSummaryProps {
  reviewId: ID;
  isOpen: boolean;
  newImportLink: string;
  historyLink: string;
  isAutoExclusionEnabled: boolean;
  isAutoExclusionSupported: boolean;
  onImportStart?: (i: ImportFragment) => void;
  onImportFinish?: (i: ImportFragment) => void;
}

const isInProgress = (i: ImportFragment | null | undefined): boolean => {
  if (!i) return false;
  return !i.isFinished;
};

export const pollInterval = pollingInterval(6000);

const ImportReferencesSummary = ({
  reviewId,
  newImportLink,
  historyLink,
  isOpen = false,
  isAutoExclusionEnabled,
  isAutoExclusionSupported,
  onImportStart = () => null,
  onImportFinish = () => {
    window.location.reload();
  },
}: ImportReferencesSummaryProps) => {
  const [open, setOpen] = useState<boolean>(isOpen);
  const [ariaControlledID, ariaControls] = useControls();
  const [labelID, labelledBy] = useLabelledBy();
  const documentVisibility = useDocumentVisibility();
  const polling = documentVisibility !== 'hidden' && open;

  useReviewerFeatures();
  const {
    data: importData,
    error: importError,
    loading: importLoading,
  } = useQuery<ImportActivityQuery>(IMPORT_ACTIVITY_QUERY, {
    pollInterval: polling ? pollInterval : 0,
    variables: { reviewId },
  });

  const fullPageRefresh = () => {
    window.location.reload();
  };

  const currentImport = useMemo(
    () =>
      !importError && !importLoading
        ? importData?.review?.currentImport
        : undefined,
    [importData, importError, importLoading]
  );
  const importInProgress = isInProgress(currentImport);
  const hasImports = Boolean(currentImport);
  const oldImportRef = useRef<ImportFragment>();

  const enableBatching = () => {
    // Dont run if feature disabled
    if (!isAutoExclusionEnabled) return false;

    // Dont run if loading imports, review has no imports or there is an error
    return !importLoading && !importInProgress && hasImports && !importError;
  };
  const { data: batchData } = useGetLatestAutoExclusionBatchQuery(reviewId, {
    enabled: enableBatching(),
  });
  const autoExcludeCount = batchData?.review_total_auto_exclusions_count || 0;
  const isBatching = batchData?.isBatching;
  const wasBatching = usePrevious(isBatching);

  useEffect(() => {
    const oldCurrentImport = oldImportRef.current;
    const importWasInProgress = isInProgress(oldCurrentImport);
    const importIsInProgress = isInProgress(currentImport);

    const importChanged =
      importWasInProgress !== importIsInProgress ||
      (importIsInProgress &&
        importWasInProgress &&
        oldCurrentImport!.id !== currentImport!.id);

    if (importChanged) {
      if (importWasInProgress) {
        onImportFinish(oldCurrentImport as ImportFragment);
      }

      if (importIsInProgress) {
        onImportStart(currentImport as ImportFragment);
      }
    }

    oldImportRef.current = currentImport as ImportFragment;
  }, [currentImport, onImportFinish, onImportStart]);

  // Open panel if import is in progress
  useEffect(() => {
    if (importInProgress) {
      setOpen(true);
    }
  }, [importInProgress]);

  // Open panel if batch is in progress
  useEffect(() => {
    if (isBatching) {
      setOpen(true);
    }
  }, [isBatching]);

  // refresh page if batch and import are finished
  useEffect(() => {
    if (wasBatching && !isBatching && !importInProgress) {
      fullPageRefresh();
    }
  }, [isBatching, importInProgress, wasBatching]);

  if ((importLoading || !importData) && !importError) {
    return <LoadingView />;
  }

  if (importError) {
    // Ticket to improve this state https://covidence.atlassian.net/browse/COV-2120
    return <ErrorMessage>Something went wrong, try again later.</ErrorMessage>;
  }

  const recentImports = idx(importData, (_) => _.review.imports.nodes);
  const totalDuplicates = idx(
    importData,
    (_) => _.review.duplicates.totalDuplicates
  );
  const isOpenable = !isOpen && (importInProgress || recentImports!.length > 0);
  // don't appear open if there's nothing to display in the panel in an open state
  const isVisiblyOpen =
    isOpen &&
    ((recentImports && recentImports.length > 0) || currentImport != null);

  const checkDuplicatesLink = (importId: string | null) => {
    if (importId) {
      return `/reviews/${reviewId}/duplicates?import_id=${importId}`;
    }

    return `/reviews/${reviewId}/duplicates`;
  };

  const autoExclusionsListLink = (reviewId: string) => {
    return `/reviews/${reviewId}/auto_exclusions`;
  };

  // Combined 'removed' link for particular screen breakpoint, taking into account if AutoExclusions supported
  // When AutoExclusions are in play, the count is the sum of dupes plus auto-exclusions
  const combinedLinkText = `${
    (totalDuplicates ?? 0) + (isAutoExclusionSupported ? autoExcludeCount : 0)
  } removed`;

  // Destination for combined 'removed' link, based on whether AutoExclusions supported & presence of dupes
  // If dupes > 0 we go to dupes url, otherwise if AutoExclusion supported, go there, with final fallback to dupes url
  const combinedLinkHref =
    (totalDuplicates ?? 0) > 0
      ? checkDuplicatesLink(null)
      : isAutoExclusionSupported
      ? autoExclusionsListLink(reviewId)
      : checkDuplicatesLink(null);

  const handleOpenPanelClick = () => {
    setOpen(!open);
  };

  return (
    <Panel primary={isVisiblyOpen} actionable={isOpenable} padding={0}>
      <div className={styles.summaryAccordionHeader}>
        <SummaryTitle
          unpadded
          fontWeight={isVisiblyOpen ? undefined : 'lighter'}
          color={isVisiblyOpen ? undefined : 'greyDarker'}
        >
          <button
            className={styles.summaryAccordionButton}
            onClick={handleOpenPanelClick}
            aria-expanded={open}
            {...ariaControls}
            id={labelID}
          >
            <Icon
              name="chevron-up"
              iconStyle={IconStyle.Regular}
              mr="10px"
              sx={{
                transform: !open && 'rotate(180deg)',
                fontSize: 4,
                transition: 'transform 150ms ease-out',
              }}
            />
            Import references
          </button>
        </SummaryTitle>
        <div
          className={classNames(styles.summaryAccordionHeaderRightContainer, {
            [styles.inProgress]: importInProgress,
          })}
        >
          {/* Render links based on isAutoExclusionSupported */}
          <Text
            color="grey"
            fontSize={2}
            fontWeight="lighter"
            className={classNames(styles.duplicatesRemoveLinkWrapper, {
              [styles.multipleLinks]: isAutoExclusionSupported,
              [styles.singleLink]: !isAutoExclusionSupported, // Use singleLink class for dupes when AutoExclusions unsupported
            })}
          >
            {/* Always render the total duplicates link */}
            <Link href={checkDuplicatesLink(null)}>
              {totalDuplicates ?? 0} total duplicates removed
            </Link>

            {/* Render auto exclusions link only if supported */}
            {isAutoExclusionSupported && (
              <Link href={autoExclusionsListLink(reviewId)}>
                {autoExcludeCount} auto-marked as ineligible
              </Link>
            )}
          </Text>

          {/* Render combined link for smaller screens if auto exclusion is supported (hidden by default) */}
          {isAutoExclusionSupported && (
            <Text
              className={classNames(
                styles.duplicatesRemoveLinkWrapper,
                styles.combinedLink
              )}
            >
              <Link href={combinedLinkHref}>{combinedLinkText}</Link>
            </Text>
          )}

          <div className={styles.actionArea}>
            {importInProgress ? (
              <Box>
                <div className={styles.orb} /> Import in progress
              </Box>
            ) : (
              <Box>
                <PrimaryLink href={newImportLink}>
                  <Icon name="cloud-upload-alt" /> Import
                </PrimaryLink>
              </Box>
            )}
          </div>
        </div>
      </div>
      <div
        hidden={!open}
        {...labelledBy}
        id={ariaControlledID}
        role="region"
        data-testid="importReferencesPanelContent"
      >
        {importInProgress && <ImportInProgress currentImport={currentImport} />}
        {!importInProgress &&
          isAutoExclusionSupported &&
          isAutoExclusionEnabled && (
            <BatchInProgress batch={batchData?.latest_batch} />
          )}

        <ImportHistory
          checkDuplicatesLink={checkDuplicatesLink}
          recentImports={recentImports as readonly ImportFragment[]}
          viewMore={historyLink}
        />
      </div>
    </Panel>
  );
};

export default ImportReferencesSummary;
