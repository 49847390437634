import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'theme-ui';
import { DangerButton } from 'system/base/Button';
import Select, { Option } from 'system/base/Select';

class NewEffectMeasureField extends Component {
  constructor() {
    super();

    this.onRemove = this.onRemove.bind(this);
    this.onUpdateLabel = this.onUpdateLabel.bind(this);
    this.onUpdateType = this.onUpdateType.bind(this);
  }

  onRemove(e) {
    e.preventDefault();
    const { onRemove } = this.props;
    if (this.isRemoveable()) {
      onRemove();
    }
  }

  onUpdateLabel(e) {
    const { onUpdate } = this.props;
    onUpdate({ label: e.target.value });
  }

  onUpdateType(e) {
    const { onUpdate } = this.props;
    onUpdate({ type: e.target.value });
  }

  isRemoveable() {
    return typeof this.props.onRemove === 'function';
  }

  render() {
    const { field } = this.props;
    const { label, type } = field;

    return (
      <tr className="new_effect_measure--row">
        <td>
          <Input
            width="100%"
            value={label}
            placeholder="e.g. n, N, SD"
            onChange={this.onUpdateLabel}
            required
          />
        </td>
        <td>
          <Select
            width="100%"
            value={type || ''}
            onChange={this.onUpdateType}
            required
          >
            <Option value="" disabled>
              Choose a format
            </Option>
            <Option value="numeric(0)">Integer</Option>
            <Option value="numeric(4)">Decimal</Option>
            <Option value="percentage">Percent</Option>
            <Option value="text">Plain text</Option>
          </Select>
        </td>
        <td className="text-center">
          <DangerButton
            onClick={this.onRemove}
            disabled={!this.isRemoveable()}
            className="new_effect_measure--row-remove"
            iconName="minus-circle"
          >
            Remove
          </DangerButton>
        </td>
      </tr>
    );
  }
}

NewEffectMeasureField.propTypes = {
  field: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default NewEffectMeasureField;
