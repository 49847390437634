import React, { ReactNode, useEffect, useState } from 'react';
import { Label, LabelText, TextInput } from '../../shared/BlockWidgets';
import { MultilinedText } from '../../shared/MultilinedText';
import { CommentButton } from '../../shared/Comment';
import styles from './MultipleChoice.module.css';
import {
  MultipleChoiceBlock,
  isOtherOptionSelected,
} from 'concepts/Extraction/Blocks/MultipleChoice/MultipleChoiceBlock';
import { useDescribedBy } from 'hooks/useAccessibleName';
import { NakedButton } from 'system/base/Button';
import { useDebouncedFunction } from 'hooks/useDebouncedFunction';

export type ReadonlyMultipleChoiceProps = {
  block: MultipleChoiceBlock;
};

export type EditableMultipleChoiceProps = ReadonlyMultipleChoiceProps & {
  editable: true;
  value: string | null;
  comments: string | null;
  onChange: (newValue: string | null) => void;
  onChangeComments: (newComments: string) => void;
};

export type MultipleChoiceProps =
  | ReadonlyMultipleChoiceProps
  | EditableMultipleChoiceProps;

const isEditableMultipleChoiceProps = (
  props: MultipleChoiceProps
): props is EditableMultipleChoiceProps =>
  !!(props as EditableMultipleChoiceProps).editable;

type RenderCommentsProps = {
  comments: EditableMultipleChoiceProps['comments'];
  blockLabel: MultipleChoiceBlock['label'];
  onChangeComments: EditableMultipleChoiceProps['onChangeComments'];
};

const renderCommentsButton = ({
  comments,
  blockLabel,
  onChangeComments,
}: RenderCommentsProps): JSX.Element => (
  <CommentButton
    comments={comments}
    blockLabel={blockLabel}
    onChangeComments={onChangeComments}
  />
);

const renderClearSelection = (
  onChange: EditableMultipleChoiceProps['onChange']
): JSX.Element => (
  <NakedButton onClick={() => onChange(null)}>
    Clear above selection
  </NakedButton>
);

const RadioWrapper = (props: { label: string; children: ReactNode }) => {
  const { label, children } = props;
  return (
    <li className={styles.listItem}>
      <label className={styles.optionLabel}>
        {children}
        <span className={styles.optionLabelText}>{label}</span>
      </label>
    </li>
  );
};

const EditableRadio = (props: EditableMultipleChoiceProps) => {
  const { block, value } = props;

  return (
    <>
      {block.options.map((option) => (
        <RadioWrapper key={option.id} label={option.label}>
          <input
            type="radio"
            name={props.block.id}
            value={option.id}
            className={styles.optionInput}
            checked={option.id === value}
            onChange={() => {
              props.onChange(option.id);
            }}
          />
        </RadioWrapper>
      ))}
    </>
  );
};

const ReadOnlyRadio = (props: ReadonlyMultipleChoiceProps) => {
  return (
    <>
      {props.block.options.map((option) => (
        <RadioWrapper key={option.id} label={option.label}>
          <input
            type="radio"
            name={props.block.id}
            value={option.id}
            className={styles.optionInput}
            disabled={true}
          />
        </RadioWrapper>
      ))}
    </>
  );
};

const OtherTextField = (props: {
  value?: string;
  disabled: boolean;
  name: string;
  className: string;
  onChange?: (newValue: string | null) => void;
}) => {
  const { onChange } = props;
  const [fieldValue, setFieldValue] = useState(props.value);
  const noop = () => '';
  const debouncedCallback = useDebouncedFunction(onChange || noop, 300);

  useEffect(() => {
    setFieldValue(props.value);
  }, [props.value]);

  return (
    <TextInput
      {...props}
      value={fieldValue}
      onChange={(event) => {
        setFieldValue(event.target.value);
        onChange && debouncedCallback(event.target.value);
      }}
    />
  );
};

export const MultipleChoice = (props: MultipleChoiceProps): JSX.Element => {
  const [descriptionID, describedBy] = useDescribedBy();
  const isEditable = isEditableMultipleChoiceProps(props);

  const [selectedValue, setSelectedValue] = useState<string | null>(
    (isEditable && props.value) || null
  );

  const callback = isEditable ? props.onChange : () => '';
  const debounced = useDebouncedFunction(callback, 300);

  return (
    <>
      <Label>
        <div className={styles.labelOuter}>
          <div>
            <LabelText>{props.block.label}</LabelText>
            {props.block.description !== '' && (
              <div id={descriptionID} className={styles.description}>
                <MultilinedText>{props.block.description}</MultilinedText>
              </div>
            )}
          </div>
          {isEditable &&
            renderCommentsButton({
              comments: props.comments,
              blockLabel: props.block.label,
              onChangeComments: props.onChangeComments,
            })}
        </div>
      </Label>
      <ol className={styles.list} role="radiogroup" {...describedBy}>
        {isEditable ? (
          <EditableRadio
            {...props}
            value={selectedValue}
            onChange={(newValue) => {
              setSelectedValue(newValue);
              debounced(newValue);
            }}
          />
        ) : (
          <ReadOnlyRadio {...props} />
        )}
        {props.block.otherOption && (
          <li key={props.block.otherOption.id} className={styles.listItem}>
            <label className={styles.optionLabel}>
              <input
                type="radio"
                name={props.block.id}
                value={props.block.otherOption.id}
                className={styles.optionInput}
                {...(isEditable
                  ? {
                      checked: isOtherOptionSelected(props.block, props.value),
                      onChange: () => {
                        setSelectedValue('');
                        debounced('');
                      },
                    }
                  : {
                      disabled: true,
                    })}
              />
              <span className={styles.optionLabelText}>Other</span>
              <OtherTextField
                name={`${props.block.id}_text`}
                className={styles.otherOptionText}
                {...(isEditable
                  ? {
                      disabled: !isOtherOptionSelected(
                        props.block,
                        props.value
                      ),
                      onChange: props.onChange,
                      value: isOtherOptionSelected(props.block, props.value)
                        ? props.value || ''
                        : '',
                    }
                  : {
                      disabled: true,
                    })}
              />
            </label>
          </li>
        )}
      </ol>
      {isEditable &&
        renderClearSelection((value) => {
          setSelectedValue(value);
          props.onChange(value);
        })}
    </>
  );
};
