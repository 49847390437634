import React, { useState } from 'react';
import { UseFieldArrayReturn, useFieldArray } from 'react-hook-form';
import styles from '../DefaultTemplate.module.scss';
import QaConfirmationDialog from '../../QaConfirmationDialog';
import { QualityAssessmentTemplateState } from '../../QualityAssessmentTemplate';
import {
  validate,
  NO_EQUIVALENT_VALUE,
} from '../../../components/editableFieldValidation';
import EditableField from './EditableField';
import { Heading, Icon, IconList, Link } from 'components/core';

const RESERVED_FIELD_NAMES = ['all outcomes'];
const MAX_OUTCOME_GROUP_NAME_LENGTH = 1000;

const OutcomeGroupsSection = () => {
  const outcomeGroups = useFieldArray({
    name: 'outcome_groups',
  }) as UseFieldArrayReturn<QualityAssessmentTemplateState, 'outcome_groups'>;

  const [modal, setModal] = useState<React.ReactElement | null>();

  return (
    <section className={styles.DefaultTemplate_section}>
      {modal}
      <Heading
        type="h5"
        component="h3"
        variant="brand"
        className={styles.heading}
      >
        Outcome groups
        <Link
          className={styles.HelpLink}
          onClick={() => ''}
          data-pendo-key="qat-outcome-groups"
          aria-label="Get help with outcome groups"
        >
          <Icon icon={IconList.light.faQuestionCircle} />
        </Link>
      </Heading>

      <ul>
        {outcomeGroups.fields.map((field, index) => {
          return (
            <li key={field.id}>
              <EditableField
                index={index}
                name={`outcome_groups`}
                onDelete={() => {
                  if (field.prompt_before_deleting) {
                    setModal(
                      <QaConfirmationDialog
                        onConfirm={() => outcomeGroups.remove(index)}
                        onClose={() => setModal(null)}
                      />
                    );
                    return;
                  }
                  outcomeGroups.remove(index);
                }}
                validate={(value, existingFields) => {
                  return validate(
                    value,
                    existingFields,
                    RESERVED_FIELD_NAMES,
                    NO_EQUIVALENT_VALUE,
                    MAX_OUTCOME_GROUP_NAME_LENGTH
                  );
                }}
              />
            </li>
          );
        })}
      </ul>

      <Link
        onClick={() =>
          outcomeGroups.append({ name: '', prompt_before_deleting: false })
        }
        className={styles.SectionButton}
        variant="primary"
      >
        <Icon icon={IconList.light.faPlus} /> Add group
      </Link>
    </section>
  );
};

export default OutcomeGroupsSection;
