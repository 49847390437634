import {
  tokenizeRoute,
  REVIEW_IMPORT_SOURCES,
  REVIEW_IMPORT_SOURCES_DELETE,
} from '../routes';
import { getReviewId } from './index';
import { getCsrfToken } from 'util/authenticityToken';

interface GetReviewImportSourcesResponse {
  reviewImportSources: string[];
  customReviewImportSources: { id: string; sourceName: string }[];
}

interface CreateReviewImportSourceResponse {
  id?: string;
  sourceName?: string;
  message?: string;
}

interface DeleteReviewImportSourceResponse {
  id?: string;
  sourceName?: string;
  message?: string;
}

/**
 * Gets a list of review import sources
 */
export const getReviewImportSources = async (): Promise<GetReviewImportSourcesResponse> => {
  const reviewId = getReviewId();
  if (!reviewId)
    throw new Error("Couldn't get Review ID. Is this part of a review?");

  const url = tokenizeRoute(REVIEW_IMPORT_SOURCES, {
    review_id: reviewId,
  });

  return fetch(url.toString(), {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  }).then((res) => res.json());
};

/**
 * Creates a review import source for a given review
 */
export const createReviewImportSource = async (
  sourceName: string
): Promise<CreateReviewImportSourceResponse> => {
  const reviewId = getReviewId();

  if (!reviewId)
    throw new Error("Couldn't get Review ID. Is this part of a review?");

  const url = tokenizeRoute(REVIEW_IMPORT_SOURCES, {
    review_id: reviewId,
  });

  return fetch(url.toString(), {
    method: 'POST',
    body: JSON.stringify({ source_name: sourceName }),
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': getCsrfToken(),
    },
  }).then((res) => res.json());
};

/**
 * Deletes a review import source for a given review
 */
export const deleteReviewImportSource = async (
  id: string,
  sourceName: string
): Promise<DeleteReviewImportSourceResponse> => {
  const reviewId = getReviewId();

  if (!reviewId)
    throw new Error("Couldn't get Review ID. Is this part of a review?");

  const url = tokenizeRoute(REVIEW_IMPORT_SOURCES_DELETE, {
    review_id: reviewId,
    id: id,
  });

  return fetch(url.toString(), {
    method: 'DELETE',
    body: JSON.stringify({ source_name: sourceName }),
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': getCsrfToken(),
    },
  }).then((res) => res.json());
};
