import { useEffect, useState } from 'react';

declare global {
  interface Window {
    Headway: any;
  }
}

interface Props {
  trigger: string;
  selector: string;
}

export const useHeadway = ({ trigger, selector }: Props) => {
  const [headwayMessageCount, setHeadwayMessageCount] = useState<number>(0);
  const [isHeadwayInitialized, setIsHeadwayInitialized] = useState(false);
  const [isHeadwayMenuOpen, setIsHeadwayMenuOpen] = useState(false);

  useEffect(() => {
    const headwayScript = document.createElement('script');
    headwayScript.type = 'text/javascript';
    headwayScript.async = true;
    headwayScript.src = '//cdn.headwayapp.co/widget.js';

    headwayScript.addEventListener(
      'load',
      () => {
        initialiseHeadway({ trigger, selector });
      },
      { once: true }
    );

    document.body.appendChild(headwayScript);
  }, [trigger, selector]);

  const initialiseHeadway = ({ trigger, selector }: Props): void => {
    let isOpening = false;
    const config = {
      selector,
      trigger,
      account: 'xY8k5y',
      // On a large screen the headway menu opens to the right
      // This forces it to open on the left, in the same spot as the help dropdown
      position: {
        x: 'left',
      },
      callbacks: {
        onWidgetReady: (widget: any) => {
          setIsHeadwayInitialized(true);

          setHeadwayMessageCount(widget.getUnseenCount());
        },
        onShowWidget: () => {
          isOpening = true;
          setIsHeadwayMenuOpen(true);

          // Headway stops click event propagation. Trigger a separate click for global listeners
          // unfortunately this triggers onHideWidget too, so we have to work around it
          document.body.click();
          window.Headway.show();
        },
        onHideWidget: () => {
          if (!isOpening) {
            setIsHeadwayMenuOpen(false);
          }
          isOpening = false;
          setHeadwayMessageCount(0);
        },
      },
    };

    window.Headway.init(config);
  };

  return {
    isHeadwayInitialized,
    isHeadwayMenuOpen,
    headwayMessageCount,
  };
};

export default useHeadway;
