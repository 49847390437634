import React from 'react';
import { SinglePDFUploader } from '..';
import { ImportJob } from '../../index';

interface Props {
  reviewId: string;
  importJob: ImportJob;
  onProcessed: (job: ImportJob) => void;
}

const SinglePDFUploaderSection = ({
  reviewId,
  importJob,
  onProcessed,
}: Props) => {
  const onSingleFileProcessed = (job: ImportJob): void => {
    onProcessed(job);
  };

  return (
    <ul>
      {importJob.expected_files.map((expectedFile) => (
        <li key={expectedFile.attachable_id}>
          <SinglePDFUploader
            reviewId={reviewId}
            importJobId={importJob.id}
            expectedFile={expectedFile}
            onProcessed={onSingleFileProcessed}
          />
        </li>
      ))}
    </ul>
  );
};

export default SinglePDFUploaderSection;
