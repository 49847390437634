import React, { ReactNode } from 'react';
import classNames from 'classnames';
import styles from '../Message.module.scss';

interface Props {
  children: ReactNode;
  className?: string;
}

const MessageControls = ({ children, className }: Props) => {
  return (
    <div className={classNames(styles.MessageControls, className)}>
      {children}
    </div>
  );
};

export default MessageControls;
