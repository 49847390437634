import React, { FunctionComponent } from 'react';
import { gql, useQuery } from '@apollo/client';
import { PrimaryLink, TertiaryLink } from 'system/base/Link';
import DataWithControls from 'system/layout/DataWithControls';
import Panel from 'system/elements/Panel';
import Flex from 'system/base/Flex';
import Box from 'system/base/Box';
import { InlineText } from 'system/base/Text';
import Metric from 'system/elements/Metric';
import { Link } from 'system/base/Link';
import {
  OrganizationOverviewCountsQuery,
  Scalars,
  OrganizationOverviewCountsQueryVariables,
} from 'types/graphql';
import Notice from 'system/elements/Notice';
import ActivitySpinner from 'components/ActivitySpinner';
import { recordToQueryString } from 'util/queryString';
import SearchInput from 'system/elements/SearchInput';

const QUERY = gql`
  query OrganizationOverviewCounts($organizationId: ID!) {
    organization: node(id: $organizationId) {
      id
      ... on Organization {
        reviews {
          total
        }
        authors {
          total
        }
        unassignedReviews: reviews(filter: { assignedAdministrator: null }) {
          total
        }
      }
    }
  }
`;

interface Props {
  organizationId: Scalars['ID'];
}

const Overview: FunctionComponent<Props> = ({ organizationId }) => {
  const { data, error, loading } = useQuery<
    OrganizationOverviewCountsQuery,
    OrganizationOverviewCountsQueryVariables
  >(QUERY, { variables: { organizationId } });

  const organization = data && data.organization;

  const handleSearchSubmit = (q: string) => {
    window.location.href = `search${recordToQueryString({ q })}`;
  };

  return (
    <DataWithControls
      filters={
        <SearchInput
          placeholder="Find a person or review"
          onSearch={handleSearchSubmit}
        />
      }
      actions={
        <PrimaryLink href={`/reviews/new?organization_id=${organizationId}`}>
          Create a review
        </PrimaryLink>
      }
    >
      <Box boxShadow="light" colors="onWhite" p={2}>
        {error && <Notice type="error">{error.message}</Notice>}
        <Flex>
          <Panel
            width={1 / 4}
            marginRight={2}
            iconName="file-alt"
            title="Total Reviews"
          >
            {loading && <ActivitySpinner />}
            {organization && (
              <>
                <Metric textStyle="dashboardMetric">
                  {organization.reviews.total}
                </Metric>
                <Link href="reviews">View all</Link>
              </>
            )}
          </Panel>
          <Panel
            width={1 / 4}
            marginRight={2}
            iconName="user"
            title="Total Authors"
          >
            {loading && <ActivitySpinner />}
            {organization && (
              <>
                <Metric textStyle="dashboardMetric">
                  {organization.authors.total}
                </Metric>
                <Link href="authors">View all</Link>
              </>
            )}
          </Panel>
          <Panel width={1 / 2} iconName="bell" title="Alerts">
            {loading && <ActivitySpinner />}
            {organization &&
              (organization.unassignedReviews.total === 0 ? (
                'All reviews are currently assigned to an administrator.'
              ) : (
                <>
                  <InlineText fontWeight="bold">
                    <Metric inline>
                      {organization.unassignedReviews.total}
                    </Metric>{' '}
                    reviews{' '}
                  </InlineText>
                  currently don’t have an administrator assigned
                  <Box>
                    <TertiaryLink
                      href={`reviews?assignedAdministrator=null`}
                      style={{ marginTop: 8 }}
                    >
                      Assign an administrator
                    </TertiaryLink>
                  </Box>
                </>
              ))}
          </Panel>
        </Flex>
      </Box>
    </DataWithControls>
  );
};

export default Overview;
