import React from 'react';

import { Study } from './StudyTypes';
import { PrimaryButton } from 'system/base/Button';
import { addTagsToStudies } from 'legacyBridge/bulkTagging';

export interface SaveTagsToStudyControlProps {
  study: Study;
  tags: string[];
  onSuccess: () => void;
  onFailure: (error: Error) => void;
}

export default function SaveStudyTagsButton({
  study,
  tags,
  onSuccess,
  onFailure,
}: SaveTagsToStudyControlProps): JSX.Element {
  return (
    <PrimaryButton
      onClick={() => {
        addTagsToStudies(tags, [study.id])
          .then(onSuccess)
          .catch((e) => {
            MetaError.notify(e);
            onFailure(e);
          });
      }}
    >
      Save
    </PrimaryButton>
  );
}
