import { splitAt, update } from 'ramda';
import { generateBlockID } from '../generateBlockID';
import { OptionBlock, OtherOptionBlock } from '../shared/Option';
import { TemplateBlock } from '../BaseBlock';
import { BlockValue } from 'concepts/Extraction/BlocksData';

export type CheckboxesBlockOption = OptionBlock;
export type CheckboxesBlockOtherOptions = OtherOptionBlock;

export interface CheckboxesBlock extends TemplateBlock {
  type: 'Checkboxes';
  label: string;
  description: string;
  options: CheckboxesBlockOption[];
  otherOption: CheckboxesBlockOtherOptions | null;
}

export const blockIsCheckboxes = (
  block: TemplateBlock
): block is CheckboxesBlock => block.type === 'Checkboxes';

export const newCheckboxesBlock = (
  label: string,
  extraProps?: Partial<CheckboxesBlock>
): CheckboxesBlock => ({
  type: 'Checkboxes',
  id: generateBlockID(),
  label,
  description: '',
  options: [],
  otherOption: null,
  ...extraProps,
});

export const updateLabel = (checkboxes: CheckboxesBlock, label: string) => ({
  ...checkboxes,
  label,
});

/**
 * Adds a new option to the Checkboxes block after a specified option ID.
 * If no option ID is specified, add the new option as the first option.
 *
 * @param Checkboxes - the CheckboxesBlock
 * @param option - the new option to add
 * @param afterID - the id of the option to add the new option after. In other
 * words, it is the id of the option that should appear before the new option.
 * @returns updated CheckboxesBlock
 */
export const addOption = (
  checkboxes: CheckboxesBlock,
  option: CheckboxesBlockOption,
  afterID: string | null
): CheckboxesBlock => {
  if (afterID === null) {
    return {
      ...checkboxes,
      options: [option, ...checkboxes.options],
    };
  }

  const afterOptionIndex = checkboxes.options.findIndex(
    ({ id }) => id === afterID
  );

  if (afterOptionIndex === -1) {
    throw new Error(`Cannot add option, invalid option id: ${afterID}`);
  }

  const [optionsBefore, optionsAfter] = splitAt(
    afterOptionIndex + 1,
    checkboxes.options
  );

  return {
    ...checkboxes,
    options: [...optionsBefore, option, ...optionsAfter],
  };
};

export const removeOption = (
  checkboxes: CheckboxesBlock,
  id: string
): CheckboxesBlock => {
  const optionIndex = checkboxes.options.findIndex(
    (option) => option.id === id
  );

  if (optionIndex === -1) {
    throw new Error(`Cannot remove option, invalid option id: ${id}`);
  }

  const optionsBeforeRemovedOption = checkboxes.options.slice(0, optionIndex);
  const optionsAfterRemovedOption = checkboxes.options.slice(optionIndex + 1);

  return {
    ...checkboxes,
    options: [...optionsBeforeRemovedOption, ...optionsAfterRemovedOption],
  };
};

export const updateOption = (
  checkboxes: CheckboxesBlock,
  updatedOption: CheckboxesBlockOption
): CheckboxesBlock => {
  const optionIndex = checkboxes.options.findIndex(
    (option) => option.id === updatedOption.id
  );

  if (optionIndex === -1) {
    throw new Error(
      `Cannot update option, invalid option id: ${updatedOption.id}`
    );
  }

  return {
    ...checkboxes,
    options: update(optionIndex, updatedOption, checkboxes.options),
  };
};

export type CheckboxesBlockValue = {
  options: Record<string, boolean>;
  otherOption: {
    selected: boolean;
    value: string | null;
  };
};

export const isCheckboxesBlockValue = (
  value: BlockValue
): value is CheckboxesBlockValue =>
  Object.prototype.hasOwnProperty.call(value, 'options');

export const isOtherOptionSelected = (
  { options }: CheckboxesBlock,
  value: string | null
): boolean => {
  if (value === null) return false;
  const valueIsOptionId = options.some(({ id }) => id === value);
  return !valueIsOptionId;
};
