import { useState, useCallback, useRef } from 'react';

const useStateLater = <T = any>(defaultValue: T) => {
  const [value, setImmediateValue] = useState<T>(defaultValue);
  const timeoutRef = useRef<NodeJS.Timeout | undefined>();

  const setValue = useCallback(
    (value: T, delay?: number) => {
      // cancel any potentially pending state update
      if (timeoutRef.current) clearTimeout(timeoutRef.current);

      if (delay) {
        timeoutRef.current = setTimeout(() => {
          setImmediateValue(value);
        }, delay);
      } else {
        setImmediateValue(value);
      }
    },
    [setImmediateValue]
  );

  return [value, setValue] as const;
};

export default useStateLater;
