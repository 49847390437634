import React from 'react';
import styles from './ChoosePlanButton.module.css';
import { Button } from 'components/core';

export interface ChoosePlanButtonProps {
  size?: 'sm' | 'md';
}

export const ChoosePlanButton = ({ size = 'md' }: ChoosePlanButtonProps) => {
  return (
    <Button
      className={styles.ChoosePlanButton}
      to={'/plans'}
      size={size}
      type="success"
    >
      Choose plan
    </Button>
  );
};
