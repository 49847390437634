import React from 'react';
import TextField from 'system/elements/TextField';

interface OtherRoleTextFieldProps {
  objectName: string;
  defaultOtherRole: string;
}

export const OtherRoleTextField = ({
  defaultOtherRole,
  objectName,
}: OtherRoleTextFieldProps) => (
  <div className="input form-group">
    <TextField
      defaultValue={defaultOtherRole}
      // form-control used here to provide consistent outline on this field to other Signup form fields
      inputClassName="form-control"
      name={`${objectName}[role_other]`}
      label="Please specify your role:"
    ></TextField>
  </div>
);
