import React from 'react';
import styles from './Pagination.module.css';
import { Link } from 'system/base/Link';
import Icon from 'system/base/Icon';

export interface PaginationProps {
  meta: PaginationMeta;
}

export interface PaginationMeta {
  fromItem: number;
  toItem: number;
  totalItems: number;
  prevUrl?: string;
  nextUrl?: string;
}

export function Pagination({
  meta: { fromItem, toItem, totalItems, prevUrl, nextUrl },
}: PaginationProps): JSX.Element {
  return (
    <div data-testid="pagination" className={styles.pagination}>
      {prevUrl ? (
        <Link data-testid="prev-link" href={prevUrl}>
          <Icon name="arrow-left" />
          Prev
        </Link>
      ) : (
        <span>
          <Icon name="arrow-left" /> Prev
        </span>
      )}
      <span data-testid="current-items" className={styles.currentItems}>
        Studies {fromItem} to {toItem} of {totalItems}
      </span>
      {nextUrl ? (
        <Link data-testid="next-link" href={nextUrl}>
          Next
          <Icon name="arrow-right" />
        </Link>
      ) : (
        <span>
          Next <Icon name="arrow-right" />
        </span>
      )}
    </div>
  );
}
