import React from 'react';
import styles from './AppFooter.module.scss';
import { Link } from 'components/core';
import { GrooveManager } from 'components/shared/PrimaryNavigation';

const grooveManager = GrooveManager.getInstance();

const AppFooter = () => {
  return (
    <footer className={styles.AppFooter}>
      <ul className={styles.FooterList}>
        <li className={styles.FooterItem}>
          &copy; {new Date().getFullYear()} Covidence
        </li>
        <li className={styles.FooterItem}>
          <Link to={'https://www.covidence.org/terms'} variant="medium">
            Terms
          </Link>
        </li>
        <li className={styles.FooterItem}>
          <Link to={'https://www.covidence.org/privacy'} variant="medium">
            Privacy
          </Link>
        </li>
        {grooveManager.isActive() ? (
          <li className={`${styles.FooterItem} ${styles.MobileLink}`}>
            <Link onClick={() => grooveManager.setOpen(true)} variant="medium">
              Help
            </Link>
          </li>
        ) : null}
      </ul>
    </footer>
  );
};

export default AppFooter;
