import React from 'react';
import CreatableSelect from 'react-select/creatable';
import { uniq } from 'underscore';
import Label from 'system/base/Label';

interface TagsProps {
  objectName: string;
  tags: Array<string>;
}

export const Tags = ({ objectName, tags }: TagsProps) => {
  const options = [
    { label: 'Admin', value: 'Admin' },
    { label: 'HIU', value: 'HIU' },
    { label: 'Organisational', value: 'Organisational' },
    { label: 'Paid', value: 'Paid' },
  ];
  const selectedOptions = options.filter(({ value }) => tags.includes(value));
  tags.forEach((tag) => selectedOptions.push({ label: tag, value: tag }));
  return (
    <div className="input form-group select" data-testid="reviewer-tags">
      <Label className="control-label select optional">Tags</Label>
      <CreatableSelect
        classNamePrefix="tags"
        defaultValue={uniq(selectedOptions, (option) => option.label)}
        isMulti
        options={options}
        name={`${objectName}[tags][]`}
      />
    </div>
  );
};
