import React, { Component } from 'react';
import PropTypes from 'prop-types';

const colors = ['#597CD9', '#59D9B7', '#D95959'];
const greyscaleColor = '#a6a6a6';

function numericHash(string) {
  let hash = 0;
  for (let i = 0; i < string.length; i++) {
    hash += string.charCodeAt(i);
  }
  return hash;
}

function chooseColor(uid, offset) {
  const colorNumber = numericHash(uid.toString()) + offset;
  return colors[colorNumber % colors.length];
}

class Avatar extends Component {
  constructor() {
    super();
    this.state = {
      clickCounter: 0,
    };

    this.changeColor = this.changeColor.bind(this);
  }

  changeColor() {
    this.setState(({ clickCounter }) => ({
      clickCounter: 1 + clickCounter,
    }));
  }

  render() {
    const { name, greyscale, className } = this.props;
    const splitName = (name || '').split(/\s+/);
    let letters = splitName[0][0];

    if (splitName.length > 1) {
      letters += splitName[1][0];
    }

    const style = {
      backgroundColor: greyscale
        ? greyscaleColor
        : chooseColor(name, Math.floor(this.state.clickCounter / 3)),
    };

    /* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
    return (
      <div
        className={className}
        style={style}
        onClick={this.changeColor}
        title={name}
      >
        <span className="pii">{letters}</span>
      </div>
    );
    /* eslint-enable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
  }
}

Avatar.defaultProps = {
  className: 'avatar--large',
  greyscale: false,
};

Avatar.propTypes = {
  name: PropTypes.string.isRequired,
  greyscale: PropTypes.bool,
  className: PropTypes.string,
};

export default Avatar;
