import React, { useContext, useMemo } from 'react';
import classNames from 'classnames';
import { useInView } from 'react-intersection-observer';
import { useFormContext, useWatch } from 'react-hook-form';
import { TableBlock } from '../TableBlock';
import { ComparisonRowProps } from '../../BlockRenderer';
import { ColumnComparisonBlock } from './ColumnComparisonBlock';
import styles from './TableComparisonRow.module.css';
import comparisonTableStyles from 'concepts/Extraction/Compare/ComparisonTable/ComparisonTable.module.css';
import { ComparisonTableAccordion } from 'concepts/Extraction/Compare/ComparisonTableAccordion';
import { getUnresolvedCellCountForTable } from 'concepts/Extraction/ViewModel/tables';
import { BlockRecord } from 'concepts/Extraction/BlocksData';
import { ComparisonTableContext } from 'concepts/Extraction/Compare/ComparisonTable/ComparisonTable';
import { Comment } from 'concepts/Extraction/Compare/CommentPopover/types';

type TableComparisonRowProps = Omit<ComparisonRowProps, 'block'> & {
  block: TableBlock;
  renderOnVisible?: boolean;
};

export const TableComparisonRow = ({
  block,
  reviewerRecords,
  onResolveTableCell,
  readonly,
  renderOnVisible = false,
}: TableComparisonRowProps): JSX.Element | null => {
  const formContext = useFormContext();

  const unresolvedCellIDs = useWatch({
    control: formContext.control,
    name: `unresolvedTableCells.${block.id}`,
  });

  const { reviewerNames } = useContext(ComparisonTableContext);

  const { rows, columns, label, id } = block;

  const unresolvedCellCount = getUnresolvedCellCountForTable(unresolvedCellIDs);

  /**
   * Gets the table block records from all reviewers.
   * If no table data exist in reviewerRecord, assign a default empty record value so that
   * an empty container button is still rendered even if there is no data.
   */
  const tableReviewerRecords = useMemo(() => {
    const emptyRecord: BlockRecord = {
      value: null,
    };

    return reviewerRecords.map((record) => record?.[id] ?? emptyRecord);
  }, [id, reviewerRecords]);

  const { ref, inView } = useInView({
    // We use an iframe to render PDFs which breaks this library's ability to find
    // a reference point, thus we have to give it an element to anchor to
    root: document.querySelector('#consensus-root-id'),
    rootMargin: '1000px 0px',
    triggerOnce: true,
    skip: !renderOnVisible,
  });

  const getComments = (
    reviewerRecords: BlockRecord[],
    reviewerNames: string[]
  ): Comment[] =>
    reviewerRecords
      .map(({ comment }, index): Comment | null =>
        comment
          ? {
              comment,
              commenter: reviewerNames[index],
            }
          : null
      )
      .filter((item): item is Comment => item !== null);

  return (
    <ComparisonTableAccordion
      comments={getComments(tableReviewerRecords, reviewerNames)}
      label={label}
      conflictCount={unresolvedCellCount}
    >
      <table
        className={classNames(comparisonTableStyles.table, styles.table)}
        ref={ref}
      >
        {inView || !renderOnVisible ? (
          <tbody>
            {columns.map((column, index) => {
              return (
                <ColumnComparisonBlock
                  key={`${id}-${column.id}`}
                  column={column}
                  rows={rows}
                  columnNumber={index + 1}
                  blockId={block.id}
                  reviewerRecords={tableReviewerRecords}
                  onResolveTableCell={onResolveTableCell}
                  readonly={readonly}
                  tableResolutionState={
                    unresolvedCellIDs.length === 0
                      ? 'resolvedByConsensusReviewer'
                      : null
                  }
                />
              );
            })}
          </tbody>
        ) : null}
      </table>
    </ComparisonTableAccordion>
  );
};
