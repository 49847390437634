import idx from 'idx';
import pluralize from 'pluralize';
import React, { useState } from 'react';
import styles from './StudyDetails.module.css';
import DocumentLinks from 'concepts/Documents/DocumentLinks';
import { GuidelineStudy } from 'concepts/Guidelines/GuidelineStudy/GuidelineStudy';
import { Link } from 'system/base/Link';
import { Report, Study } from 'types/graphql';

export interface StudiesRollupProps {
  reviewStudies?: readonly any[];
}

// use a stricter type for study if we ever export this function
function singleStudy(study: any): JSX.Element {
  const references = idx(study, (_) => _.references.nodes);
  const firstReference = references[0];
  const documents =
    references &&
    references.flatMap((reference: Report) =>
      idx(reference, (_) => _.documents)
    );

  return (
    <div key={study.id}>
      <GuidelineStudy
        study={{
          ...study,
          abstract: firstReference.abstract,
          journalInfo: firstReference.journalInfo,
          publisherInfo: firstReference.publisherInfo,
        }}
        showAbstract={false}
      />
      {documents && <DocumentLinks documents={documents} />}
      <hr />
    </div>
  );
}

export default function StudiesRollup({
  reviewStudies,
}: StudiesRollupProps): JSX.Element {
  const [unrolled, setUnrolled] = useState<boolean>(false);
  const studyCount = reviewStudies?.length || 0;
  const toggle = () => setUnrolled(!unrolled);

  return (
    <>
      {reviewStudies && (
        <>
          <Link
            data-testid="study-toggle-link"
            iconName={unrolled ? 'caret-down' : 'caret-right'}
            onClick={toggle}
          >
            {unrolled ? 'Hide' : 'Show'} {studyCount} selected{' '}
            {pluralize('study', studyCount)}
          </Link>
          {!unrolled && (
            <span className={styles.subtleDescriptor}>
              {' '}
              ({reviewStudies.map((study: Study) => study.studyId).join(', ')})
            </span>
          )}
        </>
      )}
      {unrolled && reviewStudies && reviewStudies.map(singleStudy)}
    </>
  );
}
