import React, { useCallback } from 'react';
import {
  useForm,
  useFieldArray,
  SubmitHandler,
  FormProvider,
} from 'react-hook-form';
import styles from './CitationSourcesForm.module.scss';
import { referenceCountValidator } from './lib/referenceCountValidator';
import { CitationSourceFormRow } from './components/CitationSourceFormRow';
import { Button, Link, Text } from 'components/core';
import { updateCitationImportSources } from 'query/review';
import { CitationImportSource, CitationImportSourceFormData } from 'types';

export type FormValues = {
  citationImportSources: CitationImportSourceFormData[];
};
export interface Props {
  citationImportSources: CitationImportSource[];
  reviewImportSources: string[];
  citationImportId: string;
  citationImportTotalCitations: number;
  setSourcesPopoverIsOpen: (isOpen: boolean) => void;
  setStoredCitationImportSources: (data: CitationImportSource[]) => void;
  setStoredImportSourcesPlaceholderText: (placeholder: string) => void;
}

export const CitationSourcesForm = ({
  citationImportSources,
  reviewImportSources,
  citationImportId,
  citationImportTotalCitations,
  setSourcesPopoverIsOpen,
  setStoredCitationImportSources,
  setStoredImportSourcesPlaceholderText,
}: Props) => {
  const populateDefaultValues = useCallback(() => {
    const emptyRow = [
      { sourceName: '', numberOfCitations: citationImportTotalCitations },
    ];
    const existingRows = citationImportSources.map(
      ({ sourceName, numberOfCitations }) => {
        return {
          sourceName,
          // Default to 0 if the existing field is null
          numberOfCitations: numberOfCitations || 0,
        };
      }
    );
    return existingRows.length ? existingRows : emptyRow;
  }, [citationImportSources, citationImportTotalCitations]);

  const formMethods = useForm<FormValues>({
    mode: 'all',
    defaultValues: {
      citationImportSources: populateDefaultValues(),
    },
  });

  const { control, setError, formState, watch, handleSubmit } = formMethods;

  const { fields, append, remove } = useFieldArray({
    control: control,
    name: 'citationImportSources',
    rules: {
      validate: (citationImportSourceValues) =>
        referenceCountValidator(
          citationImportSourceValues,
          citationImportTotalCitations
        ),
    },
  });

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    const formattedData = data.citationImportSources.map(
      ({ sourceName, numberOfCitations }) => ({
        source_name: sourceName,
        number_of_citations: numberOfCitations,
      })
    );

    return updateCitationImportSources(citationImportId, formattedData)
      .then(({ citationImportSources, importSourcesPlaceholder }) => {
        setStoredCitationImportSources(citationImportSources);
        setStoredImportSourcesPlaceholderText(importSourcesPlaceholder);
        setSourcesPopoverIsOpen(false);
      })
      .catch(() => {
        setError('root.serverError', {
          message: 'Something went wrong, please try again later',
        });
      });
  };

  const usedCitationImportSources = watch('citationImportSources');

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.CitationSourcesForm_formRows}>
          <div className={styles.CitationSourcesForm_formRow}>
            <Text
              size="sm"
              component="span"
              className={styles.CitationSourcesForm_formCol}
            >
              Source
            </Text>
            <Text
              size="sm"
              component="span"
              className={styles.CitationSourcesForm_formCol}
            >
              # references
            </Text>
          </div>

          {fields.length === 0 && (
            <Text
              variant="weak"
              className={styles.CitationSourcesForm_noRowsMessage}
            >
              No sources added
            </Text>
          )}

          {fields.map((field, index) => {
            return (
              <div
                key={field.id}
                className={styles.CitationSourcesForm_formRow}
              >
                <CitationSourceFormRow
                  rowIndex={index}
                  sourceOptions={reviewImportSources}
                  usedSourceOptions={usedCitationImportSources}
                  removeRow={() => remove(index)}
                />
              </div>
            );
          })}
        </div>

        <div className={styles.CitationSourcesForm_formErrors}>
          {formState.errors.citationImportSources?.root?.message && (
            <Text variant="danger" size="xs">
              {formState.errors.citationImportSources?.root?.message}
            </Text>
          )}

          {formState.errors.root?.serverError?.message && (
            <Text variant="danger" size="xs">
              {formState.errors.root?.serverError?.message}
            </Text>
          )}
        </div>

        <div
          className={[
            styles.CitationSourcesForm_formFooter,
            styles.CitationSourcesForm_formRow,
          ].join(' ')}
        >
          <div className={styles.CitationSourcesForm_formCol}>
            {
              // Hide 'add sources' if all available sources are already assigned
              usedCitationImportSources.length !==
                reviewImportSources.length && (
                <Link
                  type="button"
                  variant="primary"
                  onClick={() => {
                    append(
                      { sourceName: '', numberOfCitations: 0 },
                      {
                        focusName: `citationImportSources.${usedCitationImportSources.length}.sourceName`,
                      }
                    );
                  }}
                >
                  + Add additional source
                </Link>
              )
            }
          </div>

          <div
            className={[
              styles.CitationSourcesForm_formActions,
              styles.CitationSourcesForm_formCol,
            ].join(' ')}
          >
            <Button
              htmlType="button"
              type="neutral"
              variant="outline"
              onClick={() => setSourcesPopoverIsOpen(false)}
            >
              Cancel
            </Button>

            <Button type="brand" isLoading={formState.isSubmitting}>
              Save
            </Button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};
