import React, { ReactNode, useState } from 'react';
import classNames from 'classnames';
import styles from './Message.module.scss';
import { Button, Icon, IconList } from 'components/core';
import { IconType } from 'components/core/Icon/Icon';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  icon?: IconType | false;
  className?: string;
  type?: 'primary' | 'info' | 'success' | 'warning' | 'danger';
  variant?: 'subtle' | 'prominent';
  dismissible?: boolean;
  onClose?: () => void;
}

const IconLookup = {
  primary: IconList.solid.faCircleCheck,
  info: IconList.solid.faCircleInfo,
  success: IconList.solid.faCircleCheck,
  warning: IconList.solid.faTriangleExclamation,
  danger: IconList.solid.faCircleXmark,
};

const Message = ({
  children,
  className,
  icon,
  type = 'info',
  variant = 'subtle',
  role = 'status',
  dismissible = true,
  onClose = () => '',
  ...props
}: Props) => {
  const [isOpen, setOpen] = useState(true);
  if (!isOpen) return <></>;

  if (icon !== false) {
    icon = icon ?? IconLookup[type];
  }

  return (
    <section
      className={classNames(
        styles.Message,
        className,
        styles[type],
        styles[variant]
      )}
      role={role}
      {...props}
    >
      {icon && (
        <div className={styles.IconContainer}>
          <Icon icon={icon} size="xl" className={styles.MessageIcon} />
        </div>
      )}
      <div className={styles.MessageContainer}>{children}</div>
      {dismissible && (
        <div className={styles.IconContainer}>
          <Button
            iconOnly
            size="xs"
            variant="ghost"
            aria-label="Dismiss message"
            className={styles.closeButton}
            onClick={() => {
              setOpen(false);
              onClose();
            }}
          >
            <Icon icon={IconList.light.faClose} size="lg">
              Close
            </Icon>
          </Button>
        </div>
      )}
    </section>
  );
};

export default Message;
