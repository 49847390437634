import React from 'react';
import { EditorTextbox } from '../../../FormBuilder/Widgets/EditorTextbox';
import {
  TableBlock,
  updateLabel,
} from 'concepts/Extraction/Blocks/Table/TableBlock';
import { TemplateBlock } from 'concepts/Extraction/Blocks/BaseBlock';

export interface TableEditorProps {
  block: TableBlock;
  placeholder?: string;
  onChange: (updatedTableBlock: TemplateBlock) => void;
  onComplete: () => void;
  onRemove: () => void;
}

export const TableEditor = ({
  block,
  onChange,
  onRemove,
  onComplete,
  placeholder,
}: TableEditorProps) => (
  <div>
    <EditorTextbox
      value={block.label}
      placeholder={placeholder || 'Table'}
      onChange={(event) => {
        const newLabel = event.target.value;
        onChange(updateLabel(block, newLabel));
      }}
      onKeyDown={(event) => {
        switch (event.key) {
          case 'Backspace':
            if (block.label == '') {
              event.preventDefault();
              onRemove();
            }
            break;
          case 'Enter':
            event.preventDefault();
            onComplete();
        }
      }}
    />
  </div>
);
