import React, { useState, useRef } from 'react';
import { gql, useMutation } from '@apollo/client';
import { ExecutionResult } from 'graphql';

import styles from './DocumentUploader.module.css';
import {
  GenerateSignedUploadUrlMutation,
  GenerateSignedUploadUrlInput,
  AddUploadedDocumentMutation,
  AddUploadedDocumentInput,
} from 'types/graphql';
import { SecondaryButton } from 'system/base/Button';
import LoadingSpinner from 'system/elements/Spinner';
import Icon from 'system/base/Icon';

const GENERATE_SIGNED_UPLOAD_URL = gql`
  mutation generateSignedUploadUrl($referenceId: ID!, $filename: String!) {
    generateSignedUploadUrl(
      input: { referenceId: $referenceId, filename: $filename }
    ) {
      success
      url
      errors {
        message
      }
    }
  }
`;

const ADD_UPLOADED_DOCUMENT = gql`
  mutation addUploadedDocument($referenceId: ID!, $filename: String!) {
    addUploadedDocument(
      input: { referenceId: $referenceId, filename: $filename }
    ) {
      success
      documents {
        id
        fileName
        url
        primaryDocument
      }
      errors {
        message
      }
    }
  }
`;

export type DocumentUploaderProps = {
  referenceId: string;
  updateDocuments: (documents: any) => void;
  displayFiles: () => void;
};

export function DocumentUploader({
  referenceId,
  updateDocuments,
  displayFiles,
}: DocumentUploaderProps) {
  const [generateSignedUploadUrl] = useMutation<
    GenerateSignedUploadUrlMutation,
    GenerateSignedUploadUrlInput
  >(GENERATE_SIGNED_UPLOAD_URL);
  const [addUploadedDocument] = useMutation<
    AddUploadedDocumentMutation,
    AddUploadedDocumentInput
  >(ADD_UPLOADED_DOCUMENT);

  const [uploading, setUploading] = useState<boolean>(false);

  const updateDisplayedDocuments = ({
    data,
    errors,
  }: ExecutionResult<AddUploadedDocumentMutation>) => {
    if (errors) return;

    setUploading(false);
    if (
      data &&
      data.addUploadedDocument &&
      data.addUploadedDocument.documents
    ) {
      updateDocuments(data.addUploadedDocument.documents);
      displayFiles();
    }
  };

  const attachUploadedDocumentToReference = (filename: string) => {
    addUploadedDocument({
      variables: {
        referenceId: referenceId,
        filename: filename,
      },
    }).then(updateDisplayedDocuments);
  };

  const uploadFileToS3 = (signedUrl: string, file: File, filename: string) => {
    if (signedUrl) {
      fetch(signedUrl, {
        method: 'PUT',
        body: file,
      }).then(() => {
        attachUploadedDocumentToReference(filename);
      });
    }
  };

  const doUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;

    setUploading(true);

    const file = e.target.files[0];
    const filename = file.name;
    generateSignedUploadUrl({
      variables: {
        referenceId: referenceId,
        filename: filename,
      },
    }).then((response) => {
      const url = response.data?.generateSignedUploadUrl?.url;
      if (url) {
        uploadFileToS3(url, file, filename);
      }
    });
  };

  const uploadEl = useRef<HTMLInputElement>(null);

  const loadingSpinner = uploading ? (
    <span>
      <LoadingSpinner className={styles.loadingSpinner} /> Uploading...
    </span>
  ) : null;

  return (
    <>
      <SecondaryButton
        disabled={uploading}
        onClick={() => uploadEl.current && uploadEl.current.click()}
      >
        <Icon name="cloud-upload-alt" /> Add full text
      </SecondaryButton>

      {loadingSpinner}

      <input
        data-testid="full-text-uploader"
        type="file"
        ref={uploadEl}
        onChange={doUpload}
        style={{ display: 'none' }}
      />
    </>
  );
}
