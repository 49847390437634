/** @jsxImportSource theme-ui */
import React, { ReactNode } from 'react';
import { Box, Flex, ThemeUIStyleObject } from 'theme-ui';

export type DialogPosition =
  | 'topRight'
  | 'topLeft'
  | 'bottomRight'
  | 'bottomLeft';

type PopoverDialogProps = React.ComponentPropsWithoutRef<'div'> & {
  isPopoverOpen: boolean;
  children: ReactNode;
  position?: DialogPosition;
};

const getDialogPositionStyles = (
  position?: DialogPosition
): ThemeUIStyleObject => {
  switch (position) {
    case 'topRight':
      return { bottom: '100%', left: '100%', justifyContent: 'flex-start' };
    case 'topLeft':
      return { bottom: '100%', right: '100%', justifyContent: 'flex-end' };
    case 'bottomRight':
      return { top: '100%', left: '100%', justifyContent: 'flex-start' };
    case 'bottomLeft':
      return { top: '100%', right: '100%', justifyContent: 'flex-end' };
    default:
      return {};
  }
};

export const PopoverDialog = ({
  isPopoverOpen,
  children,
  position,
  ...rest
}: PopoverDialogProps): JSX.Element => (
  <Flex
    sx={{
      position: 'absolute',
      width: '400px',
      /**
       * &:hover necessary for some reason for pointer to not appear
       * clickable when on comment.
       */
      '&:hover': {
        pointerEvents: 'none',
      },
      display: !isPopoverOpen && 'none',
      ...getDialogPositionStyles(position),
    }}
    onClick={(e) => {
      /**
       * Prevent clicking on comment acting as if clicking CompareButton
       */
      e.stopPropagation();
    }}
    {...rest}
  >
    <Box
      sx={{
        fontSize: 'body',
        border: 'panel',
        background: 'white',
        boxShadow: '0px 16px 24px 8px rgba(0, 0, 0, 0.2)',
        borderRadius: 3,
        padding: 2,
        cursor: 'auto',
        pointerEvents: 'auto',
      }}
    >
      {children}
    </Box>
  </Flex>
);
