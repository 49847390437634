const POLL_INTERVAL = 2000;

type ExportStatusResponse = {
  status: 'success';
  downloadUrl?: string;
  fileName?: string;
};

type PendingResponse = {
  status: 'pending';
  args: GetExportStatusArgs;
};

type FailureResponse = {
  status: 'failure';
  message: string;
};

type GetExportStatusArgs = {
  reviewId: string;
  exportId: number;
};

type ExportStatusData = {
  authenticatedUrl: string;
  failed: boolean;
  completedAt: string;
  fileName: string;
};

export type CallbackFunction = (
  result: ExportStatusResponse | FailureResponse
) => void;

type ExportPromiseType = Promise<
  PendingResponse | ExportStatusResponse | FailureResponse
>;

const getExportStatus = (args: GetExportStatusArgs): ExportPromiseType => {
  const { reviewId, exportId } = args;

  return fetch(`/reviews/${reviewId}/citation_exports/${exportId}.json`)
    .then((response: Response) => response.json())
    .then(
      (exportStatus): ExportPromiseType => {
        const {
          authenticatedUrl,
          failed,
          fileName,
          completedAt,
        } = exportStatus as ExportStatusData;

        if (failed) {
          return Promise.resolve({
            status: 'failure',
            message: 'Unable to download missing full text',
          });
        }

        if (!completedAt) {
          return Promise.resolve({ status: 'pending', args });
        }

        return Promise.resolve({
          status: 'success',
          downloadUrl: authenticatedUrl,
          fileName: fileName,
        });
      }
    )
    .catch(() =>
      Promise.reject({ message: 'Unable to export missing full text' })
    );
};

const exportStudies = (reviewId: string) => {
  return fetch(`/reviews/${reviewId}/citation_exports.json`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      export: {
        export_option: 'missing_full_text',
        reference_manager: 'endnote',
        category: 'select',
      },
    }),
  })
    .then((response: Response) => {
      if (!response.ok) return Promise.reject();
      return response.json();
    })
    .catch(() =>
      Promise.reject({
        message: 'Unable to create missing full text export',
      })
    );
};

const downloadExport = (url: string, fileName: string) => {
  return fetch(url)
    .then((response: Response) => {
      if (!response.ok) return Promise.reject();
      return response.blob();
    })
    .then((blob) => {
      // I don't know why we have to go through this rigamarole
      const fileUrl = URL.createObjectURL(blob);

      const anchor = document.createElement('a');
      anchor.href = fileUrl;
      anchor.download = fileName;

      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
      // This is apparently for cleaning up memory
      URL.revokeObjectURL(fileUrl);
    })
    .catch(() =>
      Promise.reject({ message: 'Unable to download the missing full text' })
    );
};

export const exportAndDownload = (reviewId: string) => (
  callback: CallbackFunction,
  exportStatusArgs?: { reviewId: string; exportId: number }
): void => {
  const failureCallback = (error: FailureResponse) => {
    callback({ status: 'failure', message: error.message });
  };

  const processPoll = (
    value: PendingResponse | ExportStatusResponse | FailureResponse
  ) => {
    if (value?.status == 'success') {
      downloadExport(value.downloadUrl as string, value.fileName as string)
        .then(() => callback({ status: 'success' }))
        .catch(failureCallback);
    } else if (value?.status == 'pending') {
      // Wait and retry here
      setTimeout(() => {
        exportAndDownload(reviewId)(callback, { ...value.args });
      }, POLL_INTERVAL);
    } else {
      failureCallback(value);
    }
  };
  // If we have the args, we don't go creating another export
  if (exportStatusArgs) {
    getExportStatus({
      reviewId: exportStatusArgs.reviewId,
      exportId: exportStatusArgs.exportId,
    })
      .then(processPoll)
      .catch(failureCallback);
    return;
  }

  // Export is created here
  exportStudies(reviewId)
    .then((body: { export_id: number; type: string }) =>
      getExportStatus({
        reviewId,
        exportId: body.export_id,
      })
    )
    .then(processPoll)
    .catch(failureCallback);
};
