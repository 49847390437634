import {
  withRequestRetry,
  defaultFetchOptions,
  _handleJsonResponse,
  RequestErrorHandler,
} from './DataExtraction';
import {
  DELETE_OUTCOME_RESULT,
  SAVE_RESULT,
  ANY_RESULTS_EXTRACTED,
  tokenizeRoute,
  CREATE_OUTCOME_RESULT,
  REPLACE_OUTCOME_RESULT,
  UPDATE_OUTCOME_RESULT_POSITIONS_ROUTE,
} from 'query/routes';
import { OutcomeResult } from 'types/DataExtraction';

export const saveResult = async (
  resultValue: string,
  reviewStudyId: string,
  outcome_result_id: string,
  arm_id: string,
  timepoint_id: string,
  onError?: RequestErrorHandler
) => {
  return withRequestRetry(
    () =>
      _saveResult(
        resultValue,
        reviewStudyId,
        outcome_result_id,
        arm_id,
        timepoint_id
      ),
    onError
  );
};

const _saveResult = async (
  value: string,
  reviewStudyId: string,
  outcome_result_id: string,
  arm_id: string,
  timepoint_id: string
) => {
  const url = tokenizeRoute(SAVE_RESULT, {
    review_study_id: reviewStudyId,
  });
  const requestBody = {
    value,
    outcome_result_id,
    arm_id,
    timepoint_id,
  };

  return fetch(url.toString(), {
    method: 'PUT',
    body: JSON.stringify(requestBody),
    ...defaultFetchOptions(),
  }).then((response) => {
    return _handleJsonResponse(response);
  });
};

export interface MeasureParams {
  result_type_id?: string;
  result_set_id?: string;
}

export const createOutcomeResult = async (
  createParams: MeasureParams,
  outcomeId: string,
  onError: RequestErrorHandler
) => {
  return withRequestRetry(
    () => _createOutcomeResult(createParams, outcomeId),
    onError
  );
};

const _createOutcomeResult = async (
  createParams: MeasureParams,
  outcomeId: string
) => {
  const url = tokenizeRoute(CREATE_OUTCOME_RESULT, {
    outcome_id: outcomeId,
  });
  const body = JSON.stringify(createParams);

  return fetch(url.toString(), {
    method: 'POST',
    body,
    ...defaultFetchOptions(),
  })
    .then((response) => {
      return _handleJsonResponse(response);
    })
    .then((response) => {
      return response.map((outcomeResult: OutcomeResult) => {
        return {
          id: outcomeResult.id,
          result_type_id: outcomeResult.result_type_id,
        } as OutcomeResult;
      });
    });
};

export const replaceOutcomeResult = async (
  replaceParams: MeasureParams,
  outcomeResultId: string,
  onError: RequestErrorHandler
) => {
  return withRequestRetry(
    () => _replaceOutcomeResult(replaceParams, outcomeResultId),
    onError
  );
};

const _replaceOutcomeResult = async (
  replaceParams: MeasureParams,
  outcomeResultId: string
) => {
  const url = tokenizeRoute(REPLACE_OUTCOME_RESULT, {
    outcome_result_id: outcomeResultId,
  });
  const body = JSON.stringify(replaceParams);

  return fetch(url.toString(), {
    method: 'PUT',
    body,
    ...defaultFetchOptions(),
  })
    .then((response) => {
      return _handleJsonResponse(response);
    })
    .then((response) => {
      return response.map((outcomeResult: OutcomeResult) => {
        return {
          id: outcomeResult.id,
          result_type_id: outcomeResult.result_type_id,
        } as OutcomeResult;
      });
    });
};

export const deleteOutcomeResult = async (
  outcomeResultId: string,
  onError: RequestErrorHandler
) => {
  return withRequestRetry(() => _deleteOutcomeResult(outcomeResultId), onError);
};

const _deleteOutcomeResult = async (outcomeResultId: string) => {
  const url = tokenizeRoute(DELETE_OUTCOME_RESULT, {
    outcome_result_id: outcomeResultId,
  });

  return fetch(url.toString(), {
    method: 'DELETE',
    ...defaultFetchOptions(),
  });
};

export const anyResultsExtracted = async (
  outcomeResultId: string,
  onError: RequestErrorHandler
) => {
  return withRequestRetry(() => _anyResultsExtracted(outcomeResultId), onError);
};

const _anyResultsExtracted = async (outcomeResultId: string) => {
  const url = tokenizeRoute(ANY_RESULTS_EXTRACTED, {
    outcome_result_id: outcomeResultId,
  });

  return fetch(url.toString(), {
    method: 'GET',
    ...defaultFetchOptions(),
  }).then((response) => {
    return _handleJsonResponse(response);
  });
};

export const updateOutcomeResultPositions = async (
  reviewStudyId: string,
  outcomeId: string,
  outcomeResults: OutcomeResult[],
  onError: RequestErrorHandler
) => {
  return withRequestRetry(
    () =>
      _updateOutcomeResultPositions(reviewStudyId, outcomeId, outcomeResults),
    onError
  );
};

const _updateOutcomeResultPositions = (
  reviewStudyId: string,
  outcomeId: string,
  outcomeResults: OutcomeResult[]
) => {
  const url = tokenizeRoute(UPDATE_OUTCOME_RESULT_POSITIONS_ROUTE, {
    review_study_id: reviewStudyId,
    outcome_id: outcomeId,
  });

  return fetch(url.toString(), {
    method: 'PUT',
    body: JSON.stringify({
      positions: outcomeResults.map((outcomeResult) => outcomeResult.id),
    }),
    ...defaultFetchOptions(),
  });
};
