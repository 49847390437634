import React, { useState } from 'react';

import { Modal, ModalBody, ModalHeader, Text } from 'components/core';

interface CollaboratorExpiredModalProps {
  isOpen: boolean;
  reviewOwner: string;
  reviewName: string;
}

export const CollaboratorExpiredModal = ({
  isOpen,
  reviewOwner,
  reviewName,
}: CollaboratorExpiredModalProps) => {
  const [isModalOpen, setIsModalOpen] = useState(isOpen);

  return (
    <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
      <ModalHeader>Trial Expired</ModalHeader>
      <ModalBody>
        <Text>
          This review&apos;s trial has expired, {reviewOwner} (review owner) can
          reactivate <strong>{reviewName}</strong> by choosing a Covidence Plan.
        </Text>
      </ModalBody>
    </Modal>
  );
};
