import React from 'react';
import Dialog, { Banner as DialogBanner } from 'system/base/Dialog';
import { PrimaryButton, NakedButton } from 'system/base/Button';

export interface ResetAllWarningDialogProps {
  progress: {
    started: number;
    completed: number;
  };
  isOpen: boolean;
  onConfirm: () => void;
  onDismiss: () => void;
}

function studiesInProgressOrCompleted({
  progress,
}: {
  progress: {
    started: number;
    completed: number;
  };
}): boolean {
  return progress.completed > 0 || progress.started > 0;
}

function BannerStudiesWarning({
  progress,
}: {
  progress: {
    started: number;
    completed: number;
  };
}): JSX.Element {
  const reportStudiesCount = (
    completed: number,
    started: number
  ): JSX.Element => (
    <>
      {!!completed && <strong>{completed} completed</strong>}
      {!!started && !!completed && ' and '}
      {!!started && <strong>{started} in-progress</strong>}
    </>
  );

  const studyPlural =
    progress.started + progress.completed === 1 ? 'study' : 'studies';

  if (studiesInProgressOrCompleted({ progress })) {
    return (
      <div>
        You currently have{' '}
        {reportStudiesCount(progress.completed, progress.started)} {studyPlural}
      </div>
    );
  } else {
    return <></>;
  }
}

export function ResetAllWarningDialog({
  progress,
  isOpen,
  onConfirm,
  onDismiss,
}: ResetAllWarningDialogProps) {
  return (
    <Dialog
      isOpen={isOpen}
      onDismiss={onDismiss}
      title="I want to start from scratch"
    >
      <DialogBanner type="warning">
        <div>
          <div>All items in the Editor will be deleted.</div>
          <BannerStudiesWarning progress={progress} />
        </div>
      </DialogBanner>

      {studiesInProgressOrCompleted({ progress }) ? (
        <p>
          Your team will have to return to all completed and in-progress studies
          to extract all data again and <b>reach consensus again</b>.
        </p>
      ) : (
        <p>
          By starting from scratch, all items will be removed from your
          template.
        </p>
      )}

      <p>Are you sure you want to proceed?</p>

      <PrimaryButton mr={2} onClick={onConfirm}>
        Yes, proceed
      </PrimaryButton>
      <NakedButton onClick={onDismiss}>Cancel</NakedButton>
    </Dialog>
  );
}
