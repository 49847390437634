import { useTable, UseTableOptions } from 'react-table';
import React, { useMemo } from 'react';
import {
  getReactTableColumns,
  getReactTableRowLabelColumn,
  getReactTableData,
} from './utils';
import styles from './BaseTable.module.css';
import { Header, RowData, TableData, UpdateCellValue } from './types';
import { EditableCell } from './EditableCell/EditableCell';

export interface BaseTableProps
  extends React.ComponentPropsWithoutRef<'table'> {
  editable?: boolean;
  columnHeaders: Header[];
  rowHeaders?: Header[];
  data?: TableData;
  updateCellValue?: UpdateCellValue;
}

// BaseTable is a generic abstraction that transforms our table data into a
// format that can be rendered by the react-table API.
export const BaseTable = ({
  rowHeaders = [],
  columnHeaders = [],
  data = [],
  editable,
  updateCellValue,
  ...tableProps
}: BaseTableProps) => {
  const hasColumnHeaders = !!columnHeaders.length;
  const hasRowHeaders = !!rowHeaders.length;

  const memoizedColumns = useMemo(() => {
    const reactTableColumns = getReactTableColumns(columnHeaders);

    return hasRowHeaders
      ? [getReactTableRowLabelColumn(rowHeaders), ...reactTableColumns]
      : reactTableColumns;
  }, [columnHeaders, rowHeaders, hasRowHeaders]);

  const memoizedData = useMemo(() => getReactTableData(data, rowHeaders), [
    data,
    rowHeaders,
  ]);

  const defaultColumn: NonNullable<
    UseTableOptions<RowData>['defaultColumn']
  > = editable
    ? {
        Cell: EditableCell,
      }
    : {};

  /**
   * @remarks
   * rowHeaders & columnHeaders are only needed as custom useTable props
   * to create accessible labels by EditableCells.
   */
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable<RowData>({
    columns: memoizedColumns,
    data: memoizedData,
    defaultColumn,
    updateCellValue,
    rowHeaders,
    columnHeaders,
  });

  /**
   * @remarks
   * At lease one column must exist for a table to render.
   */
  if (!hasColumnHeaders) return null;

  /**
   * @see {@link https://react-table.tanstack.com/docs/examples/basic} for
   * react-table example.
   * @see {@link https://react-table.tanstack.com/docs/api/useTable} for the
   * react-table useTable API.
   */
  return (
    <table className={styles.table} {...tableProps} {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          /**
           * @remark
           * The eslint react/jsx-key rule is disabled because eslint does not
           * recognize that getHeaderGroupProps() includes jsx keys.
           */
          /* eslint-disable react/jsx-key */
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(({ getHeaderProps, render }) => {
              const headerNode = render('Header');

              return headerNode ? (
                <th
                  className={styles.header}
                  scope={hasRowHeaders ? 'col' : undefined}
                  {...getHeaderProps()}
                >
                  {headerNode}
                </th>
              ) : (
                <td className={styles.header} {...getHeaderProps()}>
                  {headerNode}
                </td>
              );
            })}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(({ column, render, getCellProps }) =>
                column.id === 'rowLabel' ? (
                  <th
                    className={styles.header}
                    scope="row"
                    {...getCellProps()}
                    role="rowheader"
                  >
                    {render('Cell')}
                  </th>
                ) : (
                  <td {...getCellProps()}>{render('Cell')}</td>
                )
              )}
            </tr>
            /* eslint-enable react/jsx-key */
          );
        })}
      </tbody>
    </table>
  );
};
