import React, { useState } from 'react';
import styles from '../../containers/Comments/Comments.module.css';
import CommentForm, {
  CommentFormSubmitProps,
} from '../../concepts/Comments/CommentForm';
import Attribution from './Attribution';
import { NakedButton } from 'system/base/Button';

export interface ReplyProps {
  reply: any;
  handleEdit: (props: CommentFormSubmitProps) => Promise<any>;
}

export function Reply({ reply, handleEdit }: ReplyProps): JSX.Element {
  const [showForm, setShowForm] = useState(false);

  const renderContent = () => (
    <div>
      <p className={styles.replyText}>
        <span data-testid="content">{reply.content}</span>
        {reply.actions.canEdit && (
          <>
            {' '}
            <NakedButton
              style={{ marginLeft: '5px' }}
              onClick={() => setShowForm(true)}
            >
              <span className={styles.editLink}>Edit</span>
            </NakedButton>
          </>
        )}
      </p>
    </div>
  );

  const handleEditAndClose = async (editParams: CommentFormSubmitProps) => {
    setShowForm(false);
    handleEdit(editParams);
  };

  const renderContentForm = () => (
    <CommentForm
      handleSubmit={handleEditAndClose}
      handleCancel={() => setShowForm(false)}
      existingComment={reply}
      submitLabel="Save"
    />
  );

  return (
    <div
      key={reply.id}
      className={styles.replyContainer}
      data-testid={`reply-${reply.id}`}
    >
      {showForm ? renderContentForm() : renderContent()}

      <div data-testid="meta">
        <Attribution
          name={reply.reviewer.name}
          action="replied"
          timeAgo={reply.createdAgo}
          edited={!!reply.editedAgo}
        />
      </div>
    </div>
  );
}

export default Reply;
