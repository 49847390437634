import React from 'react';

import AccountSelector from './AccountSelector/AccountSelector';
import { BillableAccount } from './reviewInnerProps';
import { PrimaryButton } from 'system/base/Button';
import Errors from 'components/Errors';

export const processErrors = ({
  createReviewResult,
  apolloError,
  submitting,
  errors,
  success,
}: {
  createReviewResult: any;
  apolloError: any;
  submitting: boolean;
  errors: { message: string }[];
  success: boolean;
}): string[] => {
  if (!createReviewResult || submitting || success) {
    return [];
  }
  if (apolloError) {
    return [apolloError.message];
  }
  if (errors && errors.length > 0) {
    return errors.map((v) => v.message);
  }
  if (!success) {
    return ['Unknown error'];
  }
  return [];
};

export const AccountControls = (props: {
  isCochraneReview: boolean;
  accounts: BillableAccount[];
  selectedAccountId: string;
  setSelectedAccountId: (a: string) => any;
  isSubmittable: boolean;
  submitting: boolean;
  errors?: string[];
  submit?: () => void;
}) => {
  const {
    isCochraneReview,
    accounts,
    selectedAccountId,
    setSelectedAccountId,
    isSubmittable,
    submitting,
    submit,
    errors = [],
  } = props;

  return (
    <div className="row">
      <div className="form-group col-md-8">
        <hr />
        <AccountSelector
          isCochraneReview={isCochraneReview}
          accounts={accounts}
          selectedAccountId={selectedAccountId}
          onSelectAccount={setSelectedAccountId}
        />
        <hr />
        {errors.length > 0 && (
          <Errors
            errors={errors}
            title="There was an error creating your review"
          />
        )}
        <PrimaryButton onClick={submit} disabled={!isSubmittable}>
          {submitting ? 'Creating Review...' : 'Create Review'}
        </PrimaryButton>
        <a href="/" className="reviews_new--cancel">
          Cancel
        </a>
      </div>
    </div>
  );
};
