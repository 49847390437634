import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import INVITE_PERSON_TO_ORGANIZATION from '../../invitePersonToOrganization';
import { Invitation } from 'types/graphql';
import ActivitySpinner from 'components/ActivitySpinner';
import SuccessMessage from 'components/SuccessMessage';
import ErrorMessage from 'components/ErrorMessage';
import { ID } from 'util/types';
import {
  InvitePersonToOrganizationMutation,
  InvitePersonToOrganizationInput,
} from 'types/graphql';
import { NakedButton } from 'system/base/Button';

interface Props {
  organizationId: ID;
  invitation: Invitation;
}

const ResendInvitation = ({ organizationId, invitation }: Props) => {
  enum SendingState {
    Inactive,
    Sending,
    Sent,
    Error,
  }
  const [sendingState, setSendingState] = useState(SendingState.Inactive);

  const [invitePersonToOrganization] = useMutation<
    InvitePersonToOrganizationMutation,
    InvitePersonToOrganizationInput
  >(INVITE_PERSON_TO_ORGANIZATION, {
    variables: {
      organizationId,
      // This needs to support resending existing invitations without name
      name: invitation.name || '',
      email: invitation.email,
    },
  });

  const resend = (e: React.MouseEvent) => {
    e.preventDefault();

    setSendingState(SendingState.Sending);
    invitePersonToOrganization()
      .then(() => setSendingState(SendingState.Sent))
      .catch(() => setSendingState(SendingState.Error));
  };

  switch (sendingState) {
    case SendingState.Inactive:
      return (
        <NakedButton onClick={resend} style={{ padding: 0 }}>
          Re-send invitation
        </NakedButton>
      );
    case SendingState.Sending:
      return <ActivitySpinner />;
    case SendingState.Sent:
      return <SuccessMessage>Sent!</SuccessMessage>;
    case SendingState.Error:
      return <ErrorMessage>Unable to send</ErrorMessage>;
    default:
  }

  return null;
};

export default ResendInvitation;
