import React, { useState } from 'react';

import styles from './FullTextsPreview.module.css';
import { PDFPreview, PspdfkitConfig } from './PDFPreview';
import { SelectFullText } from './SelectFullText';

interface FullText {
  title: string;
  url: string;
}

export interface FullTextsPreviewProps {
  fullTexts: Array<FullText>;
  pspdfkitConfig: PspdfkitConfig;
}
export function FullTextsPreview({
  fullTexts,
  pspdfkitConfig,
}: FullTextsPreviewProps): JSX.Element | null {
  const [selectedIndex, updateSelectedIndex] = useState(0);

  if (fullTexts.length === 0) {
    return null;
  }

  const { title, url } = fullTexts[selectedIndex];

  return (
    <div className={styles.FullTextsPreview}>
      <div className={styles.toolbar}>
        <SelectFullText
          fullTexts={fullTexts}
          selectedIndex={selectedIndex}
          onSelect={updateSelectedIndex}
        />
      </div>
      <div className={styles.preview}>
        <PDFPreview
          url={url}
          label={`Preview of full text: ${title}`}
          pspdfkitConfig={pspdfkitConfig}
        />
      </div>
    </div>
  );
}
