import React, { memo } from 'react';
import { useFieldArray, UseFieldArrayReturn } from 'react-hook-form';
import styles from '../../DataExtractionTemplate.module.scss';
import {
  Heading,
  Icon,
  IconList,
  Link,
  Button,
  Tooltip,
  TooltipTrigger,
  TooltipContent,
  Text,
} from '../../../../../components/core';
import { DataExtractionTemplateState } from '../../DataExtractionTemplate';
import { DataExtractionTemplateResultType } from '../../../../../types';
import resultDataStyles from './ResultsDataPanel.module.scss';
import CustomResultTypeEditor from './CustomResultTypeEditor';
import {
  dataFormat,
  newContinuousDataFormats,
  legacyContinuousDataFormats,
  legacyDichotomousDataFormats,
  newDichotomousDataFormats,
} from './ResultDataFormats';
import { useReviewFeatures } from 'hooks/reviews/useReviewFeatures';
import { OutcomeTypes } from 'types/DataExtraction';

const chipButton = (format: dataFormat, key: string) => {
  return (
    <Tooltip key={key}>
      <TooltipTrigger>
        <Button
          className={resultDataStyles.chipButton}
          data-testid="chipButton"
          size="xs"
          isNaked={true}
        >
          {format.name}
        </Button>
      </TooltipTrigger>
      <TooltipContent>{format.tooltip}</TooltipContent>
    </Tooltip>
  );
};

type Props = {
  resultsDataAnchor?: string; // NB: ID used for anchor from OutcomeEditor (View list of data formats)
};

const ResultsDataPanel = ({ resultsDataAnchor }: Props) => {
  const { hasSelectableMeasures } = useReviewFeatures();

  const continuousData = hasSelectableMeasures
    ? newContinuousDataFormats
    : legacyContinuousDataFormats;

  const dichotomousData = hasSelectableMeasures
    ? newDichotomousDataFormats
    : legacyDichotomousDataFormats;

  const customResultTypes = useFieldArray<DataExtractionTemplateState>({
    name: 'custom_result_types',
  }) as UseFieldArrayReturn<DataExtractionTemplateState, 'custom_result_types'>;
  const deleteCustomResultType = (
    resultType: DataExtractionTemplateResultType,
    index: number
  ) => {
    if (!resultType.used) customResultTypes.remove(index);
  };
  const addCustomDichotomous = () => {
    customResultTypes.append({
      outcome_type: OutcomeTypes.DICHOTOMOUS,
      label: '',
    });
  };
  const addCustomContinuous = () => {
    customResultTypes.append({
      outcome_type: OutcomeTypes.CONTINUOUS,
      label: '',
    });
  };

  return (
    <section id={resultsDataAnchor} className={styles.section}>
      <div className={resultDataStyles.outcomeTypeSection}>
        <Heading
          type="h5"
          component="h3"
          variant="brand"
          className={resultDataStyles.outcomeTypeHeading}
        >
          Results data
          <Link
            className={styles.HelpLink}
            onClick={() => ''}
            data-pendo-key={
              hasSelectableMeasures
                ? 'det-selectable-results-data'
                : 'det-results-data'
            }
            aria-label="Get help with results data"
          >
            <Icon icon={IconList.light.faQuestionCircle} />
          </Link>
        </Heading>
        <Text>You can select any of these data formats in each study:</Text>
      </div>
      <div className={resultDataStyles.outcomeTypeSection}>
        <Heading
          type="h6"
          component="h3"
          variant="neutral"
          className={resultDataStyles.outcomeTypeHeading}
        >
          Continuous data
        </Heading>
        <p className={resultDataStyles.resultTypeHeading}>Standard</p>
        {continuousData.map((format, index) =>
          chipButton(format, `continuous_${index + 1}`)
        )}
        {hasSelectableMeasures && (
          <div className={resultDataStyles.customResultTypesSection}>
            <p className={resultDataStyles.resultTypeHeading}>Custom</p>
            <div className={resultDataStyles.customEditors}>
              {customResultTypes.fields.map((field, index) => {
                if (field.outcome_type == OutcomeTypes.CONTINUOUS)
                  return (
                    <CustomResultTypeEditor
                      key={field.id}
                      index={index}
                      resultType={field}
                      onDelete={() => deleteCustomResultType(field, index)}
                    />
                  );
              })}
            </div>
            <Link onClick={addCustomContinuous} variant="primary">
              <Icon icon={IconList.light.faPlus} /> Add custom
            </Link>
          </div>
        )}
      </div>
      <div className={resultDataStyles.outcomeTypeSection}>
        <Heading
          type="h6"
          component="h3"
          variant="neutral"
          className={resultDataStyles.outcomeTypeHeading}
        >
          Dichotomous data
        </Heading>
        <p className={resultDataStyles.resultTypeHeading}>Standard</p>
        {dichotomousData.map((format, index) =>
          chipButton(format, `dichotomous_${index + 1}`)
        )}
        {hasSelectableMeasures && (
          <div className={resultDataStyles.customResultTypesSection}>
            <p className={resultDataStyles.resultTypeHeading}>Custom</p>
            <div className={resultDataStyles.customEditors}>
              {customResultTypes.fields.map((field, index) => {
                if (field.outcome_type == OutcomeTypes.DICHOTOMOUS)
                  return (
                    <CustomResultTypeEditor
                      key={field.id}
                      index={index}
                      resultType={field}
                      onDelete={() => deleteCustomResultType(field, index)}
                    />
                  );
              })}
            </div>
            <Link onClick={addCustomDichotomous} variant="primary">
              <Icon icon={IconList.light.faPlus} /> Add custom
            </Link>
          </div>
        )}
      </div>
    </section>
  );
};

export default memo(ResultsDataPanel);
