import React, { useState } from 'react';
import styles from './SingleSignOnForms.module.scss';

import { CovidenceLogo } from './CovidenceLogo';
import {
  Button,
  Form,
  FormControl,
  FormLabel,
  Input,
  Text,
} from 'components/core';
import { Stack } from 'components/layout';
import { SINGLE_SIGN_ON_SIGN_IN } from 'query/routes';
import { getCsrfToken } from 'util/authenticityToken';

interface SamlDiscoveryFormProps {
  prompt: string;
  ssoRequired?: boolean;
  email?: string;
}

/**
 * This form will do a full browser submit and expects to be redirected
 */
export const SamlDiscoveryForm = (props: SamlDiscoveryFormProps) => {
  const { prompt, ssoRequired, email } = props;

  const [loading, setLoading] = useState(false);

  return (
    <div className={styles.wrapper}>
      <Form
        aria-label="sign in form"
        action={SINGLE_SIGN_ON_SIGN_IN}
        method="post"
        className={`${styles.form} ${styles.discoveryForm}`}
        onSubmit={function handleSubmit() {
          setLoading(true);
        }}
        acceptCharset="UTF-8"
      >
        <input type="hidden" name="utf8" value="✓" autoComplete="off" />
        <input
          type="hidden"
          name="authenticity_token"
          value={getCsrfToken()}
          autoComplete="off"
        />
        <Stack spacing={6}>
          <CovidenceLogo />
          {ssoRequired && (
            <Text size="sm" className={styles.message}>
              Your organization requires SSO
            </Text>
          )}
          <FormControl>
            <FormLabel className={styles.label}>Email</FormLabel>
            <Input
              id="emailInput"
              name="email"
              defaultValue={email || ''}
              inputType="email"
              required
            />
          </FormControl>
          <Button
            size="md"
            isLoading={loading}
            className={styles.submitButton}
            type="brand"
          >
            <Text size="md" className={styles.buttonText}>
              {prompt}
            </Text>
          </Button>
        </Stack>
      </Form>
    </div>
  );
};

export const SamlDiscoveryPage = (props: SamlDiscoveryFormProps) => {
  return (
    <div className={styles.formContainer}>
      <Stack spacing={6}>
        <SamlDiscoveryForm {...props} />
      </Stack>
    </div>
  );
};
