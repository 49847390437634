import React, { useState, useRef, useLayoutEffect } from 'react';
import { Flex, Box } from 'theme-ui';
import { gql, useMutation } from '@apollo/client';
import { PrimaryButton, TertiaryButton } from '../../system/base/Button';

import styles from './Build.module.css';
import ToolbarNav from './ToolbarNav';
import RevertModal, { RevertModalType } from './RevertModal';
import LoadingSpinner from 'system/elements/Spinner';
import FormattedDate from 'components/FormattedDate';
import { ID } from 'util/types';

import { TemplateModel } from 'concepts/Extraction/TemplateModel';
import {
  fromModel,
  toModel,
} from 'concepts/Extraction/ViewModel/TemplateViewModel';
import BuildEditor from 'concepts/Extraction/FormBuilder/BuildEditor';
import BuildPreview from 'concepts/Extraction/Preview/BuildPreview';
import Errors from 'components/Errors';
import { SectionHeading } from 'concepts/Extraction/Widgets/SectionHeading';
import { errorMessagesFrom } from 'util/errorMessages';

import {
  CreateExtractionFormRevisionMutation,
  CompleteExtractionFormMutation,
  RevertTemplateMutation,
} from 'types/graphql';
import { ExtractionFormType } from 'concepts/Extraction/ExtractionFormType';
import BuildSettings from 'concepts/Extraction/FormBuilder/BuildSettings';
import { PublishWarningDialog } from 'concepts/Extraction/FormBuilder/PublishWarningDialog';
import { Badge } from 'components/Badge';
import { useConfirmUnsaved } from 'hooks/useConfirmUnsaved';

export const CREATE_TEMPLATE_REVISION_MUTATION = gql`
  mutation CreateExtractionFormRevision(
    $extractionFormRevision: CreateExtractionFormRevisionInput!
  ) {
    createExtractionFormRevision(input: $extractionFormRevision) {
      success
      errors {
        message
      }
    }
  }
`;

export const COMPLETE_EXTRACTION_FORM_MUTATION = gql`
  mutation CompleteExtractionForm(
    $extractionFormRevision: CompleteExtractionFormInput!
  ) {
    completeExtractionForm(input: $extractionFormRevision) {
      success
      errors {
        message
      }
    }
  }
`;

const REVERT_TEMPLATE_MUTATION = gql`
  mutation RevertTemplate($params: RevertTemplateInput!) {
    response: revertTemplate(input: $params) {
      success
      errors {
        message
      }
    }
  }
`;

export interface BuildProps {
  currentReviewerId?: ID;
  reviewId: ID;
  formId?: ID;
  formType: ExtractionFormType;
  formSuperseded: boolean;
  formPublisher: {
    firstName: string;
    lastName: string;
    isCurrentReviewer: boolean;
    date: Date | null;
  };
  formEditor: {
    firstName: string;
    lastName: string;
    isCurrentReviewer: boolean;
    date: Date | null;
  };
  model: TemplateModel;
  backUrl: string;
  progress: {
    started: number;
    completed: number;
  };
  isCustomTables2Enabled?: boolean;
  isRevertable: boolean;
}

const LoadingOverlay = (): JSX.Element => (
  <div
    style={{
      position: 'absolute',
      height: '100%',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'rgb(128, 128, 128, 0.5)',
      zIndex: 1,
    }}
  >
    <LoadingSpinner size="large" />
  </div>
);

export function useSyncedScrolling(): [
  (id: string) => void,
  React.MutableRefObject<HTMLDivElement | null>,
  React.MutableRefObject<HTMLDivElement | null>
] {
  const selectedBlockIDChange = useRef<boolean>(false);
  const selectedBlockID = useRef<string | null>(null);
  const editorPanel = useRef<HTMLDivElement | null>(null);
  const previewPanel = useRef<HTMLDivElement | null>(null);

  const selectFunction = (id: string) => {
    selectedBlockID.current = id;
    selectedBlockIDChange.current = true;
  };

  useLayoutEffect(() => {
    if (selectedBlockIDChange.current) {
      selectedBlockIDChange.current = false;

      if (!selectedBlockID.current) {
        return;
      }

      const previewBlock = document.querySelector(
        `[data-preview-block-id="${selectedBlockID.current}"]`
      );
      const editorBlock = document.querySelector(
        `[data-block-id="${selectedBlockID.current}"]`
      );

      if (!previewBlock || !editorBlock) {
        return;
      }

      const offsetTopDiff =
        (editorBlock as HTMLElement).offsetTop -
        (previewBlock as HTMLElement).offsetTop;

      const scrollHandler = () => {
        if (
          editorPanel.current &&
          previewPanel.current &&
          previewBlock &&
          editorBlock &&
          previewPanel.current.scrollTo
        ) {
          previewPanel.current.scrollTo(
            0,
            editorPanel.current.scrollTop - offsetTopDiff
          );
        }
      };
      // Fire it once to bring the preview panel inline straight away
      scrollHandler();
      editorPanel.current?.addEventListener('scroll', scrollHandler);

      const editorPanelRef = editorPanel.current;
      return () => {
        editorPanelRef?.removeEventListener('scroll', scrollHandler);
      };
    }
  });

  return [selectFunction, editorPanel, previewPanel];
}

export default function Build(props: BuildProps): JSX.Element {
  const [viewModel, updateViewModel] = useState(() => fromModel(props.model));
  const [selectedBlockID, updateSelectedBlockID] = useState<string | null>(
    null
  );
  const [showWarningDialog, updateShowWarningDialog] = useState<boolean>(false);

  const [
    createRevisionMutation,
    { data: createRevisionData, error: createRevisionError, loading: saving },
  ] = useMutation<CreateExtractionFormRevisionMutation>(
    CREATE_TEMPLATE_REVISION_MUTATION
  );

  const [
    completeRevisionMutation,
    { data: completeFormData, error: completeFormError, loading: completing },
  ] = useMutation<CompleteExtractionFormMutation>(
    COMPLETE_EXTRACTION_FORM_MUTATION
  );

  const [
    revertTemplateMutation,
    { error: revertTemplateError, loading: reverting },
  ] = useMutation<RevertTemplateMutation>(REVERT_TEMPLATE_MUTATION);

  const revertTemplateOnClick = () => {
    revertTemplateMutation({
      variables: {
        params: {
          templateId: props.formId,
        },
      },
    }).then(({ data }) => {
      const response = data?.response;
      if (response?.errors) {
        setShowRevertModal('error');
      } else {
        setShowRevertModal('success');
      }
    });
  };

  const [clean, dirty] = useConfirmUnsaved();
  const [published, setPublished] = useState(!props.formSuperseded);
  const [lastSaved, setLastSaved] = useState(props.formEditor.date);
  const displayEditorName = props.formEditor.isCurrentReviewer
    ? 'You'
    : props.formEditor.firstName + ' ' + props.formEditor.lastName;
  const [lastSavedBy, setLastSavedBy] = useState(displayEditorName);
  const [showRevertModal, setShowRevertModal] = useState<RevertModalType>(
    'closed'
  );

  const [saved, setSaved] = useState(true);

  const [setSyncScrollingId, editorPanel, previewPanel] = useSyncedScrolling();
  const selectBlockId = (id: string) => {
    setSyncScrollingId(id);
    updateSelectedBlockID(id);
  };

  const title = () =>
    props.formType === 'quality_assessment'
      ? 'Quality Assessment Template'
      : 'Data Extraction Template';

  const save = () => {
    const model = toModel(viewModel);
    createRevisionMutation({
      variables: {
        extractionFormRevision: {
          extractionFormId: props.formId,
          definition: model,
          reviewId: props.reviewId,
          formType: props.formType,
          reviewerId: props.currentReviewerId,
        },
      },
    })
      .then(() => {
        clean();
        setPublished(false);
        setSaved(true);
        setLastSaved(new Date());
        setLastSavedBy('You');
      })
      .catch((e) => {
        MetaError.notify(e);
      });
  };

  const publish = (options: { force: boolean }) => {
    const showWarning =
      options.force === false &&
      (props.progress.completed > 0 || props.progress.started > 0);

    if (showWarning) {
      updateShowWarningDialog(true);
      return;
    }

    const model = toModel(viewModel);
    completeRevisionMutation({
      variables: {
        extractionFormRevision: {
          extractionFormId: props.formId,
          definition: model,
          reviewId: props.reviewId,
          formType: props.formType,
          reviewerId: props.currentReviewerId,
        },
      },
    })
      .then(() => {
        clean();
        window.location.assign(props.backUrl);
      })
      .catch((e) => {
        MetaError.notify(e);
      });
  };

  const editingStatusDisplay = (props: BuildProps) => {
    const display_publisher_name = props.formPublisher.isCurrentReviewer
      ? 'You'
      : props.formPublisher.firstName + ' ' + props.formPublisher.lastName;
    const same_revision = lastSaved === props.formPublisher.date;
    return (
      <Flex as="nav" sx={{ justifyContent: 'space-between' }}>
        <Flex sx={{ alignItems: 'center' }}>
          <Badge variant={published ? 'Success' : 'Warn'} size="medium">
            {published ? 'Published' : 'Draft'}
          </Badge>

          {!published && lastSaved && !same_revision && (
            <Box ml={2}>
              {lastSavedBy} saved{' '}
              <FormattedDate date={lastSaved} format="relative" /> ago.
            </Box>
          )}

          {props.formPublisher.date && (
            <Box ml={2}>
              {display_publisher_name} published{' '}
              <FormattedDate
                date={props.formPublisher.date}
                format="relative"
              />{' '}
              ago.
            </Box>
          )}
        </Flex>
      </Flex>
    );
  };

  const templateChanged = (updated: any) => {
    dirty();
    updateViewModel(updated);
    setPublished(false);
    setSaved(false);
  };

  return (
    <div className={styles.Build}>
      {reverting && <LoadingOverlay />}
      <RevertModal
        mode={
          (revertTemplateError && 'error') ||
          (reverting === true && 'closed') ||
          showRevertModal
        }
        onRevert={revertTemplateOnClick}
        onDismiss={() => {
          setShowRevertModal('closed');
        }}
        onCancel={() => {
          setShowRevertModal('closed');
        }}
        onSuccess={() => {
          setShowRevertModal('closed');
          clean();
          window.location.reload();
        }}
      />
      <header className={styles.headerPanel}>
        <ToolbarNav
          backUrl={props.backUrl}
          title={title()}
          left={editingStatusDisplay(props)}
          right={
            <Flex as="nav" sx={{ justifyContent: 'space-between' }}>
              <Flex sx={{ alignItems: 'center' }}>
                <TertiaryButton
                  mr={3}
                  className={styles.saveButton}
                  disabled={saving || saved}
                  onClick={save}
                >
                  {saving ? 'Saving…' : 'Save as Draft'}
                </TertiaryButton>

                <PrimaryButton
                  disabled={completing || published || reverting}
                  onClick={() => publish({ force: false })}
                >
                  {completing ? 'Publishing…' : 'Publish'}
                </PrimaryButton>
              </Flex>
            </Flex>
          }
        />
      </header>
      <div className={styles.settingsPanel} data-testid="build-editor-panel">
        <SectionHeading>Item Settings</SectionHeading>
        <BuildSettings
          formType={props.formType}
          viewModel={viewModel}
          blockID={selectedBlockID}
          onChange={templateChanged}
        />
      </div>
      <div className={styles.editorAndPreviewPanelsContainer}>
        <div className={styles.editorPanel} ref={editorPanel}>
          <SectionHeading>Editor</SectionHeading>
          <BuildEditor
            viewModel={viewModel}
            onChange={templateChanged}
            formType={props.formType}
            onSelectBlock={selectBlockId}
            selectedBlockID={selectedBlockID}
            isCustomTables2Enabled={props.isCustomTables2Enabled}
            progress={props.progress}
            revertButtonOnClick={() => {
              setShowRevertModal('ask');
            }}
            showRevertButton={
              !!props.formId && props.isRevertable && !published
            }
          />
        </div>
        <div
          className={styles.previewPanel}
          ref={previewPanel}
          data-testid="build-preview-panel"
        >
          <SectionHeading>Preview</SectionHeading>
          <BuildPreview viewModel={viewModel} />
        </div>
      </div>
      <Errors
        title="Error saving"
        errors={errorMessagesFrom(
          createRevisionData?.createExtractionFormRevision?.errors,
          createRevisionError,
          completeFormData?.completeExtractionForm?.errors,
          completeFormError
        )}
      />
      <PublishWarningDialog
        inProgress={props.progress.started}
        completed={props.progress.completed}
        isOpen={showWarningDialog}
        onDismiss={() => {
          updateShowWarningDialog(false);
        }}
        onConfirm={() => {
          updateShowWarningDialog(false);
          publish({ force: true });
        }}
      />
    </div>
  );
}
