import React from 'react';
import { PENDO_REVIEW_SETTING_PREFIX } from '../ExtractionToolSelector';
import styles from './DualExtractorModal.module.scss';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Text,
} from 'components/core';

interface Props {
  onConfirm: () => void;
  onClose: () => void;
}

const DualExtractorModal = ({ onConfirm, onClose }: Props) => {
  return (
    <Modal isOpen={true} onClose={onClose}>
      <ModalHeader>
        <Text className={styles.ModalHeaderTitle}>Change to 2 reviewers</Text>
      </ModalHeader>
      <ModalBody className={styles.ModalBody}>
        <Text>
          All studies will require 2 reviewers and a consensus, even if you have
          completed studies using 1 reviewer.
        </Text>
        <Text>Are you sure you want to proceed?</Text>
      </ModalBody>
      <ModalFooter>
        <Button type="neutral" variant="outline" onClick={onClose}>
          Cancel
        </Button>
        <Button
          type="brand"
          onClick={onConfirm}
          data-pendo-key={
            PENDO_REVIEW_SETTING_PREFIX +
            'single_extractor_notification_confirmation'
          }
        >
          Yes, continue
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default DualExtractorModal;
