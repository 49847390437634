/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react';
import Dropdown, { OptionType as DropdownOption } from 'components/Dropdown';
import { SetSortFn, SortableVariables } from 'hooks/useSortVariables';
import LabelFor from 'system/elements/LabelFor';

export { DropdownOption as Option };

interface Props<T> {
  // with no sort or direction, this might be null
  sort: T | null;
  setSort: SetSortFn<T>;
  // but the option values must not be Maybeish
  options: DropdownOption<T>[];
  className?: string;
}

const sortComparator = (a: SortableVariables, b: SortableVariables) =>
  a.sort === b.sort && a.sortDirection === b.sortDirection;

const SortDropdown = <T extends SortableVariables>({
  options,
  sort,
  setSort,
  className,
}: Props<T>) => {
  return (
    <LabelFor
      className={className}
      labelProps={{ mr: 2 }}
      input={
        <Dropdown<T>
          options={options}
          selected={sort || options[0].value}
          onChange={(value) => setSort(value.sort, value.sortDirection)}
          comparator={sortComparator}
        />
      }
      flexDirection="row-reverse"
    >
      Sort by
    </LabelFor>
  );
};

export default SortDropdown;
