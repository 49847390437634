import React, { useState } from 'react';
import { Study } from './StudyTypes';
import styles from './NotesModal.module.css';
import AddNoteToStudyButton from './AddNoteToStudyButton';
import Note from './Note';
import Dialog from 'system/base/Dialog';
import Textbox from 'system/base/Textbox';

export interface NotesModalProps {
  study: Study;
  isOpen: boolean;
  currentUserId: string;
  onSuccess: () => void;
  onCancel: () => void;
  onFailure: () => void;
}

export default function NotesModal({
  study,
  isOpen,
  currentUserId,
  onSuccess,
  onCancel,
  onFailure,
}: NotesModalProps): JSX.Element {
  const [noteContent, setNoteContent] = useState('');

  const onSuccessNote = () => {
    setNoteContent('');
    onSuccess();
  };

  return (
    <Dialog
      isOpen={isOpen}
      onDismiss={onCancel}
      title={`Notes for ${study.studyId}`}
    >
      <div>
        <div className={styles.addNote}>
          <Textbox
            className={styles.noteInput}
            placeholder="Write your note here"
            onChange={(e: any) => {
              setNoteContent(e.target.value);
            }}
            value={noteContent}
          />
          <AddNoteToStudyButton
            study={study}
            note={noteContent}
            onSuccess={onSuccessNote}
            onFailure={onFailure}
          />
        </div>
        {study.notes.map((note) => (
          <Note
            {...note}
            currentUserId={currentUserId}
            key={note.id}
            onSuccess={onSuccess}
            onFailure={onFailure}
          />
        ))}
      </div>
    </Dialog>
  );
}
