import React, { FunctionComponent, useState, useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import idx from 'idx';
import Box from 'system/base/Box';
import Flex from 'system/base/Flex';
import { Link } from 'system/base/Link';
import { ID } from 'util/types';
import {
  GetPendingReviewsQuery,
  GetPendingReviewsQueryVariables,
} from 'types/graphql';
import { CRS_IMPORT } from 'constants/features';
import hasFeature from 'util/hasFeature';
import cochraneLogo from 'assets/images/cochrane-brandmark.svg';

const QUERY = gql`
  query GetPendingReviews($reviewId: ID!) {
    review: node(id: $reviewId) {
      ... on Review {
        enabledFeatures
        cochraneReviewLink {
          crsPendingStudiesCount
        }
        currentImport {
          id
          isFinished
        }
      }
    }
  }
`;

interface Props {
  reviewId: ID;
}

const ImportStudiesNotification: FunctionComponent<Props> = ({ reviewId }) => {
  // if an import becomes running while this component is mounted, just hide it
  // until we revisit this page / remount this component
  const [importRunning, setImportRunning] = useState(false);

  const { data, error, loading } = useQuery<
    GetPendingReviewsQuery,
    GetPendingReviewsQueryVariables
  >(QUERY, { variables: { reviewId } });

  const pendingCount = idx(
    data,
    (_) => _.review.cochraneReviewLink.crsPendingStudiesCount
  );

  useEffect(() => {
    const currentImport = idx(data, (_) => _.review.currentImport);
    if (currentImport && !currentImport.isFinished) {
      setImportRunning(true);
    }
  }, [data, setImportRunning]);

  if (importRunning) return null;

  if (loading || error || pendingCount === 0 || pendingCount == null) {
    return null;
  }

  if (!(data && data.review) || !hasFeature(data && data.review, CRS_IMPORT))
    return null;

  return (
    <Flex
      colors="onWhite"
      border="panel"
      borderLeft="cochrane"
      padding={2}
      alignItems="center"
      borderRadius={3}
    >
      <Box width={30} height={30} marginRight={2}>
        <img src={cochraneLogo} alt="Cochrane" />
      </Box>
      <Link href={`/reviews/${reviewId}/crs_imports`}>
        Import {pendingCount} studies from CRS
      </Link>
    </Flex>
  );
};

export default ImportStudiesNotification;
