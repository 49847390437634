import React, { useEffect, useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import styles from '../../containers/Comments/Comments.module.css';
import { NakedButton, PrimaryButton } from 'system/base/Button';
import { ID } from 'util/types';
export interface CommentFormSubmitProps {
  content: string;
  existingCommentId?: ID;
}
interface Comment {
  id: ID;
  content: string;
}
export interface CommentFormProps {
  handleSubmit: (props: CommentFormSubmitProps) => Promise<any>;
  handleCancel?: () => void;
  existingComment?: Comment;
  submitLabel?: string;
}

export function CommentForm({
  handleSubmit,
  handleCancel,
  existingComment,
  submitLabel,
}: CommentFormProps): JSX.Element {
  const [content, setContent] = useState<string>(
    (existingComment && existingComment.content) || ''
  );
  const [visible, setVisible] = useState<boolean>(true);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setContent(e.target.value);
  };

  const submitAndReset = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const existingCommentId = existingComment ? existingComment.id : undefined;
    await handleSubmit({ content, existingCommentId });
    if (visible) {
      setContent('');
    }
  };

  useEffect(() => {
    return () => {
      setVisible(false);
    };
  }, []);

  return (
    <form onSubmit={submitAndReset}>
      <label htmlFor="content">
        <TextareaAutosize
          className={styles.commentContentInput}
          name="content"
          value={content}
          minRows={6}
          onChange={handleChange}
          placeholder="enter your comment here..."
          data-testid="textarea-comment-content"
        />
      </label>
      <div className={styles.submitButtons}>
        <PrimaryButton data-testid="submit-link" type="submit">
          {submitLabel ? submitLabel : 'Save'}
        </PrimaryButton>
        {handleCancel && (
          <NakedButton style={{ marginLeft: '10px' }} onClick={handleCancel}>
            Cancel
          </NakedButton>
        )}
      </div>
    </form>
  );
}

export default CommentForm;
