import React from 'react';
import styles from './TrialExpiryBanner.module.scss';
import { ChoosePlanButton } from 'components/ChoosePlanButton';
import { Banner, BannerActions, Text } from 'components/core';

export interface TrialExpiryBannerProps {
  accountId?: string;
  isSubscriptionExpired?: boolean;
  reviewOwner?: string;
  trialExpiresIn?: string;
  userOwnsReview: boolean;
}

export const TrialExpiryBanner = ({
  accountId,
  isSubscriptionExpired,
  reviewOwner,
  trialExpiresIn,
  userOwnsReview,
}: TrialExpiryBannerProps) => {
  if (!(isSubscriptionExpired || userOwnsReview)) {
    // No banner if working on someone else's trial review
    // that's not expired yet
    //
    return <></>;
  }

  let bannerContent = <></>;

  if (isSubscriptionExpired && userOwnsReview) {
    bannerContent = (
      <>
        <strong>Your trial has expired, </strong>
        please purchase a plan to continue.
      </>
    );
  } else if (isSubscriptionExpired && !userOwnsReview) {
    bannerContent = (
      <>
        <strong>Trial expired</strong>, {reviewOwner} (review owner) can
        reactivate this review by choosing a Covidence plan.
      </>
    );
  } else {
    bannerContent = (
      <>
        You have <strong>{trialExpiresIn}</strong> remaining in your trial
        <span className={styles.desktopOnly}>
          , which includes all features
        </span>
        .
      </>
    );
  }

  return (
    <Banner>
      <Text>{bannerContent}</Text>
      {accountId && userOwnsReview && (
        <BannerActions>
          <ChoosePlanButton size="sm" />
        </BannerActions>
      )}
    </Banner>
  );
};
