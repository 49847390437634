import React from 'react';
import LabelFor from './LabelFor';

export interface CheckboxProps {
  disabled?: boolean;
  checked?: boolean;
  required?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  label: string | JSX.Element;
}

const Checkbox = ({
  label,
  onChange,
  disabled = false,
  checked = false,
  required = false,
}: CheckboxProps) => (
  <LabelFor
    flexDirection="row"
    input={
      <input
        type="checkbox"
        style={{ margin: 0 }} // Override bootstrap styles
        disabled={disabled}
        required={required}
        checked={checked}
        onChange={onChange}
      />
    }
  >
    {label}
  </LabelFor>
);

export default Checkbox;
