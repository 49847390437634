import React from 'react';
import styles from './Pagination.module.scss';
import { Link, Icon, IconList } from 'components/core';

interface Props {
  page: number;
  totalPages: number;
  visiblePages?: number;
}

const createUrl = (page: number) => {
  const url = new URL(document.location.href);
  url.searchParams.set('page', page.toString());
  return url;
};

const Pagination = ({ page, totalPages, visiblePages = 7 }: Props) => {
  if ((page === 1 && totalPages === 1) || page > totalPages) {
    return <></>;
  }

  const renderPages = () => {
    // We always show first and last pages, so if we're already showing both options, we can stop
    if (totalPages <= 2) return;
    // Remove first and last pages from our total
    let allowedPages = visiblePages - 2;

    // Build the set of allowed pages starting from the current page until we run out of space
    let leftCursor = page === totalPages ? page - 1 : page;
    let rightCursor = page + 1;
    const pages = [];
    while (allowedPages > 0) {
      // If both left and right cursor can't move anymore, bail
      if (leftCursor <= 1 && rightCursor >= totalPages) break;

      if (leftCursor > 1) {
        pages.unshift(leftCursor);
        leftCursor -= 1;
        allowedPages -= 1;
      }

      if (rightCursor < totalPages && allowedPages > 0) {
        pages.push(rightCursor);
        rightCursor += 1;
        allowedPages -= 1;
      }
    }

    if (!pages.length) return;

    // Check if the numbering is continuous, if not replace it with an ellipsis
    const showLeftEllipsis = pages[0] !== 2;
    const showRightEllipsis = pages[pages.length - 1] !== totalPages - 1;
    if (showLeftEllipsis) pages.shift();
    if (showRightEllipsis) pages.pop();

    return (
      <>
        {showLeftEllipsis && (
          <span className={styles.Pagination_ellipsis}>...</span>
        )}
        {pages.map((val) => {
          return (
            <Link
              key={val}
              to={createUrl(val)}
              disabled={page === val}
              aria-label={`Go to page ${val}`}
              className={styles.Pagination_link}
            >
              {val.toString()}
            </Link>
          );
        })}
        {showRightEllipsis && (
          <span className={styles.Pagination_ellipsis}>...</span>
        )}
      </>
    );
  };

  return (
    <nav role="navigation" className={styles.Pagination}>
      <Link
        to={createUrl(page - 1)}
        rel="previous"
        disabled={page <= 1}
        className={styles.Pagination_item}
      >
        <Icon icon={IconList.light.faAngleLeft} />
      </Link>
      <Link
        to={createUrl(1)}
        disabled={page === 1}
        aria-label={`Go to page 1`}
        className={styles.Pagination_link}
      >
        1
      </Link>
      {renderPages()}
      <Link
        to={createUrl(totalPages)}
        disabled={page === totalPages}
        aria-label={`Go to page ${totalPages}`}
        className={styles.Pagination_link}
      >
        {totalPages.toString()}
      </Link>
      <Link
        to={createUrl(page + 1)}
        rel="next"
        disabled={page >= totalPages}
        className={styles.Pagination_item}
      >
        <Icon icon={IconList.light.faAngleRight} />
      </Link>
    </nav>
  );
};

export default Pagination;
