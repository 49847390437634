import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { TemplateBlock } from '../BaseBlock';
import Domain from './control/Domain';
import { DomainBlock } from './DomainBlock';
import { DomainEditorSettings } from './editor/DomainEditorSettings';
import {
  PreviewProps,
  ControlProps,
  EditorProps,
  EditorSettingsProps,
} from 'concepts/Extraction/Blocks/BlockRenderer';
import DomainEditor from 'concepts/Extraction/Blocks/Domain/editor/DomainEditor';
import { BlockRecord } from 'concepts/Extraction/BlocksData';

const typeIconSVG = (
  <svg
    role="presentation"
    width="55"
    height="32"
    viewBox="0 0 55 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 5C0 2.23858 2.23858 0 5 0H50C52.7614 0 55 2.23858 55 5V27C55 29.7614 52.7614 32 50 32H5C2.23857 32 0 29.7614 0 27V5Z"
      fill="#F19D0D"
    />
    <path d="M17.5 12L24.8612 24H10.1388L17.5 12Z" fill="white" />
    <line x1="7" y1="11" x2="28" y2="11" stroke="white" strokeWidth="2" />
  </svg>
);

export const DomainBlockRenderer = {
  Editor({ block, onChange, onComplete, onRemove }: EditorProps) {
    return (
      <DomainEditor
        block={block as DomainBlock}
        onChange={onChange}
        onComplete={onComplete}
        onRemove={onRemove}
      />
    );
  },
  EditorSettings({ block, onChange }: EditorSettingsProps) {
    return (
      <DomainEditorSettings block={block as DomainBlock} onChange={onChange} />
    );
  },
  Control({ block }: ControlProps) {
    const { setValue, control } = useFormContext();
    const blockValue = useWatch({
      control,
      name: block.id,
    });

    return (
      <Domain
        editable
        block={block as DomainBlock}
        selected={blockValue?.value}
        comments={blockValue?.comment}
        onChange={(newValue) => {
          setValue(
            block.id,
            { ...blockValue, value: newValue },
            { shouldDirty: true }
          );
        }}
        onChangeComments={(newComment) => {
          setValue(
            block.id,
            { ...blockValue, comment: newComment },
            { shouldDirty: true }
          );
        }}
      />
    );
  },
  Preview({ block }: PreviewProps) {
    return <Domain block={block as DomainBlock} />;
  },
  ComparisonRow() {
    // Domains only live in QA, so there is no concept of a Comparison Row, as they use a different UI
    return <></>;
  },
  keepResponse(
    record: BlockRecord,
    newBlock: TemplateBlock,
    oldBlock: TemplateBlock
  ): boolean {
    const oldJudgementHadValue =
      (oldBlock as DomainBlock).judgements.find(
        (judgement) => judgement.id == record.value
      ) != undefined;
    const newJudgementsHaveValue =
      (newBlock as DomainBlock).judgements.find(
        (judgement) => judgement.id == record.value
      ) != undefined;

    // If the value appears amongst the judgements of the old template — but not the new one — then remove the record
    if (oldJudgementHadValue && !newJudgementsHaveValue) {
      return false;
    }
    return true;
  },
  displayOnly: false,
  TypeIcon: () => typeIconSVG,
};
