import React from 'react';
import { gql, useQuery } from '@apollo/client';

import EmailPermissions from './EmailPermissions';
import ErrorMessage from 'components/ErrorMessage';
import { ProfileDataQuery, ProfileDataQueryVariables } from 'types/graphql';

const GET_PROFILE_DATA = gql`
  query profileData {
    me {
      consent(name: "mailing_list") {
        decision
      }
    }
  }
`;

type Props = {
  signedSource: string;
};
const PrivacyAndPermissions = ({ signedSource }: Props) => {
  const { data, error, loading } = useQuery<
    ProfileDataQuery,
    ProfileDataQueryVariables
  >(GET_PROFILE_DATA);

  if (loading) return <div className="loading">&nbsp;</div>;
  if (error) return <ErrorMessage>Error loading data</ErrorMessage>;

  const consented = data?.me?.consent?.decision === 'CONSENTED';

  return (
    <>
      <EmailPermissions
        mailingListConsent={consented}
        signedSource={signedSource}
      />
    </>
  );
};

export default PrivacyAndPermissions;
