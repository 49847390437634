import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  useCallback,
  FunctionComponent,
} from 'react';
import { v4 as UUID } from 'uuid';
import { AccordionEvent, useAccordionController } from '..';
import { AccordionAnimationController } from '../AccordionAnimationController';
import styles from './AccordionGroup.module.scss';

interface Props {
  children: JSX.Element | JSX.Element[];
  className?: string;
  component?: string | FunctionComponent<any>;
  initiallyOpen?: boolean;
}

const AccordionGroup = ({
  children,
  className,
  component = 'div',
  initiallyOpen = false,
  ...props
}: Props) => {
  const [isOpen, _setOpen] = useState(false);
  const uuid = useMemo(() => UUID(), []);
  const containerRef = useRef<HTMLElement>(null);
  const animationController = useMemo(
    () => new AccordionAnimationController(containerRef),
    []
  );
  const classes = [styles.AccordionGroup];
  if (className) classes.push(className);

  const accordionEventHandler = useAccordionController();

  const setOpen = useCallback(
    (state?: boolean, noAnimate = false) => {
      noAnimate || animationController.calculateStartingHeight();
      _setOpen(!!state);
      noAnimate || animationController.startAnimation();
    },
    [animationController]
  );

  useEffect(() => {
    accordionEventHandler.subscribe(
      uuid,
      (event: symbol, value?: boolean, noAnimate?: boolean) => {
        if (event === AccordionEvent.SET_OPEN) {
          setOpen(value, noAnimate);
        }

        if (event === AccordionEvent.IS_OPEN) {
          return isOpen;
        }
        return true;
      }
    );
    return () => {
      accordionEventHandler.unsubscribe(uuid);
    };
  });

  useEffect(() => {
    if (initiallyOpen && accordionEventHandler.dispatch(AccordionEvent.OPEN)) {
      setOpen(true, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child as React.ReactElement, {
        isOpen,
        isAlwaysOpen: accordionEventHandler.isAlwaysOpen,
        allowMultiple: accordionEventHandler.allowMultiple,
        setOpen: (value: boolean) => {
          if (
            accordionEventHandler.dispatch(
              value ? AccordionEvent.OPEN : AccordionEvent.CLOSE
            )
          ) {
            setOpen(value);
          }
        },
      });
    }
  });

  return React.createElement<any>(
    component,
    {
      ref: containerRef,
      className: classes.join(' '),
      role: 'region',
      ...props,
    },
    childrenWithProps
  );
};

export default AccordionGroup;
