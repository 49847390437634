import { TemplateBlock } from '../BaseBlock';
import { generateBlockID } from '../generateBlockID';

export interface SubheadingBlock extends TemplateBlock {
  type: 'Subheading';
  label: string;
}

export function blockIsSubheading(
  block: TemplateBlock
): block is SubheadingBlock {
  return block.type === 'Subheading';
}

export function newSubheadingBlock(
  label: string,
  extraProps?: Partial<SubheadingBlock>
): SubheadingBlock {
  return {
    type: 'Subheading',
    id: generateBlockID(),
    label,
    ...extraProps,
  };
}

export function updateLabel(subheading: SubheadingBlock, label: string) {
  return { ...subheading, label };
}
