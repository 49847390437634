import React from 'react';
import LabelFor from './LabelFor';

interface RadioProps {
  label: string | JSX.Element;
  name?: string;
  value?: string;
  checked?: boolean;
  required?: boolean;
  disabled?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const Radio = ({
  label,
  name,
  onChange,
  checked = false,
  required = false,
  disabled = false,
  value,
}: RadioProps) => (
  <LabelFor
    flexDirection="row"
    mr={2}
    input={
      <input
        name={name}
        value={value}
        type="radio"
        required={required}
        checked={checked}
        disabled={disabled}
        onChange={onChange}
      />
    }
  >
    {label}
  </LabelFor>
);

export default Radio;
