import React, { useEffect, useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import Split from 'react-split';
import { FormProvider, useForm } from 'react-hook-form';
import { PrimaryButton, TertiaryButton } from '../../system/base/Button';

import styles from './Extract.module.css';
import ToolbarNav from './ToolbarNav';
import UnsavedChangesWarning from './UnsavedChangesWarning';
import { TemplateModel } from 'concepts/Extraction/TemplateModel';
import { fromModel } from 'concepts/Extraction/ViewModel/ExtractViewModel';
import {
  SaveExtractedDataMutation,
  CompleteExtractedDataMutation,
  SaveFormExtractedDataInput,
} from 'types/graphql';
import { ExtractForm } from 'concepts/Extraction/Extract/ExtractForm';
import Errors from 'components/Errors';
import { errorMessagesFrom } from 'util/errorMessages';
import { ExtractionTabs } from 'concepts/Extraction/Widgets/ExtractionTabs';
import { ExtractionFormType } from 'concepts/Extraction/ExtractionFormType';
import { BlockRecords } from 'concepts/Extraction/BlocksData';
import { FullTextsPreview } from 'concepts/Extraction/Widgets/FullText/FullTextsPreview';
import { PspdfkitConfig } from 'concepts/Extraction/Widgets/FullText/PDFPreview';
import { migrate } from 'concepts/Extraction/Blocks/migrate';

export const SAVE_EXTRACTED_DATA_MUTATION = gql`
  mutation SaveExtractedData($input: SaveExtractedDataInput!) {
    saveExtractedData(input: $input) {
      success
      errors {
        message
      }
    }
  }
`;

export const COMPLETE_EXTRACTED_DATA_MUTATION = gql`
  mutation CompleteExtractedData($input: CompleteExtractedDataInput!) {
    completeExtractedData(input: $input) {
      success
      errors {
        message
      }
    }
  }
`;

export interface ExtractProps {
  dataExtractionModel: TemplateModel | null;
  supersededDataExtractionModel?: TemplateModel;
  dataExtractionFormRevisionId: string | null;
  dataExtractionCurrentRevision: BlockRecords | null;
  qualityAssessmentModel: TemplateModel | null;
  supersededQualityAssessmentModel?: TemplateModel;
  qualityAssessmentFormRevisionId: string | null;
  qualityAssessmentCurrentRevision: BlockRecords | null;
  studyId: string;
  fullTexts: Array<{ url: string; title: string }>;
  title: string;
  backUrl: string;
  singleExtractor: boolean;
  pspdfkitConfig: PspdfkitConfig;
}
function Extract({
  dataExtractionModel,
  supersededDataExtractionModel,
  dataExtractionFormRevisionId,
  dataExtractionCurrentRevision,
  qualityAssessmentModel,
  supersededQualityAssessmentModel,
  qualityAssessmentFormRevisionId,
  qualityAssessmentCurrentRevision,
  fullTexts,
  studyId,
  title,
  backUrl,
  singleExtractor,
  pspdfkitConfig,
}: ExtractProps): JSX.Element {
  const [navigateToPreviousPage, setNavigateToPreviousPage] = useState(false);
  const getDataExtractionDefaultValues = () => {
    if (!dataExtractionModel || !dataExtractionCurrentRevision) {
      return;
    }

    if (supersededDataExtractionModel) {
      return migrate(
        dataExtractionModel,
        supersededDataExtractionModel,
        dataExtractionCurrentRevision
      );
    }

    return dataExtractionCurrentRevision;
  };
  const getQualityAssessmentDefaultValues = () => {
    if (!qualityAssessmentModel || !qualityAssessmentCurrentRevision) {
      return;
    }

    if (supersededQualityAssessmentModel) {
      return migrate(
        qualityAssessmentModel,
        supersededQualityAssessmentModel,
        qualityAssessmentCurrentRevision
      );
    }

    return qualityAssessmentCurrentRevision;
  };

  const dataExtractionViewModel = dataExtractionModel
    ? fromModel(dataExtractionModel)
    : null;
  const qualityAssessmentViewModel = qualityAssessmentModel
    ? fromModel(qualityAssessmentModel)
    : null;
  const dataExtractionForm = useForm({
    defaultValues: getDataExtractionDefaultValues(),
  });
  const qualityAssessmentForm = useForm({
    defaultValues: getQualityAssessmentDefaultValues(),
  });

  const [
    saveDataMutation,
    { data: saveData, error: saveError, loading: saving },
  ] = useMutation<SaveExtractedDataMutation>(SAVE_EXTRACTED_DATA_MUTATION);

  const [
    completeExtractedDataMutation,
    { data: completeData, error: completeDataError, loading: completing },
  ] = useMutation<CompleteExtractedDataMutation>(
    COMPLETE_EXTRACTED_DATA_MUTATION
  );

  useEffect(() => {
    if (navigateToPreviousPage) {
      window.location.assign(backUrl);
    }
  }, [navigateToPreviousPage, backUrl]);

  const [selectedTab, updateSelectedTab] = useState(
    'data_extraction' as ExtractionFormType
  );

  function save(options: { complete: boolean }) {
    let dataExtraction: SaveFormExtractedDataInput | null = null;
    let qualityAssessment: SaveFormExtractedDataInput | null = null;

    if (dataExtractionViewModel && dataExtractionFormRevisionId) {
      dataExtraction = {
        formRevisionId: dataExtractionFormRevisionId,
        data: {
          values: dataExtractionForm.getValues(),
        },
      };
    }

    if (qualityAssessmentViewModel && qualityAssessmentFormRevisionId) {
      qualityAssessment = {
        formRevisionId: qualityAssessmentFormRevisionId,
        data: {
          values: qualityAssessmentForm.getValues(),
        },
      };
    }

    const input = {
      studyId: studyId,
      dataExtraction: dataExtraction,
      qualityAssessment: qualityAssessment,
    };

    interface Mutation {
      (options: { variables: { input: typeof input } }): Promise<unknown>;
    }

    const mutation: Mutation = options.complete
      ? completeExtractedDataMutation
      : saveDataMutation;

    mutation({ variables: { input } })
      .then(() => {
        // Reset form after submission to reset state values, eg: isDirty
        // Allows user to leave the page without getting unsaved changes prompt

        dataExtractionForm.reset({}, { keepValues: true });
        qualityAssessmentForm.reset({}, { keepValues: true });

        if (options.complete) {
          setNavigateToPreviousPage(true);
        }
      })
      .catch((e) => {
        MetaError.notify(e);
      });
  }

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <ToolbarNav
          backUrl={backUrl}
          title={title}
          right={
            <>
              <TertiaryButton
                mr={2}
                className={styles.saveButton}
                disabled={saving}
                onClick={() => {
                  save({
                    complete: false,
                  });
                }}
              >
                {saving ? 'Saving…' : 'Save'}
              </TertiaryButton>
              {/* Making this less ugly would incorrectly fail tests. After spending 30 minutes on it, I gave up trying to work out why the GraphQL mock is broken*/}
              {singleExtractor ? (
                <PrimaryButton
                  disabled={completing}
                  onClick={() => {
                    save({
                      complete: true,
                    });
                  }}
                >
                  {completing ? 'Completing…' : 'Complete'}
                </PrimaryButton>
              ) : (
                <PrimaryButton
                  disabled={completing}
                  onClick={() => {
                    save({
                      complete: true,
                    });
                  }}
                >
                  {completing ? 'Sending for Consensus…' : 'Send for Consensus'}
                </PrimaryButton>
              )}
            </>
          }
        />
        <Errors
          title="Error saving"
          errors={errorMessagesFrom(
            saveData?.saveExtractedData?.errors,
            saveError,
            completeData?.completeExtractedData?.errors,
            completeDataError
          )}
        />
      </header>
      <Split className={styles.split} sizes={[66, 34]} gutterSize={5}>
        <div className={styles.previewPanel}>
          <FullTextsPreview
            fullTexts={fullTexts}
            pspdfkitConfig={pspdfkitConfig}
          />
        </div>
        <div className={styles.forms}>
          <ExtractionTabs
            selected={selectedTab}
            onSelect={updateSelectedTab}
            renderPanel={(type) => {
              switch (type) {
                case 'data_extraction':
                  if (!dataExtractionViewModel) {
                    return null;
                  } else {
                    return (
                      <section
                        className={styles.formPanel}
                        aria-label="Data Extraction"
                      >
                        {dataExtractionViewModel && (
                          <FormProvider {...dataExtractionForm}>
                            <ExtractForm
                              blocks={dataExtractionViewModel.blocks}
                            />
                            <UnsavedChangesWarning />
                          </FormProvider>
                        )}
                      </section>
                    );
                  }
                case 'quality_assessment':
                  if (!qualityAssessmentViewModel) {
                    return null;
                  } else {
                    return (
                      <section
                        className={styles.formPanel}
                        aria-label="Quality Assessment"
                      >
                        {qualityAssessmentViewModel && (
                          <FormProvider {...qualityAssessmentForm}>
                            <ExtractForm
                              blocks={qualityAssessmentViewModel.blocks}
                            />
                            <UnsavedChangesWarning />
                          </FormProvider>
                        )}
                      </section>
                    );
                  }
              }
            }}
          />
        </div>
      </Split>
    </div>
  );
}

export default Extract;
