import { useRef, useEffect } from 'react';

export function useConfirmUnsaved(): [() => void, () => void] {
  const _dirty = useRef<boolean>(false);

  useEffect(() => {
    const handleBeforeUnload = (event: Event) => {
      if (_dirty.current === true) {
        event.preventDefault();
        event.returnValue = false;
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [_dirty]);

  function clean() {
    _dirty.current = false;
  }

  function dirty() {
    _dirty.current = true;
  }

  return [clean, dirty];
}
