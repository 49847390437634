import React from 'react';
import { Label, LabelText } from '../../shared/BlockWidgets';
import { BaseTable } from '../BaseTable/BaseTable';
import { CommentButton } from '../../shared/Comment';
import { MultilinedText } from '../../shared/MultilinedText';
import { TableData, UpdateCellValue } from '../BaseTable/types';
import { updateTable } from '../BaseTable/utils';
import styles from './Table.module.css';
import { TableBlock } from 'concepts/Extraction/Blocks/Table/TableBlock';
import { useDescribedBy, useLabelledBy } from 'hooks/useAccessibleName';

export type ReadonlyTableProps = {
  block: TableBlock;
};

// @TODO: COV-10 allow editable user values for DE (actually using the table).
export type EditableTableProps = ReadonlyTableProps & {
  editable: true;
  value: TableData | null;
  comments: string | null;
  onChange: (newValue: TableData | null) => void;
  onChangeComments: (newComments: string) => void;
};

export type TableProps = ReadonlyTableProps | EditableTableProps;

const isTableEditable = (props: TableProps): props is EditableTableProps =>
  (props as EditableTableProps).editable;

// The DE template preview of a table block.
export const Table = (props: TableProps) => {
  const [labelID, labelledBy] = useLabelledBy();
  const [descriptionID, describedBy] = useDescribedBy();

  const renderCommentsButton = () =>
    isTableEditable(props) ? (
      <CommentButton
        comments={props.comments}
        blockLabel={props.block.label}
        onChangeComments={props.onChangeComments}
      />
    ) : null;

  const updateCellValue: UpdateCellValue = ({
    rowID,
    columnID,
    rowIndex,
    value,
  }) => {
    if (!isTableEditable(props)) return;
    const newTableData = updateTable(
      { rowID, columnID, rowIndex },
      { value },
      props.value ?? []
    );
    props.onChange(newTableData);
  };

  const editableTableProps = isTableEditable(props)
    ? {
        editable: props.editable,
        data: props.value ?? [],
        updateCellValue,
      }
    : {};

  return (
    <>
      <Label>
        <div className={styles.labelOuter}>
          <div>
            <LabelText id={labelID}>
              {props.block.label || 'Table preview'}
            </LabelText>
            {props.block.description !== '' && (
              <div id={descriptionID} className={styles.description}>
                <MultilinedText>{props.block.description}</MultilinedText>
              </div>
            )}
          </div>
          {renderCommentsButton()}
        </div>
      </Label>
      <div>
        <BaseTable
          {...labelledBy}
          {...(!!props.block.description && describedBy)}
          columnHeaders={props.block.columns}
          rowHeaders={props.block.rows}
          {...editableTableProps}
        />
      </div>
    </>
  );
};
