import React, { ComponentPropsWithoutRef, FunctionComponent } from 'react';
import styled from '../styled';
import { SectionHeading } from 'system/base/Heading';
import Box from 'system/base/Box';
import Space from 'system/utility/Space';
import Icon, { IconName } from 'system/base/Icon';

const ActionableBox = styled(Box)(({ theme }) => ({
  '&:hover, &:active': {
    border: theme.borders.actionable,
  },
  cursor: 'pointer',
}));

interface CommonProps {
  primary?: boolean;
  actionable?: boolean;
}

interface PropsWithoutTitle extends ComponentPropsWithoutRef<typeof Box> {
  iconName?: never;
}

interface PropsWithTitle extends ComponentPropsWithoutRef<typeof Box> {
  title: string;
  iconName?: IconName;
}

type Props = CommonProps & (PropsWithoutTitle | PropsWithTitle);

const Panel: FunctionComponent<Props> = ({
  primary,
  actionable,
  title,
  iconName,
  children,
  ...props
}: Props) => {
  const WrapperBox = actionable ? ActionableBox : Box;

  const primaryProps = primary
    ? {
        p: 4,
        boxShadow: 'light',
      }
    : {};

  return (
    <WrapperBox colors="onWhite" p={3} {...primaryProps} {...props}>
      {title && (
        <Space marginTop={0} marginBottom={2}>
          <SectionHeading>
            {iconName && <Icon name={iconName} />}
            {iconName && ' '}
            {title}
          </SectionHeading>
        </Space>
      )}
      {children}
    </WrapperBox>
  );
};

Panel.defaultProps = {
  border: 'panel',
};

export default Panel;
