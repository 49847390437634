import React from 'react';
import Avatar from 'components/Avatar';
import EmailAddress from 'components/EmailAddress';

interface Props {
  name: string;
  email: string;
}
const UserCard = ({ name, email }: Props) => (
  <div style={{ display: 'flex' }}>
    <Avatar name={name} className="avatar--medium" />
    <div>
      <div className="pii">
        <strong>{name}</strong>
      </div>
      <div>
        <EmailAddress address={email} />
      </div>
    </div>
  </div>
);

export default UserCard;
