import { formatDistanceToNow } from 'date-fns';
import React, { useState } from 'react';

import Box from 'system/base/Box';
import Dialog from 'system/base/Dialog';
import Banner from 'system/base/Dialog/Banner';
import Flex from 'system/base/Flex';
import { PrimaryLink, TertiaryLink } from 'system/base/Link';

interface AcceptInvitationProps {
  acceptUrl: string;
  declineUrl: string;
  signedIn: boolean;
  inviteTitle: string;
  inviteType: string;
  canBeFoundIn: string;
  sender: string;
  createdAt: string;
}

export const AcceptInvitation = ({
  inviteTitle,
  inviteType,
  canBeFoundIn,
  sender,
  acceptUrl,
  declineUrl,
  createdAt,
}: AcceptInvitationProps) => {
  const [showModal, setShowModal] = useState<boolean>(true);

  return (
    <Dialog
      title={`Accept invite to ${inviteTitle}`}
      isOpen={showModal}
      onDismiss={() => setShowModal(false)}
    >
      <Banner type="info">
        <p>
          This {inviteType} will appear in <strong>{canBeFoundIn}</strong> if
          you accept this invitation to collaborate.{' '}
        </p>
      </Banner>
      <strong>{sender}</strong> sent you this invitation to collaborate{' '}
      <span className="hide-in-percy">
        {formatDistanceToNow(new Date(createdAt), { addSuffix: true })}.
      </span>
      <Flex mt={3}>
        <Box mr={2}>
          <PrimaryLink href={acceptUrl}>Accept</PrimaryLink>
        </Box>
        <TertiaryLink href={declineUrl}>Decline</TertiaryLink>
      </Flex>
    </Dialog>
  );
};
