import React, { useState } from 'react';
import { Box } from 'theme-ui';

import { AdministratorFragment } from 'types/graphql';
import UserCard from 'components/UserCard';
import { TertiaryButton } from 'system/base/Button';
import { ID } from 'util/types';
import Radio from 'system/elements/Radio';

interface Props {
  administrators: readonly AdministratorFragment[] | null;
  selected?: ID;
  selectAdmin: (a: AdministratorFragment | null) => void;
  disabled?: boolean;
}

const AdminList = ({
  administrators,
  selected: initialSelected,
  selectAdmin,
  disabled,
}: Props) => {
  const [selected, setSelected] = useState(initialSelected);
  const adminOptions = (administrators || []).map((a) => {
    const currentSelected = selected === a.id;

    return (
      <div key={a.id}>
        <div
          style={{
            backgroundColor: currentSelected ? '#e6ecfa' : undefined,
            paddingTop: '5px',
          }}
        >
          <Radio
            name="assignedAdmin"
            value={a.id}
            onChange={() => {
              setSelected(a.id);
              selectAdmin(a);
            }}
            checked={currentSelected}
            disabled={disabled}
            label={<UserCard name={a.name} email={a.email} />}
          />
        </div>
        <hr style={{ marginTop: '5px', marginBottom: '5px' }} />
      </div>
    );
  });

  return (
    <Box px={2}>
      <Box p={2}>{adminOptions}</Box>
      <TertiaryButton
        onClick={() => {
          setSelected(undefined);
          selectAdmin(null);
        }}
        disabled={disabled}
      >
        Clear selection
      </TertiaryButton>
    </Box>
  );
};

export default AdminList;
