import { v4 as uuid } from 'uuid';
import { TableHeaderLabel, TableHeader } from '../types';

/**
 * Creates a new row or column header.
 */
export const getNewHeader = (label: TableHeaderLabel = ''): TableHeader => ({
  type: 'TableHeader',
  id: uuid(),
  label,
});
