// allow overloaded private class members
/* eslint-disable no-dupe-class-members */

import localStorageMemory from 'localstorage-memory';
import { isLocalStorageAvailable } from '../util/isLocalStorageAvailable';

const COLLECTION = 'collection';

export default class PersistedCollection<T> implements IterableIterator<T> {
  private namespace: string;
  private iteratorIndex: number;
  private iteratorCollection: undefined | Array<number>;
  persisted: boolean;

  storage: Storage;

  constructor(namespace: string) {
    this.namespace = namespace;
    this.iteratorIndex = 0;
    this.persisted = isLocalStorageAvailable();

    if (this.persisted) {
      this.storage = window.localStorage;
    } else {
      this.storage = localStorageMemory;
    }
  }

  clear(filterFn?: (item: T) => boolean) {
    if (filterFn === undefined) {
      return this.clearAll();
    }

    // TODO: assuming we don't add errant keys, we should proably iterate over
    // COLLECTION rather than .itemKeys()
    const collection: Array<number> = this.getItem(COLLECTION) || [];
    this.itemKeys().forEach((key) => {
      if (filterFn(this.getItem(key))) {
        this.removeItem(key);
        const itemIndex = collection.findIndex((index) => index === key);
        if (itemIndex > -1) {
          collection.splice(itemIndex, 1);
        }
      }
      this.setItem(COLLECTION, collection);
    });
  }

  clearAll() {
    this.itemKeys().forEach((key) => {
      this.removeItem(key);
    });
    this.removeItem(COLLECTION);
  }

  pop(): T | undefined {
    const collection = this.getItem(COLLECTION) || [];
    const itemKey = collection.pop();
    if (itemKey !== undefined) {
      this.setItem(COLLECTION, collection);
      const item = this.getItem(itemKey);
      this.removeItem(itemKey);
      return item;
    }
    return undefined;
  }

  push(...elements: T[]) {
    const collection = this.getItem(COLLECTION) || [];
    let nextKey = this.nextStorageKey(collection);
    const itemKeys = elements.map((element) => {
      const key = nextKey;
      this.setItem(key, element);
      nextKey++;
      return key;
    });
    const newLength = collection.push(...itemKeys);
    this.setItem(COLLECTION, collection);
    return newLength;
  }

  shift() {
    const collection = this.getItem(COLLECTION) || [];
    const itemKey = collection.shift();
    if (itemKey !== undefined) {
      this.setItem(COLLECTION, collection);
      const item = this.getItem(itemKey);
      this.removeItem(itemKey);
      return item;
    }
    return undefined;
  }

  unshift(...elements: T[]) {
    const collection = this.getItem(COLLECTION) || [];
    let nextKey = this.nextStorageKey(collection);
    const itemKeys = elements.map((element) => {
      const key = nextKey;
      this.setItem(key, element);
      nextKey++;
      return key;
    });
    const newLength = collection.unshift(...itemKeys);
    this.setItem(COLLECTION, collection);
    return newLength;
  }

  toArray(): Array<T> {
    return (this.getItem(COLLECTION) || []).map((key: number) =>
      this.getItem(key)
    );
  }

  get length() {
    return (this.getItem(COLLECTION) || []).length;
  }

  // IterableIterator interface

  next() {
    // during iteration we'll refer to this.iteratorCollection to avoid
    // deserializing COLLECTION from localStorage for every item
    if (this.iteratorCollection === undefined) {
      this.iteratorCollection = this.getItem(COLLECTION);
    }

    // ensure collection's type is not undefined
    const collection = this.iteratorCollection || [];

    if (this.iteratorIndex < collection.length) {
      return {
        value: this.getItem(collection[this.iteratorIndex++]),
      };
    } else {
      this.iteratorIndex = 0;
      this.iteratorCollection = undefined;
      return { done: true, value: undefined } as const;
    }
  }

  [Symbol.iterator]() {
    return this;
  }

  // persistence methods

  private nextStorageKey(collection: Array<number>) {
    const keys = collection || [];
    return Math.max(...keys, -1) + 1;
  }

  // unordered keys in this namespace
  private itemKeys() {
    const keys: Array<number> = Array(this.storage.length);
    for (let i = 0; i < this.storage.length; i++) {
      const key = this.storage.key(i);
      if (
        key &&
        key.indexOf(this.namespaceKey('')) === 0 &&
        key !== this.namespaceKey(COLLECTION)
      ) {
        keys.push(Number(this.unnamespaceKey(key)));
      }
    }
    return keys;
  }

  private namespaceKey(key: string) {
    return `pc_${this.namespace}/${key}`;
  }

  private unnamespaceKey(key: string) {
    return key.substr(this.namespaceKey('').length);
  }

  // serialized storage access methods

  private setItem(key: typeof COLLECTION, value: Array<number>): void;
  private setItem(key: number, value: T): void;
  private setItem(key: typeof COLLECTION | number, value: any) {
    // might throw instanceof DOMException, which means quota may be exceeded
    this.storage.setItem(this.namespaceKey(String(key)), JSON.stringify(value));
  }

  private getItem(key: typeof COLLECTION): Array<number>;
  private getItem(key: number): T;
  private getItem(key: typeof COLLECTION | number) {
    try {
      return JSON.parse(
        this.storage.getItem(this.namespaceKey(String(key))) || 'null'
      );
    } catch (e) {
      if (e instanceof SyntaxError) {
        throw new Error('Error parsing persisted object');
      } else {
        throw e;
      }
    }
  }

  private removeItem(key: typeof COLLECTION): void;
  private removeItem(key: number): void;
  private removeItem(key: typeof COLLECTION | number) {
    this.storage.removeItem(this.namespaceKey(String(key)));
  }
}
