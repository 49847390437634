import { CitationImportSourcePartialFormData } from 'types';

export const referenceCountValidator = (
  formValues: CitationImportSourcePartialFormData[],
  expectedCount: number
) => {
  if (formValues.length === 0) {
    return;
  }

  const totalSources = formValues.reduce((total, { numberOfCitations }) => {
    // Don't try to total falsy values
    if (!numberOfCitations) {
      return total;
    }

    // Default negative numbers to zero to avoid adding them to total
    return total + Math.max(numberOfCitations, 0);
  }, 0);

  if (totalSources === expectedCount) {
    return;
  }

  if (totalSources > expectedCount) {
    return `Total # references must equal ${expectedCount}, please remove ${
      totalSources - expectedCount
    }`;
  }

  return `Total # references must equal ${expectedCount}, please add ${
    expectedCount - totalSources
  }`;
};
