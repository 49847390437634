import React from 'react';
import { screenVoteCounts } from '../ReviewersRequired/ReviewersRequired';
import styles from './ReviewersRequiredScreenConfirmationModal.module.scss';
import {
  Modal,
  ModalBody,
  ModalHeader,
  Text,
  Button,
  Message,
  ModalFooter,
} from 'components/core';
import { Stack } from 'components/layout';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onContinue: () => void;
  votes: screenVoteCounts;
}

const ReviewersRequiredScreenConfirmationModal = ({
  votes,
  isOpen,
  onClose,
  onContinue,
}: Props) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalHeader>Switch to single reviewer</ModalHeader>
      <ModalBody className={styles.bodyContent}>
        <Stack spacing={3}>
          <Message type="warning" variant="prominent" dismissible={false}>
            <Text>
              It is not possible to dual screen these studies once they have
              moved
            </Text>
          </Message>

          <Text size="sm">
            {votes.total.toString()} studies have votes from one reviewer.
          </Text>

          <div>
            <Text size="sm" className={styles.vote} data-testid="yes-votes">
              <Text className={styles.voteType} component="span" variant="weak">
                Yes:
              </Text>
              <Text className={styles.voteCount} component="span">
                {votes.yes.toString()}
              </Text>
            </Text>

            <Text size="sm" className={styles.vote} data-testid="maybe-votes">
              <Text className={styles.voteType} component="span" variant="weak">
                Maybe:
              </Text>
              <Text className={styles.voteCount} component="span">
                {votes.maybe.toString()}
              </Text>
            </Text>

            <Text size="sm" className={styles.vote} data-testid="no-votes">
              <Text className={styles.voteType} component="span" variant="weak">
                No:
              </Text>
              <Text className={styles.voteCount} component="span">
                {votes.no.toString()}
              </Text>
            </Text>
          </div>

          <Text size="sm">
            The studies with &quot;Yes&quot; and &quot;Maybe&quot; votes will be{' '}
            <b>permanently</b> moved to full text review. The studies with a{' '}
            &quot;No&quot; vote will be <b>permanently</b> moved to irrelevant.{' '}
            Once they&apos;ve been moved forward they cannot be moved back in{' '}
            bulk.
          </Text>
        </Stack>
      </ModalBody>
      <ModalFooter>
        <Button
          htmlType="button"
          type="neutral"
          variant="outline"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          htmlType="submit"
          type="brand"
          variant="solid"
          onClick={onContinue}
        >
          Continue
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ReviewersRequiredScreenConfirmationModal;
