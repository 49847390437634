import { useQuery } from '@apollo/client';
import idx from 'idx';
import React, { ComponentProps } from 'react';
import GET_ADMINISTRATORS from './getAdministrators';
import { OptionType } from 'components/Dropdown';
import FilterDropdown from 'system/data/FilterDropdown';
import {
  AdministratorsQuery,
  AdministratorsQueryVariables,
} from 'types/graphql';
import { ID } from 'util/types';
import Spinner from 'system/elements/Spinner';

interface Props
  extends AdministratorsQueryVariables,
    Omit<ComponentProps<typeof FilterDropdown>, 'options'> {}

const AssignedAdminFilterDropdown = ({ organizationId, ...props }: Props) => {
  const { data, loading } = useQuery<
    AdministratorsQuery,
    AdministratorsQueryVariables
  >(GET_ADMINISTRATORS, {
    variables: { organizationId },
  });

  const baseOptions: OptionType<null | undefined | ID>[] = [
    { label: 'All Administrators', value: undefined },
    { label: 'Unassigned', value: null },
  ];

  if (loading) {
    return <Spinner />;
  }

  const options = baseOptions.concat(
    (idx(data, (_) => _.organization.administrators.nodes) || []).map(
      (admin) => ({
        label: admin.name,
        value: admin.id,
      })
    )
  );

  return <FilterDropdown {...props} options={options} />;
};

export default AssignedAdminFilterDropdown;
