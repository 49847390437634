import React from 'react';
import styles from './SectionHeading.module.css';

export interface SectionHeadingProps {
  children: React.ReactNode;
}

export function SectionHeading({ children }: SectionHeadingProps) {
  return <h2 className={styles.heading}>{children}</h2>;
}
