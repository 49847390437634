import React from 'react';
import { Button, ButtonProps } from 'theme-ui';
import Icon, { IconName } from './Icon';

export type IconProps =
  | {
      iconName?: IconName;
      iconOnly?: boolean;
    }
  | {
      iconName: IconName;
      iconOnly: true;
    };

type InternalVariant =
  | 'Primary'
  | 'Secondary'
  | 'Tertiary'
  | 'Danger'
  | 'Ghost'
  | 'Naked'
  | 'Link';

type VariantProps = IconProps & {
  children: React.ReactNode;
  className?: string;
};

export type CovidenceButtonProps = ButtonProps & IconProps & VariantProps;

export function renderVariant(
  { iconName, iconOnly, ...props }: CovidenceButtonProps,
  ref: ButtonProps['ref'],
  variant: InternalVariant,
  className?: string
): JSX.Element {
  const titleWhenIconOnly =
    iconOnly === true && typeof props.children === 'string'
      ? props.children
      : undefined;

  if (props.className) {
    className = `${className || ''} ${props.className}`;
  }

  return (
    <Button
      variant={variant.toLowerCase()}
      {...props}
      ref={ref}
      className={className}
      title={titleWhenIconOnly}
      aria-label={props['aria-label'] || titleWhenIconOnly}
    >
      {iconName && (
        <>
          <Icon name={iconName} marginLeft={-1} />{' '}
        </>
      )}
      {!iconOnly && props.children}
    </Button>
  );
}
