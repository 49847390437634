import styled from '@emotion/styled';
import { CreateStyled as BaseCreateStyled } from '@emotion/styled/types/base';

const isSomeStylesStrings = (...styles: any[]): boolean =>
  styles.some((style) => typeof style === 'string');

const stringStylesError = (...styles: any[]): Error => {
  console.warn('String style(s) found in', styles);
  return new Error('specificStyled() does not support string styles');
};

/**
 * increasedSpecificityStyled increases the specificity of styles applied by
 * @emotion/styled.
 * Use increasedSpecificityStyled instead of @emotion/styled.
 *
 * @privateRemarks
 * This is a hack to override tachyon's selector specificity.
 * We are trying to move away from tachyon.
 *
 * Explanation of TS types:
 * I have used BaseCreateStyled instead of 'typeof styled' (which is currently
 * CreateStyled from '@emotion/styled/types/index.ts') because CreateStyled
 * extends BaseCreateStyled with StyledTags, which causes type errors.
 *
 * StyledTags at present cannot be properly omitted using the Omit utility type:
 * 'Omit<CreateStyled, keyof StyledTags>' because currently the Omit TypeScript
 * utility handles this incorrectly - inferring that the new interface is just
 * empty: {} (incorrect) instead of BaseCreatedStyled (correct).
 */
export const increasedSpecificityStyled: BaseCreateStyled = (
  ...styledParams: Parameters<BaseCreateStyled>
): ReturnType<BaseCreateStyled> => (...args: any[]) => {
  /**
   * My understanding is we caution against string styles because we have not
   * written increasedSpecificityStyled robustly to account for string styles,
   * which cannot apply the css specificity hack correctly.
   */
  if (process.env.NODE_ENV === 'development' && isSomeStylesStrings(args)) {
    throw stringStylesError(args);
  }

  /**
   * Increase specificity by applying css selector thrice.
   */
  return styled(...styledParams)({ '&&&': args });
};

/**
 * Asserts increasedSpecificityStyled has identical type as @emotion/styled to
 * benefit from styled's CreateStyled extended interface which includes more
 * type properties than BaseCreateStyled.
 */
export default increasedSpecificityStyled as typeof styled;
