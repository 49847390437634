/**
 * Get the current review id by scraping the URL
 *
 * TODO: find a better way of doing this, along with getStudyId
 */
export const getReviewId = () => {
  const tokens = window.location.href.split('/');
  const index = tokens.indexOf('reviews');

  if (index >= 0 && index < tokens.length - 1) {
    return tokens[index + 1];
  }
  return null;
};
