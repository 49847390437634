import React from 'react';
import {
  NavigationTabItem,
  NavigationTabs,
} from 'components/NavigationTabs/NavigationTabs';

import { ExtractionStudiesStatus } from 'types/graphql';

export type Status = ExtractionStudiesStatus | null;

export interface StatusBarProps {
  currentStatus?: Status;
  included: number;
  notStarted: number;
  inProgress: number;
  consensusRequired: number;
  complete: number;
  baseUrl: string;
}

const urlWithStatus = (status: Status, baseUrl: string) => {
  if (!status) {
    return baseUrl;
  }

  const url = new URL(baseUrl);
  url.searchParams.set('filter[status]', status);

  return url.toString();
};

export const StatusBar = ({
  currentStatus,
  baseUrl,
  included,
  notStarted,
  inProgress,
  consensusRequired,
  complete,
}: StatusBarProps) => {
  // Shorthand
  const status = ExtractionStudiesStatus;

  return (
    <NavigationTabs>
      <NavigationTabItem
        active={!currentStatus}
        name={'Total included'}
        count={included}
        url={baseUrl}
      />
      <NavigationTabItem
        active={status.NotStarted === currentStatus}
        name="Not started"
        count={notStarted}
        url={urlWithStatus(status.NotStarted, baseUrl)}
      />
      <NavigationTabItem
        active={status.InProgress === currentStatus}
        name="In progress"
        count={inProgress}
        url={urlWithStatus(status.InProgress, baseUrl)}
      />
      <NavigationTabItem
        active={status.ConsensusRequired === currentStatus}
        name="Consensus required"
        count={consensusRequired}
        url={urlWithStatus(status.ConsensusRequired, baseUrl)}
      />
      <NavigationTabItem
        active={status.Complete === currentStatus}
        name="Complete"
        count={complete}
        url={urlWithStatus(status.Complete, baseUrl)}
      />
    </NavigationTabs>
  );
};
