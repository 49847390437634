import React from 'react';
import { TableBlock } from '../TableBlock';
import { InstructionsToExtractors } from '../../shared/InstructionsToExtractors';
import { TableHeaderFields } from './settings/TableHeaderFields';

export interface TableEditorSettingsProps {
  block: TableBlock;
  onChange: (updatedBlock: TableBlock) => void;
}

export const TableEditorSettings = ({
  block,
  onChange,
}: TableEditorSettingsProps) => (
  <div>
    <InstructionsToExtractors block={block} onChange={onChange} />
    <hr />
    <TableHeaderFields
      block={block}
      onChange={onChange}
      headerType="column"
      formDescription="Enter column heading(s)"
    />
    <hr />
    <TableHeaderFields
      block={block}
      onChange={onChange}
      headerType="row"
      formDescription="Enter row heading(s)"
    />
    <hr />
  </div>
);
