import React from 'react';
import styles from './ProgressBar.module.css';

const ProgressBar = ({ uploadProgress }: { uploadProgress: number }) => {
  return (
    <div className={styles.wrapper}>
      <div
        role="progressbar"
        className={styles.progress}
        style={{
          width: `${Math.ceil(uploadProgress * 100)}%`,
          display: `${uploadProgress === 0 ? 'none' : 'block'}`,
        }}
      />
    </div>
  );
};

export default ProgressBar;
