import React from 'react';

import styles from './StageProgress.module.css';
import Progress from './Progress';
import Legend from './Legend';
import { Statistics, Stage } from './Home';

interface StageProgressProps extends Statistics {
  stage: Stage;
}

const StageProgress = ({
  complete,
  partiallyComplete,
  consensusRequired,
  notStarted,
  stage,
}: StageProgressProps) => {
  return (
    <div className={styles.stageProgress}>
      <div>
        <div className={styles.lightHeader}>Team Progress</div>
        <Progress
          complete={complete}
          partiallyComplete={partiallyComplete}
          consensusRequired={consensusRequired}
          notStarted={notStarted}
        />
        <Legend
          stage={stage}
          complete={complete}
          partiallyComplete={partiallyComplete}
          consensusRequired={consensusRequired}
          notStarted={notStarted}
        />
      </div>
    </div>
  );
};

export default StageProgress;
