import React from 'react';

import { VisuallyHidden } from '@reach/visually-hidden';
import styles from './ToolbarNav.module.css';

export interface ToolbarNavProps {
  backUrl: string;
  title: string;
  left?: JSX.Element;
  right?: JSX.Element;
}
export default function ToolbarNav({
  backUrl,
  title,
  left,
  right,
}: ToolbarNavProps): JSX.Element {
  return (
    <nav className={styles.nav}>
      <a className={styles.backButton} href={backUrl} title="Back">
        <VisuallyHidden>Back</VisuallyHidden>
      </a>
      <h2 className={styles.title}>{title}</h2>
      {left && <div className={styles.left}>{left}</div>}
      {right && <div className={styles.right}>{right}</div>}
      <button
        data-target="#feedback-and-support"
        data-toggle="modal"
        className={styles.help}
      >
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.4258 22.2143C17.4258 22.4152 17.2695 22.5714 17.0686 22.5714H14.9258C14.7249 22.5714 14.5686 22.4152 14.5686 22.2143V20.0714C14.5686 19.8705 14.7249 19.7143 14.9258 19.7143H17.0686C17.2695 19.7143 17.4258 19.8705 17.4258 20.0714V22.2143ZM20.2829 14.7143C20.2829 16.4107 19.1222 17.058 18.274 17.5379C17.7494 17.8393 17.4258 18.3862 17.4258 18.6429C17.4258 18.8438 17.2695 19 17.0686 19H14.9258C14.7249 19 14.5686 18.8438 14.5686 18.6429V18.2411C14.5686 17.1585 15.6512 16.2321 16.4436 15.875C17.1244 15.5625 17.4258 15.2723 17.4258 14.692C17.4258 14.2009 16.7673 13.7433 16.053 13.7433C15.6512 13.7433 15.2829 13.8772 15.0932 14.0112C14.8811 14.1674 14.6579 14.3683 14.1334 15.0379C14.0664 15.1272 13.9548 15.1719 13.8544 15.1719C13.7762 15.1719 13.6981 15.1496 13.6423 15.1049L12.1691 13.9888C12.024 13.8772 11.9794 13.6763 12.0798 13.5201C13.0396 11.9241 14.4012 11.1429 16.2204 11.1429C18.1401 11.1429 20.2829 12.6719 20.2829 14.7143ZM24.5686 16.8571C24.5686 12.125 20.7294 8.28571 15.9972 8.28571C11.2651 8.28571 7.42578 12.125 7.42578 16.8571C7.42578 21.5893 11.2651 25.4286 15.9972 25.4286C20.7294 25.4286 24.5686 21.5893 24.5686 16.8571Z"
            fill="currentColor"
          />
        </svg>
      </button>
    </nav>
  );
}
