import React from 'react';
import { useUID } from 'react-uid';
import { InsertDriveFile } from '../Icons/InsertDriveFile';
import styles from './Documents.module.css';
import { Document } from './Document';

import { Document as DocumentProps } from 'types/graphql';

export interface DocumentsProps {
  documents: Array<DocumentProps>;
  deleteDocument: (id: string) => void;
  markDocumentAsPrimary: (id: string) => void;
}

export function Documents({
  markDocumentAsPrimary,
  documents,
  deleteDocument,
}: DocumentsProps) {
  const uploadedFilesUID = `reference-files-uid-${useUID()}`;
  if (documents.length == 0) {
    return null;
  }

  const sortedDocuments = [...documents].sort((document) =>
    document.primaryDocument ? -1 : 1
  );

  return (
    <section aria-labelledby={uploadedFilesUID} data-testid="documents">
      <h3 id={uploadedFilesUID} className={styles.heading}>
        <InsertDriveFile className={styles.icon} />
        Uploaded files
      </h3>
      <ul>
        {sortedDocuments.map((document, index) => (
          <Document
            markDocumentAsPrimary={markDocumentAsPrimary}
            deleteDocument={deleteDocument}
            key={index}
            {...document}
          />
        ))}
      </ul>
    </section>
  );
}
