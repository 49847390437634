export default function displayName(name: string | undefined) {
  if (name === undefined) {
    return 'Unknown';
  }

  const nameParts = name.split(' ').filter((n) => n && n.length > 0);
  const firstName = nameParts.shift();
  const lastNames = nameParts.map((name) => name[0].toUpperCase()).join(' ');
  return `${firstName} ${lastNames}`;
}
