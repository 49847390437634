/*
# Metric

A Text, but for rendering numbers in a formatted way

*/
import React, { FunctionComponent, ComponentProps } from 'react';
import Text from 'system/base/Text';
import Flex from 'system/base/Flex';
import { GhostLink } from 'system/base/Link';

interface Props extends ComponentProps<typeof Flex> {
  backHref: string;
}

const MobileNavBar: FunctionComponent<Props> = ({
  children,
  backHref,
  ...props
}: Props) => {
  return (
    <Flex alignItems="center" flexWrap="nowrap" colors="onPrimary" {...props}>
      <GhostLink
        iconName="arrow-left"
        iconOnly
        href={backHref}
        p={2}
        sx={{ px: 3, borderRadius: 2, margin: 3, fontSize: 16 }}
      >
        Back
      </GhostLink>
      <Text fontSize={3} textStyle="truncate">
        {children}
      </Text>
    </Flex>
  );
};

export default MobileNavBar;
