import React from 'react';
import { GuidelineStudy } from '../../GuidelineStudy/GuidelineStudy';
import { SecondaryButton } from 'system/base/Button';
import {
  StudyModel,
  HaltedStudyModel,
} from 'containers/Guidelines/Triage/StudyModel';
import styles from 'containers/Guidelines/Triage/Triage.module.css';

export interface HaltedStudyProps {
  study: HaltedStudyModel;
}

export function HaltedStudy({ study }: HaltedStudyProps): JSX.Element {
  return (
    <div
      key={study.id}
      data-testid={`halted-study-${study.id}`}
      className={styles.assignmentPanel}
    >
      <div className={styles.assignmentPanelLeft}>
        <GuidelineStudy study={study as StudyModel} showAbstract={false} />
      </div>
      <div className={styles.assignmentPanelRight}>
        {study.status} {study.statusUpdateAgo} ago
        <form method="post" action={study.actions.restoreStudyUrl}>
          <SecondaryButton style={{ marginTop: '20px', width: '200px' }}>
            Restore
          </SecondaryButton>
        </form>
      </div>
    </div>
  );
}
