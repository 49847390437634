import React from 'react';
import { BillableAccount } from '../reviewInnerProps';
import AccountOption from './AccountOption/AccountOption';
import { Stack } from 'components/layout';

const getAvailableReviewCount = (availableReviewCount: number | undefined) => {
  const limit = availableReviewCount;
  switch (limit) {
    case Infinity:
    case null:
    case undefined:
      return 'unlimited reviews left';
    case 1:
      return '1 review left';
    case 0:
    default:
      return `${limit} reviews left`;
  }
};

function availableReviewsText(
  account: Partial<BillableAccount>,
  isCochrane: boolean
) {
  if (isCochrane) {
    return 'unlimited Cochrane reviews';
  }

  if (account.canStartTrial && !account.hasTrialed) {
    if (account.screenLimit) {
      return `Start free trial (limited to ${account.screenLimit} records)`;
    }
    return 'Start free trial';
  }

  return getAvailableReviewCount(account.availableReviewCount);
}

const noReviewsWarning = (selectedAccount?: BillableAccount) => {
  if (
    selectedAccount?.availableReviewCount === 0 &&
    selectedAccount?.hasTrialed === true
  ) {
    return (
      <div className="bg-warning reviews_new--review-count-note">
        <i className="fas fa-exclamation-triangle reviews_new--warning" />
        The selected account has no more reviews. You&apos;ll need to{' '}
        <a href="/plans">buy a new subscription</a> for this review.
      </div>
    );
  }
  return <></>;
};

const AccountSelector = ({
  accounts,
  selectedAccountId,
  onSelectAccount,
  isCochraneReview,
}: {
  accounts: BillableAccount[];
  isCochraneReview: boolean;
  selectedAccountId: string;
  onSelectAccount: (a: string) => any;
  enabledOrganization?: string | null;
}) => {
  if (!accounts || accounts.length === 0) return null;
  const multiple = accounts.length > 1;

  const selectedAccount = accounts.find((acc) => {
    return selectedAccountId === acc.id;
  });

  return (
    <>
      {multiple ? (
        <h3>Which account do you want to use?</h3>
      ) : (
        <h3>Account details</h3>
      )}
      <Stack spacing={4}>
        {accounts.map((account) => {
          const caption = availableReviewsText(account, isCochraneReview);
          const { ssoRequired, signedIn, ssoSigninLink } = account;
          // If you're signed in you don't need to show the "Sign in" button
          const signInLink =
            ssoRequired && !signedIn ? ssoSigninLink : undefined;

          return (
            <AccountOption
              accountId={account.id}
              accountName={account?.owner?.name || ''}
              caption={caption}
              isCochrane={isCochraneReview}
              key={account.id}
              onAvatarSelection={() => onSelectAccount(account.id)}
              selectable={multiple && (!ssoRequired || !!signedIn)}
              selectedValue={selectedAccountId}
              ssoSignInLink={signInLink}
            />
          );
        })}
      </Stack>
      {noReviewsWarning(selectedAccount)}

      {isCochraneReview && (
        <div className="bg-success reviews_new--review-count-note">
          <i className="fas fa-check reviews_new--success" />
          Cochrane reviews are free of charge. This review will not count
          towards your plan&apos;s quota.
        </div>
      )}
    </>
  );
};

export default AccountSelector;
