import { useContext } from 'react';
import ReviewLinkContext from './ReviewLinkContext';

export function CategoryLink(category: string, filter?: null | string) {
  const reviewLink = useContext(ReviewLinkContext);
  const base = `${reviewLink}/review_studies/${category}`;

  if (filter) {
    return `${base}?filter=${filter}`;
  }

  return base;
}
