import React, { InputHTMLAttributes } from 'react';
import styles from './Input.module.scss';

export interface Props
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'size' | 'type'> {
  /**
   * Specifies the style context for the input. Default is `primary`
   */
  type?: 'neutral' | 'success' | 'danger' | 'warning' | 'primary';
  /**
   * Specifies the input style variant. Default is `outline`
   */
  variant?: 'outline' | 'filled';
  /**
   * Sets the input to a specific size, overriding inherited sizes.
   */
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  /**
   * Sets an error state on the input
   */
  isError?: boolean;
  /**
   * Sets the type of the underlying input
   */
  inputType?:
    | 'email'
    | 'number'
    | 'password'
    | 'search'
    | 'tel'
    | 'text'
    | 'url';
}

const Input = (
  {
    type = 'primary',
    variant = 'outline',
    size,
    inputType = 'text',
    isError,
    className,
    ...props
  }: Props,
  ref: React.ForwardedRef<HTMLInputElement>
) => {
  const classes = [styles.Input, styles[type], styles[variant]];
  if (size) classes.push(styles[size]);
  if (isError) classes.push(styles.isError);
  if (className) classes.push(className);

  return (
    <input
      type={inputType}
      className={classes.join(' ')}
      aria-invalid={isError}
      ref={ref as React.RefObject<HTMLInputElement>}
      {...props}
    />
  );
};

const forwardedInput = React.forwardRef<HTMLInputElement, Props>(Input);

export default forwardedInput;
export { forwardedInput as Input };
