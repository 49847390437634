import React from 'react';
import styles from './AddOutcome.module.scss';
import { Icon, IconList, Link, Text } from 'components/core';

interface AddOutcomeProps {
  onClick: () => void;
  length: number;
  disabled?: boolean;
}

const AddOutcome = ({ onClick, length, disabled }: AddOutcomeProps) => {
  const ShowLimitWarning = () => {
    if (length > 200) {
      return (
        <Text size="xs" variant="weak">
          You have {length} outcomes which is over the maximum limit of 200.
          Please delete outcomes to export your extracted data.
        </Text>
      );
    }
    if (length === 200) {
      return (
        <Text size="xs" variant="weak">
          You&apos;ve reached a maximum of 200 outcomes. Please delete outcomes
          if you need to add more.
        </Text>
      );
    }
    if (length >= 180) {
      return (
        <Text size="xs" variant="weak">
          You have {length} of max 200 outcomes
        </Text>
      );
    }

    return null;
  };

  interface ShowOutcomeButtonProps {
    disabled?: boolean;
  }
  const ShowOutcomeButton = ({ disabled }: ShowOutcomeButtonProps) => {
    if (length >= 200) {
      return null;
    }

    const variant = disabled ? 'weak' : 'primary';

    return (
      <Link onClick={onClick} variant={variant} disabled={disabled}>
        <Icon icon={IconList.light.faPlus} /> Add outcome
      </Link>
    );
  };

  return (
    <div className={styles.AddOutcome}>
      <ShowOutcomeButton disabled={disabled} />
      <ShowLimitWarning />
    </div>
  );
};

export default AddOutcome;
