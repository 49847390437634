import React, { Component } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';

class Modal extends Component {
  constructor() {
    console.warn(
      'Modal is deprecated. Please use webpack/system/base/Dialog.tsx'
    );
    super();
    this.setRef = this.setRef.bind(this);
  }

  componentWillUnmount() {
    if (this.$node && typeof this.$node.modal === 'function') {
      this.$node.modal('hide');
    }
  }

  setRef(node) {
    const { onHideModal } = this.props;
    const $node = $(node);

    this.$node = $node;

    if (typeof $node.modal === 'function') {
      $node.modal().on('hidden.bs.modal', () => {
        if (typeof onHideModal === 'function') {
          onHideModal();
        }
      });

      // prevent scrolling
      $node.on('mousewheel DOMMouseScroll', (event) => event.preventDefault());
    }
  }

  render() {
    const { className, children, title, footer } = this.props;

    return (
      <div className="modal" ref={this.setRef}>
        <div className={`modal-dialog ${className}`}>
          <div className="modal-content">
            {title && <div className="modal-header">{title}</div>}

            <div className="modal-body">{children}</div>

            <div className="modal-footer">{footer}</div>
          </div>
        </div>
      </div>
    );
  }
}

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.node,
  footer: PropTypes.node.isRequired,
  onHideModal: PropTypes.func,
  className: PropTypes.string,
};

Modal.defaultProps = {
  title: '',
  className: 'modal-lg',
  onHideModal: null,
};

export default Modal;
