import React from 'react';
import Select from 'react-select';
import { OtherRoleTextField } from './OtherRoleTextField';

interface SignupRoleProps {
  objectName: string;
  selectedRole: string;
  defaultRole: string;
  defaultOtherRole: string;
  onSelectRole: (role: string) => void;
}

export const SignupRole = ({
  objectName,
  selectedRole,
  defaultRole,
  defaultOtherRole,
  onSelectRole,
}: SignupRoleProps) => {
  const roles = ['Student', 'Librarian', 'Researcher', 'Other'];

  const options = roles.map((role) => ({ value: role, label: role }));
  const defaultOption = options.find(({ value }) => value === defaultRole);

  return (
    <div>
      <div className="input form-group">
        <label htmlFor="role" className="required">
          What role best describes you? <abbr title="required">*</abbr>
        </label>
        <Select
          classNamePrefix="role"
          options={options}
          defaultValue={defaultOption}
          isClearable
          onChange={(option) => {
            if (!option) return;
            onSelectRole(option.value);
          }}
        />
      </div>
      {selectedRole === 'Other' && (
        <OtherRoleTextField
          objectName={objectName}
          defaultOtherRole={defaultOtherRole}
        />
      )}
      <input type="hidden" name={`${objectName}[role]`} value={selectedRole} />
    </div>
  );
};
