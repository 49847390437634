import { getReviewId } from './Review';
import { Criteria } from 'types';
import { CRITERIA, tokenizeRoute } from 'query/routes';
import { getCsrfToken } from 'query';

/**
 * Gets a list of review criteria
 */
export const getCriteria = async (): Promise<Criteria> => {
  const reviewId = getReviewId();
  if (!reviewId)
    throw new Error("Couldn't get Review ID. Is this part of a review?");

  const url = tokenizeRoute(CRITERIA, {
    review_id: reviewId,
  });

  return fetch(url.toString(), {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  })
    .then((res) => res.json())
    .then((res) => res.criteria);
};

/**
 * Update review critera
 */
export const updateCriteria = async (criteria: Criteria): Promise<Criteria> => {
  const reviewId = getReviewId();
  if (!reviewId)
    throw new Error("Couldn't get Review ID. Is this part of a review?");

  const url = tokenizeRoute(CRITERIA, {
    review_id: reviewId,
  });

  return fetch(url.toString(), {
    method: 'POST',
    body: JSON.stringify({ criteria }),
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': getCsrfToken(),
    },
  }).then((res) => res.json());
};
