import React, { useState } from 'react';
import { useDescribedBy } from 'hooks/useAccessibleName';
import { TextField } from 'system/elements';
import Notice from 'system/elements/Notice';

export interface OtherLabelFieldProps {
  value: string | null;
  setOtherLabel: React.Dispatch<React.SetStateAction<string | null>>;
  name: string;
  label: string;
  missingError: string;
}

export const OtherLabelField = ({
  value,
  setOtherLabel,
  name = 'other-label',
  label,
  missingError,
}: OtherLabelFieldProps) => {
  // Displayed value in the other type label textbox
  const [otherLabelFieldValue, setOtherLabelFieldValue] = useState(value);

  const formatInput = (value: string) => value.trim();

  // Error if other type label textbox is left blank
  const [otherLabelFieldError, setOtherLabelFieldError] = useState<
    null | string
  >(null);

  const [otherLabelErrorID, describeOtherLabelErrorID] = useDescribedBy();

  const validateOtherLabel = () => {
    if (!value) {
      setOtherLabelFieldError(missingError);
    }
  };

  const clearOtherLabelValidationError = () => {
    setOtherLabelFieldError(null);
  };

  return (
    <div style={{ paddingTop: '15px' }}>
      <TextField
        required
        name={name}
        labelClassName="help-block"
        label={label}
        value={otherLabelFieldValue ?? ''}
        aria-invalid={!!otherLabelFieldError}
        {...(!!otherLabelFieldError && describeOtherLabelErrorID)}
        onBlur={() => {
          setOtherLabelFieldValue(value || '');
          validateOtherLabel();
        }}
        onFocus={clearOtherLabelValidationError}
        onChange={(e) => {
          setOtherLabelFieldValue(e.target.value);
          setOtherLabel(formatInput(e.target.value) || null);
        }}
      />
      {!!otherLabelFieldError && (
        <Notice mt={1} type="error" id={otherLabelErrorID}>
          {otherLabelFieldError}
        </Notice>
      )}
    </div>
  );
};
