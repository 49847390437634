/**
 * Types used by the study level DE/QA forms
 */

export enum ExtractionPermissions {
  CONFIGURE = 'can_configure_extraction',
}

export interface UserPermissions {
  canConfigure: boolean;
}

export interface Arm {
  id: string;
  intervention_id: string;
  intervention_group?: string;
  position: number;
}

export interface ArmWithName extends Arm {
  intervention_name: string;
}
export interface Intervention {
  id: string;
  name: string;
  hasRequestPending?: boolean;
}

export interface InterventionCharacteristicsResult {
  arm_id: string;
  value: string;
}

export interface InterventionCharacteristics {
  id: string;
  name: string;
  results: InterventionCharacteristicsResult[];
}

export interface TemplateOutcome {
  id: string;
  name: string;
}

export type ReviewerName = {
  first_name: string;
  last_name: string;
};

export type Identification = {
  id: number;
  address: string | null;
  authors_name: string | null;
  comments: string | null;
  country: string | null;
  email: string | null;
  institution: string | null;
  setting: string | null;
  sponsorship_source: string | null;
};

export type CustomFieldCategory =
  | 'identification'
  | 'population'
  | 'study_design';

export type CustomField = {
  id: number;
  category: CustomFieldCategory;
  name: string;
  value: string | null;
};

export type CustomFields = Record<CustomFieldCategory, CustomField[]>;

export type NoteCategory =
  | 'identification'
  | 'study_design'
  | 'population'
  | 'interventions'
  | 'outcomes'
  | 'results data';

export type Note = {
  id: number;
  note: string;
  category: string;
  reviewer_id: number;
  review_study_id: number;
};

type Notes = Partial<Record<NoteCategory, Note>>;

export interface DataExtractionContextType {
  primary_reviewer: ReviewerName | null;
  permissions: ExtractionPermissions[];
  userPermissions: UserPermissions;
  doi: string | null;
}

export interface DataExtractionContextProps extends DataExtractionContextType {
  hasFeature: (feature: string) => boolean;
}

export interface DataExtraction {
  primary_reviewer: ReviewerName;
  permissions: ExtractionPermissions[];
  interventions: Intervention[];
  arms: Arm[];
  intervention_characteristics: InterventionCharacteristics[];
  template_outcomes: TemplateOutcome[];
  outcomes: Outcome[];
  custom_fields: CustomFields;
  identification: Identification | null;
  doi: string | null;
  notes: Notes;
  outcome_groups: OutcomeGroup[];
  result_types: ResultType[];
}
export type DataExtractionForm = Omit<
  DataExtraction,
  'primary_reviewer' | 'permissions' | 'doi'
>;

/**
 * Types used by the the DE/QA template pages
 */
export type DataExtractionTemplate = {
  identification: IdentificationData;
  methods: MethodsData;
  population: PopulationData;
  interventions: InterventionsData;
  outcomes: OutcomeData;
  custom_result_types: DataExtractionTemplateResultType[];
  has_extracted_data: boolean;
  number_of_revisitable_studies: number;
  updated_by?: {
    id: string;
    first_name: string;
    last_name: string;
  };
  updated_at?: string;
};

export enum QualityAssessmentTemplateFormat {
  EMPTY = 'empty',
  ROB = 'rob',
}

export type QualityAssessmentTemplate = {
  domains: Domain[];
  outcome_groups: OutcomeGroup[];
  updated_by?: {
    id: string;
    first_name: string;
    last_name: string;
  };
  updated_at?: string;
  number_of_revisitable_studies: number;
};

export type IdentificationData = {
  additionalFields: AdditionalField[];
};
export type MethodsData = {
  additionalFields: AdditionalField[];
};

export type PopulationData = {
  baseline_characteristics: BaselineCharacteristic[];
  additionalFields: AdditionalField[];
};

export type InterventionsData = {
  interventions: InterventionField[];
  characteristics: InterventionField[];
};

export type OutcomeData = {
  outcomes: DataExtractionTemplateOutcome[];
  default_continuous_measure: string;
  default_dichotomous_measure: string;
  data_types: DataType[];
};

/**
 * Fields
 */

export type AdditionalField = {
  id?: number;
  name: string;
};

export type InterventionField = {
  id?: number;
  name: string;
};

export type BaselineCharacteristic = {
  id?: number;
  name: string;
};

export type DataExtractionTemplateTimepoint = {
  id?: string;
  name: string;
  position?: number;
};

export type Timepoint = {
  id: string;
  name: string;
  template_field_id?: string;
  reported_time?: string;
  remove?: () => Promise<{ ok: boolean }>;
  checkTimepointExtractedStatus?: () => Promise<{
    ok?: boolean;
    extracted?: boolean;
  }>;
  updateReportedTime?: (reportedTime: string) => Promise<{ ok: boolean }>;
};

// Outcome from the DataExtraction template
export type DataExtractionTemplateOutcome = {
  name: string;
  timepoints: DataExtractionTemplateTimepoint[];
  outcome_type: OutcomeTypes;
  has_extracted_data: boolean;
};

// Outcome from the DataExtraction on the ReviewStudy
export type Outcome = {
  id: string;
  name: string;
  direction: string | null;
  range: string | null;
  scale: string | null;
  unit_of_measure: string | null;
  outcome_type: OutcomeTypes;
  data_value: string | null;
  outcome_group_id: string | null;
  outcome_results: OutcomeResult[];
  result_values: Record<
    Arm['id'],
    Record<Timepoint['id'], Record<OutcomeResult['id'], ResultValue>>
  >;
  notes: string | null;
  template_field_id: string;
  timepoints: Timepoint[];
  template_timepoints: DataExtractionTemplateTimepoint[];
  reported_name?: string;
};

export type OutcomeResult = {
  id: string;
  result_type_id: string;
};

export type OutcomeResultPair = {
  lowerInput: OutcomeResult;
  upperInput: OutcomeResult;
};

export type Measure = OutcomeResult | OutcomeResultPair; // Measures can be for one OutcomeResult or two in the case of result types belonging to a set

export type ResultValue = {
  id: string;
  outcome_result_id: string;
  value: string;
  arm_id: string;
  timepoint_id: string;
};

export type OutcomeGroup = {
  id?: number;
  name: string;
  prompt_before_deleting: boolean;
};

export type FieldType = 'text' | 'numeric' | 'percentage';

export type ResultType = {
  id: string;
  label: string;
  description: string;
  field_type: FieldType;
  outcome_type: string;
  position: number;
  decimals: number;
  set: Set | null;
};

export type DataExtractionTemplateResultType = {
  id?: number;
  label: string;
  outcome_type: string;
  used?: boolean;
};

export type Set = {
  id: string;
  description: string;
  label: string;
};

export type Domain = {
  id?: number;
  name: string;
  description: string;
  outcome_specific: boolean;
  prompt_before_deleting: boolean;
};

export type DataType = {
  name: string;
  description: string;
  outcome_type: OutcomeTypes;
};

/**
 * Enums
 */

export enum OutcomeTypes {
  CONTINUOUS = 'ContinuousOutcome',
  DICHOTOMOUS = 'DichotomousOutcome',
}

export enum AdditionalFieldCategory {
  METHODS = 'study_design',
  IDENTIFICATION = 'identification',
  POPULATION = 'population',
}
