import { splitAt, update } from 'ramda';
import { TemplateBlock } from '../BaseBlock';
import { generateBlockID } from '../generateBlockID';
import { OptionBlock, OtherOptionBlock } from '../shared/Option';
import { BlockValue } from 'concepts/Extraction/BlocksData';

export type MultipleChoiceBlockOptions = OptionBlock;
export type MultipleChoiceBlockOtherOptions = OtherOptionBlock;

export interface MultipleChoiceBlock extends TemplateBlock {
  type: 'MultipleChoice';
  label: string;
  description: string;
  options: Array<MultipleChoiceBlockOptions>;
  otherOption: MultipleChoiceBlockOtherOptions | null;
}

export const blockIsMultipleChoice = (
  block: TemplateBlock
): block is MultipleChoiceBlock => block.type === 'MultipleChoice';

export const newMultipleChoiceBlock = (
  label: string,
  extraProps?: Partial<MultipleChoiceBlock>
): MultipleChoiceBlock => ({
  type: 'MultipleChoice',
  id: generateBlockID(),
  label,
  description: '',
  options: [],
  otherOption: null,
  ...extraProps,
});

export const updateLabel = (
  multipleChoice: MultipleChoiceBlock,
  label: string
) => ({
  ...multipleChoice,
  label,
});

export const updateDescription = (
  textField: MultipleChoiceBlock,
  description: string
) => ({
  ...textField,
  description,
});

/**
 * Adds a new option to the MultipleChoice block after a specified option ID.
 * If no option ID is specified, add the new option as the first option.
 *
 * @param MultipleChoice - the MultipleChoiceBlock
 * @param option - the new option to add
 * @param afterID - the id of the option to add the new option after. In other
 * words, it is the id of the option that should appear before the new option.
 * @returns updated MultipleChoiceBlock
 */
export const addOption = (
  multipleChoice: MultipleChoiceBlock,
  option: MultipleChoiceBlockOptions,
  afterID: string | null
): MultipleChoiceBlock => {
  if (afterID === null) {
    return {
      ...multipleChoice,
      options: [option, ...multipleChoice.options],
    };
  }

  const afterOptionIndex = multipleChoice.options.findIndex(
    ({ id }) => id === afterID
  );

  if (afterOptionIndex === -1) {
    throw new Error(`Cannot add option, invalid option id: ${afterID}`);
  }

  const [optionsBefore, optionsAfter] = splitAt(
    afterOptionIndex + 1,
    multipleChoice.options
  );

  return {
    ...multipleChoice,
    options: [...optionsBefore, option, ...optionsAfter],
  };
};

export const removeOption = (
  multipleChoice: MultipleChoiceBlock,
  id: string
): MultipleChoiceBlock => {
  const optionIndex = multipleChoice.options.findIndex(
    (option) => option.id === id
  );

  if (optionIndex === -1) {
    throw new Error(`Cannot remove option, invalid option id: ${id}`);
  }

  const optionsBeforeRemovedOption = multipleChoice.options.slice(
    0,
    optionIndex
  );
  const optionsAfterRemovedOption = multipleChoice.options.slice(
    optionIndex + 1
  );

  return {
    ...multipleChoice,
    options: [...optionsBeforeRemovedOption, ...optionsAfterRemovedOption],
  };
};

export const moveOption = (
  multipleChoice: MultipleChoiceBlock,
  id: string,
  toIndex: number
): MultipleChoiceBlock => {
  const movedIndex = multipleChoice.options.findIndex(
    (option) => option.id === id
  );
  if (movedIndex === -1) {
    throw new Error(`Cannot move option, invalid option id: ${id}`);
  }

  const [moved] = multipleChoice.options.splice(movedIndex, 1);

  if (toIndex < 0 || toIndex >= multipleChoice.options.length) {
    throw new Error(`Cannot move option, invalid index: ${toIndex}`);
  }

  multipleChoice.options.splice(toIndex, 0, moved);
  return multipleChoice;
};

export const updateOption = (
  multipleChoice: MultipleChoiceBlock,
  updatedOption: MultipleChoiceBlockOptions
): MultipleChoiceBlock => {
  const optionIndex = multipleChoice.options.findIndex(
    (option) => option.id === updatedOption.id
  );

  if (optionIndex === -1) {
    throw new Error(
      `Cannot update option, invalid option id: ${updatedOption.id}`
    );
  }

  return {
    ...multipleChoice,
    options: update(optionIndex, updatedOption, multipleChoice.options),
  };
};

type MultipleChoiceBlockValue = string | null;

export const isMultipleChoiceBlockValue = (
  value: BlockValue
): value is MultipleChoiceBlockValue =>
  value === null || typeof value === 'string';

export const isOtherOptionSelected = (
  { options }: MultipleChoiceBlock,
  value: string | null
): boolean => {
  if (value === null) return false;
  const valueIsOptionId = options.some(({ id }) => id === value);
  return !valueIsOptionId;
};
