import React from 'react';
import { Box } from 'theme-ui';

import ConsensusSlot from './ConsensusSlot';
import ExtractionSlot from './ExtractionSlot';
import { Actions } from './StudyTypes';
import {
  ExtractionProgressStatus,
  ExtractionSlot as ExtractionSlotType,
  ConsensusSlot as ConsensusSlotType,
} from 'types/graphql';
import { PrimaryLink } from 'system/base/Link';

export interface ProgressProps {
  status: ExtractionProgressStatus;
  consensusSlot: ConsensusSlotType | null;
  extractionSlots: Array<ExtractionSlotType>;
  actions: Actions;
}

const Progress = ({
  status,
  extractionSlots,
  consensusSlot,
  actions,
}: ProgressProps) => {
  function primaryExtractButton(text: string) {
    return (
      <PrimaryLink mb={2} href={actions.extractUrl as string}>
        {text}
      </PrimaryLink>
    );
  }

  function primaryConsensusButton(text: string, edit = false) {
    const url = edit
      ? (actions.editCompareUrl as string)
      : (actions.compareUrl as string);
    return (
      <PrimaryLink mb={2} href={url}>
        {text}
      </PrimaryLink>
    );
  }

  const currentUserHasCompleteExtractionSlot = extractionSlots.some(
    (slot) => slot.currentUser && slot.completed
  );

  const currentUserHasIncompleteExtractionSlot = extractionSlots.some(
    (slot) => slot.currentUser && !slot.completed
  );

  const unassignedSlot = extractionSlots.some((slot) => !slot.assigned);

  const renderExtractionButton = () => {
    if (currentUserHasIncompleteExtractionSlot) {
      return primaryExtractButton('Continue extraction');
    }

    if (currentUserHasCompleteExtractionSlot || !unassignedSlot) {
      return (
        <Box sx={{ fontSize: 3 }} mb={2}>
          Extraction in progress
        </Box>
      );
    }

    if (unassignedSlot) {
      return primaryExtractButton('Begin extraction');
    }
  };

  const singleReviewer = extractionSlots.length == 1;

  const renderExtractionSlots = () => {
    return extractionSlots.map((slot, i) => (
      <ExtractionSlot {...slot} key={i} extractUrl={actions.extractUrl} />
    ));
  };

  const renderConsensusSlot = () => {
    if (
      status != ExtractionProgressStatus.Consensus &&
      status != ExtractionProgressStatus.Complete
    )
      return;

    if (consensusSlot) {
      return (
        <ConsensusSlot
          {...consensusSlot}
          singleReviewer={singleReviewer}
          editCompareUrl={actions.editCompareUrl}
        />
      );
    }
  };

  const renderBeginConsensusButton = () => {
    return primaryConsensusButton(
      singleReviewer ? 'Begin checking' : 'Begin consensus'
    );
  };

  const renderConsensusButton = () => {
    if (currentUserHasIncompleteExtractionSlot) {
      return primaryExtractButton('Continue extraction');
    }

    if (!consensusSlot) {
      return renderBeginConsensusButton();
    }

    if (consensusSlot.currentUser) {
      if (consensusSlot.completed) {
        return null;
      } else {
        if (consensusSlot.started) {
          return primaryConsensusButton(
            singleReviewer ? 'Continue checking' : 'Continue consensus',
            true
          );
        } else {
          return primaryConsensusButton(
            singleReviewer ? 'Begin checking' : 'Begin consensus',
            true
          );
        }
      }
    }

    if (consensusSlot.assigned && !consensusSlot.currentUser) {
      return (
        <Box sx={{ fontSize: 3 }} mb={2}>
          {singleReviewer ? 'Checking' : 'Consensus'} in progress
        </Box>
      );
    }

    return renderBeginConsensusButton();
  };

  const renderButton = () => {
    switch (status) {
      case ExtractionProgressStatus.NotStarted:
      case ExtractionProgressStatus.Extraction:
        return renderExtractionButton();
      case ExtractionProgressStatus.Consensus:
        return renderConsensusButton();
      case ExtractionProgressStatus.Complete:
        return primaryConsensusButton('View final data');
    }
  };

  return (
    <div>
      {renderButton()}
      <ul>
        {renderExtractionSlots()}
        {renderConsensusSlot()}
      </ul>
    </div>
  );
};

export default Progress;
