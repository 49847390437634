import { darken } from '@theme-ui/color';
import { makeThemeButtonsScale } from './utils/make-theme';
import { colors } from './colors';
import space from './space';
import shadows from './shadows';

// (32px height - 3px borders) / 14px text height
const lineHeight = 29 / 14;

const buttons = makeThemeButtonsScale({
  raised: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: space[3],
    paddingRight: space[3],
    borderRadius: 3,
    borderBottomWidth: 3,
    borderBottomStyle: 'solid',
    lineHeight,
    textAlign: 'center',
    whiteSpace: 'nowrap',
    verticalAlign: 'middle',
    opacity: 1.0,

    ':active:not([disabled])': {
      color: 'white',
      boxShadow: shadows.pressed,
    },
    ':disabled': {
      cursor: 'not-allowed',
      opacity: 0.65,
    },
  },
  primary: {
    variant: 'buttons.raised',
    borderBottomColor: darken(colors.primary, 0.2),
  },
  secondary: {
    variant: 'buttons.raised',
    background: colors.secondary,
    borderBottomColor: darken(colors.secondary, 0.2),
  },
  tertiary: {
    variant: 'buttons.raised',
    background: 'white',
    borderTopWidth: 1,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderStyle: 'solid',
    borderColor: darken('white', 0.2),
    color: darken('white', 0.5),
    ':active:not([disabled])': {
      color: darken('white', 0.5),
      boxShadow: shadows.pressed,
    },
  },
  success: {
    variant: 'buttons.raised',
    background: colors.success,
    borderBottomColor: darken(colors.success, 0.2),
  },
  danger: {
    variant: 'buttons.raised',
    background: colors.danger,
    borderBottomColor: darken(colors.danger, 0.2),
  },
  flat: {
    paddingTop: '1px', // to align with raised buttons
    paddingBottom: 0,
    paddingLeft: space[3],
    paddingRight: space[3],
    backgroundColor: 'transparent',
    lineHeight,
  },
  ghost: {
    variant: 'buttons.flat',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: 'white',
    color: 'white',
  },
  naked: {
    variant: 'buttons.flat',
    backgroundColor: 'transparent',
    borderColor: 'transparent',
    color: 'primary',
    lineHeight: 'inherit',
    padding: 0,
  },
  link: {
    cursor: 'pointer',
    textAlign: 'left',
    color: colors.link,
    backgroundColor: 'transparent',
    textDecoration: 'underline',
    padding: 0,

    ':hover': {
      textDecoration: 'none',
    },
    ':disabled': {
      cursor: 'not-allowed',
    },
  },
});

export default buttons;
