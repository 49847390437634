import React from 'react';
import classNames from 'classnames';
import styles from './RemoveFieldButton.module.css';

type ButtonProps = React.ComponentPropsWithoutRef<'button'>;

type RemoveFieldButtonProps = ButtonProps & {
  onClick: Required<ButtonProps>['onClick'];
};

export const RemoveFieldButton = ({
  onClick,
  className,
  'aria-label': ariaLabel = 'delete',
  ...optionalProps
}: RemoveFieldButtonProps) => (
  <button
    className={classNames(className, styles.removeFieldButton)}
    onClick={onClick}
    aria-label={ariaLabel}
    {...optionalProps}
  >
    <i className="far fa-trash-alt" />
  </button>
);
