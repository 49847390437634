/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { FunctionComponent, ComponentProps } from 'react';
import { gql } from '@apollo/client';
import idx from 'idx';
import { recordToQueryString } from 'util/queryString';
import DataWithControls from 'system/layout/DataWithControls';
import {
  OrganizationReviewsSearchQuery,
  OrganizationReviewsSearchQueryVariables,
  OrganizationAuthorsSearchQuery,
  OrganizationAuthorsSearchQueryVariables,
  Scalars,
} from 'types/graphql';
import LoadingSpinner from 'system/elements/Spinner';
import { SectionHeading } from 'system/base/Heading';
import Space from 'system/utility/Space';
import SearchInput from 'system/elements/SearchInput';
import useCollectionQuery from 'hooks/useCollectionQuery';
import DataTable, { Columns } from 'system/data/DataTable';
import EmailAddress from 'components/EmailAddress';
import MembershipStatus from 'components/MembershipStatus';
import FormattedDate from 'components/FormattedDate';
import Icon from 'system/base/Icon';
import { Link } from 'system/base/Link';
import Text from 'system/base/Text';

type DataTableProps<T> = Omit<
  ComponentProps<typeof DataTable>,
  'collection' | 'columns'
> & { data?: T };

const REVIEWS_QUERY = gql`
  query OrganizationReviewsSearch(
    $organizationId: ID!
    $q: String!
    $after: String
  ) {
    organization: node(id: $organizationId) {
      id
      ... on Organization {
        reviews(filter: { search: $q }, first: 10, after: $after) {
          total
          pageInfo {
            hasNextPage
            endCursor
          }
          nodes {
            id
            title
            lastActivityAt
            collaborators {
              total
            }
            assignedAdministrator {
              name
            }
          }
        }
      }
    }
  }
`;

const AUTHORS_QUERY = gql`
  query OrganizationAuthorsSearch(
    $organizationId: ID!
    $q: String!
    $after: String
  ) {
    organization: node(id: $organizationId) {
      id
      ... on Organization {
        authors(filter: { search: $q }, first: 10, after: $after) {
          total
          pageInfo {
            hasNextPage
            endCursor
          }
          nodes {
            id
            name
            primaryEmail
            organizationMembership(organization: $organizationId) {
              status
              memberSince
              organizationReviews {
                total
              }
            }
          }
        }
      }
    }
  }
`;

const ReviewsTable = ({
  data,
  ...props
}: DataTableProps<OrganizationReviewsSearchQuery>) => {
  const collection = idx(data, (_) => _.organization.reviews.nodes) || [];

  /* eslint-disable react/display-name */
  const columns: Columns<typeof collection> = [
    {
      heading: 'Review title',
      primary: true,
      render: ({ id, title }) => (
        <Text textStyle="truncate" title={title}>
          <Link href={`/reviews/${id}/authors`}>{title}</Link>
        </Text>
      ),
    },
    {
      heading: 'Last active',
      width: 170,
      render: ({ lastActivityAt }) => (
        <FormattedDate
          date={lastActivityAt}
          format="relative"
          options={{ addSuffix: true }}
        />
      ),
    },
    {
      heading: 'Collaborators',
      width: 110,
      render: (review) => (
        <Link href={`/reviews/${review.id}/authors`}>
          {review.collaborators.total}
        </Link>
      ),
    },
    {
      heading: 'Assigned admin',
      width: 145,
      render: (review) =>
        review.assignedAdministrator ? (
          <span className="pii">{review.assignedAdministrator.name}</span>
        ) : (
          'No administrator assigned'
        ),
    },
    {
      key: 'Settings',
      heading: '',
      width: 35,
      render: ({ id }) => (
        <a href={`/reviews/${id}/details`}>
          <Icon name="cog" />
        </a>
      ),
    },
  ];
  /* eslint-enable react/display-name */

  return <DataTable collection={collection} columns={columns} {...props} />;
};

const AuthorsTable = ({
  organizationId,
  data,
  ...props
}: {
  organizationId: string;
} & DataTableProps<OrganizationAuthorsSearchQuery>) => {
  const collection = idx(data, (_) => _.organization.authors.nodes) || [];

  /* eslint-disable react/display-name */
  const columns: Columns<typeof collection> = [
    {
      heading: 'Name',
      primary: true,
      render: (author) => (
        <Link
          className="pii"
          href={`/organizations/${organizationId}/authors/${author.id}`}
        >
          {author.name}
        </Link>
      ),
    },
    {
      heading: 'Email address',
      render: ({ primaryEmail }) => <EmailAddress address={primaryEmail} />,
    },
    {
      heading: 'Reviews',
      render: ({ organizationMembership: membership }) =>
        membership.organizationReviews.total,
    },
    {
      heading: 'Membership status',
      render: ({ organizationMembership: membership }) => (
        <MembershipStatus status={membership.status} />
      ),
    },
    {
      heading: 'Member since',
      render: ({ organizationMembership: membership }) =>
        membership.memberSince ? (
          <FormattedDate date={membership.memberSince} />
        ) : (
          '-'
        ),
    },
  ];
  /* eslint-enable react/display-name */

  return <DataTable collection={collection} columns={columns} {...props} />;
};

interface Props {
  organizationId: Scalars['ID'];
  q: string;
}

const countOfTotal = (count?: number | null, total?: number | null) => {
  if (count == null || total == null) return '';
  if (count! < total!) return `${count} of ${total} matches`;
  return `${count} match${count !== 1 ? 'es' : ''}`;
};

const handleSearchSubmit = (q: string) => {
  window.location.href = `search${recordToQueryString({ q })}`;
};

const Overview: FunctionComponent<Props> = ({ organizationId, q }) => {
  const {
    data: reviewsData,
    error: reviewsError,
    loading: reviewsLoading,
    loadMore: reviewsLoadMore,
  } = useCollectionQuery<
    OrganizationReviewsSearchQuery,
    OrganizationReviewsSearchQueryVariables
  >({
    query: REVIEWS_QUERY,
    variables: { organizationId, q },
    path: ['organization', 'reviews'],
  });

  const {
    data: authorsData,
    error: authorsError,
    loading: authorsLoading,
    loadMore: authorsLoadMore,
  } = useCollectionQuery<
    OrganizationAuthorsSearchQuery,
    OrganizationAuthorsSearchQueryVariables
  >({
    query: AUTHORS_QUERY,
    variables: { organizationId, q },
    path: ['organization', 'authors'],
  });

  return (
    <DataWithControls
      filters={
        <SearchInput
          placeholder="Find a person or review"
          onSearch={handleSearchSubmit}
          defaultValue={q}
        />
      }
    >
      {reviewsLoading || authorsLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <Space mb={2}>
            <SectionHeading>
              Reviews (
              {countOfTotal(
                (idx(reviewsData, (_) => _.organization.reviews.nodes) || [])
                  .length,
                idx(reviewsData, (_) => _.organization.reviews.total)
              )}
              )
            </SectionHeading>
          </Space>
          <ReviewsTable
            data={reviewsData}
            error={reviewsError}
            loading={reviewsLoading}
            loadMore={reviewsLoadMore}
            empty={
              <>
                We did not find a review matching or containing{' '}
                <strong>{q}</strong>
              </>
            }
          />
          <Space mt={3} mb={2}>
            <SectionHeading>
              Authors (
              {countOfTotal(
                (idx(authorsData, (_) => _.organization.authors.nodes) || [])
                  .length,
                idx(authorsData, (_) => _.organization.authors.total)
              )}
              )
            </SectionHeading>
          </Space>
          <AuthorsTable
            organizationId={organizationId}
            data={authorsData}
            error={authorsError}
            loading={authorsLoading}
            loadMore={authorsLoadMore}
            empty={
              <>
                We did not find an author matching or containing{' '}
                <strong>{q}</strong>
              </>
            }
          />
        </>
      )}
    </DataWithControls>
  );
};

export default Overview;
