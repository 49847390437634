import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import INVITE_PERSON_TO_ORGANIZATION from '../../invitePersonToOrganization';
import CANCEL_INVITATION from '../../cancelInvitation';
import { ID, Email } from 'util/types';
import {
  InvitePersonToOrganizationInput,
  InvitePersonToOrganizationMutation,
  CancelInvitationInput,
  CancelInvitationMutation,
} from 'types/graphql';

import { PrimaryButton, DangerButton } from 'system/base/Button';
import Space from 'system/utility/Space';
import FormattedDate from 'components/FormattedDate';
import ErrorMessage from 'components/ErrorMessage';

interface Author {
  name: string;
  email: Email;
}

interface Organization {
  id: ID;
  name: string;
}

interface Invitation {
  id: ID;
  email: Email;
  expiresAt: Date;
}

interface Props {
  author: Author;
  organization: Organization;
  invitation?: Invitation;
}

const Collaborator = ({
  author,
  organization,
  invitation: invitationProp,
}: Props) => {
  const [invitation, setInvitation] = useState(invitationProp || null);
  const [busy, setBusy] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const [sendInvitationMutation] = useMutation<
    InvitePersonToOrganizationMutation,
    InvitePersonToOrganizationInput
  >(INVITE_PERSON_TO_ORGANIZATION);
  const [cancelInvitationMutation] = useMutation<
    CancelInvitationMutation,
    CancelInvitationInput
  >(CANCEL_INVITATION);

  const sendInvitation = () => {
    setBusy(true);
    sendInvitationMutation({
      variables: {
        organizationId: organization.id,
        name: author.name,
        email: invitation ? invitation.email : author.email,
      },
    })
      .then(({ data: { invitePersonToOrganization: result } }: any) => {
        if (result.success) {
          setInvitation({
            ...result.invitation,
            expiresAt: new Date(result.invitation.expiresAt),
          });
        } else {
          setErrorMessage(
            result.errors.map((e: any) => `${e.message}`).join(',')
          );
        }
      })
      .catch((err) => {
        setErrorMessage(err.message);
      })
      .finally(() => setBusy(false));
  };

  let actions;
  if (invitation) {
    const cancelInvitation = () => {
      setBusy(true);

      cancelInvitationMutation({
        variables: {
          invitationId: invitation.id,
        },
      })
        .then(({ data: { cancelInvitation: result } }: any) => {
          if (result.success) {
            setInvitation(null);
          } else {
            setErrorMessage(
              result.errors.map((e: any) => `${e.message}`).join(',')
            );
          }
        })
        .catch((err) => {
          setErrorMessage(err.message);
        })
        .finally(() => setBusy(false));
    };

    actions = (
      <>
        <p>
          <strong>
            Pending invitation expires{' '}
            <FormattedDate
              date={invitation.expiresAt}
              format="relative"
              options={{ addSuffix: true }}
            />
          </strong>
        </p>
        <Space mr={2} my={2}>
          <PrimaryButton
            onClick={() => {
              sendInvitation();
            }}
            disabled={busy}
          >
            Resend
          </PrimaryButton>{' '}
          <DangerButton
            onClick={() => {
              cancelInvitation();
            }}
            disabled={busy}
          >
            Cancel
          </DangerButton>
        </Space>
      </>
    );
  } else {
    actions = (
      <PrimaryButton
        onClick={() => {
          sendInvitation();
        }}
        disabled={busy}
      >
        Send Invitation
      </PrimaryButton>
    );
  }

  return (
    <>
      <p className="tcn mb3">
        {author.name} is not a member and cannot create reviews on the{' '}
        {organization.name} subscription.
      </p>

      {actions}
      {errorMessage && (
        <div>
          <ErrorMessage>{errorMessage}</ErrorMessage>
        </div>
      )}
    </>
  );
};

export default Collaborator;
