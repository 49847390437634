import React from 'react';
import { Control, useWatch } from 'react-hook-form';
import { VisuallyHidden } from '@reach/visually-hidden';
import { getUnresolvedDecisionsCount } from './utils';
import { FormValues } from './Compare';

type ConsensusTabBadgeProps = {
  type: string;
  deControl: Control<FormValues>;
  deCompleted: boolean;
  qaControl: Control<FormValues>;
  qaCompleted: boolean;
};

const ConsensusTabBadge = ({
  type,
  deControl,
  deCompleted,
  qaControl,
  qaCompleted,
}: ConsensusTabBadgeProps) => {
  const [deUnresolvedTableCells, deBlockResolutions] = useWatch({
    control: deControl,
    name: ['unresolvedTableCells', 'blockResolutions'],
  });
  const blockResolutions = useWatch({
    control: qaControl,
    name: 'blockResolutions',
  });

  const {
    dataExtraction: dataExtractionUnresolvedCount,
    qualityAssessment: qualityAssessmentUnresolvedCount,
  } = getUnresolvedDecisionsCount({
    dataExtraction: {
      unresolvedTableCells: deUnresolvedTableCells,
      blockResolutions: deBlockResolutions,
    },
    qualityAssessment: {
      blockResolutions: blockResolutions,
    },
  });

  if (
    (type === 'data_extraction' && deCompleted) ||
    (type === 'quality_assessment' && qaCompleted)
  ) {
    return null;
  }

  const getCount = () => {
    if (type === 'data_extraction' && dataExtractionUnresolvedCount > 0) {
      return dataExtractionUnresolvedCount;
    }

    if (type === 'quality_assessment' && qualityAssessmentUnresolvedCount > 0) {
      return qualityAssessmentUnresolvedCount;
    }
  };

  const count = getCount();
  if (count == null) {
    return null;
  }

  const suffix = count === 1 ? 'conflict' : 'conflicts';
  return (
    <span id={`ExtractionTabs-${type}-badge`} aria-hidden>
      &nbsp;
      {`(${count})`}
      <VisuallyHidden> {suffix}</VisuallyHidden>
    </span>
  );
};

export default ConsensusTabBadge;
