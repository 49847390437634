import React from 'react';
import { NakedButton } from 'system/base/Button';

type NakedButtonProps = Parameters<typeof NakedButton>[0];

type AddFieldButtonProps = Partial<NakedButtonProps> & {
  onClick: NonNullable<NakedButtonProps['onClick']>;
};

export const AddFieldButton = ({
  children = 'Add field',
  onClick,
  ...optionalProps
}: AddFieldButtonProps) => (
  <NakedButton onClick={onClick} {...optionalProps}>
    {children}
  </NakedButton>
);
