import React from 'react';
import Icon from 'system/base/Icon';

interface Props {
  children: React.ReactNode;
}

const ErrorMessage = ({ children }: Props) => (
  <span className="tcn red">
    <Icon name="exclamation-triangle" />
    {children}
  </span>
);

export default ErrorMessage;
