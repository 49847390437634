import React, { createContext } from 'react';
import { ComparisonRow } from '../../Blocks/factory';
import { OnResolveTableCell } from '../types';
import styles from './ComparisonTable.module.css';
import { TemplateBlock } from 'concepts/Extraction/Blocks/BaseBlock';
import { BlockValue, BlockRecords } from 'concepts/Extraction/BlocksData';

type ReviewerID = string;

export interface ComparisonTableProps {
  blocks: Array<TemplateBlock>;
  reviewers: Array<{
    id: ReviewerID;
    name: string;
    currentExtraction: { revisionID: string; values: BlockRecords };
  }>;
  readonly?: boolean;
  onResolveValue: (id: string, value: BlockValue) => void;
  onResolveTableCell: OnResolveTableCell;
}

type ComparisonTableContextType = {
  reviewerNames: string[];
};

export const ComparisonTableContext = createContext<ComparisonTableContextType>(
  {
    reviewerNames: [],
  }
);

export default function ComparisonTable({
  blocks,
  onResolveTableCell,
  onResolveValue,
  readonly,
  reviewers,
}: ComparisonTableProps): JSX.Element {
  const contextValues = {
    reviewerNames: reviewers.map(({ name }) => name),
  };

  return (
    <ComparisonTableContext.Provider value={contextValues}>
      <table role="grid" className={styles.table}>
        <thead>
          <tr>
            <th role="columnheader" />
            <th role="columnheader" className={styles.finalDecisionHeader}>
              Final Decision
            </th>
            {reviewers.map((reviewer, index) => {
              return (
                <th
                  role="columnheader"
                  className={styles.reviewerHeader}
                  data-extracted-data-revision-id={
                    reviewer.currentExtraction.revisionID
                  }
                  key={index}
                >
                  {reviewer.name}
                  {reviewers.length > 1 ? (
                    <>
                      {' '}
                      <small>(reviewer {index + 1})</small>
                    </>
                  ) : null}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {blocks.map((block) => (
            <ComparisonRow
              block={block}
              key={block.id}
              onResolveValue={onResolveValue}
              onResolveTableCell={onResolveTableCell}
              readonly={readonly}
              reviewerRecords={reviewers.map(
                (reviewer) => reviewer.currentExtraction.values
              )}
            />
          ))}
        </tbody>
      </table>
    </ComparisonTableContext.Provider>
  );
}
