import React, { Fragment } from 'react';
import styles from './StudyDetails.module.css';
import { StudyModel } from 'containers/Guidelines/Triage/StudyModel';

export interface StudyDetailsProps {
  study: StudyModel;
  showPublisherInfo: boolean;
}

function StudyDetails({ study, showPublisherInfo }: StudyDetailsProps) {
  return (
    <Fragment>
      <div
        data-testid={`study-${study.id}`}
        className={styles.studySummaryDescriptor}
      >
        <span>#{study.covidenceNumber} - </span>
        <span>{study.studyId}</span>
      </div>
      <div className={styles.studySummarySubtleDescriptor}>{study.authors}</div>
      <div data-testid={`study-title`} className={styles.studySummaryTitle}>
        {study.title}
      </div>
      <div className={styles.subtleDescriptor}>{study.journalInfo}</div>
      {showPublisherInfo && (
        <div className={styles.subtleDescriptor}>{study.publisherInfo}</div>
      )}
    </Fragment>
  );
}

export default StudyDetails;
