import React from 'react';

export const TableIcon = (): JSX.Element => (
  <svg
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    width="56"
    height="32"
    fill="none"
    viewBox="0 0 56 32"
  >
    <title>Table</title>
    <rect width="56" height="32" fill="#F19D0D" rx="4" />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M7 15c0-1.105.895-2 2-2h10c1.105 0 2 .895 2 2v10c0 1.105-.895 2-2 2H9c-1.105 0-2-.895-2-2V15zm1.167.333h5.25V20h-5.25v-4.667zm5.25 5.834h-5.25v4.666h5.25v-4.666zm1.166-5.834h5.25V20h-5.25v-4.667zm5.25 5.834h-5.25v4.666h5.25v-4.666z"
      clipRule="evenodd"
    />
  </svg>
);
