import React, { ComponentProps } from 'react';
import { Input } from 'theme-ui';
import styled from '../styled';
import Position from '../utility/Position';
import Icon, { IconStyle, IconName } from '../base/Icon';
import Box from 'system/base/Box';

interface Props extends ComponentProps<typeof Input> {
  iconStyle?: IconStyle;
  iconName: IconName;
}

const ICON_WIDTH = 24;
const ICON_MARGIN = 6;

const CenteredIcon = styled(Icon)({
  transform: 'translateX(-50%) translateY(-50%)',
  pointerEvents: 'none',
});

const iconPosition = `${ICON_MARGIN + ICON_WIDTH / 2}px`;
const iconWidth = `${ICON_MARGIN + ICON_WIDTH}px`;

// eslint-disable-next-line react/display-name
const IconInput = React.forwardRef<HTMLInputElement, Props>(
  ({ className, width, iconStyle, iconName, ...props }: Props, ref) => (
    <Position position="relative">
      <Box display="inline-block" className={className} width={width}>
        <Input pl={iconWidth} {...props} ref={ref} width={width} />
        <Position position="absolute" top="50%" left={iconPosition}>
          <CenteredIcon iconStyle={iconStyle} name={iconName} />
        </Position>
      </Box>
    </Position>
  )
);

export default IconInput;
