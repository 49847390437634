import React, { ReactNode, useRef, useEffect } from 'react';
import { Box } from 'theme-ui';
import { ThemeUIStyleObject } from 'theme-ui';
import { PopoverTrigger } from './PopoverTrigger/PopoverTrigger';
import { PopoverDialog, DialogPosition } from './PopoverDialog/PopoverDialog';
import { useDescribedBy } from 'hooks/useAccessibleName';

type PopoverProps = {
  'aria-label': string;
  isPopoverOpen: boolean;
  onPopoverOpen: () => void;
  onPopoverClose: () => void;
  className?: string;
  sx?: ThemeUIStyleObject;
  trigger: ReactNode;
  dialog: ReactNode;
  dialogPosition?: DialogPosition;
};

export const Popover = ({
  'aria-label': ariaLabel,
  className,
  sx,
  trigger,
  dialog,
  isPopoverOpen,
  onPopoverOpen,
  onPopoverClose,
  dialogPosition = 'topRight',
}: PopoverProps): JSX.Element => {
  const [descriptionID, describedBy] = useDescribedBy();
  const popoverTriggerElement = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const popoverTrigger = popoverTriggerElement.current;
    popoverTrigger?.addEventListener('mouseenter', onPopoverOpen);
    popoverTrigger?.addEventListener('mouseleave', onPopoverClose);

    return () => {
      popoverTrigger?.removeEventListener('mouseenter', onPopoverOpen);
      popoverTrigger?.removeEventListener('mouseleave', onPopoverClose);
    };
  }, [onPopoverOpen, onPopoverClose]);

  return (
    <Box className={className} sx={sx}>
      <Box sx={{ display: 'inline-flex', position: 'relative' }}>
        <PopoverTrigger
          aria-label={ariaLabel}
          ref={popoverTriggerElement}
          {...describedBy}
        >
          {trigger}
        </PopoverTrigger>
        <PopoverDialog
          id={descriptionID}
          isPopoverOpen={isPopoverOpen}
          position={dialogPosition}
        >
          {dialog}
        </PopoverDialog>
      </Box>
    </Box>
  );
};
