import { BaseBlock } from '../BaseBlock';
import { generateBlockID } from '../generateBlockID';

export interface JudgementBlock extends BaseBlock {
  type: 'Judgement';
  label: string;
}

export function blockIsJudgement(block: BaseBlock): block is JudgementBlock {
  return block.type === 'Judgement';
}

export function newJudgementBlock(
  label: string,
  extraProps?: Partial<JudgementBlock>
): JudgementBlock {
  return {
    type: 'Judgement',
    id: generateBlockID(),
    label,
    ...extraProps,
  };
}

export function updateLabel(multipleChoice: JudgementBlock, label: string) {
  return { ...multipleChoice, label };
}
