import { BlockResolutions } from '../types';
import {
  getUnresolvedCellCountForTables,
  UnresolvedTableCellIDs,
} from 'concepts/Extraction/ViewModel/tables';

const isUnresolvedState = (state: string) => {
  return state === 'supersededByExtractor' || state === 'unresolved';
};

const countUnresolvedBlocks = (blocks: BlockResolutions): number => {
  return Object.keys(blocks).reduce((acc, key) => {
    return isUnresolvedState(blocks[key]) ? acc + 1 : acc;
  }, 0);
};

interface ViewModelCountData {
  dataExtraction: {
    unresolvedTableCells: {
      [tableID: string]: UnresolvedTableCellIDs;
    };
    blockResolutions: BlockResolutions;
  };
  qualityAssessment: {
    blockResolutions: BlockResolutions;
  };
}

const getDataExtractionUnresolvedDecisionsCount = ({
  unresolvedTableCells,
  blockResolutions,
}: ViewModelCountData['dataExtraction']): number => {
  const unresolvedCellCountForAllTables = getUnresolvedCellCountForTables(
    unresolvedTableCells
  );

  const tableKeys = new Set(Object.keys(unresolvedTableCells));

  const unresolved = Object.keys(blockResolutions).reduce((acc, key) => {
    if (tableKeys.has(key)) {
      return acc;
    }
    return isUnresolvedState(blockResolutions[key]) ? acc + 1 : acc;
  }, 0);

  return unresolvedCellCountForAllTables + unresolved;
};

export const getUnresolvedDecisionsCount = ({
  dataExtraction,
  qualityAssessment,
}: ViewModelCountData) => {
  const dataExtractionCount = getDataExtractionUnresolvedDecisionsCount(
    dataExtraction
  );

  const qualityAssessmentCount = countUnresolvedBlocks(
    qualityAssessment.blockResolutions
  );

  const totalCount = dataExtractionCount + qualityAssessmentCount;

  return {
    dataExtraction: dataExtractionCount,
    qualityAssessment: qualityAssessmentCount,
    total: totalCount,
  };
};
