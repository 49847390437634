import shouldForwardProp from '@styled-system/should-forward-prop';
import {
  colorStyle,
  ColorStyleProps,
  compose,
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system';
import styled from '../styled';

type StyleProps = ColorStyleProps &
  FlexboxProps &
  LayoutProps &
  SpaceProps &
  TypographyProps;

const styleProps = compose(layout, space, typography, colorStyle, flexbox);

const Textbox = styled('textarea', { shouldForwardProp })<StyleProps>(
  {
    borderStyle: 'solid',
    borderWidth: 1,
    borderRadius: '3px',
    width: '100%',
  },
  styleProps
);

Textbox.defaultProps = {
  colors: 'onWhite',
  p: 1,
  verticalAlign: 'middle',
};

export default Textbox;
