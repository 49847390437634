import React, { createContext, useState, useContext } from 'react';

const ModalContext = createContext<{
  setModal: (component: React.ReactNode | null) => void;
  modal: React.ReactNode | null;
}>({
  setModal: () => {
    return;
  },
  modal: null,
});

export const ModalProvider = ({ children }: { children: React.ReactNode }) => {
  const [modal, setModal] = useState<React.ReactNode | null>(null);

  return (
    <ModalContext.Provider value={{ setModal, modal }}>
      {modal}
      {children}
    </ModalContext.Provider>
  );
};

export const useModalContext = () => {
  const value = useContext(ModalContext);
  if (!value) {
    console.error('useModalContext used outside of a ModalProvider');
  }

  return value;
};
