import React from 'react';

import styles from './Progress.module.css';
import { StatusIndicator } from './StatusIndicator';
import { Actions } from './StudyTypes';
import { ExtractionSlot } from 'types/graphql';

import { Link } from 'system/base/Link';

type SlotProps = ExtractionSlot & {
  extractUrl: Actions['extractUrl'];
};

const Slot = ({
  extractUrl,
  currentUser,
  completed,
  extractor,
  lastActivityAgo,
}: SlotProps) => {
  if (!extractor) {
    return null;
  }

  const renderStatusIndicator = () => {
    if (currentUser) {
      return renderCurrentUserStatusIndicator();
    }

    return renderOtherUserStatusIndicator();
  };

  const renderEditLink = () => {
    if (extractUrl) {
      return <Link href={extractUrl}>Edit</Link>;
    }
  };

  const renderCurrentUserStatusIndicator = () => {
    if (completed) {
      return (
        <>
          <StatusIndicator completed={completed} />
          <span>
            You completed extraction {lastActivityAgo} {renderEditLink()}
          </span>
        </>
      );
    }

    if (lastActivityAgo) {
      return (
        <>
          <StatusIndicator completed={completed} />
          <span>
            Your last edit was {lastActivityAgo} {renderEditLink()}
          </span>
        </>
      );
    }

    return (
      <>
        <StatusIndicator completed={completed} />
        <span>You are extracting</span>
      </>
    );
  };

  const renderOtherUserStatusIndicator = () => {
    if (completed) {
      return (
        <>
          <StatusIndicator completed={completed} />
          <span>
            {extractor.name} completed extraction {lastActivityAgo}
          </span>
        </>
      );
    }

    return (
      <>
        <StatusIndicator completed={completed} />
        <span>
          {extractor.name} is extracting
          {lastActivityAgo && `: Last edit ${lastActivityAgo}`}
        </span>
      </>
    );
  };

  return <li className={styles.activityItem}>{renderStatusIndicator()}</li>;
};

export default Slot;
