import React, { FunctionComponent, HTMLAttributes, ReactNode } from 'react';
import classNames from 'classnames';
import { SpacingScale } from '../spacingScale';
import styles from './Inline.module.scss';

export interface InlineProps extends HTMLAttributes<any> {
  spacing?: SpacingScale;
  rowSpacing?: SpacingScale;
  children: ReactNode | ReactNode[];
  component?: keyof HTMLElementTagNameMap | FunctionComponent<any>;
  className?: string;
  shouldWrap?: boolean;
  alignY?: 'bottom' | 'center' | 'top';
  alignX?: 'left' | 'center' | 'right';
}

export const Inline = ({
  children,
  spacing = 0,
  rowSpacing,
  component = 'div',
  className,
  shouldWrap = true,
  alignY = 'center',
  alignX = 'center',
  ...rest
}: InlineProps) =>
  React.createElement(
    component,
    {
      className: classNames(
        // Apply these classes
        styles.wrapper,
        styles[`gap-${spacing}`],
        // Apply these classes if value is truthy
        {
          [styles.wrap]: shouldWrap,
          [styles[`alignY-${alignY}`]]: alignY,
          [styles[`alignX-${alignX}`]]: alignX,
          [styles[`rowGap-${rowSpacing}`]]: typeof rowSpacing === 'number',
        },
        // Apply any passed in classes
        className
      ),
      ...rest,
    },
    children
  );
