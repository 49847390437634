import React from 'react';
import { Table2Block } from '../../types/block';
import { EditorTextbox } from 'concepts/Extraction/FormBuilder/Widgets/EditorTextbox';
import { TemplateBlock } from 'concepts/Extraction/Blocks/BaseBlock';

export interface TableEditorProps {
  block: Table2Block;
  placeholder?: string;
  onChange: (updatedTableBlock: TemplateBlock) => void;
  onComplete: () => void;
  onRemove: () => void;
}

export const TableEditor = ({
  block,
  onChange,
  onRemove,
  onComplete,
  placeholder,
}: TableEditorProps) => {
  const handleChangeTableLabel: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    onChange({
      ...block,
      label: event.target.value,
    });
  };

  const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (
    event
  ) => {
    switch (event.key) {
      case 'Backspace':
        if (block.label == '') {
          event.preventDefault();
          onRemove();
        }
        break;
      case 'Enter':
        event.preventDefault();
        onComplete();
    }
  };

  return (
    <div>
      <EditorTextbox
        value={block.label}
        placeholder={placeholder || 'Table'}
        onChange={handleChangeTableLabel}
        onKeyDown={handleKeyDown}
      />
    </div>
  );
};
