import { tokenizeRoute, CITATION_IMPORT_SOURCES_UPDATE } from '../routes';
import { getCsrfToken } from 'util/authenticityToken';
import { CitationImportSource, CitationImportSourcePayloadData } from 'types';

interface UpdateCitationImportSourceResponse {
  citationImportId: string;
  citationImportSources: CitationImportSource[];
  importSourcesPlaceholder: string;
}

/**
 * Updates citation import sources for a given review / citation import
 */
export const updateCitationImportSources = async (
  citationImportId: string,
  citationSources: CitationImportSourcePayloadData[]
): Promise<UpdateCitationImportSourceResponse> => {
  const url = tokenizeRoute(CITATION_IMPORT_SOURCES_UPDATE, {
    citation_import_id: citationImportId,
  });

  return fetch(url.toString(), {
    method: 'PUT',
    body: JSON.stringify({
      citation_import_id: citationImportId,
      citation_import_sources: citationSources,
    }),
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': getCsrfToken(),
    },
  }).then((res) => res.json());
};
