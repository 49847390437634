import React from 'react';
import { Badge } from 'components/core';
import { Plan } from 'components/shared/PrimaryNavigation/PrimaryNavigation';

interface Props {
  plan: Plan;
}

const PlanBadge = ({ plan }: Props) => {
  if (plan.expired) {
    if (plan.name === 'Trial' || plan.name === 'Short Trial') {
      return (
        <Badge type="danger" size="md">
          Trial expired
        </Badge>
      );
    }

    return (
      <Badge type="danger" size="md">
        Plan expired
      </Badge>
    );
  }

  switch (plan.name) {
    case 'Trial':
    case 'Short Trial':
      return (
        <Badge type="warning" size="md">
          Free trial
        </Badge>
      );
    case 'Single':
      return <Badge size="md">Single plan</Badge>;
    case 'Package':
    case 'Package Plus':
    case 'Small Package':
    case 'Medium Package':
    case 'Large Package':
      return <Badge size="md">Package plan</Badge>;
    case 'Unlimited':
    case 'Unlimited Institutional License':
      return <Badge size="md">Unlimited plan</Badge>;
    case 'Cochrane':
      return <Badge size="md">Cochrane plan</Badge>;
    case 'Internal':
      return <Badge size="md">Internal plan</Badge>;
    default:
      return <Badge size="md">Custom plan</Badge>;
  }
};

export default PlanBadge;
