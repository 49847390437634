import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import styles from '../DefaultTemplate.module.scss';
import { QualityAssessmentTemplateState } from '../../QualityAssessmentTemplate';
import {
  validate,
  NameErrorMessages,
  NameError,
  normalize,
} from '../../../components/editableFieldValidation';
import {
  AccordionContent,
  AccordionGroup,
  AccordionHeader,
  Button,
  FormContainer,
  FormControl,
  FormLabel,
  Icon,
  IconList,
  Input,
  Checkbox,
  TextArea,
  Text,
} from 'components/core';
import { useAccordionController } from 'components/core';

const MAX_DOMAIN_NAME_LENGTH = 1000;

interface Props {
  name: string;
  onDelete: () => void;
}

const cochraneToRevManWeb = [
  // Any update to this list must also be reflected in Study::AssessmentDomain::COCHRANE_TO_REVMAN_WEB
  // See app/models/study/assessment_domain.rb
  // Keep these lowercase with no leading or trailing whitespace
  ['sequence generation', 'random sequence generation (selection bias)'],
  ['allocation concealment', 'allocation concealment (selection bias)'],
  [
    'blinding of participants and personnel',
    'blinding of participants and personnel (performance bias)',
  ],
  [
    'blinding of outcome assessors',
    'blinding of outcome assessment (detection bias)',
  ],
  ['incomplete outcome data', 'incomplete outcome data (attrition bias)'],
  ['selective outcome reporting', 'selective reporting (reporting bias)'],
  ['other sources of bias', 'other bias'],
];

export function cochraneEquivalent(newDomainName: string): string | null {
  const domainName = normalize(newDomainName);
  const pair = cochraneToRevManWeb.find((equivalentPair) => {
    return equivalentPair.includes(domainName);
  });
  if (pair)
    // find the name that's not equal to the original name
    return pair.filter((equivalentName) => equivalentName != domainName)[0];
  return null;
}

function showMessage(show: boolean) {
  if (show) {
    return (
      <Text variant="weak">
        Option cannot be changed as data has already been extracted
      </Text>
    );
  }
  return null;
}

const DomainForm = ({ name, onDelete }: Props) => {
  const { watch, register, getValues, trigger } = useFormContext();
  const hasExtractedData: boolean = getValues(`${name}.prompt_before_deleting`);
  const domainName = getValues(`${name}.name`);
  const domainNameIsBlank = domainName == undefined || domainName.trim() == '';

  const accordionEventHandler = useAccordionController();
  const lockAccordionIfFormInvalid = async () => {
    const domain = getValues(name);
    if (!domain) {
      // New domain has been deleted
      accordionEventHandler.unlock();
      return;
    }
    const valid = await trigger('domains');
    valid ? accordionEventHandler.unlock() : accordionEventHandler.lock();
  };

  return (
    <div className={styles.DomainForm}>
      <AccordionGroup
        className={styles.AccordionControl}
        initiallyOpen={domainNameIsBlank}
      >
        <AccordionHeader>{watch(`${name}.name`)}</AccordionHeader>
        <AccordionContent>
          <FormContainer>
            <Controller
              name={`${name}.name` as const}
              rules={{
                required: NameError.REQUIRED,
                onBlur: lockAccordionIfFormInvalid,
                validate: (value, formState) => {
                  const domains = formState.domains as QualityAssessmentTemplateState['domains'];
                  const domainNames = domains.map((o) => {
                    return { name: o.name };
                  });
                  return validate(
                    value,
                    domainNames,
                    [],
                    cochraneEquivalent(value),
                    MAX_DOMAIN_NAME_LENGTH,
                    NameErrorMessages
                  );
                },
              }}
              render={({ field, fieldState }) => {
                return (
                  <FormControl error={fieldState.error?.message}>
                    <FormLabel>Name</FormLabel>
                    <Input {...{ ...field, autoFocus: domainNameIsBlank }} />
                  </FormControl>
                );
              }}
            />
            <FormControl>
              <FormLabel>Description</FormLabel>
              <TextArea {...register(`${name}.description`)} />
            </FormControl>
            <FormControl>
              <Checkbox
                {...register(`${name}.outcome_specific`)}
                disabled={hasExtractedData}
                size={'sm'}
              >
                Can apply to individual outcome groups?
              </Checkbox>
              {showMessage(hasExtractedData)}
            </FormControl>
          </FormContainer>
        </AccordionContent>
      </AccordionGroup>
      <Button
        variant="ghost"
        iconOnly
        className={styles.SectionButton}
        onClick={() => {
          onDelete();
          lockAccordionIfFormInvalid();
        }}
        data-testid={`${name}.delete`}
      >
        <Icon icon={IconList.light.faTrashCan}>Delete</Icon>
      </Button>
    </div>
  );
};

export default DomainForm;
