import React from 'react';
import { gql } from '@apollo/client';
import idx from 'idx';
import StudyIdentifier from './StudyIdentifier';
import { ID } from 'util/types';
import DataTable, { Columns } from 'system/data/DataTable';
import useCollectionQuery from 'hooks/useCollectionQuery';
import {
  AdminReviewStudiesQuery,
  AdminReviewStudiesQueryVariables,
} from 'types/graphql';
import DataWithControls from 'system/layout/DataWithControls';
import FilterDropdown from 'system/data/FilterDropdown';
import { CATEGORY_NAMES } from 'schema/enums';
import SearchInput from 'system/elements/SearchInput';

const GET_REVIEWS = gql`
  query AdminReviewStudies(
    $reviewId: ID!
    $after: String
    $filter: StudiesFilter
  ) {
    review: node(id: $reviewId) {
      ... on Review {
        studies(first: 15, after: $after, filter: $filter)
          @connection(key: "studies", filter: ["filter"]) {
          ...ReviewStudies
        }
      }
    }
  }

  fragment ReviewStudies on StudyConnection {
    pageInfo {
      hasNextPage
      endCursor
    }
    nodes {
      id
      covidenceNumber
      studyId
      title
    }
  }
`;

interface Props {
  reviewId: ID;
}

const ReviewStudies = ({ reviewId }: Props) => {
  const {
    data,
    loadMore,
    error,
    loading,
    variables,
    setVariable,
  } = useCollectionQuery<
    AdminReviewStudiesQuery,
    AdminReviewStudiesQueryVariables
  >({
    query: GET_REVIEWS,
    variables: { reviewId },
    path: ['review', 'studies'],
  });

  const collection = idx(data, (_) => _.review.studies.nodes) || [];

  /* eslint-disable react/display-name */
  const columns: Columns<typeof collection> = [
    {
      heading: 'Cov#',
      primary: true,
      render: ({ covidenceNumber }) => covidenceNumber,
      width: 50,
    },
    {
      heading: 'Study ID',
      primary: true,
      render: ({ id, studyId }) => (
        <StudyIdentifier studyId={id} identifier={studyId} />
      ),
      width: 200,
    },
    {
      heading: 'Title',
      render: ({ title }) => title,
    },
  ];

  const categoryOptions = [
    { label: 'All stages', value: undefined as string | undefined },
  ].concat(
    Object.entries(CATEGORY_NAMES).map(([value, label]) => ({ label, value }))
  );

  return (
    <DataWithControls
      filters={
        <>
          <SearchInput
            onSearch={(value) =>
              setVariable(
                ['filter', 'covidenceNumber'],
                value ? Number(value) : null
              )
            }
            placeholder="Covidence #"
          />
          <SearchInput
            onSearch={(value) => setVariable(['filter', 'author'], value)}
            placeholder="Author"
          />
          <SearchInput
            onSearch={(value) => setVariable(['filter', 'title'], value)}
            placeholder="Title"
          />
          <FilterDropdown
            variables={variables}
            setVariable={setVariable}
            variableKey={['filter', 'category']}
            options={categoryOptions}
          />
        </>
      }
    >
      <DataTable
        collection={collection}
        columns={columns}
        loadMore={loadMore}
        error={error}
        loading={loading}
      />
    </DataWithControls>
  );
};

export default ReviewStudies;
