import { FileInfo, Reference, Requests } from './index';
import { sendFullTextEvent, UPLOAD_METHODS } from 'util/pendoTrack';

const transform = (fileInfo: any) => {
  const {
    upload_file_name: uploadFileName,
    uploaded_by: uploadedBy,
    is_primary: isPrimary,
    created_at: createdAt,
  } = fileInfo;

  delete fileInfo.upload_file_name;
  delete fileInfo.uploaded_by;
  delete fileInfo.is_primary;
  delete fileInfo.created_at;

  return { ...fileInfo, uploadFileName, uploadedBy, isPrimary, createdAt };
};
export default class StateManager {
  reference: Reference;
  files: FileInfo[];
  isLoadingFiles: boolean;
  uploadingFilesCount: number;
  onStateChange: () => void;

  constructor(reference: Reference, onStateChange: () => void) {
    this.reference = reference;
    this.files = [];
    this.isLoadingFiles = false;
    this.uploadingFilesCount = 0;
    this.onStateChange = onStateChange;
  }

  loadFiles(): void {
    this.isLoadingFiles = true;

    Requests.getFiles(this.reference)
      .then((response) => response.json())
      .then((files: FileInfo[]) => {
        files.forEach((file) => {
          file.type = 's3_file';
          file.uploadComplete = true;
        });

        // add an entry for full-text link
        if (this.reference.document_url) {
          files.push({
            id: this.reference.document_url,
            url: this.reference.document_url,
            type: 'link',
            uploadComplete: true,
          });
        }

        this.files = files.map(transform);
        this.isLoadingFiles = false;
        this.onStateChange();
      });

    this.onStateChange();
  }

  isPrimaryFile(fileId: string): boolean {
    return this.files.find((file) => file.id === fileId)?.isPrimary || false;
  }

  deleteFile(fileId: string, deleteFromBackend: boolean): void {
    if (deleteFromBackend) {
      Requests.deleteFiles(fileId).then(() => {
        // front-end can't easily figure out which file becomes the primary
        // when current primary file is deleted, so reload the list from backend
        if (this.isPrimaryFile(fileId)) {
          this.isLoadingFiles = true;
          this.loadFiles();
        } else {
          this.files = this.files.filter((file) => file.id !== fileId);
        }

        this.onStateChange();
      });
    } else {
      this.files = this.files.filter((file) => file.id !== fileId);
      this.onStateChange();
    }
  }

  unsetDocumentUrl(): void {
    Requests.unsetDocumentUrl(this.reference).then((response) => {
      if (response.ok) {
        this.reference.document_url = '';
        this.files = this.files.filter((file) => file.type !== 'link');

        this.onStateChange();
      }
    });
  }

  makeFilePrimary(fileId: string): void {
    Requests.makeFilePrimary(this.reference, fileId).then((response) => {
      if (response.ok) {
        this.files = this.files.map((file) => {
          const updatedFile = { ...file };
          if (file.isPrimary) {
            updatedFile.isPrimary = false;
          } else if (file.id === fileId) {
            updatedFile.isPrimary = true;
          }

          return updatedFile;
        });

        this.onStateChange();
      }
    });
  }

  uploadFile(file: File, reviewer: string): void {
    this.files = [
      {
        id: file.name,
        file: file,
        type: 's3_file',
        uploadFileName: file.name,
        uploadedBy: reviewer,
        createdAt: new Date(),
        uploadComplete: false,
      },
      ...this.files,
    ];
    this.uploadingFilesCount += 1;

    this.onStateChange();
  }

  updateFileState(file: File, updatedState: any): void {
    const updatedFiles = [...this.files];
    const index = updatedFiles.findIndex((entry) => entry.file === file);
    if (index !== -1) {
      updatedFiles[index] = {
        ...updatedFiles[index],
        ...updatedState,
      };

      this.files = updatedFiles;
    }
  }

  trackFileUploadComplete(): void {
    sendFullTextEvent({ uploadMethod: UPLOAD_METHODS.SINGLE_STUDY_MODAL });
  }

  onFileUploadComplete(fileId: string, file: File): void {
    this.trackFileUploadComplete();

    const noOtherFileUploaded = !this.files.some(
      (entry) =>
        entry.uploadComplete && entry.type === 's3_file' && entry.file !== file
    );

    this.updateFileState(file, {
      id: fileId,
      isPrimary: noOtherFileUploaded,
      uploadComplete: true,
    });

    this.uploadingFilesCount -= 1;
    this.onStateChange();
  }

  onFileUploadError(file: File): void {
    this.updateFileState(file, { uploadError: 'Upload failed' });
    this.uploadingFilesCount -= 1;
    this.onStateChange();
  }

  onDocumentUrlSaved(url: string): void {
    this.reference.document_url = url;

    const updatedFiles = [...this.files];
    const index = updatedFiles.findIndex((entry) => entry.type === 'link');
    if (index === -1) {
      updatedFiles.push({
        id: url,
        url: url,
        type: 'link',
        uploadComplete: true,
      });
    } else {
      updatedFiles[index] = { ...updatedFiles[index], id: url, url };
    }

    this.files = updatedFiles;
    this.onStateChange();
  }
}
