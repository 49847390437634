import React from 'react';

type Props = React.DetailedHTMLProps<
  React.LabelHTMLAttributes<HTMLLabelElement>,
  HTMLLabelElement
>;

const FormLabel = ({ children, ...props }: Props) => {
  return <label {...props}>{children}</label>;
};

export default FormLabel;
