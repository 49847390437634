import React from 'react';
import { Factory } from '../Blocks/factory';
import styles from './BuildPreview.module.css';
import { TemplateViewModel } from 'concepts/Extraction/ViewModel/TemplateViewModel';

export interface BuildPreviewProps {
  viewModel: TemplateViewModel;
}
export default function BuildPreview({
  viewModel,
}: BuildPreviewProps): JSX.Element {
  return (
    <article>
      {viewModel.blocks.map((block) => {
        const Preview = Factory(block).Preview;

        return (
          <div
            key={block.id}
            className={styles.item}
            data-preview-block-id={block.id}
          >
            <Preview block={block} />
          </div>
        );
      })}
    </article>
  );
}
