import React, { FunctionComponent, useEffect, useRef } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import idx from 'idx';
import {
  ReviewRevmanWebCategoryCountsQuery,
  ReviewRevmanWebCategoryCountsQueryVariables,
  InitiateRevmanWebSyncMutation,
  InitiateRevmanWebSyncMutationVariables,
  SyncRunState,
} from 'types/graphql';
import { ContentHeading } from 'system/base/Heading';
import { ID, Maybe } from 'util/types';
import Text from 'system/base/Text';
import Notice from 'system/elements/Notice';
import Panel from 'system/elements/Panel';
import LoadingSpinner from 'system/elements/Spinner';
import Box from 'system/base/Box';
import { PrimaryButton } from 'system/base/Button';
import { CochraneLink } from 'system/base/Link';
import { UnorderedList, ListItem } from 'system/base/List';
import FormattedDate from 'components/FormattedDate';
import { Link } from 'system/base/Link';

const InlineSpinner = () => (
  <LoadingSpinner
    display="inline-block"
    verticalAlign="middle"
    marginRight={1}
  />
);

const QUERY = gql`
  query ReviewRevmanWebCategoryCounts($reviewId: ID!) {
    cochrane {
      isConnected
    }
    review: node(id: $reviewId) {
      ... on Review {
        id
        cochraneReviewLink {
          id
          currentSyncRun {
            state
          }
          previousSyncRun {
            updatedAt
            state
            syncErrors {
              total
            }
          }
        }
        categoryCounts {
          included
          excluded
        }
        studiesAwaitingClassification: studies(
          filter: { awaitingClassification: true }
        ) {
          total
        }
        studiesOngoing: studies(filter: { ongoingStudy: true }) {
          total
        }
      }
    }
  }
`;

const INITIATE_MUTATION = gql`
  mutation initiateRevmanWebSync($cochraneReviewLinkId: ID!) {
    initiateRevmanWebSync(
      input: { cochraneReviewLinkId: $cochraneReviewLinkId }
    ) {
      success
    }
  }
`;

const reviewHref = (reviewId: ID, path = '') => `/reviews/${reviewId}/${path}`;

interface Props {
  reviewId: ID;
}

const Results: FunctionComponent<{
  data?: ReviewRevmanWebCategoryCountsQuery;
}> = ({ data }) => {
  const counts = idx(data, (_) => _.review.categoryCounts);
  const studiesOngoing = (
    idx(data, (_) => _.review.studiesOngoing) || { total: 0 }
  ).total;
  const studiesAwaitingClassification = (
    idx(data, (_) => _.review.studiesAwaitingClassification) || { total: 0 }
  ).total;

  if (!counts && !studiesOngoing && !studiesAwaitingClassification) {
    return (
      <p>
        There are no studies to sync to RevMan Web. Come back and sync to RevMan
        Web when you’ve finished full text review.
      </p>
    );
  }

  return (
    <>
      <ContentHeading>Sync studies to RevMan Web</ContentHeading>
      <Box my={3}>
        <UnorderedList variant="unbulleted">
          <ListItem>
            <strong>
              {counts ? Number(counts.included) - studiesOngoing : 0}
            </strong>{' '}
            included
          </ListItem>
          <ListItem>
            <strong>{studiesOngoing}</strong> ongoing
          </ListItem>
          <ListItem>
            <strong>{studiesAwaitingClassification}</strong> awaiting
            classification
          </ListItem>
          <ListItem>
            <strong>{counts ? counts.excluded : 0}</strong> excluded (including
            reasons)
          </ListItem>
        </UnorderedList>
      </Box>
      <Box my={3}>Extracted data will not be synced to RevMan Web</Box>
    </>
  );
};

const UnlinkedAccount = () => (
  <>
    <p>
      You must be signed in with your Cochrane account to sync to RevMan Web.
    </p>
    <Box marginTop={3}>
      <CochraneLink
        href={`/sign_in/cochrane?redirect_to=${encodeURIComponent(
          window.location.pathname
        )}`}
        iconName="diamond"
      >
        Sign in with Cochrane
      </CochraneLink>
    </Box>
  </>
);

const InitiateSync: FunctionComponent<{
  cochraneReviewLinkId: ID;

  processing?: boolean;
}> = ({ cochraneReviewLinkId, processing }) => {
  const [initiateSync, { data, error, loading }] = useMutation<
    InitiateRevmanWebSyncMutation,
    InitiateRevmanWebSyncMutationVariables
  >(INITIATE_MUTATION);

  if (error) {
    return <Notice type="error">{error.message}</Notice>;
  }

  if (loading) {
    return (
      <Text color="secondaryDarker">
        <InlineSpinner /> Initiating sync...
      </Text>
    );
  }

  if (!data && !processing) {
    return (
      <PrimaryButton
        onClick={() => initiateSync({ variables: { cochraneReviewLinkId } })}
      >
        Sync with RevMan Web
      </PrimaryButton>
    );
  }

  if (
    !processing &&
    idx(data, (_) => _.initiateRevmanWebSync.success) === false
  ) {
    return <Notice type="warning">Could not initiate RevMan Web sync</Notice>;
  }

  return (
    <Text color="secondaryDarker">
      <InlineSpinner /> Syncing references to RevMan Web.
    </Text>
  );
};

function handleSyncTransition(
  reviewId: ID,
  currentState?: Maybe<SyncRunState>,
  nextState?: Maybe<SyncRunState>
) {
  if (
    currentState !== SyncRunState.Failed &&
    currentState !== SyncRunState.Finished &&
    (nextState === SyncRunState.Failed || nextState === SyncRunState.Finished)
  ) {
    window.location.href = reviewHref(reviewId, `revman_web_sync/show`);
  }
}

const SyncRevmanWeb: FunctionComponent<Props> = ({ reviewId }: Props) => {
  const { data, error, loading } = useQuery<
    ReviewRevmanWebCategoryCountsQuery,
    ReviewRevmanWebCategoryCountsQueryVariables
  >(QUERY, {
    variables: {
      reviewId,
    },
    pollInterval: 2500,
  });

  // if the latest sync transitions from any "non-final" state to a final state
  // while this container is mounted, we'll move the user to the results page
  const syncStateRef = useRef(
    idx(data, (_) => _.review.cochraneReviewLink.currentSyncRun.state)
  );
  useEffect(() => {
    const newState = idx(
      data,
      (_) => _.review.cochraneReviewLink.currentSyncRun.state
    );
    handleSyncTransition(reviewId, syncStateRef.current, newState);
    syncStateRef.current = newState;
  }, [reviewId, data]);

  if (loading) {
    return (
      <Panel primary>
        <LoadingSpinner />
      </Panel>
    );
  }

  if (error) {
    return (
      <Panel primary>
        <Notice type="warning">{error.message}</Notice>
      </Panel>
    );
  }

  if (idx(data, (_) => _.cochrane.isConnected) === false) {
    return (
      <Panel primary>
        <UnlinkedAccount />
      </Panel>
    );
  }

  const total =
    (idx(data, (_) => _.review.categoryCounts.included) || 0) +
    (idx(data, (_) => _.review.studiesOngoing) || { total: 0 }).total +
    (idx(data, (_) => _.review.studiesAwaitingClassification) || { total: 0 })
      .total;

  if (total === 0) {
    return (
      <Panel primary>
        There are no studies to sync to RevMan Web. Come back and sync to Revman
        Web when you’ve finished full text review.
      </Panel>
    );
  }

  const state = idx(
    data,
    (_) => _.review.cochraneReviewLink.currentSyncRun.state
  );
  const previousSyncUpdatedAt = idx(
    data,
    (_) => _.review.cochraneReviewLink.previousSyncRun.updatedAt
  );

  return (
    <Panel primary>
      <Results data={data} />
      <InitiateSync
        cochraneReviewLinkId={
          idx(data, (_) => _.review.cochraneReviewLink.id) || ''
        }
        processing={
          state === SyncRunState.Syncing || state === SyncRunState.New
        }
      />
      {previousSyncUpdatedAt && (
        <Box mt={3}>
          <Link href={reviewHref(reviewId, `revman_web_sync/show`)}>
            The last sync was completed on{' '}
            <FormattedDate date={previousSyncUpdatedAt} />
          </Link>
        </Box>
      )}
    </Panel>
  );
};

export default SyncRevmanWeb;
