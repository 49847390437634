import React, { ReactNode } from 'react';
import ModalHeaderContainer from './components/ModalHeaderContainer';
import ModalHeaderHeading from './components/ModalHeaderHeading';

interface ModalHeaderProps {
  children: ReactNode;
  className?: string;
}

const ModalHeader = ({ children, className }: ModalHeaderProps) => {
  return (
    <ModalHeader.Container className={className}>
      <ModalHeader.Heading>{children}</ModalHeader.Heading>
    </ModalHeader.Container>
  );
};

ModalHeader.Container = ModalHeaderContainer;
ModalHeader.Heading = ModalHeaderHeading;
export default ModalHeader;
