import React from 'react';
import { BlockRenderer } from './BlockRenderer';

export const UnknownBlockRenderer: BlockRenderer = {
  Editor() {
    return <></>;
  },
  Control() {
    return <></>;
  },
  Preview() {
    return <></>;
  },
  ComparisonRow() {
    return <></>;
  },
  EditorSettings() {
    return <></>;
  },
  keepResponse() {
    return true;
  },
  displayOnly: true,
};
