import React from 'react';
import styles from '../ExpiredPlanSelectionModal.module.scss';
import { PlanOptionSelectSection } from './PlanOptionSelectSection';
import { PlanOptionBenefitsSection } from './PlanOptionBenefitsSection';

interface PlanOptionProps {
  plan: any;
}

export const PlanOption = ({ plan }: PlanOptionProps) => {
  return (
    <div className={styles.PlanOption_wrapper}>
      <PlanOptionSelectSection plan={plan} />
      <PlanOptionBenefitsSection plan={plan} />
    </div>
  );
};
