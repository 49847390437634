import React, { InputHTMLAttributes } from 'react';
import { FormControl } from 'components/core';

interface Props
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'size' | 'type'> {
  error?: string;
  size?: 'sm' | 'md' | 'lg';
}

const RadioGroup = ({
  children,
  className,
  error,
  defaultValue = '',
  ...props
}: Props) => {
  const [selectedValue, setSelectedValue] = React.useState(defaultValue);

  const childrenWithProps = React.Children.map(children, (child) => {
    if (!React.isValidElement(child)) return child;

    return React.cloneElement<any>(child, {
      onSelected: (value: string) => setSelectedValue(value),
      selectedValue,
      ...props,
    });
  });

  return (
    <FormControl role="radiogroup" className={className} error={error}>
      {childrenWithProps}
    </FormControl>
  );
};

export default RadioGroup;
