import React, { useState, useEffect, useCallback } from 'react';
import styles from 'containers/Guidelines/Triage/Triage.module.css';
import { TertiaryButton } from 'system/base/Button';
import { TopicModel } from 'containers/Guidelines/Triage/TopicModel';
import {
  StudyModel,
  StudyStatusModel,
} from 'containers/Guidelines/Triage/StudyModel';
import { GuidelineStudy } from 'concepts/Guidelines/GuidelineStudy/GuidelineStudy';
import { TopicAssignment } from 'concepts/Guidelines/Triage/TopicAssignment/TopicAssignment';

export interface StudyForTopicAssignmentProps {
  study: StudyModel;
  topics: TopicModel[];
  statuses: StudyStatusModel;
  onStudyChange: (studyId: number, hasChanged: boolean) => void;
}

export function StudyForTopicAssignment({
  topics,
  study,
  statuses,
  onStudyChange,
}: StudyForTopicAssignmentProps): JSX.Element {
  const [currentStatus, setCurrentStatus] = useState(statuses.relevant);
  const isIrrelevant = () => currentStatus === statuses.irrelevant;
  const isParked = () => currentStatus === statuses.parked;

  const initialTopicsChecked = Object.fromEntries(
    topics.map((topic) => [topic.id, false])
  );

  const [topicsChecked, setTopicsChecked] = useState(initialTopicsChecked);

  /* eslint-disable */
  // Whenever the currentStatus change it triggers the notifyWhenStudyChange event
  // it refreshes the parent object that reassigns the currentStatus and it loops over and over.
  // To prevent this loop we are using this useCallback that memoises functions even after the component was refreshed.
  // There is a lint warning here, we are missing the onStudyChange to the dependency list, but by adding it we have another loop
  // basically for the same reason. In the parent component (AssignToTopics) it already wraps the event in another useCallback
  // that should memoise again, but it doesn't work.
  //
  // This events between parent and inner components here are not straightforward as it should but for now I cant
  // refactor it throughly.
  const notifyWhenStudyChange = useCallback(() => {
    const countTopicsChecked = Object.values(topicsChecked).filter(
      (value) => value === true
    ).length;

    if (currentStatus == 0) {
      onStudyChange(study.id, countTopicsChecked > 0);
    } else {
      onStudyChange(study.id, true);
    }
  }, [study.id, topicsChecked, currentStatus]);
  /* eslint-enable */

  // Notify of status change AFTER the currentStatus state change has been applied.
  useEffect(notifyWhenStudyChange, [currentStatus, notifyWhenStudyChange]);

  const toggleCurrentStatus = (status: number) => {
    return (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();

      if (currentStatus === status) {
        setCurrentStatus(statuses.relevant);
      } else {
        setCurrentStatus(status);
      }
    };
  };

  return (
    <div
      data-testid={`assign-study-${study.id}`}
      className={styles.assignmentPanel}
    >
      <div className={styles.assignmentPanelLeft}>
        <GuidelineStudy study={study} />
      </div>
      <div className={styles.assignmentPanelRight}>
        <input
          type="hidden"
          name={`guideline_studies[${study.id}]status`}
          value={currentStatus}
          data-study-id={study.id}
          data-testid={`study-status-${study.id}`}
        />
        <div className={styles.subformBackground}>
          <TopicAssignment
            topics={topics}
            study={study}
            onInteract={(topicId) => {
              setCurrentStatus(statuses.relevant);

              setTopicsChecked({
                ...topicsChecked,
                [topicId]: !topicsChecked[topicId],
              });
            }}
          />
        </div>

        <div style={{ marginTop: '30px' }}>
          <TertiaryButton
            style={{ width: '200px' }}
            onClick={toggleCurrentStatus(statuses.irrelevant)}
            iconName={isIrrelevant() ? 'check-circle' : undefined}
            data-testid={`study-irrelevant-${study.id}`}
          >
            Irrelevant
          </TertiaryButton>
        </div>

        <div style={{ marginTop: '10px' }}>
          <TertiaryButton
            style={{ width: '200px' }}
            onClick={toggleCurrentStatus(statuses.parked)}
            iconName={isParked() ? 'check-circle' : undefined}
            data-testid={`study-parked-${study.id}`}
          >
            Parked
          </TertiaryButton>
        </div>
      </div>
    </div>
  );
}
