interface HasFeatures {
  enabledFeatures: readonly string[];
}

export default function hasFeature(model: HasFeatures, feature: string) {
  return (
    model &&
    model.enabledFeatures &&
    Array.isArray(model.enabledFeatures) &&
    model.enabledFeatures.includes(feature)
  );
}
