import React, { useEffect } from 'react';
import classNames from 'classnames';
import StudyList from '../StudyList';
import styles from './AutoExclusions.module.scss';
import {
  Heading,
  Link,
  Loading,
  Message,
  Text,
  Toast,
  ToastButton,
  createToast,
} from 'components/core';
import { CompactPagination } from 'components/shared';
import { Study } from 'types';
import { useGetLatestAutoExclusionBatchQuery } from 'query/review';
import { usePrevious } from 'hooks/usePrevious';
import {
  AUTO_EXCLUSION_BATCH_COMPLETED_TOAST_ID,
  dismissBatchCompletedToast,
} from 'pages/AutoExclusions/AutoExclusionsPage';
import usePersistedState from 'hooks/usePersistedState';

const Section = ({ children, ...props }: React.HTMLProps<HTMLElement>) => {
  return (
    <section
      id="auto-exclusion-study-list"
      className={classNames([styles.box, styles.standaloneMessage])}
      {...props}
    >
      {children}
    </section>
  );
};

const BatchingInProgressSpinner = () => {
  return (
    <div className={styles.loadingContainer}>
      <Loading size="xl" type="primary" />
      <Text className={styles.loadingHeader} component="h4" size="md">
        Auto-marking ineligible references...
      </Text>
      <Text variant="weak">
        This can take a while, depending on the number of references that have
        no manual votes.
      </Text>
    </div>
  );
};

export type AutoExclusionsProps = {
  reviewId: number;
  isAutoExclusionsEnabled: boolean;
  currentPageNumber: number;
  settingsLink: string;
  displayPageMetadata: string;
  isFetching: boolean;
  studies: Study[];
  totalPages: number;
  setCurrentPageNumber: (pageNumber: number) => void;
  refetchPage: (page: number) => Promise<void>;
};

const AutoExclusions = ({
  reviewId,
  currentPageNumber,
  displayPageMetadata,
  isAutoExclusionsEnabled,
  isFetching,
  setCurrentPageNumber,
  settingsLink,
  studies,
  totalPages,
  refetchPage,
}: AutoExclusionsProps) => {
  const sortingMethod = 'most recent';
  const studyCount = studies.length;
  const { data: batchResponseData } = useGetLatestAutoExclusionBatchQuery(
    reviewId,
    // Don't poll if feature is disabled
    { enabled: isAutoExclusionsEnabled }
  );
  const isBatching = batchResponseData?.isBatching;
  const wasBatching = usePrevious(isBatching);
  const [showListMessage, setShowListMessage] = usePersistedState<boolean>(
    'showAutoExclusionsListMessage',
    true
  );

  useEffect(() => {
    const batchingFinished =
      isBatching !== undefined && wasBatching && !isBatching;

    // Went from isBatching true to isBatching false, so the batch has finished
    if (batchingFinished) {
      // Get studies automatically if there are no studies on page
      if (studyCount === 0) {
        refetchPage(currentPageNumber);
      } else {
        // Toast when batch is completed
        createToast(
          <Toast showCloseButton={false}>
            References have been added or removed from the list
            <ToastButton
              onClick={() => {
                refetchPage(currentPageNumber);
                dismissBatchCompletedToast();
              }}
            >
              Reload
            </ToastButton>
          </Toast>,
          { duration: Infinity, id: AUTO_EXCLUSION_BATCH_COMPLETED_TOAST_ID }
        );
      }
    }
  }, [isBatching, wasBatching, currentPageNumber, refetchPage, studyCount]);

  const handleChangePageNumber = (pageNumber: number) => {
    setCurrentPageNumber(pageNumber);
    dismissBatchCompletedToast();
  };

  if (!isAutoExclusionsEnabled) {
    return (
      <Section>
        <Heading type="h3" variant="brand" className={styles.heading}>
          Reduce time manually screening
        </Heading>
        <Text>
          Use validated machine learning models to remove ineligible references
          and reduce your screening time by up to 45%. You can turn automation
          models on/off in{' '}
          <Link to={settingsLink} variant="primary">
            review settings
          </Link>
          .
        </Text>
      </Section>
    );
  }

  if (isFetching) {
    return (
      <Section aria-live={'polite'}>
        <Loading size="xl" />
      </Section>
    );
  }

  if (!studies.length) {
    if (isBatching) {
      return (
        <Section>
          <BatchingInProgressSpinner />
        </Section>
      );
    } else {
      return (
        <Section>
          <Heading type="h3" variant="brand" className={styles.heading}>
            No auto-marked as ineligible references
          </Heading>
          <Text>
            References auto-marked as ineligible will appear on this page. You
            can turn automation models on/off in{' '}
            <Link
              to={settingsLink}
              variant="primary"
              onClick={dismissBatchCompletedToast}
            >
              review settings
            </Link>
            .
          </Text>
        </Section>
      );
    }
  }

  return (
    <div className={styles.container}>
      {showListMessage && (
        <Message onClose={() => setShowListMessage(false)}>
          <Heading type="h5" className={styles.heading}>
            Removing references auto-marked as ineligible
          </Heading>
          <Text>
            This list shows all references that have been auto-marked as
            ineligible by the enabled machine learning models. You can turn
            automations models on/off in{' '}
            <Link to={settingsLink} variant="primary">
              review settings
            </Link>
            .
          </Text>
        </Message>
      )}

      <Section aria-live={'polite'} className={styles.box}>
        <div className={styles.metadataContainer}>
          <Text size="sm">{displayPageMetadata}</Text>
          <Text size="sm">Sorted by: {sortingMethod}</Text>
        </div>
        <hr className={styles.studyListBreak} />
        {isBatching && (
          <>
            <BatchingInProgressSpinner />
            <hr className={styles.studyListBreak} />
          </>
        )}

        <StudyList studies={studies} />
        <div className={styles.paginationContainer}>
          <CompactPagination
            currentPage={currentPageNumber}
            totalPages={totalPages}
            onChangePage={handleChangePageNumber}
          />
        </div>
      </Section>
    </div>
  );
};

export default AutoExclusions;
