import {
  TableData,
  ColumnCellData,
} from 'concepts/Extraction/Blocks/Table/BaseTable/types';
import { getTableCellData } from 'concepts/Extraction/Blocks/Table/BaseTable/utils';

type GetResolvedCellDataProps = {
  rowID: string;
  columnID: string;
  reviewerTables: TableData[];
};

/**
 * Returns resolved cell data if the cell is resolved.
 * A cell is resolved if the cell value inputted by all reviewers is identical.
 */
export const getResolvedCellData = ({
  rowID,
  columnID,
  reviewerTables,
}: GetResolvedCellDataProps): ColumnCellData | undefined => {
  /**
   * We use the first table as a reference point to compare other tables to
   * but really any table will do.
   */
  const [referenceReviewerTable, ...remainingReviewersTables] = reviewerTables;

  const referenceTableCellData = getTableCellData({
    table: referenceReviewerTable,
    rowID,
    columnID,
  });

  if (!referenceTableCellData?.value) return undefined;

  const isAllCellValuesTheSame: boolean = remainingReviewersTables.every(
    (table) => {
      const cellData = getTableCellData({ table, rowID, columnID });
      return cellData?.value === referenceTableCellData.value;
    }
  );

  /**
   * Omit referenceTableCellData.comments as we should only automatically resolve
   * the value of the cell.
   */
  return isAllCellValuesTheSame
    ? {
        columnID: referenceTableCellData.columnID,
        value: referenceTableCellData.value,
      }
    : undefined;
};
