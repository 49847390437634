import {
  border,
  BorderProps,
  colorStyle,
  ColorStyleProps,
  compose,
  shadow,
  ShadowProps,
  space,
  SpaceProps,
  textStyle,
  TextStyleProps,
  typography,
  TypographyProps,
  LayoutProps,
  layout,
  TLengthStyledSystem,
} from 'styled-system';
import * as CSS from 'csstype';
import shouldForwardProp from '@styled-system/should-forward-prop';
import styled from '../styled';

type TableStyleProps = BorderProps & ColorStyleProps & ShadowProps & SpaceProps;

const tableStyleProps = compose(colorStyle, shadow);

interface TableLayoutProps {
  fixed?: boolean;
}

export const Table = styled('table')<TableStyleProps & TableLayoutProps>(
  {
    width: '100%',
  },
  (props) =>
    props.fixed
      ? {
          tableLayout: 'fixed',
        }
      : {},
  tableStyleProps
);

Table.defaultProps = {
  colors: 'onWhite',
  boxShadow: 'light',
};

// sectioning elements
export const Head = styled('thead')<TextStyleProps>(textStyle);

Head.defaultProps = {
  textStyle: 'tableHeader',
};

export const Body = styled('tbody')<TextStyleProps>(textStyle);
export const Foot = styled('tfoot')<TextStyleProps>(textStyle);

// rows, cells
export const Row = styled('tr')();

type CellStyleProps = SpaceProps &
  Omit<LayoutProps, 'width' | 'height'> & {
    width?: CSS.Property.Width<TLengthStyledSystem>;
    height?: CSS.Property.Height<TLengthStyledSystem>;
  } & BorderProps &
  TextStyleProps &
  TypographyProps;

const cellStyleProps = compose(space, layout, border, textStyle, typography);

export const Cell = styled('td', {
  shouldForwardProp,
})<CellStyleProps>(cellStyleProps);

Cell.defaultProps = {
  px: 2,
  py: 1,
  border: 'table',
  height: 4,
};

export const HeadCell = styled(Cell.withComponent('th'), {
  shouldForwardProp,
})();

HeadCell.defaultProps = {
  px: 2,
  py: 1,
  border: 'table',
  textStyle: 'truncate',
};

// caption and columns
export const Caption = styled('caption')();

// note the styles which can be applied to cols are very limited
// https://www.w3.org/TR/CSS21/tables.html#columns
export const Column = styled('col', {
  shouldForwardProp,
})<LayoutProps>(layout);
