import React from 'react';
import { formatDistanceToNow } from 'date-fns';
import { FileInfo, FileListRowFunctions } from '../index';
import FileStatus from './FileStatus';
import DeleteFileButton from './DeleteFileButton';
import styles from './FileList.module.css';
import { NakedButton } from 'system/base/Button';

type Props = {
  file: FileInfo;
  uploadProgress?: number;
} & FileListRowFunctions;

export default function FileListRow({
  file,
  makeFilePrimary,
  uploadProgress,
  deleteFile,
  unsetDocumentUrl,
}: Props) {
  const fileUrlOrName = () =>
    file.url ? (
      <>
        <span>
          <a href={file.url} target="_blank" rel="noopener noreferrer">
            {file.uploadFileName || file.url}
          </a>
        </span>
        <i className="fas fa-external-link"></i>
      </>
    ) : (
      <span>{file.uploadFileName}</span>
    );

  return (
    <tr key={file.id}>
      <td className={styles.FileList__fullText}>
        <div>{fileUrlOrName()}</div>
      </td>
      <td className={styles.FileList__uploadDate}>
        {file.createdAt &&
          formatDistanceToNow(new Date(file.createdAt), { addSuffix: true })}
      </td>
      <td className={styles.FileList__uploadBy}>{file.uploadedBy}</td>
      <td className={styles.FileList__primary}>
        {(file.uploadError || !file.uploadComplete || file.isPrimary) && (
          <FileStatus file={file} uploadProgress={uploadProgress || 0} />
        )}
        {file.type === 's3_file' && file.uploadComplete && !file.isPrimary && (
          <NakedButton onClick={() => makeFilePrimary(file.id)}>
            Make primary
          </NakedButton>
        )}
      </td>
      <td className={styles.FileList__delete}>
        {(file.uploadComplete || file.uploadError) && (
          <DeleteFileButton
            file={file}
            deleteFile={deleteFile}
            unsetDocumentUrl={unsetDocumentUrl}
          />
        )}
      </td>
    </tr>
  );
}
