import {
  tokenizeRoute,
  CITATION_IMPORTS,
  CITATION_IMPORTS_DELETE,
} from '../routes';
import { getReviewId } from './index';
import { getCsrfToken } from 'util/authenticityToken';
import { CitationImport } from 'types';

interface GetCitationImport {
  total_pages: number;
  citation_imports: CitationImport[];
}
/**
 * Gets a list of citation imports
 */
export const getCitationImports = async (
  page: number
): Promise<GetCitationImport> => {
  const reviewId = getReviewId();
  if (!reviewId)
    throw new Error("Couldn't get Review ID. Is this part of a review?");

  const url = tokenizeRoute(CITATION_IMPORTS, {
    review_id: reviewId,
    page: page,
  });

  return fetch(url.toString(), {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  }).then((res) => res.json());
};

/**
 * Undoes a citation import
 */
export const undoCitationImport = async (
  citationImportId: string
): Promise<Response> => {
  const reviewId = getReviewId();
  if (!reviewId)
    throw new Error("Couldn't get Review ID. Is this part of a review?");

  const url = tokenizeRoute(CITATION_IMPORTS_DELETE, {
    review_id: reviewId,
    citation_import_id: citationImportId,
  });

  return fetch(url.toString(), {
    method: 'DELETE',
    credentials: 'same-origin',
    headers: { 'X-CSRF-Token': getCsrfToken() },
  });
};
