import React from 'react';
import { format as formatDate, formatDistanceToNow } from 'date-fns';
import { Time } from 'util/types';

interface AbsoluteProps {
  date: Date | Time;
  format?: Exclude<string, 'relative'>;
  options?: Parameters<typeof formatDate>[2];
}

interface RelativeProps {
  date: Date | Time;
  format?: 'relative';
  options?: Parameters<typeof formatDistanceToNow>[1];
}

const FormattedDate = ({
  date: providedDate,
  format = 'dd MMM yyyy',
  options = {},
}: AbsoluteProps | RelativeProps) => {
  const date = new Date(providedDate);

  return (
    <time
      dateTime={date.toISOString()}
      className="hide-in-percy"
      title={formatDate(date, 'yyyy-MM-dd HH:mm:ss')}
    >
      {format === 'relative'
        ? formatDistanceToNow(date, options)
        : formatDate(date, format)}
    </time>
  );
};

export default FormattedDate;
