import React, { ReactNode } from 'react';
import {
  ApolloClient,
  createHttpLink,
  split,
  ApolloProvider as Provider,
} from '@apollo/client';
import { loadErrorMessages, loadDevMessages } from '@apollo/client/dev';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import fetch from 'cross-fetch';
import { InMemoryCache } from '@apollo/client/cache';
import introspectionQueryResultData from './graphql_interfaces_unions';

if (process.env.DEV) {
  // Apollo 3.8 omits error messages from its core bundle to reduce its bundle size
  // This adds them back in for better error messages during development
  // https://www.apollographql.com/docs/react/errors/
  loadErrorMessages();
  loadDevMessages();
}

const LINK_OPTIONS = {
  uri: '/api/graphql',
  credentials: 'same-origin',
  fetch,
};

const batchLink = new BatchHttpLink(LINK_OPTIONS);
const link = createHttpLink(LINK_OPTIONS);

const cache = new InMemoryCache({
  possibleTypes: introspectionQueryResultData.possibleTypes,
  // When subsequent queries are fetched merge the data under the same key in the cache
  typePolicies: {
    Node: {
      merge: true,
    },
    HasDuplicatesInterface: {
      merge: true,
    },
    ReferenceInterface: {
      merge: true,
    },
    AccountOwner: {
      merge: true,
    },
    MutationResultInterface: {
      merge: true,
    },
  },
});

export const client = new ApolloClient({
  cache,
  link: split(
    (operation) => operation.getContext().batch === true,
    batchLink,
    link
  ),
  queryDeduplication: true,
});

export const ApolloProvider = (
  props: { children: ReactNode } & Record<string, unknown>
) => <Provider client={client} {...props} />;

export default client;
