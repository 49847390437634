import { Instance, ToolbarItem } from 'pspdfkit';
import { rotateLeftIcon, rotateRightIcon } from '../icons';

export const customiseToolbar = (
  instance: Instance,
  allowedToolbarItems: string[]
) => {
  const rotateClockwiseButton: ToolbarItem = {
    type: 'custom',
    id: 'rotate-clockwise-button',
    title: 'Rotate All Pages Clockwise',
    icon: rotateRightIcon,
    onPress: () => {
      const state = instance.viewState;
      const newState = state.rotateRight();
      instance.setViewState(newState);
    },
  };

  const rotateCounterClockwiseButton: ToolbarItem = {
    type: 'custom',
    id: 'rotate-counter-clockwise-button',
    title: 'Rotate All Pages Counter-clockwise',
    icon: rotateLeftIcon,
    onPress: () => {
      const state = instance.viewState;
      const newState = state.rotateLeft();
      instance.setViewState(newState);
    },
  };

  const toolbarItems = instance.toolbarItems.filter((item) =>
    allowedToolbarItems.includes(item.type)
  );
  instance.setToolbarItems(toolbarItems);
  instance.setToolbarItems((items) => {
    // inject rotate controls at position 7 & 8
    items.splice(7, 0, rotateClockwiseButton);
    items.splice(8, 0, rotateCounterClockwiseButton);
    return items;
  });
};
