import React from 'react';
import Subheading from './control/Subheading';
import { SubheadingBlock } from './SubheadingBlock';
import { SubheadingComparisonRow } from './control/SubheadingComparisonRow';
import {
  PreviewProps,
  ControlProps,
  EditorProps,
  ComparisonRowProps,
} from 'concepts/Extraction/Blocks/BlockRenderer';
import SubheadingEditor from 'concepts/Extraction/Blocks/Subheading/editor/SubheadingEditor';

const typeIconSVG = (
  <svg
    role="img"
    fill="none"
    height="32"
    viewBox="0 0 55 32"
    width="55"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Subheading</title>
    <path
      d="M0 5C0 2.23858 2.23858 0 5 0H50C52.7614 0 55 2.23858 55 5V27C55 29.7614 52.7614 32 50 32H5C2.23857 32 0 29.7614 0 27V5Z"
      fill="#64B0F1"
    />
    <path
      d="M7.495 22V21.16L8.875 20.83V12.67L7.495 12.34V11.5H12.7V12.34L11.32 12.67V16.12H15.055V12.67L13.675 12.34V11.5H18.88V12.34L17.5 12.67V20.83L18.88 21.16V22H13.675V21.16L15.055 20.83V17.275H11.32V20.83L12.7 21.16V22H7.495ZM19.7189 22V21.23C19.8289 21.12 19.9609 20.9807 20.1149 20.812C20.2763 20.636 20.5183 20.3463 20.8409 19.943C21.1709 19.5397 21.4606 19.1437 21.7099 18.755C21.9593 18.3663 22.1829 17.93 22.3809 17.446C22.5789 16.9547 22.6779 16.5147 22.6779 16.126C22.6779 15.7593 22.5973 15.444 22.4359 15.18C22.2746 14.916 22.0656 14.784 21.8089 14.784L20.5109 16.082C20.1223 15.7227 19.9133 15.257 19.8839 14.685C20.5953 14.3183 21.3726 14.135 22.2159 14.135C22.8979 14.135 23.4516 14.2853 23.8769 14.586C24.3023 14.8867 24.5149 15.3487 24.5149 15.972C24.5149 17.3287 23.5506 18.8723 21.6219 20.603H23.9209L24.3499 19.745H24.9439L24.6359 22H19.7189Z"
      fill="white"
    />
  </svg>
);

export const SubheadingBlockRenderer = {
  Editor({ block, onChange, onComplete, onRemove }: EditorProps) {
    return (
      <SubheadingEditor
        block={block as SubheadingBlock}
        onChange={onChange}
        onComplete={onComplete}
        onRemove={onRemove}
      />
    );
  },
  Control({ block }: ControlProps) {
    return <Subheading block={block as SubheadingBlock} />;
  },
  Preview({ block }: PreviewProps) {
    return <Subheading block={block as SubheadingBlock} />;
  },
  ComparisonRow({ block, reviewerRecords }: ComparisonRowProps) {
    return (
      <SubheadingComparisonRow
        label={block.label}
        numberReviewers={reviewerRecords.length}
      />
    );
  },
  keepResponse() {
    return true;
  },
  displayOnly: true,
  TypeIcon: () => typeIconSVG,
};
