import { useEffect } from 'react';
import { useFormState } from 'react-hook-form';
import { useConfirmUnsaved } from 'hooks/useConfirmUnsaved';

const UnsavedChangesWarning = () => {
  const [clean, dirty] = useConfirmUnsaved();
  // By using this hook we can isolate rerenders to this component
  // If you instead got the value from useForm(), then form would completely rerender on first interaction
  const { isDirty } = useFormState();

  useEffect(() => {
    // When forms are dirty we want to warn users about unsaved changes
    isDirty ? dirty() : clean();
  }, [isDirty, dirty, clean]);

  return null;
};

export default UnsavedChangesWarning;
