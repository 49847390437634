import React, { FunctionComponent, ReactNode } from 'react';
import Flex from 'system/base/Flex';
import SpacedFlex from 'system/utility/SpacedFlex';
import Box from 'system/base/Box';

interface Props {
  sidebar?: ReactNode;
}

const ContentSidebar: FunctionComponent<Props> = ({ sidebar, children }) => (
  <Flex flexDirection="row">
    <Box width={2 / 3}>{children}</Box>
    {sidebar && (
      <SpacedFlex
        flexDirection="column"
        justifyContent="start"
        ml={4}
        width={1 / 3}
      >
        {sidebar}
      </SpacedFlex>
    )}
  </Flex>
);

export default ContentSidebar;
