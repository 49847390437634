import React from 'react';
import toast, { ToastPosition } from 'react-hot-toast';

interface ToastOptions {
  duration?: number;
  position?: ToastPosition;
  id?: string;
}

const defaultOptions: ToastOptions = {
  duration: 4000,
  position: 'bottom-center',
};

const createToast = (component: any, options: ToastOptions = {}) => {
  const toastOptions = { ...defaultOptions, ...options };
  toast.custom(
    (toast) =>
      React.cloneElement(component, {
        toastId: toast.id,
        visible: toast.visible,
      }),
    toastOptions
  );
};

export const dismissToast = toast.dismiss;

export default createToast;
