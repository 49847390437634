import React, { useState } from 'react';

import { sortBy, filter } from 'underscore';

import NewCochraneLinkedReview from './NewCochraneLinkedReview/NewCochraneLinkedReview';
import NewRegularReview from './NewRegularReview/NewRegularReview';
import { BillableAccount } from './reviewInnerProps';
import Flex from 'system/base/Flex';
import { Radio } from 'system/elements';
import { ID } from 'util/types';

const NewReview = ({
  limitToAccountId,
  isCochraneConnected,
  billableAccounts,
}: {
  limitToAccountId?: ID;
  isCochraneConnected: boolean;
  billableAccounts: Record<string, BillableAccount>;
  enabledOrganization: string;
}) => {
  const [isCochraneReview, setIsCochraneReview] = useState(false);
  const [selectedAccountId, setSelectedAccountId] = useState('');

  const accounts = filter(
    sortBy(billableAccounts, ({ signedIn, owner, ssoRequired }) => [
      // Put personal account first
      owner.ownerType === 'Reviewer' ? -1 : 1,
      // No login required
      !ssoRequired ? -1 : 1,
      // Signed in
      signedIn ? -1 : 1,
      owner.name,
    ]),
    ({ id }) => (limitToAccountId ? id === limitToAccountId : true)
  ) as BillableAccount[];

  const selectedAccount: BillableAccount | undefined =
    accounts.find((acc) => {
      return acc.id === selectedAccountId;
    }) || accounts[0];

  const isCochraneUser = isCochraneConnected;

  return (
    <div className="floating-box">
      <h3>Are you creating a Cochrane review?</h3>
      <Flex>
        <Radio
          value="true"
          onChange={() => setIsCochraneReview(true)}
          checked={isCochraneReview}
          label="Yes"
        />
        <Radio
          value="false"
          onChange={() => setIsCochraneReview(false)}
          checked={!isCochraneReview}
          label="No"
        />
      </Flex>
      <hr />

      {isCochraneReview ? (
        <NewCochraneLinkedReview
          account={selectedAccount}
          accounts={accounts}
          isCochraneUser={isCochraneUser}
          hasTrialed={selectedAccount.hasTrialed}
          setSelectedAccountId={setSelectedAccountId}
        />
      ) : (
        <NewRegularReview
          account={selectedAccount}
          accounts={accounts}
          hasTrialed={selectedAccount.hasTrialed}
          setSelectedAccountId={setSelectedAccountId}
        />
      )}
    </div>
  );
};

export default NewReview;
