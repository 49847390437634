import React, { useRef, useState } from 'react';
import { NakedButton } from '../../../../system/base/Button';
import Icon, { IconStyle } from '../../../../system/base/Icon';
import {
  sendPendoEvent,
  uploadPDFToS3,
  saveReviewReferenceDocument,
  pollImportJobStatus,
} from '../../query';
import { ImportJob } from '../../index';
import DragAndDropUploader from '../DragAndDropUploader';
import { useAllowNavigate } from '../useAllowNavigate';
import ProgressBar from '../ProgressBar';
import styles from './SinglePDFUploader.module.css';
import { ExpectedFile } from 'components/FullTextBulkUploader';

interface Props {
  reviewId: string;
  importJobId: string;
  expectedFile: ExpectedFile;
  onProcessed: (importJob: ImportJob) => void;
}

const SinglePDFUploader = ({
  reviewId,
  importJobId,
  expectedFile,
  onProcessed,
}: Props) => {
  const { file_name, attachable_id } = expectedFile;
  const { setAllowNavigate } = useAllowNavigate(true);
  const fileRef = useRef<HTMLInputElement>(null);
  const [progress, setProgress] = useState<number>(0);
  const [error, setError] = useState<string>('');
  const title = file_name.replace('.pdf', '');

  const handleFileUpload = (files: File[], byDragAndDrop: boolean) => {
    if (files.length) {
      setError('');
      const file = files[0];
      if (file.type.indexOf('pdf') < 0) {
        setError('Please choose a .PDF file');
        return;
      }
      const matchedFile = { file, ...expectedFile };
      const totalUploadBytes = file.size;
      new Promise<string>((resolve, reject) => {
        setAllowNavigate(false);
        const errorMessage = 'Unable to upload file. Please try again.';
        uploadPDFToS3(matchedFile, reviewId, importJobId, {
          onProgress: (bytesUploaded) => {
            setProgress(bytesUploaded / totalUploadBytes);
          },
          onError: () => {
            reject(errorMessage);
          },
          onFinish: () => {
            resolve(attachable_id);
          },
        });
      })
        .then((attachable_id) =>
          saveReviewReferenceDocument(
            file,
            reviewId,
            importJobId,
            attachable_id
          )
        )
        .then(() => {
          const numOfUploads = 1;
          const pendoEvent = byDragAndDrop
            ? 'bulk-uploader-single-file-drop'
            : 'bulk-uploader-single-file-select';
          sendPendoEvent(pendoEvent, numOfUploads);
          return pollImportJobStatus(reviewId, importJobId);
        })
        .then((job: ImportJob) => onProcessed(job))
        .catch((e) => setError(e))
        .finally(() => {
          setAllowNavigate(true);
          setProgress(0);
        });
    }
  };

  return (
    <DragAndDropUploader
      acceptUpload=".pdf"
      onFileChange={handleFileUpload}
      ref={fileRef}
      data-testid="SinglePDFUploader"
      onDragEnterClassName={styles['SinglePDFUploader--dragged']}
    >
      <div className={styles.SinglePDFUploader}>
        <div
          data-testid="SinglePDFUploader__inputSection"
          className={styles.SinglePDFUploader__inputSection}
        >
          <span title={title} className={styles.SinglePDFUploader__fileName}>
            {title}
          </span>

          {/* styling needs to be refactored...picking up .FullTextBulkUploader button */}
          <NakedButton
            onClick={(e) => {
              e.preventDefault();
              fileRef.current?.click();
            }}
            disabled={progress > 0}
          >
            <Icon name="cloud-upload" iconStyle={IconStyle.Solid} />
            Choose file
          </NakedButton>
        </div>

        {error && (
          <span
            data-testid="SinglePDFUploader__error"
            className={styles.SinglePDFUploader__error}
          >
            {error}
          </span>
        )}

        {progress > 0 ? (
          <div className={styles.SinglePDFUploader__progressBar}>
            <ProgressBar uploadProgress={progress} />
          </div>
        ) : (
          <div className={styles.SinglePDFUploader__fileSeperator}></div>
        )}
      </div>
    </DragAndDropUploader>
  );
};

export default SinglePDFUploader;
