import React, { useState, useCallback } from 'react';
import idx from 'idx';
import { useMutation, gql } from '@apollo/client';
import { ID } from 'util/types';
import DataTable, { Columns } from 'system/data/DataTable';
import useCollectionQuery from 'hooks/useCollectionQuery';
import {
  AdminOrganizationInvitationsQuery,
  AdminOrganizationInvitationsQueryVariables,
  AdminDeleteOrganizationInvitationMutation,
  AdminDeleteOrganizationInvitationMutationVariables,
  AdminResendOrganizationInvitationMutation,
  AdminResendOrganizationInvitationMutationVariables,
} from 'types/graphql';
import FormattedDate from 'components/FormattedDate';
import DataWithControls from 'system/layout/DataWithControls';
import SearchInput from 'system/elements/SearchInput';
import { DangerButton, SecondaryButton } from 'system/base/Button';

interface Props {
  organizationId: ID;
}

const GET_INVITATIONS = gql`
  query AdminOrganizationInvitations(
    $organizationId: ID!
    $after: String
    $filter: OrganizationInvitationsFilter
  ) {
    organization: node(id: $organizationId) {
      id

      ... on Organization {
        invitations(first: 25, after: $after, filter: $filter)
          @connection(key: "invitations", filter: ["filter"]) {
          pageInfo {
            hasNextPage
            endCursor
          }

          nodes {
            id
            email
            name
            expiresAt
          }
        }
      }
    }
  }
`;

const DELETE_INVITATION = gql`
  mutation AdminDeleteOrganizationInvitation($invitationId: ID!) {
    cancelInvitation(input: { invitationId: $invitationId }) {
      success
      errors {
        message
      }
    }
  }
`;

const RESEND_INVITATION = gql`
  mutation AdminResendOrganizationInvitation(
    $organizationId: ID!
    $name: String!
    $email: Email!
  ) {
    invitePersonToOrganization(
      input: { organizationId: $organizationId, name: $name, email: $email }
    ) {
      success
      errors {
        message
      }

      invitation {
        id
        name
        email
        expiresAt
      }
    }
  }
`;

interface ActionsProps {
  organizationId: ID;
  invitationId: ID;
  name?: string;
  email: string;
}

const Actions = ({
  organizationId,
  invitationId,
  name,
  email,
}: ActionsProps) => {
  const [busy, setBusy] = useState(false);

  const [deleteInvitationMutation] = useMutation<
    AdminDeleteOrganizationInvitationMutation,
    AdminDeleteOrganizationInvitationMutationVariables
  >(DELETE_INVITATION);
  const deleteInvitation = useCallback(() => {
    setBusy(true);
    deleteInvitationMutation({
      variables: { invitationId },
      refetchQueries: ['AdminOrganizationInvitations'],
    });
  }, [setBusy, deleteInvitationMutation, invitationId]);

  const [resendInvitationMutation] = useMutation<
    AdminResendOrganizationInvitationMutation,
    AdminResendOrganizationInvitationMutationVariables
  >(RESEND_INVITATION);
  const resendInvitation = useCallback(() => {
    setBusy(true);
    resendInvitationMutation({
      variables: { organizationId, name: name || '', email },
    }).then(() => setBusy(false));
  }, [setBusy, resendInvitationMutation, organizationId, name, email]);

  return (
    <>
      <DangerButton
        iconName="trash-alt"
        onClick={deleteInvitation}
        disabled={busy}
        style={{ marginRight: 4 }}
      >
        Delete
      </DangerButton>
      <SecondaryButton
        iconName="sync"
        onClick={resendInvitation}
        disabled={busy}
      >
        Re-send
      </SecondaryButton>
    </>
  );
};

const OrganizationInvitations = ({ organizationId }: Props) => {
  const {
    data,
    loadMore,
    error,
    loading,
    variables,
    setVariable,
  } = useCollectionQuery<
    AdminOrganizationInvitationsQuery,
    AdminOrganizationInvitationsQueryVariables
  >({
    query: GET_INVITATIONS,
    variables: { organizationId },
    path: ['organization', 'invitations'],
  });
  const collection = idx(data, (_) => _.organization.invitations.nodes) || [];

  /* eslint-disable react/display-name */
  const columns: Columns<typeof collection> = [
    {
      heading: 'Email',
      primary: true,
      render: ({ email }) => email,
    },
    {
      heading: 'Expires in',
      render: ({ expiresAt }) => (
        <FormattedDate date={expiresAt} format="relative" />
      ),
    },
    {
      heading: '',
      render: ({ id, name, email }) => (
        <Actions
          organizationId={organizationId}
          invitationId={id}
          name={name}
          email={email}
        />
      ),
    },
  ];
  /* eslint-enable react/display-name */

  return (
    <DataWithControls
      actions={
        <SearchInput
          defaultValue={idx(variables, (_) => _.filter.email) || ''}
          onSearch={(value) => setVariable(['filter', 'email'], value)}
          placeholder="Email"
        />
      }
    >
      <DataTable
        collection={collection}
        columns={columns}
        loadMore={loadMore}
        error={error}
        loading={loading}
      />
    </DataWithControls>
  );
};

export default OrganizationInvitations;
