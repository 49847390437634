import React from 'react';
import { VisuallyHidden } from '@reach/visually-hidden';
import { Menu, MenuItem } from '../Widgets/Menu';
import { AddIcon } from 'concepts/Extraction/Icons/Add';

const newDomainIcon = (
  <svg
    role="img"
    fill="none"
    height="20"
    viewBox="0 0 23 20"
    width="23"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="m11.5006 2.11108 7.7461 13.41672h-15.49222z" fill="#050505" />
    <path d="m0 1.11108h23" stroke="#050505" strokeWidth="2" />
  </svg>
);

const multipleChoiceIcon = (
  <svg
    role="img"
    fill="none"
    height="20"
    viewBox="0 0 23 20"
    width="23"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g stroke="#989898" strokeDasharray="2 1">
      <path d="m11.5006 2 7.7461 13.4167h-15.49222z" />
      <path d="m0 .5 23 .000002" />
    </g>
  </svg>
);

export interface AddMenuProps {
  onAdd: (blockType: 'Domain') => void;
  onDuplicate: () => void;
}
export default function AddMenu({
  onAdd,
  onDuplicate,
}: AddMenuProps): JSX.Element {
  return (
    <Menu
      buttonTitle={
        <>
          <VisuallyHidden>Add</VisuallyHidden>{' '}
          <span aria-hidden>
            <AddIcon />
          </span>
        </>
      }
      itemsHeading="Add new item"
      position="right"
    >
      <MenuItem
        title="New Domain"
        description="Start a domain from scratch"
        icon={newDomainIcon}
        onSelect={() => onAdd('Domain')}
      />
      <MenuItem
        title="Duplicate this domain"
        description="Copy, paste, and edit this domain"
        icon={multipleChoiceIcon}
        onSelect={() => onDuplicate()}
      />
    </Menu>
  );
}
