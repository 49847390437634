import styled from '../styled';
import Text from './Text';

interface UnpaddedProps {
  unpadded?: boolean;
}

const unpadded = (props: UnpaddedProps) =>
  props.unpadded
    ? {
        margin: 0,
        padding: 0,
      }
    : {};

const Heading = styled(Text.withComponent('h1'))<UnpaddedProps>(unpadded);

export default Heading;

/* we'll force the following types since withComponent() won't maintain the styled props */

export const PageTitle = Heading.withComponent('h1') as typeof Heading;

PageTitle.defaultProps = {
  textStyle: 'pageTitle',
};

export const SummaryTitle = Heading.withComponent('h2') as typeof Heading;

SummaryTitle.defaultProps = {
  textStyle: 'summaryTitle',
};

export const SectionHeading = Heading.withComponent('h4') as typeof Heading;

SectionHeading.defaultProps = {
  textStyle: 'sectionHeading',
};

export const ContentHeading = Heading.withComponent('h4') as typeof Heading;

ContentHeading.defaultProps = {
  textStyle: 'contentHeading',
};
