import React, { useEffect, useState, useRef } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { formatDistanceToNow } from 'date-fns';
import OptionToRevisitStudiesModal from '../../OptionToRevisitStudiesModal';
import OutcomeGroupsSection from './components/OutcomeGroupsSection';
import DomainsSection from './components/DomainsSection';
import styles from './DefaultTemplate.module.scss';
import { useConfirmUnsaved } from 'hooks/useConfirmUnsaved';
import { Button, Icon, IconList, Link, Text } from 'components/core';
import { IsolationPage } from 'components/shared';
import { EXTRACTION_STUDIES_LIST_ROUTE, tokenizeRoute } from 'query/routes';
import {
  createEmptyQATemplate,
  getQATemplateProps,
  getQATemplate,
  getReviewId,
  updateQATemplate,
  getHttpErrorMessage,
} from 'query/review';
import {
  QualityAssessmentTemplateFormat,
  QualityAssessmentTemplate,
} from 'types/DataExtraction';
import {
  createToast,
  Toast,
  ToastButton,
  ToastContainer,
} from 'components/core/Toast';

const backRoute = tokenizeRoute(EXTRACTION_STUDIES_LIST_ROUTE, {
  review_id: getReviewId(),
});

interface Props {
  getTemplate?: ({
    initialFormat,
  }: getQATemplateProps) => Promise<QualityAssessmentTemplate>;
  updateTemplate?: (
    template: QualityAssessmentTemplate,
    revisitStudies: boolean
  ) => Promise<Response>;
  initialFormat: QualityAssessmentTemplateFormat;
}

const DefaultTemplate = ({
  getTemplate = getQATemplate,
  updateTemplate = updateQATemplate,
  initialFormat,
}: Props) => {
  const [isLoading, setLoading] = useState<boolean>();
  const [revisitModal, setRevisitModal] = useState<React.ReactElement | null>();
  const revisitStudies = useRef(false);
  const formFn = useForm<QualityAssessmentTemplate>({
    mode: 'all',
    defaultValues: createEmptyQATemplate(),
    shouldFocusError: false,
  });

  const {
    formState: { isDirty, isValid },
  } = formFn;

  const [template, setTemplate] = useState<QualityAssessmentTemplate>();
  useEffect(() => {
    getTemplate({ initialFormat: initialFormat }).then((template) => {
      setTemplate(template);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    formFn.reset(template);
  }, [formFn, template]);

  const [clean, dirty] = useConfirmUnsaved();
  useEffect(() => {
    isDirty ? dirty() : clean();
  }, [isDirty, dirty, clean]);

  const shouldShowRevisitModal = (): boolean => {
    return (
      (template?.number_of_revisitable_studies || 0) > 0 && isValid && isDirty
    );
  };

  const onPublish = (revisit = false) => {
    revisitStudies.current = revisit;
    publishTemplate();
  };

  const publishTemplate = formFn.handleSubmit(
    (state) => {
      setLoading(true);
      updateTemplate(state, revisitStudies.current)
        .then((response) => {
          if (response.status !== 200) {
            createToast(
              <Toast type="danger">
                {getHttpErrorMessage(response.status)}
                <ToastButton
                  to={'mailto:support@covidence.org'}
                  target="blank"
                  rel="noopener noreferrer"
                >
                  Need help?
                </ToastButton>
              </Toast>,
              { duration: 6000 }
            );
            return;
          }

          response.json().then((data) => {
            clean();
            window.location.href = backRoute.toString();

            if (data.moved_back) {
              document.cookie = `qa_moved_back=${data.moved_back}; SameSite=Lax; Path=/`;
            }

            document.cookie = `qa_template_published=true; SameSite=Lax; Path=/`;
            return;
          });
        })
        .finally(() => setLoading(false));
    },
    () => {
      const invalidInput = document.querySelector(
        '[aria-invalid=true]'
      ) as HTMLElement;
      invalidInput?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      setTimeout(() => invalidInput?.focus(), 1000);
    }
  );

  const showOptionToRevisitModal = () => {
    setRevisitModal(
      <OptionToRevisitStudiesModal
        numberOfRevisitableStudies={
          template?.number_of_revisitable_studies || 0
        }
        onClose={() => setRevisitModal(null)}
        onPublish={onPublish}
        templateType="QualityAssessment"
      />
    );
  };

  const publishHandler = () => {
    if (shouldShowRevisitModal()) {
      return showOptionToRevisitModal;
    } else {
      return publishTemplate;
    }
  };

  return (
    <FormProvider {...formFn}>
      {revisitModal}
      <IsolationPage
        back={backRoute}
        title="Quality assessment template"
        controls={
          <>
            {template?.updated_at && (
              <Text variant="medium" size="xs">
                Published{' '}
                {formatDistanceToNow(Date.parse(template?.updated_at), {
                  addSuffix: true,
                })}{' '}
                by {template?.updated_by?.first_name}
              </Text>
            )}
            <Button
              onClick={publishHandler()}
              type="brand"
              isLoading={isLoading}
              data-pendo-key="qat-publish"
            >
              Publish template
            </Button>
            <Link
              className={styles.HelpLink}
              onClick={() => ''}
              data-pendo-key="qat-help"
              aria-label="Get help"
            >
              <Icon icon={IconList.light.faQuestionCircle} />
            </Link>
          </>
        }
      >
        <div className={styles.DefaultTemplate}>
          <DomainsSection />
          <OutcomeGroupsSection />
        </div>
      </IsolationPage>
      <ToastContainer />
    </FormProvider>
  );
};

export default DefaultTemplate;
