import {
  makeThemeFontSizesScale,
  makeThemeFontWeightsScale,
  makeThemeTextStylesScale,
} from './utils/make-theme';
import { colors } from './colors';

export const sizeScale = [
  12,
  14, // 1em
  16,
  20,
  22,
  26,
  30,
  42,
  47,
  52,
];

export const sizeAliases = {
  body: sizeScale[1],
  pageTitle: sizeScale[6],
  summaryTitle: sizeScale[5],
  sectionHeading: sizeScale[2],
  contentHeading: sizeScale[3],
  dashboardMetric: sizeScale[4],
};

export const fontSizes = makeThemeFontSizesScale(
  Object.assign([], sizeScale, sizeAliases)
);

// available font weights for Inter
// for reasons of gaps in our styled-system types, we'll name them the same as
// CSS.FontWeightProperty keys.
export const fontWeights = makeThemeFontWeightsScale({
  lighter: 300,
  normal: 400,
  semibold: 500,
  bold: 700,
});

export const textStyles = makeThemeTextStylesScale({
  body: {
    fontSize: sizeAliases.body,
    color: colors.text,
  },
  pageTitle: {
    fontSize: sizeAliases.pageTitle,
    fontWeight: fontWeights.lighter,
    color: colors.text,
  },
  summaryTitle: {
    fontSize: sizeAliases.pageTitle,
    fontWeight: fontWeights.normal,
    color: colors.text,
  },
  sectionHeading: {
    fontSize: sizeAliases.sectionHeading,
    lineHeight: 1.4,
  },
  contentHeading: {
    fontSize: sizeAliases.contentHeading,
    color: colors.text,
    fontWeight: fontWeights.normal,
  },
  link: {
    color: colors.link,
    textDecoration: 'underline',

    ':hover': {
      textDecoration: 'none',
    },
  },
  inherit: {
    color: 'currentColor',
  },
  inheritLink: {
    color: 'currentColor',
    textDecoration: 'underline',

    ':hover': {
      textDecoration: 'none',
    },
  },
  tableHeader: {
    color: colors.greyDark,
  },
  primaryCell: {
    fontWeight: fontWeights.semibold,
  },
  truncate: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  dashboardMetric: {
    fontSize: sizeAliases.dashboardMetric,
  },
  deleted: {
    color: colors.danger,
  },
  muted: {
    color: colors.greyDark,
  },
});
