import React, { ComponentProps, FunctionComponent, ReactNode } from 'react';
import { layout, LayoutProps } from 'styled-system';
import Icon, { IconName, IconStyle } from '../base/Icon';
import { ListItem, UnorderedList } from '../base/List';
import Text, { InlineText } from 'system/base/Text';
import styled from 'system/styled';

export const IconList: FunctionComponent<
  ComponentProps<typeof UnorderedList>
> = (props) => <UnorderedList variant="unbulleted" {...props} />;

const BulletWrapper = styled(Text)<LayoutProps>(layout);

BulletWrapper.defaultProps = {
  display: 'inline-block',
  width: 4,
  textAlign: 'center',
};

interface Props {
  iconColor?: ComponentProps<typeof InlineText>['color'];
  iconStyle?: IconStyle;
  iconName: IconName;
  children: ReactNode;
}

export const IconListItem: FunctionComponent<
  Props & ComponentProps<typeof ListItem>
> = ({ iconColor, iconStyle, iconName, children, ...props }: Props) => {
  return (
    <ListItem {...props}>
      <BulletWrapper color={iconColor}>
        <Icon iconStyle={iconStyle} name={iconName} />
      </BulletWrapper>
      {children}
    </ListItem>
  );
};

export default IconListItem;
