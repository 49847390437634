import React from 'react';
import { gql } from '@apollo/client';
import idx from 'idx';
import Checkbox from 'system/elements/Checkbox';
import SortDropdown from 'system/data/SortDropdown';
import { ID } from 'util/types';
import useCollectionQuery from 'hooks/useCollectionQuery';
import DataTable, { Columns } from 'system/data/DataTable';
import FormattedDate from 'components/FormattedDate';
import Text from 'system/base/Text';
import Box from 'system/base/Box';

import {
  AdminAccountReviewsQuery,
  AdminAccountReviewsQueryVariables,
  SortDirection,
  ReviewSort,
} from 'types/graphql';
import SearchInput from 'system/elements/SearchInput';
import DataWithControls from 'system/layout/DataWithControls';
import useSortVariables from 'hooks/useSortVariables';
import Notice from 'system/elements/Notice';
import { Link } from 'system/base/Link';
import cochraneLogo from 'assets/images/cochrane-brandmark.svg';

const GET_REVIEWS = gql`
  query AdminAccountReviews(
    $organizationId: ID!
    $after: String
    $filter: OrganizationReviewsFilter
    $sort: ReviewSort
    $sortDirection: SortDirection
  ) {
    organization: node(id: $organizationId) {
      ... on Organization {
        id
        reviews(
          first: 15
          after: $after
          filter: $filter
          sort: $sort
          sortDirection: $sortDirection
        )
          @connection(
            key: "reviews"
            filter: ["filter", "sort", "sortDirection"]
          ) {
          ...AccountReviews
        }
      }
    }
  }

  fragment AccountReviews on ReviewConnection {
    pageInfo {
      hasNextPage
      endCursor
    }
    nodes {
      id
      dbId
      title
      lastActivityAt
      createdAt
      deletedAt
      creator {
        id
        dbId
        name
      }
      cochraneReviewLink {
        id
      }
    }
  }
`;

interface Props {
  organizationId: ID;
}

const sortOptions = [
  {
    label: 'Review Title (A-Z)',
    value: { sort: ReviewSort.Title, sortDirection: SortDirection.Asc },
  },
  {
    label: 'Review Title (Z-A)',
    value: { sort: ReviewSort.Title, sortDirection: SortDirection.Desc },
  },
  {
    label: 'Most recently active',
    value: {
      sort: ReviewSort.LastActivityAt,
      sortDirection: SortDirection.Desc,
    },
  },
  {
    label: 'Least recently active',
    value: {
      sort: ReviewSort.LastActivityAt,
      sortDirection: SortDirection.Asc,
    },
  },
  {
    label: 'Newest',
    value: { sort: ReviewSort.CreatedAt, sortDirection: SortDirection.Desc },
  },
  {
    label: 'Oldest',
    value: { sort: ReviewSort.CreatedAt, sortDirection: SortDirection.Asc },
  },
];

const Date = ({ date }: { date: Date | string }) => (
  <FormattedDate date={date} format="relative" options={{ addSuffix: true }} />
);

const AccountReviews = ({ organizationId }: Props) => {
  const {
    data,
    loadMore,
    error,
    loading,
    variables,
    setVariable,
    setVariables,
  } = useCollectionQuery<
    AdminAccountReviewsQuery,
    AdminAccountReviewsQueryVariables
  >({
    query: GET_REVIEWS,
    variables: { organizationId },
    path: ['organization', 'reviews'],
  });
  const [sort, setSort] = useSortVariables(variables, setVariables);

  const collection = idx(data, (_) => _.organization.reviews.nodes) || [];

  /* eslint-disable react/display-name */
  const columns: Columns<typeof collection> = [
    {
      heading: 'Title',
      primary: true,
      render: ({ dbId, title, deletedAt, cochraneReviewLink }) => (
        <Link href={`/admin/reviews/${dbId}`} style={{ display: 'inline' }}>
          {cochraneReviewLink && (
            <Box display="inline" verticalAlign="top" marginRight={1}>
              <img src={cochraneLogo} alt="Cochrane" />
            </Box>
          )}
          {deletedAt ? (
            <Text textStyle={'deleted'}>{title} (deleted)</Text>
          ) : (
            title
          )}
        </Link>
      ),
    },
    {
      heading: 'Last Active',
      render: (r) => <Date date={r.lastActivityAt} />,
    },
    { heading: 'Created', render: (r) => <Date date={r.createdAt} /> },
    {
      heading: 'Creator',
      render: ({ creator }) => (
        <Link href={`/admin/reviewers/${creator.dbId}`}>{creator.name}</Link>
      ),
    },
  ];
  /* eslint-enable react/display-name */

  const updateIncludeDeletedFilter = (e: React.ChangeEvent<HTMLInputElement>) =>
    setVariable(['filter', 'includeDeleted'], e.target.checked);

  return (
    <DataWithControls
      filters={
        <>
          <SortDropdown sort={sort} setSort={setSort} options={sortOptions} />
          <Checkbox
            onChange={updateIncludeDeletedFilter}
            label="Include deleted?"
          />
        </>
      }
      actions={
        <SearchInput
          defaultValue={idx(variables, (_) => _.filter.search) || ''}
          onSearch={(value) => setVariable(['filter', 'search'], value)}
          placeholder="Find a review"
        />
      }
    >
      {error && (
        <Notice type="warning">
          {
            "You don't have access to this data while proxied as a non-admin user"
          }
        </Notice>
      )}
      <DataTable
        collection={collection}
        columns={columns}
        loadMore={loadMore}
        error={error}
        loading={loading}
      />
    </DataWithControls>
  );
};

export default AccountReviews;
