import React from 'react';
import styles from '../../containers/Comments/Comments.module.css';
import displayName from './displayName';
import { CommentMention } from 'types/graphql';

export type Mention = Pick<CommentMention, 'name' | 'replied'>;
export interface MentionsProps {
  mentions: ReadonlyArray<Mention>;
}

export interface MentionProps {
  mention: Mention;
}

export function Mentions({ mentions }: MentionsProps): JSX.Element | null {
  return mentions.length > 0 ? (
    <section className={styles.mentionSection} data-testid="mentions">
      {mentions.map((mention: Mention, i) => (
        <SingleMention key={`mention-${mention.name}-${i}`} mention={mention} />
      ))}
    </section>
  ) : null;
}

export function SingleMention({ mention }: MentionProps): JSX.Element {
  const replyStatusClass = mention.replied ? styles.replied : styles.notReplied;
  return (
    <p
      className={`${styles.commentMention} ${replyStatusClass}`}
      data-testid="mention"
    >
      {displayName(mention.name)}
    </p>
  );
}

export default Mentions;
