import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import React from 'react';
import classNames from 'classnames';
import styles from './Reorder.module.scss';
import { Button, Icon, IconList } from 'components/core';

interface Props {
  children: React.ReactNode;
  id: string | number;
  className?: string;
}

const ReorderItem = ({ children, id, className }: Props) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({
    id,
  });

  const reorderStyle = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <li
      ref={setNodeRef}
      style={reorderStyle}
      className={classNames(styles.ReorderItem, className)}
    >
      <Button
        {...attributes}
        {...listeners}
        type="neutral"
        variant="ghost"
        iconOnly
        className={styles.DragHandle}
      >
        <Icon icon={IconList.solid.faGripDotsVertical} size="xs">
          Drag Handle
        </Icon>
      </Button>
      {children}
    </li>
  );
};

export default ReorderItem;
