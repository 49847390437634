import {
  NonRetriableError,
  OfflineError,
  withRequestRetry,
  DEFAULT_REQUEST_TIMEOUT,
} from './DataExtraction';
import { CREATE_CUSTOM_FIELD_VALUES_ROUTE, tokenizeRoute } from 'query/routes';
import { getStudyId } from 'query/study/Study';

export const createCustomFieldValues = async (
  category: string,
  custom_fields: { custom_field_id: number; value: string }[],
  onError?: (error: Error) => void
) => {
  return withRequestRetry(
    () => _createCustomFieldValuesRequest(category, custom_fields),
    onError
  );
};

const _createCustomFieldValuesRequest = async (
  category: string,
  custom_fields: { custom_field_id: number; value: string }[]
) => {
  if (!navigator.onLine)
    throw new OfflineError('No network connection detected');

  const url = tokenizeRoute(CREATE_CUSTOM_FIELD_VALUES_ROUTE, {
    review_study_id: getStudyId(),
  });

  return fetch(url.toString(), {
    method: 'POST',
    body: JSON.stringify({
      category: category,
      custom_fields: custom_fields,
    }),
    headers: { 'Content-Type': 'application/json' },
    signal: (AbortSignal as any).timeout(DEFAULT_REQUEST_TIMEOUT),
  }).then((response) => {
    if (response.status !== 201)
      throw new NonRetriableError(String(response.status));

    return response.json();
  });
};
