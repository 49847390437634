import React, { useState } from 'react';
import MultiSelect from 'react-select/creatable';
import styles from './AddTagsModal.module.css';
import { Study } from './StudyTypes';
import SaveStudyTagsButton from './SaveStudyTagsButton';
import Dialog from 'system/base/Dialog';

export interface AddTagsModalProps {
  study: Study;
  isOpen: boolean;
  availableTags: string[];
  onSuccess: () => void;
  onCancel: () => void;
  onFailure: () => void;
}

export default function AddTagsModal({
  study,
  availableTags,
  isOpen,
  onSuccess,
  onCancel,
  onFailure,
}: AddTagsModalProps): JSX.Element {
  const [tagContent, setTagContent] = useState<string[]>([]);

  const tagToOption = (tagName: string) => ({
    label: tagName,
    value: tagName,
  });

  const title = `Tags for ${study.studyId}`;

  return (
    <Dialog isOpen={isOpen} onDismiss={onCancel} title={title}>
      <div className={styles.tagsInputContainer}>
        <MultiSelect
          placeholder=""
          options={availableTags.map(tagToOption)}
          defaultValue={tagContent.map(tagToOption)}
          isValidNewOption={(inputValue) => {
            return inputValue.length >= 3;
          }}
          onChange={(selectedOptions: any) => {
            if (selectedOptions) {
              setTagContent(selectedOptions.map((option: any) => option.value));
            } else {
              setTagContent([]);
            }
          }}
          openMenuOnFocus={true}
          isSearchable={true}
          isMulti={true}
          styles={{
            container: (style) => ({
              ...style,
              marginRight: '10px',
              width: '100%',
            }),
            control: (style) => ({
              ...style,
              minHeight: '38px',
              margin: 0,
              padding: 0,
              alignItems: 'center',
            }),
            indicatorsContainer: (style) => ({
              ...style,
              padding: '0px',
              alignItems: 'center',
            }),
            valueContainer: (style) => ({
              ...style,
              padding: '0px',
              alignItems: 'center',
            }),
            input: (style) => ({
              ...style,
              margin: 0,
              padding: 0,
              paddingLeft: '8px',
            }),
          }}
        />
        <div className={styles.saveButtonContainer}>
          <SaveStudyTagsButton
            study={study}
            tags={tagContent}
            onFailure={onFailure}
            onSuccess={onSuccess}
          />
        </div>
      </div>
    </Dialog>
  );
}
