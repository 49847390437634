import { v4 as uuid } from 'uuid';
import { clone, omit, without } from 'ramda';
import { TemplateBlock } from '../../BaseBlock';
import { Table2Block } from '../types/block';

export const blockIsTable2 = (block: TemplateBlock): block is Table2Block =>
  block.type === 'CustomTable2';

export const updateHeaderLabel = (tableBlock: Table2Block) => (
  headerID: string
) => (label: string): Table2Block => {
  const updatedBlock = clone(tableBlock);
  updatedBlock.headers[headerID] = { label };
  return updatedBlock;
};

export const addRow = (tableBlock: Table2Block) => (
  label = '',
  rowID: string = uuid()
): Table2Block => {
  const updatedBlock = clone(tableBlock);
  updatedBlock.headers[rowID] = { label };
  updatedBlock.structure.rows.push(rowID);
  return updatedBlock;
};

export const addColumn = (tableBlock: Table2Block) => (
  label = '',
  columnID: string = uuid()
): Table2Block => {
  const updatedBlock = clone(tableBlock);
  updatedBlock.headers[columnID] = { label };
  updatedBlock.structure.cols.push(columnID);
  return updatedBlock;
};

export const removeRow = (tableBlock: Table2Block) => (
  rowID: string
): Table2Block => {
  const updatedHeaders = omit([rowID], tableBlock.headers);
  const updatedRows = without([rowID], tableBlock.structure.rows);
  const updatedBlock = clone(tableBlock);

  updatedBlock.headers = updatedHeaders;
  updatedBlock.structure.rows = updatedRows;

  return updatedBlock;
};

export const removeColumn = (tableBlock: Table2Block) => (
  columnID: string
): Table2Block => {
  const updatedHeaders = omit([columnID], tableBlock.headers);
  const updatedColumns = without([columnID], tableBlock.structure.cols);
  const updatedBlock = clone(tableBlock);

  updatedBlock.headers = updatedHeaders;
  updatedBlock.structure.cols = updatedColumns;

  return updatedBlock;
};
