import React, { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import TextareaAutosize from 'react-textarea-autosize';
import pluralize from 'pluralize';
import styles from './CreateComment.module.css';
import { StudyModel } from 'containers/Guidelines/Triage/StudyModel';
import { Link } from 'system/base/Link';
import { PrimaryButton } from 'system/base/Button';
import { CreateCommentMutation } from 'types/graphql';
import StudyDetails from 'concepts/Studies/StudyDetails';
import ErrorMessage from 'components/ErrorMessage';

export const CREATE_COMMENT_MUTATION = gql`
  mutation CreateComment($input: CreateCommentInput!) {
    createComment(input: $input) {
      success
      errors {
        message
      }
    }
  }
`;

export interface MentionModel {
  id: number;
  name: string;
  gqlId: string;
}

export interface onChangeProps {
  mentionId: string;
  isSelected: boolean;
}

export interface MentionCheckboxProps {
  mention: MentionModel;
  isSelected: boolean;
  onChange: (props: onChangeProps) => void;
}

export interface CreateCommentProps {
  studies: StudyModel[];
  internalMentions: MentionModel[];
  expertMentions: MentionModel[];
  showDetails?: boolean;
}

function singleStudy(study: StudyModel): JSX.Element {
  return (
    <div key={study.id} className={styles.item}>
      <StudyDetails study={study} showPublisherInfo={true} />
    </div>
  );
}

interface MentionsGroupProps {
  groupName: string;
  groupMentions: MentionModel[];
  onSelected: (selected: string[]) => void;
}

function MentionsGroup({
  groupName,
  groupMentions,
  onSelected,
}: MentionsGroupProps): JSX.Element {
  const [selectedMentions, setSelectedMentions] = useState<string[]>([]);
  const [isAllSelected, setIsAllSelected] = useState<boolean>(false);
  const handleMentionsChanged = (mentions: string[]) => {
    setSelectedMentions(mentions);
    onSelected(mentions);
  };

  const renderSelectAll = () => {
    return (
      <label htmlFor="select-all" className={styles.mentionSelect}>
        <input
          type="checkbox"
          checked={isAllSelected}
          id="select-all"
          data-testid={`select-all-${groupName.toLowerCase()}`}
          onChange={() => {
            handleMentionsChanged(
              isAllSelected ? [] : groupMentions.map((mention) => mention.gqlId)
            );
            setIsAllSelected(!isAllSelected);
          }}
        />
        Select all
      </label>
    );
  };

  const mentionsCheckbox = ({
    mention,
    isSelected,
    onChange,
  }: MentionCheckboxProps): JSX.Element => {
    return (
      <label
        data-testid="mention-label"
        className={styles.mentionSelect}
        htmlFor={mention.gqlId}
        key={mention.id}
      >
        <input
          type="checkbox"
          checked={isSelected}
          id={mention.gqlId}
          data-testid={mention.id}
          onChange={(e: any) =>
            onChange({
              mentionId: mention.gqlId,
              isSelected: e.target.checked,
            })
          }
        />
        {mention.name}
      </label>
    );
  };

  return groupMentions.length > 0 ? (
    <div className={styles.mentionColumn}>
      <h4>{groupName}</h4>
      {renderSelectAll()}
      {groupMentions.map((mention) => {
        const isSelected = selectedMentions.includes(mention.gqlId);

        const onChange = ({ mentionId, isSelected }: onChangeProps) => {
          let newSelectedMentions;
          if (isSelected) {
            newSelectedMentions = [...selectedMentions];
            if (!selectedMentions.includes(mentionId)) {
              newSelectedMentions.push(mentionId);
            }
          } else {
            newSelectedMentions = selectedMentions.filter(
              (gqlId) => gqlId !== mentionId
            );
          }

          setIsAllSelected(newSelectedMentions.length === groupMentions.length);
          handleMentionsChanged(newSelectedMentions);
        };
        return mentionsCheckbox({ mention, isSelected, onChange });
      })}
    </div>
  ) : (
    <></>
  );
}

export function CreateComment({
  studies,
  internalMentions,
  expertMentions,
  showDetails = false,
}: CreateCommentProps): JSX.Element {
  const [createCommentMutation] = useMutation<CreateCommentMutation>(
    CREATE_COMMENT_MUTATION
  );

  const [content, setContent] = useState<string>();
  const [selectedInternalMentions, setSelectedInternalMentions] = useState<
    string[]
  >([]);
  const [selectedExpertMentions, setSelectedExpertMentions] = useState<
    string[]
  >([]);
  const [statusMessage, setStatusMessage] = useState<string>();
  const [showingDetails, setShowDetails] = useState<boolean>(showDetails);
  const toggle = () => setShowDetails(!showingDetails);
  const studyCount = studies.length;

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setContent(e.target.value);
  };

  function createComment() {
    const studyIds = studies.map((s) => s.gqlId);
    createCommentMutation({
      variables: {
        input: {
          content,
          studyIds,
          mentionedReviewerIds: selectedInternalMentions.concat(
            selectedExpertMentions
          ),
        },
      },
    })
      .then((result) => {
        const { data } = result;
        if (data?.createComment?.success) {
          window.location.href = document.referrer;
        } else {
          const errors = data?.createComment?.errors;
          setStatusMessage(
            errors
              ? errors.map((err) => err.message).join('; ')
              : 'Unknown error'
          );
        }
      })
      .catch((e) => {
        setStatusMessage(e.message);
      });
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (content && content.length > 0) {
      createComment();
    } else {
      setStatusMessage('Please enter a question!');
    }
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Studies to discuss</h1>
      <p className={styles.subtleDescriptor}>
        These studies will be attached to your discussion
      </p>

      <Link
        data-testid="study-toggle-link"
        className={styles.studyLink}
        iconName={showingDetails ? 'caret-down' : 'caret-right'}
        onClick={toggle}
      >
        {showingDetails ? 'Hide' : 'Show'} {studyCount} selected{' '}
        {pluralize('study', studyCount)}
      </Link>

      {showingDetails && studies.map(singleStudy)}

      <form className={styles.createCommentForm} onSubmit={handleSubmit}>
        {statusMessage && <ErrorMessage>{statusMessage}</ErrorMessage>}
        <label className={styles.commentContentLabel} htmlFor="content">
          <h1 className={styles.title}>Question</h1>
          <p className={styles.subtleDescriptor}>
            Ask team members a question or seek their opinion on the selected
            studies
          </p>
          <TextareaAutosize
            className={styles.commentContentInput}
            data-testid="comment-content-input"
            name="content"
            value={content}
            minRows={6}
            onChange={handleChange}
          />
        </label>
        <label htmlFor="mentions" className={styles.teamMembers}>
          <h1 className={styles.title}>Mentions</h1>
          <p className={styles.subtleDescriptor}>
            Select the people who you want to provide input for your discussion
          </p>
          <div className={styles.mentionsColumns} data-testid="mentions">
            <MentionsGroup
              groupName="Internal"
              groupMentions={internalMentions}
              onSelected={setSelectedInternalMentions}
            />
            <MentionsGroup
              groupName="Experts"
              groupMentions={expertMentions}
              onSelected={setSelectedExpertMentions}
            />
          </div>
        </label>
        <PrimaryButton data-testid="submit-link" type="submit">
          Ask question
        </PrimaryButton>
        <Link
          data-testid="cancel-link"
          className={styles.cancelLink}
          onClick={() => (window.location.href = document.referrer)}
        >
          Cancel
        </Link>
      </form>
    </div>
  );
}
