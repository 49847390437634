import { getCsrfToken } from 'query';

/**
 * Get the current study ID by scraping the URL
 *
 * TODO: find a better way of doing this, along with getReviewId
 */
export const getStudyId = () => {
  const tokens = window.location.href.split('/');
  const index = tokens.indexOf('review_studies');

  if (index >= 0 && index < tokens.length - 1) {
    return tokens[index + 1];
  }
  return '';
};

export const moveToScreening = (reviewId: number, studyId: number) => {
  const promise = new Promise<void>((resolve, reject) => {
    fetch(
      `/reviews/${reviewId}/auto_excluded_studies/${studyId}/move_to_screening`,
      {
        method: 'PATCH',
        headers: {
          'X-CSRF-Token': getCsrfToken(),
        },
      }
    ).then((response) => {
      if (response.ok) {
        resolve();
      } else {
        reject();
      }
    });
  });

  return promise;
};
