import React, { useMemo, FunctionComponent } from 'react';
import { ID } from 'util/types';
import SummaryTable, { Columns } from 'system/elements/SummaryTable';
import FormattedDate from 'components/FormattedDate';
import Text, { InlineText } from 'system/base/Text';
import { Link } from 'system/base/Link';
import { CATEGORY_NAMES } from 'schema/enums';
import { ImportFragment, ImportState } from 'types/graphql';

export interface Props {
  recentImports: readonly ImportFragment[] | null;
  viewMore: string;
  checkDuplicatesLink: (id: ID | null) => string;
}

const importSourceFlag = (
  i: ImportFragment,
  source: string,
  pretty: string = source
) =>
  i.source === source && (
    <>
      {' from '}
      <InlineText fontWeight="normal">{pretty}</InlineText>
    </>
  );

const SuccessfulImportSummary: FunctionComponent<{ i: ImportFragment }> = ({
  i,
}) => (
  <Text fontSize={2} fontWeight="lighter">
    <InlineText fontWeight="normal">{i.importedStudiesCount}</InlineText>
    {' studies added to '}
    <InlineText fontWeight="normal">
      {CATEGORY_NAMES[i.defaultCategory]}
    </InlineText>
    {importSourceFlag(i, 'CRS')}
    {importSourceFlag(i, 'GUIDELINE_TRIAGE', 'Triage')}
    {importSourceFlag(i, 'STUDY_TRIAGE', 'Triage')}
  </Text>
);

const ImportHistory = ({
  recentImports,
  viewMore,
  checkDuplicatesLink,
}: Props) => {
  const columns: Columns<NonNullable<typeof recentImports>> = useMemo(() => {
    /* eslint-disable react/display-name*/
    return [
      {
        description: 'Import date',
        width: 140,
        render: ({ createdAt }) => (
          <Text
            color="greyDark"
            fontWeight="lighter"
            style={{ textTransform: 'uppercase' }}
          >
            <FormattedDate date={createdAt} />
          </Text>
        ),
      },
      {
        description: 'Summary',
        render: (i) =>
          i.state === ImportState.Failed ? (
            <Text color="danger" fontSize={2} fontWeight="lighter">
              Import attempt failed - {i.error && i.error.message}
            </Text>
          ) : (
            <SuccessfulImportSummary i={i} />
          ),
      },
      {
        key: 'Duplicate label',
        cellProps: {
          'aria-hidden': true,
          paddingLeft: 0,
        },
        width: 300,
        render: (i) => (
          <span>
            <Text textAlign="right" fontSize={2}>
              {i.duplicateCitationsCount > 0 ? (
                <Link href={checkDuplicatesLink(i.id)}>
                  {i.duplicateCitationsCount}{' '}
                  {i.duplicateCitationsCount == 1
                    ? 'duplicate was'
                    : 'duplicates were'}{' '}
                  removed
                </Link>
              ) : (
                'No duplicates found'
              )}
            </Text>
          </span>
        ),
      },
    ];
    /* eslint-enable react/display-name */
  }, [checkDuplicatesLink]);

  if (!recentImports || recentImports.length === 0) {
    return null;
  }

  return (
    <SummaryTable
      header={<Text color="text">IMPORT HISTORY</Text>}
      footer={
        <Link href={viewMore} ml={3}>
          VIEW DETAILS
        </Link>
      }
      footerProps={{ paddingLeft: 0 }}
      columns={columns}
      collection={recentImports}
    />
  );
};

export default ImportHistory;
