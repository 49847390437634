import {
  makeThemeColorsScale,
  makeThemeColorStylesScale,
} from './utils/make-theme';

export const colors = makeThemeColorsScale({
  text: '#2A2A2A',
  background: '#f4f4f4',

  whiteLight: '#ffffff',
  white: '#f4f4f4',
  whiteDark: '#ededed',
  orange: '#f19d0d',

  // buttons
  primary: '#405779',
  primaryDark: '#374b68',
  primaryDarker: '#202c3d',
  secondary: '#839ac6',
  secondaryDark: '#728cbe',
  secondaryDarker: '#4b69a3',
  success: '#1BBD82',
  successDark: '#18a773',
  successDarker: '#106d4b',
  danger: '#e1284e',
  dangerDark: '#d21d42',
  dangerDarker: '#981530',

  // brands
  cochrane: '#8D186B',

  //
  link: '#638CA3',
  included: '#1bbd82',
  excluded: '#e1284e',

  greyLighter: 'rgb(238, 238, 238)',
  greyLight: 'rgb(210, 210, 210)',
  grey: 'rgb(166, 166, 166)',
  greyDark: 'rgb(112, 112, 112)',
  greyDarker: 'rgb(102, 102, 102)',

  blue: '#597cd9',

  transparent: 'transparent',

  // OSLD
  // semi-transparent
  shadow: 'rgba(0,0,0,0.50);',
  shadowSubtle: 'rgba(0,0,0,0.125)',
});

export const colorStyles = makeThemeColorStylesScale({
  // text variants, typically used in Texts and Icons
  link: {
    color: colors.link,
    backgroundColor: colors.transparent,
  },
  danger: {
    color: colors.danger,
    backgroundColor: colors.whiteLight,
    borderColor: colors.grey,
  },
  primary: {
    color: colors.text,
    backgroundColor: colors.whiteLight,
    borderColor: colors.greyLight,
  },
  warning: {
    color: colors.text,
    backgroundColor: colors.orange,
  },

  // text-on-color variants, typically used in Buttons and Notices
  onWhite: {
    color: colors.text,
    backgroundColor: colors.whiteLight,
    borderColor: colors.greyLight,
  },
  onPrimary: {
    color: colors.white,
    backgroundColor: colors.primary,
    borderColor: colors.primaryDarker,
  },
  onSecondary: {
    color: colors.white,
    backgroundColor: colors.secondary,
    borderColor: colors.secondaryDarker,
  },
  onSuccess: {
    color: colors.white,
    backgroundColor: colors.success,
    borderColor: colors.successDarker,
  },
  onDanger: {
    color: colors.white,
    backgroundColor: colors.danger,
    borderColor: colors.dangerDarker,
  },
  onGhost: {
    color: colors.white,
    backgroundColor: colors.transparent,
    borderColor: colors.white,
  },
  onCochrane: {
    color: colors.white,
    backgroundColor: colors.cochrane,
    borderColor: colors.cochrane,
  },
  onIncluded: {
    color: colors.white,
    backgroundColor: colors.included,
  },
  onExcluded: {
    color: colors.white,
    backgroundColor: colors.excluded,
  },
  // avatars
  onGrey: {
    color: colors.white,
    backgroundColor: colors.grey,
  },
  // current user avatar (heading)
  onBlue: {
    color: colors.white,
    backgroundColor: colors.blue,
  },
});

export const actionableColorStyles = {
  onWhite: {
    ':hover, :focus': {
      backgroundColor: colors.white,
    },
  },
  onPrimary: {
    ':hover, :focus': {
      backgroundColor: colors.primaryDark,
    },
  },
  onSecondary: {
    ':hover, :focus': {
      backgroundColor: colors.secondaryDark,
    },
  },
  onSuccess: {
    ':hover, :focus': {
      backgroundColor: colors.successDark,
    },
  },
  onDanger: {
    ':hover, :focus': {
      backgroundColor: colors.dangerDark,
    },
  },
};
