import React from 'react';
import { gql } from '@apollo/client';
import idx from 'idx';
import styles from './Comments.module.css';
import {
  CommentReviewer,
  ListedDiscussion,
} from 'concepts/Comments/ListedDiscussion';
import useCollectionQuery from 'hooks/useCollectionQuery';
import { ID } from 'util/types';
import Spinner from 'system/elements/Spinner';
import ErrorMessage from 'components/ErrorMessage';
import { NoWorkToDo } from 'concepts/Guidelines/NoWorkToDo/NoWorkToDo';
import { PrimaryButton } from 'system/base/Button';
import Box from 'system/base/Box';
import {
  GetCommentsQuery,
  GetCommentsQueryVariables,
  Comment as GqlComment,
  CommentSort,
  CommentStatus,
  CommentRestriction,
  Maybe,
} from 'types/graphql';

export const COMMENTS_QUERY = gql`
  query GetComments(
    $filter: CommentsFilter!
    $sort: CommentSort
    $after: String
  ) {
    comments(first: 25, after: $after, filter: $filter, sort: $sort) {
      pageInfo {
        endCursor
        hasNextPage
      }
      nodes {
        id
        content
        mentions {
          name
          replied
        }
        createdAgo
        activeAgo
        activeBy {
          name
        }
        reviewer {
          name
        }
        reviewStudies {
          id
          title
          authors
          studyId
          covidenceNumber
          references {
            nodes {
              abstract
              journalInfo
              publisherInfo
              documents {
                id
                url
                fileName
              }
            }
          }
        }
        actions {
          showUrl
        }
      }
    }
  }
`;

export interface CommentScopeProps {
  scopeId: ID;
  restriction: Maybe<CommentRestriction>;
}
export interface DiscussionsLoaderProps {
  scope: CommentScopeProps;
  status: Maybe<CommentStatus>;
}

export function DiscussionsLoader({
  scope,
  status,
}: DiscussionsLoaderProps): JSX.Element {
  const { data, error, loading, loadMore } = useCollectionQuery<
    GetCommentsQuery,
    GetCommentsQueryVariables
  >({
    query: COMMENTS_QUERY,
    path: ['comments'],
    variables: {
      filter: {
        scopeId: scope.scopeId,
        status,
        restriction: scope.restriction,
      },
      sort:
        status === 'RESOLVED'
          ? CommentSort.RecentlyResolved
          : CommentSort.RecentlyActive,
    },
  });

  if (loading) {
    return <Spinner />;
  }
  if (error) {
    return <ErrorMessage>{error}</ErrorMessage>;
  }

  const renderDiscussion = (comment: GqlComment) => {
    return (
      <ListedDiscussion
        key={comment.id}
        {...(comment as GqlComment)}
        reviewer={comment.reviewer as CommentReviewer}
      />
    );
  };

  const comments = idx(data, (_) => _.comments.nodes);
  const pageInfo = idx(data, (_) => _.comments.pageInfo);

  if (comments) {
    return comments.length > 0 ? (
      <section className={styles.container} data-testid="comments-section">
        {comments.map((comment) => renderDiscussion(comment as GqlComment))}

        {pageInfo && pageInfo.hasNextPage && loadMore && (
          <Box textAlign="center">
            <PrimaryButton data-testid="load-more" onClick={() => loadMore()}>
              Load More
            </PrimaryButton>
          </Box>
        )}
      </section>
    ) : (
      <NoWorkToDo />
    );
  }

  return <></>;
}

export default DiscussionsLoader;
