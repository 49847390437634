import React, { useState } from 'react';
import styles from './DataExtractionTemplate/DataExtractionTemplate.module.scss';
import {
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Text,
  Button,
} from 'components/core';

type TemplateType = 'DataExtraction' | 'QualityAssessment';
interface Props {
  onClose: () => void;
  numberOfRevisitableStudies: number;
  onPublish: (revisit?: boolean) => void;
  templateType: TemplateType;
}

const OptionToRevisitStudiesModal = ({
  onClose,
  numberOfRevisitableStudies,
  onPublish,
  templateType,
}: Props) => {
  const [isOpen, setOpen] = useState(true);
  const [doNotRevisitIsLoading, setDoNotRevisitIsLoading] = useState(false);
  const [doRevisitIsLoading, setDoRevisitIsLoading] = useState(false);

  const studyOrStudies = (numberOfStudies: number) => {
    if (numberOfStudies == 1) return `${numberOfStudies} study`;
    return `${numberOfStudies} studies`;
  };

  const extractOrAssess = (templateType: TemplateType) => {
    if (templateType == 'DataExtraction') return 'extract';
    return 'assess';
  };

  const modalBodyClosingLineText = (templateType: TemplateType) => {
    if (templateType == 'DataExtraction')
      return 'Data that has already been extracted will be saved.';
    return 'Assessments that have already been made will be saved.';
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setOpen(false);
        onClose();
      }}
    >
      <ModalHeader>Would you like reviewers to revisit studies?</ModalHeader>
      <ModalBody>
        <Text>
          {`Would you like to move studies back to incomplete, so reviewers can
          ${extractOrAssess(templateType)} any new or edited template items?`}
        </Text>
        <Text className={styles.SubsequentParagraph}>
          {modalBodyClosingLineText(templateType)}
        </Text>
      </ModalBody>
      <ModalFooter>
        <Button
          type="neutral"
          variant="outline"
          isLoading={doNotRevisitIsLoading}
          disabled={doRevisitIsLoading || doNotRevisitIsLoading}
          onClick={() => {
            setDoNotRevisitIsLoading(true);
            onPublish();
          }}
        >
          {"No, don't move studies"}
        </Button>
        <Button
          type="brand"
          isLoading={doRevisitIsLoading}
          disabled={doRevisitIsLoading || doNotRevisitIsLoading}
          onClick={() => {
            setDoRevisitIsLoading(true);
            onPublish(true);
          }}
        >
          {`Yes, move ${studyOrStudies(
            numberOfRevisitableStudies
          )} to incomplete`}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default OptionToRevisitStudiesModal;
