import { darken } from '@theme-ui/color';
import { makeThemeLinksScale } from './utils/make-theme';
import { colors } from './colors';

// (32px height - 3px borders) / 14px text height
const lineHeight = 29 / 14;

const links = makeThemeLinksScale({
  raised: {
    lineHeight,
    display: 'inline-block',
    paddingLeft: 3,
    paddingRight: 3,
    borderRadius: 3,
    borderWidth: 0,
    borderStyle: 'solid',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    verticalAlign: 'middle',
    opacity: 1.0,
    color: 'white',
    ':hover, :focus': {
      textDecoration: 'none',
      color: 'white',
    },
  },
  flat: {
    color: colors.link,
    paddingBottom: 0,
    textDecoration: 'underline',
    backgroundColor: 'transparent',
    ':hover': {
      textDecoration: 'underline',
    },
  },
  primary: {
    variant: 'links.raised',
    color: 'white',
    background: colors.primary,
    borderColor: colors.primary,
    borderBottomWidth: 3,
    borderBottomColor: darken(colors.primary, 0.18),
  },
  secondary: {
    variant: 'links.raised',
    background: colors.secondary,
    borderColor: colors.secondary,
    borderBottomWidth: 3,
    borderBottomColor: darken(colors.secondary, 0.18),
  },
  tertiary: {
    variant: 'links.raised',
    background: 'white',
    borderTopWidth: 1,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderBottomWidth: 3,
    borderStyle: 'solid',
    borderColor: darken('white', 0.2),
    color: darken('white', 0.5),
    ':hover': {
      color: darken('white', 0.5),
    },
  },
  ghost: {
    variant: 'links.flat',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: 'white',
    color: 'white',
    ':hover': {
      color: 'white',
    },
  },
  cochrane: {
    variant: 'links.raised',
    background: colors.cochrane,
    borderColor: colors.cochrane,
    borderBottomWidth: 3,
    borderBottomColor: darken(colors.cochrane, 0.18),
  },
});

export default links;
