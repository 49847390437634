import React, { useState } from 'react';
import { Label, LabelText } from '../../shared/BlockWidgets';
import { MultilinedText } from '../../shared/MultilinedText';
import { CommentButton } from '../../shared/Comment';
import { BaseCheckboxes } from '../BaseCheckboxes';
import styles from './Checkboxes.module.css';
import {
  CheckboxesBlock,
  CheckboxesBlockValue,
} from 'concepts/Extraction/Blocks/Checkboxes/CheckboxesBlock';
import { useDescribedBy } from 'hooks/useAccessibleName';
import { useDebouncedFunction } from 'hooks/useDebouncedFunction';

export type ReadonlyCheckboxesProps = {
  block: CheckboxesBlock;
};

export type EditableCheckboxesProps = ReadonlyCheckboxesProps & {
  editable: true;
  value: CheckboxesBlockValue;
  comments: string | null;
  onChange: (newValue: CheckboxesBlockValue | null) => void;
  onChangeComments: (newComments: string) => void;
};

export type CheckboxesProps = ReadonlyCheckboxesProps | EditableCheckboxesProps;

const isEditableCheckboxProps = (
  props: CheckboxesProps
): props is EditableCheckboxesProps =>
  !!(props as EditableCheckboxesProps).editable;

type RenderCommentsProps = {
  comments: EditableCheckboxesProps['comments'];
  blockLabel: CheckboxesBlock['label'];
  onChangeComments: EditableCheckboxesProps['onChangeComments'];
};

const renderCommentsButton = ({
  comments,
  blockLabel,
  onChangeComments,
}: RenderCommentsProps): JSX.Element => (
  <CommentButton
    comments={comments}
    blockLabel={blockLabel}
    onChangeComments={onChangeComments}
  />
);

export const Checkboxes = (props: CheckboxesProps): JSX.Element => {
  const [value, setValue] = useState((props as EditableCheckboxesProps).value);
  const [descriptionID, describedBy] = useDescribedBy();

  const isEditable = isEditableCheckboxProps(props);
  const noop = () => '';

  const callback = isEditable
    ? (props as EditableCheckboxesProps).onChange
    : noop;

  const debouncedCallback = useDebouncedFunction(callback, 300);

  return (
    <>
      <Label>
        <div className={styles.labelOuter}>
          <div>
            <LabelText>{props.block.label}</LabelText>
            {props.block.description !== '' && (
              <div id={descriptionID} className={styles.description}>
                <MultilinedText>{props.block.description}</MultilinedText>
              </div>
            )}
          </div>
          {isEditableCheckboxProps(props) &&
            renderCommentsButton({
              comments: props.comments,
              blockLabel: props.block.label,
              onChangeComments: props.onChangeComments,
            })}
        </div>
      </Label>
      <BaseCheckboxes
        {...describedBy}
        block={props.block}
        isDisabled={!isEditableCheckboxProps(props)}
        {...(isEditableCheckboxProps(props) && {
          value,
          onChange: (newValue) => {
            setValue(newValue as CheckboxesBlockValue);
            debouncedCallback(newValue);
          },
          canClearSelection: true,
        })}
      />
    </>
  );
};
