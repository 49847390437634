import React from 'react';
import styles from './CriteriaDescription.module.scss';
import { List, ListItem, Text } from 'components/core';

export type CriteriaDescriptionProps = {
  renderAsList: boolean;
  description: string[];
};

const CriteriaDescription = ({
  description,
  renderAsList,
}: CriteriaDescriptionProps) => {
  if (!description.length) {
    return <Text className={styles.na}>N/A</Text>;
  }

  if (renderAsList) {
    return (
      <List>
        {description.map((item, index) => (
          <ListItem key={index}>
            <p
              className={styles.criteriaItemText}
              dangerouslySetInnerHTML={{ __html: item }}
            ></p>
          </ListItem>
        ))}
      </List>
    );
  }

  return (
    <p
      className={styles.criteriaItemText}
      dangerouslySetInnerHTML={{ __html: description[0] }}
    ></p>
  );
};

export default CriteriaDescription;
