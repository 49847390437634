import React, { useState } from 'react';
import pluralize from 'pluralize';
import { StudyModel } from './StudyModel';
import {
  QuestionAssignmentOptions,
  QuestionsAssignmentActions,
} from './QuestionAssignmentOptions';
import { useConfirmUnsaved } from 'hooks/useConfirmUnsaved';
import { Filters } from 'containers/Guidelines/Triage/FiltersModel';
import styles from 'containers/Guidelines/Triage/Triage.module.css';
import guidelineStyles from 'containers/Guidelines/Guidelines.module.css';
import { PrimaryButton } from 'system/base/Button';
import { QuestionAssignments } from 'concepts/Guidelines/Triage/QuestionAssignments/QuestionAssignments';
import { NoWorkToDo } from 'concepts/Guidelines/NoWorkToDo/NoWorkToDo';
import { TopicFilter } from 'concepts/Guidelines/Triage/TopicFilter/TopicFilter';

export interface AssignToQuestionsProps {
  filters: Filters;
  studies: StudyModel[];
  questionOptions: QuestionAssignmentOptions;
  actions: QuestionsAssignmentActions;
}

export function AssignToQuestions({
  studies,
  questionOptions,
  actions,
  filters,
}: AssignToQuestionsProps): JSX.Element {
  const [assignedStudiesCount, setAssignedStudiesCount] = useState(0);
  const [hasDuplicateAssignments, setDuplicateAssignments] = useState(false);
  const [clean, dirty] = useConfirmUnsaved();

  const checkForDuplicateAssignments = (
    assignedStudies: HTMLSelectElement[]
  ) => {
    const studyAndReviewIds = assignedStudies.map(
      (s) => `${s.dataset.studyId}-to-${s.value}`
    );
    setDuplicateAssignments(
      studyAndReviewIds.length != new Set(studyAndReviewIds).size
    );
  };

  const countAssignedStudies = (e: React.FormEvent<HTMLFormElement>) => {
    const form = e.currentTarget;
    const questionAssignmentSelectNodes = [
      ...form.querySelectorAll("select[data-select='question']"),
    ] as HTMLSelectElement[];
    const assignedStudies = questionAssignmentSelectNodes.filter(
      (s) => s.value !== ''
    );
    const uniqueStudies = new Set(
      assignedStudies.map((s) => s.dataset.studyId)
    );
    setAssignedStudiesCount(uniqueStudies.size);
    uniqueStudies.size === 0 ? clean() : dirty();
    checkForDuplicateAssignments(assignedStudies);
  };

  return (
    <form
      data-testid="assignQuestions"
      method="post"
      action={actions.createGuidelineTriageQuestionAssignmentsUrl}
      onChange={countAssignedStudies}
      onSubmit={() => {
        clean();
      }}
    >
      <div>
        <nav className={styles.listNavWithFilter}>
          <TopicFilter filters={filters} />

          <div>
            {hasDuplicateAssignments && (
              <span style={{ paddingRight: '10px' }}>
                Studies can only be assigned to a review once!
              </span>
            )}
            <PrimaryButton
              disabled={hasDuplicateAssignments || assignedStudiesCount === 0}
              type="submit"
              style={{ minWidth: '150px' }}
              data-testid="assign-studies-button"
            >
              {`Import ${assignedStudiesCount} ${pluralize(
                'study',
                assignedStudiesCount
              )}`}
            </PrimaryButton>
          </div>
        </nav>
        <article className={guidelineStyles.withShadow}>
          <div>
            {studies.map((study) => (
              <QuestionAssignments
                key={study.id}
                study={study}
                questionOptions={questionOptions}
              />
            ))}
            {studies.length === 0 && <NoWorkToDo />}
          </div>
        </article>
      </div>
    </form>
  );
}
