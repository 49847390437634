import React, { Fragment, useState } from 'react';
import { TertiaryButton } from 'system/base/Button';
import styles from 'containers/Guidelines/Triage/Triage.module.css';

export interface AbstractProps {
  text: string;
  showAbstract?: boolean;
}

export function Abstract({ text, showAbstract = true }: AbstractProps) {
  const [showingAbstract, setShowAbstract] = useState(showAbstract);
  const toggle = () => setShowAbstract(!showingAbstract);

  return !showingAbstract ? (
    <div className={styles.studySummaryHideShow}>
      <TertiaryButton iconName="caret-right" onClick={toggle}>
        Show Abstract &amp; IDs
      </TertiaryButton>
    </div>
  ) : (
    <Fragment>
      <div className={styles.studySummaryHideShow}>
        <TertiaryButton iconName="caret-down" onClick={toggle}>
          Hide Abstract &amp; IDs
        </TertiaryButton>
      </div>
      <p className={styles.studySummarySmallText}>{text}</p>
    </Fragment>
  );
}
