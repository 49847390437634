import { flexbox, FlexboxProps } from 'styled-system';
import styled from '../styled';
import Box from './Box';

// for elements which extend Flex and apply additional styling, like LabelFor or SpacedFlex
export const isDirectionVertical = (
  flexDirection: FlexboxProps['flexDirection']
) => flexDirection === 'column' || flexDirection === 'column-reverse';

const Flex = styled(Box)<FlexboxProps>(
  {
    display: 'flex',
    // workaround for IE10, which defaults flex children to `flex: 0 0 auto`
    '> *': {
      flex: '0 1 auto',
    },
  },
  flexbox
);

export default Flex;
