import { ApolloError, gql, useMutation } from '@apollo/client';

import {
  CreateReviewMutation,
  CreateReviewInput,
  CreateReviewPayload,
} from 'types/graphql';

const CREATE_REVIEW_MUTATION = gql`
  mutation CreateReview(
    $accountId: ID!
    $title: String!
    $reviewType: String!
    $otherReviewTypeLabel: String
    $questionType: String!
    $otherQuestionTypeLabel: String
    $researchArea: String!
    $otherResearchAreaLabel: String
    $enableRctClassifier: Boolean
    $enableRctAutoExclusion: Boolean
  ) {
    createReview(
      input: {
        accountId: $accountId
        title: $title
        reviewType: $reviewType
        otherReviewTypeLabel: $otherReviewTypeLabel
        questionType: $questionType
        otherQuestionTypeLabel: $otherQuestionTypeLabel
        researchArea: $researchArea
        otherResearchAreaLabel: $otherResearchAreaLabel
        enableRctClassifier: $enableRctClassifier
        enableRctAutoExclusion: $enableRctAutoExclusion
      }
    ) {
      success
      errors {
        message
      }

      review {
        id
        title
      }
    }
  }
`;

const processResult = (result?: CreateReviewMutation) => {
  const newReviewId = result?.createReview?.review?.id;
  const errors = result?.createReview?.errors;
  const success = result?.createReview?.success;
  return { newReviewId, errors, success };
};

const processErrors = ({
  createReviewResult,
  apolloError,
  submitting,
  errors,
  success,
}: {
  createReviewResult?: Pick<CreateReviewPayload, 'success'> | null;
  apolloError?: ApolloError;
  submitting: boolean;
  errors: { message: string }[];
  success: boolean;
}): string[] => {
  if (!createReviewResult || submitting || success) {
    return [];
  }
  if (apolloError) {
    return [apolloError.message];
  }
  if (errors && errors.length > 0) {
    return errors.map((v) => v.message);
  }
  return ['Unknown error'];
};

export const useCreateReviewMutation = () => {
  const [
    createReview,
    { loading: submitting, data: result, error: apolloError },
  ] = useMutation<CreateReviewMutation, CreateReviewInput>(
    CREATE_REVIEW_MUTATION
  );

  const { success, newReviewId } = processResult(result || undefined);

  const errors = processErrors({
    submitting,
    apolloError,
    createReviewResult: result?.createReview,
    errors:
      result?.createReview?.errors?.map((e) => {
        return { message: e.message };
      }) || [],
    success: !!success,
  });

  return {
    createReview,
    submitting,
    success,
    newReviewId,
    errors,
  };
};
