import React, { useEffect, useMemo } from 'react';
import { AccordionEventHandler, AccordionEventContext } from './index';

interface Props {
  allowMultiple?: boolean;
  children: React.ReactNode;
  isAlwaysOpen?: boolean;
  className?: string;
  component?: string | React.FunctionComponent<any>;
}

const Accordion = ({
  allowMultiple = true,
  children,
  isAlwaysOpen = false,
  component = React.Fragment,
  ...props
}: Props) => {
  const eventHandler = useMemo(
    () => new AccordionEventHandler(allowMultiple, isAlwaysOpen),
    [allowMultiple, isAlwaysOpen]
  );

  // Open the first element on initial render
  useEffect(() => {
    eventHandler.checkOpen();
  }, [eventHandler, eventHandler.subscribers.length]);

  return (
    <AccordionEventContext.Provider value={eventHandler}>
      {React.createElement(component, { ...props }, children)}
    </AccordionEventContext.Provider>
  );
};

export default Accordion;
