import React from 'react';
import { CheckboxesBlock } from '../CheckboxesBlock';
import { InstructionsToExtractors } from '../../shared/InstructionsToExtractors';
import { AddOtherOptionCheckbox } from './settings/AddOtherOptionCheckbox';

export interface CheckboxesEditorSettingsProps {
  block: CheckboxesBlock;
  onChange: (updatedBlock: CheckboxesBlock) => void;
}

export const CheckboxesEditorSettings = ({
  block,
  onChange,
}: CheckboxesEditorSettingsProps): JSX.Element => (
  <>
    <InstructionsToExtractors block={block} onChange={onChange} />
    <hr />
    <AddOtherOptionCheckbox block={block} onChange={onChange} />
    <hr />
  </>
);
