import React, { useState, useEffect } from 'react';
import { CellProps } from 'react-table';
import TextareaAutosize from 'react-textarea-autosize';
import { VisuallyHidden } from '@reach/visually-hidden';
import { RowData, CellValue } from '../types';
import styles from './EditableCell.module.css';
import { getCellLabel } from './getCellLabel';
import { NakedButton } from 'system/base/Button';

type EditableCellProps = CellProps<RowData, CellValue>;

type DisplayerProps = {
  value: string;
  onClick: () => void;
};

const Displayer = ({ value, onClick }: DisplayerProps) => {
  return (
    <NakedButton className={styles.display} color="inherit" onClick={onClick}>
      {value}
    </NakedButton>
  );
};

/** @TODO Allow navigating between cells using arrow keys. */
export const EditableCell = ({
  value: initialValue,
  row,
  column,
  updateCellValue,
  rowHeaders,
  columnHeaders,
}: EditableCellProps) => {
  const [value, setValue] = useState<CellValue>(initialValue);
  const [editMode, setEditMode] = useState(false);
  const rowID = row.original.rowID;

  /**
   * @remarks
   * Just in case rowID does not exist for column-only tables, rowIndex can be
   * used to identify the row.
   * This is just future-proofing the possibility of column-only tables.
   * Currently, the DE table creator will always generate an id for rows.
   */
  const rowIndex = row.index;
  const columnID = column.id;

  const label = getCellLabel({
    cell: {
      rowID,
      rowIndex,
      columnID,
    },
    headers: {
      rows: rowHeaders ?? [],
      columns: columnHeaders ?? [],
    },
  });

  const onChange: React.ChangeEventHandler<HTMLTextAreaElement> = (e) =>
    setValue(e.target.value);

  const onBlur: React.FocusEventHandler<HTMLTextAreaElement> = () => {
    setEditMode(false);
    if (!updateCellValue || value === initialValue) return;
    updateCellValue({ rowID, rowIndex, columnID, value });
  };

  // If the initialValue is changed external, sync it up with our state
  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  // The reason for using a placeholder when instead of simply rendering the text field is
  // that when a review has a table with a large number of cells, rendering many
  // `TextareaAutosize` components becomes computationally expensive
  const displayValue = value?.toString() ?? '';
  return (
    <label className={styles.label} data-testid="editable-cell">
      <VisuallyHidden>{label}</VisuallyHidden>
      {editMode ? (
        <TextareaAutosize
          className={styles.textArea}
          onChange={onChange}
          onBlur={onBlur}
          value={displayValue}
          // since this component will be rendered when the user clicks
          // we can presume the user did this intentionally so autofocus is ok.
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={true}
        />
      ) : (
        <Displayer
          value={displayValue}
          onClick={() => {
            setEditMode(true);
          }}
          data-testid="editable-cell-button"
        />
      )}
    </label>
  );
};
