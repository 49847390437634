import React from 'react';
import styles from './PlanModal.module.css';
import { Plan } from './reviewInnerProps';
import { PrimaryLink } from 'system/base/Link';
import { PrimaryButton } from 'system/base/Button';

export interface PlanProps {
  accountID: string;
  plan: Plan;
  hasTrialed: boolean;
}

const Plan = ({ accountID, plan, hasTrialed }: PlanProps) => {
  const reviewsAvailableMessage = (number: number | null | undefined) => {
    if (number) {
      if (number > 1) {
        return `${number} reviews`;
      } else if (number == 1) {
        return `${number} review`;
      } else {
        return 'Unlimited reviews';
      }
    } else {
      return 'Unlimited reviews';
    }
  };

  const screeningLimit = () => {
    if (plan.screenLimit) {
      return `(${plan.screenLimit} references)`;
    }
  };

  const reviewersAvailableMessage = (
    reviewersAvailable: number | null | undefined
  ) => {
    if (!reviewersAvailable) {
      return 'Unlimited reviewers';
    }

    if (reviewersAvailable > 1) {
      return `${reviewersAvailable} reviewers`;
    } else if (reviewersAvailable == 1) {
      return `${reviewersAvailable} reviewer`;
    }
  };

  const callToActionButton = () => {
    if (plan.name == 'Trial' && hasTrialed == true) {
      return <PrimaryButton disabled={true}>Used</PrimaryButton>;
    } else if (plan.name == 'Trial') {
      return (
        <form
          action={`/plans/subscribe_to_trial?account_id=${accountID}`}
          method="post"
        >
          <PrimaryButton>Choose</PrimaryButton>
        </form>
      );
    } else {
      return <PrimaryLink href={`/plans/${plan.id}`}>Choose</PrimaryLink>;
    }
  };

  const planPrice = (price: any) => {
    if (price.amount < 1) {
      return (
        <div className={styles.price}>
          <div className={styles.priceAmount}>FREE</div>
        </div>
      );
    }
    return (
      <div className={styles.price}>
        <span className={styles.priceCurrency}>{price.currency}</span>
        <span className={styles.priceAmount}>{price.amount}</span>
      </div>
    );
  };

  return (
    <div key={plan.id} className={styles.plan} data-testid={plan.name}>
      <div className={styles.name}>{plan.name.toUpperCase()}</div>
      {planPrice(plan.price)}
      <div className={styles.billingPeriod}>
        PER {plan.billingPeriodUnit.toUpperCase()}
      </div>
      <div className={styles.choosePlan}>{callToActionButton()}</div>
      <div className={styles.numberOfReviews}>
        {reviewsAvailableMessage(plan.numberOfReviews)} {screeningLimit()}
      </div>
      <div className={styles.numberOfReviewers}>
        {reviewersAvailableMessage(plan.numberOfReviewers)}
      </div>
    </div>
  );
};

export default Plan;
