import React, { useState } from 'react';
import Select, { Props as SelectProps } from 'react-select';
import { OtherLabelField } from './OtherLabelField/OtherLabelField';
import { useDescribedBy } from 'hooks/useAccessibleName';
import Notice from 'system/elements/Notice';

export type Option = {
  value: string;
  label: string;
};

export interface DropdownSelectorProps<OptionType>
  extends Omit<SelectProps<OptionType>, 'onChange' | 'defaultValue'> {
  options: OptionType[];
  selectedOption: SelectProps<OptionType>['defaultValue'];
  setOption: React.Dispatch<React.SetStateAction<OptionType | null>>;
  name: string;
  label: string;
  missingError: string;
  otherLabel: {
    value: string | null;
    setOtherLabel: React.Dispatch<React.SetStateAction<string | null>>;
    label: string; // "Please specify the type of review",
    missingError: string; // "Please name the type of review",
  };
}

export const DropdownSelector = <OptionType extends Option>({
  options,
  selectedOption,
  setOption,
  otherLabel,
  name = 'review-type',
  label = 'Review type',
  missingError = 'Please select the type of review',
  ...otherProps
}: DropdownSelectorProps<OptionType>) => {
  // Error if dropdown is left blank
  const [errorID, describedByErrorID] = useDescribedBy();
  const [dropdownError, setDropdownError] = useState<null | string>(null);

  const validateOption = () => {
    if (!selectedOption) setDropdownError(missingError);
  };

  const clearError = () => {
    setDropdownError(null);
  };

  return (
    <>
      <div>
        <h3>{label}</h3>
        <Select<OptionType>
          mt={2}
          id={`${name}-dropdown`} // to select in testing, data-testid doesn't work
          openMenuOnFocus
          options={options}
          name={name}
          defaultValue={selectedOption}
          onChange={(option) => {
            if (option) setOption(option);
          }}
          onBlur={validateOption}
          onFocus={clearError}
          {...(!!dropdownError && describedByErrorID)}
          {...otherProps}
        />
        {!!dropdownError && (
          <Notice mt={1} type="error" id={errorID}>
            {dropdownError}
          </Notice>
        )}
      </div>
      {selectedOption?.value === 'other' && (
        <OtherLabelField
          value={otherLabel.value}
          setOtherLabel={otherLabel.setOtherLabel}
          name={`other-${name}-label`}
          label={otherLabel.label}
          missingError={otherLabel.missingError}
        />
      )}
    </>
  );
};
