import React, { useState, FunctionComponent } from 'react';
import styles from './ExportToFile.module.scss';

import { CreateReferencesExportParams } from './index';
import {
  Button,
  Heading,
  Select,
  FormControl,
  FormLabel,
  FormContainer,
} from 'components/core';

export interface ReferencesFormProps {
  createExport: (type: string, params: CreateReferencesExportParams) => void;
  selectOptions: { [key: string]: string };
  formats: { [key: string]: string };
}

const ReferencesForm: FunctionComponent<ReferencesFormProps> = (props) => {
  const { createExport, formats, selectOptions } = props;

  const [option, setOption] = useState<string>('');
  const [format, setFormat] = useState<string>('');

  const submit = (e: React.FormEvent) => {
    e.preventDefault();

    /* eslint-disable @typescript-eslint/naming-convention */

    gtag('event', 'Export References', {
      event_category: 'engagement',
      event_label: option,
    });
    gtag('event', 'Export References to Reference Manager ', {
      event_category: 'engagement',
      event_label: `${option}_${format}`,
    });

    createExport('references', {
      export_option: option,
      reference_manager: format,
    });

    /* eslint-enable @typescript-eslint/naming-convention */
  };

  const pickFormat = (e: React.FocusEvent<HTMLSelectElement>) => {
    setFormat(e.target.value);
  };

  const pickOption = (e: React.FocusEvent<HTMLSelectElement>) => {
    setOption(e.target.value);
  };

  const disableSubmit = () => {
    return format === '' || option === '';
  };

  const renderFormatSelect = () => {
    return (
      <Select
        className="form-control select required span4"
        id="citation_export_format"
        onBlur={pickFormat}
        onChange={pickFormat}
      >
        <option value="">Please select...</option>
        {Object.keys(formats).map((key: string) => (
          <option key={key} value={key}>
            {formats[key]}
          </option>
        ))}
      </Select>
    );
  };

  const specialCaseOption = (option: string) => {
    switch (option) {
      case 'Screen':
        return 'Title and abstract screening';
      default:
        return option;
    }
  };

  const renderSelectOptions = () => {
    return (
      <Select
        className="form-control select required span4"
        id="citation_export_category"
        onBlur={pickOption}
        onChange={pickOption}
      >
        <option value="">Please select...</option>
        {Object.keys(selectOptions).map((key: string) => (
          <option key={key} value={selectOptions[key]}>
            {specialCaseOption(key)}
          </option>
        ))}
      </Select>
    );
  };

  return (
    <form
      id="export-references"
      className={styles.exportForm}
      onSubmit={submit}
    >
      <div>
        <div className={styles.exportFormHeader}>
          <Heading type="h5">References</Heading>
        </div>
        <FormContainer className={styles.optionsContainer}>
          <FormControl>
            <FormLabel htmlFor="citation_export_category">Options</FormLabel>
            {renderSelectOptions()}
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="citation_export_format">Format</FormLabel>
            {renderFormatSelect()}
          </FormControl>
        </FormContainer>
      </div>
      <div className={styles.exportFormFooter}>
        <Button
          className={styles.exportFormButton}
          type="brand"
          disabled={disableSubmit()}
        >
          Prepare file
        </Button>
      </div>
    </form>
  );
};

export default ReferencesForm;
