import React from 'react';
import { ImportJob } from '..';
import styles from './ImportJobStatus.module.css';
import ProgressBar from './ProgressBar';
import Icon, { IconStyle } from 'system/base/Icon';
interface Props {
  fileName: ImportJob['file_name'];
  totalFiles: number;
  error?: string | React.ReactNode;
  uploadProgress?: number;
}

const ImportJobStatus = ({
  totalFiles,
  fileName,
  error = '',
  uploadProgress,
}: Props) => {
  return error ? (
    <div className={styles.ImportJobStatus__error}>{error}</div>
  ) : (
    <div className={styles.wrapper} data-testid="ImportJobStatus">
      <div className={styles.ImportJobStatus__success}>
        <Icon name="file" iconStyle={IconStyle.Solid} />
        <div>
          <div className={styles.ImportJobStatus__filename}>{fileName}</div>
          <div className={styles.ImportJobStatus__success__message}>
            {totalFiles} missing full texts were located in this file
          </div>
        </div>
      </div>
      <ProgressBar uploadProgress={uploadProgress || 0} />
    </div>
  );
};

export default ImportJobStatus;
