import React, { useState } from 'react';
import { SignupRole } from './Role';
import { SignupStudyLevel } from './StudyLevel';

type RoleAndStudyLevelProps = {
  objectName: string;
  defaultRole: string;
  defaultOtherRole: string;
  defaultStudyLevel: string;
  defaultOtherStudyLevel: string;
};

export const SignupRoleAndStudyLevel = ({
  objectName,
  defaultRole,
  defaultOtherRole,
  defaultStudyLevel,
  defaultOtherStudyLevel,
}: RoleAndStudyLevelProps): JSX.Element => {
  const [role, setRole] = useState<string>(defaultRole);

  return (
    <>
      <SignupRole
        defaultRole={defaultRole}
        selectedRole={role}
        defaultOtherRole={defaultOtherRole}
        objectName={objectName}
        onSelectRole={setRole}
      />
      {role === 'Student' && (
        <SignupStudyLevel
          defaultStudyLevel={defaultStudyLevel}
          defaultOtherStudyLevel={defaultOtherStudyLevel}
          objectName={objectName}
        />
      )}
    </>
  );
};
