
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "Node": [
      "CochraneReview",
      "CochraneReviewLink",
      "Review",
      "DuplicateCluster",
      "User",
      "Account",
      "Organization",
      "AuthorExport",
      "Invitation",
      "Membership",
      "Import",
      "ExtractionForm",
      "ExtractionFormRevision",
      "ExtractionStudy",
      "Note",
      "NoteAuthor",
      "Report",
      "Document",
      "Invite",
      "ReviewMembership",
      "Study",
      "Vote",
      "Tag",
      "CochraneSyncRun",
      "CochraneSyncError",
      "Comment",
      "Guideline",
      "TopicExpertInvite",
      "Topic",
      "TopicExpert",
      "ConsensusRevision",
      "Consensus",
      "ExtractedDataRevision",
      "StudyExtractor"
    ],
    "HasDuplicatesInterface": [
      "Review",
      "Import",
      "ReviewMembership"
    ],
    "ReferenceInterface": [
      "Report"
    ],
    "AccountOwner": [
      "Organization",
      "User"
    ],
    "MutationResultInterface": [
      "AddNoteToStudyPayload",
      "AddReplyToCommentPayload",
      "AddTagsToStudiesPayload",
      "AddUploadedDocumentPayload",
      "AssignOrganizationAdministratorToReviewPayload",
      "CancelInvitationPayload",
      "CancelTopicExpertInvitePayload",
      "ChangeStudyIdentifierPayload",
      "CompleteConsensusPayload",
      "CompleteExtractedDataPayload",
      "CompleteExtractionFormPayload",
      "CreateCommentPayload",
      "CreateExtractionFormPayload",
      "CreateExtractionFormRevisionPayload",
      "CreateLinkedCochraneReviewPayload",
      "CreateReviewPayload",
      "CreateTagPayload",
      "DeleteDocumentPayload",
      "DeleteTagPayload",
      "DeleteTopicExpertPayload",
      "DemoteOrganizationAdministratorToMemberPayload",
      "DenyMailingListConsentPayload",
      "EditCommentPayload",
      "EditNotePayload",
      "ExportOrganizationAuthorListPayload",
      "GenerateSignedUploadUrlPayload",
      "GrantMailingListConsentPayload",
      "InitiateCRSImportPayload",
      "InitiateRevmanWebSyncPayload",
      "InvitePersonToOrganizationPayload",
      "InvitePersonToReviewPayload",
      "InviteTopicExpertPayload",
      "MarkAsNotDuplicatePayload",
      "MarkDocumentAsPrimaryPayload",
      "MergeStudiesPayload",
      "PromoteOrganizationMemberToAdministratorPayload",
      "ReassignStudyReviewerPayload",
      "RecordStudyVotePayload",
      "RemoveNotePayload",
      "RemoveReviewerPayload",
      "RemoveTagsFromStudiesPayload",
      "ResendTopicExpertInvitePayload",
      "ResolveDiscussionPayload",
      "RevertTemplatePayload",
      "RevokeMembershipPayload",
      "SaveConsensusPayload",
      "SaveExtractedDataPayload",
      "UnassignOrganizationAdministratorFromReviewPayload",
      "UnmergeStudiesPayload"
    ]
  }
};
      export default result;
    