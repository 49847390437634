import React, { ReactNode } from 'react';
import styles from './ActivityDetailBox.module.scss';
import { Loading, Text } from 'components/core';

interface Props {
  isActive?: boolean;
  status: string;
  children?: ReactNode;
}

const ActivityDetailBox = ({
  isActive = false,
  status,
  children = null,
}: Props) => (
  <div className={styles.detail}>
    {isActive && <Loading type="primary" size="lg" />}

    <div>
      <Text size="md">{status}</Text>
      <div className={styles.explanation}>{children}</div>
    </div>
  </div>
);

export default ActivityDetailBox;
