import React from 'react';
import styles from './ListedReview.module.css';
import { Link } from 'system/base/Link';

export interface ProgressDetailsModel {
  percentage: number;
  available: number;
}

export interface GuidelineReviewProgressModel {
  screen: ProgressDetailsModel;
  select: ProgressDetailsModel;
  included: ProgressDetailsModel;
}

export interface GuidelineReviewModel {
  id: number;
  title: string;
  actions: GuidelineReviewActions;
  progress: GuidelineReviewProgressModel;
}

export interface GuidelineReviewActions {
  reviewUrl: string;
  reviewEditUrl: string;
}

export interface ListedReviewProps {
  review: GuidelineReviewModel;
  readOnly: boolean;
}

export function ListedReview({
  review: {
    id,
    title,
    progress,
    actions: { reviewUrl, reviewEditUrl },
  },
  readOnly,
}: ListedReviewProps): JSX.Element {
  const renderProgress = (
    progressDetails: ProgressDetailsModel,
    label: string,
    name: string
  ) => {
    return (
      <li data-testid={`progress-available-${name}`}>
        {progressDetails.available} to {label}
      </li>
    );
  };
  const renderProgressBars = (
    progressDetails: ProgressDetailsModel,
    name: string
  ) => {
    return (
      <div className={styles.baseBar}>
        <div
          className={styles.progressBar}
          style={{ width: `${progressDetails.percentage}%` }}
          data-testid={`progress-bar-${name}`}
        ></div>
      </div>
    );
  };

  return (
    <div
      className={styles.guidelineReview}
      key={`review_${id}`}
      data-testid={`review_${id}`}
    >
      <div className={styles.reviewDetails}>
        <h4>
          <Link href={reviewUrl}>{title}</Link>
        </h4>

        {!readOnly && (
          <div style={{ marginTop: '10px' }}>
            <Link
              data-testid={`review-${id}-edit`}
              href={reviewEditUrl}
              style={{ fontWeight: 'bold' }}
            >
              Edit
            </Link>
          </div>
        )}
      </div>
      <div className={styles.progressNumbers}>
        <ul>
          {renderProgress(progress.screen, 'screen', 'screen')}
          {renderProgress(progress.select, 'screen', 'select')}
          {renderProgress(progress.included, 'extract', 'included')}
        </ul>
      </div>
      <div className={styles.progress}>
        {renderProgressBars(progress.screen, 'screen')}
        {renderProgressBars(progress.select, 'select')}
        {renderProgressBars(progress.included, 'included')}
      </div>
    </div>
  );
}
