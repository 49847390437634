import { makeThemeSpaceScale } from './utils/make-theme';
// margin and padding
const gridUnit = 16;
const space = makeThemeSpaceScale([
  0,
  `${gridUnit / 4}px`,
  `${gridUnit / 2}px`,
  `${gridUnit}px`,
  `${gridUnit * 2}px`,
  `${gridUnit * 4}px`,
]);

export default space;
