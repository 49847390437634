export const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/;

/**
 * Validates email using a modified version of this regex here
 * https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/email#basic_validation
 * The original regex would have allowed addresses like john@here to be submitted as valid email addresses
 * the last section was modified (from * quantifier to +) to enforce having a Top Level Domain component. e.g. john@here.org
 *
 * /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/;
 *                                                                                                               modification is here ^
 */
export const validateEmail = (email: string) => {
  return !!email.match(EMAIL_REGEX);
};
