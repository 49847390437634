import React, { useState, useMemo } from 'react';
import styles from './FullTextBulkUploader.module.css';
import {
  Banner,
  BulkPDFUploader,
  ImportResults,
  ReferenceManagerHelper,
  ReferenceManagerFileExporter,
  XMLUploader,
  SinglePDFUploaderSection,
} from './components';
import { exportAndDownload } from './components/exportAndDownload';
import { createImportJob } from './components/XMLUploader/query';
import { ImportJob, importJobSuccessful } from './index';
import FullTextImportJobHeader from 'components/FullTextImportJobHeader/FullTextImportJobHeader';

interface Props {
  reviewId: string;
  lastProcessedImport: ImportJob | null;
  onCompletePath: string;
}

const FullTextBulkUploader = ({
  reviewId,
  lastProcessedImport,
  onCompletePath,
}: Props) => {
  const [fileExporterError, setFileExporterError] = useState('');
  const [importJob, setImportJob] = useState(lastProcessedImport);
  const importSuccess = useMemo(() => importJobSuccessful(importJob), [
    importJob,
  ]);
  const onSuccessfulPDFUpload = (updatedImportJob: ImportJob): void => {
    setImportJob(updatedImportJob);
  };

  return (
    <>
      <div className={styles.FullTextBulkUploader}>
        <div className={styles.bottomSpace}>
          <Banner />
        </div>
        <div className={styles.bottomSpace}>
          <ReferenceManagerFileExporter
            downloadStudies={exportAndDownload(reviewId)}
            setErrorMessage={setFileExporterError}
          />
          {fileExporterError && (
            <div className={styles.errorMessage}>{fileExporterError}</div>
          )}
        </div>
        <div className={styles.bottomSpace}>
          <ReferenceManagerHelper />
        </div>
        <div className={styles.bottomSpace}>
          <XMLUploader
            createImportJob={createImportJob}
            reviewId={reviewId}
            importJob={importJob}
            uploadedFiles={importJob?.files_uploaded || 0}
            onCreateImportJob={() => {
              setImportJob(null);
            }}
            onFinishImportJob={(job) => {
              if (job) setImportJob(job);
            }}
          />
        </div>
        {importSuccess && importJob && importJob.files_uploaded === 0 && (
          <div className={styles.bottomSpace}>
            <BulkPDFUploader
              reviewId={reviewId}
              importJob={importJob}
              onProcessed={onSuccessfulPDFUpload}
            />
          </div>
        )}
      </div>

      {importJob && importJob.files_uploaded > 0 && (
        <>
          <div className={styles.FullTextBulkUploader__jobHeader}>
            <FullTextImportJobHeader
              fileName={importJob.file_name}
              uploadedWhen={importJob.uploaded_when}
              uploadedBy={importJob.uploaded_by}
            />
          </div>
          <div className={styles.FullTextBulkUploader__results}>
            <ImportResults
              uploadedFiles={importJob.files_uploaded}
              totalFiles={importJob.found_with_full_text}
              onCompletePath={onCompletePath}
            />
            {importJob && importJob.expected_files.length > 0 && (
              <>
                <BulkPDFUploader
                  reviewId={reviewId}
                  importJob={importJob}
                  onProcessed={onSuccessfulPDFUpload}
                  text={
                    'Drag and drop the PDFs still to upload here or upload them individually'
                  }
                />
                <SinglePDFUploaderSection
                  reviewId={reviewId}
                  importJob={importJob}
                  onProcessed={onSuccessfulPDFUpload}
                />
              </>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default FullTextBulkUploader;
