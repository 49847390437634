import React from 'react';
import { StudyModel } from '../../../../containers/Guidelines/Triage/StudyModel';
import styles from 'containers/Guidelines/Triage/Triage.module.css';
import { QuestionAssignmentOptions } from 'containers/Guidelines/Triage/QuestionAssignmentOptions';

export interface QuestionAssignmentProps {
  study: StudyModel;
  questionOptions: QuestionAssignmentOptions;
  index: number;
}

export function QuestionAssignment({
  study,
  questionOptions,
  index,
}: QuestionAssignmentProps) {
  const { questions, studyCategories } = questionOptions;
  return (
    <section data-testid={`question-assignment-for-study-${study.id}-${index}`}>
      <h3>Assign To Question</h3>
      <select
        data-testid={`question-select-for-study-${study.id}`}
        data-study-id={study.id}
        data-select="question"
        name={`guideline_studies[${study.id}]review_assignments[]question_id`}
        className={styles.triageInput}
      >
        <option value="">-- Select a question --</option>
        {questions.map((question) => (
          <option key={question.id} value={question.id}>
            {question.title}
          </option>
        ))}
      </select>

      <select
        data-testid={`study-category-select-for-study-${study.id}`}
        data-study-id={study.id}
        data-select="category"
        name={`guideline_studies[${study.id}]review_assignments[]study_category`}
        className={styles.triageInput}
      >
        {studyCategories.map(({ name, label }) => (
          <option key={name} value={name}>
            {label}
          </option>
        ))}
      </select>

      <div className={styles.questionAssignmentField}>
        <label
          htmlFor={`guideline_studies_${study.id}_review_assignments_${index}_tags`}
        >
          Add tags
        </label>
        <input
          data-testid={`tags-for-study-${study.id}`}
          name={`guideline_studies[${study.id}]review_assignments[]tags`}
          id={`guideline_studies_${study.id}_review_assignments_${index}_tags`}
          type="text"
          placeholder="Enter tags"
          className={styles.triageInput}
        />
        <div className={styles.questionAssignmentFieldHint}>
          Comma-separated tags
        </div>
      </div>

      <div className={styles.questionAssignmentField}>
        <label
          htmlFor={`guideline_studies_${study.id}_review_assignments_${index}_note`}
          className={styles.triageLabel}
        >
          Add a note
        </label>
        <input
          data-testid={`note-for-study-${study.id}`}
          name={`guideline_studies[${study.id}]review_assignments[]note`}
          id={`guideline_studies_${study.id}_review_assignments_${index}_note`}
          type="text"
          placeholder="Enter notes/comments"
          className={styles.triageInput}
        />
      </div>
    </section>
  );
}
