import React from 'react';
import { NakedButton } from 'system/base/Button';

interface ReviewLike {
  assignedAdministrator: {
    name: string;
  };
}

interface AssignedAdministratorProps<T extends ReviewLike> {
  review: T;
  showAssignableAdmins?: (r: T) => void;
}

const AssignedAdministrator = <T extends ReviewLike>({
  review,
  showAssignableAdmins,
}: AssignedAdministratorProps<T>) => (
  <NakedButton
    onClick={() => showAssignableAdmins && showAssignableAdmins(review)}
    disabled={!showAssignableAdmins}
  >
    {review.assignedAdministrator ? (
      <span className="pii">{review.assignedAdministrator.name}</span>
    ) : (
      'Assign an admin'
    )}
  </NakedButton>
);

export default AssignedAdministrator;
