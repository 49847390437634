import React, { InputHTMLAttributes, RefCallback } from 'react';
import { IconList } from '../Icon';
import { Icon } from '../Icon';
import styles from './RadioButton.module.scss';

export interface Props
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'size' | 'type'> {
  size?: 'sm' | 'md' | 'lg';
  isError?: boolean;
  onSelected?: (value: string) => void;
  selectedValue?: string;
  value: string;
}

const RadioButton = (
  {
    className,
    children,
    size,
    isError,
    onSelected,
    selectedValue,
    value,
    onChange,
    ...props
  }: Props,
  ref: React.ForwardedRef<HTMLInputElement>
) => {
  const classes = [styles.RadioButtonWrapper];
  if (size) classes.push(styles[size]);
  if (isError) classes.push(styles.isError);
  if (className) classes.push(className);

  const checked = selectedValue == value;

  return (
    <div>
      <label className={classes.join(' ')}>
        <input
          type="radio"
          ref={ref as React.RefObject<HTMLInputElement>}
          className={styles.RadioButton_input}
          checked={checked}
          value={value}
          onChange={(event) => {
            onSelected && onSelected(value);
            onChange && onChange(event);
          }}
          {...props}
        />
        <div className={styles.RadioButton}>
          <Icon
            className={styles.Radio}
            icon={
              checked ? IconList.solid.faCircleDot : IconList.solid.faCircle
            }
          />
          <div className={styles.FocusRing} />
        </div>
        {children}
      </label>
    </div>
  );
};

const forwardedRadioButton = React.forwardRef<any, Omit<Props, 'onSelected'>>(
  RadioButton as any
) as React.FC<
  Omit<Props, 'onSelected'> & {
    ref?: React.RefObject<any> | RefCallback<any>;
  }
>;

export default forwardedRadioButton;
export { forwardedRadioButton as RadioButton };
