import { equals } from 'ramda';
import { BlockRecords, BlockValue, readBlockValue } from '../BlocksData';
import { TemplateModel } from '../TemplateModel';
import { Factory } from '../Blocks/factory';
import {
  CellValue,
  isTableBlockValue,
  TableData,
} from '../Blocks/Table/BaseTable/types';
import { isTableBlock } from '../Blocks/Table/types';
import { TableBlock } from '../Blocks/Table/TableBlock';
import {
  getInitialTableResolutionState,
  updateResolutionsOnTableResolutionChange,
  TableResolutionState,
} from './tables';
import { addToTableResolutionState } from './tables/updateTableResolutionState';
import {
  BlockResolutions,
  Resolutions,
  ResolutionsWithOutBlockResolutions,
} from 'containers/Extraction/types';

interface prepareViewModelArgs {
  formTemplate: TemplateModel;
  reviewerExtractionData: BlockRecords[];
  decidedValues?: BlockRecords;
  consensusDecider: boolean;
  resolveBlanks?: boolean;
}
export const prepareViewModel = ({
  formTemplate,
  reviewerExtractionData,
  decidedValues = {},
  consensusDecider,
  resolveBlanks = true,
}: prepareViewModelArgs): Readonly<Resolutions> => {
  const resolutions = compareExtractedValues(
    formTemplate,
    reviewerExtractionData,
    resolveBlanks
  );

  const finalData = consensusDecider
    ? resolveDecidedValues(resolutions, decidedValues)
    : resolutions;

  const withOutDisplay = formTemplate.blocks.filter(
    (block) => !Factory(block).displayOnly
  );

  const resolutionsStates = resolveResolutionStates(
    withOutDisplay.map((block) => block.id),
    reviewerExtractionData,
    decidedValues,
    resolveBlanks
  );
  return {
    ...finalData,
    blockResolutions: resolutionsStates,
  } as Readonly<Resolutions>;
};

function setUnresolved(blockID: string, unresolvedIDs: Set<string>) {
  return {
    unresolvedIDs: unresolvedIDs.add(blockID),
  };
}

function setResolved(blockID: string, unresolvedIDs: Set<string>) {
  unresolvedIDs.delete(blockID);
  return { unresolvedIDs };
}

function isChangedFromLastExtraction(
  blockId: string,
  records: Array<BlockRecords>
): boolean {
  return records.some((rec) => {
    return rec[blockId]?.changed;
  });
}

type flattenedTable = Record<string, CellValue>;

// Turns this
// [
//   {
//     rowId: 'row1',
//     columns: [
//       { columnId: 'col1', value: 'foo' },
//       { columnId: 'col2', value: 'bar' },
//     ],
//   },
//   {
//     rowId: 'row2',
//     columns: [
//       { columnId: 'col1', value: 'baz' },
//       { columnId: 'col2', value: 'qux' },
//     ],
//   },
// ];
// into
// {
//   'row1:col1': { value: 'foo' },
//   'row1:col2': { value: 'bar' },
//   'row2:col1': { value: 'baz' },
//   'row2:col2': { value: 'qux' },
// };
const flattenTable = (table: TableData): flattenedTable => {
  return table.reduce((acc, row) => {
    const { rowID, columns } = row;
    const flatColumns = columns.reduce(
      (cols, cell) => ({
        ...cols,
        [`${rowID}:${cell.columnID}`]: cell.value,
      }),
      {}
    );
    return {
      ...acc,
      ...flatColumns,
    };
  }, {});
};

const isAllBlank = (records: flattenedTable): boolean => {
  const values = Object.values(records);
  return (
    values.length > 0 && values.every((value) => value === null || value === '')
  );
};

export const isTableAllBlank = (table: TableData) =>
  isAllBlank(flattenTable(table));

const valuesForId = (extractorData: Array<BlockRecords>, id: string) =>
  extractorData.map((datum) => readBlockValue(datum as BlockRecords, id));

export function resolveResolutionStates(
  formTemplateIds: Array<string>,
  extractorData: Array<BlockRecords>,
  consensusDeciderData: BlockRecords,
  resolveBlanks: boolean
): BlockResolutions {
  return formTemplateIds.reduce((acc, id): BlockResolutions => {
    const extractorValues = valuesForId(extractorData, id);
    const [firstExtractorValue, ...restOfExtractorValues] = extractorValues;

    const consensusDeciderValue = consensusDeciderData[id]?.value;

    const isExtractorValuesTheSame =
      firstExtractorValue &&
      restOfExtractorValues.every(
        (value) => value && equals(firstExtractorValue, value)
      );

    const hasChangedFromLastExtraction = isChangedFromLastExtraction(
      id,
      extractorData
    );

    const isExtractorValuesMatchConsensusValue = extractorValues.every(
      (value) => equals(consensusDeciderValue, value)
    );

    // Yes, I could squeeze these statements into a more compact style
    // but the verbose way made it easier for me to deal with
    const allExtractorsBlank =
      extractorValues.length > 0 &&
      extractorValues.every((val) => val === '' || val === null);

    if (allExtractorsBlank && resolveBlanks) {
      return { ...acc, [id]: 'resolvedByConsensusReviewer' };
    }

    const hasNoConsensusButMatching =
      !consensusDeciderValue && isExtractorValuesTheSame;

    if (hasNoConsensusButMatching) {
      return { ...acc, [id]: 'resolvedByConsensusReviewer' };
    }

    const isSupersededButMatching =
      hasChangedFromLastExtraction &&
      consensusDeciderValue &&
      isExtractorValuesMatchConsensusValue;

    if (isSupersededButMatching) {
      return { ...acc, [id]: 'resolvedByConsensusReviewer' };
    }

    if (hasChangedFromLastExtraction) {
      return { ...acc, [id]: 'supersededByExtractor' };
    }

    if (consensusDeciderValue) {
      return { ...acc, [id]: 'resolvedByConsensusReviewer' };
    }

    if (isExtractorValuesTheSame) {
      return { ...acc, [id]: 'resolvedByConsensusReviewer' };
    }

    return { ...acc, [id]: 'unresolved' };
  }, {} as BlockResolutions);
}

function resolveTableState(
  blockValues: BlockValue[],
  block: TableBlock
): TableResolutionState {
  const reviewerTables = blockValues as TableData[];

  const areAllTablesBlank = reviewerTables.every((table) => {
    return table === null || isTableAllBlank(table);
  });

  const isAllTablesNonBlank = reviewerTables.length > 0 && !areAllTablesBlank;

  const initialTableResolutionState = getInitialTableResolutionState({
    reviewerTables,
    rowIDs: block.rows.map(({ id }) => id),
    columnIDs: block.columns.map(({ id }) => id),
  });

  if (isAllTablesNonBlank) return initialTableResolutionState;

  const emptyButResolved: TableResolutionState = {
    resolvedCellData: [],
    unresolvedCellIDs: [],
  };

  if (areAllTablesBlank) return emptyButResolved;

  const oneTableUnfilled = blockValues.some((value) => value === null);
  const onlyOneTable = blockValues.length === 1;
  const oneReviewerWithEmptyTable = onlyOneTable && oneTableUnfilled;

  if (oneReviewerWithEmptyTable) return emptyButResolved;

  return initialTableResolutionState;
}

/**
 * Returns ComparisonResolutions object which stores resolved values and
 * ids of unresolved blocks.
 *
 * @param data - values provided by different reviewers for a given block
 */
export function compareExtractedValues(
  formTemplate: TemplateModel,
  data: Array<BlockRecords>,
  resolveBlanks: boolean
): ResolutionsWithOutBlockResolutions {
  const emptyState: ResolutionsWithOutBlockResolutions = {
    resolvedData: {},
    unresolvedIDs: new Set<string>(),
    unresolvedTableCells: {},
  };

  return formTemplate.blocks.reduce(
    (acc, block): ResolutionsWithOutBlockResolutions => {
      // This block is for display only and has no values, so just ignore
      if (Factory(block).displayOnly) return acc;

      const { resolvedData, unresolvedIDs, unresolvedTableCells } = acc;

      /**
       * Reviewers data for the matching formTemplate block
       */
      const blockValues = data.map((datum) =>
        readBlockValue(datum as BlockRecords, block.id)
      );

      if (isTableBlock(block)) {
        return updateResolutionsOnTableResolutionChange(
          {
            resolvedData,
            unresolvedIDs,
            unresolvedTableCells,
            blockResolutions: {},
          },
          block.id,
          resolveTableState(blockValues, block)
        );
      }
      /**
       * The following code adds/replaces a block into resolvedData if:
       * (a) none of the block's records is an empty value AND
       * (b) all of the block's records have the same value.
       * Otherwise, it adds the block IDto unresolvedIDs.
       */
      const [reference, ...tests] = blockValues;

      /**
       * This automatically is false when any value is not the same as the first
       * value, hence it will be false if any value is empty as well.
       *
       * @remarks
       * equals matches identical object values (i.e. checkboxes values) & so is more applicable
       * than strict equality, i.e. test === reference
       */
      const isAllAnswersTheSame = tests.every((test) =>
        equals(test, reference)
      );

      const isAllAnswersBlank = blockValues.every(
        (value) => value === '' || value === null
      );

      const shouldSetBlockValue =
        (reference && isAllAnswersTheSame) ||
        (isAllAnswersBlank && resolveBlanks);

      if (shouldSetBlockValue) {
        return {
          ...acc,
          resolvedData: {
            ...resolvedData,
            [block.id]: { ...resolvedData[block.id], value: reference },
          },
        };
      }

      const unresolved = setUnresolved(block.id, unresolvedIDs);

      return {
        ...acc,
        ...unresolved,
      };
    },
    emptyState
  );
}

/**
 * Adds a resolved (i.e. decided) block to the resolvedData object &
 * removes its block id from unresolvedIDs list.
 */
export function resolveDecidedValues(
  resolutions: ResolutionsWithOutBlockResolutions,
  decidedRecords: BlockRecords
): ResolutionsWithOutBlockResolutions {
  const startingState: ResolutionsWithOutBlockResolutions = {
    unresolvedIDs: new Set(resolutions.unresolvedIDs),
    resolvedData: resolutions.resolvedData,
    unresolvedTableCells: resolutions.unresolvedTableCells,
  };

  return Object.keys(decidedRecords).reduce(
    (
      acc: ResolutionsWithOutBlockResolutions,
      decidedID: string
    ): ResolutionsWithOutBlockResolutions => {
      const { value: decidedValue, comment: decidedComment } = decidedRecords[
        decidedID
      ];

      if (decidedValue === null) return acc;

      const { resolvedData, unresolvedIDs, unresolvedTableCells } = acc;

      if (isTableBlockValue(decidedValue)) {
        const resolutionBlock = resolvedData[decidedID] ?? { value: [] };

        const originalTableState: TableResolutionState = {
          unresolvedCellIDs: unresolvedTableCells[decidedID] ?? [],
          resolvedCellData: isTableBlockValue(resolutionBlock.value)
            ? resolutionBlock.value
            : [],
        };

        /**
         * Removes decided cells from unresolvedTableCells
         */
        const {
          unresolvedCellIDs: updatedUnresolvedCellIDs,
          resolvedCellData: updatedResolvedCellData,
        }: TableResolutionState = decidedValue.reduce(
          (tableStateAtPriorRow, { rowID, rowIndex, columns }) =>
            columns.reduce(
              (tableStateAtPriorColumn, cellData) =>
                addToTableResolutionState(tableStateAtPriorColumn)({
                  rowID,
                  columnID: cellData.columnID,
                  rowIndex,
                })(cellData),
              tableStateAtPriorRow
            ),
          originalTableState
        );

        const newResolvedData = {
          ...resolvedData,
          [decidedID]: {
            ...resolvedData[decidedID],
            value: updatedResolvedCellData,
            /**
             * Updates to new comment if it exists
             */
            ...(decidedComment !== null &&
              decidedComment !== undefined && { comment: decidedComment }),
          },
        };

        const newUnresolvedTableCells = {
          ...unresolvedTableCells,
          [decidedID]: updatedUnresolvedCellIDs,
        };

        const isFullyResolved = updatedUnresolvedCellIDs.length === 0;

        if (!isFullyResolved) {
          return {
            ...acc,
            resolvedData: newResolvedData,
            unresolvedTableCells: newUnresolvedTableCells,
          };
        }

        return {
          ...acc,
          resolvedData: newResolvedData,
          unresolvedTableCells: newUnresolvedTableCells,
          ...setResolved(decidedID, unresolvedIDs),
        };
      }

      const newComment =
        (decidedComment !== null &&
          decidedComment !== undefined && { comment: decidedComment }) ||
        null; // If it's not set it won't merge

      const newResolvedData: BlockRecords = {
        ...resolvedData,
        [decidedID]: {
          value: decidedValue,
          ...newComment,
        },
      };

      return {
        ...resolutions,
        resolvedData: newResolvedData,
        ...setResolved(decidedID, unresolvedIDs),
      };
    },
    startingState
  );
}
