import React from 'react';
import displayName from './displayName';

export interface AttributionProps {
  name: string;
  timeAgo: string;
  action: string;
  edited: boolean;
}

export function Attribution({
  name,
  timeAgo,
  action,
  edited,
}: AttributionProps): JSX.Element {
  return (
    <span data-testid="attribution">
      {displayName(name)} {action} {timeAgo}
      {edited && ' - EDITED'}.
    </span>
  );
}

export default Attribution;
