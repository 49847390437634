import React from 'react';
import {
  Label,
  LabelText,
  TextInput,
  DebouncedTextInput,
} from '../../shared/BlockWidgets';
import { MultilinedText } from '../../shared/MultilinedText';
import { CommentButton } from '../../shared/Comment';
import styles from './TextField.module.css';
import { TextFieldBlock } from 'concepts/Extraction/Blocks/TextField/TextFieldBlock';
import { useDescribedBy, useLabelledBy } from 'hooks/useAccessibleName';

export type ReadonlyTextFieldProps = {
  block: TextFieldBlock;
};

export type EditableTextFieldProps = ReadonlyTextFieldProps & {
  editable: true;
  value: string | null;
  comments: string | null;
  onChange: (newValue: string) => void;
  onChangeComments: (newComments: string) => void;
};

export type TextFieldProps = ReadonlyTextFieldProps | EditableTextFieldProps;

export default function TextField(props: TextFieldProps): JSX.Element {
  const [labelID, labelledBy] = useLabelledBy();
  const [descriptionID, describedBy] = useDescribedBy();

  function renderCommentsButton(): JSX.Element | null {
    if (!('editable' in props)) {
      return null;
    }

    return (
      <CommentButton
        comments={props.comments}
        blockLabel={props.block.label}
        onChangeComments={props.onChangeComments}
      />
    );
  }

  return (
    <div>
      <Label htmlFor={props.block.id}>
        <div className={styles.labelOuter}>
          <div>
            <LabelText id={labelID}>{props.block.label}</LabelText>
            {props.block.description !== '' && (
              <div id={descriptionID} className={styles.description}>
                <MultilinedText>{props.block.description}</MultilinedText>
              </div>
            )}
          </div>
          {renderCommentsButton()}
        </div>
        {'editable' in props ? (
          <DebouncedTextInput
            id={props.block.id}
            value={props.value || ''}
            onChange={(e) => props.onChange(e.target.value)}
            {...labelledBy}
            {...describedBy}
          />
        ) : (
          <TextInput disabled {...labelledBy} {...describedBy} />
        )}
      </Label>
    </div>
  );
}
