import React from 'react';
import { gql, useQuery } from '@apollo/client';

import TitleAndAbstract from './TitleAndAbstract';
import FullTextReview from './FullTextReview';
import Extraction from './Extraction';
import ImportStudiesNotification from './ImportStudiesNotification';
import ImportReferencesSummary from './ImportReferencesSummary';
import ReviewLinkContext from './ReviewLinkContext';

import Spinner from 'system/elements/Spinner';
import Errors from 'components/Errors';
import { errorMessagesFrom } from 'util/errorMessages';
import { QueryProvider } from 'query/QueryProvider';
import { Stack } from 'components/layout';

export type Stage = 'screen' | 'select' | 'included';

export interface Permissions {
  consensusRequired: boolean;
}

export interface Statistics {
  contribution: number;
  complete: number;
  partiallyComplete: number;
  available: number;
  notStarted: number;
  consensusRequired: number;
}

interface ReviewHomeProps {
  importsLink: string;
  newImportLink: string;
  reviewId: string;
  reviewLink: string;
  permissions: Permissions;
  reviewerName: string;
  stage: 'screen' | 'select' | 'included';
  isAutoExclusionEnabled: boolean;
  isAutoExclusionSupported: boolean;
}

const GET_COUNT_QUERY = gql`
  query getCounts($reviewId: ID!) {
    review: node(id: $reviewId) {
      ... on Review {
        categoryCounts {
          screen
          select
          included
          excluded
          irrelevant
          excluded
        }
        statistics {
          screen {
            ...statsFragment
          }
          select {
            ...statsFragment
          }
          included {
            ...statsFragment
          }
        }
      }
    }
  }

  fragment statsFragment on DetailedStatistics {
    complete
    partiallyComplete
    consensusRequired
    available
    notStarted
    contribution
  }
`;

const Home = ({
  reviewId,
  reviewLink,
  reviewerName,
  importsLink,
  newImportLink,
  isAutoExclusionEnabled,
  isAutoExclusionSupported,
}: ReviewHomeProps) => {
  const { data, error, loading } = useQuery(GET_COUNT_QUERY, {
    variables: { reviewId },
  });

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <Errors errors={errorMessagesFrom(error)} />;
  }

  const {
    review: { categoryCounts, statistics },
  } = data;

  const { irrelevant, screen, excluded, select, included } = categoryCounts;

  const firstOpenView = () => {
    if (screen > 0) {
      return 'title_and_abstract';
    }

    if (select > 0) {
      return 'full_text_review';
    }

    if (included > 0) {
      return 'extraction';
    }

    return 'import_summary';
  };

  return (
    <QueryProvider>
      <ReviewLinkContext.Provider value={reviewLink}>
        <Stack spacing={5}>
          <ImportStudiesNotification reviewId={reviewId} />

          <ImportReferencesSummary
            historyLink={importsLink}
            newImportLink={newImportLink}
            isOpen={firstOpenView() === 'import_summary'}
            reviewId={reviewId}
            isAutoExclusionEnabled={isAutoExclusionEnabled}
            isAutoExclusionSupported={isAutoExclusionSupported}
          />

          <TitleAndAbstract
            isOpen={firstOpenView() === 'title_and_abstract'}
            reviewerName={reviewerName}
            irrelevant={irrelevant}
            stats={statistics.screen}
          />

          <FullTextReview
            isOpen={firstOpenView() === 'full_text_review'}
            reviewerName={reviewerName}
            excluded={excluded}
            stats={statistics.select}
          />

          <Extraction
            isOpen={firstOpenView() === 'extraction'}
            reviewerName={reviewerName}
            complete={statistics.included.complete}
            stats={statistics.included}
          />
        </Stack>
      </ReviewLinkContext.Provider>
    </QueryProvider>
  );
};

export default Home;
