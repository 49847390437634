import React from 'react';
import { TopicModel } from 'containers/Guidelines/Triage/TopicModel';
import { StudyModel } from 'containers/Guidelines/Triage/StudyModel';

export interface TopicAssignmentProps {
  topics: TopicModel[];
  study: StudyModel;
  onInteract: (topicId: number) => void;
}

export function TopicAssignment({
  topics,
  study,
  onInteract,
}: TopicAssignmentProps): JSX.Element {
  return (
    <section data-testid={`topic-assignments-for-study-${study.id}`}>
      <h3>Assign To Topic</h3>
      <ul>
        {topics.map((topic) => {
          const id = `guideline_studies_${study.id}_topics_${topic.id}`;

          return (
            <li key={topic.id}>
              <input
                type="checkbox"
                name={`guideline_studies[${study.id}]topic_ids[]`}
                value={topic.id}
                id={id}
                data-testid={id}
                data-study-id={study.id}
                onClick={() => onInteract(topic.id)}
              />
              <label htmlFor={id}>{topic.name}</label>
            </li>
          );
        })}
      </ul>
    </section>
  );
}
