import React from 'react';
import { VisuallyHidden } from '@reach/visually-hidden';
import { Menu, MenuItem } from '../Widgets/Menu';
import { AddIcon } from 'concepts/Extraction/Icons/Add';

const textIcon = (
  <svg
    role="img"
    fill="none"
    height="17"
    viewBox="0 0 29 17"
    width="29"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m.78 16v-1.012l2.068-.484 5.346-14.014001h1.584l5.016 14.036001 2.024.462v1.012h-6.468v-1.012l1.628-.374-1.056-2.97h-5.39l-1.144 2.904 1.936.44v1.012zm5.236-5.654h4.422l-2.156-6.006zm11.5235 2.926c0-2.5373 2.2514-3.806 6.754-3.806v-.77c0-1.276-.1833-2.08267-.55-2.42-.352-.352-.9093-.528-1.672-.528l-2.574 2.2c-.352-.22-.6526-.528-.902-.924-.2346-.41067-.3593-.84333-.374-1.298.616-.33733 1.2907-.594 2.024-.77.7334-.19067 1.4594-.286 2.178-.286 3.0067 0 4.51 1.26133 4.51 3.784v6.116h1.716v.726c-.3373.3227-.7113.572-1.122.748-.4106.1907-.814.286-1.21.286-.8653 0-1.5033-.374-1.914-1.122-.9826.748-2.09 1.122-3.322 1.122-.968 0-1.804-.2567-2.508-.77-.6893-.5133-1.034-1.276-1.034-2.288zm2.772-.088c0 .5427.176.946.528 1.21.3667.264.8287.396 1.386.396.8067 0 1.496-.2493 2.068-.748v-3.454c-1.32 0-2.3173.242-2.992.726-.66.4693-.99 1.0927-.99 1.87z"
      fill="#000"
    />
  </svg>
);

const multipleChoiceIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    fill="none"
    viewBox="0 0 48 48"
  >
    <path
      fill="#fff"
      d="M0 5c0-2.761 2.239-5 5-5h38c2.761 0 5 2.239 5 5v38c0 2.761-2.239 5-5 5H5c-2.761 0-5-2.239-5-5V5z"
    />
    <path stroke="#A7A7A7" strokeWidth="3" d="M21 12h19M21 24h19M21 36h19" />
    <path
      fill="#3F557B"
      d="M12 7c-2.76 0-5 2.24-5 5s2.24 5 5 5 5-2.24 5-5-2.24-5-5-5z"
    />
    <path
      fill="#fff"
      d="M12.004 9c-1.656 0-3 1.344-3 3s1.344 3 3 3 3-1.344 3-3-1.344-3-3-3z"
    />
    <path
      fill="#3F557B"
      d="M12.004 10c-1.104 0-2 .896-2 2s.896 2 2 2 2-.896 2-2-.896-2-2-2z"
    />
    <circle cx="12" cy="36" r="5" fill="#C4C4C4" />
    <circle cx="12" cy="24" r="5" fill="#C4C4C4" />
    <path
      fill="#fff"
      d="M12.004 33c-1.656 0-3 1.344-3 3s1.344 3 3 3 3-1.344 3-3-1.344-3-3-3zm0-12c-1.656 0-3 1.344-3 3s1.344 3 3 3 3-1.344 3-3-1.344-3-3-3z"
    />
  </svg>
);

const checkboxesIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    fill="none"
    viewBox="0 0 48 48"
  >
    <path
      fill="#fff"
      d="M0 5c0-2.761 2.239-5 5-5h38c2.761 0 5 2.239 5 5v38c0 2.761-2.239 5-5 5H5c-2.761 0-5-2.239-5-5V5z"
    />
    <path
      stroke="#A7A7A7"
      strokeWidth="3"
      d="M21 12.5h19m-19 12h19m-19 12h19"
    />
    <path
      fill="#3F557B"
      fillRule="evenodd"
      d="M9 8c-1.105 0-2 .895-2 2v5c0 1.105.895 2 2 2h5c1.105 0 2-.895 2-2v-5c0-1.105-.895-2-2-2H9zm-.854 4.851 1.795 1.795c.195.195.515.195.705 0l3.795-3.795c.195-.195.195-.51 0-.705-.195-.195-.51-.195-.705 0l-3.44 3.44-1.445-1.44c-.195-.195-.51-.195-.705 0-.093.094-.146.22-.146.353 0 .132.053.259.146.352zM9 20c-1.105 0-2 .895-2 2v5c0 1.105.895 2 2 2h5c1.105 0 2-.895 2-2v-5c0-1.105-.895-2-2-2H9zm-.854 4.851 1.795 1.795c.195.195.515.195.705 0l3.795-3.795c.195-.195.195-.51 0-.705-.195-.195-.51-.195-.705 0l-3.44 3.44-1.445-1.44c-.195-.195-.51-.195-.705 0-.093.094-.146.22-.146.353 0 .132.053.259.146.352z"
      clipRule="evenodd"
    />
    <rect width="9" height="9" x="7" y="32" fill="#C4C4C4" rx="2" />
  </svg>
);

const tableIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="48"
    fill="none"
    viewBox="0 0 48 48"
  >
    <path
      fill="#fff"
      d="M0 5c0-2.761 2.239-5 5-5h38c2.761 0 5 2.239 5 5v38c0 2.761-2.239 5-5 5H5c-2.761 0-5-2.239-5-5V5z"
    />
    <path
      fill="#A7A7A7"
      fillRule="evenodd"
      d="M12 14c0-1.105.895-2 2-2h20c1.105 0 2 .895 2 2v20c0 1.105-.895 2-2 2H14c-1.105 0-2-.895-2-2V14zm2 2h9v8h-9v-8zm9 10h-9v8h9v-8zm2-10h9v8h-9v-8zm9 10h-9v8h9v-8z"
      clipRule="evenodd"
    />
  </svg>
);

const headingIcon = (
  <svg
    role="img"
    fill="none"
    width="26"
    height="25"
    viewBox="0 0 26 25"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.495 10.8047V9.96469L1.875 9.63469V1.47469L0.495 1.14469V0.304687H5.7V1.14469L4.32 1.47469V4.92469H8.055V1.47469L6.675 1.14469V0.304687H11.88V1.14469L10.5 1.47469V9.63469L11.88 9.96469V10.8047H6.675V9.96469L8.055 9.63469V6.07969H4.32V9.63469L5.7 9.96469V10.8047H0.495ZM12.6639 4.32569L14.9849 3.10469H15.9419V10.0457L16.8769 10.2217V10.8047H12.8949V10.2217L14.1599 10.0017V4.32569L12.8399 4.88669L12.6639 4.32569Z"
      fill="black"
    />
    <line x1="1" y1="16.3047" x2="26" y2="16.3047" stroke="#A7A7A7" />
    <line x1="1" y1="20.3047" x2="19" y2="20.3047" stroke="#A7A7A7" />
    <line x1="1" y1="24.3047" x2="12" y2="24.3047" stroke="#A7A7A7" />
  </svg>
);

const subheadingIcon = (
  <svg
    width="26"
    height="29"
    viewBox="0 0 26 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.495 14.8047V13.9647L1.875 13.6347V5.47469L0.495 5.14469V4.30469H5.7V5.14469L4.32 5.47469V8.92469H8.055V5.47469L6.675 5.14469V4.30469H11.88V5.14469L10.5 5.47469V13.6347L11.88 13.9647V14.8047H6.675V13.9647L8.055 13.6347V10.0797H4.32V13.6347L5.7 13.9647V14.8047H0.495ZM12.7189 14.8047V14.0347C12.8289 13.9247 12.9609 13.7854 13.1149 13.6167C13.2763 13.4407 13.5183 13.151 13.8409 12.7477C14.1709 12.3444 14.4606 11.9484 14.7099 11.5597C14.9593 11.171 15.1829 10.7347 15.3809 10.2507C15.5789 9.75935 15.6779 9.31935 15.6779 8.93069C15.6779 8.56402 15.5973 8.24869 15.4359 7.98469C15.2746 7.72069 15.0656 7.58869 14.8089 7.58869L13.5109 8.88669C13.1223 8.52735 12.9133 8.06169 12.8839 7.48969C13.5953 7.12302 14.3726 6.93969 15.2159 6.93969C15.8979 6.93969 16.4516 7.09002 16.8769 7.39069C17.3023 7.69135 17.5149 8.15335 17.5149 8.77669C17.5149 10.1334 16.5506 11.677 14.6219 13.4077H16.9209L17.3499 12.5497H17.9439L17.6359 14.8047H12.7189Z"
      fill="black"
    />
    <line x1="1" y1="20.3047" x2="26" y2="20.3047" stroke="#A7A7A7" />
    <line x1="1" y1="24.3047" x2="19" y2="24.3047" stroke="#A7A7A7" />
    <line x1="1" y1="28.3047" x2="12" y2="28.3047" stroke="#A7A7A7" />
  </svg>
);

export interface AddMenuProps {
  isCustomTables2Enabled?: boolean;
  onAdd: (
    blockType:
      | 'TextField'
      | 'MultipleChoice'
      | 'Checkboxes'
      | 'Heading'
      | 'Subheading'
      | 'Table'
  ) => void;
}

export default function AddMenu({ onAdd }: AddMenuProps): JSX.Element {
  return (
    <Menu
      buttonTitle={
        <>
          <VisuallyHidden>Add</VisuallyHidden>{' '}
          <span aria-hidden>
            <AddIcon />
          </span>
        </>
      }
      itemsHeading="Add new item"
      position="right"
    >
      <MenuItem
        title="Heading"
        description="Add a heading to create smaller chunks"
        icon={headingIcon}
        onSelect={() => onAdd('Heading')}
      />
      <MenuItem
        title="Subheading"
        description="Add a sub heading to break up the work"
        icon={subheadingIcon}
        onSelect={() => onAdd('Subheading')}
      />
      <MenuItem
        title="Text field"
        description="Good for short and long answers"
        icon={textIcon}
        onSelect={() => onAdd('TextField')}
      />
      <MenuItem
        title="Single choice"
        description="Pick one item from a list"
        icon={multipleChoiceIcon}
        onSelect={() => onAdd('MultipleChoice')}
      />
      <MenuItem
        title="Checkboxes"
        description="Pick one or more items from a list"
        icon={checkboxesIcon}
        onSelect={() => onAdd('Checkboxes')}
      />
      <MenuItem
        title="Table"
        description="Extract data into a table format"
        icon={tableIcon}
        onSelect={() => onAdd('Table')}
      />
    </Menu>
  );
}
