import React from 'react';
import { TextFieldBlock } from '../TextFieldBlock';
import { InstructionsToExtractors } from '../../shared/InstructionsToExtractors';

export interface TextFieldEditorSettingsProps {
  block: TextFieldBlock;
  onChange: (updatedBlock: TextFieldBlock) => void;
}

export function TextFieldEditorSettings({
  block,
  onChange,
}: TextFieldEditorSettingsProps): JSX.Element {
  return (
    <>
      <InstructionsToExtractors block={block} onChange={onChange} />
      <hr />
    </>
  );
}
