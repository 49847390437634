import React, { SyntheticEvent, useState } from 'react';
import { useForm } from 'react-hook-form';
import styles from './SingleSignOnForms.module.scss';

import { CovidenceLogo } from './CovidenceLogo';
import {
  Button,
  Checkbox,
  Form,
  FormControl,
  FormLabel,
  Input,
  Link,
  Select,
} from 'components/core';
import { Stack } from 'components/layout';
import { getCsrfToken } from 'util/authenticityToken';
import { SIGN_IN_SAML_EDIT } from 'query/routes';

type NameData = any;

type SubmitFn = (data: NameData, event: any) => void;

type PageProps = {
  firstName?: string;
  lastName?: string;
};

type FormProps = {
  firstName?: string;
  lastName?: string;
  onSubmit?: SubmitFn;
};

const noop = () => '';

export const SamlNameForm = ({ firstName, lastName, onSubmit }: FormProps) => {
  const [showOtherField, setShowOtherField] = useState(false);

  const { register, handleSubmit, formState } = useForm({
    mode: 'onSubmit',
  });

  return (
    <div className={styles.wrapper}>
      <Form
        method="POST"
        action={SIGN_IN_SAML_EDIT}
        className={styles.form}
        id="myForm"
        onSubmit={handleSubmit(onSubmit || noop)}
      >
        <input name="authenticity_token" type="hidden" value={getCsrfToken()} />
        <Stack spacing={6}>
          <CovidenceLogo />
          <FormControl error={formState.errors.first_name && 'Required'}>
            <FormLabel className={styles.label}>First name</FormLabel>
            <Input
              size="md"
              defaultValue={firstName || ''}
              {...register('first_name', { required: 'Required' })}
            />
          </FormControl>
          <FormControl error={formState.errors.last_name && 'Required'}>
            <FormLabel className={styles.label}>Last name</FormLabel>
            <Input
              size="md"
              defaultValue={lastName || ''}
              {...register('last_name', { required: 'Required' })}
            />
          </FormControl>
          <FormControl error={formState.errors.role && 'Required'}>
            <FormLabel className={styles.label}>
              What role best describes you?
            </FormLabel>
            <Select
              size="md"
              {...register('role', { required: true })}
              onChange={(event) => {
                setShowOtherField(event.target.value === 'other');
              }}
            >
              <option></option>
              <option value={'student'}>Student</option>
              <option value={'librarian'}>Librarian</option>
              <option value={'researcher'}>Researcher</option>
              <option value={'other'}>Other</option>
            </Select>
          </FormControl>
          {showOtherField && (
            <FormControl error={formState.errors.other_role && 'Required'}>
              <FormLabel className={styles.label}>
                Please specify your role
              </FormLabel>
              <Input
                size="md"
                {...register('other_role', { required: true })}
              />
            </FormControl>
          )}
          <Stack spacing={2}>
            <FormControl error={formState.errors.terms && 'Required'}>
              <Checkbox size="sm" {...register('terms', { required: true })}>
                {"I agree to Covidence's"}
                <Link
                  target="_blank"
                  to="https://www.covidence.org/terms/"
                  className={styles.link}
                  variant="primary"
                >
                  Terms and Conditions
                </Link>
              </Checkbox>
            </FormControl>
            <FormControl error={formState.errors.privacy && 'Required'}>
              <Checkbox size="sm" {...register('privacy', { required: true })}>
                {"I agree to Covidence's"}
                <Link
                  target="_blank"
                  to="https://www.covidence.org/privacy/"
                  className={styles.link}
                  variant="primary"
                >
                  Privacy Policy
                </Link>
              </Checkbox>
            </FormControl>
            <Checkbox size="sm" {...register('news')}>
              {
                'Send me occasional emails about product updates, training and best practice'
              }
            </Checkbox>
          </Stack>
          <Button
            size="md"
            className={styles.submitButton}
            type="brand"
            htmlType="submit"
          >
            Create account
          </Button>
        </Stack>
      </Form>
    </div>
  );
};

export const SamlNameFormPage = (props: PageProps) => {
  const submit = (
    _data: any,
    event: SyntheticEvent<HTMLFormElement, SubmitEvent>
  ) => {
    const formElement = event.target as HTMLFormElement;

    formElement.submit();
  };

  return (
    <div className={styles.formContainer}>
      <Stack spacing={6}>
        <SamlNameForm onSubmit={submit} {...props} />
      </Stack>
    </div>
  );
};
