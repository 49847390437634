import React from 'react';
import { Link } from 'system/base/Link';

interface Props {
  address: string;
}

const EmailAddress = ({ address }: Props) => (
  <Link href={`mailto:${address}`} className="pii">
    {address}
  </Link>
);

export default EmailAddress;
