import {
  withRequestRetry,
  defaultFetchOptions,
  _handleJsonResponse,
  RequestErrorHandler,
  CheckExistingExtractionDataResponse,
} from './DataExtraction';
import {
  CHECK_OUTCOME_EXTRACTED_DATA_ROUTE,
  CREATE_OUTCOME_ROUTE,
  UPDATE_OUTCOME_ROUTE,
  DESTROY_OUTCOME_ROUTE,
  tokenizeRoute,
} from 'query/routes';
import { Timepoint, Outcome } from 'types/DataExtraction';

export interface OutcomeCreatedResponse {
  id: string;
  name: string;
  template_field_id: string;
  timepoints: Timepoint[];
  template_timepoints: Timepoint[];
}

export const createOutcome = async (
  reviewStudyId: string,
  templateOutcomeId: string,
  onError?: RequestErrorHandler
) => {
  return withRequestRetry(
    () => _createOutcome(reviewStudyId, templateOutcomeId),
    onError
  );
};

const _createOutcome = async (
  reviewStudyId: string,
  templateOutcomeId: string
) => {
  const url = tokenizeRoute(CREATE_OUTCOME_ROUTE, {
    review_study_id: reviewStudyId,
  });

  return fetch(url.toString(), {
    method: 'POST',
    body: JSON.stringify({ template_outcome_id: templateOutcomeId }),
    ...defaultFetchOptions(),
  }).then((response) => {
    return _handleJsonResponse(response);
  });
};

export const updateOutcome = async (
  outcome: Outcome,
  onError?: RequestErrorHandler
) => {
  return withRequestRetry(() => _updateOutcome(outcome), onError);
};

const _updateOutcome = async (outcome: Outcome) => {
  const url = tokenizeRoute(UPDATE_OUTCOME_ROUTE, {
    outcome_id: outcome.id,
  });

  const permittedOutcomeAttributes = {
    direction: outcome.direction,
    range: outcome.range,
    scale: outcome.scale,
    unit_of_measure: outcome.unit_of_measure,
    data_value: outcome.data_value,
    outcome_group_id: outcome.outcome_group_id,
    notes: outcome.notes,
    reported_name: outcome.reported_name,
  };

  return fetch(url.toString(), {
    method: 'PUT',
    body: JSON.stringify(permittedOutcomeAttributes),
    headers: { 'Content-Type': 'application/json' },
  }).then((response) => {
    return _handleJsonResponse(response);
  });
};

export const destroyOutcome = async (
  outcomeId: string,
  onError?: RequestErrorHandler
) => {
  return withRequestRetry(() => _destroyOutcome(outcomeId), onError);
};

const _destroyOutcome = async (outcomeId: string) => {
  const url = tokenizeRoute(DESTROY_OUTCOME_ROUTE, {
    outcome_id: outcomeId,
  });

  return fetch(url.toString(), {
    method: 'DELETE',
    ...defaultFetchOptions(),
  }).then((response) => {
    return _handleJsonResponse(response);
  });
};

export const checkOutcomeExtractedStatus = async (
  reviewStudyId: string,
  templateOutcomeId: string,
  onError?: RequestErrorHandler
): Promise<CheckExistingExtractionDataResponse> => {
  return withRequestRetry(
    () => _checkOutcomeExtractedStatusRequest(reviewStudyId, templateOutcomeId),
    onError
  );
};

const _checkOutcomeExtractedStatusRequest = async (
  reviewStudyId: string,
  templateOutcomeId: string
): Promise<CheckExistingExtractionDataResponse> => {
  const url = tokenizeRoute(CHECK_OUTCOME_EXTRACTED_DATA_ROUTE, {
    review_study_id: reviewStudyId,
    template_outcome_id: templateOutcomeId,
  });

  return fetch(url.toString(), {
    method: 'GET',
    ...defaultFetchOptions(),
  }).then((response) => {
    return _handleJsonResponse(response);
  });
};
