import React from 'react';
import { Flex, Box } from 'theme-ui';
import styles from './RevertModal.module.css';
import Dialog from 'system/base/Dialog';

import { NakedButton, DangerButton, PrimaryButton } from 'system/base/Button';
import { WarningIcon, InfoIcon } from 'system/base/Icons';

export type RevertModalType = 'success' | 'error' | 'ask' | 'closed';
export interface RevertModalProps {
  since?: string;
  onRevert?: () => void;
  onCancel?: () => void;
  onDismiss?: () => void;
  onSuccess?: () => void;
  mode?: RevertModalType;
}

const noop = () => {
  // Do nothing
};

const OkButton = (props: { onClick: () => any }): JSX.Element => {
  return (
    <PrimaryButton
      sx={{
        margin: '16px 0px 0px 16px',
        padding: '0px 48px',
      }}
      onClick={props.onClick}
    >
      OK
    </PrimaryButton>
  );
};

const AskToRevert = ({
  since,
  onCancel = noop,
  onRevert = noop,
}: RevertModalProps): JSX.Element => {
  const sinceMessage = since ? `since ${since}` : '';
  return (
    <>
      <Flex
        variant="colorStyles.warning"
        sx={{
          padding: '16px',
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <WarningIcon />
        <Flex
          sx={{
            padding: '0px 16px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
          }}
        >
          All changes to template will be lost
        </Flex>
      </Flex>
      <Box
        sx={{
          padding: '16px 0px',
        }}
      >
        All changes made {sinceMessage} will be lost and the template will be
        reverted back to your last published version
      </Box>
      <Box sx={{ margin: '16px 0px' }}>
        <DangerButton onClick={onRevert}>Yes, Proceed</DangerButton>
        <NakedButton className={styles.buttonWrapper} onClick={onCancel}>
          Cancel
        </NakedButton>
      </Box>
    </>
  );
};

const Successful = (props: { dismiss: () => any }): JSX.Element => {
  return (
    <>
      <Flex
        sx={{
          padding: '16px',
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <div className={styles.iconContainer}>
          <InfoIcon />
        </div>
        <Flex
          sx={{
            padding: '0px 16px',
            display: 'flex',
          }}
        >
          Template has been successfully reverted
        </Flex>
      </Flex>
      <OkButton onClick={props.dismiss} />
    </>
  );
};

const Errorful = (prop: { dismiss: () => any }): JSX.Element => {
  return (
    <>
      <Flex
        sx={{
          padding: '16px',
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <div className={styles.iconContainer}>
          <InfoIcon />
        </div>
        <div className={styles.errorText}>
          There has been an issue reverting the template to the last published
          version, please try to revert the template again. If this continues to
          occur then please contact us at support@covidence.org
        </div>
      </Flex>
      <OkButton onClick={prop.dismiss} />
    </>
  );
};

const RevertModal = (props: RevertModalProps): JSX.Element => {
  const { mode, onDismiss = noop, onSuccess = noop } = props;

  const title = mode === 'error' ? 'Error' : 'Revert to last published version';
  return (
    <Dialog isOpen={mode !== 'closed'} title={title} onDismiss={onDismiss}>
      {mode === 'ask' && <AskToRevert {...props} />}
      {mode === 'success' && <Successful dismiss={onSuccess} />}
      {mode === 'error' && <Errorful dismiss={onDismiss} />}
    </Dialog>
  );
};

export default RevertModal;
