import React from 'react';
import styles from './Progress.module.css';
import { StatusIndicator } from './StatusIndicator';
import { Actions } from './StudyTypes';
import { ConsensusSlot as ConsensusSlotType } from 'types/graphql';

import { Link } from 'system/base/Link';

type ConsensusSlotProps = ConsensusSlotType & {
  singleReviewer: boolean;
  editCompareUrl: Actions['editCompareUrl'];
};

const ConsensusSlot = ({
  started,
  completed,
  decider,
  lastActivityAgo,
  currentUser,
  editCompareUrl,
  singleReviewer,
}: ConsensusSlotProps) => {
  if (!decider) {
    return null;
  }

  const renderEditLink = () => {
    if (editCompareUrl) {
      return <Link href={editCompareUrl}>Edit</Link>;
    }
  };

  const checkingOrComparing = singleReviewer ? 'checking' : 'comparing';

  const renderStatus = () => {
    if (completed) {
      return (
        <span>
          {currentUser ? 'You' : decider.name} completed comparison{' '}
          {lastActivityAgo} {currentUser ? renderEditLink() : null}
        </span>
      );
    }

    if (!started) {
      return (
        <span>
          {currentUser ? 'You are' : decider.name + ' is'} assigned to{' '}
          {singleReviewer ? 'check this reference' : 'complete consensus'}
        </span>
      );
    }

    if (currentUser) {
      return (
        <span>
          You are currently {checkingOrComparing}: Last edit {lastActivityAgo}
        </span>
      );
    }

    return (
      <span>
        {decider.name} is {checkingOrComparing}: Last edit {lastActivityAgo}
      </span>
    );
  };

  return (
    <li className={styles.activityItem}>
      <StatusIndicator completed={completed} />
      {renderStatus()}
    </li>
  );
};

export default ConsensusSlot;
