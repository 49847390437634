import React, { Component } from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';

import './styles.css';

import Checkbox from 'system/elements/Checkbox';

export default class ConfirmationChecklist extends Component {
  constructor() {
    super();
    this.state = { allConfirmed: false };
  }

  componentDidUpdate(_, prevState) {
    if (
      typeof this.props.onChange === 'function' &&
      this.state.allConfirmed !== prevState.allConfirmed
    ) {
      this.props.onChange(this.state.allConfirmed);
    }
  }

  onChangeFor(item) {
    const { items } = this.props;

    return (event) => {
      const checked = event.target.checked;

      this.setState((state) => ({
        [item]: checked,
        allConfirmed:
          checked && items.every((i) => i === item || state[i] === true),
      }));
    };
  }

  render() {
    const { className, items } = this.props;

    return (
      <ul
        className={classnames(
          'ConfirmationChecklist',
          'list-unstyled',
          className
        )}
      >
        {items.map((item) => (
          <li
            key={item}
            className={classnames('ConfirmationChecklist--option', {
              'ConfirmationChecklist--option__confirmed':
                this.state[item] || false,
            })}
          >
            <Checkbox
              required
              checked={this.state[item] || false}
              onChange={this.onChangeFor(item)}
              label={item}
            />
          </li>
        ))}
      </ul>
    );
  }
}

ConfirmationChecklist.propTypes = {
  className: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.node).isRequired,
  onChange: PropTypes.func.isRequired,
};

ConfirmationChecklist.defaultProps = {
  className: null,
};
