import React from 'react';
import {
  NavigationTabItem,
  NavigationTabs,
} from 'components/NavigationTabs/NavigationTabs';

interface Props {
  currentPage: string;
  count: number;
}

const FullTextBulkUploaderNavigationTabs = ({ currentPage, count }: Props) => {
  const BULK_UPLOADER_URL = 'full-text-bulk-uploader';
  const BULK_UPLOADER_HISTORY_URL = 'bulk-upload-history';

  return (
    <NavigationTabs>
      <NavigationTabItem
        url={BULK_UPLOADER_URL}
        name={'Bulk upload PDFs'}
        active={BULK_UPLOADER_URL === currentPage}
      />
      <NavigationTabItem
        url={BULK_UPLOADER_HISTORY_URL}
        name={'Bulk upload history'}
        active={BULK_UPLOADER_HISTORY_URL === currentPage}
        count={count}
      />
    </NavigationTabs>
  );
};

export default FullTextBulkUploaderNavigationTabs;
