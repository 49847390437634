import { BlockRecords } from '../../BlocksData';
import { TableResolutionState } from './types';
import { Resolutions } from 'containers/Extraction/types';

/**
 * 1. Adds/updates table value in resolvedData
 * 2. Adds/updates unresolved table cell ids in unresolvedTableCells
 * 3. Adds the table block id to unresolvedIDs if not fully resolved, removes
 *    the table block id otherwise.
 */
export const updateResolutionsOnTableResolutionChange = (
  {
    resolvedData,
    unresolvedIDs,
    unresolvedTableCells,
    ...restOfResolutions // Just in case we add more data to Resolutions type
  }: Resolutions,
  tableBlockID: string,
  { resolvedCellData, unresolvedCellIDs }: TableResolutionState
): Resolutions => {
  const updatedResolvedData: BlockRecords = {
    ...resolvedData,
    [tableBlockID]: {
      ...resolvedData[tableBlockID],
      value: resolvedCellData,
    },
  };

  const updatedUnresolvedTableCells: Resolutions['unresolvedTableCells'] = {
    ...unresolvedTableCells,
    [tableBlockID]: unresolvedCellIDs,
  };

  const updatedUnresolvedIDs = new Set(unresolvedIDs);

  // Remove table from unresolvedIds
  if (unresolvedCellIDs.length) {
    // If we have unresolved cells, the table is unresolved
    updatedUnresolvedIDs.add(tableBlockID);
  } else {
    updatedUnresolvedIDs.delete(tableBlockID);
  }

  return {
    ...restOfResolutions,
    resolvedData: updatedResolvedData,
    unresolvedIDs: updatedUnresolvedIDs,
    unresolvedTableCells: updatedUnresolvedTableCells,
  };
};
