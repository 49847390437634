import React from 'react';
import styles from './NoWorkToDo.module.css';

export interface NoWorkToDoProps {
  message?: string | JSX.Element;
}

export function NoWorkToDo({ message }: NoWorkToDoProps) {
  return (
    <div data-testid="zero-state-message" className={styles.zeroStateMessage}>
      {message ? message : 'Nice job! No more work to do here for now.'}
    </div>
  );
}
