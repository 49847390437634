import React from 'react';

import { Table2Block } from '../../types/block';
import { TableHeaderFields } from './TableHeaderFields';
import { InstructionsToExtractors } from 'concepts/Extraction/Blocks/shared/InstructionsToExtractors';

export interface TableEditorSettingsProps {
  block: Table2Block;
  onChange: (updatedBlock: Table2Block) => void;
}

export const TableEditorSettings = ({
  block,
  onChange,
}: TableEditorSettingsProps) => (
  <div>
    <InstructionsToExtractors block={block} onChange={onChange} />
    <hr />
    <TableHeaderFields
      block={block}
      onChange={onChange}
      headerType="column"
      formDescription="Enter column heading(s)"
    />
    <hr />
    <TableHeaderFields
      block={block}
      onChange={onChange}
      headerType="row"
      formDescription="Enter row heading(s)"
    />
    <hr />
  </div>
);
