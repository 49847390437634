import React, { memo, useState } from 'react';
import {
  useFieldArray,
  UseFieldArrayReturn,
  useFormContext,
} from 'react-hook-form';
import styles from '../../DataExtractionTemplate.module.scss';
import { DataExtractionTemplateState } from '../../DataExtractionTemplate';
import ConfirmationDialog from '../ConfirmationDialog';
import AddOutcome from './AddOutcome/AddOutcome';
import outcomeStyles from './OutcomesPanel.module.scss';
import OutcomeEditor from './OutcomeEditor';
import {
  Heading,
  Icon,
  IconList,
  Link,
  Accordion,
  Text,
} from 'components/core';
import { OutcomeTypes } from 'types/DataExtraction';

type Props = {
  resultsDataAnchor?: string;
};
const OutcomesPanel = ({ resultsDataAnchor }: Props) => {
  const [modal, setModal] = useState<React.ReactElement | null>();
  const outcomes = useFieldArray<DataExtractionTemplateState>({
    name: 'outcomes.outcomes',
  }) as UseFieldArrayReturn<DataExtractionTemplateState, 'outcomes.outcomes'>;
  const { getValues, formState } = useFormContext();

  const onAddOutcome = () => {
    outcomes.append({
      name: '',
      outcome_type: OutcomeTypes.CONTINUOUS,
      timepoints: [{ name: 'Baseline' }],
      has_extracted_data: false,
    });
  };

  return (
    <section className={styles.section}>
      {modal}
      <Heading
        type="h5"
        component="h3"
        variant="brand"
        className={styles.heading}
      >
        Outcomes
        <Link
          className={styles.HelpLink}
          onClick={() => ''}
          data-pendo-key="det-outcomes"
          aria-label="Get help with outcomes"
        >
          <Icon icon={IconList.light.faQuestionCircle} />
        </Link>
      </Heading>
      <div className={outcomeStyles.outcomeDetailsSection}>
        <Heading type="h6" component="h4" variant="neutral">
          Outcomes
        </Heading>

        <Accordion component="div" allowMultiple={false}>
          {outcomes.fields.map((field, index) => {
            return (
              <OutcomeEditor
                key={field.id}
                index={index}
                resultsDataAnchor={resultsDataAnchor}
                hasExtractedData={field.has_extracted_data}
                onDelete={() => {
                  const { id, has_extracted_data } = getValues(
                    `outcomes.outcomes.${index}`
                  );

                  if (id && has_extracted_data) {
                    setModal(
                      <ConfirmationDialog
                        onConfirm={() => outcomes.remove(index)}
                        onClose={() => setModal(null)}
                      />
                    );
                  } else {
                    outcomes.remove(index);
                  }
                }}
              />
            );
          })}
        </Accordion>

        <AddOutcome
          onClick={onAddOutcome}
          disabled={!formState.isValid}
          length={outcomes.fields.length}
        />
      </div>

      <div className={styles.details}>
        <Heading type="h6" component="h4" variant="neutral">
          Outcome details
        </Heading>
        <ul>
          <li>
            <Text variant="medium">Outcome reporting</Text>
          </li>
          <li>
            <Text variant="medium">Scale</Text>
          </li>
          <li>
            <Text variant="medium">Range</Text>
          </li>
          <li>
            <Text variant="medium">Unit of measurement</Text>
          </li>
          <li>
            <Text variant="medium">Direction</Text>
          </li>
          <li>
            <Text variant="medium">Data value</Text>
          </li>
          <li>
            <Text variant="medium">Notes</Text>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default memo(OutcomesPanel);
