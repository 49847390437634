type HandleDirectoryArgs = {
  directories: FileSystemDirectoryEntry[];
  files: FileSystemFileEntry[];
};

const isGetAsEntrySupported = (dataTransferItems: DataTransferItem[]) => {
  if (dataTransferItems.length > 0) {
    const item = dataTransferItems[0];
    if (typeof item.webkitGetAsEntry === 'function') {
      return true;
    }
  }
  return false;
};

const groupByEntryType = (
  entries: FileSystemEntry[]
): {
  directories: FileSystemDirectoryEntry[];
  files: FileSystemFileEntry[];
} => {
  const directories = entries.filter((dir) => {
    return dir?.isDirectory;
  }) as FileSystemDirectoryEntry[];

  const files = entries.filter((f) => {
    return f?.isFile;
  }) as FileSystemFileEntry[];
  return { files, directories };
};

const handleDirectory = ({
  directories,
  files,
}: HandleDirectoryArgs): Promise<FileSystemFileEntry[]> => {
  return new Promise((resolve) => {
    if (directories.length === 0) {
      resolve(files);
      return;
    }

    const firstDirectory = directories.shift();

    firstDirectory?.createReader().readEntries((entries) => {
      const grouped = groupByEntryType(entries);
      handleDirectory({
        directories: directories.concat(grouped.directories),
        files: files.concat(grouped.files),
      }).then((resolvedFiles) => {
        resolve(resolvedFiles);
      });
    });
  });
};

const handleFolders = (items: DataTransferItemList) => {
  const dataTransferItems = Array.from<DataTransferItem>(items);

  if (!isGetAsEntrySupported(dataTransferItems)) {
    return Promise.resolve([]);
  }
  const asEntries = dataTransferItems.map((item) => item.webkitGetAsEntry());

  const { files, directories } = groupByEntryType(
    asEntries as FileSystemEntry[]
  );

  return handleDirectory({
    directories,
    files,
  });
};

export default handleFolders;
