import React from 'react';

import styles from './StageComplete.module.css';

export interface StageCompleteProps {
  stage: string;
  reviewerName: string;
  contribution: number;
}

const StageComplete = ({
  stage,
  reviewerName,
  contribution,
}: StageCompleteProps) => {
  const action = stage === 'included' ? 'completed' : 'screened';
  const object = stage === 'included' ? 'extractions' : 'studies';

  return (
    <div className={styles.complete}>
      <div className={`${styles.ok} ss-like`}></div>
      <div className={styles.contribution}>
        <div>{reviewerName},</div>
        you have {action}{' '}
        <span>
          <strong>{contribution}</strong>
        </span>{' '}
        <span>{object}</span>
      </div>
    </div>
  );
};

export default StageComplete;
