import React from 'react';
import styles from './TopicExperts.module.css';
import DataTable, { Columns } from 'system/data/DataTable';
import DataWithControls from 'system/layout/DataWithControls';
import { Topic, TopicExpertInvite } from 'types/graphql';
import { ID } from 'util/types';
import { NakedButton } from 'system/base/Button';

export interface PendingInvitesProps {
  loading: boolean;
  error?: Error;
  loadMore?: () => void;
  cancelInvite: (variables: ModifyInviteVariables) => void;
  resendInvite: (variables: ModifyInviteVariables) => void;
  invitations: TopicExpertInvite[];
}

export interface ModifyInviteVariables {
  id: ID;
}

export function PendingInvites({
  loading,
  loadMore,
  cancelInvite,
  resendInvite,
  error,
  invitations,
}: PendingInvitesProps): JSX.Element {
  /* eslint-disable react/display-name */
  const columns: Columns<typeof invitations> = [
    {
      heading: 'Name',
      primary: true,
      render: ({ id, name }) => (
        <span data-testid={`reviewer-name-${id}`}>{name}</span>
      ),
    },
    {
      heading: 'Email',
      render: ({ id, email }) => (
        <span data-testid={`reviewer-email-${id}`}>{email}</span>
      ),
    },
    {
      heading: 'Topics',
      render: ({ id, expertTopics }) => (
        <span data-testid={`topic-names-${id}`}>
          {expertTopics.map((expertTopic: Topic, i: number) => (
            <span data-testid="topic-name" key={i} className={styles.topicPill}>
              {expertTopic.name}
            </span>
          ))}
        </span>
      ),
    },
    {
      heading: 'Actions',
      render: ({ id }) => (
        <span data-testid={`invite-actions-${id}`}>
          <NakedButton onClick={() => cancelInvite({ id })}>Cancel</NakedButton>
          <NakedButton
            style={{ marginLeft: '10px' }}
            onClick={() => resendInvite({ id })}
          >
            Resend
          </NakedButton>
        </span>
      ),
    },
  ];
  /* eslint-enable react/display-name */

  return (
    <section
      className={styles.container}
      data-testid="pending-topic-expert-invites"
    >
      <h2 className={styles.subtitle}>Pending invitations</h2>
      <p className={styles.subtleDescriptor}>
        These guideline topic experts have pending invitations. They have been
        sent an email invitation but have not yet accepted.
      </p>
      <DataWithControls>
        <DataTable
          collection={invitations}
          columns={columns}
          loadMore={loadMore}
          error={error}
          loading={loading}
          empty="There are no pending invitations for this guideline"
        />
      </DataWithControls>
    </section>
  );
}
