import React, { HTMLAttributes } from 'react';
import Notice from 'system/elements/Notice';

type UnblindedWarningProps = {
  style?: HTMLAttributes<HTMLDivElement>['style'];
};

export const UnblindedWarning = ({
  style,
}: UnblindedWarningProps): JSX.Element => (
  <Notice style={style} mt={2} mb={2} type="warning" icon>
    This export contains unblinded data and may increase the risk of bias in
    your review process.
  </Notice>
);
