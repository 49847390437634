import React from 'react';
import styles from '../../containers/Comments/Comments.module.css';
import Mentions, { Mention } from './Mentions';
import Attribution from './Attribution';
import RecentActivity from './RecentActivity';
import { Link } from 'system/base/Link';
import { Comment as GqlComment, Study, User } from 'types/graphql';
import StudiesRollup from 'concepts/Studies/StudiesRollup';

export type CommentReviewer = Pick<User, 'name'>;

export type ListedDiscussionProps = Omit<
  GqlComment,
  '__typename' | 'reviewer' | 'activeBy' | 'reviewStudies' | 'mentions'
> & {
  activeBy: { name: string };
  mentions: ReadonlyArray<Mention>;
  reviewer: CommentReviewer;
  reviewStudies: ReadonlyArray<Study>;
};

export function ListedDiscussion({
  id,
  createdAgo,
  activeAgo,
  activeBy,
  reviewer,
  actions,
  content,
  mentions,
  reviewStudies,
}: ListedDiscussionProps): JSX.Element {
  return (
    <div data-testid={`comment-${id}`}>
      <div data-testid="meta" className={styles.subtleDescriptor}>
        <Attribution
          name={reviewer.name}
          action="created discussion"
          timeAgo={createdAgo}
          edited={false}
        />{' '}
        <RecentActivity
          timeAgo={createdAgo}
          activeAgo={activeAgo}
          activeByName={activeBy?.name}
        />
      </div>
      <h2 className={styles.discussionTitle} data-testid="content">
        <Link href={`${actions.showUrl}`} data-testid={`details-link-${id}`}>
          {content}
        </Link>
      </h2>
      <StudiesRollup reviewStudies={reviewStudies} />
      <Mentions mentions={mentions} />
      <hr />
    </div>
  );
}

export default ListedDiscussion;
