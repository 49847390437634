import { RowID, Header } from '../types';

type GetCellLabelProps = {
  cell: {
    rowID: RowID;
    rowIndex: number;
    columnID: string;
  };
  headers: {
    rows: Header[];
    columns: Header[];
  };
};

/**
 * Creates accessible labels for EditableCell input.
 * @returns `column ${columnLabel} row ${rowLabel}`
 */
export const getCellLabel = ({
  cell: { rowID, columnID, rowIndex },
  headers: { rows, columns },
}: GetCellLabelProps): string => {
  const rowLabel =
    rows.find((row) => row.id === rowID)?.label ?? (rowIndex + 1).toString();

  /**
   * @remarks
   * Should not return undefined (i.e. columnID should always match a column
   * header)
   */
  const columnLabel = columns.find((column) => column.id === columnID)?.label;

  /**
   * @remarks
   * There should always be a columnLabel, but this conditional is there to
   * address that 'undefined' type.
   */
  return (columnLabel && `column ${columnLabel} `) + `row ${rowLabel}`;
};
