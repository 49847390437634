import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { TemplateBlock } from '../BaseBlock';
import { MultipleChoice } from './control/MultipleChoice';
import {
  MultipleChoiceBlock,
  isMultipleChoiceBlockValue,
} from './MultipleChoiceBlock';
import { MultipleChoiceEditorSettings } from './editor/MultipleChoiceEditorSettings';
import { MultipleChoiceComparisonRow } from './control/MultipleChoiceComparisonRow';
import {
  PreviewProps,
  ControlProps,
  EditorProps,
  EditorSettingsProps,
  ComparisonRowProps,
} from 'concepts/Extraction/Blocks/BlockRenderer';
import MultipleChoiceEditor from 'concepts/Extraction/Blocks/MultipleChoice/editor/MultipleChoiceEditor';
import { BlockRecord } from 'concepts/Extraction/BlocksData';

const typeIconSVG = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="56"
    height="32"
    fill="none"
    role="img"
  >
    <title>Multiple Choice</title>
    <path
      fill="#8D186B"
      d="M0 5c0-2.761 2.239-5 5-5h46c2.761 0 5 2.239 5 5v22c0 2.761-2.239 5-5 5H5c-2.761 0-5-2.239-5-5V5z"
    />
    <path
      fill="#fff"
      d="M14 14c-3.312 0-6 2.688-6 6s2.688 6 6 6 6-2.688 6-6-2.688-6-6-6z"
    />
    <path
      fill="#8D186B"
      d="M14 15.5c-2.484 0-4.5 2.016-4.5 4.5s2.016 4.5 4.5 4.5 4.5-2.016 4.5-4.5-2.016-4.5-4.5-4.5z"
    />
    <path
      fill="#fff"
      d="M14 17.75c-1.242 0-2.25 1.008-2.25 2.25s1.008 2.25 2.25 2.25 2.25-1.008 2.25-2.25-1.008-2.25-2.25-2.25z"
    />
  </svg>
);

export const MultipleChoiceBlockRenderer = {
  Editor({ block, onChange, onComplete, onRemove }: EditorProps) {
    return (
      <MultipleChoiceEditor
        block={block as MultipleChoiceBlock}
        onChange={onChange}
        onComplete={onComplete}
        onRemove={onRemove}
      />
    );
  },
  EditorSettings({ block, onChange }: EditorSettingsProps) {
    return (
      <MultipleChoiceEditorSettings
        block={block as MultipleChoiceBlock}
        onChange={onChange}
      />
    );
  },
  Control({ block }: ControlProps) {
    const { setValue, control } = useFormContext();
    const blockValue = useWatch({
      control,
      name: block.id,
    });

    return (
      <MultipleChoice
        editable
        block={block as MultipleChoiceBlock}
        value={
          isMultipleChoiceBlockValue(blockValue?.value)
            ? blockValue?.value
            : null
        }
        comments={blockValue?.comment}
        onChange={(newValue) => {
          setValue(
            block.id,
            { ...blockValue, value: newValue },
            { shouldDirty: true }
          );
        }}
        onChangeComments={(newComment) => {
          setValue(
            block.id,
            { ...blockValue, comment: newComment },
            { shouldDirty: true }
          );
        }}
      />
    );
  },
  Preview({ block }: PreviewProps) {
    return <MultipleChoice block={block as MultipleChoiceBlock} />;
  },
  ComparisonRow({ block, ...rest }: ComparisonRowProps) {
    return (
      <MultipleChoiceComparisonRow
        block={block as MultipleChoiceBlock}
        {...rest}
      />
    );
  },
  TypeIcon: () => typeIconSVG,
  keepResponse(
    record: BlockRecord,
    newTemplateBlock: TemplateBlock,
    oldTemplateBlock: TemplateBlock
  ) {
    const newBlock = newTemplateBlock as MultipleChoiceBlock;
    const oldBlock = oldTemplateBlock as MultipleChoiceBlock;

    const oldOptionsHaveValue =
      oldBlock.options.find((option) => option.id == record.value) != undefined;
    const newOptionsHaveValue =
      newBlock.options.find((option) => option.id == record.value) != undefined;
    const oldOptionsHadOther = !!oldBlock.otherOption;
    const newOptionsHaveOther = !!newBlock.otherOption;

    // If the value appears amongst the options of the old template — but not the new one — then remove the record
    if (oldOptionsHaveValue && !newOptionsHaveValue) {
      return false;
    }

    // The user selected other on the last extraction, But the other option has now been removed, so remove the record
    if (oldOptionsHadOther && !newOptionsHaveOther && !oldOptionsHaveValue) {
      return false;
    }

    return true;
  },
  displayOnly: false,
};
