export default function pollingInterval(
  defaultInterval: number | null
): number {
  // Default to 1s in CI:
  if (process.env.CI) {
    return 100;
  }

  // Default to 10s in development environments:
  if (process.env.DEV) {
    return 1000;
  }

  // But otherwise return the default value (and if that wasn't supplied, we
  // only send the request once a minute):
  return defaultInterval || 6000;
}
