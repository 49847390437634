import { gql, useQuery } from '@apollo/client';
import idx from 'idx';
import React, {
  FunctionComponent,
  useRef,
  useCallback,
  useState,
  ReactNode,
} from 'react';
import { TrialExpiryBanner } from './TrialExpiryBanner/TrialExpiryBanner';
import usePersistedState from 'hooks/usePersistedState';
import Notice from 'system/elements/Notice';
import LoadingSpinner from 'system/elements/Spinner';
import { ID } from 'util/types';
import { ContentHeading, SectionHeading } from 'system/base/Heading';
import useStudyVoter, { AvailableStudiesState } from 'hooks/useStudyVoter';
import { Menu, MenuButton, MenuList, MenuItem } from 'system/elements/Menu';
import {
  VoteValue,
  ScreeningReviewQuery,
  ScreeningReviewQueryVariables,
  Maybe,
} from 'types/graphql';
import Text, { InlineText } from 'system/base/Text';
import Icon, { IconStyle } from 'system/base/Icon';
import { PrimaryButton, SecondaryButton } from 'system/base/Button';
import { PrimaryLink } from 'system/base/Link';
import Decision from 'system/layout/Decision';
import Position from 'system/utility/Position';
import MobileNavBar from 'system/elements/MobileNavBar';
import Box from 'system/base/Box';
import Space from 'system/utility/Space';
import Highlight from 'components/Highlight';
import unreachable from 'util/unreachable';

export const STUDIES_QUERY = gql`
  query ScreeningReview($reviewId: ID!) {
    review: node(id: $reviewId) {
      ... on Review {
        id
        title
        includedKeywords
        excludedKeywords
      }
    }
  }
`;

const ErrorNotice = ({ error }: { error?: Error | null }) => {
  const [dismissedError, setDismissedError] = useState<Error | null>(null);

  const dismissError = useCallback(() => {
    if (error) setDismissedError(error);
  }, [error]);

  return error && dismissedError !== error ? (
    <Notice type="error" onDismiss={dismissError}>
      Error: {error.message}
    </Notice>
  ) : null;
};

interface ContainerProps {
  error?: Error | null;
  accountId?: ID;
  isTrial?: boolean;
  isSubscriptionExpired?: boolean;
  trialExpiresIn?: string;
  userOwnsReview?: boolean;
}

const Container: FunctionComponent<ContainerProps> = ({ error, children }) => {
  return (
    <>
      {error && (
        <Position position="fixed" bottom={0} right={0} left={0}>
          <ErrorNotice error={error} />
        </Position>
      )}
      {children}
    </>
  );
};

interface NavBarProps {
  review?: Maybe<ScreeningReviewQuery['review']>;
  sessionCount: number;
}
const NavBar: FunctionComponent<NavBarProps> = ({ review, sessionCount }) => (
  <>
    <MobileNavBar backHref="/reviews/active">
      {review ? review.title : <LoadingSpinner />}
    </MobileNavBar>
    <Box borderY="panel" paddingX={3} paddingY={1} fontSize={2}>
      <strong>{sessionCount}</strong>
      <InlineText textStyle="muted"> studies screened this session</InlineText>
    </Box>
  </>
);

const NoStudiesTemplate = ({
  icon,
  message,
  action,
}: {
  icon: ReactNode;
  message: ReactNode;
  action?: ReactNode;
}) => (
  <Box marginTop="20vh" marginX={3} textAlign="center">
    <Space marginY={3}>
      <Text fontSize={8}>{icon}</Text>
      <ContentHeading textAlign="center">{message}</ContentHeading>
      {action}
    </Space>
  </Box>
);

const NoStudies: FunctionComponent<{
  availableStudiesState: Exclude<
    AvailableStudiesState,
    AvailableStudiesState.StudiesAvailable
  >;
  hasPendingVotes: boolean;
}> = ({ availableStudiesState, hasPendingVotes }) => {
  switch (availableStudiesState) {
    case AvailableStudiesState.FetchingStudies:
      return (
        <NoStudiesTemplate
          icon={<LoadingSpinner marginX="auto" />}
          message="Fetching studies..."
        />
      );

    case AvailableStudiesState.NoMoreStudiesFromServer:
      if (hasPendingVotes) {
        return (
          <NoStudiesTemplate
            icon={<Icon name="cloud" iconStyle={IconStyle.Regular} />}
            message="We're still syncing your votes, don't close this tab yet."
          />
        );
      } else {
        return (
          <NoStudiesTemplate
            icon={<Icon name="check" />}
            message="Well done! There are no more studies for you to screen."
            action={
              <PrimaryLink href="/reviews/active">
                Return to Reviews list
              </PrimaryLink>
            }
          />
        );
      }

    case AvailableStudiesState.Offline:
      return (
        <NoStudiesTemplate
          icon={<Icon name="wifi-slash" />}
          message="You've screened all the studies that are available offline at the moment. Go back online to get some more."
        />
      );

    default:
      return unreachable(availableStudiesState);
  }
};

const AdditionalActionMenu: FunctionComponent<{
  onSkip: () => void;
  onUndo?: () => void;
  onToggleHighlight: () => void;
  onMarkAsDuplicate: () => void;
  highlighted: boolean;
  className?: string;
}> = ({
  onSkip,
  onUndo,
  onToggleHighlight,
  onMarkAsDuplicate,
  highlighted,
  className,
}) => (
  <Menu>
    <MenuButton
      variant="raised"
      colors="onWhite"
      fontSize={3}
      className={className}
    >
      <Icon name="ellipsis-v" aria-label="More actions" />
    </MenuButton>
    <MenuList colors="onWhite" fontSize={3} marginBottom={12}>
      {/* add an extra element to workaround bug in menu position calculation */}
      {/* https://github.com/reach/reach-ui/pull/391 */}
      <MenuItem disabled fontSize={2} onSelect={() => false}>
        <Text textStyle="muted">Actions</Text>
      </MenuItem>

      <MenuItem onSelect={onSkip}>Skip</MenuItem>
      {onUndo && <MenuItem onSelect={onUndo}>Undo last vote</MenuItem>}
      <MenuItem onSelect={onMarkAsDuplicate}>Mark as Duplicate</MenuItem>
      <MenuItem onSelect={onToggleHighlight}>
        {highlighted ? 'Hide text highlights' : 'Show text highlights'}
      </MenuItem>
    </MenuList>
  </Menu>
);

interface Props {
  accountId: ID;
  reviewId: ID;
  reviewerId: ID;
  isTrial: boolean;
  isSubscriptionExpired: boolean;
  trialExpiresIn: string;
  userOwnsReview: boolean;
}

export const StudyScreening = ({
  accountId,
  reviewId,
  reviewerId,
  isTrial,
  isSubscriptionExpired,
  trialExpiresIn,
  userOwnsReview,
}: Props): JSX.Element => {
  const overflowElement = useRef<HTMLDivElement>(null);
  const resetOverflowScroll = () => {
    if (overflowElement.current) {
      overflowElement.current.scrollTop = 0;
    }
  };

  const { data, error, loading } = useQuery<
    ScreeningReviewQuery,
    ScreeningReviewQueryVariables
  >(STUDIES_QUERY, {
    variables: {
      reviewId,
    },
  });

  const [
    { syncError },
    studies,
    {
      undoableVoteStudyIds,
      availableStudiesState,
      hasPendingVotes,
      sessionCount,
      markStudyAsDuplicateError,
    },
    { castVote, castSkip, markStudyAsDuplicate },
  ] = useStudyVoter(reviewId, reviewerId);

  const [showHighlight, setShowHighlight] = usePersistedState<boolean>(
    'mobilescreening_highlight_pref',
    false
  );

  const trialExpiryBanner = () =>
    isTrial && (
      <TrialExpiryBanner
        accountId={accountId}
        isSubscriptionExpired={isSubscriptionExpired}
        trialExpiresIn={trialExpiresIn}
        userOwnsReview={userOwnsReview}
      />
    );

  if (loading) {
    return (
      <Container>
        <NavBar
          sessionCount={sessionCount}
          review={idx(data, (_) => _.review)}
        />
        <LoadingSpinner />
      </Container>
    );
  }

  if (error) {
    return (
      <Container>
        <NavBar
          sessionCount={sessionCount}
          review={idx(data, (_) => _.review)}
        />
        <Notice type="warning">{error.message}</Notice>
      </Container>
    );
  }

  if (availableStudiesState != AvailableStudiesState.StudiesAvailable) {
    return (
      <Container
        error={syncError}
        accountId={accountId}
        isTrial={isTrial}
        isSubscriptionExpired={isSubscriptionExpired}
        trialExpiresIn={trialExpiresIn}
        userOwnsReview={userOwnsReview}
      >
        {trialExpiryBanner()}
        <NavBar
          sessionCount={sessionCount}
          review={idx(data, (_) => _.review)}
        />
        <NoStudies
          availableStudiesState={availableStudiesState}
          hasPendingVotes={hasPendingVotes}
        />
      </Container>
    );
  }

  const study = studies[0];
  const firstReference = idx(study, (_) => _.references.nodes[0]);
  const includedKeywords = idx(data, (_) => _.review.includedKeywords) || [];
  const excludedKeywords = idx(data, (_) => _.review.excludedKeywords) || [];

  const notice = () => {
    if (syncError) return <ErrorNotice error={syncError} />;
    if (markStudyAsDuplicateError)
      return <ErrorNotice error={markStudyAsDuplicateError} />;
    return null;
  };

  return (
    <Container
      accountId={accountId}
      isTrial={isTrial}
      isSubscriptionExpired={isSubscriptionExpired}
      trialExpiresIn={trialExpiresIn}
    >
      <Decision
        ref={overflowElement}
        heading={
          <NavBar
            sessionCount={sessionCount}
            review={idx(data, (_) => _.review)}
          />
        }
        notice={notice()}
        actions={
          <>
            <PrimaryButton
              onClick={() => {
                castVote({
                  id: Date.now(),
                  studyId: study.id,
                  value: VoteValue.Yes,
                });
                /* eslint-disable @typescript-eslint/naming-convention */
                gtag('event', 'Mobile Vote', {
                  event_category: 'mobile-vs-desktop',
                  event_label: 'Yes',
                });
                /* eslint-enable @typescript-eslint/naming-convention */

                resetOverflowScroll();
              }}
              style={{ fontSize: 20, flexGrow: 2 }}
            >
              Yes
            </PrimaryButton>
            <SecondaryButton
              onClick={() => {
                castVote({
                  id: Date.now(),
                  studyId: study.id,
                  value: VoteValue.No,
                });

                try {
                  /* eslint-disable @typescript-eslint/naming-convention */
                  gtag('event', 'Mobile Vote', {
                    event_category: 'mobile-vs-desktop',
                    event_label: 'No',
                  });
                  /* eslint-enable @typescript-eslint/naming-convention */
                } catch (e) {
                  MetaError.notify(e);
                }

                resetOverflowScroll();
              }}
              style={{ fontSize: 20, flexGrow: 2 }}
            >
              No
            </SecondaryButton>
            <AdditionalActionMenu
              onSkip={() => {
                castSkip(study.id);

                try {
                  /* eslint-disable @typescript-eslint/naming-convention */
                  gtag('event', 'Mobile Vote', {
                    event_category: 'mobile-vs-desktop',
                    event_label: 'Skip',
                  });
                  /* eslint-enable @typescript-eslint/naming-convention */
                } catch (e) {
                  MetaError.notify(e);
                }
              }}
              onUndo={
                undoableVoteStudyIds.length > 0
                  ? () => {
                      castVote({
                        id: Date.now(),
                        studyId:
                          undoableVoteStudyIds[undoableVoteStudyIds.length - 1],
                        value: VoteValue.UndoVote,
                      });

                      resetOverflowScroll();
                    }
                  : undefined
              }
              highlighted={showHighlight}
              onToggleHighlight={() => setShowHighlight(!showHighlight)}
              onMarkAsDuplicate={() => {
                // Kind of a "hack". useStudyVoter maintains a list of studyIds
                // that you're going through and calculates it through the concept
                // of a vote.
                // A vote can be a Yes | No | Maybe or a Skip | Undo. Skip acts
                // differently from the rest as it doesn't actually send a "Skip"
                // vote to the server, it just locally skips a study.
                //
                // So we locally skip the study then mark it as a duplicate, so
                // that it no longer appears to be voted on then is sent to
                // "Manually Marked Duplicates" (on Desktop view)
                //
                castSkip(study.id);
                markStudyAsDuplicate(study.id);
              }}
            />
          </>
        }
        trialBanner={trialExpiryBanner()}
      >
        <Box marginBottom={2}>
          <SectionHeading fontWeight="normal">
            #{study?.covidenceNumber} — {firstReference?.primaryAuthor}{' '}
            {firstReference?.publication?.year}
          </SectionHeading>
          <ContentHeading fontSize={4}>
            {showHighlight ? (
              <Highlight
                includeWords={includedKeywords}
                excludeWords={excludedKeywords}
              >
                {firstReference?.title || ''}
              </Highlight>
            ) : (
              firstReference?.title
            )}
          </ContentHeading>
        </Box>
        <Text fontSize={2}>
          {showHighlight ? (
            <Highlight
              includeWords={includedKeywords}
              excludeWords={excludedKeywords}
            >
              {firstReference?.abstract || ''}
            </Highlight>
          ) : (
            firstReference?.abstract
          )}
        </Text>
      </Decision>
    </Container>
  );
};
