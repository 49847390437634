import { useRef, useCallback, useState } from 'react';
import PersistedCollection from 'util/PersistedCollection';

export type ActionManagerResult<T> = [Array<T>, CastAction<T>, Clear<T>];

type CastAction<T> = (action: T) => void;
type Clear<T> = (filterFn?: (item: T) => boolean) => void;

function useActionManager<T>(persistedKey: string): ActionManagerResult<T> {
  const { current } = useRef(new PersistedCollection<T>(persistedKey));

  // just so we re-render when elements are pushed onto the collection
  const [, setState] = useState<number>(Math.random());

  const cast = useCallback(
    (action: T) => {
      current.push(action);
      setState(Math.random());
    },
    [current]
  );

  const clear = useCallback(
    (arg?: (item: T) => boolean) => {
      current.clear(arg);
      setState(Math.random());
    },
    [current]
  );

  const currentArray = Array.from(current) as Array<T>;

  return [currentArray, cast, clear];
}

export default useActionManager;
