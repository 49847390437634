import React, { useEffect, useRef } from 'react';
import cn from 'classnames';
import styles from './Flash.module.scss';
import { Text } from 'components/core';

const getFlashElement = (): HTMLElement | null => {
  const element = document.getElementsByClassName('notice')[0] as HTMLElement;

  if (element instanceof HTMLElement) {
    return element;
  }

  return null;
};

const getFlashElements = (): HTMLElement[] => {
  const flashes = Array.from(document.querySelectorAll('.notice')).filter(
    (element) => {
      // Don't copy unconfirmed email, we have a flash for that already
      return (element as HTMLElement).dataset.flash !== 'unconfirmed-email';
    }
  );
  return flashes as HTMLElement[];
};

const UnconfirmedEmailFlash = () => {
  const flashContent = getFlashElement();

  let isProfile = false;
  let profileUrl = '';

  if (flashContent) {
    isProfile = flashContent.dataset.isProfile === 'true';
    profileUrl = flashContent.dataset.profile || '';
  }

  return (
    <div
      className={cn(
        styles.notice,
        styles.alert,
        styles.alertWarning,
        styles.text
      )}
    >
      <div className={styles.container}>
        <Text size="sm">
          <span className="fa-stack">
            <i className="fas fa-circle fa-stack-2x"></i>
            <i className="fas fa-at fa-stack-1x fa-inverse"></i>
          </span>{' '}
          Your email address on record is not verified.
          {!isProfile ? (
            <>
              {' '}
              Please visit <a href={profileUrl}> your profile</a> to confirm
              your details.
            </>
          ) : null}
        </Text>
      </div>
    </div>
  );
};

export const Flash = () => {
  // This allows us to keep the flashes that are rendered on standard rails templates
  // We should spend some time pulling this into a component with params instead of
  // copying Rail's homework along with standalone CSS
  // Note: The trial flash uses tachyons.scss so we need to port that over

  const elementRef = useRef<HTMLDivElement>(null);

  const unconfirmedEmailFlash = document.querySelectorAll(
    '[data-flash=unconfirmed-email]'
  )[0];

  useEffect(() => {
    const flashElements = getFlashElements();

    flashElements.forEach((element) => {
      elementRef.current?.appendChild(element);
    });
    if (unconfirmedEmailFlash) {
      (unconfirmedEmailFlash as HTMLElement).style.display = 'none';
    }
  }, [unconfirmedEmailFlash]);

  return (
    <div className={styles.flashContainer} ref={elementRef}>
      {unconfirmedEmailFlash ? <UnconfirmedEmailFlash /> : null}
    </div>
  );
};
