import React, { useEffect, useRef, useState, useImperativeHandle } from 'react';
import { Button } from 'components/core';
import { ButtonProps } from 'components/core/Button/Button.d';

interface Props extends Omit<ButtonProps, 'onClick' | 'isNaked'> {
  menuId: number;
  isMenuOpen: boolean;
  setMenuOpen: (open: boolean) => void;
  activeClass?: string;
}

type MinimalProps = Omit<Props, 'menuId' | 'isMenuOpen' | 'setMenuOpen'>;

const MenuButton = (
  { menuId, isMenuOpen, setMenuOpen, activeClass, className, ...props }: Props,
  ref: React.ForwardedRef<any>
) => {
  const buttonRef = useRef<any>();
  const [reclaimFocus, setReclaimFocus] = useState(false);
  useImperativeHandle(ref, () => buttonRef.current);

  const classes = [];
  if (className) classes.push(className);
  if (activeClass && isMenuOpen) classes.push(activeClass);

  useEffect(() => {
    if (!isMenuOpen && reclaimFocus) {
      setReclaimFocus(false);
      buttonRef.current?.focus();
    }
  }, [isMenuOpen, reclaimFocus]);

  const keyEvent = (e: KeyboardEvent) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      e.stopPropagation();
      setMenuOpen(!isMenuOpen);

      // if the menu is going to be open
      if (!isMenuOpen) {
        const menu = document.querySelector(`#covidence-menu-${menuId}`);
        const focusElement =
          menu?.querySelector('a') || menu?.querySelector('button');

        // only reclaim focus on menu close if we opened it via the keyboard
        setReclaimFocus(true);
        focusElement?.focus();
      }
    }

    if (e.key === 'Escape') {
      setMenuOpen(false);
      setReclaimFocus(true);
    }

    // Close menu if it is opened but not focused/entered
    if (e.shiftKey && e.key === 'Tab') {
      if (isMenuOpen) setMenuOpen(false);
    }
  };

  return (
    <Button
      ref={buttonRef}
      onClick={() => setMenuOpen(!isMenuOpen)}
      onKeyDown={(e: any) => {
        props.onKeyDown && props.onKeyDown(e);
        keyEvent(e as KeyboardEvent);
      }}
      className={classes.join(' ')}
      isNaked={!!activeClass && isMenuOpen}
      aria-expanded={isMenuOpen}
      aria-haspopup="menu"
      aria-controls={`covidence-menu-${menuId}`}
      {...props}
    />
  );
};

export default React.forwardRef<any, Props>(
  MenuButton
) as React.ForwardRefExoticComponent<MinimalProps>;
