import React, {
  FunctionComponent,
  ReactNode,
  HTMLAttributes,
  useRef,
  useImperativeHandle,
} from 'react';
import styles from './Text.module.scss';

export interface Props extends HTMLAttributes<any> {
  size?: 'xxl' | 'xl' | 'lg' | 'md' | 'sm' | 'xs';
  variant?:
    | 'default'
    | 'weak'
    | 'medium'
    | 'strong'
    | 'neutral'
    | 'brand'
    | 'primary'
    | 'danger'
    | 'warning'
    | 'success';
  component?: string | FunctionComponent<any>;
  className?: string;
  children?: ReactNode;
}

const Text = (
  { size, variant, component = 'p', className, children, ...props }: Props,
  ref: React.ForwardedRef<any>
) => {
  const componentType = component;

  const classes = [styles.text];
  if (size) classes.push(styles[size]);
  if (variant) classes.push(styles[variant]);
  if (className) classes.push(className);

  const forwardedRef = useRef<any>(null);
  useImperativeHandle(ref, () => forwardedRef.current);

  return React.createElement<any>(
    componentType,
    {
      ref: forwardedRef,
      className: classes.join(' ') || '',
      ...props,
    },
    children
  );
};

const forwardedText = React.forwardRef<any, Props>(Text) as React.FC<Props>;
export default forwardedText;
export { forwardedText as Text };
