import React from 'react';
import { gql, useMutation } from '@apollo/client';

import { Study } from './StudyTypes';
import { PrimaryButton } from 'system/base/Button';

import { AddNoteToStudyMutation } from 'types/graphql';

export const ADD_NOTE_TO_STUDY_MUTATION = gql`
  mutation AddNoteToStudy($input: AddNoteToStudyInput!) {
    addNoteToStudy(input: $input) {
      success
      errors {
        message
      }
    }
  }
`;

export interface AddNoteToStudyControlProps {
  study: Study;
  note: string;
  onSuccess: () => void;
  onFailure: () => void;
}

export default function AddNoteToStudyButton({
  study,
  note,
  onSuccess,
  onFailure,
}: AddNoteToStudyControlProps): JSX.Element {
  const [performAddNoteToStudy] = useMutation<AddNoteToStudyMutation>(
    ADD_NOTE_TO_STUDY_MUTATION
  );
  return (
    <PrimaryButton
      onClick={() => {
        performAddNoteToStudy({
          variables: {
            input: {
              studyId: study.id,
              note: note,
            },
          },
        })
          .then(onSuccess)
          .catch((e) => {
            MetaError.notify(e);
            onFailure;
          });
      }}
    >
      Save
    </PrimaryButton>
  );
}
