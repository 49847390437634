import React, { useState, useRef, ReactNode } from 'react';
import { useMutation } from '@apollo/client';
import { Box, Grid, Input } from 'theme-ui';

import INVITE_PERSON_TO_ORGANIZATION from '../../invitePersonToOrganization';
import { PrimaryButton } from 'system/base/Button';
import SuccessMessage from 'components/SuccessMessage';
import ErrorMessage from 'components/ErrorMessage';
import { ID } from 'util/types';
import {
  InvitePersonToOrganizationMutation,
  InvitePersonToOrganizationInput,
} from 'types/graphql';
import { validateEmail } from 'util/validateEmail';

interface Props {
  organizationId: ID;
  query: any;
}

const InviteForm = ({ organizationId, query }: Props) => {
  const nameEl = useRef<HTMLInputElement>(null);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [message, setMessage] = useState<ReactNode>('');
  const readyForSubmit = name && email && validateEmail(email);

  const refetchQueries = [
    { query, variables: { organizationId, after: null } },
  ];

  const [invitePersonToOrganization] = useMutation<
    InvitePersonToOrganizationMutation,
    InvitePersonToOrganizationInput
  >(INVITE_PERSON_TO_ORGANIZATION, {
    variables: { organizationId, name, email },
    refetchQueries,
  });

  const sendInvite = () => {
    setSubmitting(true);
    invitePersonToOrganization()
      .then((response: any) => {
        if (response.data.invitePersonToOrganization.success) {
          setMessage(<SuccessMessage>Invitation sent</SuccessMessage>);
          setName('');
          setEmail('');
        } else {
          const text = response.data.invitePersonToOrganization.errors
            .map((e: any) => `${e.message}`)
            .join(',');
          setMessage(<ErrorMessage>{text}</ErrorMessage>);
        }
        setSubmitting(false);
      })
      .catch((err) => {
        setMessage(<ErrorMessage>{err.message}</ErrorMessage>);
        setSubmitting(false);
      });
  };

  const submitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (readyForSubmit) {
      sendInvite();
      if (nameEl.current) {
        nameEl.current.focus();
      }
    }
  };

  return (
    <div>
      <h3>Invite someone to your organization</h3>
      <Box pb={3}>
        Invitees will receive an email with a link so they can sign up to the
        organization.
      </Box>
      <Box pb={1}>
        <form onSubmit={submitForm}>
          <Grid gap={2} columns={['1fr', '2fr 3fr 1fr']}>
            <Input
              placeholder="Enter a name"
              onChange={(e: any) => {
                setMessage('');
                setName(e.target.value);
              }}
              value={name}
              ref={nameEl}
            />
            <Input
              placeholder="Enter an email address"
              type="email"
              onChange={(e: any) => {
                setMessage('');
                setEmail(e.target.value);
              }}
              value={email}
            />
            <PrimaryButton
              disabled={!readyForSubmit || submitting}
              onClick={(e) => {
                e.preventDefault();
                sendInvite();
              }}
            >
              Send Invitation
            </PrimaryButton>
          </Grid>
        </form>
      </Box>
      {message}
    </div>
  );
};

export default InviteForm;
