import React, { FunctionComponent, useState } from 'react';
import fetch from 'cross-fetch';

import styles from './ExportToFile.module.scss';
import ReferencesForm, { ReferencesFormProps } from './ReferencesForm';
import ExtractionForm from './ExtractedDataForm/ExtractedDataForm';
import InterRaterReliabilityForm from './InterRaterReliabilityForm';
import History from './History';

import { Message, Text } from 'components/core';

export interface Export {
  id: string;
  references: ReferencesFormProps;
  createdAt: string;
  description: string;
  completedAt: string;
  failed: boolean;
  format: string;
  authenticatedUrl: string;
  type: string;
}

export interface CreateReferencesExportParams {
  export_option: string;
  reference_manager: string;
}

export interface CreateInterRaterReliabilityExportParams {
  stage: string;
}

export interface CreateExtractedDataExportParams {
  format: string;
  content_filter: string;
}

type CreateExportParams =
  | CreateReferencesExportParams
  | CreateInterRaterReliabilityExportParams
  | CreateExtractedDataExportParams;

interface ExportToFileProps {
  historyUrl: string;
  references: {
    select_options: { [key: string]: string };
    formats: { [key: string]: string };
    submit_url: string;
  };
  interRaterReliability: {
    submit_url: string;
  };
  extractedData: {
    formats: { [key: string]: string };
    submit_url: string;
  };
  qualityAssessment: {
    all_data_submit_url: string;
    consensus_only_submit_url: string;
  };
  exports: Export[];
  newExtraction: boolean;
  useNewTemplates: boolean;
  isSingleExtractor: boolean;
}

const ExportToFile: FunctionComponent<ExportToFileProps> = (props) => {
  let pollingTimer: any = null;

  const {
    historyUrl,
    references,
    extractedData,
    qualityAssessment,
    interRaterReliability,
    newExtraction,
    useNewTemplates,
    isSingleExtractor,
  } = props;

  const [exports, setExports] = useState<Export[]>(props.exports);
  const [notification, setNotification] = useState<string | null>(null);

  const clearNotification = () => {
    setNotification(null);
  };

  const updateHistory = () => {
    fetch(historyUrl)
      .then((res) => res.json())
      .then((response: Export[]) => {
        const pending = response.filter((ex) => !ex.completedAt);
        // Cancel polling once there are no pending exports returned
        if (pending.length === 0) {
          clearNotification();
          clearInterval(pollingTimer);
        }
        setExports(response);
      });
  };

  const startPolling = () => {
    pollingTimer = setInterval(() => {
      updateHistory();
    }, 5000);
  };

  const notify = (message: string) => {
    setNotification(message);
  };

  const renderNotification = () => {
    if (notification) {
      return (
        <Message
          className={styles.notification}
          variant="prominent"
          dismissible={false}
        >
          <Text>{notification}</Text>
        </Message>
      );
    }
  };

  const notifySuccess = () => {
    notify(
      'Please wait while we prepare your export. It will be available to download from the list below.'
    );
  };

  const createExport = (type: string, params: CreateExportParams) => {
    clearNotification();

    let submitUrl;
    switch (type) {
      case 'references':
        submitUrl = references.submit_url;
        break;
      case 'data_extraction':
        submitUrl = extractedData.submit_url;
        break;
      case 'quality_assessment':
        if (
          (params as CreateExtractedDataExportParams).content_filter == 'all'
        ) {
          submitUrl = qualityAssessment.all_data_submit_url;
        } else {
          submitUrl = qualityAssessment.consensus_only_submit_url;
        }
        break;
      case 'extracted_data_for_new_extraction':
        submitUrl = extractedData.submit_url;
        break;
      case 'inter_rater_reliability':
        submitUrl = interRaterReliability.submit_url;
        break;
    }

    if (submitUrl) {
      return fetch(submitUrl, {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        /* eslint-disable-next-line @typescript-eslint/naming-convention */
        body: JSON.stringify({ export: params }),
      }).then(() => {
        updateHistory();
        startPolling();
        notifySuccess();
      });
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.exportContainer}>
        <div className={styles.exportFormContainer}>
          <ReferencesForm
            createExport={createExport}
            selectOptions={references.select_options}
            formats={references.formats}
          />
        </div>
        <div className={styles.exportFormContainer}>
          <InterRaterReliabilityForm createExport={createExport} />
        </div>
        <div className={styles.exportFormContainer}>
          <ExtractionForm
            createExport={createExport}
            newExtraction={newExtraction}
            useNewTemplates={useNewTemplates}
            isSingleExtractor={isSingleExtractor}
          />
        </div>
      </div>
      {renderNotification()}
      <div className={styles.exportFormContainer}>
        <History exports={exports} />
      </div>
    </div>
  );
};

export default ExportToFile;
