import React from 'react';
import { render } from 'react-dom';
import GrooveButton from './GrooveButton';
import loadGroove from './vendor/loadGroove';

import styles from './GrooveWidget.module.scss';

declare global {
  interface Window {
    groove: any;
  }
}

export default class GrooveManager {
  private static instance: GrooveManager;
  private static GROOVE_ID = '2cf89baf-66cb-4e8e-811e-ee4c49b61527';

  public isOpen: boolean;
  private listeners: ((isOpen: boolean) => void)[];

  private constructor() {
    this.isOpen = false;
    this.listeners = [];
  }

  public static getInstance(): GrooveManager {
    if (!GrooveManager.instance) {
      GrooveManager.instance = new GrooveManager();
    }

    return GrooveManager.instance;
  }

  public isActive(): boolean {
    return (
      !!window.groove &&
      !!document.querySelector(`#groove-container-${GrooveManager.GROOVE_ID}`)
    );
  }

  public init(): void {
    loadGroove(GrooveManager.GROOVE_ID);

    window.groove.widget = window.groove.createWidget();
    window.groove.widget.init(GrooveManager.GROOVE_ID, {});
    window.groove.widget.onload = () => {
      this.loadGroove();
      this.initReact();
      window.groove.widget.onload = null;
    };
  }

  private loadGroove() {
    const grooveContainer = document.querySelector(
      `#groove-container-${GrooveManager.GROOVE_ID}`
    );
    grooveContainer?.classList.add('groove-container');
    window.addEventListener('message', () => {
      const prevState = this.isOpen;
      this.isOpen = window.groove.widget.shim.isOpen;
      if (prevState != this.isOpen)
        this.listeners.forEach((fn) => fn(this.isOpen));
    });

    window.addEventListener('click', () => {
      // Doesn't trigger on iframe clicks, so doesn't affect the widget
      if (this.isOpen) this.setOpen(false);
    });

    const grooveOuterContainer = document.querySelector(
      '.groove-outer-container'
    );
    grooveOuterContainer?.appendChild(grooveContainer as Node);
  }

  private initReact() {
    const grooveContainer = document.querySelector(
      `#groove-container-${GrooveManager.GROOVE_ID}`
    );
    const reactRoot = document.createElement('div');
    reactRoot.classList.add(styles.widgetContainer);
    grooveContainer?.prepend(reactRoot);

    render(<GrooveButton grooveManager={this} />, reactRoot);
  }

  public addListener(listener: (isOpen: boolean) => void) {
    this.listeners.push(listener);
  }

  public setOpen(isOpen: boolean): void {
    window.groove?.widget?.toggle(isOpen);
  }
}
