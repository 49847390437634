import FileUploadDialog from './components/FileUploadDialog';

export interface Reference {
  id: string;
  title: string;
  url: string;
  document_url?: string;
}

export interface FileInfo {
  id: string;
  type: 'link' | 's3_file';
  isPrimary?: boolean;
  file?: File;
  url?: string;
  uploadFileName?: string;
  uploadedBy?: string;
  uploadError?: string;
  uploadComplete?: boolean;
  createdAt?: Date;
  progress?: number;
}

export type FileListRowFunctions = {
  makeFilePrimary: (fileId: string) => void;
  deleteFile: (fileId: string, deleteFromBackend: boolean) => void;
  unsetDocumentUrl: () => void;
};

export function decodeHTMLEntities(html: string) {
  const el = document.createElement('p');
  el.innerHTML = html;
  return el.textContent;
}

export function getCsrfToken() {
  return (
    document
      .querySelector("meta[name='csrf-token']")
      ?.getAttribute('content') || ''
  );
}

export const Requests = {
  getFiles,
  deleteFiles,
  unsetDocumentUrl,
  makeFilePrimary,
};

function getFiles(reference: Reference): Promise<Response> {
  return fetch(reference.url + '/documents');
}

function deleteFiles(fileId: string): Promise<Response> {
  return fetch(`/documents/${fileId}`, {
    method: 'DELETE',
    headers: { 'X-CSRF-Token': getCsrfToken() },
    redirect: 'manual',
  });
}

function unsetDocumentUrl(reference: Reference): Promise<Response> {
  return fetch(reference.url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': getCsrfToken(),
    },
    body: JSON.stringify({ document_url: '' }),
  });
}

function makeFilePrimary(
  reference: Reference,
  fileId: string
): Promise<Response> {
  return fetch(reference.url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': getCsrfToken(),
    },
    body: JSON.stringify({ primary_document_id: fileId }),
  });
}

export const fetchNewS3Url = (
  id: string,
  fileName: string,
  reviewId: string
) => {
  const params = new URLSearchParams({
    attachable_type: 'ReviewReference',
    attachable_id: id,
    file_name: fileName,
    review_id: reviewId,
  });
  return fetch(`/documents/s3_upload_config?${params}`).then((res) => {
    if (!res.ok) throw new Error(`Couldn't get storage config`);
    return res.json();
  });
};

export const uploadFileToS3 = (
  url: string,
  file: File,
  callbackUrl: string,
  onProgress: (progress: number) => void
) => {
  return new Promise<{ file: File; callbackUrl: string }>((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open('PUT', url, true);

    xhr.upload.addEventListener('progress', (e: ProgressEvent) => {
      onProgress(e.loaded / e.total);
    });

    xhr.addEventListener('error', () => {
      reject(file);
    });

    xhr.addEventListener('load', () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        resolve({ file, callbackUrl });
      } else {
        reject(file);
      }
    });

    xhr.send(file);
  });
};

export { FileUploadDialog as default, FileUploadDialog };
