import React from 'react';
import styles from './History.module.css';
import { Export } from './index';
import { SecondaryButton } from 'system/base/Button';
import Icon from 'system/base/Icon';

interface ExportRowProps {
  ex: Export;
}
const ExportRow = ({ ex }: ExportRowProps) => {
  const renderStatus = () => {
    if (ex.completedAt) {
      return (
        <SecondaryButton
          onClick={() => (window.location.href = ex.authenticatedUrl)}
        >
          <Icon name="download" /> Download
        </SecondaryButton>
      );
    }

    if (ex.failed) {
      return <span>Failed</span>;
    }

    return (
      <div className={styles.pending}>
        <Icon name="clock" /> Pending...
      </div>
    );
  };

  return (
    <tr>
      <td>{ex.createdAt}</td>
      <td>{ex.format.toUpperCase()}</td>
      <td>{ex.description}</td>
      <td className={styles.exportStatus}>{renderStatus()}</td>
    </tr>
  );
};

export interface Props {
  exports: Export[];
}

const History = ({ exports }: Props) => {
  return (
    <table className={styles.exportHistory}>
      <thead>
        <tr>
          <th>Exported</th>
          <th>Format</th>
          <th>Description</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {exports.length === 0 ? (
          <tr>
            <td colSpan={4}>
              <p>There have been no exports in the last 7 days</p>
            </td>
          </tr>
        ) : (
          exports.map((ex) => <ExportRow key={ex.id} ex={ex} />)
        )}
      </tbody>
    </table>
  );
};

export default History;
