export type dataFormat = {
  name: string;
  tooltip: string;
};

export const newContinuousDataFormats: dataFormat[] = [
  { name: 'mean', tooltip: 'Mean' },
  { name: 'SD', tooltip: 'Standard deviation' },
  { name: '95% CI', tooltip: '95% confidence interval' },
  { name: 'SE', tooltip: 'Standard error' },
  { name: 'Total', tooltip: 'Total number of participants' },
  { name: 'median', tooltip: 'Median' },
  { name: 'IQR', tooltip: 'Interquartile range' },
  { name: 'range', tooltip: 'Range' },
  { name: 'MD', tooltip: 'Mean difference' },
  { name: 'SMD', tooltip: 'Standardised mean difference' },
  { name: 'p-value', tooltip: 'p-value' },
];

export const legacyContinuousDataFormats: dataFormat[] = [
  { name: 'mean', tooltip: 'Mean' },
  { name: 'SD', tooltip: 'Standard deviation' },
  { name: '95% CI', tooltip: '95% confidence interval' },
  { name: 'SE', tooltip: 'Standard error' },
  { name: 'N', tooltip: 'Total number of participants' },
  { name: 'MD', tooltip: 'Mean difference' },
  { name: 'SMD', tooltip: 'Standardised mean difference' },
  { name: 'p-value', tooltip: 'p-value' },
  { name: 'Custom', tooltip: 'Create custom formats in the study' },
];

export const legacyDichotomousDataFormats = [
  { name: 'n', tooltip: 'Number of participants with event' },
  { name: '%', tooltip: 'Percentage of participants with event' },
  { name: 'N', tooltip: 'Total number of participants' },
  { name: 'HR', tooltip: 'Hazard ratio' },
  { name: 'log HR', tooltip: 'Log hazard ratio' },
  { name: 'OR', tooltip: 'Odds ratio' },
  { name: 'log OR', tooltip: 'Log odds ratio' },
  { name: 'RD', tooltip: 'Risk difference' },
  { name: 'RR', tooltip: 'Risk ratio' },
  { name: 'log RR', tooltip: 'Log risk ratio' },
  { name: '95% CI', tooltip: '95% confidence interval' },
  { name: 'SE', tooltip: 'Standard error' },
  { name: 'p-value', tooltip: 'p-value' },
  { name: 'Custom', tooltip: 'Create custom formats in the study' },
];

export const newDichotomousDataFormats = [
  { name: 'Events', tooltip: 'Number of participants with event' },
  { name: '% Events', tooltip: 'Percentage of participants with event' },
  { name: 'Total', tooltip: 'Total number of participants' },
  { name: 'HR', tooltip: 'Hazard ratio' },
  { name: 'log HR', tooltip: 'Log hazard ratio' },
  { name: 'OR', tooltip: 'Odds ratio' },
  { name: 'log OR', tooltip: 'Log odds ratio' },
  { name: 'RR', tooltip: 'Risk ratio' },
  { name: 'log RR', tooltip: 'Log risk ratio' },
  { name: 'RD', tooltip: 'Risk difference' },
  { name: '95% CI', tooltip: '95% confidence interval' },
  { name: 'SE', tooltip: 'Standard error' },
  { name: 'p-value', tooltip: 'p-value' },
];
