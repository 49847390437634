import React, { useState } from 'react';
import { formatDateString } from '../util';
import { CitationImport } from 'types';
import {
  Button,
  Text,
  Icon,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  IconList,
  Tooltip,
  TooltipTrigger,
  TooltipContent,
} from 'components/core';

interface Props extends Pick<CitationImport, 'state'> {
  canUndo: boolean;
  undoDate?: string;
  onClick: () => void;
}

export const UndoControl = ({ canUndo, state, undoDate, onClick }: Props) => {
  const [isModalOpen, setModalOpen] = useState(false);

  if (state === 'new' || state === 'started') {
    return <Text variant="weak">In progress</Text>;
  }

  if (state === 'undoing') {
    return <Text variant="weak">Undo in progress</Text>;
  }

  if (undoDate) {
    return <Text variant="weak">Undone on {formatDateString(undoDate)}</Text>;
  }

  if (canUndo) {
    return (
      <>
        <Button
          type="neutral"
          variant="outline"
          onClick={() => {
            setModalOpen(true);
          }}
        >
          Undo import
        </Button>
        <Modal
          isOpen={isModalOpen}
          onClose={() => setModalOpen(false)}
          size="sm"
        >
          <ModalHeader>Undo import?</ModalHeader>
          <ModalBody>
            This action will remove all studies added from this import.
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={() => setModalOpen(false)}
              type="neutral"
              variant="outline"
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                onClick();
                setModalOpen(false);
              }}
              type="danger"
              data-testid="undo-confirm"
            >
              Undo Import
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }

  return (
    <Tooltip placement="bottom" hasArrow>
      <TooltipTrigger>
        <Text variant="weak" tabIndex={0}>
          Undo import disabled <Icon icon={IconList.light.faCircleQuestion} />
        </Text>
      </TooltipTrigger>
      <TooltipContent>
        Import cannot be undone as actions have occurred against one or more
        studies.
      </TooltipContent>
    </Tooltip>
  );
};

export default UndoControl;
