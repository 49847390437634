import {
  withRequestRetry,
  defaultFetchOptions,
  _handleJsonResponse,
  RequestErrorHandler,
  OfflineError,
  CheckExistingExtractionDataResponse,
} from './DataExtraction';
import {
  CREATE_TIMEPOINT_ROUTE,
  UPDATE_TIMEPOINT_ROUTE,
  DESTROY_TIMEPOINT_ROUTE,
  CHECK_TIMEPOINT_EXTRACTED_DATA_ROUTE,
  tokenizeRoute,
} from 'query/routes';
import { Timepoint } from 'types/DataExtraction';

export interface TimepointCreatedResponse {
  id: string;
  outcome_id: string;
  template_field_id: string;
  name: string;
}

export type TimepointPromise = Promise<Timepoint | void>;

export const createTimepoint = async (
  outcomeId: string,
  templateFieldId: string,
  onRequestError?: RequestErrorHandler
): Promise<TimepointCreatedResponse> => {
  return withRequestRetry(
    () => _createTimepoint(outcomeId, templateFieldId),
    onRequestError
  );
};

const _createTimepoint = async (
  outcomeId: string,
  templateFieldId: string
): Promise<TimepointCreatedResponse> => {
  if (!navigator.onLine)
    throw new OfflineError('No network connection detected');

  const url = tokenizeRoute(CREATE_TIMEPOINT_ROUTE, {
    outcome_id: outcomeId,
  });

  const body: any = {
    template_field_id: templateFieldId,
  };

  return fetch(url.toString(), {
    method: 'POST',
    body: JSON.stringify(body),
    ...defaultFetchOptions(),
  }).then((response) => {
    return _handleJsonResponse(response);
  });
};

export const destroyTimepoint = async (
  timepointId: string,
  onError?: RequestErrorHandler
) => {
  return withRequestRetry(() => _destroyTimepoint(timepointId), onError);
};

const _destroyTimepoint = async (timepointId: string) => {
  const url = tokenizeRoute(DESTROY_TIMEPOINT_ROUTE, {
    timepoint_id: timepointId,
  });

  return fetch(url.toString(), {
    method: 'DELETE',
    ...defaultFetchOptions(),
  }).then((response) => {
    return _handleJsonResponse(response);
  });
};

export const checkTimepointExtractedStatus = async (
  timepointId: string,
  onError?: RequestErrorHandler
): Promise<CheckExistingExtractionDataResponse> => {
  return withRequestRetry(
    () => _checkTimepointExtractedStatusRequest(timepointId),
    onError
  );
};

const _checkTimepointExtractedStatusRequest = async (
  timepointId: string
): Promise<CheckExistingExtractionDataResponse> => {
  const url = tokenizeRoute(CHECK_TIMEPOINT_EXTRACTED_DATA_ROUTE, {
    timepoint_id: timepointId,
  });

  return fetch(url.toString(), {
    method: 'GET',
    ...defaultFetchOptions(),
  }).then((response) => {
    return _handleJsonResponse(response);
  });
};

export const updateTimepointReportedTime = async (
  timepointId: string,
  reportedTime: string,
  onError?: RequestErrorHandler
): Promise<any> => {
  return withRequestRetry(
    () => _updateTimepointReportedTime(timepointId, reportedTime),
    onError
  );
};

const _updateTimepointReportedTime = async (
  timepointId: string,
  reportedTime: string
): Promise<any> => {
  const url = tokenizeRoute(UPDATE_TIMEPOINT_ROUTE, {
    timepoint_id: timepointId,
  });

  return fetch(url.toString(), {
    method: 'PUT',
    body: JSON.stringify({ reported_time: reportedTime }),
    ...defaultFetchOptions(),
  }).then((response) => {
    return _handleJsonResponse(response);
  });
};
