import React from 'react';
import styles from '../Form.module.scss';

interface Props {
  children: React.ReactNode;
  alignment?: 'left' | 'right';
  className?: string;
}

const FormButtons = ({
  children,
  className,
  alignment = 'left',
  ...props
}: Props) => {
  const classes = [styles.FormButtons, styles[alignment]];
  if (className) classes.push(className);

  return (
    <div className={classes.join(' ')} {...props}>
      {children}
    </div>
  );
};

export default FormButtons;
