import React from 'react';
import { MembershipStatus as Status } from 'schema/enums';
import unreachable from 'util/unreachable';

const MembershipStatus = ({ status }: { status: Status }) => {
  switch (status) {
    case Status.NotAMember:
      return <>Non-member</>;
    case Status.Member:
      return <>Member</>;
    case Status.Admin:
      return <>Admin</>;
    default:
      return unreachable(status);
  }
};

export default MembershipStatus;
