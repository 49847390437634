import React from 'react';
import {
  Label,
  LabelText,
  DebouncedTextInput,
} from '../../shared/BlockWidgets';
import { JudgementBlock } from '../JudgementBlock';
import { MultilinedText } from '../../shared/MultilinedText';
import styles from './Domain.module.css';
import { DomainBlock } from 'concepts/Extraction/Blocks/Domain/DomainBlock';
import { useDescribedBy, useLabelledBy } from 'hooks/useAccessibleName';

type ReadonlyDomainProps = {
  block: DomainBlock;
};

export type EditableDomainProps = ReadonlyDomainProps & {
  editable: true;
  selected: string | null;
  comments: string | null;
  hideLabels?: boolean;
  invalid?: boolean;
  onChange: (newValue: string) => void;
  onChangeComments: (newComments: string) => void;
  onClick?: () => void;
};
const noop = () => '';
export type DomainProps = ReadonlyDomainProps | EditableDomainProps;

export default function Domain(props: DomainProps): JSX.Element {
  const { block } = props;
  const [labelID, labelledBy] = useLabelledBy();
  const [descriptionID, describedBy] = useDescribedBy();

  function renderLabels() {
    if ('editable' in props && props.hideLabels) {
      return null;
    }

    return (
      <div className={styles.labels}>
        <div id={labelID} className={styles.labelText}>
          {block.label}
        </div>
        {block.description !== '' && (
          <div id={descriptionID} className={styles.description}>
            <MultilinedText>{block.description}</MultilinedText>
          </div>
        )}
      </div>
    );
  }
  const clickHandler = ('editable' in props && props.onClick) || noop;

  function renderJudgementRadioInput(judgement: JudgementBlock): JSX.Element {
    if ('editable' in props) {
      return (
        <input
          type="radio"
          name={block.id}
          value={judgement.id}
          checked={judgement.id === props.selected}
          onChange={() => {
            props.onChange(judgement.id);
          }}
          className={styles.judgementInput}
          onClick={clickHandler}
        />
      );
    } else {
      return (
        <input
          type="radio"
          name={block.id}
          value={judgement.id}
          disabled
          className={styles.judgementInput}
        />
      );
    }
  }

  function renderCommentsInput(): JSX.Element {
    if ('editable' in props) {
      return (
        <Label className={styles.commentsLabel}>
          <LabelText>Supporting text</LabelText>
          <DebouncedTextInput
            placeholder="Enter supporting text about your judgement"
            value={props.comments || ''}
            onChange={(e) => {
              props.onChangeComments(e.target.value);
            }}
            onClick={clickHandler}
          />
        </Label>
      );
    } else {
      return (
        <div className={styles.commentsHint}>
          Extractors will also be able to add supporting text to justify their
          judgements
        </div>
      );
    }
  }

  return (
    <div role="group" {...labelledBy} className={styles.container}>
      {renderLabels()}

      <ol
        role="radiogroup"
        className={styles.list}
        {...describedBy}
        aria-invalid={'editable' in props && props.invalid}
      >
        {block.judgements.map((judgement) => (
          <li key={judgement.id} className={styles.listItem}>
            <label className={styles.judgementButton}>
              {renderJudgementRadioInput(judgement)}
              <span>{judgement.label}</span>
            </label>
          </li>
        ))}
      </ol>

      {renderCommentsInput()}
    </div>
  );
}
