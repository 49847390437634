import React, { InputHTMLAttributes, RefCallback } from 'react';
import { IconList } from '../Icon';
import { Icon } from '../Icon';
import styles from './Checkbox.module.scss';

export interface Props
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'size' | 'type'> {
  size?: 'sm' | 'md' | 'lg';
  intermediate?: boolean;
  isError?: boolean;
}

const Checkbox = (
  { className, children, size, intermediate = false, isError, ...props }: Props,
  ref: React.ForwardedRef<HTMLInputElement>
) => {
  const classes = [styles.CheckboxWrapper];
  if (size) classes.push(styles[size]);
  if (isError) classes.push(styles.isError);
  if (intermediate) classes.push(styles.intermediate);
  if (className) classes.push(className);

  // The <label> is required so that click events are
  // sent to the <input> which is hidden. The .StylngHook
  // is used to position the custom checkbox styling and
  // respond to changes of state in the <input>
  return (
    <div>
      <label className={classes.join(' ')}>
        <input
          type="checkbox"
          className={styles.Checkbox_input}
          ref={ref as React.RefObject<HTMLInputElement>}
          {...props}
        />
        <div className={styles.Checkbox}>
          <Icon
            className={styles.Check}
            icon={intermediate ? IconList.solid.faDash : IconList.solid.faCheck}
          />
          <div className={styles.FocusRing} />
        </div>
        {children}
      </label>
    </div>
  );
};

const forwardedCheckbox = React.forwardRef<any, Props>(Checkbox) as React.FC<
  Props & { ref?: React.RefObject<any> | RefCallback<any> }
>;

export default forwardedCheckbox;
export { forwardedCheckbox as Checkbox };
