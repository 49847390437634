import shouldForwardProp from '@styled-system/should-forward-prop';
import {
  border,
  BorderProps,
  color,
  ColorProps,
  colorStyle,
  ColorStyleProps,
  compose,
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
  ShadowProps,
  shadow,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system';
import styled from '../styled';

type Props = BorderProps &
  ColorProps &
  ColorStyleProps &
  FlexboxProps &
  LayoutProps &
  ShadowProps &
  SpaceProps &
  TypographyProps;

// Apply seperately from styleProps to avoid overwriting specific props
const variantStyleProps = compose(colorStyle, typography);

const styleProps = compose(space, layout, border, flexbox, shadow, color);

// generic type workaround to avoid clash between theme prop and IntrinsicProp "color"
// https://github.com/emotion-js/emotion/issues/1272#issuecomment-472993768
const Box = styled('div', { shouldForwardProp })<Props>(
  variantStyleProps,
  styleProps
);

export default Box;
