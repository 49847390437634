import React, { useState } from 'react';
import styles from './ExpiredPlanSelectionModal.module.scss';
import { PlanOption } from './components/PlanOption';
import { Link, Modal, ModalBody, ModalHeader, Text } from 'components/core';

interface ExpiredPlanSelectionModalProps {
  isOpen: boolean;
  plans: any;
}

export const ExpiredPlanSelectionModal = ({
  isOpen,
  plans,
}: ExpiredPlanSelectionModalProps) => {
  const [isModalOpen, setIsModalOpen] = useState(isOpen);

  return (
    <Modal
      className={styles.ExpiredPlanSelectionModal_content}
      size="custom"
      isOpen={isModalOpen}
      onClose={() => setIsModalOpen(false)}
    >
      <ModalHeader.Container
        className={styles.ExpiredPlanSelectionModal_headingWrapper}
      >
        <ModalHeader.Heading
          type="h2"
          className={styles.ExpiredPlanSelectionModal_heading}
        >
          Choose your plan
        </ModalHeader.Heading>
        <Text size="sm" className={styles.ExpiredPlanSelectionModal_subheading}>
          To continue screening references or extracting data, please choose
          your plan
        </Text>
      </ModalHeader.Container>
      <ModalBody className={styles.ExpiredPlanSelectionModal_body}>
        <div className={styles.ExpiredPlanSelectionModal_plans}>
          {plans.map((plan: any) => (
            <PlanOption plan={plan} key={plan.id} />
          ))}
        </div>
        <Text size="sm">
          <b>Looking for more? </b>
          <Link
            to={'https://www.covidence.org/organizations/'}
            className={styles.ExpiredPlanSelectionModal_link}
          >
            Contact us
          </Link>
          &nbsp;to find out more about our institutional subscriptions
        </Text>
      </ModalBody>
    </Modal>
  );
};
