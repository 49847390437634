import React from 'react';
import RemoveTagsButton from './RemoveTagsButton';

import styles from './StudyTags.module.css';
import { Study } from './StudyTypes';

export interface StudyTagsProps {
  tags: string[];
  study: Study;
  onRemoveSuccess: () => void;
}

export default function StudyTags(props: StudyTagsProps): JSX.Element {
  if (props.tags.length === 0) {
    return <></>;
  }

  return (
    <div className={styles.tagsContainer}>
      <ul className={styles.tags}>
        {props.tags.map((tag) => (
          <li key={tag} className={styles.tag}>
            {tag}
            <RemoveTagsButton
              tag={tag}
              study={props.study}
              onSuccess={props.onRemoveSuccess}
            />
          </li>
        ))}
      </ul>
    </div>
  );
}
