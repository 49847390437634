import React from 'react';

import { Reference } from './StudyTypes';

import { StudyReference } from './StudyReference';

import styles from './StudyReferences.module.css';

interface StudyReferencesProps {
  references: Array<Reference>;
}

export function StudyReferences({ references }: StudyReferencesProps) {
  return (
    <div className={references.length > 1 ? styles.mergedStudies : ''}>
      {references.map((reference, i) => (
        <StudyReference key={i} {...reference} />
      ))}
    </div>
  );
}
