import { get, system, Scale } from 'styled-system';

// defaults, isNumber and getMargin were adapted from styled-system's `space` function
// https://github.com/styled-system/styled-system/blob/master/packages/space/src/index.js#L3-L21
const defaults = {
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
};

const isNumber = (n: unknown) => typeof n === 'number' && !isNaN(n);

const getNegativeMargin = (n: any, scale?: Scale) => {
  if (!isNumber(n)) {
    return get(scale, n, n);
  }

  const absolute = Math.abs(n);
  const value = get(scale, absolute, absolute);
  if (!isNumber(value)) {
    return '-' + value;
  }
  return value * -1;
};

const negativeMargin = system({
  marginX: {
    properties: ['marginLeft', 'marginRight'],
    scale: 'space',
    transform: getNegativeMargin,
    defaultScale: defaults.space,
  },
  marginY: {
    properties: ['marginTop', 'marginBottom'],
    scale: 'space',
    transform: getNegativeMargin,
    defaultScale: defaults.space,
  },
});

export default negativeMargin;
