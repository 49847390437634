import { gql } from '@apollo/client';

const CANCEL_INVITATION = gql`
  mutation cancelInvitation($invitationId: ID!) {
    cancelInvitation(input: { invitationId: $invitationId }) {
      success
      errors {
        message
      }
    }
  }
`;

export default CANCEL_INVITATION;
