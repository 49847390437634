import React from 'react';
import {
  Dialog as ReachDialog,
  DialogProps as ReachDialogProps,
} from '@reach/dialog';
import styles from './Dialog.module.css';
import { NakedButton } from './Button';
import Banner from './Dialog/Banner';
import { useLabelledBy } from 'hooks/useAccessibleName';

type DialogProps = ReachDialogProps & {
  title: string;
  onHideModal?: () => void;
  footer?: React.ReactNode;
};

export default function Dialog({
  title,
  onHideModal,
  footer,
  ...props
}: DialogProps) {
  const [labelID, labelledBy] = useLabelledBy();

  if (onHideModal) {
    console.warn('onHideModel is deprecated. Please use onDismiss.');
    props.onDismiss = onHideModal;
  }
  if (footer) {
    console.warn(
      'The footer prop is deprecated. Just include your footer HTML at the bottom of the component children.'
    );
  }

  return (
    <ReachDialog {...props} {...labelledBy}>
      <div className={styles.header}>
        <h2 className={styles.title} id={labelID}>
          {title}
        </h2>
        <NakedButton
          sx={{ minWidth: '20px', fontSize: '2rem' }}
          className={styles.closeButton}
          onClick={props.onDismiss}
          iconName="times"
          iconOnly
        >
          Close Dialog
        </NakedButton>
      </div>
      <div className={styles.content}>{props.children}</div>
      {footer && <div>{footer}</div>}
    </ReachDialog>
  );
}

export { Banner };
