import React from 'react';
import styles from './Stack.module.css';

interface StackProps {
  component?: React.ElementType<React.HTMLAttributes<HTMLElement>>;
  children?: React.ReactNode;
  gap?: true;
}
export function Stack({
  component: Component = 'div',
  children,
  gap,
}: StackProps): JSX.Element {
  return (
    <Component className={styles.Stack} data-gap={gap}>
      {children}
    </Component>
  );
}
