import React from 'react';

export const InfoIcon = () => (
  <svg
    role="img"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 17C11.45 17 11 16.55 11 16V12C11 11.45 11.45 11 12 11C12.55 11 13 11.45 13 12V16C13 16.55 12.55 17 12 17ZM11 9H13V7H11V9Z"
      fill="#839AC6"
    />
  </svg>
);

export const WarningIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24 12C24 18.6289 18.627 24 12 24C5.37305 24 0 18.6289 0 12C0 5.37498 5.37305 0 12 0C18.627 0 24 5.37498 24 12ZM12 14.4194C10.7707 14.4194 9.77419 15.4159 9.77419 16.6452C9.77419 17.8744 10.7707 18.871 12 18.871C13.2293 18.871 14.2258 17.8744 14.2258 16.6452C14.2258 15.4159 13.2293 14.4194 12 14.4194ZM9.88679 6.41874L10.2457 12.9994C10.2625 13.3073 10.5171 13.5484 10.8255 13.5484H13.1745C13.4829 13.5484 13.7375 13.3073 13.7543 12.9994L14.1132 6.41874C14.1314 6.08613 13.8665 5.80645 13.5334 5.80645H10.4665C10.1334 5.80645 9.86864 6.08613 9.88679 6.41874Z"
      fill="white"
    />
  </svg>
);
