import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Instance } from 'pspdfkit';
import styles from './PDFPreview.module.scss';
import { hideExistingAnnotations } from './lib/hideExistingAnnotations';
import { customiseToolbar } from './lib/customiseToolbar';
import { customiseInlineToolbar } from './lib/customiseInlineToolbar';

export interface PspdfkitConfig {
  key: string;
  base_url: string;
}

export interface PDFPreviewProps {
  url: string;
  label?: string;
  pspdfkitConfig?: PspdfkitConfig;
  allowedToolbarItems?: string[];
  inlineToolbarItems?: (instance: Instance) => unknown[];
  styleSheets?: string[];
}

export const emptyToolbar = () => [];

const defaultConfig = {
  key: '', // license key for PSPDFKit per environment. Leave empty string for evaluation mode.
  base_url: '',
};

const defaultToolbarItems = [
  'pan',
  'zoom-in',
  'zoom-out',
  'zoom-mode',
  'pager',
  'sidebar-thumbnails',
  'sidebar-document-outline',
  'custom',
  'search',
];

const defaultStyleSheets: string[] = [];
export function PDFPreview({
  url,
  label,
  pspdfkitConfig = defaultConfig,
  allowedToolbarItems = defaultToolbarItems,
  inlineToolbarItems = emptyToolbar,
  styleSheets = defaultStyleSheets,
}: PDFPreviewProps): JSX.Element {
  const [pdfInstance, setPdfInstance] = useState<Instance>();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const customStylesheets = useMemo(() => ['/pdf-theme.css', ...styleSheets], [
    styleSheets,
  ]);

  let conditionalAttributes = {};

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    let PSPDFKit: any;

    const initPSPDFKit = async () => {
      PSPDFKit = (await import('pspdfkit')).default; // lazy load PSPDFKit library

      // Ignore document permissions to allow users to create annotations, this does not modify the document at all
      // By default the viewer will not enable certain features if document permissions are set
      // Options are frozen the first time PSPDFKit.load is called, and won't be modifiable after that
      if (!PSPDFKit.Options.IGNORE_DOCUMENT_PERMISSIONS) {
        PSPDFKit.Options.IGNORE_DOCUMENT_PERMISSIONS = true;
      }

      const instance = await PSPDFKit.load({
        container, // Container where PSPDFKit will be mounted
        document: url, // remote url of document (or, filename if under /public)
        baseUrl: `${pspdfkitConfig.base_url}/`,
        licenseKey: pspdfkitConfig.key,
        styleSheets: customStylesheets,
        locale: 'en', // force locale to english as we remove non english locales as part of the asset cleaner
      });
      setPdfInstance(instance);
    };

    initPSPDFKit().catch(console.error);

    return () => {
      if (PSPDFKit) PSPDFKit.unload(container);
    };
  }, [url, pspdfkitConfig, styleSheets, customStylesheets]);

  useEffect(() => {
    if (!pdfInstance) return;

    customiseToolbar(pdfInstance, allowedToolbarItems);
    customiseInlineToolbar(pdfInstance, inlineToolbarItems);
    hideExistingAnnotations(pdfInstance);
  }, [pdfInstance, allowedToolbarItems, inlineToolbarItems]);

  if (label) {
    conditionalAttributes = {
      'aria-label': label,
      title: label,
    };
  }

  return (
    <div
      className={styles.iframe}
      role="document"
      ref={containerRef}
      {...conditionalAttributes}
    />
  );
}
