import React, { useEffect, useReducer, useState } from 'react';
import { ThemeProvider } from 'theme-ui';
import { PrimaryButton } from '../../../system/base/Button';
import Dialog from '../../../system/base/Dialog';
import Notice from '../../../system/elements/Notice';
import Spinner from '../../../system/elements/Spinner';
import { fetchNewS3Url, Reference, uploadFileToS3 } from '..';
import StateManager from '../StateManager';
import styles from './FileUploadDialog.module.css';
import DocumentUrlUploader from './DocumentUrlUploader';
import DragAndDropFileUploader from './DragAndDropFileUploader';
import FileList from './FileList';
import { theme } from 'system/theme';

interface Props {
  reviewId: string;
  reference: Reference;
  reviewer: string;
  saveDocument: (file: File, callbackUrl: string) => Promise<{ id: string }>;
  saveDocumentUrl: (url: string) => Promise<boolean>;
  onClose?: (numberOfFullTexts: number) => void;
  buttonMessage?: string;
}

export default function FileUploadDialog({
  reviewId,
  reference,
  reviewer,
  saveDocument,
  saveDocumentUrl,
  onClose,
  buttonMessage = 'Finish',
}: Props) {
  const [isOpen, setOpen] = useState(true);
  const [error, setError] = useState(false);

  // encapsulate most states into `stateManager` shared by all versions of React callbacks
  // 1. `FileUploadDialog`(this file) is mostly responsible for view and thus much simpler
  // 2. when multiple files are being uploaded concurrently, `onFileUploadSuccess` and `onFileUploadError`
  //    callbacks can always access the latest `files` and `uploadingFilesCount` state in `stateManager`
  const [, triggerRerender] = useReducer((x) => x + 1, 0);
  const [stateManager] = useState(new StateManager(reference, triggerRerender));

  useEffect(() => {
    stateManager.loadFiles();
  }, [stateManager]);

  const onFileUploadSuccess = (file: File, saveDocumentUrl: string) => {
    saveDocument(file, saveDocumentUrl).then(
      ({ id }) => stateManager.onFileUploadComplete(id, file),
      () => stateManager.onFileUploadError(file)
    );

    saveGtag();
  };

  const handleOnDismiss = () => {
    setOpen(false);
    const numberOfFullTexts = stateManager.files.length;
    if (onClose) onClose(numberOfFullTexts);
  };

  const saveGtag = () => {
    try {
      gtag('event', 'Upload Full Text', {
        event_category: 'engagement',
      });
    } catch (e) {
      MetaError.notify(e);
    }
  };

  const uploadFile = (
    id: string,
    fileName: string,
    file: File,
    onProgress: (progress: number) => void
  ) =>
    fetchNewS3Url(id, fileName, reviewId).then((data) => {
      const { url, callback_url: callbackUrl } = data;

      return uploadFileToS3(url, file, callbackUrl, onProgress);
    });

  const uploadErrorHandler = stateManager.onFileUploadError.bind(stateManager);

  return (
    <ThemeProvider theme={theme}>
      {/* The Reach region from the Dialog breaks the theme inheritance, so we have to inject the theme manually */}
      <Dialog
        title="Upload full text"
        isOpen={isOpen}
        onDismiss={handleOnDismiss}
      >
        {error && (
          <div className={styles.FileUploadDialog__errorRegion}>
            <Notice type="error">
              There was an error uploading your files
            </Notice>
          </div>
        )}

        <DragAndDropFileUploader
          reference={reference}
          onFileUpload={(file) => {
            stateManager.uploadFile(file, reviewer);

            uploadFile(reference.id, file.name, file, (progress) => {
              stateManager.updateFileState(file, { progress });
              stateManager.onStateChange();
            })
              .then(({ file, callbackUrl }) => {
                onFileUploadSuccess(file, callbackUrl);
              })
              .catch((file) => uploadErrorHandler(file));
          }}
        />
        <DocumentUrlUploader
          onUpload={(url) =>
            saveDocumentUrl(url)
              .then(
                () => stateManager.onDocumentUrlSaved(url),
                () => setError(true)
              )
              .finally(() => saveGtag())
          }
        />

        {stateManager.isLoadingFiles ? (
          <div>
            <Spinner /> Loading documents...
          </div>
        ) : (
          stateManager.files.length > 0 && (
            <FileList
              files={stateManager.files}
              makeFilePrimary={stateManager.makeFilePrimary.bind(stateManager)}
              deleteFile={stateManager.deleteFile.bind(stateManager)}
              unsetDocumentUrl={stateManager.unsetDocumentUrl.bind(
                stateManager
              )}
            />
          )
        )}

        <div className={styles.FileUploadDialog__footer}>
          <PrimaryButton
            onClick={handleOnDismiss}
            disabled={stateManager.uploadingFilesCount > 0}
          >
            {buttonMessage}
          </PrimaryButton>
        </div>
      </Dialog>
    </ThemeProvider>
  );
}
