import { TemplateBlock } from '../BaseBlock';
import { generateBlockID } from '../generateBlockID';

export interface HeadingBlock extends TemplateBlock {
  type: 'Heading';
  label: string;
}

export function blockIsHeading(block: TemplateBlock): block is HeadingBlock {
  return block.type === 'Heading';
}

export function newHeadingBlock(
  label: string,
  extraProps?: Partial<HeadingBlock>
): HeadingBlock {
  return {
    type: 'Heading',
    id: generateBlockID(),
    label,
    ...extraProps,
  };
}

export function updateLabel(heading: HeadingBlock, label: string) {
  return { ...heading, label };
}
