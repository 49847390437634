import React, { useState } from 'react';

import Props, { BillableAccount } from '../reviewInnerProps';
import PlanModal from '../PlanModal';
import {
  ReviewTypeSelector,
  ResearchAreaSelector,
  ReviewTypeOption,
  ResearchAreaOption,
} from '../DropdownSelector';
import { NewAutomationOptions } from '../AutomationOptions';
import {
  QuestionTypeOption,
  QuestionTypeSelector,
} from '../DropdownSelector/QuestionTypeSelector';
import { AccountControls } from '../AccountControls';
import { useCreateReviewMutation } from './useCreateNormalReview';
import { Link, Text } from 'components/core';
import { TextField } from 'system/elements';

const NewRegularReview = ({
  account,
  hasTrialed,
  accounts,
  setSelectedAccountId,
}: Props) => {
  const [title, setTitle] = useState('');
  const [reviewType, setReviewType] = useState<ReviewTypeOption | null>(null);
  const [questionType, setQuestionType] = useState<QuestionTypeOption | null>(
    null
  );
  const [researchArea, setResearchArea] = useState<ResearchAreaOption | null>(
    null
  );
  const [enableRctClassifier, setEnableRctClassifier] = useState(false);
  const [enableRctAutoExclusion, setEnableRctAutoExclusion] = useState(false);

  const [otherReviewTypeLabel, setOtherReviewTypeLabel] = useState<
    string | null
  >(null);

  const [otherQuestionTypeLabel, setOtherQuestionTypeLabel] = useState<
    string | null
  >(null);

  const [otherResearchAreaLabel, setCustomResearchAreaLabel] = useState<
    string | null
  >(null);

  const [planModalOpen, setPlanModalOpen] = useState<boolean>(false);
  const {
    createReview,
    submitting,
    errors,
    success,
    newReviewId,
  } = useCreateReviewMutation();

  const isReviewTypeValid =
    !!reviewType && (reviewType?.value !== 'other' || !!otherReviewTypeLabel);

  const isQuestionTypeValid =
    !!questionType &&
    (questionType?.value !== 'other' || !!otherQuestionTypeLabel);

  const isResearchAreaValid =
    !!researchArea &&
    (researchArea?.value !== 'other' || !!otherResearchAreaLabel);

  const isSubmittable =
    title.length !== 0 &&
    isReviewTypeValid &&
    isQuestionTypeValid &&
    isResearchAreaValid;

  const submit = () => {
    if (!isSubmittable) return;

    if (account.availableReviewCount === 0) {
      setPlanModalOpen(true);
      return;
    }

    createReview({
      variables: {
        accountId: account.id,
        title: title,
        /**
         * Type assertion is safe because isSubmittable has already checked that reviewType is not nullish.
         * For some reason, eslint still considers reviewType to possibly be null.
         *
         * If we wanted to avoid type assertions, you could write a `isReviewTypeNull` type guard but
         * I decided that would be harder to read.
         */
        reviewType: (reviewType as ReviewTypeOption).value,
        ...(reviewType?.value === 'other' && {
          otherReviewTypeLabel,
        }),
        questionType: (questionType as QuestionTypeOption).value,
        ...(questionType?.value === 'other' && {
          otherQuestionTypeLabel,
        }),
        researchArea: (researchArea as ResearchAreaOption).value,
        ...(researchArea?.value === 'other' && {
          otherResearchAreaLabel,
        }),
        ...(researchArea?.value === 'medical_and_health_sciences' && {
          enableRctClassifier,
          enableRctAutoExclusion,
        }),
      },
    });
  };

  if (success && newReviewId) {
    window.location.href = `/reviews/${newReviewId}/authors`;
    return null;
  }

  return (
    <>
      <div className="row">
        <div className="form-group col-md-8">
          <TextField
            inputID="review-title"
            required
            value={title}
            onChange={(e: any) => setTitle(e.target.value)}
            label={<h3>Name your review</h3>}
          />

          <div className="help-block">
            <Text variant="weak">
              Use the full working title or an informal working title. You can
              change this later.
            </Text>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="form-group col-md-8">
          <ReviewTypeSelector
            selectedType={reviewType}
            setType={setReviewType}
            otherTypeLabel={otherReviewTypeLabel}
            setOtherTypeLabel={setOtherReviewTypeLabel}
          />
          <div style={{ paddingTop: '5px' }}>
            <Link
              external
              variant="primary"
              to="https://support.covidence.org/help/types-of-review-explained"
            >
              Learn about the different types of reviews
            </Link>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="form-group col-md-8">
          <QuestionTypeSelector
            selectedType={questionType}
            setType={setQuestionType}
            otherTypeLabel={otherQuestionTypeLabel}
            setOtherTypeLabel={setOtherQuestionTypeLabel}
          />
          <div style={{ paddingTop: '5px' }}>
            <Link
              external
              variant="primary"
              to="https://support.covidence.org/help/what-are-the-different-question-types"
            >
              Learn about the different types of questions
            </Link>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="form-group col-md-8">
          <ResearchAreaSelector
            selectedArea={researchArea}
            setArea={setResearchArea}
            otherAreaLabel={otherResearchAreaLabel}
            setOtherAreaLabel={setCustomResearchAreaLabel}
          />
          {researchArea?.value === 'medical_and_health_sciences' && (
            <NewAutomationOptions
              isRctClassifierEnabled={enableRctClassifier}
              isRctAutoExclusionEnabled={enableRctAutoExclusion}
              onChange={({ enableRctAutoExclusion, enableRctClassifier }) => {
                setEnableRctClassifier(enableRctClassifier);
                setEnableRctAutoExclusion(enableRctAutoExclusion);
              }}
            />
          )}
        </div>
      </div>
      <PlanModal
        accountID={account.id}
        plans={account.availablePlans}
        hasTrialed={hasTrialed}
        isOpen={planModalOpen}
        onDismiss={() => {
          setPlanModalOpen(false);
        }}
      />

      <AccountControls
        submit={submit}
        errors={errors}
        isCochraneReview={false}
        accounts={accounts as BillableAccount[]}
        selectedAccountId={account.id}
        setSelectedAccountId={setSelectedAccountId}
        isSubmittable={isSubmittable}
        submitting={submitting}
      />
    </>
  );
};

export default NewRegularReview;
