import React, { useEffect } from 'react';
import styles from './RefreshCachedStatus.module.css';
import Spinner from 'system/elements/Spinner';

export interface RefreshCachedStatusProps {
  pollProgressUrl: string;
  redirectUrl: string;
}

export const RefreshCachedStatus = ({
  pollProgressUrl,
  redirectUrl,
}: RefreshCachedStatusProps) => {
  const checkRefreshProgress = async () => {
    const response = await fetch(pollProgressUrl);

    // avoid repeating the polling for non-200 status code,
    // as it is very likely to return the same error over and over again
    if (!response.ok) {
      window.location.href = redirectUrl;
    }

    const data = await response.json();
    if (data.in_progress !== true) {
      window.location.href = redirectUrl;
    }
  };

  useEffect(() => {
    const timer = setInterval(checkRefreshProgress, 2000);
    return () => clearInterval(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pollProgressUrl, redirectUrl]);

  return (
    <>
      <div className={styles.loading}>
        <Spinner />
        <p>Updating number of reviewers...</p>
      </div>
    </>
  );
};
