import React from 'react';
import { EditorTextbox } from '../../../FormBuilder/Widgets/EditorTextbox';
import styles from './HeadingEditor.module.css';
import {
  HeadingBlock,
  updateLabel,
} from 'concepts/Extraction/Blocks/Heading/HeadingBlock';
import { TemplateBlock } from 'concepts/Extraction/Blocks/BaseBlock';

export interface HeadingEditorProps {
  block: HeadingBlock;
  placeholder?: string;
  onChange: (updatedHeadingBlock: TemplateBlock) => void;
  onComplete: () => void;
  onRemove: () => void;
}

export default function HeadingEditor({
  block,
  onChange,
  onRemove,
  onComplete,
  placeholder,
}: HeadingEditorProps): JSX.Element {
  return (
    <div className={styles.container}>
      <EditorTextbox
        value={block.label}
        placeholder={placeholder || 'Heading'}
        onChange={(event) => {
          const newLabel = event.target.value;
          onChange(updateLabel(block, newLabel));
        }}
        onKeyDown={(event) => {
          switch (event.key) {
            case 'Backspace':
              if (block.label == '') {
                event.preventDefault();
                onRemove();
              }
              break;
            case 'Enter':
              event.preventDefault();
              onComplete();
          }
        }}
      />
    </div>
  );
}
