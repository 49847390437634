import React from 'react';
import styles from '../../../Menu.module.scss';
import { Link } from 'components/core';
import { Props } from 'components/core/Link/Link.d';

const MenuItemLink = ({ className, ...props }: Props) => {
  const classes = [styles.MenuItem_link];
  if (className) classes.push(className);

  return <Link className={classes.join(' ')} {...props} />;
};

export default MenuItemLink;
