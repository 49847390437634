import { makeThemeBordersScale } from './utils/make-theme';
import { colors } from './colors';

const borders = makeThemeBordersScale({
  table: `1px solid ${colors.greyLighter}`,
  actionable: `1px solid ${colors.primary}`,
  panel: `1px solid ${colors.greyLight}`,
  cochrane: `8px solid ${colors.cochrane}`,
  success: `8px solid ${colors.success}`,
});

export default borders;
