import React, { useEffect, useState } from 'react';
import { useUID } from 'react-uid';
import { VisuallyHidden } from '@reach/visually-hidden';
import { gql, useMutation } from '@apollo/client';
import { Link } from '../Icons/Link';
import styles from './StudyReferences.module.css';
import { Documents } from './Documents';
import { ReferenceToggle } from './ReferenceToggle';
import { DocumentUploader } from './DocumentUploader';
import {
  Document,
  DeleteDocumentMutation,
  DeleteDocumentInput,
  MarkDocumentAsPrimaryMutation,
  MarkDocumentAsPrimaryInput,
} from 'types/graphql';

interface ReferenceDocumentProps {
  id: string;
  fullText: boolean;
  referenceUrl: string;
  documents: Array<Document>;
}

const DELETE_DOCUMENT = gql`
  mutation deleteDocument($documentId: ID!) {
    deleteDocument(input: { documentId: $documentId }) {
      success
    }
  }
`;

const MARK_DOCUMENT_AS_PRIMARY = gql`
  mutation markDocumentAsPrimary($documentId: ID!) {
    markDocumentAsPrimary(input: { documentId: $documentId }) {
      success
      documents {
        id
        fileName
        url
        primaryDocument
      }
    }
  }
`;

export function ReferenceDocuments(props: ReferenceDocumentProps) {
  const { id, referenceUrl } = props;
  const referenceUID = `reference-uid-${useUID()}`;
  const referenceUrlUID = `reference-url-uid-${useUID()}`;

  const [referenceBoxExpanded, setReferenceBoxExpanded] = useState<boolean>(
    false
  );

  const [documents, setDocuments] = useState<Array<Document>>(props.documents);

  const [fullText, setFullText] = useState<boolean>(props.fullText);

  const [deleteDocumentMutation] = useMutation<
    DeleteDocumentMutation,
    DeleteDocumentInput
  >(DELETE_DOCUMENT);

  const [markDocumentAsPrimaryMutation] = useMutation<
    MarkDocumentAsPrimaryMutation,
    MarkDocumentAsPrimaryInput
  >(MARK_DOCUMENT_AS_PRIMARY);

  const hideFiles = () => {
    setFullText(false);
    setReferenceBoxExpanded(false);
  };

  const deleteDocument = (id: string) => {
    deleteDocumentMutation({ variables: { documentId: id } });

    const updatedDocuments = documents.filter((document) => document.id !== id);
    setDocuments(updatedDocuments);

    if (documents.length == 0) {
      hideFiles();
    }
  };

  const markDocumentAsPrimary = (id: string) => {
    markDocumentAsPrimaryMutation({
      variables: { documentId: id },
    }).then(({ data: { markDocumentAsPrimary: result } }: any) => {
      setDocuments(result.documents);
    });
  };

  const displayFiles = () => {
    setFullText(true);
    setReferenceBoxExpanded(true);
  };

  useEffect(() => {
    setDocuments(props.documents);
  }, [props.documents]);

  const renderDocuments = () => {
    return (
      <section aria-labelledby={referenceUID}>
        <VisuallyHidden>
          <h2 id={referenceUID}>References</h2>
        </VisuallyHidden>
        {fullText && referenceUrl && (
          <section
            aria-labelledby={referenceUrlUID}
            className={styles.referenceUrl}
          >
            <h3 id={referenceUrlUID} className={styles.heading}>
              <Link className={styles.icon} />
              Reference URL
            </h3>

            <a
              href={referenceUrl}
              target="_blank"
              rel="noreferrer"
              className={styles.referenceLink}
            >
              {referenceUrl}
            </a>
          </section>
        )}
        <Documents
          markDocumentAsPrimary={markDocumentAsPrimary}
          deleteDocument={deleteDocument}
          documents={documents}
        />
      </section>
    );
  };

  return (
    <div>
      <ReferenceToggle
        visible={referenceBoxExpanded}
        onChange={(visible) => setReferenceBoxExpanded(visible)}
        hasFullText={fullText}
      />

      <DocumentUploader
        referenceId={id}
        updateDocuments={setDocuments}
        displayFiles={displayFiles}
      />
      {referenceBoxExpanded && renderDocuments()}
    </div>
  );
}
