import React from 'react';
import { gql, useMutation } from '@apollo/client';
import { ButtonProps } from 'theme-ui';
import { Study } from './StudyTypes';
import { PrimaryButton } from 'system/base/Button';

import { UnmergeStudiesMutation } from 'types/graphql';

export const UNMERGE_STUDIES_MUTATION = gql`
  mutation UnmergeStudies($input: UnmergeStudiesInput!) {
    unmergeStudies(input: $input) {
      success
      errors {
        message
      }
    }
  }
`;

export type UnmergeStudiesButtonProps = ButtonProps & {
  primaryStudy: Study;
  handleSuccess: () => void;
  handleFailure: () => void;
};

export default function UnmergeStudiesButton(
  props: UnmergeStudiesButtonProps
): JSX.Element {
  const [performUnmergeStudies] = useMutation<UnmergeStudiesMutation>(
    UNMERGE_STUDIES_MUTATION
  );

  return (
    <PrimaryButton
      {...props}
      onClick={() => {
        performUnmergeStudies({
          variables: {
            input: {
              primaryStudyId: props.primaryStudy['id'],
            },
          },
        })
          .then(props.handleSuccess)
          .catch(props.handleFailure);
      }}
    >
      Yes, Un-merge
    </PrimaryButton>
  );
}
