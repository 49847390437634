import React, { FunctionComponent, HTMLAttributes, ReactNode } from 'react';
import classNames from 'classnames';
import { SpacingScale } from '../spacingScale';
import styles from './Stack.module.scss';

export interface StackProps extends HTMLAttributes<any> {
  spacing?: SpacingScale;
  children: ReactNode | ReactNode[];
  component?: keyof HTMLElementTagNameMap | FunctionComponent<any>;
  className?: string;
}

export const Stack = ({
  children,
  spacing = 0,
  component = 'div',
  className,
  ...rest
}: StackProps) =>
  React.createElement(
    component,
    {
      className: classNames(
        styles.wrapper,
        styles[`gap-${spacing}`],
        className
      ),
      ...rest,
    },
    children
  );
