import { useCallback, useEffect, useRef } from 'react';

type TimeoutRef = null | NodeJS.Timeout;
/**
 * A debounce function that uses ONLY setTimeout to delay a callbacks calling.
 *
 * Yes, I am aware of use debounce https://www.npmjs.com/package/use-debounce
 * However that library uses requestAnimationFrame. This makes testing with jest tricky
 * as we can't mock timers and such, this causes issues with race conditions.
 *
 * @param callback The function that will be called after the callback expires
 * @param timeInMillis Time in milliseconds before the timeout function is called
 * @returns a function that will be delayed until the timeInMillis has passed
 */
export const useDebouncedFunction = (
  callback: (...args: any) => void,
  timeInMillis: number
): ((...funcArgs: any[]) => void) => {
  const timeout = useRef<TimeoutRef>(null);
  const timeoutToClear = timeout.current;

  useEffect(() => {
    return () => {
      timeoutToClear && clearTimeout(timeoutToClear);
    };
  }, [timeoutToClear]);

  const debounced = useCallback(
    (...funcArgs: any[]) => {
      clearTimeout(timeout.current as NodeJS.Timeout);
      timeout.current = setTimeout(() => {
        callback(...funcArgs);
      }, timeInMillis);
    },
    [callback, timeInMillis]
  );

  return debounced;
};
