import React, { useState } from 'react';
import Select from 'react-select';
import { OtherStudyLevelTextField } from './OtherStudyLevelTextField';

interface SignupStudyLevelProps {
  objectName: string;
  defaultStudyLevel: string;
  defaultOtherStudyLevel: string;
}

export const SignupStudyLevel = ({
  objectName,
  defaultStudyLevel,
  defaultOtherStudyLevel,
}: SignupStudyLevelProps) => {
  const [studyLevel, setStudyLevel] = useState<string>(defaultStudyLevel);
  const studyLevels = ['Associate', 'Bachelor', 'Masters', 'Doctoral', 'Other'];

  const options = studyLevels.map((studyLevel) => ({
    value: studyLevel,
    label: studyLevel,
  }));

  const defaultOption = options.find(
    ({ value }) => value === defaultStudyLevel
  );

  return (
    <div>
      <div className="input form-group">
        <label htmlFor="studyLevel">
          What level of study are you completing?
        </label>
        <Select
          classNamePrefix="studyLevel"
          options={options}
          defaultValue={defaultOption}
          isClearable={true}
          onChange={(option) => {
            if (!option) return;
            setStudyLevel(option.value);
          }}
        />
      </div>
      {studyLevel === 'Other' && (
        <OtherStudyLevelTextField
          objectName={objectName}
          defaultOtherStudyLevel={defaultOtherStudyLevel}
        />
      )}
      <input
        type="hidden"
        name={`${objectName}[study_level]`}
        value={studyLevel}
      />
    </div>
  );
};
