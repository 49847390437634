import { PivotsType } from 'pivots';
import { TemplateBlock, BlockID } from 'concepts/Extraction/Blocks/BaseBlock';
import {
  TemplateViewModel,
  updateBlock,
  add,
  remove,
  move,
} from 'concepts/Extraction/ViewModel/TemplateViewModel';

export type BlockAction<Block extends TemplateBlock> = PivotsType<{
  update: { block: Block };
  addAfter: { afterBlockID: BlockID; newBlock: Block };
  complete: { blockID: BlockID };
  remove: { blockID: BlockID };
  replaceAll: { newBlocks: Array<Block> };
  selectBlock: { blockID: BlockID };
  move: { blockID: BlockID; to: number };
}>;

export function applyBlockAction<Block extends TemplateBlock>(
  viewModel: TemplateViewModel,
  action: BlockAction<Block>,
  makeNewBlock: () => Block
): {
  newViewModel: TemplateViewModel;
  focusOnBlockID?: string;
} {
  switch (action.type) {
    case 'addAfter': {
      return {
        newViewModel: add(viewModel, action.newBlock, action.afterBlockID),
        focusOnBlockID: action.newBlock.id,
      };
    }
    case 'complete': {
      const newBlock = makeNewBlock();
      return {
        newViewModel: add(viewModel, newBlock, action.blockID),
        focusOnBlockID: newBlock.id,
      };
    }
    case 'remove': {
      let newViewModel = remove(viewModel, action.blockID);

      if (newViewModel.blocks.length === 0) {
        const newFirstBlock = makeNewBlock();
        newViewModel = add(newViewModel, newFirstBlock, null);
        return {
          newViewModel,
          focusOnBlockID: newFirstBlock.id,
        };
      } else {
        const removedIndex: number = viewModel.blocks.findIndex(
          (block) => block.id === action.blockID
        );

        let focusedBlockID = newViewModel.blocks[removedIndex - 1]?.id;
        if (focusedBlockID == null) {
          focusedBlockID = newViewModel.blocks[0].id;
        }
        return {
          newViewModel,
          focusOnBlockID: focusedBlockID,
        };
      }
    }
    case 'update': {
      return {
        newViewModel: updateBlock(viewModel, action.block),
      };
    }
    case 'replaceAll': {
      return {
        newViewModel: {
          ...viewModel,
          blocks: action.newBlocks,
        },
        focusOnBlockID: action.newBlocks[0]?.id,
      };
    }
    case 'move':
      return {
        newViewModel: move(viewModel, action.blockID, action.to),
      };

    default:
      return { newViewModel: viewModel };
  }
}
