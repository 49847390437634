import React, { useState } from 'react';

import styles from './StageSummary.module.css';
import StageDetail from './StageDetail';
import { Permissions, Statistics, Stage } from './Home';
import Icon, { IconStyle } from 'system/base/Icon';

import Panel from 'system/elements/Panel';
import { SummaryTitle } from 'system/base/Heading';
import { Link } from 'system/base/Link';
import { useControls, useLabelledBy } from 'hooks/useAccessibleName';

interface StageProps {
  stage: Stage;
  title: string;
  secondaryLink: string;
  secondaryContent: React.ReactNode;
  studyCategoryCount: React.ReactNode;
  studyCategoryLink: string;
  reviewerName: string;
  permissions: Permissions;
  isOpen: boolean;
  stats: Statistics;
}

const StageSummary = ({
  title,
  stage,
  secondaryContent,
  secondaryLink,
  studyCategoryCount,
  studyCategoryLink,
  reviewerName,
  stats,
  permissions,
  isOpen,
}: StageProps) => {
  const [open, setOpen] = useState(isOpen);
  const [ariaControlledID, ariaControls] = useControls();
  const [labelID, labelledBy] = useLabelledBy();

  return (
    <Panel primary={true} actionable={true} padding={0}>
      <div className={styles.summaryAccordionHeader}>
        <SummaryTitle
          unpadded
          fontWeight={open ? undefined : 'lighter'}
          color={open ? undefined : 'greyDarker'}
        >
          <button
            className={styles.summaryAccordionButton}
            onClick={() => setOpen(!open)}
            aria-expanded={open}
            {...ariaControls}
            id={labelID}
          >
            <Icon
              name="chevron-up"
              iconStyle={IconStyle.Regular}
              mr="10px"
              sx={{
                transform: !open && 'rotate(180deg)',
                fontSize: 4,
                transition: 'transform 150ms ease-out',
              }}
            />
            {title}
          </button>
        </SummaryTitle>
        <div className={styles.summaryAccordionHeaderRightContainer}>
          <Link
            href={secondaryLink}
            className={styles.irrelevantStudiesLink}
            sx={{ padding: 3 }}
          >
            {secondaryContent}
          </Link>
          <Link
            href={studyCategoryLink}
            className={styles.toScreenLink}
            sx={{ padding: 3 }}
          >
            {studyCategoryCount}
          </Link>
        </div>
      </div>
      <StageDetail
        {...labelledBy}
        className={styles.summaryAccordionPanel}
        id={ariaControlledID}
        reviewerName={reviewerName}
        stage={stage}
        stats={stats}
        permissions={permissions}
        hidden={!open}
        role="region"
        studyCategoryLink={studyCategoryLink}
      />
    </Panel>
  );
};

export default StageSummary;
