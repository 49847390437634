import React from 'react';
import pluralize from 'pluralize';
import styles from './Guidelines.module.css';
import { PageTitle, SectionHeading } from 'system/base/Heading';
import { PrimaryLink, SecondaryLink, TertiaryLink } from 'system/base/Link';
import { Link } from 'system/base/Link';
import {
  ListedReview,
  GuidelineReviewModel,
} from 'concepts/Guidelines/ListedReview/ListedReview';

export interface ShowProps {
  guidelineModel: GuidelineModel;
}

export interface GuidelineModel {
  name: string;
  description?: string;
  studiesToTriageCount: number;
  openDiscussionsCount?: number;
  topics: GuidelineTopicModel[];
  readOnly: boolean;
  actions: GuidelineActions;
  manageTopicExpertsFeatureEnabled: boolean;
}
export interface GuidelineActions {
  newReviewUrl: string;
  newTopicUrl: string;
  newImportUrl: string;
  triageTopicAssignmentsUrl: string;
  manageStudiesUrl: string;
  viewCommentsUrl?: string;
  manageMembersUrl: string;
}

export interface GuidelineTopicModel {
  id: number;
  name: string;
  reviews: GuidelineReviewModel[];
  actions: GuidelineTopicActions;
}

export interface GuidelineTopicActions {
  editTopicUrl: string;
}

export function Show({ guidelineModel }: ShowProps): JSX.Element {
  const renderTopic = (topic: GuidelineTopicModel) => (
    <section
      key={topic.id}
      data-testid={`topic_${topic.id}`}
      className={styles.topicSection}
    >
      <SectionHeading>
        <div className={styles.topicHeader}>
          <span data-testid="topic-name">{topic.name}</span>
          {!guidelineModel.readOnly && (
            <Link
              data-testid={`topic-${topic.id}-edit`}
              href={topic.actions.editTopicUrl}
            >
              Edit
            </Link>
          )}
        </div>
      </SectionHeading>
      {topic.reviews.map((review) => (
        <ListedReview
          review={review}
          readOnly={guidelineModel.readOnly}
          key={review.id}
        />
      ))}
    </section>
  );

  const renderImportAndTriage = () => (
    <section
      className={`${styles.card} ${styles.withShadow}`}
      data-testid="import_and_triage"
    >
      <h5>IMPORT AND TRIAGE</h5>
      <div
        className={styles.cardStatusLink}
        data-testid="studies-to-triage-counter"
      >
        {guidelineModel.studiesToTriageCount === 0 ? (
          `${guidelineModel.studiesToTriageCount} studies to triage`
        ) : (
          <Link
            data-testid="studies-to-triage-link"
            href={guidelineModel.actions.triageTopicAssignmentsUrl}
          >
            {guidelineModel.studiesToTriageCount}{' '}
            {pluralize('study', guidelineModel.studiesToTriageCount)} to triage
          </Link>
        )}
      </div>
      <PrimaryLink
        data-testid="import-link"
        href={guidelineModel.actions.newImportUrl}
      >
        Import evidence
      </PrimaryLink>
      <TertiaryLink
        data-testid="manage-studies-link"
        style={{ marginLeft: '10px' }}
        href={guidelineModel.actions.manageStudiesUrl}
      >
        Manage studies
      </TertiaryLink>
    </section>
  );

  const renderDiscussions = () => (
    <section
      className={`${styles.card} ${styles.withShadow}`}
      data-testid="discussions"
    >
      <h5>PANEL DISCUSSIONS</h5>
      <div
        data-testid="open-discussions-counter"
        className={styles.cardStatusLink}
      >
        {guidelineModel.openDiscussionsCount === 0 ? (
          `${guidelineModel.openDiscussionsCount} open discussions`
        ) : (
          <Link
            data-testid="open-discussions-link"
            href={guidelineModel.actions.viewCommentsUrl}
          >
            {guidelineModel.openDiscussionsCount} open{' '}
            {pluralize('discussion', guidelineModel.openDiscussionsCount)}
          </Link>
        )}
      </div>
      <TertiaryLink
        data-testid="view-discussions"
        href={guidelineModel.actions.viewCommentsUrl}
      >
        View discussions
      </TertiaryLink>
      {guidelineModel.manageTopicExpertsFeatureEnabled && (
        <TertiaryLink
          data-testid="manage-experts"
          style={{ marginLeft: '10px' }}
          href={guidelineModel.actions.manageMembersUrl}
        >
          Manage experts
        </TertiaryLink>
      )}
    </section>
  );

  const renderLinks = () => (
    <section>
      <PrimaryLink
        data-testid="create-new-guideline-review"
        href={guidelineModel.actions.newReviewUrl}
        style={{ marginRight: '10px' }}
      >
        Create new review
      </PrimaryLink>
      <SecondaryLink
        data-testid="create-new-guideline-topic"
        href={guidelineModel.actions.newTopicUrl}
      >
        Create new topic
      </SecondaryLink>
    </section>
  );

  return (
    <div>
      <header className={styles.headerPanel}>
        <PageTitle>{guidelineModel.name}</PageTitle>
      </header>

      <div data-testid="panels-container" className={styles.container}>
        {!guidelineModel.readOnly && renderImportAndTriage()}
        {!guidelineModel.readOnly && renderDiscussions()}
      </div>

      {!guidelineModel.readOnly && renderLinks()}

      <section data-testid="topics">
        {guidelineModel.topics.map((topic) => renderTopic(topic))}
      </section>
    </div>
  );
}
