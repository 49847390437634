import React, { useState, useEffect } from 'react';

import { Study } from './StudyTypes';
import styles from './MergeStudiesModal.module.css';
import MergeStudiesButton from './MergeStudiesButton';
import Dialog from 'system/base/Dialog';
import { NakedButton } from 'system/base/Button';
import { Radio } from 'system/elements';

export interface MergeStudiesModalProps {
  isOpen: boolean;
  studies: Array<Study>;
  onMergeSuccess: () => void;
  onMergeFailure: () => void;
  onCancel: () => void;
}

export default function MergeStudiesModal({
  isOpen,
  studies,
  onMergeSuccess,
  onMergeFailure,
  onCancel,
}: MergeStudiesModalProps): JSX.Element {
  const [selectedStudyId, setSelectedStudyId] = useState('');
  const [isMergeButtonDisabled, setIsMergeButtonDisabled] = useState(
    selectedStudyId === ''
  );

  useEffect(() => {
    if (!isOpen) {
      setSelectedStudyId('');
    }
  }, [isOpen]);

  useEffect(() => {
    if (selectedStudyId === '') {
      setIsMergeButtonDisabled(true);
    }
  }, [selectedStudyId]);

  const onMergeStart = (): void => {
    setIsMergeButtonDisabled(true);
  };

  return (
    <Dialog isOpen={isOpen} onDismiss={onCancel} title="Merge: Set a study ID">
      <div>
        {studies.map((study, i) => (
          <div key={`merge-studies-modal-${i}`}>
            <div className={styles.referenceSelection}>
              <p className={styles.studyId}>{study?.studyId || ''}</p>
              <div className={styles.studyIdRadioButton}>
                <Radio
                  name="selectStudyId"
                  onChange={() => {
                    setSelectedStudyId(study.id);
                    setIsMergeButtonDisabled(false);
                  }}
                  label="Set as study ID"
                  checked={selectedStudyId === study.id}
                />
              </div>
            </div>
            {study.references.nodes.map((reference, i) => (
              <div
                className={styles.reference}
                key={`merge-studies-title-${i}`}
              >
                <p className={styles.title}>{reference.title}</p>
                <p className={styles.authors}>
                  #{study.covidenceNumber} &ndash; {reference.authors}
                </p>
              </div>
            ))}
          </div>
        ))}
        <div className={styles.modalFooter}>
          <NakedButton onClick={onCancel} className={styles.cancel}>
            Cancel
          </NakedButton>
          <MergeStudiesButton
            disabled={isMergeButtonDisabled}
            desiredMasterStudyId={selectedStudyId}
            selectedStudyIDs={studies.map((study) => study.id)}
            onStart={onMergeStart}
            onSuccess={onMergeSuccess}
            onFailure={onMergeFailure}
          />
        </div>
      </div>
    </Dialog>
  );
}
