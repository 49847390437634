import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { Checkboxes } from './control/Checkboxes';
import { CheckboxesEditorSettings } from './editor/CheckboxesEditorSettings';
import {
  blockIsCheckboxes,
  CheckboxesBlock,
  CheckboxesBlockValue,
  isCheckboxesBlockValue,
} from './CheckboxesBlock';
import { getInitialCheckboxesBlockValue } from './utils';
import { CheckboxesComparisonRow } from './control/CheckboxesComparisonRow';
import { CheckboxesEditor } from 'concepts/Extraction/Blocks/Checkboxes/editor/CheckboxesEditor';
import {
  ComparisonRowProps,
  ControlProps,
  EditorProps,
  EditorSettingsProps,
  PreviewProps,
} from 'concepts/Extraction/Blocks/BlockRenderer';

const typeIconSVG = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    width="56"
    height="32"
    fill="none"
  >
    <title>Checkboxes</title>
    <rect width="56" height="32" fill="#8D186B" rx="4" />
    <rect width="12" height="12" x="8" y="14" fill="#fff" rx="2" />
    <path
      fill="#8D186B"
      d="m11.947 24.053-2.725-2.725C9.08 21.186 9 20.994 9 20.793c0-.2.08-.393.222-.535.296-.296.774-.296 1.07 0l2.194 2.186 5.222-5.222c.296-.296.774-.296 1.07 0 .296.296.296.774 0 1.07l-5.76 5.761c-.29.296-.775.296-1.071 0z"
    />
  </svg>
);

export const CheckboxesBlockRenderer = {
  Editor({ block, onChange, onComplete, onRemove }: EditorProps) {
    return (
      <CheckboxesEditor
        block={block as CheckboxesBlock}
        onChange={onChange}
        onComplete={onComplete}
        onRemove={onRemove}
      />
    );
  },
  EditorSettings({ block, onChange }: EditorSettingsProps) {
    return (
      <CheckboxesEditorSettings
        block={block as CheckboxesBlock}
        onChange={onChange}
      />
    );
  },
  Control({ block }: ControlProps) {
    const { setValue, control } = useFormContext();
    const blockValue = useWatch({
      control,
      name: block.id,
    });
    const checkboxValue = blockValue?.value;

    if (!blockIsCheckboxes(block)) return <></>;

    const checkboxesValue: CheckboxesBlockValue =
      checkboxValue && isCheckboxesBlockValue(checkboxValue)
        ? checkboxValue
        : getInitialCheckboxesBlockValue(block.options);

    return (
      <Checkboxes
        editable
        block={block}
        value={checkboxesValue}
        comments={blockValue?.comment}
        onChange={(newValue) => {
          setValue(
            block.id,
            { ...blockValue, value: newValue },
            { shouldDirty: true }
          );
        }}
        onChangeComments={(newComment) => {
          setValue(
            block.id,
            { ...blockValue, comment: newComment },
            { shouldDirty: true }
          );
        }}
      />
    );
  },
  Preview({ block }: PreviewProps) {
    return <Checkboxes block={block as CheckboxesBlock} />;
  },
  ComparisonRow({ block, ...rest }: ComparisonRowProps) {
    return (
      <CheckboxesComparisonRow block={block as CheckboxesBlock} {...rest} />
    );
  },
  TypeIcon: () => typeIconSVG,
  /**
   * This really shouldn't ever be used by migrate.ts as we use custom checkboxes logic to produce
   * the updated Checkboxes block record when a superseding template exists.
   * Instead getSupersedingCheckboxesBlockValue should be responsible for migrating to a superseding template block.
   * This is here because type signatures demands it.
   */
  keepResponse() {
    throw new Error('Checkboxes keepResponse should not be called. ');
  },
  displayOnly: false,
};
