import React, { useState, useEffect } from 'react';
import History from './History';
import styles from './RevmanWebExport.module.css';
import { PrimaryButton } from 'system/base/Button';
import Notice from 'system/elements/Notice';

export interface Export {
  id: string;
  createdAt: string;
  description: string;
  completedAt: string | null;
  failed: boolean;
  format: string;
  authenticatedUrl: string;
  type: string;
}

export interface Props {
  exports: Export[];
  historyUrl: string;
  createExportsUrl: string;
}

const RevmanWebExport = (props: Props) => {
  const [exports, setExports] = useState<Export[]>(props.exports);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);

  let pollHistoryTimer: ReturnType<typeof setInterval> | null = null;

  useEffect(() => {
    if (hasPendingExport()) {
      startPollHistory();
    }

    return stopPollHistory;
  });

  const hasPendingExport = () =>
    !!exports.find((ex) => !ex.completedAt && !ex.failed);

  const startPollHistory = () => {
    if (!pollHistoryTimer) {
      pollHistoryTimer = setInterval(() => {
        updateHistory();
      }, 5000);
    }
  };

  const stopPollHistory = () => {
    if (pollHistoryTimer) {
      clearInterval(pollHistoryTimer);
      pollHistoryTimer = null;
    }
  };

  const updateHistory = () => {
    fetch(props.historyUrl)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((exports: Export[]) => {
        if (!hasPendingExport()) {
          stopPollHistory();
        }

        setExports(exports);
      });
  };

  const createRevmanWebExports = () => {
    fetch(props.createExportsUrl, {
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
    })
      .then((response) => {
        if (!response.ok) return Promise.reject('Failed to create exports');

        setErrorMsg(null);
        updateHistory();
        startPollHistory();
      })
      .catch((reason) => {
        setErrorMsg(reason);
      });
  };

  return (
    <div className="new-style floating-box">
      <div className={styles.header}>
        <h2>Recent exports</h2>
        <PrimaryButton onClick={() => createRevmanWebExports()}>
          Export RevMan Web files
        </PrimaryButton>
      </div>
      {errorMsg && (
        <Notice type="error" mb={4}>
          {errorMsg}
        </Notice>
      )}
      <History exports={exports} />
    </div>
  );
};

export default RevmanWebExport;
