import React, { useLayoutEffect, useRef } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { BlockAction } from '../../Blocks/BlockAction';
import styles from './BlocksContainer.module.css';
import { TemplateBlock, BlockID } from 'concepts/Extraction/Blocks/BaseBlock';
import { OrderedList } from 'system/base/List';
import { Factory } from 'concepts/Extraction/Blocks/factory';

export interface BlocksContainerProps<Block extends TemplateBlock> {
  blocks: Array<Block>;
  dispatch: (action: BlockAction<Block>) => void;
  renderMenu: (block: Block) => JSX.Element;
  selectedBlockID: BlockID | null;
}

export function BlocksContainer<Block extends TemplateBlock>({
  blocks,
  renderMenu,
  dispatch,
  selectedBlockID,
}: BlocksContainerProps<Block>): JSX.Element {
  const elRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const el = elRef.current;
    if (el == null) {
      return;
    }

    const listener = (event: FocusEvent) => {
      // Ignore <button> focus from @reach/menu-button that it schedules
      // using requestAnimationFrame() that break our tests.
      if (
        event.target instanceof HTMLInputElement ||
        event.target instanceof HTMLTextAreaElement
      ) {
        const li = event.target.closest('[data-block-id]');
        if (li instanceof HTMLElement) {
          const blockID = li.dataset.blockId;
          if (blockID) {
            dispatch({ type: 'selectBlock', blockID });
          }
        }
      }
    };
    el.addEventListener('focus', listener, true);

    return () => {
      el.removeEventListener('focus', listener, true);
    };
  }, [dispatch]);

  return (
    <DragDropContext
      onDragEnd={({ destination, source }) => {
        if (destination) {
          const block = blocks[source.index];
          if (block) {
            dispatch({
              type: 'move',
              blockID: block.id,
              to: destination.index,
            });
          }
        }
      }}
    >
      <Droppable droppableId="block-droppable-1">
        {(provided) => (
          <div ref={elRef}>
            <OrderedList
              variant="unbulleted"
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {blocks.map((block, index) => {
                const { Editor, TypeIcon } = Factory(block);

                return (
                  <Draggable
                    key={block.id}
                    index={index}
                    draggableId={block.id}
                  >
                    {(provided) => (
                      <li
                        data-block-id={block.id}
                        key={block.id}
                        className={
                          selectedBlockID === block.id
                            ? styles.selected
                            : styles.container
                        }
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                      >
                        <div className={styles.actions}>
                          {renderMenu(block)}
                          <div role="button" {...provided.dragHandleProps}>
                            {TypeIcon ? <TypeIcon /> : <></>}
                          </div>
                        </div>
                        <Editor
                          block={block}
                          index={index}
                          onChange={(updatedBlock) => {
                            dispatch({
                              type: 'update',
                              block: updatedBlock as Block,
                            });
                          }}
                          onRemove={() => {
                            dispatch({ type: 'remove', blockID: block.id });
                          }}
                          onComplete={() => {
                            dispatch({ type: 'complete', blockID: block.id });
                          }}
                        />
                      </li>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </OrderedList>
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}
