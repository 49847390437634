import React from 'react';
import {
  Avatar,
  MenuGroup,
  MenuHeading,
  MenuItem,
  Text,
} from 'components/core';
import { Organization } from 'components/shared/PrimaryNavigation/PrimaryNavigation';

interface Props {
  organizations: Organization[];
  className?: string;
}

const OrganizationSection = ({ organizations, className }: Props) => {
  return (
    <MenuGroup className={className}>
      <MenuHeading>Organizations</MenuHeading>
      {organizations.map((org) => {
        if (org.isAdmin) {
          return (
            <MenuItem.Container key={org.id}>
              <MenuItem.Link to={`/organizations/${org.id}/overview`}>
                <Avatar name={org.name} size="xs" aria-hidden />
                {org.name}
              </MenuItem.Link>
            </MenuItem.Container>
          );
        }

        return (
          <MenuItem.Container key={org.id}>
            <Text component="div">
              <Avatar name={org.name} size="xs" aria-hidden />
              {org.name}
            </Text>
          </MenuItem.Container>
        );
      })}
    </MenuGroup>
  );
};

export default OrganizationSection;
