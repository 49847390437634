import React from 'react';
import styles from './List.module.scss';

interface Props {
  children: React.ReactNode;
  className?: string;
}

export const ListItem = ({ children, className }: Props) => {
  const classes = [styles.listItem];

  if (className) classes.push(className);

  return <li className={classes.join(' ')}>{children}</li>;
};
