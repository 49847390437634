import React, { useState } from 'react';
import { Box } from '@theme-ui/components';
import { omit } from 'ramda';
import classNames from 'classnames';
import { TextInput } from '../shared/BlockWidgets';
import styles from './BaseCheckboxes.module.css';
import { CheckboxesBlock, CheckboxesBlockValue } from './CheckboxesBlock';
import {
  deselectAllCheckboxes,
  toggleCheckboxOption,
  toggleCheckboxOtherOption,
  updateCheckboxOtherOptionValue,
} from './utils';
import { NakedButton } from 'system/base/Button';

type CommonCheckboxesProps = Omit<
  React.ComponentPropsWithoutRef<'ol'>,
  'onChange'
> & {
  block: CheckboxesBlock;
  isDisabled?: boolean;
};

export type EditableBaseCheckboxesProps = CommonCheckboxesProps & {
  onChange: (newValue: CheckboxesBlockValue | null) => void;
  value: CheckboxesBlockValue;
  canClearSelection?: boolean;
};

type UneditableBaseCheckboxesProps = CommonCheckboxesProps & {
  value?: CheckboxesBlockValue;
};

export type BaseCheckboxesProps =
  | EditableBaseCheckboxesProps
  | UneditableBaseCheckboxesProps;

const isEditableBaseCheckboxesProps = (
  props: BaseCheckboxesProps
): props is EditableBaseCheckboxesProps => 'onChange' in props && !!props.value;

export const BaseCheckboxes = (props: BaseCheckboxesProps): JSX.Element => {
  const { block, isDisabled = false, className, value, ...otherProps } = props;

  const [
    showInvalidOtherOptionError,
    setShowInvalidOtherOptionError,
  ] = useState(false);

  const handleCheckboxChange = (optionId: string) => () => {
    const { onChange, value } = props as EditableBaseCheckboxesProps;
    onChange(toggleCheckboxOption(value, optionId));
  };

  const handleOtherOptionCheckboxChange = () => {
    const { onChange, value } = props as EditableBaseCheckboxesProps;
    onChange(toggleCheckboxOtherOption(value));
  };

  const handleOtherOptionTextChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    if (event.target.value.includes(';')) {
      event.preventDefault();
      setShowInvalidOtherOptionError(true);
      return;
    }

    if (showInvalidOtherOptionError) setShowInvalidOtherOptionError(false);

    const newText = event.target.value;

    isEditableBaseCheckboxesProps(props) &&
      props.onChange(
        updateCheckboxOtherOptionValue(
          (props as EditableBaseCheckboxesProps).value,
          newText
        )
      );
  };

  const handleClearSelection = () => {
    const { onChange, value } = props as EditableBaseCheckboxesProps;
    onChange(deselectAllCheckboxes(value, block.options));
  };

  const listProps = omit(['onChange', 'canClearSelection'], otherProps);

  return (
    <>
      <ol className={classNames(styles.list, className)} {...listProps}>
        {block.options.map((option) => (
          <li key={option.id} className={styles.listItem}>
            <label className={styles.optionLabel}>
              <input
                type="checkbox"
                name={block.id}
                value={option.id}
                className={styles.optionInput}
                checked={value?.options[option.id] ?? false}
                onChange={
                  isEditableBaseCheckboxesProps(props)
                    ? handleCheckboxChange(option.id)
                    : undefined
                }
                disabled={isDisabled}
              />
              <span className={styles.optionLabelText}>{option.label}</span>
            </label>
          </li>
        ))}
        {block.otherOption && (
          <li key={block.otherOption.id} className={styles.listItem}>
            <label className={styles.optionLabel}>
              <input
                type="checkbox"
                name={block.id}
                value={block.otherOption.id}
                className={styles.optionInput}
                checked={value?.otherOption.selected ?? false}
                onChange={
                  isEditableBaseCheckboxesProps(props)
                    ? handleOtherOptionCheckboxChange
                    : undefined
                }
                disabled={isDisabled}
              />
              <span className={styles.optionLabelText}>Other</span>
              <div className={styles.checkboxIndentContainer}>
                <TextInput
                  aria-label={`${block.id}_other_option_text`}
                  name={`${block.id}_other_option_text`}
                  className={styles.otherOptionText}
                  disabled={isDisabled || !value?.otherOption.selected}
                  value={value?.otherOption.value || ''}
                  onChange={
                    isEditableBaseCheckboxesProps(props)
                      ? handleOtherOptionTextChange
                      : undefined
                  }
                />
                {showInvalidOtherOptionError && (
                  <Box role="alert" paddingTop={1} color="orange">
                    The “;” character cannot be used
                  </Box>
                )}
              </div>
            </label>
          </li>
        )}
      </ol>

      {isEditableBaseCheckboxesProps(props) && props.canClearSelection && (
        <NakedButton onClick={handleClearSelection}>
          Clear above selection
        </NakedButton>
      )}
    </>
  );
};
