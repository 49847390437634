import React, { useState, useRef, useEffect } from 'react';
import { Input } from 'theme-ui';
import {
  addColumn,
  addRow,
  removeColumn,
  removeRow,
  updateHeaderLabel,
} from '../../utils/block';
import { TableEditorSettingsProps } from './TableEditorSettings';
import { DynamicFields } from 'system/elements/DynamicFields/DynamicFields';
import { useDebouncedFunction } from 'hooks/useDebouncedFunction';

interface TableHeaderFieldsProps extends TableEditorSettingsProps {
  headerType: 'row' | 'column';
  formDescription: string;
}

export const TableHeaderFields = ({
  block,
  onChange,
  headerType,
  formDescription,
}: TableHeaderFieldsProps) => {
  // We use an internal state instead of props so that when users type fields
  // quickly, we can debounce the onChange callback whilst having the input
  // field value change instantaneously.
  const [tableBlockState, setTableBlockState] = useState(block);
  const headerKey = headerType === 'row' ? 'rows' : 'cols';
  const headerIDs = tableBlockState.structure[headerKey];

  useEffect(() => {
    setTableBlockState(block);
  }, [block]);

  const debouncedOnChange = useDebouncedFunction(onChange, 500);

  // To focus on the the new field when user clicks 'add' button
  const [doesNewFieldNeedFocus, setDoesNewFieldNeedFocus] = useState(false);

  const lastInputEl = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!doesNewFieldNeedFocus) return;

    if (lastInputEl.current) {
      lastInputEl.current.focus();
    }
    setDoesNewFieldNeedFocus(false);
  }, [doesNewFieldNeedFocus]);

  const addHeader =
    headerType === 'row' ? addRow(tableBlockState) : addColumn(tableBlockState);

  const removeHeader =
    headerType === 'row'
      ? removeRow(tableBlockState)
      : removeColumn(tableBlockState);

  const handleAddField = () => {
    const updatedTableBlock = addHeader();
    setTableBlockState(updatedTableBlock);
    setDoesNewFieldNeedFocus(true);
    onChange(updatedTableBlock);
  };

  const getHandleRemoveField = (headerID: string) => () => {
    const updatedTableBlock = removeHeader(headerID);
    setTableBlockState(updatedTableBlock);
    onChange(updatedTableBlock);
  };

  const getHandleOnChange = (
    headerID: string
  ): React.ChangeEventHandler<HTMLInputElement> => (e) => {
    const updatedTableBlock = updateHeaderLabel(tableBlockState)(headerID)(
      e.target.value
    );
    setTableBlockState(updatedTableBlock);
    debouncedOnChange(updatedTableBlock);
  };

  const dynamicFields = headerIDs.map((headerID, index) => {
    const { label } = tableBlockState.headers[headerID];
    const ariaLabel = `${headerType} ${index + 1}`;
    const isLastInput = headerIDs.length === index + 1;

    return {
      id: headerID,
      label: ariaLabel,
      field: (
        <Input
          id={headerID}
          aria-label={ariaLabel}
          value={label}
          onChange={getHandleOnChange(headerID)}
          ref={isLastInput ? lastInputEl : undefined}
        />
      ),
    };
  });

  return (
    <DynamicFields
      label={formDescription}
      addFieldButtonText={`Add ${headerType}`}
      onAddField={handleAddField}
      onRemoveField={(id) => getHandleRemoveField(id)}
      fields={dynamicFields}
    />
  );
};
