import React from 'react';
import classNames from 'classnames/dedupe';
import { WarningIcon, InfoIcon } from '../Icons';
import styles from './Banner.module.css';

export default function Banner({
  type,
  children,
  className,
  hasIcon = true,
}: {
  type: string;
  children: JSX.Element;
  hasIcon?: boolean;
  className?: string;
}) {
  let icon;

  switch (type) {
    case 'warning':
      icon = <WarningIcon />;
      break;
    case 'info':
      icon = <InfoIcon />;
      break;
  }

  return (
    <div className={classNames(styles[type], className)}>
      {hasIcon && <div className={styles.icon}>{icon}</div>}
      <div className={styles.container}>{children}</div>
    </div>
  );
}
