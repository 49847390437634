import React from 'react';
import { Input, InputProps } from 'theme-ui';

export function EditorTextbox({
  value,
  ...props
}: Omit<InputProps, 'ref'>): JSX.Element {
  return (
    <Input
      {...props}
      type="text"
      sx={{
        borderWidth: 0,
        borderColor: 'transparent',
        lineHeight: '23px',
        fontSize: 2,
        outline: 'none',
      }}
      value={value as string | undefined}
    />
  );
}
