import React, { useState } from 'react';
import styles from '../../ConfirmationDialog.module.scss';
import {
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  Text,
  Checkbox,
} from 'components/core';

interface Props {
  onConfirm?: () => void;
  onClose?: () => void;
}

const ConfirmationDialog = ({
  onClose = () => '',
  onConfirm = () => '',
}: Props) => {
  const [isOpen, setOpen] = useState(true);
  const [isConfirmed, setConfirmed] = useState(false);
  const [error, setError] = useState('');

  return (
    <Modal isOpen={isOpen}>
      <ModalHeader>Are you sure?</ModalHeader>
      <ModalBody>
        <Text className={styles.ConfirmationDialog_text}>
          You&apos;re about to <b>permanently delete this field</b> and any
          associated data.
        </Text>
        <div>
          <Checkbox
            onChange={(e) => {
              setConfirmed(e.target.checked);
            }}
            isError={!!error}
          >
            Yes, I understand extracted data will be permanently deleted and
            that this action cannot be undone.
          </Checkbox>
          {error ? (
            <Text variant="danger" size="sm">
              {error}
            </Text>
          ) : undefined}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          type="neutral"
          variant="outline"
          onClick={() => {
            onClose();
            setOpen(false);
          }}
        >
          Cancel
        </Button>
        <Button
          type="danger"
          onClick={() => {
            if (!isConfirmed)
              return setError(
                'Please confirm you understand the impact of deleting'
              );
            onConfirm();
            onClose();
            setOpen(false);
          }}
        >
          Delete
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmationDialog;
