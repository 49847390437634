import { update } from 'ramda';
import { UnresolvedTableCellIDs } from 'concepts/Extraction/ViewModel/tables/types';
import { CellPosition } from 'concepts/Extraction/Blocks/Table/BaseTable/types';

export const removeFromUnresolvedTableCellIDs = (
  unresolvedTableCellIDs: UnresolvedTableCellIDs
) => ({ rowID, columnID }: CellPosition): UnresolvedTableCellIDs => {
  const rowIndex = unresolvedTableCellIDs.findIndex(
    (rowData) => rowData.rowID === rowID
  );

  const rowIsNotInUnresolvedCells = rowIndex === -1;

  if (rowIsNotInUnresolvedCells) {
    return unresolvedTableCellIDs;
  }

  const rowData = unresolvedTableCellIDs[rowIndex];
  const updatedColumnIDs = [...rowData.columnIDs].filter(
    (id) => id !== columnID
  );

  if (!updatedColumnIDs.length) {
    const updatedUnresolvedTableCellIDs = unresolvedTableCellIDs.filter(
      (rowData) => rowData.rowID !== rowID
    );
    return updatedUnresolvedTableCellIDs;
  }

  const updatedRowData = { ...rowData, columnIDs: new Set(updatedColumnIDs) };

  /**
   * Creates new unresolvedTableCellIDs, adding new column id to the existing row
   * object.
   */
  const updatedUnresolvedTableCellIDs = update(
    rowIndex,
    updatedRowData,
    unresolvedTableCellIDs
  );

  return updatedUnresolvedTableCellIDs;
};

const addOrUpdateCellInUnresolvedTableCellIDs = (
  unresolvedTableCellIDs: UnresolvedTableCellIDs
) => ({ rowID, columnID }: CellPosition): UnresolvedTableCellIDs => {
  const matchingRowIndex = unresolvedTableCellIDs.findIndex(
    (rowData) => rowData.rowID === rowID
  );

  const rowIsNotInUnresolvedCells = matchingRowIndex === -1;

  /**
   * Add new row with columnID.
   */
  if (rowIsNotInUnresolvedCells) {
    const updatedUnresolvedTableCellIDs = [
      ...unresolvedTableCellIDs,
      {
        rowID,
        columnIDs: new Set([columnID]),
      },
    ];

    return updatedUnresolvedTableCellIDs;
  }

  const rowData = unresolvedTableCellIDs[matchingRowIndex];

  /**
   * Creates new unresolvedTableCellIDs, adding new column id to the existing row
   * object.
   */
  const updatedUnresolvedTableCellIDs = update(
    matchingRowIndex,
    {
      ...rowData,
      columnIDs: new Set([...rowData.columnIDs, columnID]),
    },
    unresolvedTableCellIDs
  );

  return updatedUnresolvedTableCellIDs;
};

export const updateUnresolvedTableCellIDs = (
  unresolvedTableCellIDs: UnresolvedTableCellIDs,
  { isResolved, ...cellPosition }: CellPosition & { isResolved: boolean }
): UnresolvedTableCellIDs => {
  const updatedUnresolvedTableCellIDs = isResolved
    ? removeFromUnresolvedTableCellIDs(unresolvedTableCellIDs)(cellPosition)
    : addOrUpdateCellInUnresolvedTableCellIDs(unresolvedTableCellIDs)(
        cellPosition
      );

  return updatedUnresolvedTableCellIDs;
};
