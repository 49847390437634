import React, { ReactNode } from 'react';
import classNames from 'classnames';
import styles from '../ModalHeader.module.scss';

interface ModalHeaderContainerProps {
  children: ReactNode;
  className?: string;
}

const ModalHeaderContainer = ({
  children,
  className,
}: ModalHeaderContainerProps) => {
  return (
    <header
      className={classNames(styles.ModalHeader, className)}
      data-testid="ModalHeader"
    >
      {children}
    </header>
  );
};

export default ModalHeaderContainer;
