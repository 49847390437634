import { BaseBlock } from 'concepts/Extraction/Blocks/BaseBlock';
import { generateBlockID } from 'concepts/Extraction/Blocks/generateBlockID';

export interface OptionBlock extends BaseBlock {
  type: 'Option';
  label: string;
}

export const blockIsOption = (block: BaseBlock): block is OptionBlock =>
  block.type === 'Option';

export const newOptionBlock = (
  label: string,
  extraProps?: Partial<OptionBlock>
): OptionBlock => ({
  type: 'Option',
  id: generateBlockID(),
  label,
  ...extraProps,
});

export const updateLabel = (multipleChoice: OptionBlock, label: string) => ({
  ...multipleChoice,
  label,
});
