import React, { useState } from 'react';
import styles from './ExtractionToolChangeConfirmationDialog.module.scss';
import {
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  Text,
} from 'components/core';

interface Props {
  title: string;
  onConfirm: () => void;
  onCancel: () => void;
}

const ExtractionToolChangeConfirmationDialog = ({
  title,
  onCancel,
  onConfirm,
}: Props) => {
  const [isOpen, setOpen] = useState(true);

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onCancel();
        setOpen(false);
      }}
    >
      <ModalHeader>{title}</ModalHeader>
      <ModalBody>
        <Text className={styles.paragraph}>
          If you have started extraction, the data you have extracted will not
          be transferred to the other extraction tool.
        </Text>
        <Text className={styles.paragraph}>
          Any extracted data will be saved in the previous tool and you can
          always revert back if you change your mind.
        </Text>
        <Text>Are you sure you want to proceed?</Text>
      </ModalBody>
      <ModalFooter>
        <Button
          type="neutral"
          variant="outline"
          onClick={() => {
            onCancel();
            setOpen(false);
          }}
        >
          Cancel
        </Button>
        <Button
          type="brand"
          onClick={() => {
            onConfirm();
            setOpen(false);
          }}
        >
          Yes, change
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ExtractionToolChangeConfirmationDialog;
