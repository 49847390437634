import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import styles from '../DefaultTemplate.module.scss';
import { LabelError } from '../../../components/editableFieldValidation';
import { Button, FormControl, Icon, IconList, Input } from 'components/core';
interface Props {
  name: string;
  index: number;
  onDelete: () => void;
  validate?: (
    value: string,
    existingFields: Array<{ name: string }>
  ) => string | undefined;
}

const EditableField = ({
  name,
  index,
  onDelete,
  validate = () => undefined,
}: Props) => {
  const { getValues, trigger } = useFormContext();

  useEffect(() => {
    return () => {
      trigger(name).then(() => '');
    };
  }, [name, trigger]);

  return (
    <Controller
      rules={{
        required: LabelError.REQUIRED,
        validate: (value) => {
          return validate(value, getValues(name));
        },
      }}
      name={`${name}.${index}.name` as const}
      render={({ field, fieldState }) => {
        return (
          <div className={styles.EditableField}>
            <FormControl error={fieldState.error?.message}>
              <Input {...field} data-testid={`${name}.${index}.name`} />
            </FormControl>
            <Button
              data-testid={`${name}.${index}.delete`}
              iconOnly
              onClick={onDelete}
              variant="ghost"
            >
              <Icon icon={IconList.light.faTrashCan}>Delete</Icon>
            </Button>
          </div>
        );
      }}
    />
  );
};

export default EditableField;
