import React from 'react';
import { StudyModel } from 'containers/Guidelines/Triage/StudyModel';
import StudyDetails from 'concepts/Studies/StudyDetails';
import { Abstract } from 'concepts/Studies/Abstract';

export interface GuidelineStudyProps {
  study: StudyModel;
  showAbstract?: boolean;
}

export function GuidelineStudy({
  study,
  showAbstract = true,
}: GuidelineStudyProps) {
  return (
    <section data-testid={`study-details-${study.id}`}>
      <StudyDetails study={study} showPublisherInfo={false} />
      {study.abstract && (
        <Abstract text={study.abstract} showAbstract={showAbstract} />
      )}
    </section>
  );
}
