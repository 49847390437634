import React from 'react';
import styles from './IsolationPage.module.scss';
import { Button, Heading, Icon, IconList } from 'components/core';

interface Props {
  back?: string | URL;
  title?: string;
  controls?: React.ReactNode;
  children?: React.ReactNode;
}

const IsolationPage = ({ back, title, controls, children }: Props) => {
  return (
    <>
      <header className={styles.IsolationPageHeader}>
        {back ? (
          <Button
            to={back}
            iconOnly
            className={styles.BackButton}
            aria-label="Back"
            variant="ghost"
          >
            <Icon icon={IconList.light.faArrowLeft} />
          </Button>
        ) : null}
        {title ? (
          <Heading type="h5" component="h1" className={styles.Heading}>
            {title}
          </Heading>
        ) : null}
        {controls ? <div className={styles.Controls}>{controls}</div> : null}
      </header>
      <main>
        <div className="container">{children}</div>
      </main>
    </>
  );
};

export default IsolationPage;
