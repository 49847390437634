import React, { ChangeEventHandler, useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { CompareButton } from '../CompareButton/CompareButton';

export type TextAreaOnDemandProps = {
  className: string;
  onChange?: ChangeEventHandler<HTMLTextAreaElement>;
  onBlur?: ChangeEventHandler<HTMLTextAreaElement>;
  onFocus?: (() => void) | undefined;
  value?: string;
  autoFocus?: boolean;
  placeholder?: string | undefined;
  actionRequired?: string | undefined;
};

export const TextAreaOnDemand = (props: TextAreaOnDemandProps) => {
  const [editMode, setEditMode] = useState(false);

  const onBlurOverride: React.FocusEventHandler<HTMLTextAreaElement> = (e) => {
    setEditMode(false);
    if (props.onBlur) {
      props.onBlur(e);
    }
  };

  const { actionRequired, ...cleanProps } = props;
  return (
    <>
      {editMode ? (
        <TextareaAutosize
          {...cleanProps}
          onBlur={onBlurOverride}
          //since this component will be rendered when the user clicks
          //we can presume the user did this intentionally so autofocus is ok.
          //eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={true}
        />
      ) : (
        <CompareButton
          consensus={true}
          resolved={!actionRequired}
          onClick={() => {
            setEditMode(true);
          }}
          onFocus={() => {
            setEditMode(true);
          }}
        >
          {props.value || actionRequired}
        </CompareButton>
      )}
    </>
  );
};

export default TextAreaOnDemand;
