import { Instance } from 'pspdfkit';

// Hide existing annotations
// A user reported seeing many annotations on their uploaded document when viewing data extraction
// These annotations were not 'Covidence annotations'. They were pre-existing on the raw PDF buffer

export const hideExistingAnnotations = async (instance: Instance) => {
  // Loop through all pages, get annotations and convert to an array
  const pagesAnnotations = await Promise.all(
    Array.from({ length: instance.totalPageCount }).map((_, pageIndex) =>
      instance.getAnnotations(pageIndex).then((annotations) => [...annotations])
    )
  );

  const allAnnotations = pagesAnnotations.flat();

  // Loop through annotations and set them to noView, which will hide them from UI
  // If needed in future we can filter by annotation type to hide a particular annotation type
  const updatedAnnotations = allAnnotations.map((annotation) => {
    return annotation.set('noView', true);
  });

  // Apply annotation updates to instance
  instance.update(updatedAnnotations);
};
