import React from 'react';

import LoadingSpinner, { Size, Type } from './components/LoadingSpinner';

interface Props {
  /**
   * The percent to show on the loading bar. Uses a spinner if omitted
   */
  percent?: number;
  size?: Size;
  className?: string;
  type?: Type;
}

const Loading = ({ percent, size, className, type }: Props) => {
  if (percent) {
    // Eventually this should present a loading bar
    throw new Error('Not yet implemented');
  }

  return <LoadingSpinner size={size} className={className} type={type} />;
};

export default Loading;
