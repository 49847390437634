import React, { AriaAttributes, ReactNode } from 'react';
import styles from './Badge.module.scss';

interface Props extends AriaAttributes {
  type?: 'neutral' | 'success' | 'danger' | 'warning' | 'primary';
  variant?: 'solid' | 'outline' | 'subtle';
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  children?: ReactNode;
  className?: string;
  component?: string;
}

export const Badge = ({
  type = 'primary',
  variant = 'subtle',
  size,
  children,
  className,
  component = 'span',
}: Props) => {
  const classes = [styles[type], styles[variant], styles.Badge];
  if (size) classes.push(styles[size]);
  if (className) classes.push(className);
  return React.createElement(
    component,
    {
      className: classes.join(' '),
    },
    children
  );
};

export default Badge;
