import React from 'react';
import { DropdownSelectorProps, DropdownSelector } from './DropdownSelector';

type QuestionTypeValue =
  | 'intervention_question'
  | 'prevention_question'
  | 'etiology_question'
  | 'diagnosis_question'
  | 'prognosis_question'
  | 'qualitative_question'
  | 'other';

export type QuestionTypeOption = { value: QuestionTypeValue; label: string };

export const questionTypeOptions: QuestionTypeOption[] = [
  {
    value: 'intervention_question',
    label: 'Therapy (Intervention / Treatment)',
  },
  { value: 'prevention_question', label: 'Prevention' },
  { value: 'etiology_question', label: 'Etiology (Harm / Causation)' },
  { value: 'diagnosis_question', label: 'Diagnosis' },
  { value: 'prognosis_question', label: 'Prognosis (Forecast)' },
  { value: 'qualitative_question', label: 'Qualitative (Meaning)' },
  { value: 'other', label: 'Other' },
];

export type QuestionTypeLabels = {
  label: 'Question type';
  specifyQuestionType: 'Please specify the question type:';
  missingQuestionType: 'Please select the question type:';
  missingQuestionOther: 'Please name the question type:';
};

export const questionTypeLabels: QuestionTypeLabels = {
  label: 'Question type',
  specifyQuestionType: 'Please specify the question type:',
  missingQuestionType: 'Please select the question type:',
  missingQuestionOther: 'Please name the question type:',
};

export interface QuestionTypeSelectorProps {
  selectedType: DropdownSelectorProps<QuestionTypeOption>['selectedOption'];
  setType: React.Dispatch<React.SetStateAction<QuestionTypeOption | null>>;
  otherTypeLabel: string | null;
  setOtherTypeLabel: React.Dispatch<React.SetStateAction<string | null>>;
}

export const QuestionTypeSelector = ({
  selectedType,
  setType,
  otherTypeLabel,
  setOtherTypeLabel,
  ...otherProps
}: QuestionTypeSelectorProps) => (
  <DropdownSelector<QuestionTypeOption>
    options={questionTypeOptions}
    selectedOption={selectedType}
    setOption={setType}
    name="question-type"
    label={questionTypeLabels.label}
    missingError={questionTypeLabels.missingQuestionType}
    otherLabel={{
      value: otherTypeLabel,
      setOtherLabel: setOtherTypeLabel,
      label: questionTypeLabels.specifyQuestionType,
      missingError: questionTypeLabels.missingQuestionOther,
    }}
    {...otherProps}
  />
);
