// This list was generated by looking at that unicode characters that people could use
// A lot of these symbols appear in different list types in Google Docs or MS Word
// We search for the rendered symbol, instead of the HTML character
// Each of these characters have 3 different html codes, which would make the list massive
// I also haven't come across a usecase where html codes are appearing in the clipboard

const bullets = [
  // Circles
  '⦿',
  '⦾',
  '◉',
  '⊙',
  '⊚',
  '●',
  '•',
  '◦',
  '○',

  // Squares
  '■',
  '□',
  '☐',
  '⧠',

  // Diamonds
  '◆',
  '◇',
  '⬥',
  '⬦',
  '❖',
  '◈',

  // Dashes
  '-', // dash
  '‒', // figure-dash
  '–', // en-dash
  '—', // em-dash
  '⁓',

  // Arrows
  '>',
  '‣',
  '▹',
  '▷',
  '►',
  '▻',
  '▸',
  '➞',
  '➝',
  '➔',
  '➜',
  '➝',
  '➤',
  '➢',
  '➣',
  'ᐅ',
  '❯',
  'ᐳ',

  // Asterisks / Stars
  '*',
  '∗',
  '⁎',
  '✱',
  '✽',
  '⋆',
  '★',
  '☆',
  '✯',
  '✦',
];

export default bullets;
