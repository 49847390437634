import * as React from 'react';
import { usePopover } from './hooks/usePopover/usePopover';
import { PopoverPlacement } from '.';

type PopoverContext = {
  renderCloseButton: boolean;
  onClose?: () => any;
} & ReturnType<typeof usePopover>;

const PopoverContext = React.createContext<PopoverContext | null>(null);

export const usePopoverContext = () => {
  const context = React.useContext(PopoverContext);

  if (context == null) {
    throw new Error('Popover components must be wrapped in <Popover />');
  }

  return context;
};

export type PopoverProps = {
  children: React.ReactNode;
  isOpen?: boolean;
  closeOnBlur?: boolean;
  renderCloseButton?: boolean;
  placement?: PopoverPlacement;
  onClose?: () => any;
  onOpenChange?: () => any;
};

export const Popover = ({
  children,
  isOpen,
  placement = 'bottom',
  closeOnBlur = true,
  renderCloseButton = true,
  onClose,
}: PopoverProps) => {
  const popover = usePopover({
    onClose,
    placement,
    closeOnBlur,
    controlledIsOpen: isOpen,
  });

  return (
    <PopoverContext.Provider
      value={{
        ...popover,
        renderCloseButton,
        onClose,
      }}
    >
      {children}
    </PopoverContext.Provider>
  );
};
