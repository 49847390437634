import { TableResolutionState } from './types';
import {
  CellData,
  CellPosition,
} from 'concepts/Extraction/Blocks/Table/BaseTable/types';
import {
  addOrUpdateCellInTable,
  updateTable,
} from 'concepts/Extraction/Blocks/Table/BaseTable/utils';
import {
  removeFromUnresolvedTableCellIDs,
  updateUnresolvedTableCellIDs,
} from 'concepts/Extraction/Blocks/Table/utils/resolution';

/**
 * If cell data is not null, add the cell to the 'resolved' property in the
 * table resolution state & remove it from 'unresolved'.
 * If cell data is null, remove the cell from 'resolved' & add the
 * cell to 'unresolved'.
 */
export const updateTableResolutionState = (
  { resolvedCellData, unresolvedCellIDs, ...rest }: TableResolutionState,
  { rowID, columnID, rowIndex }: CellPosition,
  data: CellData | null
): TableResolutionState => {
  const newResolvedCellData = updateTable(
    { rowID, columnID, rowIndex },
    data,
    resolvedCellData
  );

  const newUnresolvedCellIDs = updateUnresolvedTableCellIDs(unresolvedCellIDs, {
    rowID,
    rowIndex,
    columnID,
    isResolved: !!data,
  });

  return {
    ...rest,
    resolvedCellData: newResolvedCellData,
    unresolvedCellIDs: newUnresolvedCellIDs,
  };
};

/**
 * Unlike updateTableResolutionState, when new cell data is null,
 * do nothing (do not overwrite existing cell data, nor add cell to
 * unresolvedCellIDs.)
 *
 * @remarks
 *
 * Used by resolveDecidedValues to merge decidedRecords on top of resolutions,
 * but not delete existing resolutions.
 */
export const addToTableResolutionState = ({
  resolvedCellData,
  unresolvedCellIDs,
  ...rest
}: TableResolutionState) => ({ rowID, columnID, rowIndex }: CellPosition) => (
  data: CellData | null
): TableResolutionState => {
  if (data === null)
    return {
      resolvedCellData,
      unresolvedCellIDs,
    };

  const newResolvedCellData = addOrUpdateCellInTable(resolvedCellData)({
    rowID,
    columnID,
    rowIndex,
  })(data);

  const newUnresolvedCellIDs = removeFromUnresolvedTableCellIDs(
    unresolvedCellIDs
  )({
    rowID,
    rowIndex,
    columnID,
  });

  return {
    ...rest,
    resolvedCellData: newResolvedCellData,
    unresolvedCellIDs: newUnresolvedCellIDs,
  };
};
