import {
  isMultipleChoiceBlockValue,
  MultipleChoiceBlock,
} from '../../MultipleChoice/MultipleChoiceBlock';
import { OtherOptionBlock } from './OtherOption/OtherOptionBlock';
import { OptionBlock } from './Option/OptionBlock';
import { BlockRecord, BlockValue } from 'concepts/Extraction/BlocksData';

type OptionLabel = {
  label: string;
  type: OptionBlock['type'] | OtherOptionBlock['type'] | 'invalidOptionValue';
};

/**
 * Determine if the MultipleChoiceBlock value matches an option, a custom
 * OtherOption value, or is an invalid multiple choice value (bug - should not
 * happen).
 */
export const getOptionLabel = (
  record: BlockRecord<BlockValue>,
  block: MultipleChoiceBlock
): OptionLabel => {
  const recordValue = record?.value;

  if (!recordValue || !isMultipleChoiceBlockValue(recordValue)) {
    return {
      label: '',
      type: 'invalidOptionValue',
    };
  }

  /**
   * If recordValue matches an option ID, return the option's label.
   * If not, recordValue is the value and label of OtherOption.
   */
  const matchingOption = block.options.find(({ id }) => id === recordValue);

  return matchingOption
    ? {
        label: matchingOption.label,
        type: 'Option',
      }
    : {
        label: recordValue,
        type: 'OtherOption',
      };
};
