import {
  ApolloClient,
  gql,
  ApolloQueryResult,
  useMutation,
  useApolloClient,
} from '@apollo/client';
import idx from 'idx';
import { useEffect, useReducer, useState, useRef } from 'react';
import { DeepRequired } from 'utility-types';
import useStateLater from './useStateLater';
import useActionManager from './useActionManager';
import useBackoff from './useBackoff';
import useNavigatorOnline from './useNavigatorOnline';
import {
  ReviewStudyCastVoteMutation,
  ReviewStudyCastVoteMutationVariables,
  ReviewStudyScreeningQuery,
  ReviewStudyScreeningQueryVariables,
} from 'types/graphql';
import { ID } from 'util/types';
import {
  VoteAction,
  voteActionsToStudyIds,
  clearLegacyLocalStorage,
} from 'util/votes';

const STUDIES_QUERY = gql`
  query ReviewStudyScreening(
    $reviewId: ID!
    $reviewerId: ID!
    $excludedStudyIds: [ID!]
  ) {
    review: node(id: $reviewId) {
      ... on Review {
        id
        title
        includedKeywords
        excludedKeywords
        studiesAvailableForVoting(
          category: TA
          reviewer: $reviewerId
          excluding: $excludedStudyIds
        ) {
          id
          covidenceNumber
          references {
            nodes {
              primaryAuthor
              title
              abstract
              publication {
                year
              }
            }
          }
        }
      }
    }
  }
`;

const VOTE_MUTATION = gql`
  mutation ReviewStudyCastVote($studyId: ID!, $vote: VoteValue!) {
    recordStudyVote(input: { category: TA, studyId: $studyId, vote: $vote }) {
      success
      errors {
        message
      }
    }
  }
`;

type Study = Exclude<
  DeepRequired<ReviewStudyScreeningQuery>['review'],
  null
>['studiesAvailableForVoting'][number];

// # Options

// sync n votes at a time
const SYNC_BATCH_SIZE = 5;

// always fetch when less than this many unvoted studies in collection
const FETCH_LOW_WATER_MARK = 50;

// # Actions

enum ActionType {
  Vote,
  Fetch,
}

interface HasId {
  id: ID;
}

class FetchError extends Error {
  name = 'Fetch error';
}
class SyncError extends Error {
  name = 'Sync error';
}
class RetryableSyncError extends Error {
  name = 'Retryable Sync error';
}
export class MarkStudyAsDuplicateError extends Error {
  name = 'Mark Study As Duplicate Error';
}

type VoteResult = readonly [
  ActionType.Vote,
  SyncError | RetryableSyncError | null,
  VoteAction
];
type FetchResult = readonly [
  ActionType.Fetch,
  FetchError | null,
  ReadonlyArray<Study> | null
];

function shouldFetchMoreStudies(numStudiesAvailableForVoting: number) {
  return numStudiesAvailableForVoting < FETCH_LOW_WATER_MARK;
}

// this will fire mutations for as many pending votes as necessary
function syncVotes(
  votes: Array<VoteAction>,
  saveVote: (args: {
    variables?: ReviewStudyCastVoteMutationVariables;
  }) => Promise<{ data?: ReviewStudyCastVoteMutation | null }>
): Array<Promise<VoteResult>> {
  return votes.slice(0, SYNC_BATCH_SIZE).map((vote) =>
    saveVote({
      variables: {
        studyId: vote.studyId,
        vote: vote.value,
      },
    })
      .then((result) => {
        if (idx(result, (_) => _.data.recordStudyVote.success)) {
          return [ActionType.Vote, null, vote] as const;
        } else {
          return [
            ActionType.Vote,
            new SyncError(
              idx(result, (_) => _.data.recordStudyVote.errors[0].message) ||
                'Casting vote was not successful'
            ),
            vote,
          ] as const;
        }
      })
      .catch((error: Error) => {
        return [
          ActionType.Vote,
          new RetryableSyncError(error.message),
          vote,
        ] as const;
      })
  );
}

function fetchMoreStudiesAction(
  client: ApolloClient<object>,
  variables: ReviewStudyScreeningQueryVariables,
  studiesLength: number
): Promise<FetchResult> {
  return client
    .query<ReviewStudyScreeningQuery, ReviewStudyScreeningQueryVariables>({
      query: STUDIES_QUERY,
      fetchPolicy: 'network-only',
      variables,
      context: {
        batch: studiesLength > 0,
      },
    })
    .then((result: ApolloQueryResult<ReviewStudyScreeningQuery>) => {
      const errors = idx(result, (_) => _.errors) || [];

      if (errors.length > 0) {
        return [
          ActionType.Fetch,
          new FetchError(JSON.stringify(errors)),
          null,
        ] as const;
      } else {
        const newStudies =
          idx(result.data, (_) => _.review.studiesAvailableForVoting) || null;

        return [ActionType.Fetch, null, newStudies] as const;
      }
    })
    .catch((error: Error) => {
      return [ActionType.Fetch, error, null] as const;
    });
}

export enum AvailableStudiesState {
  StudiesAvailable,
  FetchingStudies,
  Offline,
  NoMoreStudiesFromServer,
}

// Run this syncronously on first render only
function useClearLegacy() {
  const legacyCleared = useRef(false);
  if (!legacyCleared.current) {
    clearLegacyLocalStorage();
    legacyCleared.current = true;
  }
}

function useInitialSync(votes: Array<VoteAction>) {
  const [initialSync, setInitialSync] = useState<boolean>(votes.length > 0);
  const initialStudies = useRef<Array<ID>>();

  // store study ids which weren't yet synced when we mount, so we can avoid
  // counting them as synced "this session"
  if (initialStudies.current === undefined) {
    initialStudies.current = voteActionsToStudyIds(votes);
  }

  useEffect(() => {
    if (initialSync && votes.length === 0) {
      setInitialSync(false);
    }
  }, [initialSync, setInitialSync, votes.length]);

  return [initialSync, initialStudies.current] as const;
}

export default function useStudyVoter(reviewId: ID, reviewerId: ID) {
  const [idle, setIdle] = useStateLater<boolean>(true);
  /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
  const [_fetchingStudies, setFetchingStudies] = useState(true);
  const [syncError, setSyncError] = useState<Error | null>(null);
  const [, backoffDelayOnError, backoffDelayOnSuccess] = useBackoff();
  const [online] = useNavigatorOnline();

  const [studies, addStudies] = useReducer(
    (studies: Array<Study>, newStudies: ReadonlyArray<Study>) =>
      studies.concat(newStudies),
    [] as Array<Study>
  );
  const [noMoreStudiesFromServer, setNoMoreStudiesFromServer] = useState(false);

  // study ids which have been voted on, whose votes may be reversed
  const [syncedVoteHistory, addSyncedVote] = useReducer<
    (state: Array<VoteAction>, action: Array<VoteAction>) => Array<VoteAction>
  >((votes, newVotes) => votes.concat(newVotes), []);

  // delete legacy localStorage entries, before useActionManager instatiates
  useClearLegacy();

  // votes pending sync to server
  const [votes, castVote, clearVotes] = useActionManager<VoteAction>('votes');

  // skips (which are persisted locally)
  const [skips, castSkip, clearSkips] = useActionManager<ID>('skips');

  const [initialSync, initialStudies] = useInitialSync(votes);

  const [
    markStudyAsDuplicateError,
    setMarkStudyAsDuplicateError,
  ] = useState<MarkStudyAsDuplicateError | null>(null);

  // update backoff when online/offline status changes
  useEffect(() => {
    if (online) {
      setIdle(true, backoffDelayOnSuccess());
    } else {
      backoffDelayOnError(5);
    }
  }, [online, backoffDelayOnSuccess, backoffDelayOnError, setIdle]);

  const [saveVote] = useMutation<
    ReviewStudyCastVoteMutation,
    ReviewStudyCastVoteMutationVariables
  >(VOTE_MUTATION, {
    context: {
      batch: true,
    },
  });

  const recentVoteStudyIds = voteActionsToStudyIds([
    ...syncedVoteHistory,
    ...votes,
  ]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const studiesExcludedFromQueue = [...recentVoteStudyIds, ...skips];
  const filteredStudies = studies.filter(
    (study: HasId) => !studiesExcludedFromQueue.includes(study.id)
  );

  const client = useApolloClient();

  useEffect(() => {
    if (!idle) return;

    const studiesExcludedFromFetch = [
      ...studies.map((s) => s.id),
      ...studiesExcludedFromQueue,
    ];

    const pendingActions: Array<Promise<VoteResult | FetchResult>> = [];

    pendingActions.push(...syncVotes(votes, saveVote));

    if (
      shouldFetchMoreStudies(filteredStudies.length) &&
      !noMoreStudiesFromServer
    ) {
      setFetchingStudies(true);
      pendingActions.push(
        fetchMoreStudiesAction(
          client,
          {
            reviewId,
            reviewerId,
            excludedStudyIds: studiesExcludedFromFetch,
          },
          studies.length
        )
          .then((result) => {
            const newStudies = result[2];
            if (newStudies === null) {
              // null means there was an error, just pass the result down the chain
            } else if (newStudies.length == 0) {
              setNoMoreStudiesFromServer(true);
            } else {
              addStudies(newStudies);
            }
            return result;
          })
          .finally(() => {
            setFetchingStudies(false);
          })
      );
    }

    async function asyncEffect() {
      setIdle(false);

      const results = await Promise.all(pendingActions);
      const voteResults = results.filter(function (
        action
      ): action is VoteResult {
        return action[0] === ActionType.Vote;
      });
      const fetchResults = results.filter(function (
        action
      ): action is FetchResult {
        return action[0] === ActionType.Fetch;
      });

      const anyFetchError = fetchResults.find(([, error]) => !!error);

      const hasRetryableVoteErrors = voteResults.some(
        ([, error]) => error instanceof RetryableSyncError
      );

      const invalidVotes = voteResults.filter(
        // if there's an error and it's not retryable
        ([, error]) => !!error && !(error instanceof RetryableSyncError)
      );
      if (invalidVotes.length && !initialSync) {
        // show the user the first error message
        setSyncError(invalidVotes[0][1]);
      }

      const successfulVotes = voteResults.filter(([, error]) => !error);

      if (successfulVotes.length) {
        // move votes from the persisted collection into temporary, undoable collection
        addSyncedVote(successfulVotes.map(([, , result]) => result));
      }

      // clear successful and un-retryable votes from future syncs
      if (invalidVotes.length > 0 || successfulVotes.length > 0) {
        const clearableResults = invalidVotes.concat(successfulVotes);
        clearVotes((result) =>
          clearableResults
            .map(([, , result]) => result)
            .some((vote) => vote.id === (result && result.id))
        );
      }

      // if there's any errors, back off
      if (hasRetryableVoteErrors || anyFetchError) {
        setIdle(true, backoffDelayOnError());
      } else {
        setIdle(true, backoffDelayOnSuccess());
      }
    }

    if (pendingActions.length > 0) asyncEffect();
  }, [
    client,
    reviewId,
    reviewerId,
    studies,
    studiesExcludedFromQueue,
    idle,
    votes,
    saveVote,
    setIdle,
    backoffDelayOnError,
    backoffDelayOnSuccess,
    clearVotes,
    skips,
    filteredStudies.length,
    noMoreStudiesFromServer,
    initialSync,
  ]);

  let availableStudiesState = AvailableStudiesState.StudiesAvailable;
  if (filteredStudies.length == 0) {
    if (noMoreStudiesFromServer) {
      availableStudiesState = AvailableStudiesState.NoMoreStudiesFromServer;
      if (skips.length > 0) {
        clearSkips();
      }
    } else if (!online) {
      availableStudiesState = AvailableStudiesState.Offline;
    } else {
      // we're either fetching studies or about to begin fetching
      availableStudiesState = AvailableStudiesState.FetchingStudies;
    }
  }

  const recentVotesWithoutCurrent = recentVoteStudyIds.filter(
    (study) => !initialStudies.includes(study)
  );

  const markStudyAsDuplicate = async (studyId: string) => {
    fetch('/api/v1/duplicates', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        duplicate: {
          review_study_id: studyId,
        },
      }),
    }).then((response) => {
      if (response.status !== 200) {
        response.json().then((responseData) => {
          setMarkStudyAsDuplicateError(
            new MarkStudyAsDuplicateError(
              idx(responseData, (_) => _.data.error)
            )
          );
        });
      }
    });
  };

  return [
    { syncError },
    filteredStudies,
    {
      recentVoteStudyIds,
      undoableVoteStudyIds: recentVotesWithoutCurrent,
      availableStudiesState,
      hasPendingVotes: votes.length > 0,
      // don't include studies unsynced on page load in the session count
      sessionCount: recentVotesWithoutCurrent.length,
      initialSync,
      markStudyAsDuplicateError: markStudyAsDuplicateError,
    },
    {
      castVote,
      castSkip,
      markStudyAsDuplicate,
    },
  ] as const;
}
