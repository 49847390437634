import React from 'react';
import { BlockControl } from '../Blocks/factory';
import styles from './ExtractForm.module.css';
import { TemplateBlock } from 'concepts/Extraction/Blocks/BaseBlock';

export interface ExtractFormProps {
  blocks: Array<TemplateBlock>;
}

export const ExtractForm = ({ blocks }: ExtractFormProps): JSX.Element => (
  <form
    onSubmit={(e) => {
      e.preventDefault();
    }}
  >
    {blocks.map((block) => (
      <div key={block.id} className={styles.item} data-block-type={block.type}>
        <BlockControl block={block} />
      </div>
    ))}
  </form>
);
