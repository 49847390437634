import React, { ChangeEvent, useEffect, useState } from 'react';
import styles from './MachineLearningRelevancyPopover.module.scss';
import {
  Link,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Text,
} from 'components/core';

interface Props {
  currentSortKey: string;
  allowAISort: boolean;
  aiSortedResults: boolean;
  changeOrder: (e: ChangeEvent<HTMLSelectElement>) => undefined;
}

const mlNotificationCookieLabel = 'seen_ml_notification';
const notificationAlreadySeen = () => {
  const notificationCookie = document.cookie
    .split('; ')
    .find((row) => row.startsWith(`${mlNotificationCookieLabel}=`))
    ?.split('=')[1];

  return notificationCookie === 'true';
};

const MachineLearningRelevancyPopover = ({
  currentSortKey,
  allowAISort,
  aiSortedResults,
  changeOrder,
}: Props) => {
  const calculateIsOpenValue = () => {
    if (notificationAlreadySeen()) {
      return false;
    }
    return sortKey === 'most_relevant_ai' && allowAISort && aiSortedResults;
  };

  useEffect(() => {
    window.addEventListener('ai_sorted_results loaded', () => {
      if (!notificationAlreadySeen()) {
        setIsOpen(true);
      }
    });
  }, []);

  const [sortKey, setSortKey] = useState(currentSortKey);
  const [isOpen, setIsOpen] = useState(() => calculateIsOpenValue());

  if (allowAISort) {
    if (sortKey === 'relevancy') {
      setSortKey('most_relevant_ai');
    }
  } else if (sortKey === 'most_relevant_ai') {
    // allowAISort = false
    setSortKey('relevancy');
  }

  const onClosePopover = () => {
    // TODO document.cookie should include review_id on it too
    //      possibly reviewer_id? Edge case where two reviewers
    //      use the same machine to screen
    document.cookie = `${mlNotificationCookieLabel}=true; Secure`;
    setIsOpen(false);
  };

  const onSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
    changeOrder(e);
    setSortKey(e.target.value);
  };

  return (
    <Popover
      isOpen={isOpen}
      onClose={() => onClosePopover()}
      closeOnBlur={false}
      placement="bottom-end"
    >
      <PopoverTrigger>
        <select
          id="study-order-by"
          className="order-by"
          onChange={onSelectChange}
          value={sortKey}
        >
          <option value="author">Author</option>
          <option value="title">Title</option>
          <option value="most_recent">Most recent</option>
          <option value={allowAISort ? 'most_relevant_ai' : 'relevancy'}>
            Most relevant
          </option>
        </select>
      </PopoverTrigger>
      <PopoverContent className={styles.PopoverContent}>
        <Text>
          Most relevant sort is now using <strong>machine learning</strong> to
          order the studies based on your include and exclude behaviour.
        </Text>
        <Link
          external
          to="https://support.covidence.org/help/sorting-the-title-abstract-screening-list"
          className={styles.learnMoreLink}
        >
          <strong>Learn more about most relevant sort</strong>
        </Link>
      </PopoverContent>
    </Popover>
  );
};

export default MachineLearningRelevancyPopover;
