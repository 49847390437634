import React from 'react';
import { Link, Text } from 'components/core';
import { CriteriaCategory } from 'types';

const Population = () => (
  <>
    <Text>
      The sample or group(s) of individuals or participants of interest in the
      study, e.g. adults over 18 with knee pain, children under 5 with asthma.
    </Text>
    <Link
      to="https://support.covidence.org/help/how-to-create-and-manage-eligibility-criteria#population"
      variant="primary"
      external
    >
      Learn more about Population criteria
    </Link>
  </>
);

const InterventionExposure = () => (
  <>
    <Text>
      The treatments, exposures, or assessments you are evaluating in your
      review, e.g. steroid injections, handwashing.
    </Text>
    <Link
      to="https://support.covidence.org/help/how-to-create-and-manage-eligibility-criteria#intervention_or_exposure"
      variant="primary"
      external
    >
      Learn more about Intervention / Exposure criteria
    </Link>
  </>
);

const ComparatorContext = () => (
  <>
    <Text>
      The alternative used to evaluate your intervention of interest, such as a
      different treatment, a placebo, or even the absence of intervention (i.e.,
      your control group(s)).
    </Text>
    <Link
      to="https://support.covidence.org/help/how-to-create-and-manage-eligibility-criteria#comparator_or_context"
      variant="primary"
      external
    >
      Learn more about Comparator / Context criteria
    </Link>
  </>
);

const Outcome = () => (
  <>
    <Text>
      The specific results or effects being assessed to answer your review
      question, e.g. duration of symptoms.
    </Text>
    <Link
      to="https://support.covidence.org/help/how-to-create-and-manage-eligibility-criteria#outcomes"
      variant="primary"
      external
    >
      Learn more about Outcome criteria
    </Link>
  </>
);

const StudyCharacteristics = () => (
  <>
    <Text>
      Study designs or any other descriptive information about the study, e.g.
      RCTs, published after 2000.
    </Text>
    <Link
      to="https://support.covidence.org/help/how-to-create-and-manage-eligibility-criteria#study_characteristics"
      variant="primary"
      external
    >
      Learn more about Study Characteristics criteria
    </Link>
  </>
);

const Other = () => (
  <>
    <Text>
      Any additional information that affects a study&apos;s eligibility for
      your review or is helpful to consider during screening.
    </Text>
    <Link
      to="https://support.covidence.org/help/how-to-create-and-manage-eligibility-criteria#other"
      variant="primary"
      external
    >
      Learn more about Other criteria
    </Link>
  </>
);

export const PICOHelperContent: Record<CriteriaCategory, () => JSX.Element> = {
  population: Population,
  intervention_exposure: InterventionExposure,
  comparator_context: ComparatorContext,
  outcome: Outcome,
  study_characteristics: StudyCharacteristics,
  other: Other,
};
