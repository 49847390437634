import React, { memo, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import styles from '../../DataExtractionTemplate.module.scss';
import { DataExtractionTemplateState } from '../../DataExtractionTemplate';
import EditableField from '../EditableField';
import {
  validate,
  NO_EQUIVALENT_VALUE,
} from '../../../components/editableFieldValidation';
import ConfirmationDialog from '../ConfirmationDialog';
import { Heading, Icon, IconList, Link } from 'components/core';

interface Props {
  hasExtractedData?: boolean;
}

const InterventionsPanel = ({ hasExtractedData = false }: Props) => {
  const [modal, setModal] = useState<React.ReactElement | null>();
  const { getValues } = useFormContext();
  const interventions = useFieldArray<DataExtractionTemplateState>({
    name: 'interventions.interventions',
  });
  const characteristics = useFieldArray<DataExtractionTemplateState>({
    name: 'interventions.characteristics',
  });
  const MAX_LENGTH = 255;

  return (
    <section className={styles.section}>
      {modal}
      <Heading
        type="h5"
        component="h3"
        variant="brand"
        className={styles.heading}
      >
        Interventions
        <Link
          className={styles.HelpLink}
          onClick={() => ''}
          data-pendo-key="det-interventions"
          aria-label="Get help with interventions"
        >
          <Icon icon={IconList.light.faQuestionCircle} />
        </Link>
      </Heading>
      <div className={styles.details} data-testid="interventions">
        <Heading type="h6" component="h4" variant="neutral">
          Interventions
        </Heading>
        {interventions.fields.map((field, index) => {
          return (
            <EditableField
              key={field.id}
              index={index}
              name="interventions.interventions"
              onDelete={() => {
                const { id } = getValues(
                  `interventions.interventions.${index}`
                );

                if (id && hasExtractedData) {
                  setModal(
                    <ConfirmationDialog
                      onConfirm={() => interventions.remove(index)}
                      onClose={() => setModal(null)}
                    />
                  );
                } else {
                  interventions.remove(index);
                }
              }}
              validate={(value, existingFields) => {
                return validate(
                  value,
                  existingFields,
                  [],
                  NO_EQUIVALENT_VALUE,
                  MAX_LENGTH
                );
              }}
            />
          );
        })}

        <Link
          onClick={() => interventions.append({ name: '' })}
          variant="primary"
          className={styles.AddButton}
        >
          <Icon icon={IconList.light.faPlus} /> Add intervention
        </Link>
      </div>
      <div className={styles.details} data-testid="characteristics">
        <Heading type="h6" component="h4" variant="neutral">
          Intervention characteristics
        </Heading>
        {characteristics.fields.map((field, index) => {
          return (
            <EditableField
              key={field.id}
              index={index}
              name="interventions.characteristics"
              onDelete={() => {
                if (hasExtractedData) {
                  setModal(
                    <ConfirmationDialog
                      onConfirm={() => characteristics.remove(index)}
                      onClose={() => setModal(null)}
                    />
                  );
                } else {
                  characteristics.remove(index);
                }
              }}
              validate={(value, existingFields) => {
                return validate(
                  value,
                  existingFields,
                  [],
                  NO_EQUIVALENT_VALUE,
                  MAX_LENGTH
                );
              }}
            />
          );
        })}
        <Link
          onClick={() => characteristics.append({ name: '' })}
          variant="primary"
          className={styles.AddButton}
        >
          <Icon icon={IconList.light.faPlus} /> Add characteristic
        </Link>
      </div>
    </section>
  );
};

export default memo(InterventionsPanel);
