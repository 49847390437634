import { gql, useQuery } from '@apollo/client';
import idx from 'idx';
import React, { ComponentProps, FunctionComponent } from 'react';
import Box from 'system/base/Box';
import { PrimaryButton } from 'system/base/Button';
import { Link } from 'system/base/Link';
import { ContentHeading } from 'system/base/Heading';
import IconListItem, { IconList } from 'system/elements/IconList';
import Notice from 'system/elements/Notice';
import Panel from 'system/elements/Panel';
import LoadingSpinner from 'system/elements/Spinner';
import {
  ReviewRevmanWebLatestSyncQuery,
  ReviewRevmanWebLatestSyncQueryVariables,
  SyncRunState,
} from 'types/graphql';
import { ID } from 'util/types';

const QUERY = gql`
  query ReviewRevmanWebLatestSync($reviewId: ID!) {
    review: node(id: $reviewId) {
      ... on Review {
        cochraneReviewLink {
          previousSyncRun {
            updatedAt
            state
            syncErrors {
              total
            }
          }
        }
        categoryCounts {
          included
          excluded
        }
        studiesAwaitingClassification: studies(
          filter: { awaitingClassification: true }
        ) {
          total
        }
        studiesOngoing: studies(filter: { ongoingStudy: true }) {
          total
        }
      }
    }
  }
`;

const reviewHref = (reviewId: ID, path = '') => `/reviews/${reviewId}/${path}`;

interface Props {
  reviewId: ID;
}

const SuccessItem: FunctionComponent<
  Omit<ComponentProps<typeof IconListItem>, 'iconName' | 'iconColor'>
> = (props) => (
  <IconListItem
    iconName="check-circle"
    iconColor="success"
    marginBottom={1}
    {...props}
  />
);

const Success: FunctionComponent<{
  reviewId: ID;
  data: ReviewRevmanWebLatestSyncQuery;
}> = ({ reviewId, data }) => {
  const counts = idx(data, (_) => _.review.categoryCounts);
  const studiesOngoing = (
    idx(data, (_) => _.review.studiesOngoing) || { total: 0 }
  ).total;
  const studiesAwaitingClassification = (
    idx(data, (_) => _.review.studiesAwaitingClassification) || { total: 0 }
  ).total;

  return (
    <>
      <Box marginBottom={3}>
        <IconList>
          <SuccessItem textStyle="contentHeading" marginBottom={2}>
            Studies successfully synced to RevMan Web
          </SuccessItem>
          <SuccessItem>
            <strong>
              {counts ? Number(counts.included) - studiesOngoing : 0}
            </strong>{' '}
            included
          </SuccessItem>
          <SuccessItem>
            <strong>{studiesOngoing}</strong> ongoing
          </SuccessItem>
          <SuccessItem>
            <strong>{studiesAwaitingClassification}</strong> awaiting
            classification
          </SuccessItem>
          <SuccessItem>
            <strong>{counts ? counts.excluded : 0}</strong> excluded (including
            reasons)
          </SuccessItem>
        </IconList>
      </Box>
      <PrimaryButton>Open RevMan Web</PrimaryButton>
      <Link href={reviewHref(reviewId, `revman_web_sync/new`)}>
        Back to sync
      </Link>
    </>
  );
};

const PartialError: FunctionComponent<{
  reviewId: ID;
}> = ({ reviewId }) => (
  <>
    <ContentHeading>Some studies were synced to RevMan Web</ContentHeading>
    <Box my={3}>Open Revman Web to view the studies that were synced.</Box>
    <PrimaryButton>Open RevMan Web</PrimaryButton>
    <Link href={reviewHref(reviewId, `revman_web_sync/new`)}>Back to sync</Link>
  </>
);

const TotalError: FunctionComponent = () => (
  <>
    <ContentHeading>Something went wrong...</ContentHeading>
    <p>Try again later. If the problem continues, contact support.</p>
  </>
);

const SyncRevmanWebResult: FunctionComponent<Props> = ({ reviewId }: Props) => {
  const { data, error, loading } = useQuery<
    ReviewRevmanWebLatestSyncQuery,
    ReviewRevmanWebLatestSyncQueryVariables
  >(QUERY, {
    variables: {
      reviewId,
    },
  });

  if (loading) {
    return (
      <Panel primary>
        <LoadingSpinner />
      </Panel>
    );
  }

  if (error) {
    return (
      <Panel primary>
        <Notice type="warning">{error.message}</Notice>
      </Panel>
    );
  }

  const errorCount =
    idx(
      data,
      (_) => _.review.cochraneReviewLink.previousSyncRun.syncErrors.total
    ) || 0;
  const state = idx(
    data,
    (_) => _.review.cochraneReviewLink.previousSyncRun.state
  );

  return (
    <Panel primary>
      {state === SyncRunState.Failed && <TotalError />}
      {state === SyncRunState.Finished && errorCount > 0 && (
        <PartialError reviewId={reviewId} />
      )}
      {state === SyncRunState.Finished && errorCount === 0 && data && (
        <Success reviewId={reviewId} data={data} />
      )}
    </Panel>
  );
};

export default SyncRevmanWebResult;
