import React from 'react';
import { Label, LabelText } from '../../shared/BlockWidgets';
import { MultilinedText } from '../../shared/MultilinedText';
import { Table2Block } from '../types/block';
import { BaseTable } from './BaseTable/BaseTable';
import styles from './Table.module.css';
import { useDescribedBy, useLabelledBy } from 'hooks/useAccessibleName';

export type ReadonlyTableProps = {
  block: Table2Block;
};

export type TableProps = ReadonlyTableProps;

export const Table = (props: TableProps) => {
  const [labelID, labelledBy] = useLabelledBy();
  const [descriptionID, describedBy] = useDescribedBy();

  return (
    <>
      <Label>
        <div className={styles.labelOuter}>
          <div>
            <LabelText id={labelID}>
              {props.block.label || 'Table preview'}
            </LabelText>
            {props.block.description !== '' && (
              <div id={descriptionID} className={styles.description}>
                <MultilinedText>{props.block.description}</MultilinedText>
              </div>
            )}
          </div>
        </div>
      </Label>
      <div>
        <BaseTable
          {...labelledBy}
          {...(!!props.block.description && describedBy)}
          headers={props.block.headers}
          structure={props.block.structure}
        />
      </div>
    </>
  );
};
