import React, { AriaAttributes, ReactNode, useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import styles from './Toast.module.scss';
import { IconType } from 'components/core/Icon/Icon';
import { Button, Icon, IconList } from 'components/core';

export type ToastType =
  | 'neutral'
  | 'info'
  | 'warning'
  | 'success'
  | 'danger'
  | 'primary'
  | 'brand'
  | undefined;

interface Props extends AriaAttributes {
  children: ReactNode;
  /**
   * Icon reference
   */
  icon?: IconType;
  className?: string;
  type?: ToastType;
  variant?: 'solid' | 'subtle';
  role?: 'status' | 'alert';
  /**
   * Sets the aria-live value to assertive. This will interrupt screen readers
   * and should only be used when the notification is time-critical.
   */
  assertive?: boolean;
  // provided by createToast
  toastId?: string;
  visible?: boolean;
  // Set to false to hide the dismiss button on the toast
  showCloseButton?: boolean;
}

const Toast = ({
  children,
  icon,
  role = 'status',
  className,
  type = 'neutral',
  variant = 'solid',
  toastId,
  visible,
  showCloseButton = true,
  ...props
}: Props) => {
  if (!toastId)
    throw new Error(
      'No toast reference provided. Was this Toast created using `createToast`?'
    );

  // Trailing visible state so we can add the visible css after render for entry transitions
  const [isVisible, setVisible] = useState<boolean>(false);

  const classes = [styles.Toast, styles[type], styles[variant]];
  if (className) classes.push(className);
  if (isVisible) classes.push(styles.visible);

  useEffect(() => {
    if (isVisible !== visible) setVisible(!!visible);
  }, [visible, isVisible]);

  return (
    <div className={classes.join(' ')} role={role} {...props}>
      {icon ? (
        <div className={styles.IconContainer}>
          <Icon icon={icon} />
        </div>
      ) : null}
      {children}
      {showCloseButton && (
        <Button
          className={`${styles.ToastButton}`}
          iconOnly
          isNaked
          onClick={() => toast.dismiss(toastId)}
          aria-label="Close toast"
          htmlType="button"
        >
          <Icon icon={IconList.light.faClose} className={styles.closeIcon}>
            Close
          </Icon>
        </Button>
      )}
    </div>
  );
};

export default Toast;
