import React from 'react';
import styles from './AccordionHeader.module.scss';
import { Button, Icon, IconList } from 'components/core';

interface Props {
  children: React.ReactNode;
  className?: string;
  isOpen?: boolean;
  setOpen?: (val: boolean) => void;
  isAlwaysOpen?: boolean;
  allowMultiple?: boolean;
}

const hasControlChildren = (elem: React.ReactNode): boolean => {
  let isControl = false;
  if (React.isValidElement(elem)) {
    ['href', 'to', 'onClick'].forEach((prop) => {
      if (elem.props[prop]) {
        isControl = true;
      }
    });

    if (!isControl && elem.props.children) {
      React.Children.forEach(
        elem.props.children,
        (child) => (isControl = hasControlChildren(child))
      );
    }
  }
  return isControl;
};

const AccordionHeader = ({
  className,
  isOpen,
  setOpen,
  isAlwaysOpen,
  allowMultiple,
  children,
}: Props) => {
  const classes = [styles.AccordionHeader];
  if (className) classes.push(className);
  if (isOpen) classes.push(styles.AccordionHeader_open);

  const displayChildren: React.ReactNode[] = [];
  const controlChildren: React.ReactNode[] = [];

  React.Children.forEach(children, (child) => {
    if (hasControlChildren(child)) {
      controlChildren.push(child);
    } else {
      displayChildren.push(child);
    }
  });

  return (
    <div className={classes.join(' ')}>
      <Button
        onClick={() => {
          setOpen && setOpen(!isOpen);
        }}
        role="group"
        aria-disabled={false}
        disabled={isAlwaysOpen && !allowMultiple && isOpen}
        isNaked={true}
        className={styles.AccordionHeader_button}
      >
        <Icon
          icon={
            isOpen ? IconList.light.faChevronUp : IconList.light.faChevronDown
          }
        />
        {displayChildren}
      </Button>
      {controlChildren.length ? (
        <div className={styles.AccordionControlsContainer}>
          {controlChildren}
        </div>
      ) : undefined}
    </div>
  );
};

export default AccordionHeader;
