import React, { useEffect, useState } from 'react';
import styles from './ReviewEligibilityCriteria.module.scss';
import CriteriaDescription from './components/CriteriaDescription';
import { Banner, Button, Icon, IconList, Text } from 'components/core';
import { Criteria, CriteriaCategory } from 'types/Criteria';
import { getCriteria } from 'query/review/Criteria';
import { getReviewId } from 'query/review';
import { labels } from 'pages/EligibilityCriteria/components/EligibilityCriteriaForm/definitions';

const ReviewEligibilityCriteria = () => {
  const [isFetching, setIsFetching] = useState<boolean>(true);
  const [fetchingFailed, setFetchingFailed] = useState<boolean>(false);
  const [criteria, setCriteria] = useState<Criteria>();
  const reviewId = getReviewId();

  useEffect(() => {
    getCriteria()
      .then((criteria) => {
        setCriteria(criteria);
      })
      .catch(() => {
        setFetchingFailed(true);
      })
      .finally(() => {
        setIsFetching(false);
      });
  }, []);

  if (!isFetching && fetchingFailed) {
    return (
      <div className={styles.container}>
        <Banner type="danger">
          <Text>Unable to load your criteria, please try again later.</Text>
        </Banner>
      </div>
    );
  }

  if (!isFetching && !fetchingFailed && criteria) {
    const sections = [
      CriteriaCategory.StudyCharacteristics,
      CriteriaCategory.Population,
      CriteriaCategory.InterventionExposure,
      CriteriaCategory.ComparatorContext,
      CriteriaCategory.Outcome,
      CriteriaCategory.Other,
    ];

    // We don't want to render the "Other" title if it's the only category present
    const showTitle = Object.keys(criteria).some((key) => {
      return (
        key !== CriteriaCategory.Other &&
        (criteria[key].include.length || criteria[key].exclude.length)
      );
    });

    return (
      <div className={[styles.container, styles.resizable].join(' ')}>
        {sections.map((section) => {
          const { include, exclude } = criteria[section];

          if (!include.length && !exclude.length) return null;

          return (
            <div className={styles.section} key={section}>
              {showTitle && (
                <Text className={styles.sectionTitle}>{labels[section]}</Text>
              )}

              <div className={styles.row}>
                <div className={styles.rowItem}>
                  <Text className={styles.include}>Include</Text>
                  <CriteriaDescription
                    description={include}
                    renderAsList={section !== CriteriaCategory.Other}
                  />
                </div>
                <div className={styles.rowItem}>
                  <Text className={styles.exclude}>Exclude</Text>
                  <CriteriaDescription
                    description={exclude}
                    renderAsList={section !== CriteriaCategory.Other}
                  />
                </div>
              </div>
            </div>
          );
        })}

        <div>
          <Button
            size="xs"
            type="neutral"
            variant="outline"
            to={`/reviews/${reviewId}/criteria`}
          >
            <Icon icon={IconList.light.faPenToSquare} />
            Edit criteria
          </Button>
        </div>
      </div>
    );
  }

  return null;
};

export default ReviewEligibilityCriteria;
