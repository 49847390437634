import {
  ApolloQueryResult,
  QueryHookOptions,
  useQuery,
  QueryResult,
  OperationVariables,
} from '@apollo/client';
import { DocumentNode } from 'graphql';
import { getIn, updateIn } from 'timm';
import { PageInfo } from 'types/graphql';
import { Omit } from 'util/types';

// There may be a way to typecheck the `path` so that it is a recursive set of
// properties on the result object, but we'll hold off for now as it is
// exceptionally slow in TS 2.9 and up (true as of version 3.3)
// ref: https://github.com/microsoft/TypeScript/issues/12290
// ref: https://github.com/Microsoft/TypeScript/issues/23400
// ref: https://github.com/Morglod/ts-pathof

// Acts like `useQuery` but omits the returned `fetchMore` function in lieu of
// the `loadMore` function, which knows how to splice in the new result into the
// previous results, based on the supplied `paginate` property "path".

export type LoadMoreFn<TData, TVariables> = (
  variables?: TVariables
) => Promise<ApolloQueryResult<TData>>;

// Generates a function which wraps fetchMore to splice in the new results with the old ones.
// Takes the `fetchMore` function that Apollo provdes, and the `path` to the GraphQL Relay
// Connection that we are paginating.
function paginator<TData, TVariables>(
  path: string[],
  after: PageInfo['startCursor'],
  fetchMore: QueryResult<TData>['fetchMore']
): LoadMoreFn<TData, TVariables> {
  return (variables?: Partial<TVariables>) =>
    fetchMore({
      variables: { ...variables, after },
      updateQuery: (previousResult, { fetchMoreResult }) =>
        updateIn(previousResult, path as any, (oldData: any) => {
          if (fetchMoreResult) {
            const newData: any = getIn(fetchMoreResult as any, path as any);
            if (newData && newData.pageInfo && newData.nodes) {
              return {
                ...oldData,
                pageInfo: newData.pageInfo,
                nodes: [...oldData.nodes, ...newData.nodes],
              };
            }
            if (newData && newData.pageInfo && newData.edges) {
              return {
                ...oldData,
                pageInfo: newData.pageInfo,
                edges: [...oldData.edges, ...newData.edges],
              };
            }
          }
          return previousResult;
        }),
    });
}

type PaginatedQueryResult<TData, TVariables> = Omit<
  QueryResult<TData, TVariables>,
  'fetchMore'
> & {
  loadMore?: LoadMoreFn<TData, TVariables>;
};

export default function usePaginatedQuery<
  TData,
  TVariables = OperationVariables
>(
  query: DocumentNode,
  {
    paginate: path,
    ...options
  }: QueryHookOptions<TData, TVariables> & { paginate: string[] }
): PaginatedQueryResult<TData, TVariables> {
  const { fetchMore, ...result } = useQuery<TData, TVariables>(query, options);

  // Don't offer any pagination if we don't yet have a base result to work with
  if (result.loading || result.error) return result;

  const connection = getIn(result.data, path);

  // If we have the neccessary pageInfo at this path with more results, return result with loadMore
  if (
    connection &&
    connection.pageInfo &&
    connection.pageInfo.endCursor !== undefined
  ) {
    const pageInfo = connection.pageInfo;
    const cursor = pageInfo.endCursor;

    if (pageInfo.hasNextPage) {
      const loadMore = paginator<TData, TVariables>(path, cursor, fetchMore);
      return { ...result, loadMore };
    }
  } else {
    // Otherwise, by reason of error (probably null result in path) warn and return original result
    // eslint-disable-next-line no-console
    console.warn(
      `Missing pagination \`pageInfo.endCursor\` at path \`${path.join(
        '.'
      )}\` in Query`
    );
  }

  return result;
}
