import React, { ReactNode } from 'react';
import Icon from 'system/base/Icon';

interface Props {
  children: ReactNode;
}

const SuccessMessage = ({ children }: Props) => (
  <span className="tcn green">
    <Icon name="check-circle" />
    {children}
  </span>
);

export default SuccessMessage;
