import React, {
  ReactNode,
  RefObject,
  useRef,
  useState,
  useEffect,
} from 'react';
import classnames from 'classnames';
import styles from './DragAndDropUploader.module.css';
import handleFolders from './traverseFileSystem';

interface Props {
  children?: ReactNode;
  acceptUpload: string;
  allowDirectories?: boolean;
  onFileChange: (files: File[], byDragAndDrop: boolean) => void;
  onDragEnterClassName?: string;
}

const DragAndDropUploader = (
  {
    acceptUpload,
    onFileChange,
    children,
    allowDirectories = false,
    onDragEnterClassName = '',
    ...otherProps
  }: Props,
  ref: React.ForwardedRef<HTMLInputElement>
) => {
  const _ref = useRef<HTMLInputElement>(null);
  const fileRef = (ref ?? _ref) as RefObject<HTMLInputElement>;
  const [droppingFiles, setDroppingFiles] = useState(false);
  const [isDragged, setDragged] = useState(false);

  const dragState = useRef(false);

  const fileChange = (files: File[], byDragAndDrop: boolean) => {
    const input = fileRef.current;
    dragState.current = false;
    if (input) input.value = '';
    onFileChange(files, byDragAndDrop);
  };

  const fileChangeOnceOnly = (fromInput: boolean, files?: File[]) => {
    const fromInputFiles = fileRef.current?.files;
    if (
      fromInput &&
      fromInputFiles &&
      fromInputFiles.length &&
      !dragState.current
    ) {
      fileChange(Array.from(fromInputFiles), false);
    }

    if (!fromInput && files && files.length !== 0) {
      fileChange(Array.from(files as File[]), true);
    }
  };

  const onDragEnter = (e: React.DragEvent<HTMLElement>) => {
    // This is set to prevent onChange from firing prematurely when doing a drag
    dragState.current = true;
    setDragged(true);
    if (!e.dataTransfer?.items?.length) return;

    const files = Array.from(e.dataTransfer.items).filter(
      (file: DataTransferItem) => file.kind === 'file' && file.type !== ''
    );
    if (files.length) setDroppingFiles(true);
  };

  const onDragEnd = () => {
    setDragged(false);
    setDroppingFiles(false);
  };

  const onDrop = (e: React.DragEvent<HTMLInputElement>) => {
    handleFolders(e.dataTransfer.items)
      .then((files) => {
        return files.map((fileEntry: FileSystemFileEntry) => {
          return new Promise<File>((resolve) => {
            fileEntry.file((file: File) => resolve(file));
          });
        });
      })
      .then((filePromises) => {
        return Promise.all(filePromises);
      })
      .then((files) => {
        dragState.current = false;
        fileChangeOnceOnly(false, files);
      });
    setDragged(false);
    setDroppingFiles(false);
  };

  // Get around typescript stripping attributes
  useEffect(() => {
    if (!fileRef.current) return;

    if (droppingFiles) {
      fileRef.current.removeAttribute('directory');
      fileRef.current.removeAttribute('webkitdirectory');
    } else if (allowDirectories) {
      fileRef.current.setAttribute('directory', '');
      fileRef.current.setAttribute('webkitdirectory', '');
    }
  }, [fileRef, allowDirectories, droppingFiles]);

  const draggedStyle = isDragged ? onDragEnterClassName : null;

  return (
    <div
      className={classnames(styles.UploaderSection, draggedStyle)}
      onDragEnter={(e) => acceptUpload && onDragEnter(e)}
      {...otherProps}
    >
      {acceptUpload ? (
        <input
          ref={fileRef}
          type="file"
          accept={allowDirectories ? '' : acceptUpload}
          className={classnames(
            styles.input,
            isDragged && styles.fileInputDragEnter
          )}
          onDragLeave={onDragEnd}
          onDrop={onDrop}
          onChange={() => fileChangeOnceOnly(true)}
          multiple
        />
      ) : null}
      {children}
    </div>
  );
};

export default React.forwardRef<HTMLInputElement, Props>(DragAndDropUploader);
