import React from 'react';
import { Props as ButtonProps } from '../Button/Button.d';
import styles from './Toast.module.scss';
import { Button } from 'components/core';

type Props = Exclude<ButtonProps, 'color' | 'type' | 'variant' | 'isNaked'>;

const ToastButton = ({ className, children, ...props }: Props) => {
  const classes = [styles.ToastButton];
  if (className) classes.push(className);

  return (
    <Button className={classes.join(' ')} size="sm" isNaked {...props}>
      {children}
    </Button>
  );
};

export default ToastButton;
