import React, {
  ComponentProps,
  ReactNode,
  RefForwardingComponent,
} from 'react';
import Box from 'system/base/Box';
import Flex from 'system/base/Flex';
import SpacedFlex from 'system/utility/SpacedFlex';
import useWindowSize from 'hooks/useWindowSize';

interface Props extends ComponentProps<typeof Flex> {
  heading?: ReactNode;
  notice?: ReactNode;
  actions?: ReactNode;
  // padding for the containers and margin between elements
  space?: ComponentProps<typeof Box>['paddingTop'];
  trialBanner?: ReactNode;
}

// this element fowards the ref to the inner Box to control overflow/scrolling
type RefElement = HTMLDivElement;

const DecisionInner: RefForwardingComponent<RefElement, Props> = (
  { heading, notice, actions, space, children, trialBanner, ...props },
  ref
) => {
  const { height } = useWindowSize();

  return (
    <Flex flexDirection="column" height={height} {...props}>
      {trialBanner}
      <Box flexShrink={0}>{heading}</Box>
      <Box overflow="auto" padding={space} ref={ref}>
        <Box>{children}</Box>
      </Box>
      <Box flexGrow={2} />
      {notice && <Box flexShrink={0}>{notice}</Box>}
      {actions && (
        <Box
          padding={space}
          flexShrink={0}
          borderTop="panel"
          backgroundColor="whiteDark"
          borderBottom={0}
        >
          <SpacedFlex flexDirection="row">{actions}</SpacedFlex>
        </Box>
      )}
    </Flex>
  );
};

const Decision = React.forwardRef<RefElement, Props>(DecisionInner);

Decision.defaultProps = {
  space: 3,
};

export default Decision;
