import {
  QUALITY_ASSESSMENT_TEMPLATE_API_ROUTE,
  tokenizeRoute,
} from '../routes';
import { getCsrfToken } from '../index';
import { getReviewId } from './Review';
import {
  QualityAssessmentTemplate,
  QualityAssessmentTemplateFormat,
} from 'types/DataExtraction';

interface QualityAssessmentTemplateAPI {
  domains: {
    id?: number;
    name: string;
    description: string;
    outcome_specific: boolean;
    prompt_before_deleting: boolean;
  }[];
  outcome_groups: {
    id?: number;
    name: string;
    prompt_before_deleting: boolean;
  }[];
  updated_by?: {
    id: string;
    first_name: string;
    last_name: string;
  };
  updated_at?: string;
  number_of_revisitable_studies: number;
}

export interface getQATemplateProps {
  initialFormat: QualityAssessmentTemplateFormat;
}

const getQATemplate = async ({
  initialFormat,
}: getQATemplateProps): Promise<QualityAssessmentTemplate> => {
  const reviewId = getReviewId();
  if (!reviewId) throw new Error("Couldn't get Review ID.");

  const url = tokenizeRoute(QUALITY_ASSESSMENT_TEMPLATE_API_ROUTE, {
    review_id: reviewId,
    initial_format: initialFormat,
  });

  return fetch(url.toString(), {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  })
    .then((res) => res.json())
    .then((res: QualityAssessmentTemplateAPI) => {
      const {
        domains = [],
        outcome_groups = [],
        updated_by,
        updated_at,
        number_of_revisitable_studies,
      } = res;
      return {
        domains,
        outcome_groups,
        updated_by,
        updated_at,
        number_of_revisitable_studies,
      };
    });
};

const updateQATemplate = (
  { domains, outcome_groups }: QualityAssessmentTemplate,
  revisitStudies: boolean
) => {
  const body = {
    domains,
    outcome_groups,
    revisit_studies: revisitStudies,
  };

  const reviewId = getReviewId();
  if (!reviewId) throw new Error("Couldn't get Review ID.");

  const url = tokenizeRoute(QUALITY_ASSESSMENT_TEMPLATE_API_ROUTE, {
    review_id: reviewId,
  });

  return fetch(url.toString(), {
    method: 'PUT',
    headers: {
      'X-CSRF-Token': getCsrfToken(),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });
};

const createEmptyQATemplate = () => {
  return {
    domains: [],
    outcome_groups: [],
    number_of_revisitable_studies: 0,
  };
};

export { getQATemplate, updateQATemplate, createEmptyQATemplate };
