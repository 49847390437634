import React, { memo, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import styles from '../../DataExtractionTemplate.module.scss';
import { DataExtractionTemplateState } from '../../DataExtractionTemplate';
import EditableField from '../EditableField';
import {
  validate,
  NO_EQUIVALENT_VALUE,
} from '../../../components/editableFieldValidation';
import ConfirmationDialog from '../ConfirmationDialog';
import { Heading, Text, Icon, IconList, Link } from 'components/core';

const RESERVED_FIELD_NAMES: Array<string> = [
  'sponsorship source',
  'country',
  'setting',
  'comments',
  "author's name",
  'institution',
  'email',
  'address',
];

interface Props {
  hasExtractedData?: boolean;
}

const IdentificationPanel = ({ hasExtractedData = false }: Props) => {
  const [modal, setModal] = useState<React.ReactElement | null>();
  const { getValues } = useFormContext();
  const additionalFields = useFieldArray<DataExtractionTemplateState>({
    name: 'identification.additionalFields',
  });
  const MAX_LENGTH = 255;

  return (
    <section className={styles.section}>
      {modal}
      <Heading
        type="h5"
        component="h3"
        variant="brand"
        className={styles.heading}
      >
        Identification
        <Link
          className={styles.HelpLink}
          onClick={() => ''}
          data-pendo-key="det-identification"
          aria-label="Get help with identification"
        >
          <Icon icon={IconList.light.faQuestionCircle} />
        </Link>
      </Heading>

      <div className={styles.details}>
        <Heading type="h6" component="h4" variant="neutral">
          Study details
        </Heading>
        <ul>
          <li>
            <Text variant="medium">Sponsorship source</Text>
          </li>
          <li>
            <Text variant="medium">Country</Text>
          </li>
          <li>
            <Text variant="medium">Setting</Text>
          </li>
          <li>
            <Text variant="medium">Comments</Text>
          </li>
        </ul>
      </div>

      <div className={styles.details}>
        <Heading type="h6" component="h4" variant="neutral">
          {`Author's contact details`}
        </Heading>
        <ul>
          <li>
            <Text variant="medium">Name</Text>
          </li>
          <li>
            <Text variant="medium">Institution</Text>
          </li>
          <li>
            <Text variant="medium">Email</Text>
          </li>
          <li>
            <Text variant="medium">Address</Text>
          </li>
        </ul>
      </div>

      <div className={styles.details}>
        <Heading type="h6" component="h4" variant="neutral">
          Additional data
        </Heading>
        {additionalFields.fields.map((field, index) => (
          <EditableField
            key={field.id}
            index={index}
            name="identification.additionalFields"
            onDelete={() => {
              const { id } = getValues(
                `identification.additionalFields.${index}`
              );

              if (id && hasExtractedData) {
                setModal(
                  <ConfirmationDialog
                    onConfirm={() => additionalFields.remove(index)}
                    onClose={() => setModal(null)}
                  />
                );
              } else {
                additionalFields.remove(index);
              }
            }}
            validate={(value, existingFields) => {
              return validate(
                value,
                existingFields,
                RESERVED_FIELD_NAMES,
                NO_EQUIVALENT_VALUE,
                MAX_LENGTH
              );
            }}
          />
        ))}
        <Link
          onClick={() => additionalFields.append({ name: '' })}
          variant="primary"
          className={styles.AddButton}
        >
          <Icon icon={IconList.light.faPlus} /> Add additional data
        </Link>
      </div>
    </section>
  );
};

export default memo(IdentificationPanel);
