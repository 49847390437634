import React from 'react';
import styles from './StatusIndicator.module.css';

interface StatusIndicatorProps {
  completed: boolean;
}
export function StatusIndicator({
  completed,
}: StatusIndicatorProps): JSX.Element {
  return <span className={completed ? styles.complete : styles.draft} />;
}
