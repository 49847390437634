import React, { useState } from 'react';
import classNames from 'classnames';
import comparisonTableStyles from '../ComparisonTable/ComparisonTable.module.css';
import { Comment } from '../CommentPopover/types';
import { CommentPopover } from '../CommentPopover/CommentPopover';
import styles from './ComparisonTableAccordion.module.css';
import { Badge } from 'components/Badge';
import { useControls, useLabelledBy } from 'hooks/useAccessibleName';
import Icon, { IconStyle } from 'system/base/Icon';

interface ComparisonTableAccordionProps {
  label: string;
  conflictCount: number;
  children: React.ReactNode;
  isInitiallyExpanded?: boolean;
  comments?: Comment[];
}

export const ComparisonTableAccordion = ({
  label,
  conflictCount,
  children,
  isInitiallyExpanded = true,
  comments,
}: ComparisonTableAccordionProps) => {
  const [ariaControlledID, ariaControls] = useControls();
  const [labelID, labbelledBy] = useLabelledBy();
  const [isExpanded, setIsExpanded] = useState(isInitiallyExpanded);

  return (
    <>
      <tr className={comparisonTableStyles.row}>
        <th colSpan={4}>
          <button
            id={labelID}
            className={styles.accordionTrigger}
            aria-expanded={isExpanded}
            onClick={() => {
              setIsExpanded(!isExpanded);
            }}
            {...ariaControls}
          >
            <span>{label}</span>
            <div className={styles['accordionTrigger-endContainer']}>
              {!!comments?.length && (
                <CommentPopover
                  variant="light"
                  sx={{
                    color: 'initial',
                    paddingRight: 3,
                    fontSize: 3,
                  }}
                  comments={comments}
                />
              )}
              <Badge
                size={'medium'}
                appendText={` conflict${conflictCount === 1 ? '' : 's'}`}
                variant={conflictCount === 0 ? 'Muted' : 'Primary'}
              >
                {conflictCount}
              </Badge>
              <Icon
                name="angle-down"
                iconStyle={IconStyle.Regular}
                className={classNames(
                  styles.accordionArrow,
                  isExpanded && styles['accordionArrow--isExpanded']
                )}
              />
            </div>
          </button>
        </th>
      </tr>
      <tr
        {...labbelledBy}
        id={ariaControlledID}
        className={classNames(
          comparisonTableStyles.row,
          styles['accordionPanel-wrapper']
        )}
      >
        <td colSpan={4} className={styles.accordionPanel} hidden={!isExpanded}>
          {children}
        </td>
      </tr>
    </>
  );
};
