import React, {
  ComponentProps,
  FunctionComponent,
  ReactComponentElement,
} from 'react';
import { useUID } from 'react-uid';
import * as CSS from 'csstype';

import { Label, Input } from 'theme-ui';
import Flex, { isDirectionVertical } from '../base/Flex';

import Box from 'system/base/Box';

interface Props extends ComponentProps<typeof Flex> {
  input: ReactComponentElement<typeof Input>;
  flexDirection?: CSS.Property.FlexDirection;
  labelProps?: ComponentProps<typeof Label>;
}

const inputContainerProps = (flexDirection: Props['flexDirection']) => {
  if (flexDirection === 'column' || flexDirection === 'column-reverse') {
    return { width: '100%' };
  }
  if (flexDirection === 'row-reverse') {
    return {};
  }
  if (flexDirection === 'row') {
    return {
      mr: 1,
    };
  }
  // other flexDirection values (unset, inherit, etc) are like 'row'
  return {};
};

const LabelFor: FunctionComponent<Props> = ({
  input,
  children,
  flexDirection,
  labelProps,
  ...props
}: Props) => {
  const uid = useUID();

  return (
    // Horizontal layouts vertically center the label to the input
    <Flex
      flexDirection={flexDirection}
      alignItems={isDirectionVertical(flexDirection) ? 'flex-start' : 'center'}
      alignSelf="flex-start"
      {...props}
    >
      <Box
        alignSelf={
          isDirectionVertical(flexDirection) ? undefined : 'flex-start'
        }
        {...inputContainerProps(flexDirection)}
      >
        {React.cloneElement(input, {
          id: uid,
        })}
      </Box>
      <Box>
        <Label {...labelProps} htmlFor={uid}>
          {children}
        </Label>
      </Box>
    </Flex>
  );
};
export default LabelFor;
