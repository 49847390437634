import { gql } from '@apollo/client';
import { client } from 'store/apollo';

export const ADD_TAGS_TO_STUDIES = gql`
  mutation addTagsToStudies($tags: [String!]!, $studyIds: [ID!]!) {
    addTagsToStudies(input: { tags: $tags, studyIds: $studyIds }) {
      success
    }
  }
`;

export const addTagsToStudies = (tagNames: string[], studyIds: string[]) =>
  client.mutate({
    mutation: ADD_TAGS_TO_STUDIES,
    variables: {
      tags: tagNames,
      studyIds,
    },
  });

export const addTagToStudies = (tagName: string, studyIds: string[]) =>
  addTagsToStudies([tagName], studyIds);

export const REMOVE_TAGS_FROM_STUDIES = gql`
  mutation removeTagsFromStudies($tags: [String!]!, $studyIds: [ID!]!) {
    removeTagsFromStudies(input: { tags: $tags, studyIds: $studyIds }) {
      success
    }
  }
`;

export const removeTagFromStudies = (tagName: string, studyIds: string[]) =>
  client.mutate({
    mutation: REMOVE_TAGS_FROM_STUDIES,
    variables: {
      tags: [tagName],
      studyIds,
    },
  });
