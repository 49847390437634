import React, {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import AutoExclusions from './components/AutoExclusions/AutoExclusions';
import styles from './AutoExclusionsPage.module.scss';
import AutomationNavigation from './components/AutomationNavigation';
import { Flash } from './components/Flash';
import { PrimaryNavigation } from 'components/shared';
import {
  Button,
  Icon,
  IconList,
  Text,
  Toast,
  ToastContainer,
  createToast,
  dismissToast,
} from 'components/core';
import { Study } from 'types';
import { moveToScreening } from 'query/study/Study';
import PageHeader from 'components/PageHeader/PageHeader';
import { Reviewer } from 'components/shared/PrimaryNavigation/PrimaryNavigation';
import {
  AUTO_EXCLUSION_STUDIES_PER_PAGE,
  AutoExclusionStudiesResponse,
  getAutoExclusionStudies,
} from 'query/review/AutoExclusion';
import { QueryProvider } from 'query/QueryProvider';

export const PageContainer = ({ children }: { children: ReactNode }) => {
  // We are currently using the existing global css
  // but this is abstracted so we can change it independently
  return <div className={styles.pageContainer}>{children}</div>;
};

type MoveToScreeningContext = {
  moveStudyToScreening: (studyId: number) => Promise<void>;
};

export const MoveToScreeningContext = createContext<MoveToScreeningContext | null>(
  null
);
export const useMoveToScreeningContext = () => {
  const context = useContext(MoveToScreeningContext);

  if (context == null) {
    throw new Error(
      'useMoveToScreeningContext called outside of an MoveToScreening provider'
    );
  }

  return context;
};

export interface AutoExclusionsPageProps {
  review: {
    id: number;
    title: string;
  };
  reviewer: Reviewer;
  isAutoExclusionsEnabled: boolean;
  totalManualDuplicateStudies: number;
  totalSystemDuplicateStudies: number;
  fetchStudies?: (pageNumber: number) => Promise<AutoExclusionStudiesResponse>;
  showGroove: boolean;
  searchPath: string;
}

type DisplayPageMetadataProps = {
  pageNumber: number;
  itemsPerPage: number;
  totalItems: number;
  currentPageItems: number;
};

export const displayPageMetadata = ({
  pageNumber,
  itemsPerPage,
  totalItems,
  currentPageItems,
}: DisplayPageMetadataProps): string => {
  const firstEntryIndex = (pageNumber - 1) * itemsPerPage + 1;
  const lastEntryIndex = firstEntryIndex + currentPageItems - 1;

  return `${firstEntryIndex}-${lastEntryIndex} of ${totalItems}`;
};

const getTotalPages = (totalItems: number) =>
  Math.ceil(totalItems / AUTO_EXCLUSION_STUDIES_PER_PAGE);

const AutoExclusionsPage = ({
  review,
  reviewer,
  isAutoExclusionsEnabled,
  totalManualDuplicateStudies,
  totalSystemDuplicateStudies,
  fetchStudies = getAutoExclusionStudies,
  showGroove = true,
  searchPath,
}: AutoExclusionsPageProps) => {
  const firstPage = 1;
  const [studies, setStudies] = useState<Study[]>([]);
  const [totalStudies, setTotalStudies] = useState<number>(0);
  const [currentPageNumber, setCurrentPageNumber] = useState<number>(firstPage);
  const [currentPageStudyCount, setCurrentPageStudyCount] = useState<number>(0);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const settingsLink = `/reviews/${review.id}/details`;
  const totalPages = getTotalPages(totalStudies);

  const fetchStudiesForPage = useCallback(
    async (page, { refresh } = { refresh: false }) => {
      if (!refresh) {
        setIsFetching(true);
      }

      try {
        try {
          const { studies = [], totalStudies } = await fetchStudies(page);
          setStudies(studies);
          setTotalStudies(totalStudies);
          setCurrentPageStudyCount(studies.length);

          if (studies.length === 0 && page > 0) {
            const previousPage = Math.max(firstPage, page - 1);

            // Studies are fetched when page number changes
            setCurrentPageNumber(previousPage);
          }
        } catch {
          createToast(
            <Toast type="danger">
              <Text>Unable to load your studies, please try again later.</Text>
            </Toast>
          );
        }
      } finally {
        setIsFetching(false);
      }
    },
    [fetchStudies]
  );

  useEffect(() => {
    if (isAutoExclusionsEnabled) {
      // Refetch studies anytime page number changes
      fetchStudiesForPage(currentPageNumber);
    }
  }, [isAutoExclusionsEnabled, currentPageNumber, fetchStudiesForPage]);

  return (
    <QueryProvider>
      <PrimaryNavigation
        reviewTitle={review.title}
        reviewPath={`/reviews/${review.id}`}
        logoPath="/reviews"
        searchPath={searchPath}
        reviewer={{
          first_name: reviewer.first_name,
          last_name: reviewer.last_name,
          admin: reviewer.admin,
          account: reviewer.account,
          organizations: reviewer.organizations,
        }}
        showGroove={showGroove}
      />
      <PageHeader
        backLink={`/reviews/${review.id}`}
        headerText={'Removed before screening'}
      />
      <AutomationNavigation
        reviewId={review.id}
        autoExcludeFeatureEnabled={true}
        activeTab="auto-exclude"
        totalAutoExcludedStudies={totalStudies}
        totalManualDuplicateStudies={totalManualDuplicateStudies}
        totalSystemDuplicateStudies={totalSystemDuplicateStudies}
      />
      <Flash />
      <PageContainer>
        <MoveToScreeningContext.Provider
          value={{
            moveStudyToScreening: (studyId) =>
              moveToScreening(review.id, studyId)
                .then(() => {
                  fetchStudiesForPage(currentPageNumber, { refresh: true });
                })
                .then(() => {
                  // Hide toast as new studies have been fetched
                  dismissBatchCompletedToast();
                })
                .catch(() => {
                  const message =
                    "Oh no! We're experiencing some technical difficulties right now, please try again later";
                  createToast(<Toast type="danger">{message}</Toast>);
                }),
          }}
        >
          <AutoExclusions
            reviewId={review.id}
            setCurrentPageNumber={setCurrentPageNumber}
            currentPageNumber={currentPageNumber}
            displayPageMetadata={displayPageMetadata({
              pageNumber: currentPageNumber,
              itemsPerPage: AUTO_EXCLUSION_STUDIES_PER_PAGE,
              totalItems: totalStudies,
              currentPageItems: currentPageStudyCount,
            })}
            settingsLink={settingsLink}
            totalPages={totalPages}
            isFetching={isFetching}
            studies={studies}
            isAutoExclusionsEnabled={isAutoExclusionsEnabled}
            refetchPage={fetchStudiesForPage}
          />
        </MoveToScreeningContext.Provider>
        <ToastContainer />
      </PageContainer>
      <Button
        data-pendo-key="auto-exclude-list-feedback"
        type="brand"
        size="md"
        className={styles.feedbackButton}
      >
        <Icon icon={IconList.solid.faComment} />
        Feedback
      </Button>
      {/* We need this div for fixed positioning of the groove widget */}
      <div className="groove-outer-container"></div>
    </QueryProvider>
  );
};

export const AUTO_EXCLUSION_BATCH_COMPLETED_TOAST_ID =
  'auto-exclusion-batch-completed';
export const dismissBatchCompletedToast = () =>
  dismissToast(AUTO_EXCLUSION_BATCH_COMPLETED_TOAST_ID);

export default AutoExclusionsPage;
