import { update } from 'ramda';
import {
  ColumnCellData,
  TableData,
  CellData,
  CellPosition,
  CellValue,
} from '../types';

const deleteCellFromTable = (table: TableData) => ({
  rowID,
  columnID,
}: CellPosition): TableData => {
  const matchingRowIndex = table.findIndex((row) => row.rowID === rowID);
  const noMatchingRow = matchingRowIndex === -1;

  if (noMatchingRow) {
    return table;
  }

  const matchingRow = table[matchingRowIndex];

  const updatedColumns = matchingRow.columns.filter(
    (column) => column.columnID !== columnID
  );

  if (!updatedColumns.length) {
    const updatedTable = table.filter((row) => row.rowID !== rowID);
    return updatedTable;
  }

  const updatedRow = { ...matchingRow, columns: updatedColumns };
  const updatedTable = update(matchingRowIndex, updatedRow, table);

  return updatedTable;
};

/**
 * To match validation schema & ensure null represents empty values for
 * consistency.
 */
const formatCellValue = (value: CellValue) => {
  if (typeof value === 'number') return value.toString();
  return value === '' ? null : value;
};

export const addOrUpdateCellInTable = (table: TableData) => ({
  rowID,
  columnID,
  rowIndex,
}: CellPosition) => (cellData: CellData): TableData => {
  const formattedCellData = {
    ...cellData,
    value: formatCellValue(cellData.value),
  };

  // Find matching rowID. Add new row if not found.
  const matchingRowIndex = table.findIndex((row) => row.rowID === rowID);
  const noMatchingRow = matchingRowIndex === -1;

  if (noMatchingRow) {
    const newCell: ColumnCellData = {
      ...formattedCellData,
      columnID,
    };
    const newRow = {
      rowID,
      rowIndex,
      columns: [newCell],
    };
    const updatedTable = [...table, newRow];
    return updatedTable;
  }

  const matchingRow = table[matchingRowIndex];

  // Find matching columnID. Add new column if not found.
  const matchingColumnIndex = matchingRow.columns.findIndex(
    (column) => column.columnID === columnID
  );
  const noMatchingColumn = matchingColumnIndex === -1;

  if (noMatchingColumn) {
    const newCell: ColumnCellData = {
      ...formattedCellData,
      columnID,
    };
    const updatedColumns = [...matchingRow.columns, newCell];
    const updatedRow = { ...matchingRow, columns: updatedColumns };
    const updatedTable = update(matchingRowIndex, updatedRow, table);
    return updatedTable;
  }

  // Find matching cell, do nothing if new value is equal to old value
  const matchingCell = matchingRow.columns[matchingColumnIndex];
  if (matchingCell.value === cellData.value) return table;

  // Update table with new cell
  const updatedCell: ColumnCellData = { ...matchingCell, ...formattedCellData };
  const updatedColumns = update(
    matchingColumnIndex,
    updatedCell,
    matchingRow.columns
  );
  const updatedRow = { ...matchingRow, columns: updatedColumns };
  const updatedTable = update(matchingRowIndex, updatedRow, table);
  return updatedTable;
};

/**
 * Updates the table by removing empty cells or adding cell with data.
 *
 * @param cellData If null, remove cell from the table. Otherwise, add it.
 */
export const updateTable = (
  cellPosition: CellPosition,
  cellData: CellData | null,
  table: TableData
): TableData => {
  /**
   * If we want to implement column-only tables in the future, use the
   * cell's rowIndex (already in UpdateCellValueProps) to find matching row
   * instead. Currently, we assume all rows have a row label and thus a
   * matching rowID.
   */
  const updatedTable = cellData
    ? addOrUpdateCellInTable(table)(cellPosition)(cellData)
    : deleteCellFromTable(table)(cellPosition);

  return updatedTable;
};
