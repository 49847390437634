import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Checklist from 'components/DeleteReviewChecklist';
import { DangerButton } from 'system/base/Button';

export class DeleteReview extends Component {
  constructor() {
    super();

    this.state = {
      showModal: false,
    };

    this.onShowModal = this.onShowModal.bind(this);
    this.onHideModal = this.onHideModal.bind(this);
  }

  onShowModal() {
    this.setState({
      showModal: true,
    });
  }

  onHideModal() {
    this.setState({
      showModal: false,
    });
  }

  render() {
    const { review } = this.props;
    const { showModal } = this.state;

    return (
      <div>
        <DangerButton onClick={this.onShowModal}>
          Delete this Review
        </DangerButton>
        <Checklist
          review={review}
          showModal={showModal}
          onHideModal={this.onHideModal}
        />
      </div>
    );
  }
}

DeleteReview.propTypes = {
  review: PropTypes.object.isRequired,
};

export default DeleteReview;
