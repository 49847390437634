import React from 'react';
import { FileInfo, FileListRowFunctions } from '../index';
import FileListRow from './FileListRow';
import styles from './FileList.module.css';

type Props = {
  files: FileInfo[];
} & FileListRowFunctions;

export default function FileList({
  files,
  makeFilePrimary,
  unsetDocumentUrl,
  deleteFile,
}: Props) {
  return (
    <table className={styles.FileList}>
      <thead>
        <tr>
          <th className={styles.FileList__fullText}>Full text</th>
          <th className={styles.FileList__uploadDate}>Upload date</th>
          <th className={styles.FileList__uploadBy}>Uploaded by</th>
          <th className={styles.FileList__primary}></th>
          <th className={styles.FileList__delete}></th>
        </tr>
      </thead>
      <tbody>
        {files.map((file) => (
          <FileListRow
            key={file.id}
            file={file}
            uploadProgress={file.progress}
            makeFilePrimary={makeFilePrimary}
            unsetDocumentUrl={unsetDocumentUrl}
            deleteFile={deleteFile}
          />
        ))}
      </tbody>
    </table>
  );
}
