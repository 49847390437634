import React from 'react';
import styles from '../Form.module.scss';

interface Props {
  children: React.ReactNode;
  className?: string;
}

const FormContainer = ({ className, children }: Props) => {
  const classes = [styles.FormContainer];
  if (className) classes.push(className);

  return <div className={classes.join(' ')}>{children}</div>;
};

export default FormContainer;
