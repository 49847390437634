import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import Split from 'react-split';
import { FormProvider, useForm, UseFormReturn } from 'react-hook-form';
import { PrimaryButton, TertiaryButton } from '../../system/base/Button';
import { BlockResolutions, Resolutions, ResolutionState } from './types';
import { UnresolvedDecisionsStatus } from './UnresolvedDecisionsStatus/UnresolvedDecisionsStatus';
import styles from './Compare.module.css';
import ToolbarNav from './ToolbarNav';
import UnsavedChangesWarning from './UnsavedChangesWarning';
import ConsensusTabBadge from './ConsensusTabBadge';
import { errorMessagesFrom } from 'util/errorMessages';

import { BlockRecords } from 'concepts/Extraction/BlocksData';
import { TemplateModel } from 'concepts/Extraction/TemplateModel';
import { prepareViewModel } from 'concepts/Extraction/ViewModel/comparison';
import Errors from 'components/Errors';
import {
  SaveConsensusMutation,
  CompleteConsensusMutation,
} from 'types/graphql';
import { ExtractionTabs } from 'concepts/Extraction/Widgets/ExtractionTabs';
import { ExtractionFormType } from 'concepts/Extraction/ExtractionFormType';
import { CompareDataExtractionForm } from 'concepts/Extraction/Compare/CompareDataExtractionForm';
import { CompareQualityAssessmentForm } from 'concepts/Extraction/Compare/CompareQualityAssessmentForm';
import { FullTextsPreview } from 'concepts/Extraction/Widgets/FullText/FullTextsPreview';
import { PrimaryLink } from 'system/base/Link';
import { PspdfkitConfig } from 'concepts/Extraction/Widgets/FullText/PDFPreview';
import { UnresolvedTableCellIDs } from 'concepts/Extraction/ViewModel/tables';

export const SAVE_CONSENSUS_MUTATION = gql`
  mutation SaveConsensus($input: SaveConsensusInput!) {
    saveConsensus(input: $input) {
      success
      errors {
        message
      }
    }
  }
`;

export const COMPLETE_CONSENSUS_MUTATION = gql`
  mutation CompleteConsensus($input: CompleteConsensusInput!) {
    completeConsensus(input: $input) {
      success
      errors {
        message
      }
    }
  }
`;
// Deal with unresolved values here
export interface Review {
  id: string;
  name: string;
  currentExtraction: {
    revisionID: string;
    values: BlockRecords;
  };
}

export interface CompareFormProps {
  template: TemplateModel;
  reviewers: Array<Review>;
  consensusDecider: {
    name: string;
    status: 'draft' | 'complete';
    data: {
      values: BlockRecords;
    };
  } | null;
}

const emptyComparisonResult: Resolutions = {
  resolvedData: {},
  unresolvedIDs: new Set<string>(),
  unresolvedTableCells: {},
  blockResolutions: {},
};

export type FormValues = {
  resolvedData: BlockRecords;
  unresolvedIDs: Set<string>;
  blockResolutions: BlockResolutions;
  unresolvedTableCells: { string: UnresolvedTableCellIDs };
};

type CompareHeaderProps = {
  studyId: string;
  dataExtractionForm: CompareFormProps | undefined;
  dataExtractionConsensusForm: UseFormReturn<FormValues>;
  qualityAssessmentForm: CompareFormProps | undefined;
  qualityAssessmentConsensusForm: UseFormReturn<FormValues>;
  setNavigateToPreviousPage: Dispatch<SetStateAction<boolean>>;
  completed: boolean;
  editUrl: string;
  currentUserIsTheDecider: boolean;
  backUrl: string;
  title: string;
};

const CompareHeader = ({
  studyId,
  dataExtractionForm,
  dataExtractionConsensusForm,
  qualityAssessmentForm,
  qualityAssessmentConsensusForm,
  setNavigateToPreviousPage,
  currentUserIsTheDecider,
  editUrl,
  completed,
  backUrl,
  title,
}: CompareHeaderProps) => {
  const [
    saveMutation,
    { data: saveData, error: saveError, loading: saving },
  ] = useMutation<SaveConsensusMutation>(SAVE_CONSENSUS_MUTATION);
  const [
    completeMutation,
    { data: completeData, error: completeError, loading: completing },
  ] = useMutation<CompleteConsensusMutation>(COMPLETE_CONSENSUS_MUTATION);

  function save(options: { complete: boolean }) {
    const dataExtraction = dataExtractionForm
      ? {
          sourceIds: dataExtractionForm.reviewers.map(
            (reviewer) => reviewer.currentExtraction.revisionID
          ),
          data: {
            values: dataExtractionConsensusForm.getValues('resolvedData'),
          },
          unresolvedFields: unresolvedFields(
            dataExtractionForm.reviewers,
            dataExtractionConsensusForm.getValues('blockResolutions')
          ),
        }
      : undefined;

    const qualityAssessment = qualityAssessmentForm
      ? {
          sourceIds: qualityAssessmentForm.reviewers.map(
            (reviewer) => reviewer.currentExtraction.revisionID
          ),
          data: {
            values: qualityAssessmentConsensusForm.getValues('resolvedData'),
          },
          unresolvedFields: unresolvedFields(
            qualityAssessmentForm.reviewers,
            qualityAssessmentConsensusForm.getValues('blockResolutions')
          ),
        }
      : undefined;

    const input = {
      studyId: studyId,
      dataExtraction,
      qualityAssessment,
    };

    interface Mutation {
      (options: { variables: { input: typeof input } }): Promise<unknown>;
    }

    const mutation: Mutation = options.complete
      ? completeMutation
      : saveMutation;

    mutation({ variables: { input } })
      .then(() => {
        // Reset form after submission to reset state values, eg: isDirty
        // Allows user to leave the page without getting unsaved changes prompt
        if (dataExtractionForm) {
          dataExtractionConsensusForm.reset({}, { keepValues: true });
        }
        if (qualityAssessmentForm) {
          qualityAssessmentConsensusForm.reset({}, { keepValues: true });
        }

        if (options.complete) {
          setNavigateToPreviousPage(true);
        }
      })
      .catch((e) => {
        MetaError.notify(e);
      });
  }

  let buttons: JSX.Element = <></>;
  if (completed && currentUserIsTheDecider) {
    buttons = <PrimaryLink href={editUrl}>Edit</PrimaryLink>;
  } else if (!completed) {
    buttons = (
      <>
        <TertiaryButton
          mr={2}
          className={styles.saveButton}
          disabled={saving}
          onClick={() => {
            save({
              complete: false,
            });
          }}
        >
          {saving ? 'Saving…' : 'Save'}
        </TertiaryButton>
        <PrimaryButton
          mr={2}
          disabled={completing}
          onClick={() => {
            save({
              complete: true,
            });
          }}
        >
          {completing ? 'Completing…' : 'Complete'}
        </PrimaryButton>
      </>
    );
  }

  return (
    <header className={styles.header}>
      <ToolbarNav backUrl={backUrl} title={title} right={buttons} />
      <Errors
        title="Error saving"
        errors={errorMessagesFrom(
          saveData?.saveConsensus?.errors,
          saveError,
          completeData?.completeConsensus?.errors,
          completeError
        )}
      />
    </header>
  );
};

export interface CompareProps {
  title: string;
  dataExtractionForm?: CompareFormProps;
  qualityAssessmentForm?: CompareFormProps;
  initialSection?: ExtractionFormType;
  singleReviewer: boolean;
  studyId: string;
  fullTexts: Array<{ url: string; title: string }>;
  backUrl: string;
  editUrl: string;
  currentUserIsTheDecider: boolean;
  pspdfkitConfig: PspdfkitConfig;
}
type strArr = Array<string>;

type userChangedValues = {
  reviewerId: string;
  changedFields: strArr;
};

function unresolvedFields(
  reviewers: Array<Review>,
  blockResolutions: BlockResolutions
): userChangedValues[] {
  const reviewerResolved = Object.keys(blockResolutions).reduce(
    (acc: Array<string>, key: string) => {
      const resolved =
        (blockResolutions[key] as ResolutionState) ===
        'resolvedByConsensusReviewer';
      return resolved ? [...acc, key] : acc;
    },
    []
  );

  return reviewers.map((reviewer) => {
    const values = reviewer.currentExtraction.values;
    const changed = Object.keys(reviewer.currentExtraction.values).filter(
      (key) => values[key].changed && !reviewerResolved.includes(key)
    );

    return { reviewerId: reviewer.id, changedFields: changed };
  });
}

export default function Compare({
  studyId,
  title,
  singleReviewer,
  dataExtractionForm,
  qualityAssessmentForm,
  initialSection = 'data_extraction',
  fullTexts,
  backUrl,
  editUrl,
  currentUserIsTheDecider,
  pspdfkitConfig,
}: CompareProps): JSX.Element {
  const [navigateToPreviousPage, setNavigateToPreviousPage] = useState(false);
  const [selectedTab, updateSelectedTab] = useState(initialSection);

  const dataExtractionResolutions = dataExtractionForm
    ? prepareViewModel({
        formTemplate: dataExtractionForm.template,
        reviewerExtractionData: dataExtractionForm.reviewers.map(
          (reviewer) => reviewer.currentExtraction.values
        ),
        consensusDecider: !!dataExtractionForm.consensusDecider,
        decidedValues: dataExtractionForm?.consensusDecider?.data.values,
      })
    : emptyComparisonResult;

  const qualityAssessmentResolutions = qualityAssessmentForm
    ? prepareViewModel({
        formTemplate: qualityAssessmentForm.template,
        reviewerExtractionData: qualityAssessmentForm.reviewers.map(
          (reviewer) => reviewer.currentExtraction.values
        ),
        consensusDecider: !!qualityAssessmentForm.consensusDecider,
        decidedValues: qualityAssessmentForm.consensusDecider?.data.values,
      })
    : emptyComparisonResult;

  const dataExtractionConsensusForm = useForm<FormValues>({
    defaultValues: {
      resolvedData: dataExtractionResolutions.resolvedData,
      unresolvedIDs: dataExtractionResolutions.unresolvedIDs,
      blockResolutions: dataExtractionResolutions.blockResolutions,
      unresolvedTableCells: dataExtractionResolutions.unresolvedTableCells,
    },
  });

  const qualityAssessmentConsensusForm = useForm<FormValues>({
    defaultValues: {
      resolvedData: qualityAssessmentResolutions.resolvedData,
      unresolvedIDs: qualityAssessmentResolutions.unresolvedIDs,
      blockResolutions: qualityAssessmentResolutions.blockResolutions,
      unresolvedTableCells: qualityAssessmentResolutions.unresolvedTableCells,
    },
  });

  const deCompleted =
    dataExtractionForm?.consensusDecider?.status === 'complete';
  const qaCompleted =
    qualityAssessmentForm?.consensusDecider?.status === 'complete';
  const completed = deCompleted || qaCompleted;

  useEffect(() => {
    if (navigateToPreviousPage) {
      window.location.assign(backUrl);
    }
  }, [navigateToPreviousPage, backUrl]);

  const status = (
    <UnresolvedDecisionsStatus
      isAllDecisionsResolved={completed}
      isSingleReviewer={singleReviewer}
      deControl={dataExtractionConsensusForm.control}
      qaControl={qualityAssessmentConsensusForm.control}
    />
  );

  return (
    <div className={styles.container}>
      <CompareHeader
        backUrl={backUrl}
        title={title}
        studyId={studyId}
        dataExtractionForm={dataExtractionForm}
        dataExtractionConsensusForm={dataExtractionConsensusForm}
        qualityAssessmentForm={qualityAssessmentForm}
        qualityAssessmentConsensusForm={qualityAssessmentConsensusForm}
        setNavigateToPreviousPage={setNavigateToPreviousPage}
        currentUserIsTheDecider={currentUserIsTheDecider}
        editUrl={editUrl}
        completed={completed}
      />
      <Split className={styles.split} sizes={[40, 60]} gutterSize={5}>
        <div className={styles.previewPanel}>
          <FullTextsPreview
            fullTexts={fullTexts}
            pspdfkitConfig={pspdfkitConfig}
          />
        </div>
        <section
          id={'consensus-root-id'}
          className={styles.forms}
          aria-label="Consensus form"
        >
          <header className={styles.consensusHeader}>
            <h4 className={styles.consensusHeading}>
              {singleReviewer ? 'Check data' : 'Consensus'}
            </h4>
            {status}
          </header>

          <ExtractionTabs
            selected={selectedTab}
            onSelect={updateSelectedTab}
            renderBadge={(type) => (
              <ConsensusTabBadge
                deControl={dataExtractionConsensusForm.control}
                qaControl={qualityAssessmentConsensusForm.control}
                deCompleted={deCompleted}
                qaCompleted={qaCompleted}
                type={type}
              />
            )}
            renderPanel={(type) => {
              switch (type) {
                case 'data_extraction':
                  if (!dataExtractionForm) {
                    return null;
                  } else {
                    return (
                      <section
                        aria-label="Data Extraction"
                        className={styles.tabPanel}
                      >
                        <FormProvider {...dataExtractionConsensusForm}>
                          <CompareDataExtractionForm
                            form={dataExtractionForm}
                            completed={deCompleted}
                          />
                          <UnsavedChangesWarning />
                        </FormProvider>
                      </section>
                    );
                  }
                case 'quality_assessment':
                  if (!qualityAssessmentForm) {
                    return null;
                  } else {
                    return (
                      <section
                        aria-label="Quality Assessment"
                        className={styles.tabPanel}
                      >
                        <FormProvider {...qualityAssessmentConsensusForm}>
                          <CompareQualityAssessmentForm
                            form={qualityAssessmentForm}
                            completed={qaCompleted}
                          />
                          <UnsavedChangesWarning />
                        </FormProvider>
                      </section>
                    );
                  }
              }
            }}
          />
        </section>
      </Split>
    </div>
  );
}
