import React from 'react';
import StageSummary from './StageSummary';
import { Statistics } from './Home';
import { CategoryLink } from './categoryLink';

interface FullTextScreeningProps {
  reviewerName: string;
  excluded: number;
  stats: Statistics;
  isOpen: boolean;
}

const FullTextScreening = ({
  isOpen,
  excluded,
  reviewerName,
  stats,
}: FullTextScreeningProps) => {
  const secondaryContent = `${excluded} excluded`;
  const studyCategoryCount = `${stats.available} ${
    stats.available === 1 ? 'study' : 'studies'
  } to screen`;
  return (
    <StageSummary
      isOpen={isOpen}
      stage="select"
      title="Full text review"
      secondaryContent={secondaryContent}
      secondaryLink={CategoryLink('excluded')}
      studyCategoryCount={studyCategoryCount}
      studyCategoryLink={CategoryLink('select')}
      permissions={{ consensusRequired: true }}
      reviewerName={reviewerName}
      stats={stats}
    />
  );
};

export default FullTextScreening;
