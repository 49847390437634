import React from 'react';
import StageProgress from './StageProgress';
import StageIncomplete from './StageIncomplete';

import styles from './StageDetail.module.css';
import { Permissions, Statistics, Stage } from './Home';
import StageComplete from './StageComplete';
import StageNotStarted from './StageNotStarted';
import ReviewLinkContext from './ReviewLinkContext';

export interface StageDetailProps extends React.HTMLAttributes<HTMLDivElement> {
  stage: Stage;
  stats: Statistics;
  reviewerName: string;
  permissions: Permissions;
  studyCategoryLink: string;
}

const StageDetail = ({
  stage,
  stats,
  reviewerName,
  permissions,
  studyCategoryLink,
  ...divProps
}: StageDetailProps) => {
  const isComplete = stats.consensusRequired + stats.available == 0;
  const notStarted =
    stats.complete +
      stats.partiallyComplete +
      stats.consensusRequired +
      stats.notStarted ==
    0;

  let status: JSX.Element;

  if (isComplete) {
    status = (
      <StageComplete
        reviewerName={reviewerName}
        contribution={stats.contribution}
        stage={stage}
      />
    );
  } else {
    status = (
      <StageIncomplete
        stage={stage}
        consensusRequired={stats.consensusRequired}
        available={stats.available}
        contribution={stats.contribution}
        reviewerName={reviewerName}
        permissions={permissions}
        studyCategoryLink={studyCategoryLink}
      />
    );
  }

  if (notStarted) {
    status = <StageNotStarted reviewerName={reviewerName} />;
  }

  return (
    <ReviewLinkContext.Consumer>
      {(reviewLink) => (
        <div {...divProps}>
          <div className={styles.stageDetail}>
            <div>
              <StageProgress stage={stage} {...stats} />
              <div>
                <a href={`${reviewLink}/team#${stage}`}>
                  <span className={styles.settingsIcon}></span>
                  Team settings
                </a>
              </div>
            </div>
            {status}
          </div>
        </div>
      )}
    </ReviewLinkContext.Consumer>
  );
};

export default StageDetail;
