import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import REVOKE_MEMBERSHIP from './revokeMembership';
import Member from './Member';
import Collaborator from './Collaborator';
import AdminStatus from './AdminStatus';
import ErrorMessage from 'components/ErrorMessage';
import { ID, Email } from 'util/types';
import { MembershipStatus as Status } from 'schema/enums';
import { RevokeMembershipMutation, RevokeMembershipInput } from 'types/graphql';
import Panel from 'system/elements/Panel';
import Space from 'system/utility/Space';

interface Author {
  id: ID;
  name: string;
  email: Email;
}

interface Organization {
  id: ID;
  name: string;
}

interface Invitation {
  id: ID;
  email: Email;
  expiresAt: Date;
}

interface Props {
  currentUserId: ID;
  author: Author;
  organization: Organization;
  status: Status;
  invitation?: Invitation;
}

const MembershipStatus = ({
  currentUserId,
  author,
  organization,
  status,
  invitation,
}: Props) => {
  const [busy, setBusy] = useState(false);
  const [error, setError] = useState('');
  const [revokeMembershipMutation] = useMutation<
    RevokeMembershipMutation,
    RevokeMembershipInput
  >(REVOKE_MEMBERSHIP);
  const revokeMembership = () => {
    setBusy(true);
    revokeMembershipMutation({
      variables: {
        organizationId: organization.id,
        memberId: author.id,
      },
    })
      .then((response: any) => {
        if (!response.data.revokeMembership.success) {
          setError(
            response.data.revokeMembership.errors
              .map((e: any) => `${e.message}`)
              .join(',')
          );
        }
      })
      .catch((err) => {
        setError(err.message);
      })
      .finally(() => setBusy(false));
  };

  if (error) {
    return <ErrorMessage>error</ErrorMessage>;
  }

  let memberComponent;
  if (status === Status.NotAMember) {
    memberComponent = (
      <Collaborator
        author={author}
        organization={organization}
        invitation={invitation}
      />
    );
  } else {
    memberComponent = (
      <Member
        isCurrentUser={currentUserId === author.id}
        authorName={author.name}
        organizationName={organization.name}
        revokeMembership={revokeMembership}
        busy={busy}
      />
    );
  }

  return (
    <>
      <Panel title={`${organization.name} Subscription Access`}>
        {memberComponent}
      </Panel>
      {status !== Status.NotAMember && (
        <Space mt={3}>
          <Panel title={`Administration privileges`}>
            <AdminStatus
              status={status}
              organization={organization}
              memberId={author.id}
            />
          </Panel>
        </Space>
      )}
    </>
  );
};

export default MembershipStatus;
