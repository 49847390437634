import { BaseBlock } from 'concepts/Extraction/Blocks/BaseBlock';
import { generateBlockID } from 'concepts/Extraction/Blocks/generateBlockID';

export interface OtherOptionBlock extends BaseBlock {
  type: 'OtherOption';
}

export const blockIsOtherOption = (
  block: BaseBlock
): block is OtherOptionBlock => block.type === 'OtherOption';

export const newOtherOptionBlock = (): OtherOptionBlock => ({
  type: 'OtherOption',
  id: generateBlockID(),
});
