import React from 'react';
import classNames from 'classnames';

import styles from './ButtonGroup.module.scss';

interface Props extends Omit<React.HTMLProps<HTMLDivElement>, 'size'> {
  type?: 'primary' | 'success' | 'warning' | 'danger' | 'neutral' | 'brand';
  variant?: 'solid' | 'outline';
}

const ButtonGroup = (
  {
    children,
    className,
    type = 'neutral',
    variant = 'outline',
    ...props
  }: Props,
  ref: React.ForwardedRef<HTMLDivElement>
) => {
  const validChildren = React.Children.toArray(children).filter(Boolean);
  const childCount = validChildren.length;
  return (
    <div
      ref={ref}
      className={classNames(styles.ButtonGroup, className, {
        [styles[type]]: !!type,
        [styles[variant]]: !!variant,
      })}
      {...props}
    >
      {React.Children.map(children, (child, index) => {
        if (child) {
          return (
            <>
              {child}
              {index < childCount - 1 ? (
                <div className={styles.separator} />
              ) : null}
            </>
          );
        }
      })}
    </div>
  );
};

export default React.forwardRef(ButtonGroup);
