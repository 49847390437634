import React from 'react';
import classNames from 'classnames';
import { FloatingPortal, useMergeRefs } from '@floating-ui/react';
import { useTooltipContext } from '../../Tooltip';
import { TooltipArrow } from '../TooltipArrow/TooltipArrow';
import styles from './TooltipContent.module.scss';

export const TooltipContent = React.forwardRef<
  HTMLDivElement,
  React.HTMLProps<HTMLDivElement>
>(function TooltipContent({ style, className, ...props }, propRef) {
  const context = useTooltipContext();
  const ref = useMergeRefs([context.refs.setFloating, propRef]);

  if (!context.open) return null;

  return (
    <FloatingPortal>
      <div
        ref={ref}
        style={{
          ...context.floatingStyles,
          ...style,
        }}
        className={classNames(styles.TooltipContent, className)}
        {...context.getFloatingProps(props)}
      >
        {context.hasArrow && <TooltipArrow />}
        {props.children}
      </div>
    </FloatingPortal>
  );
});
