import React from 'react';
import { HeadingBlock, newHeadingBlock } from '../Blocks/Heading/HeadingBlock';
import {
  newSubheadingBlock,
  SubheadingBlock,
} from '../Blocks/Subheading/SubheadingBlock';
import { TableBlock, newTableBlock } from '../Blocks/Table/TableBlock';
import BuildEditorInstructions from './BuildEditorInstructions';
import { BlocksContainer } from './Blocks/BlocksContainer';
import QualityAssessmentAddMenu from './QualityAssessment/AddMenu';
import DataExtractionAddMenu from './DataExtraction/AddMenu';
import { TemplateViewModel } from 'concepts/Extraction/ViewModel/TemplateViewModel';
import { TemplateBlock, BlockID } from 'concepts/Extraction/Blocks/BaseBlock';
import {
  newTextFieldBlock,
  TextFieldBlock,
} from 'concepts/Extraction/Blocks/TextField/TextFieldBlock';
import {
  useFocusManager,
  FocusManagerProvider,
  FocusOptions,
} from 'contexts/FocusManager/FocusManager';
import {
  newMultipleChoiceBlock,
  MultipleChoiceBlock,
} from 'concepts/Extraction/Blocks/MultipleChoice/MultipleChoiceBlock';
import {
  newCheckboxesBlock,
  CheckboxesBlock,
} from 'concepts/Extraction/Blocks/Checkboxes/CheckboxesBlock';
import {
  newDomainBlock,
  DomainBlock,
} from 'concepts/Extraction/Blocks/Domain/DomainBlock';
import { ExtractionFormType } from 'concepts/Extraction/ExtractionFormType';
import {
  BlockAction,
  applyBlockAction,
} from 'concepts/Extraction/Blocks/BlockAction';

type ChangeHandler = (prevViewModel: TemplateViewModel) => TemplateViewModel;

export interface BuildEditorProps {
  formType: ExtractionFormType;
  viewModel: TemplateViewModel;
  selectedBlockID: BlockID | null;
  onChange: (changer: ChangeHandler) => void;
  onSelectBlock: (blockID: BlockID) => void;
  generateNewBlock?: () => TemplateBlock;
  isCustomTables2Enabled?: boolean;
  progress: {
    started: number;
    completed: number;
  };
  showRevertButton: boolean;
  revertButtonOnClick: () => void;
}

function BuildQualityAssessmentEditor({
  viewModel,
  selectedBlockID,
  onChange,
  onSelectBlock,
  progress,
  revertButtonOnClick,
  showRevertButton,
}: BuildEditorProps): JSX.Element {
  const requestFocus = useFocusManager();
  function requestFocusOnBlock(blockID: string, focusOption: FocusOptions) {
    requestFocus(
      `[data-block-id="${blockID}"] textarea, [data-block-id="${blockID}"] input`,
      focusOption
    );
  }

  function dispatch(action: BlockAction<DomainBlock>) {
    if (action.type === 'selectBlock') {
      onSelectBlock(action.blockID);
      return;
    }

    const { newViewModel, focusOnBlockID } = applyBlockAction(
      viewModel,
      action,
      () => newDomainBlock('')
    );
    onChange(() => newViewModel);
    if (focusOnBlockID) {
      requestFocusOnBlock(focusOnBlockID, {
        when: action.type === 'addAfter' ? 'nextAnimationFrame' : 'nextRender',
      });
    }
  }

  return (
    <div>
      <BuildEditorInstructions
        progress={progress}
        onReset={() => {
          dispatch({
            type: 'replaceAll',
            newBlocks: [newDomainBlock('')],
          });
        }}
        showRevertButton={showRevertButton}
        revertButtonOnClick={revertButtonOnClick}
      />
      <BlocksContainer
        blocks={viewModel.blocks as any}
        dispatch={dispatch}
        selectedBlockID={selectedBlockID}
        renderMenu={(block) => (
          <QualityAssessmentAddMenu
            onAdd={() => {
              dispatch({
                type: 'addAfter',
                afterBlockID: block.id,
                newBlock: newDomainBlock(''),
              });
            }}
            onDuplicate={() => {
              // Create a new block, copying ONLY the judgements
              const newBlock = {
                ...newDomainBlock(''),
                judgements: block.judgements,
              };
              dispatch({ type: 'addAfter', afterBlockID: block.id, newBlock });
            }}
          />
        )}
      />
    </div>
  );
}

const BuildDataExtractionEditor = ({
  viewModel,
  selectedBlockID,
  onChange,
  onSelectBlock,
  isCustomTables2Enabled = false,
  progress,
  revertButtonOnClick,
  showRevertButton,
}: BuildEditorProps): JSX.Element => {
  const requestFocus = useFocusManager();
  function requestFocusOnBlock(blockID: string, focusOption: FocusOptions) {
    requestFocus(
      `[data-block-id="${blockID}"] textarea, [data-block-id="${blockID}"] input`,
      focusOption
    );
  }

  function dispatch(
    action: BlockAction<
      | TextFieldBlock
      | MultipleChoiceBlock
      | CheckboxesBlock
      | HeadingBlock
      | SubheadingBlock
      | TableBlock
    >
  ) {
    if (action.type === 'selectBlock') {
      onSelectBlock(action.blockID);
      return;
    }

    const { newViewModel, focusOnBlockID } = applyBlockAction(
      viewModel,
      action,
      () => newTextFieldBlock('')
    );
    onChange(() => newViewModel);
    if (focusOnBlockID) {
      requestFocusOnBlock(focusOnBlockID, {
        when: action.type === 'addAfter' ? 'nextAnimationFrame' : 'nextRender',
      });
    }
  }

  return (
    <div>
      <BuildEditorInstructions
        progress={progress}
        onReset={() => {
          dispatch({
            type: 'replaceAll',
            newBlocks: [newTextFieldBlock('')],
          });
        }}
        showRevertButton={showRevertButton}
        revertButtonOnClick={revertButtonOnClick}
      />
      <BlocksContainer
        blocks={viewModel.blocks as any}
        dispatch={dispatch}
        selectedBlockID={selectedBlockID}
        renderMenu={(block) => (
          <DataExtractionAddMenu
            isCustomTables2Enabled={isCustomTables2Enabled}
            onAdd={(blockType) => {
              let newBlock:
                | TextFieldBlock
                | MultipleChoiceBlock
                | CheckboxesBlock
                | HeadingBlock
                | SubheadingBlock
                | TableBlock;
              if (blockType === 'MultipleChoice') {
                newBlock = newMultipleChoiceBlock('');
              } else if (blockType === 'Checkboxes') {
                newBlock = newCheckboxesBlock('');
              } else if (blockType === 'Heading') {
                newBlock = newHeadingBlock('');
              } else if (blockType === 'Subheading') {
                newBlock = newSubheadingBlock('');
              } else if (blockType === 'Table') {
                newBlock = newTableBlock('');
              } else {
                newBlock = newTextFieldBlock('');
              }

              dispatch({ type: 'addAfter', afterBlockID: block.id, newBlock });
            }}
          />
        )}
      />
    </div>
  );
};

function BuilderFactory(props: BuildEditorProps): JSX.Element {
  switch (props.formType) {
    case 'quality_assessment':
      return <BuildQualityAssessmentEditor {...props} />;
    default:
      return <BuildDataExtractionEditor {...props} />;
  }
}

export default function BuildEditor(props: BuildEditorProps): JSX.Element {
  return (
    <FocusManagerProvider>
      <BuilderFactory {...props} />
    </FocusManagerProvider>
  );
}
