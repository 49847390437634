import React, { ReactNode } from 'react';
import classnames from 'classnames';
import styles from './UploaderSection.module.css';

interface Props {
  children: ReactNode;
  dashed?: boolean;
  error?: boolean;
}

const UploaderSection = ({
  children,
  dashed = false,
  error = false,
}: Props) => {
  return (
    <section
      className={classnames(styles.UploaderSection, {
        [styles['UploaderSection--dashed']]: dashed,
        [styles['UploaderSection--error']]: error,
      })}
    >
      {children}
    </section>
  );
};

export default UploaderSection;
