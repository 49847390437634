// Create a minimal API to expose React and select components on a global
// object so legacy Backbone can hook into it.
//
// We allow exporting React components as functions that instantiate the React
// component for easy mounting with the also-exported `ReactDom.render`
// wrapper.
//
import React from 'react';
import ReactDOM from 'react-dom';
import {
  addTagToStudies,
  removeTagFromStudies,
} from 'legacyBridge/bulkTagging';

// `Covidence` is the "namespace" that most of the Backbone application uses to
// hold all its components. We'll export React components here too.
window.Covidence = window.Covidence || {};
const cov = window.Covidence;

cov.reactUnmount = ReactDOM.unmountComponentAtNode.bind(ReactDOM);
cov.reactRender = ReactDOM.render;
cov.addTagToStudies = addTagToStudies;
cov.removeTagFromStudies = removeTagFromStudies;

export default function exportReactComponents(components = {}) {
  Object.keys(components).forEach((componentName) => {
    const Component = components[componentName];
    cov[componentName] = (props = {}) => {
      const component = React.createElement(Component, props);
      return component;
    };
  });
}
