const baseURL = new URL(window.location.origin);
const createURL = (url: string) => new URL(url, baseURL);

// Authentications
export const SINGLE_SIGN_ON_SIGN_IN = '/sign_in/sso';
export const SIGN_IN_SAML_EDIT = '/sign_in/saml/edit';

export const CITATION_IMPORTS = '/api/v1/citation_imports';
export const CITATION_IMPORTS_DELETE =
  '/reviews/{review_id}/citation_imports/{citation_import_id}';
export const CITATION_IMPORT_SOURCES_UPDATE =
  'api/v1/citation_imports/{citation_import_id}/citation_import_sources';
export const DUPLICATES_ROUTE = '/reviews/{review_id}/duplicates';
export const EXTRACTION_STUDIES_LIST_ROUTE =
  '/reviews/{review_id}/review_studies/included?filter=ready_for_extraction';

export const REVIEW_IMPORT_SOURCES =
  '/api/v1/reviews/{review_id}/review_citation_import_sources';
export const REVIEW_IMPORT_SOURCES_DELETE =
  '/api/v1/reviews/{review_id}/review_citation_import_sources/{id}';
export const UNMERGE_STUDY = '/api/v1/studies/{study_id}/unmerge';
export const CRITERIA = '/api/reviews/v1/reviews/{review_id}/criteria';

// Using screening endpoint for now until real endpoint is built
export const AUTO_EXCLUSIONS = '/reviews/{review_id}/auto_excluded_studies';

export const LATEST_AUTO_EXCLUSION_BATCH =
  '/api/reviews/v1/reviews/{review_id}/auto_exclusion_batches/latest';
export const REVIEW_FEATURE_FLAG_ROUTE =
  '/api/reviews/v1/reviews/{review_id}/features';

// Metadata
export const STUDY_METADATA =
  '/api/central_data_repo/v1/study_data/review_study_metadata/{reviewStudyId}';

// Data Extraction Templates
export const DATA_EXTRACTION_TEMPLATE_CREATE_ROUTE =
  '/reviews/{review_id}/data_extraction_template';
export const DATA_EXTRACTION_TEMPLATE_API_ROUTE =
  '/api/extraction/v1/reviews/{review_id}/templates/data_extraction';

// Quality Assessment Templates
export const QUALITY_ASSESSMENT_TEMPLATE_CREATE_ROUTE =
  '/reviews/{review_id}/qa_template';
export const QUALITY_ASSESSMENT_CUSTOM_TEMPLATE_CREATE_ROUTE =
  '/reviews/{review_id}/qa_template/default';
export const QUALITY_ASSESSMENT_TEMPLATE_API_ROUTE =
  '/api/extraction/v1/reviews/{review_id}/templates/quality_assessment';

// Data Extraction
export const DATA_EXTRACTION_ROUTE =
  '/api/extraction/v1/review_studies/{review_study_id}/data_extraction';

// Data Extraction: Identifications
export const CREATE_IDENTIFICATION_ROUTE = `/api/extraction/v1/review_studies/{review_study_id}/data_extraction/identifications`;

// Data Extraction: Custom Field Values
export const CREATE_CUSTOM_FIELD_VALUES_ROUTE = `/api/extraction/v1/review_studies/{review_study_id}/data_extraction/custom_field_values`;

// Data Extraction: Interventions and Arms
export const CHECK_INTERVENTION_EXTRACTED_DATA_ROUTE =
  'api/extraction/v1/review_studies/{review_study_id}/interventions/{intervention_id}/extracted';
export const INTERVENTION_CHARACTERISTIC_RESULTS_ROUTE =
  '/api/extraction/v1/review_studies/{review_study_id}/data_extraction/intervention_characteristics/{intervention_characteristic_id}/results';
export const CREATE_STUDY_ARM_ROUTE =
  '/api/extraction/v1/review_studies/{review_study_id}/data_extraction/arms';
export const STUDY_ARM_ROUTE = '/api/extraction/v1/arms/{arm_id}';
export const GET_ARMS_EXISTING_EXTRACTION_ROUTE =
  '/api/extraction/v1/arms/{arm_id}/extracted';
export const UPDATE_STUDY_ARM_POSITIONS_ROUTE =
  '/api/extraction/v1/review_studies/{review_study_id}/data_extraction/arms/set_positions';

// Data Extraction: Outcomes
export const CREATE_OUTCOME_ROUTE = `/api/extraction/v1/review_studies/{review_study_id}/data_extraction/outcomes`;
export const UPDATE_OUTCOME_ROUTE = `/api/extraction/v1/outcomes/{outcome_id}`;
export const DESTROY_OUTCOME_ROUTE = `/api/extraction/v1/outcomes/{outcome_id}`;
export const CHECK_OUTCOME_EXTRACTED_DATA_ROUTE =
  'api/extraction/v1/review_studies/{review_study_id}/data_extraction_template/outcomes/{template_outcome_id}/extracted';

// Data Extraction: Timepoints
export const CREATE_TIMEPOINT_ROUTE = `/api/extraction/v1/outcomes/{outcome_id}/timepoints`;
export const UPDATE_TIMEPOINT_ROUTE = `/api/extraction/v1/timepoints/{timepoint_id}`;
export const DESTROY_TIMEPOINT_ROUTE = `/api/extraction/v1/timepoints/{timepoint_id}`;
export const CHECK_TIMEPOINT_EXTRACTED_DATA_ROUTE =
  '/api/extraction/v1/timepoints/{timepoint_id}/extracted';

// Data Extraction: Notes
export const CREATE_STUDY_NOTE_ROUTE = `/api/extraction/v1/review_studies/{review_study_id}/notes`;
export const UPDATE_STUDY_NOTE_ROUTE = `/api/extraction/v1/review_studies/{review_study_id}/notes/{note_id}`;

// Data Extraction: Results
export const SAVE_RESULT = `/api/extraction/v1/review_studies/{review_study_id}/data_extraction/results/upsert`;
export const CREATE_OUTCOME_RESULT = `/api/extraction/v1/outcomes/{outcome_id}/outcome_results`;
export const REPLACE_OUTCOME_RESULT = `/api/extraction/v1/outcome_results/{outcome_result_id}/replace`;
export const DELETE_OUTCOME_RESULT = `/api/extraction/v1/outcome_results/{outcome_result_id}`;
export const ANY_RESULTS_EXTRACTED = `/api/extraction/v1/outcome_results/{outcome_result_id}/extracted`;
export const UPDATE_OUTCOME_RESULT_POSITIONS_ROUTE =
  '/api/extraction/v1/review_studies/{review_study_id}/data_extraction/outcomes/{outcome_id}/outcome_results/set_positions';

// Reviewer
export const REVIEWER_FEATURE_FLAG_ROUTE = '/api/reviewers/v1/features';

/**
 * Replace route tokens with values. Also add any search params to non-tokenized routes.
 * @param route
 * @param tokens
 */
export const tokenizeRoute = (route: string, tokens: any) => {
  // extract tokens to replace in route
  const rawTokens = Array.from(route.matchAll(/{(.*?)}/g), (match) => match[0]);

  // map them into an object of raw tokens (including the {}), and stripped tokens to match with the input tokens
  const tokenMap = rawTokens.reduce<any>((obj, rawToken) => {
    const strippedToken = rawToken.slice(1, -1);
    const rtn = { ...obj };
    rtn[strippedToken] = rawToken;
    return rtn;
  }, {});

  let outputRoute = route;
  const params: any = {};

  // Replace route tokens with input token values
  Object.keys(tokens).forEach((token) => {
    if (tokenMap[token]) {
      outputRoute = outputRoute.replace(tokenMap[token], tokens[token]);
      delete tokenMap[token];
    } else {
      // If there is no matching token, add it as a search param
      params[token] = tokens[token];
    }
  });

  if (Object.keys(tokenMap).length > 0)
    throw new Error(`Missing tokens: ${Object.keys(tokenMap).join(',')}`);

  // Put any additional params on the route
  const url = createURL(outputRoute);
  Object.keys(params).forEach((key) => {
    url.searchParams.set(key, params[key]);
  });

  return url;
};
