import React from 'react';

interface Props {
  className?: string;
  children: React.ReactNode;
}

const MenuHeadingContainer = ({ className, children }: Props) => {
  return (
    <li role="menuitem" className={className}>
      {children}
    </li>
  );
};

export default MenuHeadingContainer;
