import React from 'react';
import { PENDO_REVIEW_SETTING_PREFIX } from '../ExtractionToolSelector';
import styles from './SingleExtractorModal.module.scss';
import {
  Button,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Text,
  Badge,
} from 'components/core';

interface Props {
  onConfirm: () => void;
  onClose: () => void;
}

const SingleExtractorModal = ({ onConfirm, onClose }: Props) => {
  return (
    <Modal isOpen={true} onClose={onClose}>
      <ModalHeader>
        <div className={styles.ModalHeaderTitle}>
          <Text>Single reviewer workflow is now available!</Text>
          <Badge size="md" type="primary" variant="solid">
            Early access
          </Badge>
        </div>
      </ModalHeader>
      <ModalBody className={styles.ModalBody}>
        With this feature, you can:
        <List>
          <ListItem>Extract and export data as a single reviewer</ListItem>
          <ListItem>
            Eliminate the need for consensus of each study form
          </ListItem>
        </List>
        <Text>
          Please note, this is an early access release and may still lack some
          features. We&apos;re actively working on improvements and would love
          your feedback to help us make it better!
        </Text>
        <Text>Would you like to enable this feature?</Text>
      </ModalBody>
      <ModalFooter>
        <Button type="neutral" variant="outline" onClick={onClose}>
          Cancel
        </Button>
        <Button
          type="brand"
          onClick={onConfirm}
          data-pendo-key={
            PENDO_REVIEW_SETTING_PREFIX +
            'single_extractor_notification_confirmation'
          }
        >
          Yes, enable
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default SingleExtractorModal;
