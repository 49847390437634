import React from 'react';
import { FloatingArrow } from '@floating-ui/react';
import { useTooltipContext } from '../../Tooltip';
import styles from './TooltipArrow.module.scss';

export const TooltipArrow = () => {
  const { arrowRef, context } = useTooltipContext();

  return (
    <FloatingArrow className={styles.arrow} ref={arrowRef} context={context} />
  );
};
