import React from 'react';
import { Study } from './StudyTypes';
import styles from './UnmergeStudiesModal.module.css';
import UnmergeStudiesButton from './UnmergeStudiesButton';
import Dialog, { Banner as DialogBanner } from 'system/base/Dialog';
import { NakedButton } from 'system/base/Button';

export interface UnmergeStudiesModalProps {
  isOpen: boolean;
  primaryStudy: Study;
  onUnmergeSuccess: () => void;
  onUnmergeFailure: () => void;
  onCancel: () => void;
}

export default function UnmergeStudiesModal({
  isOpen,
  primaryStudy,
  onUnmergeSuccess,
  onUnmergeFailure,
  onCancel,
}: UnmergeStudiesModalProps): JSX.Element {
  const threshold = 20;
  function displayThresholdReached() {
    return primaryStudy.references.nodes.length >= threshold;
  }

  return (
    <Dialog isOpen={isOpen} onDismiss={onCancel} title="Un-merge studies">
      <DialogBanner type="warning">
        <div>
          {displayThresholdReached() ? (
            <div>There are over 20 merged studies.</div>
          ) : (
            <div>
              There are {primaryStudy.references.nodes.length} merged studies.
            </div>
          )}
        </div>
      </DialogBanner>
      <div>
        <p>
          Un-merging will return all studies back to the state they were in
          before they were merged. This includes restoring any extracted data.
        </p>
        <p>Are you sure you want to un-merge?</p>

        <div>
          <UnmergeStudiesButton
            mr={20}
            primaryStudy={primaryStudy}
            handleSuccess={onUnmergeSuccess}
            handleFailure={onUnmergeFailure}
          />
          <NakedButton onClick={onCancel} className={styles.cancel}>
            Cancel
          </NakedButton>
        </div>
      </div>
    </Dialog>
  );
}
