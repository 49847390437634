import React, { ReactNode } from 'react';
import { Box, Flex } from 'theme-ui';
import { useDescribedBy } from 'hooks/useAccessibleName';

type CompareNotificationBorderProps = {
  showNotification: boolean;
  notificationMessage: string;
  children: ReactNode;
};

export const CompareNotificationBorder = ({
  showNotification = false,
  notificationMessage,
  children,
}: CompareNotificationBorderProps): JSX.Element => {
  const [descriptionID, describedBy] = useDescribedBy();

  if (!showNotification) {
    return <>{children}</>;
  }

  return (
    <Flex
      {...describedBy}
      sx={{
        flexDirection: 'column',
        border: 'solid 2px',
        borderColor: 'cochrane',
        height: '100%',
      }}
    >
      <Box
        data-testid="compare-notification"
        id={descriptionID}
        role="status"
        p={1}
        color="white"
        backgroundColor="cochrane"
        sx={{ fontSize: 1 }}
      >
        {notificationMessage}
      </Box>
      {children}
    </Flex>
  );
};
