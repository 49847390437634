import React from 'react';
import MenuHeadingContainer from './components/MenuHeadingContainer';
import MenuHeadingHeading from './components/MenuHeadingHeading';
import { Props as TextProps } from 'components/core/Typography/components/Text/Text';

const MenuHeading = ({ ...props }: Partial<TextProps>) => {
  return (
    <MenuHeading.Container>
      <MenuHeading.Heading {...props} />
    </MenuHeading.Container>
  );
};

MenuHeading.Container = MenuHeadingContainer;
MenuHeading.Heading = MenuHeadingHeading;

export default MenuHeading;
