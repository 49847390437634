import React from 'react';

import styles from './StageNotStarted.module.css';

interface StageNotStarted {
  reviewerName: string;
}

const StageNotStarted = ({ reviewerName }: StageNotStarted) => {
  return (
    <div className={styles.notStarted}>
      {reviewerName}, you have not yet started
    </div>
  );
};

export default StageNotStarted;
