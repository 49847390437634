import React from 'react';
import idx from 'idx';
import { gql, useQuery } from '@apollo/client';

import styles from './ExportHistory.module.css';
import {
  GetAuthorsExportQuery,
  GetAuthorsExportQueryVariables,
} from 'types/graphql';

import Icon from 'system/base/Icon';

interface ExportData {
  id: string;
  authenticatedUrl: string;
  createdAt: string;
  completedAt: string;
  failed: boolean;
}

interface ExportHistoryProps {
  organizationId: string;
  initialExports: ExportData[];
}

const GET_AUTHOR_EXPORTS = gql`
  query GetAuthorsExport($organizationId: ID!) {
    organization: node(id: $organizationId) {
      ... on Organization {
        exports {
          id
          authenticatedUrl
          createdAt
          completedAt
          failed
        }
      }
    }
  }
`;

const ExportHistory = ({ organizationId }: ExportHistoryProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data, error, loading } = useQuery<
    GetAuthorsExportQuery,
    GetAuthorsExportQueryVariables
  >(GET_AUTHOR_EXPORTS, {
    pollInterval: process.env.DEV ? 1000 : 6000, // eslint-disable-line no-undef
    variables: { organizationId },
  });

  if (loading) {
    return null;
  }

  const exports = idx(data, (_) => _.organization.exports) || [];

  if (exports.length == 0) {
    return <div>There have been no exports in the last 7 days</div>;
  }

  const renderExport = (ex: ExportData) => {
    return (
      <ExportRow
        key={ex.id}
        id={ex.id}
        createdAt={ex.createdAt}
        completedAt={ex.completedAt}
        authenticatedUrl={ex.authenticatedUrl}
        failed={ex.failed}
      />
    );
  };

  return (
    <div className={styles.exportHistory}>
      <div className="floating-box">
        <h2 className={styles.title}>EXPORT HISTORY</h2>
        <table className={styles.table}>
          <thead>
            <tr>
              <th style={{ width: '33%' }}>Exported</th>
              <th style={{ width: '33%' }}>Type</th>
              <th style={{ width: '33%' }}>Status</th>
            </tr>
          </thead>
          <tbody>{exports.map(renderExport)}</tbody>
        </table>
      </div>
    </div>
  );
};

const ExportRow = ({
  createdAt,
  completedAt,
  authenticatedUrl,
  failed,
}: ExportData) => {
  const renderStatus = () => {
    if (completedAt && authenticatedUrl) {
      return (
        <a className="btn btn-secondary" href={authenticatedUrl}>
          <Icon name="download" /> Download
        </a>
      );
    }

    if (failed) {
      return 'Failed';
    }

    return <div>Pending...</div>;
  };

  return (
    <tr className="export">
      <td>{createdAt}</td>
      <td>Authors</td>
      <td>{renderStatus()}</td>
    </tr>
  );
};

export default ExportHistory;
