import React, { useRef, useState, KeyboardEvent, useEffect } from 'react';
import { CustomImportSource as CustomImportSourceType } from 'types/CitationImport';
import styles from './ReviewCitationSourcesModal.module.scss';
import {
  Button,
  Icon,
  IconList,
  Input,
  Link,
  LoadingSpinner,
  Modal,
  ModalHeader,
  ModalBody,
  Text,
} from 'components/core';
import { useDebouncedFunction } from 'hooks/useDebouncedFunction';
import {
  getReviewImportSources,
  createReviewImportSource,
  deleteReviewImportSource,
  getReviewId,
} from 'query/review';

interface Props {
  isOpen: boolean;
  setIsOpen: (bool: boolean) => any;
  reviewImportSources: string[];
  setReviewImportSources: (sourceNames: string[]) => void;
  customReviewImportSources: CustomImportSourceType[];
  setCustomReviewImportSources: any;
}

const FormattedError = ({
  errors,
  sourceName,
}: {
  errors?: Record<string, string> | null;
  sourceName: string;
}) => {
  const [isLinkLoading, setIsLinkLoading] = useState<boolean>(false);
  const error = errors && errors[sourceName];

  if (!error) return null;

  return (
    <p
      data-testid={`error-${sourceName}`}
      className={styles.ReviewCitationSourcesModal_deleteError}
    >
      {error}

      {error.includes('This source is currently in use.') && (
        <>
          <Link
            onClick={() => {
              setIsLinkLoading(true);
            }}
            className={styles.ReviewCitationSourcesModal_errorLink}
            variant={'primary'}
            to={`/reviews/${getReviewId()}/citation_imports`}
          >
            Import History.
          </Link>

          {isLinkLoading && (
            <span className={styles.ReviewCitationSourcesModal_errorLink}>
              &nbsp;Please wait&nbsp;
              <LoadingSpinner
                className={styles.ReviewCitationSourcesModal_errorLink}
              />
            </span>
          )}
        </>
      )}
    </p>
  );
};

const ReviewCitationSourcesModal = ({
  isOpen,
  setIsOpen,
  reviewImportSources,
  setReviewImportSources,
  customReviewImportSources,
  setCustomReviewImportSources,
}: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [inputError, setInputError] = useState<string | null>(null);
  const [canSubmit, setCanSubmit] = useState<boolean>(false);
  const [deleteErrors, setDeleteErrors] = useState<Record<
    string,
    string
  > | null>(null);
  const [deleteButtonsDisabled, setDeleteButtonsDisabled] = useState<any>(null);

  const unmounted = useRef(false);

  useEffect(() => {
    return () => {
      unmounted.current = true;
    };
  });
  const checkDuplicateSource = () => {
    const inputValue = inputRef.current?.value.trim();
    if (!inputValue) return;

    const lowerCaseMatch = (target: string, subject: string): boolean => {
      return target.toLowerCase() === subject.toLowerCase();
    };

    const foundSource = reviewImportSources.find((source) => {
      return lowerCaseMatch(source, inputValue);
    });

    if (foundSource) {
      setCanSubmit(false);
      setInputError(`${foundSource} has already been added.`);
    } else {
      setInputError(null);
      setCanSubmit(true);
    }
  };
  const checkDuplicateError = useDebouncedFunction(checkDuplicateSource, 300);

  const handleAddSource = () => {
    setCanSubmit(false);
    const inputValue = inputRef.current?.value.trim();
    if (inputValue) {
      createReviewImportSource(inputValue)
        .then(({ id }) => {
          if (id) {
            // optimistically set review import sources
            setReviewImportSources([...reviewImportSources, inputValue]);
            Promise.resolve({ id }).then(() => {
              getReviewImportSources().then(({ reviewImportSources }) => {
                setReviewImportSources(reviewImportSources);
                if (inputRef.current) inputRef.current.value = '';
                unmounted.current || setInputError(null);
                // add to custom sources so that added source will render with a delete button as needed
                setCustomReviewImportSources([
                  ...customReviewImportSources,
                  { id: id, sourceName: inputValue },
                ]);
              });
            });
          } else {
            setInputError('Unable to save source. Please try again.');
          }
        })
        .catch(() => {
          setInputError('Unable to save source. Please try again.');
        })
        .finally(() => {
          if (!unmounted.current) {
            setCanSubmit(true);
            setDeleteErrors(null); // always clear any delete validation errors after processing Add Source
            setDeleteButtonsDisabled(null);
          }
        });
    }
  };

  const onEnterKeyPress = (event: KeyboardEvent) => {
    if (!canSubmit) return;

    if (event.key === 'Enter') {
      handleAddSource();
    }
  };

  const handleDeleteSource = (id: string, sourceName: string) => {
    setDeleteButtonsDisabled({
      [sourceName]: true,
    });
    deleteReviewImportSource(id, sourceName)
      .then(({ sourceName, message }) => {
        if (message === 'Deleted successfully.') {
          const updatedSources = reviewImportSources.filter(
            (originalSourceName) => {
              return originalSourceName !== sourceName;
            }
          );
          setReviewImportSources(updatedSources);
          const updatedCustomSources = customReviewImportSources.filter(
            (customSource) => {
              return customSource.sourceName !== sourceName;
            }
          );
          setCustomReviewImportSources(updatedCustomSources);
          setDeleteButtonsDisabled({
            [`${sourceName}`]: false,
          });
        } else {
          if (sourceName && message) {
            setDeleteErrors({
              [sourceName]: message,
            });
          }
        }
      })
      .catch(() => {
        setDeleteErrors({
          [sourceName]: 'Unable to delete source. Please try again.',
        });
      });
  };

  return (
    <Modal
      size="sm"
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false);
        setInputError(null);
        setDeleteErrors(null);
        setDeleteButtonsDisabled(null);
      }}
    >
      <ModalHeader>
        <Text variant="neutral" component="span">
          Source
        </Text>
      </ModalHeader>
      <ModalBody>
        <div className={styles.ReviewCitationSourcesModal_inputGroup}>
          <Input
            size="sm"
            aria-label="Add source"
            placeholder="Add source"
            ref={inputRef}
            isError={!!inputError}
            onChange={checkDuplicateError}
            onKeyUp={onEnterKeyPress}
          />
          <Button
            size="sm"
            type="neutral"
            variant="outline"
            onClick={handleAddSource}
            data-testid="ReviewCitationSourcesModal_addButton"
            disabled={!canSubmit}
          >
            <Text>Add</Text>
          </Button>
        </div>
        {!!inputError && (
          <span
            className={styles.ReviewCitationSourcesModal_inputError}
            data-testid="ReviewCitationSourcesModal_inputError"
          >
            <Text>{inputError}</Text>
          </span>
        )}
        <ul
          className={styles.ReviewCitationSourcesModal_sourceList}
          data-testid="sourcesList"
        >
          {reviewImportSources.map((sourceName, index) => {
            const customSource = customReviewImportSources.find(
              (source) => source.sourceName === sourceName
            );

            return (
              <li
                className={styles.ReviewCitationSourcesModal_sourceItem}
                key={`citation-import-source-${index}`}
                data-testid="ReviewCitationSourcesModal_sourceItem"
              >
                <div
                  className={
                    styles.ReviewCitationSourcesModal_sourceContentWrapper
                  }
                >
                  <div
                    className={
                      styles.ReviewCitationSourcesModal_sourceDetailsWrapper
                    }
                  >
                    <div
                      className={
                        styles.ReviewCitationSourcesModal_sourceNameWrapper
                      }
                    >
                      <Text variant="neutral" size="sm">
                        {sourceName}
                      </Text>
                    </div>

                    {customSource && (
                      <Button
                        data-testid={`delete-${sourceName}`}
                        className={
                          styles.ReviewCitationSourcesModal_deleteButton
                        }
                        disabled={
                          deleteButtonsDisabled &&
                          deleteButtonsDisabled[sourceName]
                        }
                        iconOnly
                        variant="outline"
                        isNaked
                        onClick={() => {
                          handleDeleteSource(
                            customSource.id,
                            customSource.sourceName
                          );
                        }}
                        aria-label={'Delete Review Import Source'}
                      >
                        <Icon icon={IconList.light.faTrashCan} />
                      </Button>
                    )}
                  </div>

                  <FormattedError
                    errors={deleteErrors}
                    sourceName={sourceName}
                  />
                </div>
              </li>
            );
          })}
        </ul>
      </ModalBody>
    </Modal>
  );
};

export default ReviewCitationSourcesModal;
