import React, { FunctionComponent, useEffect } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import idx from 'idx';
import {
  ReviewCrsImportStatusQuery,
  ReviewCrsImportStatusQueryVariables,
  InitiateCrsImportMutation,
  InitiateCrsImportMutationVariables,
} from 'types/graphql';
import { ID } from 'util/types';
import Box from 'system/base/Box';
import Notice from 'system/elements/Notice';
import Panel from 'system/elements/Panel';
import LoadingSpinner from 'system/elements/Spinner';
import { PrimaryButton } from 'system/base/Button';
import Text from 'system/base/Text';

interface Props {
  reviewId: ID;
}

const QUERY = gql`
  query ReviewCrsImportStatus($reviewId: ID!) {
    review: node(id: $reviewId) {
      ... on Review {
        cochraneReviewLink {
          id
          crsPendingStudiesCount
        }
      }
    }
  }
`;

const INITIATE_MUTATION = gql`
  mutation initiateCrsImport($cochraneId: ID!) {
    initiateCrsImport(input: { cochraneReviewLinkId: $cochraneId }) {
      success
    }
  }
`;

interface Props {
  reviewId: ID;
}

const ImportCRS: FunctionComponent<Props> = ({ reviewId }: Props) => {
  const { data, error, loading } = useQuery<
    ReviewCrsImportStatusQuery,
    ReviewCrsImportStatusQueryVariables
  >(QUERY, { variables: { reviewId } });

  const [
    initiateImport,
    { data: initiateData, error: initiateError, loading: initiateLoading },
  ] = useMutation<
    InitiateCrsImportMutation,
    InitiateCrsImportMutationVariables
  >(INITIATE_MUTATION);

  useEffect(() => {
    if (idx(initiateData, (_) => _.initiateCrsImport.success)) {
      window.location.href = `/reviews/${reviewId}`;
    }
  }, [initiateData, reviewId]);

  const pendingCount = idx(
    data,
    (_) => _.review.cochraneReviewLink.crsPendingStudiesCount
  );

  if (loading) {
    return (
      <Panel primary>
        <LoadingSpinner />
      </Panel>
    );
  }

  if (error) {
    return (
      <Panel primary>
        <Notice type="warning">{error.message}</Notice>
      </Panel>
    );
  }

  return (
    <Panel primary>
      {initiateError && <Notice type="error">{initiateError.message}</Notice>}
      <p>
        There are{' '}
        <strong>
          {pendingCount === 0 || pendingCount == null ? 'no' : pendingCount}{' '}
          studies
        </strong>{' '}
        in the <strong>Cochrane Registry of Studies</strong> for this review
        that are ready to be screened.
      </p>
      {pendingCount ? (
        <>
          <Box marginTop={3}>
            {initiateLoading ? (
              <PrimaryButton>
                <LoadingSpinner />
              </PrimaryButton>
            ) : (
              <PrimaryButton
                onClick={() =>
                  initiateImport({
                    variables: {
                      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                      cochraneId: idx(
                        data,
                        (_) => _.review.cochraneReviewLink.id
                      )!,
                    },
                  })
                }
              >
                Import {pendingCount} studies from CRS
              </PrimaryButton>
            )}
          </Box>
          <Box marginTop={3}>
            <Text textStyle="muted">
              Studies will automatically be imported into title and abstract
              screening
            </Text>
          </Box>
        </>
      ) : (
        <Box marginTop={3}>
          Speak with your Cochrane Information Specialist about importing
          studies directly from CRS.
        </Box>
      )}
    </Panel>
  );
};

export default ImportCRS;
