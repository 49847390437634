import React from 'react';
import {
  Menu as ReachMenu,
  MenuButton,
  MenuPopover,
  MenuItems,
  MenuItem as ReachMenuItem,
} from '@reach/menu-button';
import styles from './Menu.module.css';
import Icon from 'system/base/Icon';
import { useLabelledBy, useDescribedBy } from 'hooks/useAccessibleName';

export interface MenuItemProps {
  title: string;
  description?: string;
  icon?: JSX.Element;
  checked?: boolean;
  onSelect(): void;
}
export function MenuItem({
  title,
  description,
  icon,
  checked,
  onSelect,
}: MenuItemProps): JSX.Element {
  const [labelID, labelledBy] = useLabelledBy();
  const [descriptionID, describedBy] = useDescribedBy();

  return (
    <ReachMenuItem
      {...labelledBy}
      {...describedBy}
      role={checked !== undefined ? 'menuitemcheckbox' : 'menuitem'}
      aria-checked={checked}
      onSelect={onSelect}
      className={styles.menuItem}
    >
      {checked !== undefined && (
        <span className={styles.checkboxHolder}>
          {checked && <Icon name="check" />}
        </span>
      )}
      {icon && <div className={styles.menuIconBox}>{icon}</div>}
      <div>
        <div id={labelID} className={styles.menuItemTitle}>
          {title}
        </div>
        {description && (
          <small id={descriptionID} className={styles.menuItemDescription}>
            {description}
          </small>
        )}
      </div>
    </ReachMenuItem>
  );
}

// This is copied and adjusted from the @reach/popover source
const positionRight = (
  targetRect?: Partial<DOMRect> | null,
  popoverRect?: Partial<DOMRect> | null
) => {
  if (
    !targetRect ||
    !popoverRect ||
    targetRect.right == null ||
    targetRect.top == null
  ) {
    return {};
  }

  return {
    left: `${targetRect.right + window.pageXOffset}px`,
    top: `${targetRect.top + window.pageYOffset}px`,
  };
};

export interface MenuProps {
  buttonTitle: React.ReactNode;
  itemsHeading: React.ReactNode;
  children: React.ReactNode;
  position?: 'right' | 'default';
}
export function Menu({
  buttonTitle,
  itemsHeading,
  children,
  position = 'default',
}: MenuProps): JSX.Element {
  return (
    <ReachMenu>
      <MenuButton className={styles.menuButton}>{buttonTitle}</MenuButton>
      <MenuPopover position={position === 'right' ? positionRight : undefined}>
        <div className={styles.menuList}>
          <div className={styles.menuListHeading}>{itemsHeading}</div>
          <MenuItems className={styles.menuItems}>{children}</MenuItems>
        </div>
      </MenuPopover>
    </ReachMenu>
  );
}
