import React from 'react';
import { usePopoverContext } from '../../Popover';
import styles from './PopoverCloseButton.module.scss';
import { Button } from 'components/core/Button';
import { Icon, IconList } from 'components/core/Icon';

export const PopoverCloseButton = () => {
  const { setIsOpen, onClose } = usePopoverContext();

  return (
    <Button
      aria-label="Close popover"
      htmlType="button"
      variant="ghost"
      size="sm"
      iconOnly
      className={styles.closeButton}
      onClick={() => {
        setIsOpen(false);
        onClose && onClose();
      }}
    >
      <Icon icon={IconList.light.faClose} size="lg" />
    </Button>
  );
};
