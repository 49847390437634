type ErrorMessageInput = Pick<Error, 'message'> | string | undefined | null;

export function errorMessagesFrom(
  ...input: Array<ErrorMessageInput | ReadonlyArray<ErrorMessageInput>>
): Array<string> {
  const result: Array<string> = [];

  const errorMessageFrom = (input: Pick<Error, 'message'> | string) =>
    typeof input === 'string' ? input : input.message;

  input.forEach((input) => {
    const flattened = new Array<ErrorMessageInput>().concat(input);
    flattened.forEach((item) => {
      if (item != null) {
        result.push(errorMessageFrom(item));
      }
    });
  });

  return result;
}
