import React from 'react';
import Props from '../reviewInnerProps';
import { AccountControls } from '../AccountControls';

const CantLinkCochrane = ({
  accounts,
  account,
  setSelectedAccountId,
}: Props) => {
  return (
    <>
      <div className="row">
        <div className="col-md-8">
          <div className="bg-danger new_cochrane_review--error">
            <p>
              <i className="fas fa-exclamation-triangle new_cochrane_review--danger" />
              Cochrane reviews are only available to users who have signed in
              via Cochrane. To access your Cochrane reviews please{' '}
              <a href="/sign_in/cochrane">sign in with Cochrane</a>.
            </p>
          </div>
        </div>
      </div>
      <AccountControls
        accounts={accounts || []}
        isCochraneReview={false}
        isSubmittable={false}
        selectedAccountId={account.id}
        setSelectedAccountId={setSelectedAccountId}
        submitting={false}
      />
    </>
  );
};

export default CantLinkCochrane;
