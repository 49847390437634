import { ImportJob, ImportError } from './types';
import FullTextBulkUploader from './FullTextBulkUploader';

export const importJobError = (job: ImportJob | null): ImportError | null => {
  if (!job) return null;
  if (job.import_errors.length > 0) return ImportError.FAILED_TO_PARSE;
  if (job.found_with_full_text <= 0)
    return ImportError.NO_STUDIES_MISSING_FULL_TEXT;
  if (job.files_to_upload <= 0) return ImportError.NO_STUDIES_MISSING_PDF;
  return null;
};

export const importJobSuccessful = (job: ImportJob | null) => {
  return job && !importJobError(job);
};

export default FullTextBulkUploader;
export { FullTextBulkUploader };
export * from './types';
