import React, { ReactNode } from 'react';
import ReactDOM from 'react-dom';
import { slide as SlideBar } from 'react-burger-menu';

interface Props {
  children: ReactNode;
  open: boolean;
  onOpenChange?: (open: boolean) => void;
}

const styles = {
  bmCrossButton: {
    height: '34px',
    width: '34px',
  },
  bmCross: {
    background: '#597cd9',
  },
  bmMenuWrap: {
    position: 'fixed',
  },
  bmMenu: {
    background: 'white',
    borderLeft: '1px solid #f0f0f0',
  },
  bmBurgerBars: {},
  bmBurgerButton: {},
  bmItemList: {},
  bmMorphShape: {},
  bmOverlay: {},
};

const Sidebar = ({ children, open, onOpenChange }: Props) => {
  const sidebarDiv = document.getElementById('sidebar');
  if (!sidebarDiv) {
    // eslint-disable-next-line no-console
    console.log("warning: can't find sidebar div");
    return null;
  }

  return ReactDOM.createPortal(
    <SlideBar
      isOpen={open}
      onStateChange={
        onOpenChange
          ? ({ isOpen }: { isOpen: boolean }) => onOpenChange(isOpen)
          : undefined
      }
      right
      customBurgerIcon={false}
      styles={styles}
    >
      {children}
    </SlideBar>,
    sidebarDiv
  );
};

export default Sidebar;
