/*

Returns state whether the browser is reporting as online or not. Often we'd want
to update some other behaviour based on this value, for example:

```
useEffect(() => {
  if (!online) {
    setNoticeState("You are currently offline")
  } else {
    setNoticeState(null)
  }
}, [online])
```

This effect will run on the initial render, so setNoticeState will be correct
on mount

*/
import { useState, useEffect } from 'react';

const getBooleanOrDefault = (defaultValue: boolean, value?: boolean) =>
  typeof value === 'boolean' ? value : defaultValue;

const useNavigatorOnline = () => {
  // default to "online" when navigator.onLine is not available (in node or old browsers)
  const [online, setOnline] = useState<boolean>(
    getBooleanOrDefault(true, navigator?.onLine)
  );

  useEffect(() => {
    const onlineHandler = () => setOnline(true);
    const offlineHandler = () => setOnline(false);

    window.addEventListener('online', onlineHandler);
    window.addEventListener('offline', offlineHandler);

    return () => {
      window.removeEventListener('online', onlineHandler);
      window.removeEventListener('offline', offlineHandler);
    };
  }, [setOnline]);

  return [online];
};

export default useNavigatorOnline;
