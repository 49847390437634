import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import TextField from './control/TextField';
import { TextFieldBlock } from './TextFieldBlock';
import { TextFieldEditorSettings } from './editor/TextFieldEditorSettings';
import { TextFieldComparisonRow } from './control/TextFieldComparisonRow';
import {
  PreviewProps,
  ControlProps,
  EditorProps,
  EditorSettingsProps,
  BlockRenderer,
  ComparisonRowProps,
} from 'concepts/Extraction/Blocks/BlockRenderer';
import TextFieldEditor from 'concepts/Extraction/Blocks/TextField/editor/TextFieldEditor';

const typeIconSVG = (
  <svg
    role="img"
    fill="none"
    height="32"
    viewBox="0 0 55 32"
    width="55"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Text Field</title>
    <path
      d="m0 5c0-2.76142 2.23858-5 5-5h45c2.7614 0 5 2.23858 5 5v22c0 2.7614-2.2386 5-5 5h-45c-2.76143 0-5-2.2386-5-5z"
      fill="#3eb5a0"
    />
    <path
      d="m6.83 22v-.782l1.598-.374 4.131-10.829h1.224l3.876 10.846 1.564.357v.782h-4.998v-.782l1.258-.289-.816-2.295h-4.165l-.884 2.244 1.496.34v.782zm4.046-4.369h3.417l-1.666-4.641zm8.9045 2.261c0-1.9607 1.7397-2.941 5.219-2.941v-.595c0-.986-.1416-1.6093-.425-1.87-.272-.272-.7026-.408-1.292-.408l-1.989 1.7c-.272-.17-.5043-.408-.697-.714-.1813-.3173-.2776-.6517-.289-1.003.476-.2607.9974-.459 1.564-.595.5667-.1473 1.1277-.221 1.683-.221 2.3234 0 3.485.9747 3.485 2.924v4.726h1.326v.561c-.2606.2493-.5496.442-.867.578-.3173.1473-.629.221-.935.221-.6686 0-1.1616-.289-1.479-.867-.7593.578-1.615.867-2.567.867-.748 0-1.394-.1983-1.938-.595-.5326-.3967-.799-.986-.799-1.768zm2.142-.068c0 .4193.136.731.408.935.2834.204.6404.306 1.071.306.6234 0 1.156-.1927 1.598-.578v-2.669c-1.02 0-1.7906.187-2.312.561-.51.3627-.765.8443-.765 1.445z"
      fill="#fff"
    />
  </svg>
);

export const TextFieldBlockRenderer: BlockRenderer = {
  Editor({ block, onChange, onComplete, onRemove }: EditorProps) {
    return (
      <TextFieldEditor
        block={block as TextFieldBlock}
        onChange={onChange}
        onComplete={onComplete}
        onRemove={onRemove}
      />
    );
  },
  EditorSettings({ block, onChange }: EditorSettingsProps) {
    return (
      <TextFieldEditorSettings
        block={block as TextFieldBlock}
        onChange={onChange}
      />
    );
  },
  Control({ block }: ControlProps) {
    const { setValue, control } = useFormContext();
    const blockValue = useWatch({
      control,
      name: block.id,
    });

    return (
      <TextField
        editable
        block={block as TextFieldBlock}
        value={blockValue?.value}
        comments={blockValue?.comment}
        onChange={(newValue) => {
          setValue(
            block.id,
            { ...blockValue, value: newValue },
            { shouldDirty: true }
          );
        }}
        onChangeComments={(newComment) => {
          setValue(
            block.id,
            { ...blockValue, comment: newComment },
            { shouldDirty: true }
          );
        }}
      />
    );
  },
  Preview({ block }: PreviewProps) {
    return <TextField block={block as TextFieldBlock} />;
  },
  ComparisonRow(props: ComparisonRowProps) {
    return <TextFieldComparisonRow {...props} />;
  },
  keepResponse() {
    return true;
  },
  displayOnly: false,
  TypeIcon: () => typeIconSVG,
};
