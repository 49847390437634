import React, { useEffect } from 'react';
import styles from './DuplicateStudyToast.module.scss';
import {
  ToastContainer,
  createToast,
  Toast,
  ToastButton,
  Link,
} from 'components/core';

interface props {
  reviewId: string;
  onUndo: () => void;
}

const DuplicateStudyToast = ({ reviewId, onUndo }: props) => {
  useEffect(() => {
    createToast(
      <Toast type="success">
        Reference moved to{' '}
        <Link
          className={styles.link}
          to={`/reviews/${reviewId}/duplicates/manual_duplicates`}
        >
          manually marked duplicates
        </Link>
        <ToastButton onClick={onUndo}>Undo</ToastButton>
      </Toast>
    );
  }, [reviewId, onUndo]);

  return <ToastContainer />;
};

export default DuplicateStudyToast;
