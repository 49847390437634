import { gql, useQuery } from '@apollo/client';

import { MyCochraneReviewsQuery } from 'types/graphql';

const MY_COCHRANE_REVIEWS_QUERY = gql`
  query MyCochraneReviews {
    cochrane {
      reviews {
        pageInfo {
          endCursor
          hasNextPage
        }
        nodes {
          id
          cochraneId
          title
          link {
            covidenceReview {
              id
            }
          }
        }
      }
    }
  }
`;

export const useCochraneReviewsQuery = () => {
  const { data, loading, error } = useQuery<MyCochraneReviewsQuery>(
    MY_COCHRANE_REVIEWS_QUERY
  );
  const collection = data?.cochrane?.reviews?.nodes || [];
  return { collection, loading, error };
};
