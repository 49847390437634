import { BlockRecord } from '../BlocksData';
import { TemplateModel } from '../TemplateModel';
import {
  CheckboxesBlock,
  CheckboxesBlockValue,
} from './Checkboxes/CheckboxesBlock';
import { getSupersedingCheckboxesBlockValue } from './Checkboxes/utils';
import { Factory } from './factory';

function keepRecord(
  id: string,
  value: BlockRecord,
  newTemplate: TemplateModel,
  oldTemplate: TemplateModel
): boolean {
  const newBlock = newTemplate.blocks.find((block) => block.id == id);
  if (!newBlock) {
    return false;
  }

  // Technically, this is impossible, but we need to keep the compiler happy!
  const oldBlock = oldTemplate.blocks.find((block) => block.id == id);
  if (!oldBlock) {
    return true;
  }

  const keepResponse = Factory(newBlock).keepResponse;
  return keepResponse(value, newBlock, oldBlock);
}

const getMigratedCheckboxesRecord = (
  oldCheckboxesRecord: BlockRecord<CheckboxesBlockValue>,
  newCheckboxesBlock: CheckboxesBlock
): BlockRecord<CheckboxesBlockValue> => {
  const newCheckboxesValue = getSupersedingCheckboxesBlockValue({
    precedingValue: oldCheckboxesRecord.value,
    supersedingBlock: newCheckboxesBlock,
  });

  return {
    ...oldCheckboxesRecord,
    value: newCheckboxesValue,
  };
};

/**
 * Converts superseded QA MODEL view model to new one. Previous model is more generic, realised needed a QA specific model. Could possibly remove if we no longer use older model.
 */
export function migrate(
  newTemplate: TemplateModel,
  oldTemplate: TemplateModel,
  records: Record<string, BlockRecord>
): Record<string, BlockRecord> {
  const newRecords: Record<string, BlockRecord> = {};

  /**
   * @remarks
   * refactor this.
   * Instead of a keepRecord boolean, we should have a migrate method that
   * returns a new record from the superseded/old one.
   * This would enable custom, flexible ways of making record values match the
   * new template for more complex values e.g. checkboxes.
   */
  Object.keys(records).forEach((id) => {
    const oldBlock = oldTemplate.blocks.find((block) => block.id == id);

    if (oldBlock?.type === 'Checkboxes') {
      const newCheckboxesBlock = newTemplate.blocks.find(
        (block) => block.id == id
      ) as CheckboxesBlock | undefined;

      /**
       * newCheckboxesBlock is undefined if new DE template deleted the
       * checkboxes block
       */
      if (!newCheckboxesBlock) return;

      const newCheckboxesRecord = getMigratedCheckboxesRecord(
        records[id] as BlockRecord<CheckboxesBlockValue>,
        newCheckboxesBlock
      );

      newRecords[id] = newCheckboxesRecord;
      return;
    }

    if (keepRecord(id, records[id], newTemplate, oldTemplate)) {
      newRecords[id] = records[id];
    }
  });

  return newRecords;
}
