import React from 'react';
import styles from './DataExtractionNavigationItems.module.scss';
import { Button } from 'components/core';
import {
  tokenizeRoute,
  DATA_EXTRACTION_TEMPLATE_CREATE_ROUTE,
  QUALITY_ASSESSMENT_TEMPLATE_CREATE_ROUTE,
} from 'query/routes';
import { getReviewId } from 'query/review';

interface Props {
  hasExtractionTemplate: boolean;
  hasQualityTemplate: boolean;
  reviewerCanEditTemplate: boolean;
}

const DataExtractionNavigationItems = ({
  hasExtractionTemplate,
  hasQualityTemplate,
  reviewerCanEditTemplate,
}: Props) => {
  const reviewId = getReviewId();

  if (reviewerCanEditTemplate) {
    return (
      <div className={styles.DataExtractionNavigationItems}>
        <Button
          type={hasExtractionTemplate ? 'neutral' : 'brand'}
          variant={hasExtractionTemplate ? 'outline' : 'solid'}
          to={tokenizeRoute(DATA_EXTRACTION_TEMPLATE_CREATE_ROUTE, {
            review_id: reviewId,
          })}
        >
          {hasExtractionTemplate
            ? 'Data extraction template'
            : 'Create data extraction template'}
        </Button>
        <Button
          type={hasQualityTemplate ? 'neutral' : 'brand'}
          variant={hasQualityTemplate ? 'outline' : 'solid'}
          to={tokenizeRoute(QUALITY_ASSESSMENT_TEMPLATE_CREATE_ROUTE, {
            review_id: reviewId,
          })}
        >
          {hasQualityTemplate
            ? 'Quality assessment template'
            : 'Create quality assessment template'}
        </Button>
      </div>
    );
  } else {
    return null;
  }
};

export default DataExtractionNavigationItems;
