import { gql, useMutation } from '@apollo/client';
import idx from 'idx';
import React from 'react';
import { ID } from 'util/types';

import EmailAddress from 'components/EmailAddress';
import FormattedDate from 'components/FormattedDate';
import MembershipStatus from 'components/MembershipStatus';
import useCollectionQuery from 'hooks/useCollectionQuery';
import useSortVariables from 'hooks/useSortVariables';
import { PrimaryButton } from 'system/base/Button';
import { PrimaryLink } from 'system/base/Link';
import Icon from 'system/base/Icon';
import DataTable, { Columns } from 'system/data/DataTable';
import SortDropdown from 'system/data/SortDropdown';
import SearchInput from 'system/elements/SearchInput';
import DataWithControls from 'system/layout/DataWithControls';
import {
  AuthorsQuery,
  AuthorsQueryVariables,
  SortDirection,
  UserSort,
} from 'types/graphql';
import { Link } from 'system/base/Link';

const CREATE_EXPORT_MUTATION = gql`
  mutation ExportOrganizationAuthorList(
    $organizationId: ID!
    $filterAuthorsName: String
    $sortDirection: SortDirection!
  ) {
    exportOrganizationAuthorList(
      input: {
        organizationId: $organizationId
        filterAuthorsName: $filterAuthorsName
        sortDirection: $sortDirection
      }
    ) {
      success
    }
  }
`;

const QUERY = gql`
  query Authors(
    $organizationId: ID!
    $after: String
    $filter: OrganizationAuthorsFilter
    $sort: UserSort
    $sortDirection: SortDirection
  ) {
    organization: node(id: $organizationId) {
      id

      ... on Organization {
        authors(
          first: 25
          after: $after
          filter: $filter
          sort: $sort
          sortDirection: $sortDirection
        )
          @connection(
            key: "authors"
            filter: ["filter", "sort", "sortDirection"]
          ) {
          pageInfo {
            hasNextPage
            endCursor
          }

          nodes {
            id
            name
            email: primaryEmail

            membership: organizationMembership(organization: $organizationId) {
              id
              organizationReviews {
                total
              }

              status
              memberSince
            }
          }
        }
      }
    }
  }
`;

const SORT_OPTIONS = [
  {
    value: { sort: UserSort.Name, sortDirection: SortDirection.Asc },
    label: 'Name (A-Z)',
  },
  {
    value: { sort: UserSort.Name, sortDirection: SortDirection.Desc },
    label: 'Name (Z-A)',
  },
];

const InviteNewPerson = ({ invitationsPath }: { invitationsPath: string }) => (
  <PrimaryLink href={invitationsPath} style={{ marginLeft: 8 }}>
    <Icon name="plus-square" /> Invite a new person
  </PrimaryLink>
);

const ExportToCSV = ({
  organizationId,
  filterAuthorsName,
  sortDirection,
  className,
  onSuccess,
}: {
  organizationId: string;
  filterAuthorsName: string;
  sortDirection: string;
  className?: string;
  onSuccess: () => void;
}) => {
  const [exportToCSV] = useMutation(CREATE_EXPORT_MUTATION);

  const createExport = () => {
    exportToCSV({
      variables: { organizationId, filterAuthorsName, sortDirection },
    }).then(onSuccess);
  };

  return (
    <PrimaryButton
      onClick={createExport}
      className={className}
      style={{ marginLeft: 16 }}
    >
      <Icon name="cloud-download" /> Export CSV
    </PrimaryButton>
  );
};

interface Props {
  organizationId: ID;
  invitationsPath: string;
  exportHistoryPath: string;
}

const Authors = ({
  exportHistoryPath,
  invitationsPath,
  organizationId,
}: Props) => {
  const {
    data,
    loading,
    error,
    loadMore,
    variables,
    setVariable,
    setVariables,
  } = useCollectionQuery<AuthorsQuery, AuthorsQueryVariables>({
    query: QUERY,
    variables: {
      organizationId,
      sort: UserSort.Name,
      sortDirection: SortDirection.Asc,
    },
    path: ['organization', 'authors'],
  });
  const [sort, setSort] = useSortVariables(variables, setVariables);

  const collection = idx(data, (_) => _.organization.authors.nodes) || [];

  /* eslint-disable react/display-name */
  const columns: Columns<typeof collection> = [
    {
      heading: 'Name',
      primary: true,
      render: (author) => (
        <Link
          className="pii"
          href={`/organizations/${organizationId}/authors/${author.id}`}
        >
          {author.name}
        </Link>
      ),
    },
    {
      heading: 'Email Address',
      render: ({ email }) => <EmailAddress address={email} />,
    },
    {
      heading: 'Reviews',
      render: ({ membership }) => membership.organizationReviews.total,
    },
    {
      heading: 'Membership Status',
      render: ({ membership }) => (
        <MembershipStatus status={membership.status} />
      ),
    },
    {
      heading: 'Member Since',
      render: ({ membership }) =>
        membership.memberSince ? (
          <FormattedDate date={membership.memberSince} />
        ) : (
          '-'
        ),
    },
  ];
  /* eslint-enable react/display-name */

  const filterAuthorsName = idx(variables, (_) => _.filter.search) || '';

  return (
    <DataWithControls
      filters={
        <>
          <SearchInput
            defaultValue={filterAuthorsName}
            onSearch={(value) => setVariable(['filter', 'search'], value)}
            displaySearchButton={true}
            placeholder="Find an author"
          />
          <SortDropdown sort={sort} setSort={setSort} options={SORT_OPTIONS} />
        </>
      }
      actions={
        <>
          <InviteNewPerson invitationsPath={invitationsPath} />
          <ExportToCSV
            organizationId={organizationId}
            filterAuthorsName={filterAuthorsName}
            onSuccess={() => {
              window.location.href = exportHistoryPath;
            }}
            sortDirection={(sort && sort.sortDirection) || 'asc'}
          />
        </>
      }
    >
      <DataTable
        loading={loading}
        error={error}
        loadMore={loadMore}
        columns={columns}
        collection={collection}
        empty="There are no authors in this organisation"
      />
    </DataWithControls>
  );
};

export default Authors;
