import React from 'react';
import { EditorTextbox } from '../../../FormBuilder/Widgets/EditorTextbox';
import { newHeadingBlock } from '../../Heading/HeadingBlock';
import styles from './TextFieldEditor.module.css';
import {
  TextFieldBlock,
  updateLabel,
} from 'concepts/Extraction/Blocks/TextField/TextFieldBlock';
import { TemplateBlock } from 'concepts/Extraction/Blocks/BaseBlock';
import { newMultipleChoiceBlock } from 'concepts/Extraction/Blocks/MultipleChoice/MultipleChoiceBlock';
import { useFocusManager } from 'contexts/FocusManager/FocusManager';

export interface TextFieldEditorProps {
  block: TextFieldBlock;
  placeholder?: string;
  onChange: (updatedTextFieldBlock: TemplateBlock) => void;
  onComplete: () => void;
  onRemove: () => void;
}

export default function TextFieldEditor({
  block,
  onChange,
  onRemove,
  onComplete,
  placeholder,
}: TextFieldEditorProps): JSX.Element {
  const requestFocus = useFocusManager();

  return (
    <div className={styles.container}>
      <EditorTextbox
        value={block.label}
        placeholder={placeholder || 'Label'}
        onChange={(event) => {
          const newLabel = event.target.value;
          onChange(updateLabel(block, newLabel));
        }}
        onKeyDown={(event) => {
          switch (event.key) {
            case '-':
              if (block.label == '') {
                event.preventDefault();
                onChange({ ...newMultipleChoiceBlock(''), id: block.id });
                requestFocus(
                  `[data-block-id="${block.id}"] [data-label-input=""]`
                );
              }
              break;
            case '*':
              if (block.label == '') {
                event.preventDefault();
                onChange({ ...newMultipleChoiceBlock(''), id: block.id });
                requestFocus(
                  `[data-block-id="${block.id}"] [data-label-input=""]`
                );
              }
              break;
            case '#':
              if (block.label == '') {
                event.preventDefault();
                onChange({ ...newHeadingBlock(''), id: block.id });
                requestFocus(`[data-block-id="${block.id}"] input`);
              }
              break;
            case 'Backspace':
              if (block.label == '') {
                event.preventDefault();
                onRemove();
              }
              break;
            case 'Enter':
              event.preventDefault();
              onComplete();
          }
        }}
      />
    </div>
  );
}
