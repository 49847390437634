import { Category as SchemaCategory } from '../types/graphql';

export { MembershipStatus } from '../types/graphql';

export const CATEGORY_NAMES = {
  [SchemaCategory.In]: 'Included',
  [SchemaCategory.Ft]: 'Full text review',
  [SchemaCategory.Ta]: 'Title and abstract screening',
  [SchemaCategory.Ex]: 'Excluded',
  [SchemaCategory.Ir]: 'Irrelevant',
  [SchemaCategory.Dp]: 'Manually marked duplicates',
  [SchemaCategory.Mg]: 'Merged',
  [SchemaCategory.Ae]: 'Auto excluded',
};

export enum DUPLICATE_IMPORT_TYPES {
  IMPORT = 'Import',
  REVIEW = 'Review',
}
