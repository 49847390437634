import {
  color,
  ColorProps,
  textStyle,
  TextStyleProps,
  typography,
  TypographyProps,
  compose,
} from 'styled-system';
import styled from '../styled';

type StyleProps = ColorProps & TypographyProps & TextStyleProps;

const styleProps = compose(color, typography);
const Text = styled('div')<StyleProps>(textStyle, styleProps);

export default Text;

export const InlineText = styled('span')<StyleProps>(textStyle, styleProps);
