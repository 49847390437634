import React from 'react';
import { EditorTextbox } from '../../../FormBuilder/Widgets/EditorTextbox';
import styles from './DomainEditor.module.css';
import {
  DomainBlock,
  updateLabel,
  addJudgement,
  updateJudgement,
  removeJudgement,
} from 'concepts/Extraction/Blocks/Domain/DomainBlock';
import * as Judgements from 'concepts/Extraction/Blocks/Domain/JudgementBlock';
import {
  FocusManagerProvider,
  useFocusManager,
} from 'contexts/FocusManager/FocusManager';

export interface JudgementEditorProps {
  judgement: Judgements.JudgementBlock;
  onChange: (updatedBlock: Judgements.JudgementBlock) => void;
  onComplete: () => void;
  onRemove: () => void;
}

export interface DomainEditorProps {
  block: DomainBlock;
  onChange: (updatedBlock: DomainBlock) => void;
  onComplete: () => void;
  onRemove: () => void;
  onFocus?: () => void;
}

function JudgementEditor({
  judgement,
  onChange,
  onRemove,
  onComplete,
}: JudgementEditorProps): JSX.Element {
  return (
    <li data-judgement-id={judgement.id} className={styles.judgementItem}>
      <span className={styles.judgementItemDash}>&ndash;</span>
      <EditorTextbox
        value={judgement.label}
        placeholder="Enter a judgement"
        onKeyDown={(event) => {
          switch (event.key) {
            case 'Backspace':
              if (judgement.label == '') {
                event.preventDefault();
                onRemove();
              }
              break;
            case 'Enter':
              event.preventDefault();
              onComplete();
              break;
          }
        }}
        onChange={(event) => {
          const newLabel = event.target.value;
          onChange(Judgements.updateLabel(judgement, newLabel));
        }}
      />
    </li>
  );
}

function DomainEditorInner({
  block,
  onChange,
  onRemove,
  onComplete,
}: DomainEditorProps): JSX.Element {
  const requestFocus = useFocusManager();
  function requestFocusOnJudgement(judgement: Judgements.JudgementBlock) {
    requestFocus(
      `[data-judgement-id="${judgement.id}"] textarea, [data-judgement-id="${judgement.id}"] input`
    );
  }

  return (
    <div className={styles.container}>
      <EditorTextbox
        value={block.label}
        placeholder="Domain"
        data-label-input=""
        onChange={(event) => {
          const newLabel = event.target.value;
          onChange(updateLabel(block, newLabel));
        }}
        onKeyDown={(event) => {
          switch (event.key) {
            case 'Backspace':
              if (block.label == '') {
                event.preventDefault();
                onRemove();
                // Rely on outside focus provider
              }
              break;
          }
        }}
        onKeyUp={(event) => {
          switch (event.key) {
            case 'Enter':
              {
                event.preventDefault();
                const newJudgement = Judgements.newJudgementBlock('');
                const newBlock = addJudgement(block, newJudgement, null);
                onChange(newBlock);
                requestFocusOnJudgement(newJudgement);
              }
              break;
          }
        }}
      />
      <ol className={styles.judgementList}>
        {block.judgements.map((judgement, judgementIndex) => (
          <JudgementEditor
            key={judgement.id}
            judgement={judgement}
            onChange={(newJudgement) => {
              onChange(updateJudgement(block, newJudgement));
            }}
            onComplete={() => {
              if (
                judgementIndex == block.judgements.length - 1 &&
                judgement.label === ''
              ) {
                onChange(removeJudgement(block, judgement.id));
                onComplete();
              } else {
                const newJudgement = Judgements.newJudgementBlock('');
                const newBlock = addJudgement(
                  block,
                  newJudgement,
                  judgement.id
                );
                onChange(newBlock);
                requestFocusOnJudgement(newJudgement);
              }
            }}
            onRemove={() => {
              onChange(removeJudgement(block, judgement.id));
              if (judgementIndex !== 0) {
                requestFocusOnJudgement(block.judgements[judgementIndex - 1]);
              } else {
                requestFocus('[data-label-input]');
              }
            }}
          />
        ))}
        {/* Always show a judgement to type into */}
        {block.judgements.length === 0 && (
          <JudgementEditor
            key="default"
            judgement={{ type: 'Judgement', id: 'default', label: '' }}
            onChange={({ label }) => {
              const newJudgement = Judgements.newJudgementBlock(label);
              const newBlock = addJudgement(block, newJudgement, null);
              onChange(newBlock);
              requestFocusOnJudgement(newJudgement);
            }}
            onComplete={() => {
              const newJudgement = Judgements.newJudgementBlock('');
              const newBlock = addJudgement(block, newJudgement, null);
              onChange(newBlock);
            }}
            onRemove={() => {
              requestFocus('[data-label-input]', { when: 'now' });
            }}
          />
        )}
      </ol>
    </div>
  );
}

export default function DomainEditor(props: DomainEditorProps): JSX.Element {
  return (
    <FocusManagerProvider>
      <DomainEditorInner {...props} />
    </FocusManagerProvider>
  );
}
