import React from 'react';
import styles from './SubheadingComparisonRow.module.css';

export interface SubheadingComparisonRowProps {
  label: string;
  numberReviewers: number;
}

export const SubheadingComparisonRow = ({
  label,
  numberReviewers,
}: SubheadingComparisonRowProps): JSX.Element => (
  <tr>
    <th
      colSpan={numberReviewers + 2}
      role="columnheader"
      className={styles.cell}
    >
      {label}
    </th>
  </tr>
);
