/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import TextareaAutosize, {
  TextareaAutosizeProps,
} from 'react-textarea-autosize';
import styles from './BlockWidgets.module.css';
import { useDebouncedFunction } from 'hooks/useDebouncedFunction';

export const Label = (props: React.ComponentProps<'label'>): JSX.Element => (
  <label className={styles.Label} {...props} />
);

export const LabelText = (props: React.ComponentProps<'div'>): JSX.Element => (
  <div className={styles.LabelText} {...props} />
);

export const TextInput = (
  props: Omit<TextareaAutosizeProps, 'ref'>
): JSX.Element => <TextareaAutosize className={styles.Input} {...props} />;

export const DebouncedTextInput = ({
  onChange,
  value,
  ...otherProps
}: Omit<TextareaAutosizeProps, 'ref'> &
  Required<Pick<TextareaAutosizeProps, 'onChange'>>): JSX.Element => {
  const debounced = useDebouncedFunction(onChange, 300);

  const [currentValue, setCurrentValue] = useState(value);

  // Use effect here is used so that prop updates from the tree
  // will 'supersede' the state of the component
  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  return (
    <TextInput
      value={currentValue || ''}
      onChange={(e) => {
        debounced(e);
        setCurrentValue(e.target.value);
      }}
      {...otherProps}
    />
  );
};
