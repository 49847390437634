import React, { useState } from 'react';
import {
  useFormContext,
  Controller,
  useFieldArray,
  UseFieldArrayReturn,
} from 'react-hook-form';
import { DataExtractionTemplateState } from '../../DataExtractionTemplate';
import styles from '../../DataExtractionTemplate.module.scss';
import EditableField from '../EditableField';
import ConfirmationDialog from '../ConfirmationDialog';
import {
  validate,
  NameError,
  NameErrorMessages,
  NO_EQUIVALENT_VALUE,
} from '../../../components/editableFieldValidation';
import outcomeStyles from './OutcomesPanel.module.scss';
import {
  Input,
  Select,
  FormControl,
  FormContainer,
  FormLabel,
  AccordionContent,
  AccordionGroup,
  AccordionHeader,
  Button,
  Icon,
  IconList,
  Link,
} from 'components/core';
import { OutcomeTypes } from 'types/DataExtraction';
import { useAccordionController } from 'components/core';
import { ReorderItem, ReorderItems } from 'components/core/Reorder';

const MAX_OUTCOME_NAME_LENGTH = 1000;

interface Props {
  index: number;
  onDelete: () => void;
  resultsDataAnchor?: string;
  hasExtractedData?: boolean;
}

const OutcomeEditor = ({
  index,
  onDelete,
  resultsDataAnchor = '',
  hasExtractedData = false,
}: Props) => {
  const [modal, setModal] = useState<React.ReactElement | null>();
  const name = `outcomes.outcomes.${index}`;
  const { register, watch, getValues, trigger } = useFormContext();

  const isBlank = (value: string): boolean => {
    return value.trim() == '';
  };
  const nameIsBlank = isBlank(getValues(`${name}.name`));
  const MAX_LENGTH = 255;

  const timepoints = useFieldArray<DataExtractionTemplateState>({
    name: `outcomes.outcomes.${index}.timepoints` as 'outcomes.outcomes.0.timepoints',
  }) as UseFieldArrayReturn<
    DataExtractionTemplateState,
    `outcomes.outcomes.${number}.timepoints`
  >;

  const accordionEventHandler = useAccordionController();
  const lockAccordionIfFormInvalid = async () => {
    const outcome = getValues(name);
    if (!outcome) {
      // New outcome has been deleted
      accordionEventHandler.unlock();
      return;
    }

    const timepointValues = timepoints.fields.map((_, index) =>
      getValues(`${name}.timepoints.${index}`)
    );
    if (timepointValues.find((timepointValue) => !timepointValue)) {
      // timepoint value is blank
      accordionEventHandler.lock();
      return;
    }

    const valid = await trigger('outcomes');
    valid ? accordionEventHandler.unlock() : accordionEventHandler.lock();
  };

  return (
    <div className={outcomeStyles.outcomeEditor}>
      {modal}
      <AccordionGroup
        initiallyOpen={nameIsBlank}
        className={outcomeStyles.outcomeGroup}
      >
        <AccordionHeader className={outcomeStyles.outcomeHeader}>
          {watch(`${name}.name`)}
        </AccordionHeader>
        <AccordionContent>
          <FormContainer>
            <Controller
              name={`${name}.name` as const}
              rules={{
                required: NameError.REQUIRED,
                onBlur: lockAccordionIfFormInvalid,
                validate: (value, formState) => {
                  const outcomes = formState.outcomes
                    .outcomes as DataExtractionTemplateState['outcomes']['outcomes'];
                  const outcomeNames = outcomes.map((o) => {
                    return { name: o.name };
                  });
                  return validate(
                    value,
                    outcomeNames,
                    [],
                    NO_EQUIVALENT_VALUE,
                    MAX_OUTCOME_NAME_LENGTH,
                    NameErrorMessages
                  );
                },
              }}
              render={({ field, fieldState }) => (
                <FormControl error={fieldState.error?.message}>
                  <FormLabel>Name</FormLabel>
                  <Input
                    {...field}
                    data-testid={`${name}.name`}
                    // eslint-disable-next-line jsx-a11y/no-autofocus
                    autoFocus={nameIsBlank}
                  />
                </FormControl>
              )}
            />

            <FormControl>
              <FormLabel>Type</FormLabel>
              <Select
                {...register(`${name}.outcome_type`)}
                data-testid={`${name}.type`}
                disabled={hasExtractedData}
              >
                <option value={OutcomeTypes.CONTINUOUS}>Continuous</option>
                <option value={OutcomeTypes.DICHOTOMOUS}>Dichotomous</option>
              </Select>
              {hasExtractedData && (
                <p className={outcomeStyles.outcomeTypeExplanationMessage}>
                  Type cannot be changed as data has already been extracted
                </p>
              )}
              <Link to={`#${resultsDataAnchor}`} variant="primary">
                View list of data formats
              </Link>
            </FormControl>

            <FormControl>
              <FormLabel>Timepoints</FormLabel>
              <ReorderItems
                items={timepoints.fields}
                onMove={timepoints.move}
                orderKey="id"
              >
                {timepoints.fields.map((field, index) => {
                  return (
                    <ReorderItem key={`${field.name}.${index}`} id={field.id}>
                      <EditableField
                        index={index}
                        name={`${name}.timepoints` as const}
                        onBlur={lockAccordionIfFormInvalid}
                        onDelete={() => {
                          const { id } = getValues(
                            `${name}.timepoints.${index}`
                          );

                          if (id && hasExtractedData) {
                            setModal(
                              <ConfirmationDialog
                                onConfirm={() => timepoints.remove(index)}
                                onClose={() => setModal(null)}
                              />
                            );
                          } else {
                            timepoints.remove(index);
                          }
                        }}
                        canDelete={timepoints.fields.length > 1}
                        validate={(value, existingFields) => {
                          return validate(
                            value,
                            existingFields,
                            [],
                            NO_EQUIVALENT_VALUE,
                            MAX_LENGTH
                          );
                        }}
                      />
                    </ReorderItem>
                  );
                })}
              </ReorderItems>
              <Link
                onClick={() => {
                  timepoints.append({
                    name: '',
                  });
                }}
                className={styles.AddButton}
                variant="primary"
              >
                <Icon icon={IconList.light.faPlus} /> Add timepoint
              </Link>
            </FormControl>
          </FormContainer>
        </AccordionContent>
      </AccordionGroup>
      <div className={outcomeStyles.buttonWrapper}>
        <Button
          variant="ghost"
          className={outcomeStyles.deleteOutcomeButton}
          iconOnly
          onClick={() => {
            onDelete();
            lockAccordionIfFormInvalid();
          }}
          data-testid={`${name}.delete`}
        >
          <Icon icon={IconList.light.faTrashCan}>Delete</Icon>
        </Button>
      </div>
    </div>
  );
};

export default OutcomeEditor;
