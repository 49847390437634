import { UnresolvedTableCellIDs } from './types';
import { Resolutions } from 'containers/Extraction/types';

export const getUnresolvedCellCountForTable = (
  unresolvedCellIDs: UnresolvedTableCellIDs,
  initialCount = 0
) =>
  unresolvedCellIDs.reduce(
    (countAtPriorRow, { columnIDs }) => countAtPriorRow + columnIDs.size,
    initialCount
  );

export const getUnresolvedCellCountForTables = (
  unresolvedTableCells: Resolutions['unresolvedTableCells']
) => {
  const unresolvedTableCellValues = Object.values(unresolvedTableCells);

  return unresolvedTableCellValues.reduce(
    (countAtPriorTable, currentTable) =>
      getUnresolvedCellCountForTable(currentTable, countAtPriorTable),
    0
  );
};
