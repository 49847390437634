import shouldForwardProp from '@styled-system/should-forward-prop';
import {
  colorStyle,
  ColorStyleProps,
  compose,
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system';
import styled from '../styled';

type Props = ColorStyleProps &
  FlexboxProps &
  LayoutProps &
  SpaceProps &
  TypographyProps;

const styleProps = compose(layout, space, typography, colorStyle, flexbox);

const Label = styled('label', { shouldForwardProp })<Props>(
  {
    // knock out global style applying margin-bottom on labels
    marginBottom: 0,
  },
  styleProps
);

Label.defaultProps = {};

export default Label;
