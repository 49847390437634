import React from 'react';
import { Accessor, Column } from 'react-table';
import { Header, RowData } from '../types';
import { Cell } from '../Cell/Cell';
import { getCellDataFromRow } from './getTableCellData';

const getReactTableColumn = (column: Header): Column<RowData> => {
  const accessor: Accessor<RowData> = (row) => {
    const cellData = getCellDataFromRow(row)(column.id);
    return cellData?.value;
  };

  return {
    Header: <Cell value={column.label} />,
    accessor,
    id: column.id,
  };
};

// getReactTableColumns produces react-table column object array that renders
// column labels.
export const getReactTableColumns = (
  columnHeaders: Header[]
): Column<RowData>[] => columnHeaders.map(getReactTableColumn);
