import React from 'react';
import { Column } from 'react-table';
import {
  Table2HeaderID,
  Table2Headers,
  Table2Structure,
} from '../../types/block';
import { Table2BlockValue } from '../../types/block-value';
import { Cell } from './Cell/Cell';
import { RowData, TableData } from './types';

/**
 * Transform columns to be compatible with react-table
 */
const transformColumns = (headers: Table2Headers) => (
  columns: Table2HeaderID[]
): Column<RowData>[] =>
  columns.map((headerID) => ({
    Header: <Cell value={headers[headerID].label} />,
    accessor: headerID,
  }));

export const rowLabelColumn: Column<RowData> = {
  Header: '',
  accessor: ({ rowLabel }) => rowLabel,
  id: 'rowLabel',
  Cell,
};

type TransformColumnsWithRowLabelsProps = {
  headers: Table2Headers;
  columns: Table2HeaderID[];
};
export const transformColumnsWithRowLabels = ({
  headers,
  columns,
}: TransformColumnsWithRowLabelsProps): Column<RowData>[] => [
  rowLabelColumn,
  ...transformColumns(headers)(columns),
];

const getCellID = (rowID: Table2HeaderID, columnID: Table2HeaderID) =>
  `${rowID}:${columnID}`;

interface GetRowDataProps {
  columnIDs: Table2HeaderID[];
  value?: Table2BlockValue;
}
const getRowData = ({ columnIDs, value }: GetRowDataProps) => (
  rowID: Table2HeaderID
): Omit<RowData, 'rowLabel'> =>
  columnIDs.reduce(
    (rowData: Omit<RowData, 'rowLabel'>, columnID: Table2HeaderID) => {
      const cellID = getCellID(rowID, columnID);
      rowData[columnID] = value?.[cellID]?.value ?? null;
      return rowData;
    },
    {}
  );

type TransformTableDataProps = {
  headers: Table2Headers;
  rowIDs: Table2Structure['rows'];
  columnIDs: Table2Structure['cols'];
  value?: Table2BlockValue;
};
export const transformTableData = ({
  headers,
  rowIDs,
  columnIDs,
  value,
}: TransformTableDataProps): TableData => {
  const getRowDataByRowID = getRowData({
    columnIDs,
    value,
  });

  const tableData = rowIDs.map((rowID) => ({
    rowLabel: headers[rowID].label,
    ...getRowDataByRowID(rowID),
  }));

  return tableData;
};
