import React, {
  useCallback,
  useMemo,
  useState,
  useRef,
  useEffect,
} from 'react';
import { update } from 'ramda';
import { Input } from 'theme-ui';
import { getHeaderTypePlural, getNewHeader } from '../../utils';
import { TableHeaderLabel, TableHeaderType, TableHeader } from '../../types';
import { TableEditorSettingsProps } from '../TableEditorSettings';
import { updateHeaders } from '../../TableBlock';
import { DynamicFields } from 'system/elements/DynamicFields/DynamicFields';
import { useDebouncedFunction } from 'hooks/useDebouncedFunction';

interface TableHeaderFieldsProps extends TableEditorSettingsProps {
  headerType: TableHeaderType;
  formDescription: string;
}

const getFieldLabel = (headerType: TableHeaderType, index: number) =>
  `${headerType} ${index + 1}`;

export const TableHeaderFields = ({
  headerType,
  formDescription,
  block,
  onChange,
}: TableHeaderFieldsProps) => {
  const headersKey = useMemo(() => getHeaderTypePlural(headerType), [
    headerType,
  ]);

  // We use an internal state instead of props so that when users type fields
  // quickly, we can debounce the onChange callback whilst having the input field
  // value change instantaneously.
  const [internalStateHeaders, setInternalStateHeaders] = useState(
    block[headersKey]
  );

  useEffect(() => {
    setInternalStateHeaders(block[headersKey]);
  }, [block, headersKey]);

  // To focus on the the new field when user clicks 'add' button.
  const [doesNewFieldNeedFocus, setDoesNewFieldNeedFocus] = useState(false);

  const lastInputEl = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (doesNewFieldNeedFocus) {
      if (lastInputEl.current) {
        lastInputEl.current.focus();
      }
      setDoesNewFieldNeedFocus(false);
    }
  }, [doesNewFieldNeedFocus, internalStateHeaders]);

  const handleOnChange = useCallback(
    (newHeaders: TableHeader[]) => {
      onChange(updateHeaders(block, headersKey, newHeaders));
    },
    [block, headersKey, onChange]
  );

  const debouncedOnChange = useDebouncedFunction(handleOnChange, 500);

  const handleAddField = (headerLabel: TableHeaderLabel = '') => {
    const newHeaders = [...internalStateHeaders, getNewHeader(headerLabel)];
    setInternalStateHeaders(newHeaders);
    setDoesNewFieldNeedFocus(true);
    handleOnChange(newHeaders);
  };

  const handleRemoveField = (headerID: string) => {
    const newHeaders: TableHeader[] = internalStateHeaders.filter(
      ({ id }) => id !== headerID
    );
    setInternalStateHeaders(newHeaders);
    handleOnChange(newHeaders);
  };

  const handleChangeField = (
    headerID: string,
    newHeaderLabel: TableHeaderLabel
  ) => {
    const headerIndex = internalStateHeaders.findIndex(
      ({ id }) => id === headerID
    );
    const newHeader = {
      ...internalStateHeaders[headerIndex],
      label: newHeaderLabel,
    };
    const newHeaders = update(headerIndex, newHeader, internalStateHeaders);
    setInternalStateHeaders(newHeaders);
    // Debounce the changes so onChange isn't called with every keystroke.
    debouncedOnChange(newHeaders);
  };

  const dynamicFields = internalStateHeaders.map(
    ({ id, label }: TableHeader, index) => {
      const fieldLabel = getFieldLabel(headerType, index);
      const isLastInput = internalStateHeaders.length === index + 1;

      return {
        id,
        label: fieldLabel,
        field: (
          <Input
            id={id}
            aria-label={fieldLabel}
            value={label}
            onChange={(e) => handleChangeField(id, e.target.value)}
            ref={isLastInput ? lastInputEl : undefined}
          />
        ),
      };
    }
  );

  return (
    <DynamicFields
      label={formDescription}
      addFieldButtonText={`Add ${headerType}`}
      onAddField={() => handleAddField()}
      onRemoveField={(id) => () => {
        handleRemoveField(id);
      }}
      fields={dynamicFields}
    />
  );
};
