import React from 'react';
import styles from './List.module.scss';

export interface ListProps {
  component?: 'ol' | 'ul';
  children: React.ReactNode;
  className?: string;
}

export const List = ({ component = 'ul', children, className }: ListProps) => {
  const classes = [styles.list];

  if (className) classes.push(className);

  return React.createElement(
    component,
    {
      className: classes.join(' '),
    },
    children
  );
};
