import React from 'react';
import { Box } from '@theme-ui/components';
import { AddFieldButton } from './AddFieldButton/AddFieldButton';
import { RemoveFieldButton } from './RemoveFieldButton/RemoveFieldButton';
import styles from './DynamicFields.module.css';
import { LabelText } from 'concepts/Extraction/Blocks/shared/BlockWidgets';
import { useLabelledBy } from 'hooks/useAccessibleName';

interface DynamicFieldsProps {
  label: string;
  addFieldButtonText: string;
  onAddField: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onRemoveField: (
    id: string
  ) => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  fields: { id: string; label: string; field: React.ReactElement }[];
}

export const DynamicFields = ({
  label,
  addFieldButtonText,
  onAddField,
  onRemoveField,
  fields,
}: DynamicFieldsProps) => {
  const [labelID, labelledBy] = useLabelledBy();
  const maxFields = 100;

  const tableHeaderFields = (
    <div>
      {fields.map(({ id, label, field }) => (
        <div className={styles.headerFieldWrapper} key={id}>
          {field}
          <RemoveFieldButton
            className={styles.headerFieldRemoveButton}
            disabled={fields.length === 1}
            aria-label={`delete ${label}`}
            onClick={onRemoveField(id)}
          />
        </div>
      ))}
    </div>
  );

  return (
    <div>
      <div role="group" {...labelledBy}>
        <LabelText id={labelID}>{label}</LabelText>
        {tableHeaderFields}
      </div>
      {fields.length < maxFields ? (
        <AddFieldButton onClick={onAddField}>
          {addFieldButtonText}
        </AddFieldButton>
      ) : (
        <Box as="small" color="secondaryDark" sx={{ fontWeight: 'bold' }}>
          Maximum of {maxFields} fields reached
        </Box>
      )}
    </div>
  );
};
