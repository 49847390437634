import React from 'react';
import styles from './Form.module.scss';

type Props = React.FormHTMLAttributes<HTMLFormElement>;

const Form = ({
  className,
  children,
  action,
  onSubmit = () => {
    /**/
  },
  ...props
}: Props) => {
  const classes = [styles.Form];
  if (className) classes.push(className);

  // If the form doesn't have an action, don't do a normal form submission
  const formSubmit = action
    ? onSubmit
    : (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        onSubmit(e);
      };

  return (
    <form
      action={action}
      onSubmit={formSubmit}
      className={classes.join(' ')}
      {...props}
    >
      {children}
    </form>
  );
};

export default Form;
