import React, { Fragment } from 'react';
import StudyListStyles from 'concepts/Extraction/StudyList/StudyList.module.css';
import { GuidelineStudy } from 'concepts/Guidelines/GuidelineStudy/GuidelineStudy';
import { StudyModel } from 'containers/Guidelines/Triage/StudyModel';
import { NoWorkToDo } from 'concepts/Guidelines/NoWorkToDo/NoWorkToDo';
import { DocumentLinks } from 'concepts/Documents/DocumentLinks';
import { Document } from 'types/graphql';

export interface TopicExpertStudyModel extends StudyModel {
  documents: Document[];
}
export interface ViewStudiesProps {
  studies: TopicExpertStudyModel[];
  reviewTitle: string;
}

function singleStudy(study: TopicExpertStudyModel) {
  return (
    <div
      data-testid={`study-${study.id}`}
      key={study.id}
      className={StudyListStyles.item}
    >
      <GuidelineStudy study={study} showAbstract={false} />
      {study.documents && <DocumentLinks documents={study.documents} />}
    </div>
  );
}

export function ViewStudies({
  studies,
  reviewTitle,
}: ViewStudiesProps): JSX.Element {
  return (
    <Fragment>
      <span data-testid="review-title">
        <h2>Included studies for: {reviewTitle}</h2>
      </span>
      {studies.length > 0 ? (
        <div className={StudyListStyles.container}>
          {studies.map(singleStudy)}
        </div>
      ) : (
        <NoWorkToDo message="No studies have been included yet" />
      )}
    </Fragment>
  );
}
