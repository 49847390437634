import { Outcome, Timepoint } from 'types';

export function sortOutcomes() {
  return (a: Outcome, b: Outcome) =>
    parseInt(a.template_field_id || '0') - parseInt(b.template_field_id || '0');
}

export function sortTimepoints(outcome: Outcome) {
  return (a: Timepoint, b: Timepoint) => {
    const aPosition = outcome.template_timepoints.find(
      (timepoint) => timepoint.id == a.template_field_id
    )?.position;
    const bPosition = outcome.template_timepoints.find(
      (timepoint) => timepoint.id == b.template_field_id
    )?.position;

    if (!!aPosition && !!bPosition) {
      return aPosition - bPosition;
    }

    if (!aPosition && !!bPosition) {
      return 1;
    }

    if (!!aPosition && !bPosition) {
      return -1;
    }

    return +a.id - +b.id;
  };
}
