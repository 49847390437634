import React from 'react';
import { StudyModel } from './StudyModel';
import {
  QuestionAssignmentOptions,
  QuestionsAssignmentActions,
} from './QuestionAssignmentOptions';
import {
  Pagination,
  PaginationMeta,
} from 'concepts/Guidelines/Pagination/Pagination';
import { Filters } from 'containers/Guidelines/Triage/FiltersModel';
import styles from 'containers/Guidelines/Triage/Triage.module.css';
import guidelineStyles from 'containers/Guidelines/Guidelines.module.css';
import { TopicFilter } from 'concepts/Guidelines/Triage/TopicFilter/TopicFilter';
import { ImportedAssignments } from 'concepts/Guidelines/Triage/ImportedAssignments/ImportedAssignments';
import { NoWorkToDo } from 'concepts/Guidelines/NoWorkToDo/NoWorkToDo';

export interface ViewImportedAssignmentsProps {
  filters: Filters;
  studies: StudyModel[];
  questionAssignments: QuestionAssignmentModel[];
  paginationMeta: PaginationMeta;
  questionOptions: QuestionAssignmentOptions;
  actions: QuestionsAssignmentActions;
}

export interface QuestionAssignmentModel {
  id: number;
  studyId: number;
  reviewTitle: string;
  reviewUrl: string;
  studyCategoryName: string;
  createdAgo: string;
}

export function ViewImportedAssignments({
  studies,
  questionAssignments,
  filters,
  paginationMeta,
  questionOptions,
  actions,
}: ViewImportedAssignmentsProps): JSX.Element {
  const questionAssignmentsForStudy = (study: StudyModel) =>
    questionAssignments.filter((qa) => qa.studyId === study.id);

  return (
    <div>
      <nav className={styles.listNavWithFilter}>
        <TopicFilter filters={filters} />
      </nav>
      {studies.length > 0 ? (
        <>
          <article className={guidelineStyles.withShadow}>
            {studies.map((study) => (
              <ImportedAssignments
                key={study.id}
                study={study}
                questionAssignments={questionAssignmentsForStudy(study)}
                questionOptions={questionOptions}
                actions={actions}
              />
            ))}
          </article>
          <Pagination meta={paginationMeta} />
        </>
      ) : (
        <NoWorkToDo message="There is nothing to see here right now!" />
      )}
    </div>
  );
}
