import { useEffect, useState } from 'react';

export default function useWindowSize() {
  function getSize() {
    return {
      width: typeof window === 'object' ? window.innerWidth : undefined,
      height: typeof window === 'object' ? window.innerHeight : undefined,
    };
  }

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    if (typeof window !== 'object') {
      return;
    }

    function handleResize() {
      setWindowSize(getSize());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
}
