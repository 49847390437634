/** @jsxImportSource theme-ui */
import React from 'react';
import { IconName } from '@fortawesome/fontawesome-common-types';
import { space, SpaceProps } from 'styled-system';
import shouldForwardProp from '@styled-system/should-forward-prop';
import { ThemeUIStyleObject } from 'theme-ui';
import styled from 'system/styled';
type IProps = JSX.IntrinsicElements['i'];

interface Props extends IProps, SpaceProps {
  iconStyle?: IconStyle;
  name: IconName;
  className?: string;
  'aria-label'?: string;
  sx?: ThemeUIStyleObject;
}

export enum IconStyle {
  Solid = 'fas',
  Regular = 'far',
  Light = 'fal',
  Brand = 'fab',
}

const IconElement = styled('i', { shouldForwardProp })(space);

const Icon = ({
  iconStyle = IconStyle.Solid,
  name,
  className = '',
  'aria-label': ariaLabel,
  sx,
  ...props
}: Props) => (
  <IconElement
    aria-label={ariaLabel}
    aria-hidden={!ariaLabel}
    className={`${iconStyle} fa-fw fa-${name} ${className}`}
    sx={sx}
    {...props}
  />
);

const wrap = (
  iconOrName: Props['name'] | React.ReactElement<Props>
): React.ReactElement<Props> =>
  typeof iconOrName === 'string' ? <Icon name={iconOrName} /> : iconOrName;

export default Icon;
export type { IconName };
export { wrap };
