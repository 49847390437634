import { UnresolvedTableCellIDs } from 'concepts/Extraction/ViewModel/tables/types';

/**
 * Create unresolvedTableCellIDs for tables where all cells are unresolved.
 *
 * @remarks
 *
 * Used to generate the initial table resolution state for tables where we
 * know is fully unresolved (i.e. when at least one reviewer has not entered
 * any table data.)
 */
export const getFullyUnresolvedTable = (
  rowIDs: string[],
  columnIDs: string[]
): UnresolvedTableCellIDs =>
  rowIDs.map((rowID) => ({
    rowID,
    columnIDs: new Set(columnIDs),
  }));
