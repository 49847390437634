import React, { useState } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import idx from 'idx';
import pluralize from 'pluralize';
import Mentions from '../../concepts/Comments/Mentions';
import Reply from '../../concepts/Comments/Reply';
import CommentForm, {
  CommentFormSubmitProps,
} from '../../concepts/Comments/CommentForm';
import styles from './Comments.module.css';
import { ID } from 'util/types';
import {
  GetCommentQuery,
  EditCommentMutation,
  AddReplyToCommentMutation,
  ResolveDiscussionMutation,
} from 'types/graphql';
import Spinner from 'system/elements/Spinner';
import ErrorMessage from 'components/ErrorMessage';
import { PrimaryButton, TertiaryButton } from 'system/base/Button';
import Attribution from 'concepts/Comments/Attribution';
import RecentActivity from 'concepts/Comments/RecentActivity';
import StudiesRollup from 'concepts/Studies/StudiesRollup';

export const GET_COMMENT_QUERY = gql`
  query getComment($id: ID!) {
    comment: node(id: $id) {
      ... on Comment {
        id
        content
        mentions {
          name
          replied
        }
        createdAgo
        activeAgo
        activeBy {
          name
        }
        reviewer {
          name
        }
        reviewStudies {
          id
          title
          authors
          studyId
          covidenceNumber
          references {
            nodes {
              abstract
              journalInfo
              publisherInfo
              documents {
                id
                url
                fileName
              }
            }
          }
        }
        actions {
          canResolve
          canEdit
        }
        editedAgo
        resolvedAgo
        replies {
          id
          content
          createdAgo
          editedAgo
          reviewer {
            name
          }
          actions {
            canEdit
          }
        }
      }
    }
  }
`;

export const ADD_REPLY_TO_COMMENT_MUTATION = gql`
  mutation AddReplyToComment($input: AddReplyToCommentInput!) {
    addReplyToComment(input: $input) {
      success
      errors {
        message
      }
    }
  }
`;

export const EDIT_COMMENT_MUTATION = gql`
  mutation EditComment($input: EditCommentInput!) {
    editComment(input: $input) {
      success
      errors {
        message
      }
    }
  }
`;

export const RESOLVE_DISCUSSION_MUTATION = gql`
  mutation ResolveDiscussion($input: ResolveDiscussionInput!) {
    resolveDiscussion(input: $input) {
      success
      errors {
        message
      }
    }
  }
`;
export interface DiscussionLoaderProps {
  parentId: ID;
}

export function DiscussionLoader({
  parentId,
}: DiscussionLoaderProps): JSX.Element {
  const { loading, error, data } = useQuery<GetCommentQuery>(
    GET_COMMENT_QUERY,
    {
      variables: { id: parentId },
    }
  );

  const [addReplyToComment] = useMutation<AddReplyToCommentMutation>(
    ADD_REPLY_TO_COMMENT_MUTATION
  );

  const [editComment] = useMutation<EditCommentMutation>(EDIT_COMMENT_MUTATION);

  const [resolveDiscussion] = useMutation<ResolveDiscussionMutation>(
    RESOLVE_DISCUSSION_MUTATION
  );

  const handleSubmit = ({ content }: CommentFormSubmitProps) => {
    return addReplyToComment({
      variables: { input: { content, parentId: parentId } },
      refetchQueries: ['getComment'],
    }).catch((e) => {
      setStatusMessage(e.message);
    });
  };

  const handleEdit = ({
    content,
    existingCommentId,
  }: CommentFormSubmitProps) => {
    return editComment({
      variables: { input: { content, existingCommentId } },
      refetchQueries: ['getComment'],
    }).catch((e) => {
      setStatusMessage(e.message);
    });
  };

  const handleResolve = (e: any) => {
    e.preventDefault();
    resolveDiscussion({
      variables: { input: { commentId: parentId } },
      refetchQueries: ['getComment'],
    })
      .then(() => {
        console.log('resolved successfully');
      })
      .catch((e) => {
        setStatusMessage(e.message);
      });
  };

  const [statusMessage, setStatusMessage] = useState<string>();

  const [showForm, setShowForm] = useState(false);
  const handleEditAndClose = async (editParams: CommentFormSubmitProps) => {
    setShowForm(false);
    handleEdit(editParams);
  };
  const renderContent = (comment: any) => (
    <div>
      <h2 data-testid="content" className={styles.title}>
        {comment.content}
      </h2>
    </div>
  );
  const renderContentForm = (comment: any) => (
    <CommentForm
      handleSubmit={handleEditAndClose}
      handleCancel={() => setShowForm(false)}
      existingComment={comment}
      submitLabel="Save"
    />
  );

  if (loading) {
    return <Spinner />;
  }
  if (error) {
    return <ErrorMessage>{error}</ErrorMessage>;
  }
  if (data && data.comment) {
    const comment = data.comment;
    const studies = idx(comment, (_) => _.reviewStudies) || [];
    const mentions = idx(comment, (_) => _.mentions);
    const replies = idx(comment, (_) => _.replies);
    const replyCount = (replies && replies.length) || 0;

    return (
      <section className={styles.container}>
        <div data-testid={`comment-${comment.id}`}>
          <div className={styles.discussionPanel}>
            <div data-testid="meta" className={styles.subtleDescriptor}>
              <Attribution
                name={comment.reviewer.name}
                action="created discussion"
                timeAgo={comment.createdAgo}
                edited={!!comment.editedAgo}
              />
              <br />
              <RecentActivity
                timeAgo={comment.createdAgo}
                activeAgo={comment.activeAgo}
                activeByName={comment.activeBy.name}
              />
            </div>

            {comment.resolvedAgo ? (
              <span
                style={{ paddingLeft: '10px' }}
                className={styles.subtleDescriptor}
                data-testid="resolved-ago"
              >
                resolved {comment.resolvedAgo}
              </span>
            ) : (
              <div className={styles.discussionButtons}>
                {comment.actions.canEdit && (
                  <TertiaryButton onClick={() => setShowForm(true)}>
                    Edit discussion
                  </TertiaryButton>
                )}
                {comment.actions.canResolve && (
                  <PrimaryButton
                    style={{ marginLeft: '10px' }}
                    data-testid="resolve-button"
                    onClick={handleResolve}
                  >
                    Resolve discussion
                  </PrimaryButton>
                )}
              </div>
            )}
          </div>

          {showForm ? renderContentForm(comment) : renderContent(comment)}

          <StudiesRollup reviewStudies={studies} />

          {mentions && <Mentions mentions={mentions} />}
        </div>

        <h3 className={styles.subtitle} data-testid="replies-subtitle">
          {replyCount} {pluralize('Reply', replyCount)}
        </h3>
        {replies &&
          replies.map((reply) => (
            <Reply key={reply.id} reply={reply} handleEdit={handleEdit} />
          ))}

        <div data-testid="new-reply" className={styles.createReplyForm}>
          {statusMessage && <ErrorMessage>{statusMessage}</ErrorMessage>}
          <CommentForm handleSubmit={handleSubmit} submitLabel="Reply" />
        </div>
      </section>
    );
  }

  return <></>;
}

export default DiscussionLoader;
