import React from 'react';
import { Button, Icon, IconList } from 'components/core';

interface ExportNavigationButtonProps {
  label: string;
  path: string;
  pendoKey: string;
}

const ExportNavigationButton = ({
  label,
  path,
  pendoKey,
}: ExportNavigationButtonProps) => {
  return (
    <Button to={path} variant="outline" data-key-pendo={pendoKey}>
      <Icon icon={IconList.light.faCloudDownload} />
      {label}
    </Button>
  );
};

export default ExportNavigationButton;
