import React from 'react';
import { InsertDriveFile } from '../Icons/InsertDriveFile';
import styles from './ReferenceToggle.module.css';
import { TertiaryButton } from 'system/base/Button';

export interface ReferenceToggleProps {
  hasFullText: boolean;
  visible: boolean;
  onChange: (visible: boolean) => void;
}

export function ReferenceToggle({
  hasFullText,
  visible,
  onChange,
}: ReferenceToggleProps) {
  let text = '';
  if (hasFullText) {
    if (visible) {
      text = 'Hide full text';
    } else {
      text = 'View full text';
    }
  } else {
    return <></>;
  }

  return (
    <TertiaryButton
      aria-expanded={visible}
      onClick={() => {
        onChange(!visible);
      }}
    >
      <InsertDriveFile className={styles.icon} />
      {text}
    </TertiaryButton>
  );
}
