import React from 'react';

import styles from './StudyReference.module.css';

import { Reference } from './StudyTypes';
import { ReferenceDocuments } from './ReferenceDocuments';

export function StudyReference(props: Reference) {
  const { authors, title, source, journalInfo, publisherInfo } = props;

  const renderSource = () => {
    if (source) {
      return <p>{source}</p>;
    } else {
      return (
        <>
          <p>{journalInfo}</p>
          <p>{publisherInfo}</p>
        </>
      );
    }
  };

  return (
    <div className={styles.studyReference} data-testid="reference">
      <div className={styles.authors}>{authors || ''}</div>
      <h2 className={styles.title}>{title}</h2>

      <div className={styles.source}>{renderSource()}</div>

      <div className={styles.tools}>
        <ReferenceDocuments {...props} />
      </div>
    </div>
  );
}
