import { findAll, Chunk } from 'highlight-words-core';
import React, { FunctionComponent } from 'react';
import styled from 'system/styled';
import { InlineText } from 'system/base/Text';

export interface Props {
  children: string;
  includeWords: ReadonlyArray<string>;
  excludeWords: ReadonlyArray<string>;
}

interface HighlightTextProps {
  type: 'included' | 'excluded';
}
const HighlightText = styled(
  InlineText.withComponent('mark') as typeof InlineText
)<HighlightTextProps>(({ type, theme }) => {
  if (type === 'included') {
    return theme.colorStyles.onIncluded;
  } else if (type === 'excluded') {
    return theme.colorStyles.onExcluded;
  }
});

const Highlight: FunctionComponent<Props> = ({
  children,
  includeWords,
  excludeWords,
}: Props) => {
  const chunks = findAll({
    searchWords: includeWords.concat(excludeWords),
    textToHighlight: children,
  });

  return (
    <>
      {chunks.map((chunk: Chunk) => {
        const { highlight, start, end } = chunk;
        const text = children.substr(start, end - start);

        if (highlight) {
          const included = includeWords
            .map((w) => w.toLowerCase())
            .includes(text.toLowerCase());

          return (
            <HighlightText
              type={included ? 'included' : 'excluded'}
              key={start + '_' + end}
            >
              {text}
            </HighlightText>
          );
        } else {
          return <span key={start + '_' + end}>{text}</span>;
        }
      })}
    </>
  );
};

export default Highlight;
