import React from 'react';
import { EditorTextbox } from '../../../FormBuilder/Widgets/EditorTextbox';
import styles from './CheckboxesEditor.module.css';
import {
  OptionBlock,
  updateLabel as updateOptionLabel,
  newOptionBlock,
} from 'concepts/Extraction/Blocks/shared/Option';
import {
  CheckboxesBlock,
  updateLabel,
  addOption,
  updateOption,
  removeOption,
} from 'concepts/Extraction/Blocks/Checkboxes/CheckboxesBlock';
import {
  FocusManagerProvider,
  useFocusManager,
} from 'contexts/FocusManager/FocusManager';

export interface OptionEditorProps {
  option: OptionBlock;
  disabled?: boolean;
  onChange?: (updatedBlock: OptionBlock) => void;
  onComplete?: () => void;
  onRemove?: () => void;
  moveUp?: () => void;
  moveDown?: () => void;
}

export interface CheckboxesEditorProps {
  block: CheckboxesBlock;
  onChange: (updatedBlock: CheckboxesBlock) => void;
  onComplete: () => void;
  onRemove: () => void;
}

const OptionEditor = ({
  option,
  disabled,
  onChange,
  onRemove,
  onComplete,
  moveUp,
  moveDown,
}: OptionEditorProps): JSX.Element => (
  <li data-option-id={option.id} className={styles.optionItem}>
    <span className={styles.optionItemDash}>&ndash;</span>
    <EditorTextbox
      value={option.label}
      placeholder="Type an option"
      disabled={disabled}
      onKeyDown={(event) => {
        switch (event.key) {
          case 'Backspace':
            if (option.label == '') {
              event.preventDefault();
              if (onRemove) {
                onRemove();
              }
            }
            break;
          case 'ArrowUp':
            if (moveUp) {
              event.preventDefault();
              moveUp();
            }
            break;
          case 'ArrowDown':
            if (moveDown) {
              event.preventDefault();
              moveDown();
            }
            break;
          case 'Enter':
            event.preventDefault();
            if (onComplete) {
              onComplete();
            }
            break;
        }
      }}
      onChange={(event) => {
        const newLabel = event.target.value;
        if (onChange) {
          onChange(updateOptionLabel(option, newLabel));
        }
      }}
    />
  </li>
);

const CheckboxesEditorInner = ({
  block,
  onChange,
  onRemove,
  onComplete,
}: CheckboxesEditorProps): JSX.Element => {
  const requestFocus = useFocusManager();

  const focusOnTitle = () => {
    requestFocus(`input[data-id="${block.id}"]`, {
      when: 'now',
    });
  };

  const moveFocusUp = (optionIndex: number) => {
    if (optionIndex === 0) {
      focusOnTitle();
      return;
    }
    const aboveOptionId = block.options[optionIndex - 1].id;
    requestFocus(`[data-option-id="${aboveOptionId}"] input`, {
      when: 'now',
    });
  };

  const moveFocusDown = (optionIndex: number) => {
    const nextOption = block.options[optionIndex + 1];
    if (!nextOption) {
      return;
    }
    requestFocus(`[data-option-id="${nextOption.id}"] input`, {
      when: 'now',
    });
  };

  const createAndFocusOnNewOption = () => {
    const newOption = newOptionBlock('');
    const newBlock = addOption(block, newOption, null);
    onChange(newBlock);
    requestFocus(`[data-option-id="${newOption.id}"] input`);
  };

  const focusFirstOption = () => {
    const firstOption = block.options[0];
    requestFocus(`[data-option-id="${firstOption.id}"] input`, {
      when: 'now',
    });
  };

  return (
    <div className={styles.container}>
      <EditorTextbox
        value={block.label}
        placeholder="Label"
        data-id={block.id}
        data-label-input=""
        className={styles.labelInput}
        onChange={(event) => {
          const newLabel = event.target.value;
          onChange(updateLabel(block, newLabel));
        }}
        onKeyDown={(event) => {
          switch (event.key) {
            case 'Backspace':
              if (block.label == '') {
                event.preventDefault();
                onRemove();
                // Rely on outside focus provider
              }
              break;
            case 'ArrowDown':
              event.preventDefault();

              if (block.options[0]) {
                focusFirstOption();
              } else {
                createAndFocusOnNewOption();
              }
              break;
            case 'Enter':
              event.preventDefault();
              break;
          }
        }}
        onKeyUp={(event) => {
          switch (event.key) {
            case 'Enter':
              {
                event.preventDefault();
                createAndFocusOnNewOption();
              }
              break;
          }
        }}
      />
      <ol className={styles.optionList}>
        {block.options.map((option, optionIndex) => (
          <OptionEditor
            key={option.id}
            option={option}
            onChange={(newOption) => {
              onChange(updateOption(block, newOption));
            }}
            moveUp={() => {
              moveFocusUp(optionIndex);
            }}
            moveDown={() => {
              moveFocusDown(optionIndex);
            }}
            onComplete={() => {
              if (
                optionIndex == block.options.length - 1 &&
                option.label === ''
              ) {
                onChange(removeOption(block, option.id));
                onComplete();
              } else {
                const newOption = newOptionBlock('');
                const newBlock = addOption(block, newOption, option.id);
                onChange(newBlock);
                requestFocus(`[data-option-id="${newOption.id}"] input`);
              }
            }}
            onRemove={() => {
              onChange(removeOption(block, option.id));
              if (optionIndex !== 0) {
                moveFocusUp(optionIndex);
              } else {
                requestFocus('[data-label-input]');
              }
            }}
          />
        ))}
        {block.options.length === 0 && (
          <OptionEditor
            key="default"
            option={{ type: 'Option', id: 'default', label: '' }}
            onChange={({ label }) => {
              const newOption = newOptionBlock(label);
              const newBlock = addOption(block, newOption, null);
              onChange(newBlock);
              requestFocus(`[data-option-id="${newOption.id}"] input`);
            }}
            onComplete={() => {
              const newOption = newOptionBlock('');
              const newBlock = addOption(block, newOption, null);
              onChange(newBlock);
            }}
            onRemove={() => {
              requestFocus('[data-label-input]', { when: 'now' });
            }}
          />
        )}
        {block.otherOption && (
          <OptionEditor
            key="other"
            option={{ type: 'Option', id: 'other', label: 'Other' }}
            disabled={true}
          />
        )}
      </ol>
    </div>
  );
};

export const CheckboxesEditor = (props: CheckboxesEditorProps): JSX.Element => (
  <FocusManagerProvider>
    <CheckboxesEditorInner {...props} />
  </FocusManagerProvider>
);
