import React, { useState } from 'react';
import classnames from 'classnames';
import primaryNavStyles from '../../PrimaryNavigation.module.scss';
import { useHeadway } from '../../hooks/useHeadway';
import { HeadwayMessageCount } from '..';
import styles from './HelpDropdown.module.scss';
import {
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  Icon,
  IconList,
} from 'components/core';

interface Props {
  children: React.ReactNode;
}

const HelpDropdown = ({ children }: Props) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const {
    isHeadwayInitialized,
    isHeadwayMenuOpen,
    headwayMessageCount,
  } = useHeadway({
    selector: '[data-headway-selector]',
    trigger: '[data-headway-trigger]',
  });

  return (
    <div className={styles.HelpDropdown}>
      <Menu onStateChange={setIsMenuOpen}>
        <MenuButton
          type="brand"
          size="md"
          data-testid="helpDropdownButton"
          className={primaryNavStyles.PrimaryNavigation_iconButton}
          activeClass={primaryNavStyles.PrimaryNavigation_active}
          iconOnly
        >
          <span
            data-headway-selector
            className={styles.HelpDropdown_headwaySelector}
          />
          {children}
          {!isHeadwayMenuOpen && !isMenuOpen && (
            <div className={styles.HelpDropdown_headwayCount}>
              <HeadwayMessageCount count={headwayMessageCount} />
            </div>
          )}
        </MenuButton>
        <MenuList align="right">
          <MenuGroup>
            <MenuItem
              onClick={() => {
                /* noop */
              }}
              className={classnames({ [styles.hidden]: !isHeadwayInitialized })}
              data-headway-trigger
            >
              <Icon icon={IconList.light.faBell} /> Latest changes
              <span className={styles.HelpDropdown_rightAligned}>
                <HeadwayMessageCount count={headwayMessageCount} />
              </span>
            </MenuItem>

            <MenuItem to="https://support.covidence.org/help" external>
              <Icon icon={IconList.light.faBookOpen} /> Explore knowledge base
            </MenuItem>
            <MenuItem
              to="https://support.covidence.org/help/are-you-a-new-covidence-user"
              external
            >
              <Icon icon={IconList.light.faImageUser} /> Register for a webinar
            </MenuItem>
          </MenuGroup>
        </MenuList>
      </Menu>
    </div>
  );
};

export default HelpDropdown;
