import { generateBlockID } from '../generateBlockID';
import { TemplateBlock } from '../BaseBlock';
import { TableHeaderTypePlural, TableHeader } from './types';
import { getNewHeader } from './utils';
import { isTableBlockValue } from './BaseTable/types';
import { BlockRecords, readBlockValue } from 'concepts/Extraction/BlocksData';

export interface TableBlock extends TemplateBlock {
  type: 'CustomTable';
  label: string;
  description: string; // instruction to extractors
  /** @maxItems 100 */
  rows: TableHeader[];
  /** @maxItems 100 */
  columns: TableHeader[];
}

// Currently, the only table type is a 'CustomTable'.
// blockIsTable will need to check for other table types as we create more
// types of table.
export const blockIsTable = (block: TemplateBlock): block is TableBlock =>
  block.type === 'CustomTable';

export const newTableBlock = (
  label: string,
  extraProps?: Partial<TableBlock>
): TableBlock => ({
  type: 'CustomTable',
  id: generateBlockID(),
  label,
  description: '',
  // Create 2 empty columns and rows to start so that the TableHeaderFields in
  // in the editor settings have pre-existing input fields.
  // Otherwise, users need to click "add column"/"add row" buttons first before
  // being able to create their first columns/rows, which is less intuitive.
  columns: [getNewHeader('Column 1'), getNewHeader('Column 2')],
  rows: [getNewHeader('Row 1'), getNewHeader('Row 2')],
  ...extraProps,
});

export const updateLabel = (
  block: TableBlock,
  label: TableBlock['label']
): TableBlock => ({ ...block, label });

export const updateDescription = (
  block: TableBlock,
  description: TableBlock['description']
): TableBlock => ({ ...block, description });

export const updateHeaders = (
  block: TableBlock,
  headersKey: TableHeaderTypePlural,
  newHeaders: TableHeader[]
): TableBlock => ({ ...block, [headersKey]: newHeaders });

export const readTableValue = (data: BlockRecords, block: TableBlock) => {
  const value = readBlockValue(data, block.id);
  return isTableBlockValue(value) ? value : null;
};
