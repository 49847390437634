import React, {
  ReactNode,
  CSSProperties,
  FunctionComponent,
  ComponentPropsWithoutRef,
  ComponentProps,
} from 'react';
import { Body, Cell, Foot, Head, Row, Table, Column } from 'system/base/Table';
import { ID, Optional } from 'util/types';

export interface HasID {
  id: ID | string | number;
}

export interface Column<T extends HasID> {
  key?: string | number;
  render: (data: T) => ReactNode;
  description?: string;
  cellProps?: ComponentPropsWithoutRef<typeof Cell>;
  width?: CSSProperties['width'];
}

// default styling for SummaryTable Cells
const SummaryCell: FunctionComponent<ComponentPropsWithoutRef<typeof Cell>> = (
  props
) => (
  <Cell px={3} py={2} border="" borderBottom="table" minHeight={4} {...props} />
);

export type Columns<T extends readonly HasID[]> = Column<T[number]>[];
export const Columns = {}; // dummy value export to suppress webpack warning

export interface Props<T extends HasID>
  extends ComponentPropsWithoutRef<typeof Table> {
  collection: Optional<readonly T[]>;
  columns: Columns<T[]>;
  header?: ReactNode;
  headerProps?: ComponentProps<typeof Cell>;
  footer?: ReactNode;
  footerProps?: ComponentProps<typeof Cell>;
}

function RowSpan<T extends HasID>({
  children,
  columns,
  cellProps,
}: Pick<Props<T>, 'columns' | 'children'> & {
  cellProps?: ComponentProps<typeof Cell>;
}) {
  return (
    <Row>
      <SummaryCell colSpan={columns.length} {...cellProps}>
        {children}
      </SummaryCell>
    </Row>
  );
}

function CollectionRows<T extends HasID>({
  collection,
  columns,
}: Pick<Props<T>, 'collection' | 'columns'>) {
  return (
    <>
      {collection
        ? collection.map((row) => (
            <Row key={row.id}>
              {columns.map((column) => (
                <SummaryCell
                  key={column.key || column.description}
                  {...column.cellProps}
                >
                  {column.render(row)}
                </SummaryCell>
              ))}
            </Row>
          ))
        : []}
    </>
  );
}

function ColGroup<T extends HasID>({ columns }: Pick<Props<T>, 'columns'>) {
  return (
    <colgroup>
      {columns.map((column) => (
        <Column key={column.key || column.description} width={column.width} />
      ))}
    </colgroup>
  );
}

function SummaryTable<T extends HasID>({
  columns,
  collection,
  header,
  headerProps,
  footer,
  footerProps,
  ...props
}: Props<T>) {
  const hasWidths = columns.some((col) => col.width);

  return (
    <Table fixed={hasWidths} boxShadow="" colors="none" {...props}>
      {hasWidths && <ColGroup columns={columns} />}
      {header && (
        <Head>
          <RowSpan columns={columns} cellProps={headerProps}>
            {header}
          </RowSpan>
        </Head>
      )}
      {collection && (
        <Body>
          <CollectionRows collection={collection} columns={columns} />
        </Body>
      )}
      {footer && (
        <Foot>
          <RowSpan columns={columns} cellProps={footerProps}>
            {footer}
          </RowSpan>
        </Foot>
      )}
    </Table>
  );
}

export default SummaryTable;
