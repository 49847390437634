import React, { useRef, useState } from 'react';
import styles from '../../../PrimaryNavigation.module.scss';
import { MenuGroup, MenuHeading, MenuItem, Input } from 'components/core';
import { useDebouncedFunction } from 'hooks/useDebouncedFunction';
import { validateEmail } from 'util/validateEmail';

interface Props {
  canProxy: boolean;
  className?: string;
}
type FieldState = 'pristine' | 'valid' | 'invalid';

const ProxyEmailField = () => {
  const [valid, setValid] = useState<FieldState>('pristine');

  const debounced = useDebouncedFunction((emailValid) => {
    setValid(emailValid ? 'valid' : 'invalid');
  }, 200);

  const inputRef = useRef<HTMLInputElement>(null);

  const onChange = () => {
    const field = inputRef.current as HTMLInputElement;
    const emailValid = validateEmail(field.value);
    debounced(emailValid);
  };

  return (
    <div>
      <Input
        ref={inputRef}
        inputType="email"
        name="email"
        placeholder="proxy@email.com"
        onInput={onChange}
        type={valid == 'invalid' ? 'danger' : 'neutral'}
      />
      {valid == 'invalid' ? (
        <div style={{ color: 'red' }}>Email is invalid</div>
      ) : null}
    </div>
  );
};

const AdminSection = ({ canProxy, className }: Props) => {
  return (
    <MenuGroup className={className}>
      <MenuHeading>Admin controls</MenuHeading>
      <MenuItem to="/admin">Admin</MenuItem>
      <MenuItem to="/admin/background-jobs">Background queues</MenuItem>
      {canProxy && (
        <MenuItem.Container className={styles.PrimaryNavigation_proxy}>
          <form action="/proxy_as" method="get">
            <ProxyEmailField />
          </form>
        </MenuItem.Container>
      )}
    </MenuGroup>
  );
};

export default AdminSection;
