import React, { ReactNode, useState, useEffect, useRef } from 'react';
import { Dialog } from '@headlessui/react';
import classNames from 'classnames';
import styles from './Modal.module.scss';
import { Button, Icon, IconList } from 'components/core';

interface ModalProps {
  children: ReactNode;
  size?: 'custom' | 'sm' | 'md' | 'lg';
  isOpen: boolean;
  onClose?: () => any;
  className?: string;
}

const shouldTransition = () => !process.env.TEST;

const Modal = ({
  children,
  isOpen,
  onClose,
  className,
  size = 'md',
  ...rest
}: ModalProps) => {
  const [open, setOpen] = useState(false);
  const overlayRef = useRef<HTMLDivElement>(null);
  const dialogRef = useRef<HTMLDivElement>(null);

  const [isTransitioning, setTransitioning] = useState(false);
  const [isExitTransition, setIsExitTransition] = useState(false);

  useEffect(() => {
    if (!isTransitioning) return;
    let promises: Promise<any>[] = [];

    const waitForTransitions = () => {
      Promise.all(promises).then(() => {
        setTransitioning(false);
        promises = [];
      });
    };

    let transitionsStarted = false;
    const addTransitionPromise = (e: TransitionEvent, ref: any) => {
      if (e.target !== ref.current) {
        return;
      } // ignore children
      transitionsStarted = true;

      promises.push(
        new Promise((res) => {
          ref.current?.addEventListener(
            'transitionend',
            () => {
              res(true);
            },
            { once: true }
          );
        })
      );
      waitForTransitions();
    };

    overlayRef.current?.addEventListener('transitionstart', (e) =>
      addTransitionPromise(e, overlayRef)
    );
    dialogRef.current?.addEventListener('transitionstart', (e) =>
      addTransitionPromise(e, dialogRef)
    );

    // Check to see if transitions are happening so we don't wait for
    // something that isn't coming
    const timeout = setTimeout(() => {
      if (transitionsStarted) return;
      setTransitioning(false);
      promises = [];
    }, 50);

    return () => {
      clearTimeout(timeout);
    };
  }, [isTransitioning]);

  useEffect(() => {
    setOpen(isOpen);
    if (isOpen !== open) {
      setTransitioning(true);
      setIsExitTransition(!isOpen);
    }
  }, [isOpen, open]);

  // Only hold the dialog open longer if we're using transitions,
  // otherwise, just forward isOpen
  return (
    <Dialog
      open={isOpen || (shouldTransition() && (open || isTransitioning))}
      onClose={() => onClose && onClose()}
      {...rest}
    >
      <div
        ref={overlayRef}
        className={classNames(styles.overlay, {
          [styles.enterAnimation]:
            shouldTransition() && ((isOpen && !open) || isExitTransition),
        })}
      />
      <Dialog.Panel
        ref={dialogRef}
        className={classNames(styles.ModalContent, styles[size], className, {
          [styles.enterAnimation]:
            shouldTransition() && ((isOpen && !open) || isExitTransition),
        })}
      >
        {onClose ? (
          <Button
            className={styles.closeButton}
            iconOnly
            type="neutral"
            variant="ghost"
            size="sm"
            onClick={() => onClose()}
            aria-label="Close"
          >
            <Icon icon={IconList.light.faClose} size="lg" />
          </Button>
        ) : (
          ''
        )}
        {children}
      </Dialog.Panel>
    </Dialog>
  );
};

export default Modal;
