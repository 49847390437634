import React, { ReactNode } from 'react';
import { FloatingPortal, FloatingFocusManager } from '@floating-ui/react';
import classNames from 'classnames';
import { usePopoverContext } from '../../Popover';
import { PopoverCloseButton } from '../PopoverCloseButton/PopoverCloseButton';
import { PopoverArrow } from '../PopoverArrow/PopoverArrow';
import styles from './PopoverContent.module.scss';

type PopoverContentProps = {
  className?: string;
  children: ReactNode;
};

export const PopoverContent = ({
  className,
  ...props
}: PopoverContentProps) => {
  const {
    renderCloseButton,
    refs,
    getFloatingProps,
    floatingStyles,
    context,
  } = usePopoverContext();

  // If popover is not open then dont render any content
  if (!context.open) return null;

  return (
    <FloatingPortal>
      <FloatingFocusManager
        context={context}
        // When modal is true, the user is unable to tab out of the popover
        modal={false}
        // Set focus to floating container rather than element inside
        order={['floating', 'content']}
      >
        <div
          ref={refs.setFloating}
          style={floatingStyles}
          className={classNames(styles.container, className)}
          {...getFloatingProps(props)}
        >
          <div
            className={classNames(styles.content, {
              [styles.hasCloseButton]: renderCloseButton,
            })}
          >
            {props.children}
            {renderCloseButton && <PopoverCloseButton />}
            <PopoverArrow />
          </div>
        </div>
      </FloatingFocusManager>
    </FloatingPortal>
  );
};
