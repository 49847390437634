export type Criteria = Record<string, { include: string[]; exclude: string[] }>;

export enum CriteriaCategory {
  StudyCharacteristics = 'study_characteristics',
  Population = 'population',
  InterventionExposure = 'intervention_exposure',
  ComparatorContext = 'comparator_context',
  Outcome = 'outcome',
  Other = 'other',
}
