import {
  textStyle,
  TextStyleProps,
  typography,
  TypographyProps,
  space,
  SpaceProps,
  compose,
  variant,
} from 'styled-system';
import styled from '../styled';

export const variants = {
  ordered: {
    paddingLeft: 3,
    listStyleType: 'decimal',
  },
  unordered: {
    paddingLeft: 3,
    listStyleType: 'disc',
  },
  unbulleted: {
    paddingLeft: 0,
    listStyleType: 'none',
  },
};

const listVariantProps = variant({
  prop: 'variant',
  variants,
});

type ListVariantProps = { variant?: keyof typeof variants };

type StyleProps = SpaceProps & TypographyProps;

const styleProps = compose(space, typography);

export const UnorderedList = styled('ul')<StyleProps & ListVariantProps>(
  {
    // override `ul` application styling
    marginBottom: 0,
  },
  listVariantProps,
  styleProps
);

UnorderedList.defaultProps = {
  variant: 'unordered',
};

export const OrderedList = styled('ol')<StyleProps & ListVariantProps>(
  {
    // override `ol` application styling
    marginBottom: 0,
  },
  listVariantProps,
  styleProps
);

OrderedList.defaultProps = {
  variant: 'ordered',
};

export const ListItem = styled('li')<StyleProps & TextStyleProps>(
  styleProps,
  textStyle
);
