import { gql, useMutation } from '@apollo/client';
import React, { useState } from 'react';
import idx from 'idx';
import { TopicExpertsContext } from './TopicExperts';
import useCollectionQuery from 'hooks/useCollectionQuery';
import {
  GetTopicExpertInvitesQuery,
  GetTopicExpertInvitesQueryVariables,
  InviteTopicExpertMutation,
  CancelTopicExpertInviteMutation,
  ResendTopicExpertInviteMutation,
} from 'types/graphql';
import {
  PendingInvites,
  PendingInvitesProps,
  ModifyInviteVariables,
} from 'concepts/Guidelines/TopicExperts/PendingInvites';
import {
  InviteForm,
  InviteFormProps,
  SendInviteVariables,
} from 'concepts/Guidelines/TopicExperts/InviteForm';

export const GET_INVITES = gql`
  query GetTopicExpertInvites($guidelineId: ID!, $after: String) {
    guideline: node(id: $guidelineId) {
      id
      ... on Guideline {
        id
        name
        topics {
          nodes {
            id
            name
          }
        }
        topicExpertInvites(first: 10, after: $after) {
          pageInfo {
            hasNextPage
            endCursor
          }
          nodes {
            id
            createdAt
            name
            email
            expertTopics {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const INVITE_TOPIC_EXPERT = gql`
  mutation inviteTopicExpert($input: InviteTopicExpertInput!) {
    inviteTopicExpert(input: $input) {
      success
      errors {
        message
      }
    }
  }
`;

export const CANCEL_TOPIC_EXPERT_INVITE = gql`
  mutation cancelTopicExpertInvite($input: CancelTopicExpertInviteInput!) {
    cancelTopicExpertInvite(input: $input) {
      success
      errors {
        message
      }
    }
  }
`;

export const RESEND_TOPIC_EXPERT_INVITE = gql`
  mutation resendTopicExpertInvite($input: ResendTopicExpertInviteInput!) {
    resendTopicExpertInvite(input: $input) {
      success
      errors {
        message
      }
    }
  }
`;

export interface InvitesLoaderProps {
  context: TopicExpertsContext;
}

export function InvitesLoader({
  context: { guidelineId },
}: InvitesLoaderProps): JSX.Element {
  const [successMessage, setSuccessMessage] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string>();

  const { data, loadMore, error, loading } = useCollectionQuery<
    GetTopicExpertInvitesQuery,
    GetTopicExpertInvitesQueryVariables
  >({
    query: GET_INVITES,
    variables: { guidelineId },
    path: ['guideline', 'topicExpertInvites'],
  });

  const [inviteTopicExpertMutation] = useMutation<InviteTopicExpertMutation>(
    INVITE_TOPIC_EXPERT
  );
  const [
    cancelTopicExpertInviteMutation,
  ] = useMutation<CancelTopicExpertInviteMutation>(CANCEL_TOPIC_EXPERT_INVITE);
  const [
    resendTopicExpertInviteMutation,
  ] = useMutation<ResendTopicExpertInviteMutation>(RESEND_TOPIC_EXPERT_INVITE);

  // TODO: How to specify the type of the return from the query?
  const invitations: any =
    idx(data, (_) => _.guideline.topicExpertInvites.nodes) || [];
  const topics: any = idx(data, (_) => _.guideline.topics.nodes) || [];

  function cancelInvite({ id }: ModifyInviteVariables) {
    setErrorMessage(undefined);
    setSuccessMessage(undefined);

    return cancelTopicExpertInviteMutation({
      variables: {
        input: {
          id,
        },
      },
      refetchQueries: ['GetTopicExpertInvites'],
    })
      .then((result) => {
        const { data } = result;
        if (data?.cancelTopicExpertInvite?.success) {
          setSuccessMessage(`Invitation cancelled successfully`);
          // TODO: RELOAD COLLECTION OF INVITES - to show the newly added invite
        } else {
          const errors = data?.cancelTopicExpertInvite?.errors;
          setErrorMessage(
            errors
              ? errors.map((err) => err.message).join('; ')
              : 'Unknown error'
          );
        }
      })
      .catch((e) => {
        setErrorMessage(e.message);
      });
  }

  function resendInvite({ id }: ModifyInviteVariables) {
    setErrorMessage(undefined);
    setSuccessMessage(undefined);

    return resendTopicExpertInviteMutation({
      variables: {
        input: {
          id,
        },
      },
    })
      .then((result) => {
        const { data } = result;
        if (data?.resendTopicExpertInvite?.success) {
          setSuccessMessage(`Invitation resent successfully`);
        } else {
          const errors = data?.resendTopicExpertInvite?.errors;
          setErrorMessage(
            errors
              ? errors.map((err) => err.message).join('; ')
              : 'Unknown error'
          );
        }
      })
      .catch((e) => {
        setErrorMessage(e.message);
      });
  }

  function sendInvite({ name, email, topicIds }: SendInviteVariables) {
    setErrorMessage(undefined);
    setSuccessMessage(undefined);

    return inviteTopicExpertMutation({
      variables: {
        input: {
          name,
          email,
          guidelineId,
          topicIds,
        },
      },
      refetchQueries: ['GetTopicExpertInvites'],
    })
      .then((result) => {
        const { data } = result;
        if (data?.inviteTopicExpert?.success) {
          setSuccessMessage(
            `Invitation for ${name} sent to ${email} successfully`
          );
        } else {
          const errors = data?.inviteTopicExpert?.errors;
          setErrorMessage(
            errors
              ? errors.map((err) => err.message).join('; ')
              : 'Unknown error'
          );
        }
      })
      .catch((e) => {
        setErrorMessage(e.message);
      });
  }

  const inviteFormProps: InviteFormProps = {
    loading,
    sendInvite,
    topics,
    errorMessage,
    successMessage,
  };
  const pendingInvitesProps: PendingInvitesProps = {
    loading,
    invitations,
    error,
    cancelInvite,
    resendInvite,
    loadMore,
  };

  return (
    <>
      <InviteForm {...inviteFormProps} />
      <PendingInvites {...pendingInvitesProps} />
    </>
  );
}
