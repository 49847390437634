import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Form from 'components/RailsForm';
import Modal from 'components/Modal';
import ConfirmationChecklist from 'components/ConfirmationChecklist';
import { DangerButton, NakedButton } from 'system/base/Button';

class DeleteReviewChecklist extends Component {
  constructor() {
    super();

    this.state = {
      submitting: false,
      confirmed: false,
    };

    this.onConfirmedChange = this.onConfirmedChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
  }

  onConfirmedChange(confirmed) {
    this.setState({ confirmed });
  }

  onSubmit(e) {
    const { confirmed } = this.state;

    if (confirmed) {
      this.form.submit();
      this.setState({
        submitting: true,
      });
    } else {
      e.preventDefault();
    }
  }

  onCloseModal(e) {
    e.preventDefault();
    this.props.onHideModal();
  }

  render() {
    const { showModal, review } = this.props;
    const { id } = review;
    const { submitting, confirmed } = this.state;

    if (!showModal) {
      return null;
    }

    const title = (
      <h2 className="review_delete--title modal--title">
        Are you sure you want to delete this review?
      </h2>
    );

    const footer = (
      <p className="review_delete--submit">
        <DangerButton
          disabled={!confirmed || submitting}
          onClick={this.onSubmit}
        >
          {!submitting
            ? "Yes I'm sure, please delete this review"
            : 'Submitting...'}
        </DangerButton>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <NakedButton onClick={this.onCloseModal}>
          No, take me back to the review settings
        </NakedButton>
      </p>
    );

    return (
      <Form
        onSubmit={this.onSubmit}
        action={`/reviews/${id}`}
        method="delete"
        formRef={(el) => {
          this.form = el;
        }}
      >
        <Modal
          title={title}
          footer={footer}
          onHideModal={this.props.onHideModal}
        >
          <div>
            <h3 className="review_delete--caption">
              Just in case, we’ll need you to confirm that you understand a few
              things:
            </h3>

            <ConfirmationChecklist
              onChange={this.onConfirmedChange}
              items={[
                'Deleting this review will not re-credit your plan with an additional review',
                'All review team members will no longer be able to access this review',
                'Any data or progress in this review will be lost',
              ]}
            />
          </div>
        </Modal>
      </Form>
    );
  }
}

DeleteReviewChecklist.propTypes = {
  onHideModal: PropTypes.func.isRequired,
  review: PropTypes.object.isRequired,
  showModal: PropTypes.bool,
};

DeleteReviewChecklist.defaultProps = {
  showModal: false,
};

export default DeleteReviewChecklist;
