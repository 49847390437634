import React, { useEffect, useState } from 'react';
import { useUID } from 'react-uid';
import { ApolloError, gql, useMutation } from '@apollo/client';
import { Study, Note } from '../StudyTypes';
import { StudyReferences } from '../StudyReferences';
import StudyTags from '../StudyTags';
import NotesModal from '../NotesModal';
import AddTagsModal from '../AddTagsModal';
import Progress from '../Progress';
import UnmergeStudiesModal from '../UnmergeStudiesModal';
import { ManageReviewersModal } from './ManageReviewersModal';
import styles from './StudyListItem.module.css';
import { NakedButton, TertiaryButton } from 'system/base/Button';

import { ReassignStudyReviewerMutation, User } from 'types/graphql';
import { Checkbox, LoadingSpinner } from 'components/core';

export const REASSIGN_REVIEWERS_MUTATION = gql`
  mutation ReassignStudyReviewer(
    $studyId: ID!
    $extractorReassignments: [ExtractionReassignment]
    $consensusReassignment: ConsensusReassignment
  ) {
    reassignStudyReviewer(
      input: {
        studyId: $studyId
        extractorReassignments: $extractorReassignments
        consensusReassignment: $consensusReassignment
      }
    ) {
      success
      progress {
        status
        extractionSlots {
          assigned
          currentUser
          extractor {
            id
            name
          }
          lastActivityAgo
          completed
        }
        consensusSlot {
          assigned
          currentUser
          decider {
            id
            name
          }
          lastActivityAgo
          started
          completed
        }
      }
    }
  }
`;

export type StudyListItemProps = {
  study: Study;
  selected: boolean;
  currentUserId: string;
  availableTags: string[];
  onSelect: () => void;
  onDeselect: () => void;
  onMoveBackToFullText: () => void;
  onStudyUpdate: () => void;
  selectableReviewers: User[];
  selectableReviewersLoading: boolean;
  fetchSelectableReviewersError: ApolloError | undefined;
  updateStudyInStudies: (study: Study) => void;
};

export function StudyListItem({
  study,
  selected,
  currentUserId,
  availableTags,
  onSelect,
  onDeselect,
  onMoveBackToFullText,
  onStudyUpdate,
  selectableReviewers,
  selectableReviewersLoading,
  fetchSelectableReviewersError,
  updateStudyInStudies,
}: StudyListItemProps): JSX.Element {
  const articleID = `study-${useUID()}`;

  const [showNotesDialog, setShowNotesDialog] = useState(false);
  const [showTagsDialog, setShowTagsDialog] = useState(false);
  const [showManageReviewersDialog, setShowManageReviewersDialog] = useState(
    false
  );
  const [studyLocal, setStudyLocal] = useState(study);

  const [
    reassignReviewers,
    result,
  ] = useMutation<ReassignStudyReviewerMutation>(REASSIGN_REVIEWERS_MUTATION);

  const { data, loading } = result;

  useEffect(() => {
    setStudyLocal(study);
  }, [study]);

  const newProgress = data?.reassignStudyReviewer?.progress;
  useEffect(() => {
    if (newProgress) {
      const newStudy: Study = {
        ...study,
        progress: {
          ...newProgress,
          extractionSlots: [...newProgress.extractionSlots],
        },
      };
      setStudyLocal(newStudy);
      updateStudyInStudies(newStudy);
    }
  }, [newProgress, study, updateStudyInStudies]);

  const notesModal = (
    <NotesModal
      isOpen={showNotesDialog}
      currentUserId={currentUserId}
      onCancel={() => {
        setShowNotesDialog(false);
      }}
      study={studyLocal}
      onSuccess={onStudyUpdate}
      onFailure={() => {
        setShowNotesDialog(false);
      }}
    />
  );

  const addTagsModal = (
    <AddTagsModal
      study={studyLocal}
      availableTags={availableTags}
      isOpen={showTagsDialog}
      onCancel={() => {
        setShowTagsDialog(false);
      }}
      onSuccess={() => {
        setShowTagsDialog(false);
        onStudyUpdate();
      }}
      onFailure={() => {
        setShowTagsDialog(false);
      }}
    />
  );

  const reviewerManagersModal = (
    <ManageReviewersModal
      isOpen={showManageReviewersDialog}
      isLoading={selectableReviewersLoading}
      fetchSelectableReviewersError={fetchSelectableReviewersError}
      onClose={() => {
        setShowManageReviewersDialog(false);
      }}
      selectableReviewers={selectableReviewers}
      study={studyLocal}
      reassignReviewersMutation={reassignReviewers}
      mutationResult={result}
    />
  );

  const notesButtonTitle = (notes: Note[]) => {
    if (notes.length > 1) {
      return `View ${studyLocal.notes.length} notes`;
    } else if (notes.length == 1) {
      return `View ${studyLocal.notes.length} note`;
    } else {
      return 'Add a note';
    }
  };

  const [showUnmergeStudiesDialog, setShowUnmergeStudiesDialog] = useState(
    false
  );
  const unmergeStudyModal = (
    <UnmergeStudiesModal
      isOpen={showUnmergeStudiesDialog}
      primaryStudy={studyLocal}
      onCancel={() => {
        setShowUnmergeStudiesDialog(false);
      }}
      onUnmergeSuccess={() => {
        setShowUnmergeStudiesDialog(false);
        onStudyUpdate();
      }}
      onUnmergeFailure={() => {
        setShowUnmergeStudiesDialog(false);
      }}
    />
  );

  const { progress } = studyLocal;

  const showManageReviewersButton = () =>
    // Gets all non-null extractors and sees if any are present,
    // i.e. are there any extractors on this study
    progress.extractionSlots.filter((slot) => slot.extractor).length !== 0;

  return (
    <article className={styles.container} aria-labelledby={articleID}>
      {notesModal}
      {addTagsModal}
      {unmergeStudyModal}
      {reviewerManagersModal}
      <div className={styles.select}>
        <Checkbox
          size="md"
          onChange={(event) => {
            if (event.target.checked) {
              onSelect();
            } else {
              onDeselect();
            }
          }}
          checked={selected}
          aria-label={`Select study ${studyLocal.covidenceNumber}`}
        />
      </div>
      <cite className={styles.metadata}>
        <div className={styles.identifier} id={articleID}>
          #{studyLocal.covidenceNumber} &ndash; {studyLocal.studyId || ''}
        </div>

        <StudyReferences references={studyLocal.references.nodes} />

        <div className={styles.tools}>
          <div className={styles.toolbarButton}>
            <TertiaryButton
              onClick={() => {
                setShowNotesDialog(true);
              }}
              iconName="comment-alt"
            >
              {notesButtonTitle(studyLocal.notes)}
            </TertiaryButton>
          </div>
          <div className={styles.toolbarButton}>
            <TertiaryButton onClick={onMoveBackToFullText} iconName="undo">
              Move to Full text
            </TertiaryButton>
          </div>

          {studyLocal.references.nodes.length > 1 && (
            <div>
              <TertiaryButton
                iconName="object-ungroup"
                onClick={() => {
                  setShowUnmergeStudiesDialog(true);
                }}
              >
                Un-merge studies
              </TertiaryButton>
            </div>
          )}
        </div>

        <div className={styles.tertiaryButtons}>
          <StudyTags
            tags={studyLocal.tags}
            study={studyLocal}
            onRemoveSuccess={onStudyUpdate}
          />
          <NakedButton
            className={styles.tertiaryButton}
            onClick={() => {
              setShowTagsDialog(true);
            }}
            iconName="tag"
          >
            Add a tag
          </NakedButton>
          {showManageReviewersButton() ? (
            loading ? (
              // Mutation in flight, wait for a response before allowing
              // an additional reassignment
              <LoadingSpinner />
            ) : (
              <NakedButton
                className={styles.tertiaryButton}
                iconName="user-friends"
                onClick={() => {
                  setShowManageReviewersDialog(true);
                }}
              >
                Manage reviewers
              </NakedButton>
            )
          ) : null}
        </div>
      </cite>

      <div className={styles.status}>
        {loading ? (
          // Reloading `progress` from the mutation
          <LoadingSpinner />
        ) : (
          <Progress {...progress} actions={studyLocal.actions} />
        )}
      </div>
    </article>
  );
}
