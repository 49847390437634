import { ColumnCellData, TableData, RowData } from '../types';
import { TableHeader } from '../../types';

export const getRowDataFromTable = (table: TableData) => (
  rowID: TableHeader['id']
): RowData | undefined => table.find((rowData) => rowData.rowID === rowID);

export const getCellDataFromRow = (rowData: RowData) => (
  columnID: TableHeader['id']
): ColumnCellData | undefined =>
  rowData.columns.find((columnData) => columnData.columnID === columnID);

type GetTableCellDataProps = {
  table: TableData;
  rowID: TableHeader['id'];
  columnID: TableHeader['id'];
};

/**
 * Utility to retrieve data for a cell from in a table using rowID & columnID.
 */
export const getTableCellData = ({
  table,
  rowID,
  columnID,
}: GetTableCellDataProps): ColumnCellData | undefined => {
  const rowData = getRowDataFromTable(table)(rowID);
  if (!rowData) return;

  const cellData = getCellDataFromRow(rowData)(columnID);
  return cellData;
};
