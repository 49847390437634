import React, { ReactNode } from 'react';
import classNames from 'classnames';
import styles from './ModalBody.module.scss';

interface ModalBodyProps {
  children: ReactNode;
  className?: string;
}

const ModalBody = ({ children, className }: ModalBodyProps) => {
  return (
    <div className={classNames(className, styles.ModalBody)}>{children}</div>
  );
};

export default ModalBody;
