import React from 'react';
import PropTypes from 'prop-types';
import { gql } from '@apollo/client';
import Checkbox from 'system/elements/Checkbox';

import client from 'store/apollo';
import FloatingBox from 'components/FloatingBox';
import Space from 'system/utility/Space';
import { PrimaryButton } from 'system/base/Button';
import SuccessMessage from 'components/SuccessMessage';
import ErrorMessage from 'components/ErrorMessage';
import ActivitySpinner from 'components/ActivitySpinner';

const GRANT_MAILING_LIST_CONSENT = gql`
  mutation updateMailingListConsent($signedSource: String!) {
    saveConsent: grantMailingListConsent(
      input: { signedSource: $signedSource }
    ) {
      success
    }
  }
`;

const DENY_MAILING_LIST_CONSENT = gql`
  mutation updateMailingListConsent($signedSource: String!) {
    saveConsent: denyMailingListConsent(
      input: { signedSource: $signedSource }
    ) {
      success
    }
  }
`;

class EmailPermissions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mailingListConsent: props.mailingListConsent,
      saveStatus: '',
    };
  }

  onCheckboxUpdate(val) {
    this.setState({
      saveStatus: '',
      mailingListConsent: val,
    });
  }

  save() {
    const { signedSource } = this.props;
    const { mailingListConsent } = this.state;
    const mutation = mailingListConsent
      ? GRANT_MAILING_LIST_CONSENT
      : DENY_MAILING_LIST_CONSENT;

    this.setState({
      saveStatus: 'in-progress',
    });

    client.mutate({ mutation, variables: { signedSource } }).then((result) => {
      this.setState({
        saveStatus: result.data.saveConsent.success ? 'success' : 'error',
      });
    });
  }

  statusTag() {
    switch (this.state.saveStatus) {
      case 'success':
        return <SuccessMessage>Changes saved</SuccessMessage>;
      case 'error':
        return <ErrorMessage>Error saving changes</ErrorMessage>;
      case 'in-progress':
        return <ActivitySpinner />;
      default:
        return '';
    }
  }

  render() {
    const { mailingListConsent, saveStatus } = this.state;

    return (
      <FloatingBox heading="Email Permissions">
        <Space my={2}>
          <Checkbox
            disabled={saveStatus === 'in-progress'}
            checked={mailingListConsent}
            onChange={(e) => this.onCheckboxUpdate(e.target.checked)}
            label={
              "Yes, I'd like to receive emails about product enhancements, systematic review training and best practices"
            }
          />

          <PrimaryButton
            onClick={() => this.save()}
            disabled={saveStatus === 'in-progress'}
          >
            Save
          </PrimaryButton>
          <span className="tcn mh2">{this.statusTag()}</span>
          <p className="tcn mid-gray f4">
            We use ActiveCampaign as our platform for delivering these emails.
            You can find out more about ActiveCampaign&apos;s privacy policy{' '}
            <a
              href="https://www.activecampaign.com/legal/privacy-policy"
              title="ActiveCampaign privacy policy"
            >
              here
            </a>
            .
          </p>
        </Space>
      </FloatingBox>
    );
  }
}

EmailPermissions.propTypes = {
  mailingListConsent: PropTypes.bool.isRequired,
  signedSource: PropTypes.string.isRequired,
};

export default EmailPermissions;
