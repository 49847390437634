// This requires and globally exposes the dependencies needed by code that is
// still in the Rails Asset Pipeline (and therefore can't yet directly import
// modules from Webpack)

/* eslint-disable */

if (module?.hot) {
  module.hot.accept();
}

import 'core-js/stable';
import 'regenerator-runtime/runtime';

import '@selectize/selectize';
import '@selectize/selectize/dist/css/selectize.css';
import underscore from 'underscore';

// Misc libs
window.pluralize = require('pluralize');

require('expose-loader?exposes=accounting!accounting');
require('./vendor/offline-0.7.13'); // only used in screening

// jQuery and extensions:
require('expose-loader?exposes=$!jquery');
require('expose-loader?exposes=jQuery!jquery');
// TODO: What depends on jQuery UI beyond the jQuery File Upload plugin (which vendors its own)?
require('./vendor/jquery-ui-1.11.4'); // version not in NPM; later versions untested
require('./vendor/jquery.highlight'); // not in NPM
require('./vendor/jquery.mustache');
require('dotdotdot');
require('jquery.cookie');
require('blueimp-file-upload');
require('blueimp-file-upload/js/jquery.iframe-transport');
require('expanding-textareas/dist/expanding.jquery');
// this can be installed from GitHub but not without upgrading to a newer
// version so need to test that first.
require('expose-loader?exposes=$dirtyForms!./vendor/jquery.are-you-sure-1.4.0');

// Backbone and extensions

global._ = underscore;
require('backbone-pageable');
require('backbone-relational');
Backbone.Relational.store.addModelScope(window);
require('backbone.stickit');
require('expose-loader?exposes=Backgrid!backgrid');

// Legacy mobile screening app relies on localStorage which is NOT ATOMIC.
// Multiple tabs cause data races. This is used to wrap localStorage access
// critical section in an IndexedDB transaction as a hack to create a mutex.
global.Mutex = require('idb-mutex').default;

// fontawesome-pro
require('@fortawesome/fontawesome-pro/css/all.css');

/* eslint-enable */
