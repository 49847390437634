import React from 'react';
import { Table } from './control/Table';
import { Table2Block } from './types/block';
import { TableIcon } from './TableIcon';
import { TableEditor } from './editor/TableEditor/TableEditor';
import { TableEditorSettings } from './editor/TableEditorSettings/TableEditorSettings';
import {
  EditorProps,
  EditorSettingsProps,
  PreviewProps,
} from 'concepts/Extraction/Blocks/BlockRenderer';

export const Table2BlockRenderer = {
  Editor({ block, onChange, onComplete, onRemove }: EditorProps) {
    return (
      <TableEditor
        block={block as Table2Block}
        onChange={onChange}
        onComplete={onComplete}
        onRemove={onRemove}
      />
    );
  },
  EditorSettings({ block, onChange }: EditorSettingsProps) {
    return (
      <TableEditorSettings block={block as Table2Block} onChange={onChange} />
    );
  },
  Control() {
    return <></>;
  },
  Preview({ block }: PreviewProps) {
    return <Table block={block as Table2Block} />;
  },
  ComparisonRow() {
    return <></>;
  },
  TypeIcon: TableIcon,
  keepResponse() {
    return true;
  },
  displayOnly: false,
};
