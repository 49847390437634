import React from 'react';
import PropTypes from 'prop-types';

const Errors = (props) => {
  if (props.errors && props.errors.length > 0) {
    const renderedErrors = props.errors.map((error) => (
      <li key={error}>{error}</li>
    ));

    return (
      <div className="bg-danger errors--wrapper">
        <ul>
          {renderedErrors.length == 0 ? <li>props.title</li> : renderedErrors}
        </ul>
      </div>
    );
  }

  return null;
};

Errors.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string).isRequired,
  title: PropTypes.string,
};

Errors.defaultProps = {
  title: 'There was an error saving the record.',
};

export default Errors;
