import React, { ComponentProps } from 'react';
import styled from 'system/styled';
import IconInput from 'system/elements/IconInput';
import { TertiaryButton } from 'system/base/Button';
import { onSubmitGetData } from 'util/eventHandlers';

const InlineForm = styled('form')({
  display: 'inline-block',
  // default width for SearchInputs is 270px
  flexBasis: '270px',
});

type SearchInputProps = Omit<ComponentProps<typeof IconInput>, 'iconName'>;

interface Props extends SearchInputProps {
  displaySearchButton?: boolean;
  onSearch: (searchText: string) => void;
}

const SearchInput = ({
  className,
  onSearch,
  displaySearchButton,
  ...props
}: Props) => {
  let formStyle = {};
  if (displaySearchButton) {
    formStyle = { flexBasis: '300px' };
  }
  const handleSearch = (formData: FormData) => {
    const value = formData.get('q');
    if (typeof value !== 'string') return;

    onSearch(value);
  };

  return (
    <InlineForm
      onSubmit={onSubmitGetData(handleSearch)}
      className={className}
      style={formStyle}
    >
      <IconInput
        iconName="search"
        placeholder="Search"
        name="q"
        type="search"
        width={displaySearchButton ? 'auto' : '100%'}
        {...props}
      />
      {displaySearchButton ? (
        <TertiaryButton
          type="submit"
          style={{ marginLeft: '5px', paddingLeft: '6px', paddingRight: '6px' }}
        >
          Search
        </TertiaryButton>
      ) : null}
    </InlineForm>
  );
};

export default SearchInput;
