import React from 'react';
import ReactDOM from 'react-dom';

const tryDeserialize = (v) => {
  try {
    return JSON.parse(v);
  } catch (e) {
    return v;
  }
};

// https://stackoverflow.com/a/7053197/56690
function ready(callback) {
  // IE10 and below can go to "interactive" readyState too early so we check if
  // IE and then wait until it's all the way to "completed" before we fire.
  const stateCheck = document.documentElement.doScroll
    ? /^loaded|^c/
    : /^loaded|c/;

  // in case the document is already rendered
  if (stateCheck.test(document.readyState)) callback();
  // modern browsers
  else if (document.addEventListener) {
    document.addEventListener('DOMContentLoaded', callback);
  } else {
    document.attachEvent('onreadystatechange', () => {
      if (document.readyState === 'complete') {
        callback();
      }
    });
  }
}

// IE compat (IE does not support array methods on NodeList)
function mountPoints() {
  const nodeList = document.querySelectorAll('[data-react-component]');
  return [].slice.call(nodeList);
}

const getDataset = (node) => {
  const attrName = (name) =>
    name
      .replace(/^data-/, '')
      .replace(/-[a-z]/g, (s) => s.charAt(1).toUpperCase());

  return [].slice
    .call(node.attributes)
    .filter((attr) => attr.name.match(/^data-/))
    .reduce(
      (attrs, attr) => ({ ...attrs, [attrName(attr.name)]: attr.value }),
      {}
    );
};

export default (function () {
  const ComponentStore = {};
  let isMounted = false;

  return (components = {}) => {
    Object.assign(ComponentStore, components);
    if (isMounted) return;

    ready(() => {
      mountPoints().forEach((mountPoint) => {
        const dataset = getDataset(mountPoint);
        const Component = ComponentStore[dataset.reactComponent];

        if (Component) {
          const data = {};
          Object.keys(dataset).forEach((key) => {
            if (key !== 'reactComponent') {
              data[key] = tryDeserialize(dataset[key]);
            }
          });

          ReactDOM.render(<Component {...data} />, mountPoint);
        } else {
          const msg = `WARNING: No component found for: ${dataset.reactComponent}`;
          console.error(msg);
          MetaError.log(msg);
        }
      });
    });
  };
})();
