import React from 'react';

import cn from 'classnames';
import styles from './AccountOption.module.scss';
import { Avatar, Button, RadioButton, Text } from 'components/core';
import { Inline } from 'components/layout';

interface Props {
  isCochrane: boolean;
  selectable: boolean;
  onAvatarSelection: () => void;
  selectedValue: string;
  caption: string;
  accountId: string;
  accountName: string;
  ssoSignInLink?: string;
}

const SignInButton = ({ show, url }: { show: boolean; url?: string }) => {
  if (show) {
    return (
      <Button type="neutral" variant="outline" to={url}>
        Sign in
      </Button>
    );
  }
  return null;
};

const AvatarCluster = ({
  accountName,
  caption,
  enabled,
}: {
  accountName: string;
  caption: string;
  enabled: boolean;
}) => {
  return (
    <Inline spacing={3}>
      <Avatar
        name={accountName}
        color={'grey'}
        className={cn(!enabled && styles.disabledAvatar)}
      />
      <div>
        <Text size="md"> {accountName} </Text>
        <Text size="sm" variant="weak">
          {caption}
        </Text>
      </div>
    </Inline>
  );
};
const AccountOption = ({
  selectable,
  onAvatarSelection,
  selectedValue,
  caption,
  accountName,
  ssoSignInLink,
  accountId,
}: Props) => {
  if (selectable) {
    return (
      <div className={styles.radioWrapper}>
        <RadioButton
          size={'md'}
          name="review[account_id]"
          value={accountId}
          selectedValue={selectedValue}
          onClick={onAvatarSelection}
        >
          <AvatarCluster
            accountName={accountName}
            caption={caption}
            enabled={true}
          />
        </RadioButton>
        <SignInButton show={!!ssoSignInLink} url={ssoSignInLink} />
      </div>
    );
  }
  return (
    <div className={styles.radioWrapper}>
      <div className={styles.unselectable}>
        <AvatarCluster
          accountName={accountName}
          caption={caption}
          enabled={false}
        />
      </div>
      <SignInButton show={!!ssoSignInLink} url={ssoSignInLink} />
    </div>
  );
};

export default AccountOption;
