import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import styles from './EmailField.module.scss';
import { validateEmail } from 'util/validateEmail';
import { useDebouncedFunction } from 'hooks/useDebouncedFunction';

type Props = {
  inputName?: string;
  showTitle?: boolean;
  additionalClasses?: string;
  errorMessage?: string;
  value?: string;
  placeholder?: string;
  showAlert?: boolean;
};

type FieldState = 'pristine' | 'valid' | 'invalid';

export const EmailField = (props: Props) => {
  const {
    inputName,
    showTitle = true,
    additionalClasses,
    errorMessage,
    value,
    placeholder,
    showAlert,
  } = props;

  const initialValid: FieldState = !errorMessage ? 'pristine' : 'invalid';

  const [emailValue] = useState(value || '');

  const [valid, setValid] = useState<FieldState>(initialValid);
  const [errorMessageState, setErrorMessageState] = useState(errorMessage);

  const debounced = useDebouncedFunction((emailValid, errorMessage) => {
    setValid(emailValid ? 'valid' : 'invalid');
    setErrorMessageState(errorMessage);
  }, 200);

  const inputRef = useRef<HTMLInputElement>(null);

  const onChange = () => {
    const field = inputRef.current as HTMLInputElement;
    const emailValid = validateEmail(field.value);
    debounced(emailValid, emailValid ? '' : 'Email is invalid');
  };

  useEffect(() => {
    if (showAlert) {
      // Timeout allows page to render underneath the alert without blocking
      setTimeout(() => {
        alert(errorMessage);
      }, 100);
    }
  }, [showAlert, errorMessage]);

  return (
    <div
      className={cn(styles.container, valid === 'invalid' && styles.invalid)}
    >
      <label
        htmlFor={styles.email}
        className={cn(!showTitle && styles.noTitle)}
      >
        <span>
          Email address<abbr className={styles.required}>*</abbr>{' '}
        </span>
      </label>
      <input
        id={styles.email}
        className={cn(
          styles.field,
          valid !== 'invalid' && styles.focus,
          additionalClasses
        )}
        name={inputName || ''}
        ref={inputRef}
        onInput={onChange}
        onChange={onChange}
        onBlur={onChange}
        defaultValue={emailValue}
        placeholder={placeholder || ''}
      />
      {valid === 'invalid' && (
        <span className={styles.error}>{errorMessageState}</span>
      )}
    </div>
  );
};
