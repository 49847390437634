import { makeThemeFormsScale } from './utils/make-theme';

const forms = makeThemeFormsScale({
  label: {
    lineHeight: 1.25,
  },
  input: {
    padding: 1,
    background: '#fff',
    color: 'text',
    borderColor: 'greyLight',
  },
});

export default forms;
