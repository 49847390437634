import React, { useState } from 'react';
import { UseFieldArrayReturn, useFieldArray } from 'react-hook-form';
import styles from '../DefaultTemplate.module.scss';
import { QualityAssessmentTemplateState } from '../../QualityAssessmentTemplate';
import QaConfirmationDialog from '../../QaConfirmationDialog';
import DomainForm from './DomainForm';
import { Heading, Icon, IconList, Accordion, Link } from 'components/core';

const DomainsSection = () => {
  const domains = useFieldArray<QualityAssessmentTemplateState>({
    name: 'domains',
  }) as UseFieldArrayReturn<QualityAssessmentTemplateState, 'domains'>;

  const [modal, setModal] = useState<React.ReactElement | null>();

  return (
    <section className={styles.DefaultTemplate_section}>
      {modal}
      <Heading
        type="h5"
        component="h3"
        variant="brand"
        className={styles.heading}
      >
        Domains
        <Link
          className={styles.HelpLink}
          onClick={() => ''}
          data-pendo-key="qat-domains"
          aria-label="Get help with domains"
        >
          <Icon icon={IconList.light.faQuestionCircle} />
        </Link>
      </Heading>

      <Accordion
        component="div"
        className={styles.DomainAccordion}
        allowMultiple={false}
      >
        {domains.fields.map((field, index) => {
          return (
            <DomainForm
              key={field.id}
              name={`domains.${index}`}
              onDelete={() => {
                if (field.prompt_before_deleting) {
                  setModal(
                    <QaConfirmationDialog
                      onConfirm={() => domains.remove(index)}
                      onClose={() => setModal(null)}
                    />
                  );
                  return;
                }
                domains.remove(index);
              }}
            />
          );
        })}
      </Accordion>

      <Link
        onClick={() =>
          domains.append({
            name: '',
            description: '',
            outcome_specific: false,
            prompt_before_deleting: false,
          })
        }
        className={styles.SectionButton}
        variant="primary"
      >
        <Icon icon={IconList.light.faPlus} /> Add domain
      </Link>
    </section>
  );
};

export default DomainsSection;
