/** @jsxImportSource theme-ui */
import React, { useState } from 'react';
import { ThemeUIStyleObject } from 'theme-ui';
import { CommentPopoverTrigger } from './Trigger/Trigger';
import { CommentPopoverDialog } from './Dialog/Dialog';
import { Comment } from './types';
import { Popover } from 'system/elements/Popover/Popover';

type CommentPopoverProps = {
  className?: string;
  sx?: ThemeUIStyleObject;
  comments: Comment[];
  variant?: 'dark' | 'light';
};

export const CommentPopover = ({
  className,
  sx,
  comments,
  variant = 'dark',
}: CommentPopoverProps): JSX.Element => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  return (
    <Popover
      className={className}
      isPopoverOpen={isPopoverOpen}
      onPopoverOpen={() => {
        setIsPopoverOpen(true);
      }}
      onPopoverClose={() => {
        setIsPopoverOpen(false);
      }}
      trigger={
        <CommentPopoverTrigger
          variant={variant}
          isPopoverOpen={isPopoverOpen}
        />
      }
      dialog={<CommentPopoverDialog comments={comments} />}
      dialogPosition="topLeft"
      aria-label="Comment"
      sx={sx}
    />
  );
};
