import React, { useState, ReactNode } from 'react';
import { useMutation } from '@apollo/client';
import PROMOTE_ORGANIZATION_MEMBER_TO_ADMINISTRATOR from './promoteOrganizationMemberToAdministrator';
import DEMOTE_ORGANIZATION_ADMINSTRATOR_TO_MEMBER from './demoteOrganizationAdministratorToMember';
import { MembershipStatus as Status } from 'schema/enums';
import { ID } from 'util/types';
import { SecondaryButton } from 'system/base/Button';
import ErrorMessage from 'components/ErrorMessage';
import SuccessMessage from 'components/SuccessMessage';
import Box from 'system/base/Box';
import {
  PromoteOrganizationMemberToAdministratorMutation,
  PromoteOrganizationMemberToAdministratorInput,
  DemoteOrganizationAdministratorToMemberMutation,
  DemoteOrganizationAdministratorToMemberInput,
} from 'types/graphql';
import Checkbox from 'system/elements/Checkbox';

interface Organization {
  id: ID;
  name: string;
}

interface Props {
  memberId: ID;
  status: Status;
  organization: Organization;
}

const AdminStatus = ({ memberId, status, organization }: Props) => {
  const [boxChecked, setBoxChecked] = useState(status === Status.Admin);
  const [busy, setBusy] = useState(false);
  const [statusMessage, setStatusMessage] = useState<ReactNode>();

  const [promoteMemberMutation] = useMutation<
    PromoteOrganizationMemberToAdministratorMutation,
    PromoteOrganizationMemberToAdministratorInput
  >(PROMOTE_ORGANIZATION_MEMBER_TO_ADMINISTRATOR);
  const promoteMember = () => {
    setBusy(true);
    setStatusMessage(undefined);
    promoteMemberMutation({
      variables: { memberId, organizationId: organization.id },
    })
      .then(
        ({
          data: { promoteOrganizationMemberToAdministrator: response },
        }: any) => {
          if (response.success) {
            setStatusMessage(<SuccessMessage>Saved</SuccessMessage>);
          } else {
            setStatusMessage(
              <ErrorMessage>
                {response.errors.map((e: any) => `${e.message}`).join(',')}
              </ErrorMessage>
            );
          }
        }
      )
      .catch((err) => {
        setStatusMessage(<ErrorMessage>{err.message}</ErrorMessage>);
      })
      .finally(() => {
        setBusy(false);
      });
  };

  const [demoteAdministratorMutation] = useMutation<
    DemoteOrganizationAdministratorToMemberMutation,
    DemoteOrganizationAdministratorToMemberInput
  >(DEMOTE_ORGANIZATION_ADMINSTRATOR_TO_MEMBER);
  const demoteAdministrator = () => {
    setBusy(true);
    setStatusMessage(undefined);
    demoteAdministratorMutation({
      variables: { administratorId: memberId, organizationId: organization.id },
    })
      .then(
        ({
          data: { demoteOrganizationAdministratorToMember: response },
        }: any) => {
          if (response.success) {
            setStatusMessage(<SuccessMessage>Saved</SuccessMessage>);
          } else {
            setStatusMessage(
              <ErrorMessage>
                {response.errors.map((e: any) => `${e.message}`).join(',')}
              </ErrorMessage>
            );
          }
        }
      )
      .catch((err) => {
        setStatusMessage(<ErrorMessage>{err.message}</ErrorMessage>);
      })
      .finally(() => {
        setBusy(false);
      });
  };

  return (
    <>
      <p>
        Administrators can create reviews on behalf of {organization.name} and
        can manage people and permissions for all reviews.
      </p>
      <Box my={2}>
        <Checkbox
          checked={boxChecked}
          onChange={(e: any) => setBoxChecked(e.target.checked)}
          label={`Yes, make this person a ${organization.name} administrator`}
        />
      </Box>
      <SecondaryButton
        disabled={busy}
        onClick={boxChecked ? promoteMember : demoteAdministrator}
      >
        Save
      </SecondaryButton>
      {statusMessage}
    </>
  );
};

export default AdminStatus;
