import React from 'react';
import styles from './Subheading.module.css';
import { SubheadingBlock } from 'concepts/Extraction/Blocks/Subheading/SubheadingBlock';

export type SubheadingProps = {
  block: SubheadingBlock;
};

export default function Subheading(props: SubheadingProps): JSX.Element {
  return (
    <h2 aria-level={2} className={styles.Subheading}>
      {props.block.label}
    </h2>
  );
}
