import { CriteriaCategory } from 'types';

export const labels: Record<CriteriaCategory, string> = {
  population: 'Population',
  intervention_exposure: 'Intervention / Exposure',
  comparator_context: 'Comparator / Context',
  outcome: 'Outcome',
  study_characteristics: 'Study Characteristics',
  other: 'Other',
};
