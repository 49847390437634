import { Theme } from 'theme-ui';

/**
 * makeTheme is a type-narrowing constructor used to create a type shape that
 * reflects our specific theme shape and values.
 * We use this to declare the `Theme` type in webpack/types/emotion.d.ts so
 * that TypeScript knows the `theme` prop has specific keys and values and not
 * raise 'potentially undefined' type errors when using the theme prop.
 *
 * {@link https://theme-ui.com/guides/typescript/#exact-theme-type}
 */
export const makeTheme = <ExactTheme extends Theme>(
  theme: ExactTheme
): ExactTheme => theme;

/**
 * The following type-narrowing constructors perform an identical role
 * as makeTheme, but for individual scales so we can check the type-safety of
 * individual scales objects stored as variables before putting them together
 * into one big `theme` object.
 */
type MakeThemeScale<ThemeUiScale> = <
  ExactThemeScale extends NonNullable<ThemeUiScale>
>(
  themeScale: ExactThemeScale
) => ExactThemeScale;

const makeThemeScale: MakeThemeScale<unknown> = (themeScale) => themeScale;

export const makeThemeBordersScale: MakeThemeScale<
  Theme['borders']
> = makeThemeScale;

export const makeThemeBreakpointsScale: MakeThemeScale<
  Theme['breakpoints']
> = makeThemeScale;

export const makeThemeButtonsScale: MakeThemeScale<
  Theme['buttons']
> = makeThemeScale;

export const makeThemeColorsScale: MakeThemeScale<
  Theme['colors']
> = makeThemeScale;

export const makeThemeColorStylesScale: MakeThemeScale<
  Theme['colorStyles']
> = makeThemeScale;

export const makeThemeFontSizesScale: MakeThemeScale<
  Theme['fontSizes']
> = makeThemeScale;

export const makeThemeFontWeightsScale: MakeThemeScale<
  Theme['fontWeights']
> = makeThemeScale;

export const makeThemeTextStylesScale: MakeThemeScale<
  Theme['textStyles']
> = makeThemeScale;

export const makeThemeFormsScale: MakeThemeScale<
  Theme['forms']
> = makeThemeScale;

export const makeThemeLinksScale: MakeThemeScale<
  Theme['links']
> = makeThemeScale;

export const makeThemeShadowsScale: MakeThemeScale<
  Theme['shadows']
> = makeThemeScale;

export const makeThemeSizesScale: MakeThemeScale<
  Theme['sizes']
> = makeThemeScale;

export const makeThemeSpaceScale: MakeThemeScale<
  Theme['space']
> = makeThemeScale;
