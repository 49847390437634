import React, { useState } from 'react';
import styles from './PrismaActions.module.scss';
import { Button, Modal, ModalHeader, ModalBody, Text } from 'components/core';

interface Props {
  prismaChartAsText: string;
  downloadPrismaReportPath: string;
}

const PrismaActions = ({
  prismaChartAsText,
  downloadPrismaReportPath,
}: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <div className={styles.PrismaActions_wrapper}>
      <Button
        type="neutral"
        variant="outline"
        onClick={() => setIsModalOpen(true)}
      >
        View as text
      </Button>

      <Button type="brand" to={downloadPrismaReportPath}>
        Download DOCX
      </Button>

      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <ModalHeader>Export PRISMA data</ModalHeader>
        <ModalBody>
          <div className={styles.PrismaActions_modalContent}>
            <Text size="sm">Copy and paste from the text box below</Text>
            <textarea
              className={styles.PrismaActions_textarea}
              defaultValue={prismaChartAsText}
            />
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default PrismaActions;
