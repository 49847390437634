import React from 'react';
import { gql, useMutation } from '@apollo/client';
import { TertiaryButton } from 'system/base/Button';
import { RemoveNoteMutation } from 'types/graphql';

export const REMOVE_NOTE_MUTATION = gql`
  mutation RemoveNote($input: RemoveNoteInput!) {
    removeNote(input: $input) {
      success
      errors {
        message
      }
    }
  }
`;

export interface RemoveNoteControlProps {
  id: string;
  onSuccess: () => void;
  onFailure: () => void;
}

export default function RemoveNoteButton({
  id,
  onSuccess,
  onFailure,
}: RemoveNoteControlProps): JSX.Element {
  const [performRemoveNote] = useMutation<RemoveNoteMutation>(
    REMOVE_NOTE_MUTATION
  );

  return (
    <TertiaryButton
      onClick={() => {
        performRemoveNote({
          variables: {
            input: {
              noteId: id,
            },
          },
        })
          .then(onSuccess)
          .catch((e) => {
            MetaError.notify(e);
            onFailure;
          });
      }}
    >
      Delete note
    </TertiaryButton>
  );
}
