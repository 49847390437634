import { gql } from '@apollo/client';
import { ID, Email } from 'util/types';
import { MembershipStatus } from 'schema/enums';

export interface ReviewsFragmentResult {
  reviews: {
    pageInfo: {
      hasNextPage: boolean;
      endCursor: string;
    };
    nodes: {
      id: ID;
      title: string;
      lastActivityAt: string; // boo
    }[];
  };
}

export const REVIEWS_FRAGMENT = gql`
  fragment Reviews on Membership {
    reviews: organizationReviews(first: 10, after: $after) {
      pageInfo {
        hasNextPage
        endCursor
      }

      nodes {
        id
        title

        # NOTE: visitors to this page might expect that this shows the time the _current
        # user_ was active on this review, which is not the case I think...
        lastActivityAt
      }
    }
  }
`;

export interface ReviewsQueryResult {
  author: {
    id: ID;
    membership: ReviewsFragmentResult;
  };
}

export const GET_REVIEWS = gql`
  query GetReviews($authorId: ID!, $organizationId: ID!, $after: String) {
    author: node(id: $authorId) {
      id
      ... on User {
        membership: organizationMembership(organization: $organizationId) {
          id
          ...Reviews
        }
      }
    }
  }
  ${REVIEWS_FRAGMENT}
`;

export interface AssignedReviewsFragmentResult {
  assignedReviews: {
    pageInfo: {
      hasNextPage: boolean;
      endCursor: string;
    };
    nodes: {
      id: ID;
      title: string;
      lastActivityAt: string; // boo
    }[];
  };
}

export const ASSIGNED_REVIEWS_FRAGMENT = gql`
  fragment AssignedReviews on Membership {
    assignedReviews(first: 10, after: $after) {
      pageInfo {
        hasNextPage
        endCursor
      }

      nodes {
        id
        title

        # NOTE: visitors to this page might expect that this shows the time the _current
        # user_ was active on this review, which is not the case I think...
        lastActivityAt
      }
    }
  }
`;

export interface AssignedReviewsQueryResult {
  author: {
    id: ID;
    membership: AssignedReviewsFragmentResult;
  };
}

export const GET_ASSIGNED_REVIEWS = gql`
  query GetAssignedReviews(
    $authorId: ID!
    $organizationId: ID!
    $after: String
  ) {
    author: node(id: $authorId) {
      id
      ... on User {
        membership: organizationMembership(organization: $organizationId) {
          id
          ...AssignedReviews
        }
      }
    }
  }
  ${ASSIGNED_REVIEWS_FRAGMENT}
`;

interface Invitation {
  id: ID;
  email: Email;
  expiresAt: string;
}

interface Membership
  extends ReviewsFragmentResult,
    AssignedReviewsFragmentResult {
  id: ID;
  status: MembershipStatus;
  invitation: Invitation;
}

export interface AuthorQueryResult {
  me: {
    id: ID;
  };
  author: {
    id: ID;
    name: string;
    email: Email;
    membership: Membership;
  };
  organization: {
    id: ID;
    name: string;
  };
}

export const GET_AUTHOR = gql`
  query GetAuthor($authorId: ID!, $organizationId: ID!, $after: String) {
    me {
      id
    }

    author: node(id: $authorId) {
      id
      ... on User {
        name
        email: primaryEmail

        membership: organizationMembership(organization: $organizationId) {
          id
          status
          invitation {
            id
            email
            expiresAt
          }
          ...Reviews
          ...AssignedReviews
        }
      }
    }
    organization: node(id: $organizationId) {
      id
      ... on Organization {
        name
      }
    }
  }
  ${REVIEWS_FRAGMENT}
  ${ASSIGNED_REVIEWS_FRAGMENT}
`;
