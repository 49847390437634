import { renderVariant, CovidenceLinkProps } from './linkVariants';

export const Link = (props: CovidenceLinkProps) => renderVariant(props, 'Flat');

export const PrimaryLink = (props: CovidenceLinkProps) =>
  renderVariant(props, 'Primary');

export const SecondaryLink = (props: CovidenceLinkProps) =>
  renderVariant(props, 'Secondary');

export const TertiaryLink = (props: CovidenceLinkProps) =>
  renderVariant(props, 'Tertiary');

export const CochraneLink = (props: CovidenceLinkProps) =>
  renderVariant(props, 'Cochrane');

export const GhostLink = (props: CovidenceLinkProps) =>
  renderVariant(props, 'Ghost');
