import React from 'react';
import { gql } from '@apollo/client';
import InviteForm from './InviteForm';
import Invitations from './Invitations';
import { ID } from 'util/types';

interface Props {
  organizationId: ID;
}

const GET_INVITATIONS = gql`
  query Invitations($organizationId: ID!, $after: String) {
    organization: node(id: $organizationId) {
      ... on Organization {
        invitations(first: 25, after: $after) {
          pageInfo {
            hasNextPage
            endCursor
          }

          nodes {
            id
            name
            email
            expiresAt
          }
        }
      }
    }
  }
`;
const ManageInvitations = ({ organizationId }: Props) => (
  <>
    <InviteForm organizationId={organizationId} query={GET_INVITATIONS} />
    <h3>Pending invites</h3>
    <Invitations organizationId={organizationId} query={GET_INVITATIONS} />
  </>
);

export default ManageInvitations;
