import {
  getFullyUnresolvedTable,
  getResolvedCellData,
} from '../../Blocks/Table/utils/resolution';
import { TableResolutionState } from './types';
import { updateTableResolutionState } from './updateTableResolutionState';
import { TableData } from 'concepts/Extraction/Blocks/Table/BaseTable/types';

type GetTableResolvedStateProps = {
  rowIDs: string[];
  columnIDs: string[];
  reviewerTables: TableData[];
};

const emptyTableResolutionState: TableResolutionState = {
  resolvedCellData: [],
  unresolvedCellIDs: [],
};

/**
 * Run on consensus page render to identify the resolved & unresolved cells
 * of table blocks.
 */
export const getInitialTableResolutionState = ({
  rowIDs,
  columnIDs,
  reviewerTables,
}: GetTableResolvedStateProps): TableResolutionState => {
  /**
   * If one or more reviewer tables are empty, all cells need to be resolved.
   */
  const isSomeTableEmpty = reviewerTables.some(
    (table) => table === null || table.length === 0
  );

  if (reviewerTables.length === 0 || isSomeTableEmpty) {
    return {
      ...emptyTableResolutionState,
      unresolvedCellIDs: getFullyUnresolvedTable(rowIDs, columnIDs),
    };
  }

  /**
   * Compare each cell & if resolved, add cell data to resolvedTableCells, else
   * add the cell rowID & columnID to unresolvedTableCellIDs.
   */
  const tableResolutionStateAfterResolvingAllCells = rowIDs.reduce(
    (resolutionStateAtPriorRow, rowID, rowIndex) =>
      columnIDs.reduce((resolutionStateAtPriorColumn, columnID) => {
        const resolvedCellData = getResolvedCellData({
          rowID,
          columnID,
          reviewerTables,
        });

        const newTableResolutionState = updateTableResolutionState(
          resolutionStateAtPriorColumn,
          {
            rowID,
            columnID,
            rowIndex,
          },
          resolvedCellData ?? null
        );

        return newTableResolutionState;
      }, resolutionStateAtPriorRow),
    emptyTableResolutionState
  );

  return tableResolutionStateAfterResolvingAllCells;
};
