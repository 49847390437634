import React, {
  useCallback,
  useState,
  useRef,
  FocusEvent,
  ChangeEvent,
  useMemo,
} from 'react';
import { Control, useController } from 'react-hook-form';
import styles from './BulletedTextarea.module.scss';
import { textToArray } from './lib/textToArray';
import { removeBulletsFromStart } from './lib/removeBulletsFromStart';

type BulletedTextareaProps = {
  onChange: (value: string[]) => void;
  onBlur: (value: string[]) => void;
  value: string[];
  ariaLabelledBy: string;
};

/*
This component visually looks and behaves like a textarea
Unlike a textarea each new line appears as a bullet point
It accepts an array of strings that represent the bullets
*/
export const BulletedTextarea = ({
  onChange,
  onBlur,
  value = [],
  ariaLabelledBy,
}: BulletedTextareaProps) => {
  const classNames = [styles.textarea];
  const [hasValues, setHasValues] = useState<boolean>(value.some(Boolean));
  const ref = useRef<HTMLDivElement>(null);

  // We don't want the default value to change as it causes the contenteditable element to rerender
  // On rerender the element loses focus and the user is unable to continue typing
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const defaultValue = useMemo(() => value, []);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLDivElement>) => {
      e.preventDefault();

      const items = textToArray(e.target.innerText);
      onChange(items);
      setHasValues(items.some(Boolean));
    },
    [onChange]
  );

  const handleBlur = useCallback(
    (e: FocusEvent<HTMLDivElement>) => {
      e.preventDefault();
      const items = textToArray(e.target.innerText);
      onBlur(items);
    },
    [onBlur]
  );

  const handlePaste = useCallback((e) => {
    // Prevent paste
    e.preventDefault();

    // Grab only text, ignoring any html elements
    const clipboardText = e.clipboardData.getData('Text');
    // Convert text into an array using linebreaks, this represents our bullet item rows
    const wrappedText = textToArray(clipboardText)
      .map((text) => removeBulletsFromStart(text))
      .map((text, index) => {
        // Each bullet item row is wrapped in a div. We want to add the first item in the array to the current row
        // This row may be empty or have text. We want to append text to the first row, not paste it into a new row
        // The subsequent items will be wrapped in a div, so that they appear as individual rows
        if (index === 0) {
          return text;
        }

        // Wrap text in a div so they appear on a new line and render as seperate rows
        return `<div>${text}</div>\n`;
      })
      // possible to check if cursor at start or past content, if new wrap in div
      // .map((text) => `<div>${text}</div>\n`)
      .join('');

    // Execute paste
    window.document.execCommand('insertHTML', false, wrappedText);
  }, []);

  const handleKeyDown = useCallback((e) => {
    // Not supporting shift+enter to drop to a new line without creating a bullet point as its hard to do
    // Instead we're forcing a new bullet to appear. So there are no surprises on save for the user.
    // Otherwise when form saves the shift+enter line is joined to parent as we split on new lines
    if (e.keyCode === 13 && e.shiftKey === true) {
      e.preventDefault();
      window.document.execCommand('insertHTML', false, '\n<div></div>');
    }
  }, []);

  const defaultHTML = useCallback(
    (bullets: string[]) => {
      // When structuring it like this, the first element wont be deletable
      const content = bullets.length
        ? bullets.map((item) => `<div>${item}</div>`).join('')
        : '<div></div>';

      return `<div contenteditable="true" role="textbox" aria-labelledby="${ariaLabelledBy}" data-testid="${ariaLabelledBy}">
        ${content}
      </div>`;
    },
    [ariaLabelledBy]
  );

  if (!hasValues) {
    classNames.push(styles.isEmpty);
  }

  return (
    // Disabling this rule as child elements that contentEditable creates are focusable
    // If you add a tabIndex you'll be able to keyboard navigate and focus on this element, which we dont want as you can't type in it
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      ref={ref}
      className={classNames.join(' ')}
      onInput={handleChange}
      onBlur={handleBlur}
      onPaste={handlePaste}
      onKeyDown={handleKeyDown}
      dangerouslySetInnerHTML={{
        // Not sanitizing this again as its sanitized on the backend and we trust this data
        __html: defaultHTML(defaultValue),
      }}
    ></div>
  );
};

type BulletedTextareaWrappedProps = {
  name: string;
  ariaLabelledBy: string;
  control: Control;
};

// Wraps above component to make it easier to use within react-hook-form
const BulletedTextareaWrapped = ({
  ariaLabelledBy,
  control,
  name,
}: BulletedTextareaWrappedProps) => {
  const { field } = useController({
    name,
    control,
  });

  return (
    <BulletedTextarea
      ariaLabelledBy={ariaLabelledBy}
      onChange={field.onChange}
      onBlur={field.onBlur}
      value={field.value}
    />
  );
};

export default BulletedTextareaWrapped;
