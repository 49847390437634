import React from 'react';

import { gql, useMutation } from '@apollo/client';

import { Study } from './StudyTypes';
import { PrimaryButton } from 'system/base/Button';

import { MergeStudiesMutation } from 'types/graphql';

export const MERGE_STUDIES_MUTATION = gql`
  mutation MergeStudies($input: MergeStudiesInput!) {
    mergeStudies(input: $input) {
      success
      errors {
        message
      }
    }
  }
`;

export interface StudyListControlsProps {
  selectedStudyIDs: Array<Study['id']>;
  desiredMasterStudyId: Study['id'];
  disabled: boolean;
  onStart: () => void;
  onSuccess: () => void;
  onFailure: () => void;
}

export default function MergeStudiesButton({
  selectedStudyIDs,
  desiredMasterStudyId,
  disabled,
  onStart,
  onSuccess,
  onFailure,
}: StudyListControlsProps): JSX.Element {
  const [performMergeStudies] = useMutation<MergeStudiesMutation>(
    MERGE_STUDIES_MUTATION
  );

  return (
    <PrimaryButton
      disabled={disabled}
      onClick={() => {
        onStart();
        performMergeStudies({
          variables: {
            input: {
              studyIds: selectedStudyIDs,
              desiredMasterStudyId: desiredMasterStudyId,
            },
          },
        })
          .then(onSuccess)
          .catch(onFailure);
      }}
    >
      Set Study ID
    </PrimaryButton>
  );
}
