import Accordion from './Accordion';
import AccordionContent from './components/AccordionContent';
import AccordionControls from './components/AccordionControls';
import AccordionGroup from './components/AccordionGroup';
import AccordionHeader from './components/AccordionHeader';
import {
  AccordionEventHandler,
  useAccordionController,
  AccordionEventContext,
} from './AccordionEventHandler';

export const AccordionEvent = {
  OPEN: Symbol('Accordion.Open'),
  CLOSE: Symbol('Accordion.Close'),
  SET_OPEN: Symbol('Accordion.Set_Open'),
  IS_OPEN: Symbol('Accordion.Is_Open'),
};

export interface AccordionSubscriber {
  element: any;
  reducer: (type: symbol, value?: boolean, noAnimate?: boolean) => boolean;
}

export {
  Accordion,
  AccordionContent,
  AccordionControls,
  AccordionGroup,
  AccordionHeader,
  AccordionEventHandler,
  AccordionEventContext,
  useAccordionController,
};
