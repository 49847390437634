import { REVIEWER_FEATURE_FLAG_ROUTE } from 'query/routes';

export const getReviewerFeatures = async (): Promise<{
  features: string[];
}> => {
  const response = await fetch(REVIEWER_FEATURE_FLAG_ROUTE, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });

  return await response.json();
};
