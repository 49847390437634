import { useTable } from 'react-table';
import React, { useMemo } from 'react';
import { Table2BlockValue } from '../../types/block-value';
import { Table2Headers, Table2Structure } from '../../types/block';
import styles from './BaseTable.module.css';
import { transformColumnsWithRowLabels, transformTableData } from './utils';
import { RowData } from './types';

export interface BaseTableProps
  extends React.ComponentPropsWithoutRef<'table'> {
  headers: Table2Headers;
  structure: Table2Structure;
  value?: Table2BlockValue; // aka data
}

// BaseTable is a generic abstraction that transforms our table data into a
// format that can be rendered by the react-table API.
export const BaseTable = ({
  headers,
  structure,
  value,
  ...tableProps
}: BaseTableProps) => {
  const memoizedColumns = useMemo(
    () => transformColumnsWithRowLabels({ headers, columns: structure.cols }),
    [headers, structure.cols]
  );

  const memoizedData = useMemo(
    () =>
      transformTableData({
        headers,
        rowIDs: structure.rows,
        columnIDs: structure.cols,
        value,
      }),
    [headers, structure, value]
  );

  /**
   * @remarks
   * rowHeaders & columnHeaders are only needed as custom useTable props
   * to create accessible labels by EditableCells.
   */
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable<RowData>({
    columns: memoizedColumns,
    data: memoizedData,
  });

  return (
    <table className={styles.table} {...tableProps} {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          /**
           * @remark
           * The eslint react/jsx-key rule is disabled because eslint does not
           * recognize that getHeaderGroupProps() includes jsx keys.
           */
          /* eslint-disable react/jsx-key */
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(({ getHeaderProps, render }) => {
              const headerNode = render('Header');

              return headerNode ? (
                <th className={styles.header} scope="col" {...getHeaderProps()}>
                  {headerNode}
                </th>
              ) : (
                <td className={styles.header} {...getHeaderProps()}>
                  {headerNode}
                </td>
              );
            })}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(({ column, render, getCellProps }) =>
                column.id === 'rowLabel' ? (
                  <th
                    className={styles.header}
                    scope="row"
                    {...getCellProps()}
                    role="rowheader"
                  >
                    {render('Cell')}
                  </th>
                ) : (
                  <td {...getCellProps()}>{render('Cell')}</td>
                )
              )}
            </tr>
            /* eslint-enable react/jsx-key */
          );
        })}
      </tbody>
    </table>
  );
};
