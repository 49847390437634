import React from 'react';
import { Study } from './StudyTypes';
import styles from './StudyTags.module.css';
import { NakedButton } from 'system/base/Button';

import Icon from 'system/base/Icon';
import { removeTagFromStudies } from 'legacyBridge/bulkTagging';

export interface RemoveTagsToStudyControlProps {
  study: Study;
  tag: string;
  onSuccess: () => void;
  onFailure?: () => void;
}

export default function RemoveTagsButton({
  study,
  tag,
  onSuccess,
  onFailure,
}: RemoveTagsToStudyControlProps): JSX.Element {
  return (
    <NakedButton
      ml={1}
      className={styles.removeButton}
      data-testid={`removeTagButton_${tag}`}
      onClick={() => {
        removeTagFromStudies(tag, [study.id])
          .then(onSuccess)
          .catch((e: any) => {
            MetaError.notify(e);
            onFailure;
          });
      }}
    >
      <Icon name="backspace" />
    </NakedButton>
  );
}
