import { ID } from './types';
import { VoteValue } from 'types/graphql';

export { VoteValue };

export interface VoteAction {
  id: number;
  studyId: ID;
  value: VoteValue;
}

export const isUndoVoteAction = (vote: VoteAction) =>
  [VoteValue.UndoCategory, VoteValue.UndoVote].includes(vote.value);

// return unique study ids which have been voted upon, discounting those studies
// which have had their most recent votes "undone"
export function voteActionsToStudyIds(actions: Array<VoteAction>) {
  const studyIds: Array<ID> = [];

  for (const action of actions) {
    const studyIdIndex = studyIds.indexOf(action.studyId);

    if (isUndoVoteAction(action) && studyIdIndex !== -1) {
      // if it's an undo vote and we have a past vote for this study
      studyIds.splice(studyIdIndex, 1);
    } else if (!isUndoVoteAction(action) && studyIdIndex === -1) {
      // otherwise, if it's not an undo vote and isn't already in the array
      studyIds.push(action.studyId);
    }
  }

  return studyIds;
}

export function clearLegacyLocalStorage() {
  if (typeof window !== 'object' || !window.localStorage) return;

  const MATCH_LEGACY_KEY = /^\d+_(e|Votes)(\d+|_dirty|)$/;

  try {
    Object.keys(window.localStorage)
      .filter((key) => key.match(MATCH_LEGACY_KEY))
      .forEach((key) => {
        window.localStorage.removeItem(key);
      });
    // eslint-disable-next-line no-empty
  } catch {}
}
