import React from 'react';
import styles from '../../../Menu.module.scss';

interface Props {
  className?: string;
  children: React.ReactNode;
}

const MenuItemContainer = ({ className, children }: Props) => {
  const classes = [styles.MenuItem];
  if (className) classes.push(className);

  return (
    <li role="menuitem" className={classes.join(' ')}>
      {children}
    </li>
  );
};

export default MenuItemContainer;
