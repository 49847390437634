import React from 'react';
import { DropdownSelectorProps, DropdownSelector } from './DropdownSelector';

type ReviewTypeValue =
  | 'systematic_review'
  | 'scoping_review'
  | 'rapid_review'
  | 'umbrella_review'
  | 'literature_review'
  | 'other';

export type ReviewTypeOption = { value: ReviewTypeValue; label: string };

export const reviewTypeOptions: ReviewTypeOption[] = [
  { value: 'systematic_review', label: 'Systematic review' },
  { value: 'scoping_review', label: 'Scoping review' },
  { value: 'rapid_review', label: 'Rapid review' },
  { value: 'umbrella_review', label: 'Umbrella review' },
  { value: 'literature_review', label: 'Literature review' },
  { value: 'other', label: 'Other' },
];

export interface ReviewTypeSelectorProps {
  selectedType: DropdownSelectorProps<ReviewTypeOption>['selectedOption'];
  setType: React.Dispatch<React.SetStateAction<ReviewTypeOption | null>>;
  otherTypeLabel: string | null;
  setOtherTypeLabel: React.Dispatch<React.SetStateAction<string | null>>;
}

export const ReviewTypeSelector = ({
  selectedType,
  setType,
  otherTypeLabel,
  setOtherTypeLabel,
  ...otherProps
}: ReviewTypeSelectorProps) => (
  <DropdownSelector<ReviewTypeOption>
    options={reviewTypeOptions}
    selectedOption={selectedType}
    setOption={setType}
    name="review-type"
    label="Review type"
    missingError="Please select the type of review"
    otherLabel={{
      value: otherTypeLabel,
      setOtherLabel: setOtherTypeLabel,
      label: 'Please specify the type of review:',
      missingError: 'Please name the type of review',
    }}
    {...otherProps}
  />
);
