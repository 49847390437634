import React, { useState } from 'react';
import styles from './TopicExperts.module.css';
import { Topic } from 'types/graphql';
import { TextField } from 'system/elements';
import { PrimaryButton } from 'system/base/Button';
import LoadingSpinner from 'system/elements/Spinner';
import ErrorMessage from 'components/ErrorMessage';
import SuccessMessage from 'components/SuccessMessage';

export interface onChangeProps {
  topicId: string;
  isSelected: boolean;
}
export interface TopicCheckboxProps {
  topic: Topic;
  isSelected: boolean;
  onChange: (props: onChangeProps) => void;
}

export interface SendInviteVariables {
  name: string;
  email: string;
  topicIds: string[];
}

export interface InviteFormProps {
  sendInvite(variables: SendInviteVariables): Promise<void>;
  loading: boolean;
  errorMessage?: string;
  successMessage?: string;
  topics: Topic[];
}

export function InviteForm({
  sendInvite,
  loading,
  successMessage,
  errorMessage,
  topics,
}: InviteFormProps): JSX.Element {
  const [inviteName, setInviteName] = useState('');
  const [inviteEmail, setInviteEmail] = useState('');
  const [validationMessage, setValidationMessage] = useState<string>();
  const [selectedTopics, setSelectedTopics] = useState<string[]>([]);
  const [sending, setSending] = useState<boolean>(false);

  const handleSendInvite = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!inviteName) {
      setValidationMessage('Please enter a name');
    } else if (!inviteEmail) {
      setValidationMessage('Please enter an email address');
    } else if (selectedTopics.length === 0) {
      setValidationMessage('Please select at least one topic');
    } else {
      setSending(true);
      sendInvite({
        name: inviteName,
        email: inviteEmail,
        topicIds: selectedTopics,
      }).finally(() => {
        setSending(false);
        setInviteName('');
        setInviteEmail('');
        setSelectedTopics([]);
      });
    }
  };

  const renderTopics = () => {
    return (
      <div data-testid="invite-topics">
        {topics.map((topic) => {
          const isSelected = selectedTopics.includes(topic.id);

          const onChange = ({ topicId, isSelected }: onChangeProps) => {
            let newSelectedTopics;
            if (isSelected) {
              newSelectedTopics = [...selectedTopics];
              if (!selectedTopics.includes(topicId)) {
                newSelectedTopics.push(topicId);
              }
            } else {
              newSelectedTopics = selectedTopics.filter((id) => id !== topicId);
            }

            setSelectedTopics(newSelectedTopics);
          };
          return topicsCheckbox({ topic, isSelected, onChange });
        })}
      </div>
    );
  };

  const topicsCheckbox = ({
    topic,
    isSelected,
    onChange,
  }: TopicCheckboxProps): JSX.Element => {
    return (
      <label className={styles.topicSelect} htmlFor={topic.id} key={topic.id}>
        <input
          type="checkbox"
          checked={isSelected}
          id={topic.id}
          data-testid={topic.id}
          style={{ marginRight: '5px', top: '1px', position: 'relative' }}
          onChange={(e: any) =>
            onChange({
              topicId: topic.id,
              isSelected: e.target.checked,
            })
          }
        />
        {topic.name}
      </label>
    );
  };

  const visibleError = validationMessage || errorMessage;

  return (
    <section
      className={styles.container}
      data-testid="topic-expert-invite-form"
    >
      <h1 className={styles.title}>Manage invitations</h1>
      {(visibleError || successMessage) && (
        <div style={{ marginTop: '10px' }}>
          {visibleError && <ErrorMessage>{visibleError}</ErrorMessage>}
          {!visibleError && successMessage && (
            <SuccessMessage>
              <span className="notice alert">{successMessage}</span>
            </SuccessMessage>
          )}
        </div>
      )}

      <form
        className={styles.createCommentForm}
        onSubmit={handleSendInvite}
        style={{ marginBottom: '30px', marginTop: '30px' }}
      >
        <h2 className={styles.subtitle}>Invite a topic expert</h2>
        <p className={styles.subtleDescriptor}>
          Invitees will receive an email with a link so they can sign up to
          access all PICOs in the selected topics.
        </p>

        <div style={{ marginBottom: '10px' }}>
          <TextField
            label="Name"
            value={inviteName}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setInviteName(e.target.value)
            }
          />
          <TextField
            label="Email"
            value={inviteEmail}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setInviteEmail(e.target.value)
            }
          />
        </div>

        <label htmlFor="invite-topics" className={styles.inviteTopics}>
          <span>Topics</span>
          {loading ? <LoadingSpinner /> : renderTopics()}
          <p className={styles.subtleDescriptor}>
            The person will be able to see and provide input only on the
            selected topics.
          </p>
        </label>
        {sending ? (
          <LoadingSpinner />
        ) : (
          <PrimaryButton data-testid="submit-link" type="submit">
            Send invite
          </PrimaryButton>
        )}
      </form>
    </section>
  );
}
