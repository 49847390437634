import React, { ChangeEvent, useState } from 'react';
import styles from './SelectAllStudies.module.scss';
import {
  Checkbox,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from 'components/core';

type Props = {
  handleStudySelection: (event: ChangeEvent<HTMLInputElement>) => void;
  isSelected: boolean;
  isDataExtraction2?: boolean;
};

const SelectAllStudies = ({
  handleStudySelection,
  isSelected,
  isDataExtraction2,
}: Props) => {
  const [isChecked, setIsChecked] = useState(false);
  const labelText = isChecked ? 'Deselect all' : 'Select all';

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.currentTarget.checked);
    handleStudySelection(event);
  };

  return (
    <Tooltip placement="bottom">
      <TooltipTrigger>
        {isDataExtraction2 ? (
          // For DE2, use Design System Checkbox to align with study list items,
          // which are using the same checkbox.
          <Checkbox
            size="md"
            className="select-all"
            aria-label="Select All"
            checked={isSelected}
            onChange={handleChange}
          >
            All
          </Checkbox>
        ) : (
          // For all other study lists, use the browser checkboxes to keep them
          // consistent with the study list items, which are using browser
          // checkboxes.
          // Delete this section and the dataExtraction2 flag once all
          // study lists use the Design System Checkbox.
          <div className={styles.SelectAllStudies}>
            <label className="select-all">
              <input
                type="checkbox"
                className={`${styles.checkbox}`}
                aria-label="Select All"
                checked={isSelected}
                onChange={handleChange}
              ></input>
              <span>All</span>
            </label>
          </div>
        )}
      </TooltipTrigger>
      <TooltipContent>{labelText}</TooltipContent>
    </Tooltip>
  );
};

export default SelectAllStudies;
