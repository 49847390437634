import bullets from './bullets';

export const removeBulletsFromStart = (string = '') => {
  const firstLetter = string.match('[a-zA-Z0-9]')?.pop();

  if (!firstLetter) {
    return string;
  }

  const firstLetterIndex = string.indexOf(firstLetter);

  if (firstLetterIndex === 0) {
    return string;
  }

  // Remove bullets if they appears at start of string
  const startOfString = string.slice(0, firstLetterIndex);
  const containsBullet = bullets.some((bullet) =>
    startOfString.includes(bullet)
  );

  if (containsBullet) {
    return string.substring(firstLetterIndex);
  }

  return string;
};
