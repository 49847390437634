/* eslint-disable react/prop-types */
import React, { FunctionComponent } from 'react';
import flattenChildren from 'react-keyed-flatten-children';

interface StyledChildrenProps {
  // StyledChildren will pass down its className to each child
  className?: string;
}

const getClassName = (element: React.ReactElement) =>
  (element.props && element.props.className) || '';
const classnames = (...args: (string | undefined)[]) => args.join(' ');

const StyledChildren: FunctionComponent<StyledChildrenProps> = ({
  children,
  className,
}) => (
  <>
    {flattenChildren(children).map((child) =>
      React.isValidElement(child)
        ? React.cloneElement<{ className?: string }>(child, {
            className: classnames(getClassName(child), className),
          })
        : child
    )}
  </>
);

export default StyledChildren;
