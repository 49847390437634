import React, { useState } from 'react';
import styles from './TopicFilter.module.css';
import { SecondaryLink } from 'system/base/Link';
import { Filters } from 'containers/Guidelines/Triage/FiltersModel';

export interface TopicFilterProps {
  filters: Filters;
}

export function TopicFilter({
  filters: { topicId: defaultTopicId, topicFilters },
}: TopicFilterProps): JSX.Element {
  const [topicId, setTopicId] = useState(defaultTopicId);

  const topicIdSelected = (e: React.FormEvent<HTMLSelectElement>) => {
    setTopicId(Number(e.currentTarget.value));
  };

  return (
    <div className={styles.filter}>
      <select
        data-testid="topic-select"
        className={styles.triageInput}
        onBlur={topicIdSelected}
        defaultValue={topicId}
      >
        {topicFilters.map((topicFilter) => (
          <option key={topicFilter.id} value={topicFilter.id}>
            {topicFilter.name} ({topicFilter.count})
          </option>
        ))}
      </select>
      <SecondaryLink
        data-testid="topic-filter-button"
        href={`?topic_id=${topicId}`}
      >
        Filter
      </SecondaryLink>
    </div>
  );
}
