import React from 'react';
import { Link } from '../../../system/base/Link';
import Icon, { IconStyle } from '../../../system/base/Icon';
import styles from './Banner.module.css';

const Banner = () => (
  <section className={styles.Banner}>
    <Icon
      className={styles.Banner__infoCircleIcon}
      name="info-circle"
      iconStyle={IconStyle.Regular}
    />
    Use your reference manager (e.g. EndNote, Zotero) to bulk upload full text.
    <Link
      href="https://support.covidence.org/help/bulk-pdf-import"
      target="_blank"
      rel="noopener noreferrer"
    >
      Learn more about bulk upload
      <Icon
        className={styles.Banner__externalLink}
        name="external-link"
        iconStyle={IconStyle.Solid}
      />
    </Link>
  </section>
);

export default Banner;
