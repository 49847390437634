import React from 'react';
import ActivityDetailBox from '../ActivityDetailBox';
import { Text } from 'components/core';
import { AutoExclusionBatch } from 'query/review/AutoExclusion';

type BatchInProgressProps = {
  batch?: AutoExclusionBatch | null;
};

const BatchInProgress = ({ batch }: BatchInProgressProps) => {
  if (!batch) return null;

  if (batch?.citation_import_id) {
    return (
      <ActivityDetailBox
        isActive
        status="Auto-marking ineligible references..."
      >
        <Text variant="weak">
          Classifiers are checking new references recently imported.
        </Text>
        <Text variant="weak">
          This can take a while, depending on the number of references that have
          no manual votes.
        </Text>
      </ActivityDetailBox>
    );
  }

  return (
    <ActivityDetailBox isActive status="Auto-marking ineligible references...">
      <Text variant="weak">
        Classifiers are checking existing references because of recent changes
        to your review settings.
      </Text>
      <Text variant="weak">
        This can take a while, depending on the number of references that have
        no manual votes.
      </Text>
    </ActivityDetailBox>
  );
};

export default BatchInProgress;
