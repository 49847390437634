import React from 'react';
import styles from '../ExpiredPlanSelectionModal.module.scss';
import { Button, Heading, Text } from 'components/core';

interface PlanOptionSelectSectionProps {
  plan: any;
}

export const PlanOptionSelectSection = ({
  plan,
}: PlanOptionSelectSectionProps) => {
  return (
    <div className={styles.PlanOption_plan_select_section}>
      <Heading type="h4" component="h3" className={styles.PlanOption_plan_name}>
        {plan.name}
      </Heading>
      <Text size="sm" className={styles.PlanOption_plan_name_subheading}>
        <b>${(plan.price_cents / 100).toString()}&nbsp;USD</b>
        <span className={styles.ExpiredPlanSelectionModal_subtle}>
          &nbsp;/{plan.billing_period_unit}
        </span>
      </Text>
      <Button
        type="success"
        variant="solid"
        to={`/plans/${plan.id}?ref=select_plans_modal`}
      >
        Choose plan
      </Button>
    </div>
  );
};
