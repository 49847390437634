import React, { FormEvent, useState } from 'react';
import { Box } from 'theme-ui';

import { PrimaryButton } from 'system/base/Button';
import Dialog from 'system/base/Dialog';
import { TextField } from 'system/elements';

const InviteNewReviewer = ({
  inviteReviewer,
  reviewerCapReached,
}: {
  inviteReviewer: (name: string, email: string) => void;
  reviewerCapReached: boolean;
}) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');

  const invite = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    inviteReviewer(name, email);
    setShowModal(false);
  };

  return (
    <div>
      <PrimaryButton
        disabled={reviewerCapReached}
        onClick={() => setShowModal(true)}
      >
        Invite another reviewer
      </PrimaryButton>
      <Dialog
        isOpen={showModal}
        title="Invite a new reviewer"
        onDismiss={() => setShowModal(false)}
      >
        <form onSubmit={invite}>
          <Box mb={3}>
            <Box mb={3}>
              <TextField
                label="First name"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setName(e.target.value)
                }
              />
            </Box>
            <TextField
              label="Email"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setEmail(e.target.value)
              }
            />
          </Box>
          <PrimaryButton type="submit">Invite</PrimaryButton>
        </form>
      </Dialog>
    </div>
  );
};

export default InviteNewReviewer;
