import React from 'react';
import EditableField from '../EditableField';
import {
  NO_EQUIVALENT_VALUE,
  validate,
} from '../../../components/editableFieldValidation';
import resultDataStyles from './ResultsDataPanel.module.scss';
import {
  newContinuousDataFormats,
  newDichotomousDataFormats,
} from './ResultDataFormats';
import {
  DataExtractionTemplateResultType,
  OutcomeTypes,
} from 'types/DataExtraction';
import { Text } from 'components/core';

interface Props {
  index: number;
  resultType: DataExtractionTemplateResultType;
  onDelete: () => void;
}
const CustomResultTypeEditor = ({ index, resultType, onDelete }: Props) => {
  const standardContinuousNames = newContinuousDataFormats.map(
    (format) => format.name
  );
  const standardDichotomousNames = newDichotomousDataFormats.map(
    (format) => format.name
  );

  const restrictedStrings =
    resultType.outcome_type == OutcomeTypes.CONTINUOUS
      ? standardContinuousNames
      : standardDichotomousNames;

  return (
    <div className={resultDataStyles.editor}>
      <EditableField
        key={resultType.id}
        name="custom_result_types"
        attribute="label"
        index={index}
        canDelete={!resultType.used}
        disabled={resultType.used}
        onDelete={onDelete}
        validate={(label, resultTypes) =>
          validate(
            label,
            resultTypes.filter(
              (type: any) => type.outcome_type == resultType.outcome_type
            ),
            restrictedStrings,
            NO_EQUIVALENT_VALUE,
            255
          )
        }
      />
      {resultType.used && (
        <Text size="xs" variant="weak">
          Field cannot be edited as data has already been extracted
        </Text>
      )}
    </div>
  );
};

export default CustomResultTypeEditor;
