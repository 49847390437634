import { gql } from '@apollo/client';

const ADMINISTRATOR_FRAGMENT = gql`
  fragment Administrator on User {
    id
    name
    email: primaryEmail
  }
`;

const GET_ADMINISTRATORS = gql`
  query Administrators($organizationId: ID!) {
    organization: node(id: $organizationId) {
      ... on Organization {
        administrators(sort: NAME) {
          nodes {
            ...Administrator
          }
        }
      }
    }
  }
  ${ADMINISTRATOR_FRAGMENT}
`;

export default GET_ADMINISTRATORS;
