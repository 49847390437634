/** @jsxImportSource theme-ui */
import React, { forwardRef, ReactNode } from 'react';
import { Box } from 'theme-ui';

type PopoverTriggerProps = {
  'aria-label'?: string;
  children: ReactNode;
};

/**
 * Currently necessary to forwardRef.
 * https://react-typescript-cheatsheet.netlify.app/docs/advanced/patterns_by_usecase/#wrappingmirroring-a-html-element
 */
export const PopoverTrigger = forwardRef<HTMLDivElement, PopoverTriggerProps>(
  ({ children, 'aria-label': ariaLabel, ...rest }, ref) => (
    <Box
      ref={ref}
      role="tooltip"
      aria-label={ariaLabel}
      sx={{
        display: 'block',
        cursor: 'auto',
        pointerEvent: 'none',
      }}
      onClick={(e) => {
        /**
         * CompareButton onClick behavior should not activate when
         * CommentPopover is clicked.
         */
        e.stopPropagation();
      }}
      {...rest}
    >
      {children}
    </Box>
  )
);

PopoverTrigger.displayName = 'PopoverTrigger';
