import { TemplateBlock } from '../BaseBlock';
import { generateBlockID } from '../generateBlockID';
import { BlockRecords, readBlockValue } from '../../BlocksData';

export interface TextFieldBlock extends TemplateBlock {
  type: 'TextField';
  label: string;
  description: string;
}

export function blockIsTextField(
  block: TemplateBlock
): block is TextFieldBlock {
  return block.type === 'TextField';
}

export function newTextFieldBlock(
  label: string,
  extraProps?: Partial<TextFieldBlock>
): TextFieldBlock {
  return {
    type: 'TextField',
    id: generateBlockID(),
    label,
    description: '',
    ...extraProps,
  };
}

export function updateLabel(textField: TextFieldBlock, label: string) {
  return { ...textField, label };
}

export function updateDescription(
  textField: TextFieldBlock,
  description: string
) {
  return { ...textField, description };
}

export function readTextFieldValue(
  data: BlockRecords,
  textField: TextFieldBlock
): string | null {
  const value = readBlockValue(data, textField.id);
  return typeof value === 'string' ? value : null;
}
