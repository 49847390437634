import React from 'react';
import displayName from './displayName';

export interface RecentActivityProps {
  timeAgo: string;
  activeAgo?: string;
  activeByName?: string;
}

export function RecentActivity({
  timeAgo,
  activeAgo,
  activeByName,
}: RecentActivityProps): JSX.Element {
  const showRecentActivity = () =>
    activeAgo && activeByName && timeAgo !== activeAgo;
  const recentActivity = () => (
    <span>
      Recent activity by {displayName(activeByName)} {activeAgo}.
    </span>
  );

  return (
    <span data-testid="recent-activity">
      {showRecentActivity() && recentActivity()}
    </span>
  );
}

export default RecentActivity;
