import React from 'react';
import {
  renderVariant,
  CovidenceButtonProps as ButtonProps,
} from './buttonVariants';

export const PrimaryButton = React.forwardRef(function PrimaryButton(
  props: ButtonProps,
  ref: React.Ref<HTMLButtonElement>
): JSX.Element {
  return renderVariant(props, ref, 'Primary');
});

export const SecondaryButton = React.forwardRef(function SecondaryButton(
  props: ButtonProps,
  ref: React.Ref<HTMLButtonElement>
): JSX.Element {
  return renderVariant(props, ref, 'Secondary');
});

export const TertiaryButton = React.forwardRef(function TertiaryButton(
  props: ButtonProps,
  ref: React.Ref<HTMLButtonElement>
): JSX.Element {
  return renderVariant(props, ref, 'Tertiary');
});

export const DangerButton = React.forwardRef(function DangerButton(
  props: ButtonProps,
  ref: React.Ref<HTMLButtonElement>
): JSX.Element {
  return renderVariant(props, ref, 'Danger');
});

export const GhostButton = React.forwardRef(function GhostButton(
  props: ButtonProps,
  ref: React.Ref<HTMLButtonElement>
): JSX.Element {
  return renderVariant(props, ref, 'Ghost');
});

export const NakedButton = React.forwardRef(function NakedButton(
  props: ButtonProps,
  ref: React.Ref<HTMLButtonElement>
): JSX.Element {
  return renderVariant(props, ref, 'Naked', props.className);
});

export const LinkButton = React.forwardRef(function NakedButton(
  props: ButtonProps,
  ref: React.Ref<HTMLButtonElement>
): JSX.Element {
  return renderVariant(props, ref, 'Link');
});
