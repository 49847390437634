import { gql } from '@apollo/client';

const INVITE_PERSON_TO_ORGANIZATION = gql`
  mutation invitePersonToOrganization(
    $organizationId: ID!
    $name: String!
    $email: Email!
  ) {
    invitePersonToOrganization(
      input: { organizationId: $organizationId, name: $name, email: $email }
    ) {
      success
      errors {
        message
      }

      invitation {
        id
        name
        email
        expiresAt
      }
    }
  }
`;

export default INVITE_PERSON_TO_ORGANIZATION;
