import React from 'react';
import { MultipleChoiceEditorSettingsProps } from '../MultipleChoiceEditorSettings';
import { newOtherOptionBlock } from 'concepts/Extraction/Blocks/shared/Option';
import { Checkbox } from 'system/elements';

export const AddOtherOptionCheckbox = ({
  block,
  onChange,
}: MultipleChoiceEditorSettingsProps) => (
  <Checkbox
    label="Add “Other” option"
    checked={block.otherOption != null}
    onChange={(event) => {
      const newBlock = {
        ...block,
        otherOption: event.target.checked ? newOtherOptionBlock() : null,
      };

      onChange(newBlock);
    }}
  />
);
