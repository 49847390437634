import React from 'react';
import styles from '../ExpiredPlanSelectionModal.module.scss';
import { Icon, IconList, Link, Text } from 'components/core';

interface PlanOptionBenefitsSectionProps {
  plan: any;
}

export const PlanOptionBenefitsSection = ({
  plan,
}: PlanOptionBenefitsSectionProps) => {
  const checklistIcon = (
    <Icon
      icon={IconList.solid.faCheck}
      className={styles.PlanOption_benefits_check_icon}
    />
  );
  return (
    <ul className={styles.PlanOption_plan_benefits_section}>
      <li>
        <Text>
          {checklistIcon}
          <b>
            {plan.number_of_reviews
              ? plan.number_of_reviews.toString()
              : 'Unlimited'}{' '}
            {plan.number_of_reviews == 1 ? 'review' : 'reviews'}
          </b>
        </Text>
      </li>
      <li>
        <Text>
          {checklistIcon}
          <b>
            {plan.billing_period_count.toString()} {plan.billing_period_unit}{' '}
            access
          </b>
        </Text>
      </li>
      <li>
        <Text>
          {checklistIcon}
          {plan.number_of_reviewers
            ? plan.number_of_reviewers.toString()
            : 'Unlimited'}{' '}
          {plan.number_of_reviewers == 1 ? 'reviewer' : 'reviewers'}
        </Text>
      </li>
      <li>
        <Text>{checklistIcon}Access to expert support</Text>
      </li>
      <li className={styles.PlanOption_benefits_learn_more}>
        <Text>
          <Link to="/plans" className={styles.ExpiredPlanSelectionModal_link}>
            Learn more
          </Link>
        </Text>
      </li>
    </ul>
  );
};
