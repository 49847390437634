import { useCallback, useEffect, useState } from 'react';

export const useAllowNavigate = (
  allowNavigate: boolean
): {
  allowNavigate: boolean;
  setAllowNavigate: (
    allowNavigate: boolean | ((prev: boolean) => boolean)
  ) => void;
} => {
  const [allowNavigateState, setAllowNavigate] = useState(allowNavigate);
  const beforeUnloadCallback = useCallback((event: BeforeUnloadEvent) => {
    event.preventDefault();
    return (event.returnValue = 'Are you sure you want to exit?');
  }, []);

  useEffect(() => {
    if (allowNavigateState) {
      window.removeEventListener('beforeunload', beforeUnloadCallback);
    } else {
      window.addEventListener('beforeunload', beforeUnloadCallback);
    }
    return () => {
      window.removeEventListener('beforeunload', beforeUnloadCallback);
    };
  }, [beforeUnloadCallback, allowNavigateState]);

  return { allowNavigate: allowNavigateState, setAllowNavigate };
};
