import { BlockID } from './Blocks/BaseBlock';
import { CheckboxesBlockValue } from './Blocks/Checkboxes/CheckboxesBlock';
import { TableData } from './Blocks/Table/BaseTable/types';

export type BlockValue =
  | string
  | boolean
  | CheckboxesBlockValue
  | TableData
  | null;

export interface BlockRecord<T extends BlockValue = BlockValue> {
  value: T | null;
  comment?: string | null;
  changed?: boolean;
}

export type BlockRecords = Record<BlockID, BlockRecord>;

export const readBlockValue = (
  records: BlockRecords,
  blockID: BlockID
): BlockValue | null => records[blockID]?.value ?? null;

export function readBlockComment(
  data: BlockRecords,
  id: BlockID
): string | null {
  if (id in data) {
    return data[id].comment || null;
  }
  return null;
}
