import React, { ReactNode, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import styles from './Collapser.module.scss';

type Props = {
  collapse: boolean;
  children: ReactNode;
  onAfterCollapse: () => void;
};

export const Collapser = ({ collapse, children, onAfterCollapse }: Props) => {
  const [animate, setAnimate] = useState(false);
  const element = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const actualEl = element.current;

    if (actualEl && collapse) {
      // Set height as we can't animate from height auto
      actualEl.style.height = `${actualEl.clientHeight}px`;
      setAnimate(true);
    }
  }, [collapse, onAfterCollapse]);

  return (
    <div
      data-testid="collapser"
      onAnimationEnd={() => {
        onAfterCollapse();
      }}
      className={classNames(styles.wrapper, {
        [styles.collapseAnimation]: animate,
      })}
      ref={element}
    >
      {children}
    </div>
  );
};

export default Collapser;
