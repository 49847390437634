import { DndContext, DragEndEvent } from '@dnd-kit/core';
import {
  restrictToHorizontalAxis,
  restrictToVerticalAxis,
} from '@dnd-kit/modifiers';
import { SortableContext } from '@dnd-kit/sortable';
import React from 'react';

interface ItemWithPosition {
  [key: string]: string | number;
}

interface Props<T extends ItemWithPosition> {
  children: React.ReactNode;
  items: T[];
  orderKey: keyof T;
  direction?: 'vertical' | 'horizontal';
  onMove?: (from: number, to: number) => void;
}

const ReorderItems = <T extends ItemWithPosition>({
  children,
  items,
  orderKey,
  direction = 'vertical',
  onMove,
}: Props<T>) => {
  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (!over) return;

    if (active.id === over.id) {
      return;
    }

    if (active.id !== over.id) {
      onMove &&
        onMove(
          items.findIndex((i) => i[orderKey] === active.id),
          items.findIndex((i) => i[orderKey] === over.id)
        );
    }
  };

  const modifiers = () => {
    switch (direction) {
      case 'vertical':
        return [restrictToVerticalAxis];
      case 'horizontal':
        return [restrictToHorizontalAxis];
      default:
        return [];
    }
  };

  return (
    <DndContext modifiers={[...modifiers()]} onDragEnd={handleDragEnd}>
      <SortableContext items={items.map((item) => item[orderKey])}>
        <ol>{children}</ol>
      </SortableContext>
    </DndContext>
  );
};

export default ReorderItems;
