import React from 'react';
import PropTypes from 'prop-types';

function heading(text) {
  if (text) {
    return (
      <div className="row">
        <div className="col-md-12 tcn">
          <div className="mb2 mt4 dark-gray">
            <h3>{text}</h3>
          </div>
        </div>
      </div>
    );
  }

  return '';
}

const FloatingBox = (props) => (
  <>
    {heading(props.heading)}
    <div className="floating-box">
      <div className="row">
        <div className="col-md-12">{props.children}</div>
      </div>
    </div>
  </>
);

FloatingBox.defaultProps = {
  heading: null,
};
FloatingBox.propTypes = {
  heading: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default FloatingBox;
