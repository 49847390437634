import { useMutation, PureQueryOptions } from '@apollo/client';
import React, { useState } from 'react';
import CANCEL_INVITATION from '../../cancelInvitation';
import ActivitySpinner from 'components/ActivitySpinner';
import ErrorMessage from 'components/ErrorMessage';
import Text from 'system/base/Text';
import { NakedButton } from 'system/base/Button';
import { CancelInvitationInput, CancelInvitationMutation } from 'types/graphql';
import { Invitation } from 'types/graphql';

interface Props {
  invitation: Invitation;
  refetchQueries: PureQueryOptions[];
}

const CancelInvitation = ({ invitation, refetchQueries }: Props) => {
  enum CancelingState {
    Inactive,
    Canceling,
    Error,
  }
  const [cancelingState, setCancelingState] = useState(CancelingState.Inactive);

  const [cancelInvitation] = useMutation<
    CancelInvitationMutation,
    CancelInvitationInput
  >(CANCEL_INVITATION, {
    variables: {
      invitationId: invitation.id,
    },
    refetchQueries,
  });

  const cancel = (e: React.MouseEvent) => {
    e.preventDefault();

    setCancelingState(CancelingState.Canceling);
    cancelInvitation().catch(() => setCancelingState(CancelingState.Error));
  };

  switch (cancelingState) {
    case CancelingState.Inactive:
      return (
        <NakedButton onClick={cancel} style={{ padding: 0 }}>
          <Text color="danger">Cancel invitation</Text>
        </NakedButton>
      );
    case CancelingState.Canceling:
      return <ActivitySpinner />;
    case CancelingState.Error:
      return <ErrorMessage>Unable to cancel</ErrorMessage>;
    default:
  }

  return null;
};

export default CancelInvitation;
