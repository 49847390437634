import React from 'react';
import styles from '../Loading.module.scss';
import { Icon, IconList } from 'components/core';

export type Size = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
export type Type = 'primary' | 'neutral' | 'brand';

interface Props {
  size?: Size;
  type?: Type;
  className?: string;
}

const LoadingSpinner = ({ size, className, type }: Props) => {
  const classes = [styles.Loading, styles.LoadingSpinner];
  if (size) classes.push(styles[size]);
  if (type) classes.push(styles[type]);
  if (className) classes.push(className);

  return (
    <Icon className={classes.join(' ')} icon={IconList.light.faSpinnerThird}>
      Loading
    </Icon>
  );
};

export default LoadingSpinner;
