import { gql } from '@apollo/client';
import idx from 'idx';
import React from 'react';
import useCollectionQuery from 'hooks/useCollectionQuery';
import EmailAddress from 'components/EmailAddress';
import DataTable, { Columns } from 'system/data/DataTable';
import {
  AdministratorsDataSourceQuery,
  AdministratorsDataSourceQueryVariables,
} from 'types/graphql';

const QUERY = gql`
  query AdministratorsDataSource($organizationId: ID!, $after: String) {
    organization: node(id: $organizationId) {
      ... on Organization {
        administrators(first: 25, after: $after) {
          pageInfo {
            hasNextPage
            endCursor
          }

          nodes {
            id
            name
            email: primaryEmail

            organizationMembership(organization: $organizationId) {
              assignedReviews {
                total
              }
            }
          }
        }
      }
    }
  }
`;

interface Props {
  organizationId: string;
}

const Administrators = ({ organizationId }: Props) => {
  const { data, loading, error, loadMore } = useCollectionQuery<
    AdministratorsDataSourceQuery,
    AdministratorsDataSourceQueryVariables
  >({
    query: QUERY,
    variables: {
      organizationId,
    },
    path: ['organization', 'administrators'],
  });

  const collection =
    idx(data, (_) => _.organization.administrators.nodes) || [];

  /* eslint-disable react/display-name */
  const columns: Columns<typeof collection> = [
    {
      heading: 'Name',
      primary: true,
      render: ({ name }) => <span className="pii">{name}</span>,
    },
    {
      heading: 'Email Address',
      render: ({ email }) => <EmailAddress address={email} />,
    },
    {
      heading: 'Assigned Reviews',
      render: (admin) =>
        idx(admin, (_) => _.organizationMembership.assignedReviews.total),
    },
  ];
  /* eslint-enable react/display-name */

  return (
    <DataTable
      loading={loading}
      error={error}
      collection={collection}
      columns={columns}
      loadMore={loadMore}
      empty="There are no administrators of this organisation"
    />
  );
};

export default Administrators;
