/* Generic event handlers which reference specific keyCodes or have usability concerns */
import { FormEvent } from 'react';

// https://developer.mozilla.org/en-US/docs/Web/API/FormData#Browser_compatibility
import 'formdata-polyfill';

type FormDataHandler = (
  formData: FormData,
  event: FormEvent<HTMLFormElement>
) => void;

export const onSubmitGetData = (onSubmitHandler: FormDataHandler) => (
  event: FormEvent<HTMLFormElement>
) => {
  event.preventDefault();
  onSubmitHandler(new FormData(event.currentTarget), event);
};
