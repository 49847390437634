/*

Provides functions to increase a backoff value when errors occur, increasing
with each error, and resetting on success.

The value returned will be current for any render, but calling onError() or
onSuccess() won't trigger a re-render of the consuming component. For this
reason this hook uses Ref, not State. It's expected that onError()/onSuccess()
callers will already be updating the component's state in response to the error
or success if this is neccessary.

*/
import { useCallback, useRef } from 'react';

const INITIAL_DELAY = 100;
const PER_ERROR_DELAY = 1500;

const getBackoff = (
  errorCount: number,
  initialDelay: number,
  perErrorExponential: number
) => {
  return (
    initialDelay +
    (errorCount > 0 ? perErrorExponential * Math.pow(2, errorCount - 1) : 0)
  );
};

const useBackoff = (
  initialDelay = INITIAL_DELAY,
  perErrorExponential = PER_ERROR_DELAY
) => {
  const errorCountRef = useRef<number>(0);

  const onError = useCallback(
    (errorCount?: number) => {
      errorCountRef.current = errorCount || errorCountRef.current + 1;
      return getBackoff(
        errorCountRef.current,
        initialDelay,
        perErrorExponential
      );
    },
    [initialDelay, perErrorExponential]
  );

  const onSuccess = useCallback(() => {
    errorCountRef.current = 0;
    return getBackoff(errorCountRef.current, initialDelay, perErrorExponential);
  }, [initialDelay, perErrorExponential]);

  return [
    getBackoff(errorCountRef.current, initialDelay, perErrorExponential),
    onError,
    onSuccess,
  ] as const;
};

export default useBackoff;
