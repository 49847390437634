import { makeThemeBreakpointsScale } from './utils/make-theme';

const breakpointValues = ['40em', '52em', '64em', '80em'];

const breakpointsAliases = {
  sm: breakpointValues[0],
  md: breakpointValues[1],
  lg: breakpointValues[2],
  xl: breakpointValues[3],
};

export const breakpoints = makeThemeBreakpointsScale(
  Object.assign([], breakpointValues, breakpointsAliases)
);
