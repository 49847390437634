import React, { memo, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import styles from '../../DataExtractionTemplate.module.scss';
import EditableField from '../EditableField';
import { DataExtractionTemplateState } from '../../DataExtractionTemplate';
import {
  validate,
  NO_EQUIVALENT_VALUE,
} from '../../../components/editableFieldValidation';
import ConfirmationDialog from '../ConfirmationDialog';
import { Heading, Text, Icon, IconList, Link } from 'components/core';

const RESERVED_FIELD_NAMES: Array<string> = [
  'inclusion criteria',
  'exclusion criteria',
  'group differences',
];

interface Props {
  hasExtractedData?: boolean;
}

const PopulationPanel = ({ hasExtractedData = false }: Props) => {
  const [modal, setModal] = useState<React.ReactElement | null>();
  const { getValues } = useFormContext();
  const additionalFields = useFieldArray<DataExtractionTemplateState>({
    name: 'population.additionalFields',
  });
  const baselineCharacteristics = useFieldArray<DataExtractionTemplateState>({
    name: 'population.baseline_characteristics',
  });
  const MAX_LENGTH = 255;

  return (
    <section className={styles.section}>
      {modal}
      <Heading
        type="h5"
        component="h3"
        variant="brand"
        className={styles.heading}
      >
        Population
        <Link
          className={styles.HelpLink}
          onClick={() => ''}
          data-pendo-key="det-population"
          aria-label="Get help with population"
        >
          <Icon icon={IconList.light.faQuestionCircle} />
        </Link>
      </Heading>

      <div className={styles.details}>
        <Heading type="h6" component="h4" variant="neutral">
          Population details
        </Heading>
        <ul>
          <li>
            <Text variant="medium">Inclusion criteria</Text>
          </li>
          <li>
            <Text variant="medium">Exclusion criteria</Text>
          </li>
          <li>
            <Text variant="medium">Group differences</Text>
          </li>
        </ul>
      </div>

      <div className={styles.details}>
        <Heading type="h6" component="h4" variant="neutral">
          Baseline characteristics
        </Heading>
        {baselineCharacteristics.fields.map((field, index) => (
          <EditableField
            key={field.id}
            index={index}
            name="population.baseline_characteristics"
            onDelete={() => {
              const { id } = getValues(
                `population.baseline_characteristics.${index}`
              );

              if (id && hasExtractedData) {
                setModal(
                  <ConfirmationDialog
                    onConfirm={() => baselineCharacteristics.remove(index)}
                    onClose={() => setModal(null)}
                  />
                );
              } else {
                baselineCharacteristics.remove(index);
              }
            }}
            validate={(value, existingFields) => {
              return validate(
                value,
                existingFields,
                [],
                NO_EQUIVALENT_VALUE,
                MAX_LENGTH
              );
            }}
          />
        ))}
        <Link
          onClick={() => baselineCharacteristics.append({ name: '' })}
          variant="primary"
          className={styles.AddButton}
        >
          <Icon icon={IconList.light.faPlus} /> Add baseline characteristic
        </Link>
      </div>

      <div className={styles.details}>
        <Heading type="h6" component="h4" variant="neutral">
          Additional data
        </Heading>
        {additionalFields.fields.map((field, index) => (
          <EditableField
            key={field.id}
            index={index}
            name="population.additionalFields"
            onDelete={() => {
              const { id } = getValues(`population.additionalFields.${index}`);

              if (id && hasExtractedData) {
                setModal(
                  <ConfirmationDialog
                    onConfirm={() => additionalFields.remove(index)}
                    onClose={() => setModal(null)}
                  />
                );
              } else {
                additionalFields.remove(index);
              }
            }}
            validate={(value, existingFields) => {
              return validate(
                value,
                existingFields,
                RESERVED_FIELD_NAMES,
                NO_EQUIVALENT_VALUE,
                MAX_LENGTH
              );
            }}
          />
        ))}
        <Link
          onClick={() => additionalFields.append({ name: '' })}
          variant="primary"
          className={styles.AddButton}
        >
          <Icon icon={IconList.light.faPlus} /> Add additional data
        </Link>
      </div>
    </section>
  );
};

export default memo(PopulationPanel);
