import React, { useState, FunctionComponent } from 'react';

import styles from './ExportToFile.module.scss';
import { CreateInterRaterReliabilityExportParams } from './index';

import {
  Button,
  Heading,
  Text,
  FormContainer,
  RadioButton,
  RadioGroup,
} from 'components/core';

const STAGES = { SCREEN: 'Screen', SELECT: 'Select' };

interface InterRaterReliabilityProps {
  createExport: (
    type: string,
    params: CreateInterRaterReliabilityExportParams
  ) => void;
}

const InterRaterReliabilityForm: FunctionComponent<InterRaterReliabilityProps> = (
  props
) => {
  const { createExport } = props;
  const [stage, setStage] = useState<string>(STAGES.SCREEN);

  const pickStage = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStage(e.target.value);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    /* eslint-disable @typescript-eslint/naming-convention */
    gtag('event', 'Export Inter-Rater Reliability', {
      event_category: 'engagement',
      event_label: stage,
    });

    createExport('inter_rater_reliability', {
      stage: stage,
    });
    /* eslint-enable @typescript-eslint/naming-convention */
  };

  return (
    <form
      id="inter-rater-reliability"
      onSubmit={handleSubmit}
      className={styles.exportForm}
    >
      <div>
        <div className={styles.exportFormHeader}>
          <Heading type="h5">Inter-rater reliability</Heading>
        </div>
        <FormContainer className={styles.optionsContainer}>
          <div>
            <Text>Stage</Text>
            <RadioGroup
              name="irr_stage"
              defaultValue={STAGES.SCREEN}
              onChange={pickStage}
            >
              <RadioButton value={STAGES.SCREEN}>
                Title and abstract screening
              </RadioButton>
              <RadioButton value={STAGES.SELECT}>Full text review</RadioButton>
            </RadioGroup>
          </div>
          <div className={styles.formatList}>
            <Text>Format</Text>
            <Text>CSV</Text>
          </div>
        </FormContainer>
      </div>
      <div className={styles.exportFormFooter}>
        <Button className={styles.exportFormButton} type="brand">
          Prepare file
        </Button>
      </div>
    </form>
  );
};

export default InterRaterReliabilityForm;
