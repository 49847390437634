const FULL_TEXT_UPLOAD = 'covidence.review.full_text_upload';

export const events = {
  FULL_TEXT_UPLOAD,
};

export const UPLOAD_METHODS = {
  LEGACY_SINGLE_STUDY_MODAL: 'legacy-single-study-modal',
  LEGACY_BULK_UPLOADER: 'legacy-bulk-uploader',
  SINGLE_STUDY_MODAL: 'single-study-modal',
  DRAG_AND_DROP_SCREENING_PAGE: 'drag-and-drop-screening-page',
  BULK_UPLOADER: 'bulk-uploader',
  BULK_UPLOADER_SINGLE_FILE: 'bulk-uploader-single-file',
};

export const eventProperties = {
  [events.FULL_TEXT_UPLOAD]: { UPLOAD_METHODS },
};

const sendEvent = (name: string, options: any) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (typeof pendo === 'undefined') {
    console.log('Sending event ' + name + ' with: ' + JSON.stringify(options));
  } else {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    pendo.track(name, options);
  }
};

export const sendFullTextEvent = ({
  uploadMethod,
  fileCount = 1,
}: {
  uploadMethod: string;
  fileCount?: number;
}) => {
  sendEvent(FULL_TEXT_UPLOAD, {
    full_text_upload_method: uploadMethod,
    total_full_text_uploaded: fileCount,
  });
};

export default function (name: string, options: any) {
  sendEvent(name, options);
}
