import React, { useRef, useState, useMemo } from 'react';
import { importJobError } from '../../index';
import { ImportJob, ImportError, ImportJobProgress } from '../../types';
import ImportJobStatus from '../ImportJobStatus';
import UploaderSection from '../UploaderSection';
import ProgressBar from '../ProgressBar';
import DragAndDropUploader from '../DragAndDropUploader';
import { useAllowNavigate } from '../useAllowNavigate';
import styles from './XMLUploader.module.css';
import { Callbacks, createImportJobType } from './query';
import Icon, { IconStyle } from 'system/base/Icon';
import { TertiaryButton } from 'system/base/Button';
import { Spinner } from 'system/elements';
import { Link } from 'system/base/Link';

type Props = {
  reviewId: string;
  importJob: ImportJob | null;
  uploadedFiles: number;
  createImportJob: createImportJobType;
  onCreateImportJob: () => void;
  onFinishImportJob: (job?: ImportJob) => void;
};

const HELP_LINK = (
  <Link
    href="https://support.covidence.org/help/how-to-troubleshoot-when-bulk-uploading-pdfs"
    target="_blank"
    rel="noopener noreferrer"
    className={styles.XMLUploader__errorLink}
  >
    Learn more about troubleshooting issues
    <Icon
      className={styles.XMLUploader__externalIcon}
      name="external-link"
      iconStyle={IconStyle.Solid}
    />
  </Link>
);

const ImportErrorMessage = {
  [ImportError.FAILED_TO_PARSE]: <>Unable to read the file.</>,
  [ImportError.NO_STUDIES_MISSING_FULL_TEXT]: (
    <>
      Please choose an XML file containing studies missing full texts.{' '}
      {HELP_LINK}
    </>
  ),
  [ImportError.NO_STUDIES_MISSING_PDF]: (
    <>Please choose an XML file that links to missing PDFs. {HELP_LINK}</>
  ),
};

const calculateProgress = (
  currentProgress: number,
  total: null | number
): number => {
  if (total == null) return 0;
  if (total == 0 && currentProgress == 0) return 0;

  return currentProgress / total;
};

const XMLUploader = ({
  reviewId,
  importJob,
  uploadedFiles,
  onCreateImportJob,
  onFinishImportJob,
  createImportJob,
}: Props) => {
  const { setAllowNavigate } = useAllowNavigate(true);
  const [error, setError] = useState('');
  const [uploadProgress, setUploadProgress] = useState(0);
  const [xmlJobStatus, setXmlImportJobStatus] = useState<ImportJobProgress>(
    'not-started'
  );
  const [currentImportJob, setCurrentImportJob] = useState<ImportJob | null>();
  const [indeterminateLoading, setIndeterminateLoading] = useState(false);
  const fileRef = useRef<HTMLInputElement>(null);
  const pollJobIntervalId = useRef<ReturnType<typeof setInterval> | null>(null);
  const importError = useMemo(() => importJobError(importJob), [importJob]);

  const onError = (message: string) => {
    setUploadProgress(0);
    setAllowNavigate(true);
    setIndeterminateLoading(false);
    setError(message);
  };

  const onPollingComplete = (job: ImportJob) => {
    // reset progress once import job is processed,
    // otherwise it keeps displaying 100% progress bar
    setXmlImportJobStatus('complete');
    setAllowNavigate(true);
    onFinishImportJob(job);
  };

  const callbacks: Callbacks = {
    setUploadProgress,
    onError,
    // This is used so we dont can separate the mechanism of managing the ref for
    // Intervals
    stopPolling: () => {
      if (pollJobIntervalId.current) {
        clearInterval(pollJobIntervalId.current);
        pollJobIntervalId.current = null;
      }
    },
    startPolling: (functionToPoll) => {
      setXmlImportJobStatus('in-progress');
      pollJobIntervalId.current = setInterval(() => {
        functionToPoll();
      }, 2000);
    },
    onPollingComplete,
    setSaveDocumentInProgress: (isInProgress) => {
      setIndeterminateLoading(isInProgress);
    },
    setImportJobProgress: ({ importJob, importJobStatus }) => {
      setXmlImportJobStatus(importJobStatus);
      setCurrentImportJob(importJob);
    },
  };

  const onFileChange = (files: File[]) => {
    if (files && files.length) {
      if (!files[0].type.endsWith('/xml')) {
        setError('Please choose an XML file');
      } else if (files[0].size >= 200 * 1024 * 1024) {
        setError('File is too large: must be less than 200MB');
      } else {
        setError('');
        setAllowNavigate(false);
        onCreateImportJob();
        createImportJob(files[0], reviewId, callbacks);
      }
    }
  };

  const actualImportJob = (importJob || currentImportJob) as ImportJob;

  const importJobProgress =
    xmlJobStatus === 'complete'
      ? 0
      : calculateProgress(
          actualImportJob?.found || 0,
          actualImportJob?.found &&
            actualImportJob?.references_to_update &&
            actualImportJob?.found + actualImportJob?.references_to_update
        );

  return (
    <>
      <DragAndDropUploader
        acceptUpload={'.xml'}
        onFileChange={onFileChange}
        ref={fileRef}
        data-testid="XMLUploader"
        onDragEnterClassName={styles.dragged}
      >
        <UploaderSection error={!!(error || importError)} dashed>
          Step 3: Drag and drop EndNote XML file here (200MB limit)
          <TertiaryButton
            disabled={uploadProgress > 0}
            onClick={(e) => {
              e.preventDefault();
              fileRef.current?.click();
            }}
          >
            <Icon name="cloud-upload" iconStyle={IconStyle.Solid} />
            Choose XML file
          </TertiaryButton>
          <ProgressBar uploadProgress={uploadProgress} />
        </UploaderSection>
      </DragAndDropUploader>
      {(indeterminateLoading || xmlJobStatus == 'queued') && (
        <Spinner data-testid={'spinner'} style={{ margin: '16px auto' }} />
      )}
      {error && <div className={styles.XMLUploader__error}>{error}</div>}
      {actualImportJob && uploadedFiles === 0 && xmlJobStatus !== 'queued' && (
        <ImportJobStatus
          totalFiles={actualImportJob.found_with_full_text}
          uploadProgress={importJobProgress}
          error={
            importError
              ? ImportErrorMessage[importError] || 'Error processing your file'
              : null
          }
          fileName={actualImportJob?.file_name}
        />
      )}
    </>
  );
};

export default XMLUploader;
