import React from 'react';

export interface MultilinedTextProps {
  children: string | null;
}
export function MultilinedText({ children }: MultilinedTextProps): JSX.Element {
  if (!children) {
    return <></>;
  }

  return (
    <>
      {children.split('\n').map((line, index) => (
        <p key={index}>{line || <>&nbsp;</>}</p>
      ))}
    </>
  );
}
