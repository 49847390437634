import React, { useContext } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { TableHeader } from '../../types';
import { getTableCellData } from '../../BaseTable/utils';
import { CellValue, TableData, isTableBlockValue } from '../../BaseTable/types';
import styles from './ColumnComparisonBlock.module.css';
import comparisonTableStyles from 'concepts/Extraction/Compare/ComparisonTable/ComparisonTable.module.css';
import { OnResolveTableCell } from 'concepts/Extraction/Compare/types';
import { CompareDecision } from 'concepts/Extraction/Compare/CompareDecision';
import { CompareButton } from 'concepts/Extraction/Compare/CompareButton';
import { BlockRecord } from 'concepts/Extraction/BlocksData';
import { ComparisonRowProps } from 'concepts/Extraction/Blocks/BlockRenderer';
import { ComparisonTableContext } from 'concepts/Extraction/Compare/ComparisonTable/ComparisonTable';
import { CompareNotificationBorder } from 'concepts/Extraction/Compare/CompareNotificationBorder';
import { ResolutionState } from 'containers/Extraction/types';

interface ColumnComparisonBlockProps
  extends Pick<ComparisonRowProps, 'readonly'> {
  column: TableHeader;
  rows: TableHeader[];
  columnNumber: number;
  blockId: string;
  reviewerRecords: BlockRecord[];
  onResolveTableCell: OnResolveTableCell;
  tableResolutionState?: ResolutionState | null;
}

export const ColumnComparisonBlock = ({
  column,
  rows,
  columnNumber,
  reviewerRecords,
  blockId,
  onResolveTableCell,
  readonly,
  tableResolutionState = null,
}: ColumnComparisonBlockProps) => {
  const formContext = useFormContext();
  const { reviewerNames } = useContext(ComparisonTableContext);

  const resolvedDecidedValue = useWatch({
    control: formContext.control,
    name: `resolvedData.${blockId}`,
  });

  /**
   * resolvedData should always have the table id, even with empty cell values.
   * Nevertheless, this creates an empty decidedRecord if no cell values are
   * decided yet.
   */
  const decidedRecord: BlockRecord<TableData> = (resolvedDecidedValue as BlockRecord<TableData>) ?? {
    value: [],
  };

  return (
    <>
      <tr className={comparisonTableStyles.row}>
        <th colSpan={4} className={styles.header}>
          Column {columnNumber}: {column.label}
        </th>
      </tr>
      {rows.map((row, index) => {
        const onResolveCellForSpecificTable = onResolveTableCell(blockId);
        const handleResolveSpecificCell = onResolveCellForSpecificTable({
          rowID: row.id,
          columnID: column.id,
          rowIndex: index,
        });
        const handleResolveValue = (value: CellValue) => {
          handleResolveSpecificCell({ value });
        };

        const decidedCellValue = isTableBlockValue(decidedRecord.value)
          ? getTableCellData({
              table: decidedRecord.value,
              rowID: row.id,
              columnID: column.id,
            })?.value
          : undefined;

        const cellResolutionState =
          decidedCellValue === undefined
            ? 'unresolved'
            : 'resolvedByConsensusReviewer';

        return (
          <tr key={index}>
            <th className={comparisonTableStyles.paddedCell}>{row.label}</th>
            <td>
              <CompareNotificationBorder
                notificationMessage="Check decision"
                showNotification={false}
              >
                <CompareDecision
                  readonly={readonly}
                  label={`Decide row ${row.label}, column ${column.label}`}
                  /**
                   * null or empty string values are treated as resolved.
                   */
                  resolutionState={tableResolutionState || cellResolutionState}
                  value={decidedCellValue?.toString() ?? ''}
                  onResolveValue={handleResolveValue}
                />
              </CompareNotificationBorder>
            </td>
            {reviewerRecords.map((record, index) => {
              /**
               * Type assert that BlockValue is of type TableData
               * specifically.
               */
              const tableValue = record?.value;

              /**
               * table, rowID & columnID must not be nullish to retrieve
               * cell data.
               */
              const cellData = isTableBlockValue(tableValue)
                ? getTableCellData({
                    table: tableValue,
                    rowID: row.id,
                    columnID: column.id,
                  })
                : null;

              const value = cellData?.value ?? '';

              const handleClick = () => {
                handleResolveSpecificCell({ value });
              };

              return (
                <td key={index} className={comparisonTableStyles.decisionCell}>
                  <CompareNotificationBorder
                    notificationMessage="Data edited"
                    showNotification={false}
                  >
                    <CompareButton
                      onClick={handleClick}
                      comment={
                        cellData?.comment
                          ? {
                              comment: cellData?.comment,
                              commenter: reviewerNames[index],
                            }
                          : undefined
                      }
                    >
                      {value}
                    </CompareButton>
                  </CompareNotificationBorder>
                </td>
              );
            })}
          </tr>
        );
      })}
    </>
  );
};
