import * as R from 'ramda';
import { TemplateModel } from '../TemplateModel';
import { TemplateBlock } from '../Blocks/BaseBlock';

export interface TemplateViewModel {
  blocks: Array<TemplateBlock>;
}

export function fromModel(model: TemplateModel): TemplateViewModel {
  return { blocks: model.blocks };
}

export function toModel(viewModel: TemplateViewModel): TemplateModel {
  return { blocks: viewModel.blocks };
}

export function create(): TemplateViewModel {
  return {
    blocks: [],
  };
}

export function build(blocks: Array<TemplateBlock>) {
  return {
    blocks,
  };
}

export function add(
  viewModel: TemplateViewModel,
  block: TemplateBlock,
  afterID: string | null
): TemplateViewModel {
  viewModel = R.clone(viewModel);

  if (afterID === null) {
    viewModel.blocks.unshift(block);
  } else {
    const index = viewModel.blocks.findIndex((block) => block.id === afterID);

    if (index === -1) {
      throw new Error(`Cannot add block, invalid block id: ${afterID}`);
    }

    viewModel.blocks.splice(index + 1, 0, block);
  }

  return viewModel;
}

export function remove(viewModel: TemplateViewModel, id: string) {
  viewModel = R.clone(viewModel);
  const index = viewModel.blocks.findIndex((block) => block.id === id);

  if (index === -1) {
    throw new Error(`Cannot remove block, invalid block id: ${id}`);
  }

  viewModel.blocks.splice(index, 1);
  return viewModel;
}

export function move(
  viewModel: TemplateViewModel,
  id: string,
  toIndex: number | null
): TemplateViewModel {
  viewModel = R.clone(viewModel);

  const movedIndex = viewModel.blocks.findIndex((block) => block.id === id);
  if (movedIndex === -1) {
    throw new Error(`Cannot move block, invalid block id: ${id}`);
  }

  const [moved] = viewModel.blocks.splice(movedIndex, 1);

  if (toIndex === null) {
    viewModel.blocks.unshift(moved);
  } else {
    if (toIndex < 0 || toIndex > viewModel.blocks.length) {
      throw new Error(`Cannot move block, invalid index: ${toIndex}`);
    }

    viewModel.blocks.splice(toIndex, 0, moved);
  }

  return viewModel;
}

export function replace(
  viewModel: TemplateViewModel,
  indexOrSet: number | 'all',
  newBlock: TemplateBlock
) {
  let index: number;
  let deleteCount = 1;
  if (indexOrSet === 'all') {
    index = 0;
    deleteCount = viewModel.blocks.length;
  } else {
    index = indexOrSet;
    if (index < 0 || index >= viewModel.blocks.length) {
      throw new Error(`Cannot replace block, invalid index: ${index}`);
    }
  }

  viewModel = R.clone(viewModel);
  viewModel.blocks.splice(index, deleteCount, newBlock);
  return viewModel;
}

export function updateBlock(
  viewModel: TemplateViewModel,
  newBlock: TemplateBlock
) {
  viewModel = R.clone(viewModel);
  viewModel.blocks.forEach((block, index, blocks) => {
    if (block.id === newBlock.id) {
      blocks[index] = newBlock;
    }
  });
  return viewModel;
}
