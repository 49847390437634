import React from 'react';
import Dialog, { Banner as DialogBanner } from 'system/base/Dialog';
import { PrimaryButton, NakedButton } from 'system/base/Button';

export interface PublishWarningDialogProps {
  inProgress: number;
  completed: number;
  isOpen: boolean;
  onConfirm: () => void;
  onDismiss: () => void;
}

function Banner({
  inProgress,
  completed,
}: {
  inProgress: number;
  completed: number;
}): JSX.Element {
  if (inProgress == 0) {
    return (
      <div>
        You currently have <strong>{completed} completed</strong> stud
        {completed == 1 ? 'y' : 'ies'}
      </div>
    );
  } else if (completed == 0) {
    return (
      <div>
        You currently have <strong>{inProgress} in-progress</strong> stud
        {inProgress == 1 ? 'y' : 'ies'}
      </div>
    );
  } else {
    return (
      <div>
        You currently have <strong>{completed} completed</strong> and{' '}
        <strong>{inProgress} in-progress</strong> studies
      </div>
    );
  }
}

export function PublishWarningDialog({
  completed,
  inProgress,
  isOpen,
  onConfirm,
  onDismiss,
}: PublishWarningDialogProps) {
  return (
    <Dialog
      isOpen={isOpen}
      onDismiss={onDismiss}
      title="Publish this template for extracted studies?"
    >
      <DialogBanner type="warning">
        <Banner inProgress={inProgress} completed={completed} />
      </DialogBanner>
      <p>
        Studies in Complete and Consensus required will return to In progress.
        This enables your team to extract data for any new items added to the
        template. Consensus data (or checked data in single-reviewer mode) will
        be preserved.
      </p>
      <p>Are you sure you want to proceed?</p>
      <>
        <PrimaryButton mr={2} onClick={onConfirm}>
          Yes, publish
        </PrimaryButton>
        <NakedButton onClick={onDismiss}>Cancel</NakedButton>
      </>
    </Dialog>
  );
}
