import { BlockValue } from 'concepts/Extraction/BlocksData';

// Allow RowID to be null to future-proof for column-only tables.
// Currently, row labels & RowID should always be created in the DE template.
export type RowID = string | null;
export type ColumnID = string;

export type CellPosition = {
  rowID: RowID;
  columnID: ColumnID;
  rowIndex: number;
};

export type Header = {
  id: string;
  label: string;
};

export type CellValue = string | number | null;

export type CellData = {
  value: CellValue;
  comment?: string | null;
};

export type ColumnCellData = CellData & {
  columnID: ColumnID;
};

export type RowData = {
  rowID: RowID;
  rowIndex: number;
  columns: ColumnCellData[];
};

export type TableData = RowData[];

const isRowData = (data: unknown): data is RowData =>
  Object.prototype.hasOwnProperty.call(data, 'rowID');

export const isTableBlockValue = (
  blockValue: BlockValue
): blockValue is TableData =>
  Array.isArray(blockValue) &&
  (blockValue.length === 0 || isRowData(blockValue[0]));

export const isTableBlockValues = (
  blockValues: BlockValue[]
): blockValues is TableData[] => isTableBlockValue(blockValues[0]);

export type UpdateCellValueProps = CellPosition & {
  value: CellValue;
};

// Function used to update cell values
export type UpdateCellValue = (props: UpdateCellValueProps) => void;
