import React, { ReactNode, useState, useEffect } from 'react';
import classnames from 'classnames';
import { TertiaryButton } from '../../../../system/base/Button';
import Icon, { IconStyle } from '../../../../system/base/Icon';
import { Link } from '../../../../system/base/Link';
import UploaderSection from '../UploaderSection';
import styles from './ReferenceManagerHelper.module.css';

interface OptionProps {
  href: string;
  children: ReactNode;
}

const OptionLink = ({ href, children }: OptionProps) => (
  <Link href={href} target="_blank" rel="noopener noreferrer">
    {children}
    <Icon name="external-link" iconStyle={IconStyle.Solid} />
  </Link>
);

const ReferenceManagerHelper = () => {
  const [optionsOpen, setOptionsOpen] = useState(false);
  const SUPPORT_URL = 'https://support.covidence.org';
  const ENDNOTE_URL = `${SUPPORT_URL}/help/how-to-use-endnote-to-bulk-upload-full-texts`;
  const ZOTERO_URL = `${SUPPORT_URL}/help/how-to-use-zotero-to-upload-full-texts`;

  useEffect(() => {
    const handleEscapeKeyDown = (e: KeyboardEvent) => {
      if (e.code === 'Escape' && optionsOpen) {
        setOptionsOpen(false);
      }
    };
    const handleOutsideClick = () => {
      if (optionsOpen) {
        setOptionsOpen(false);
      }
    };
    window.addEventListener('keydown', handleEscapeKeyDown);
    window.addEventListener('click', handleOutsideClick);

    return () => {
      window.removeEventListener('keydown', handleEscapeKeyDown);
      window.removeEventListener('click', handleOutsideClick);
    };
  }, [optionsOpen]);

  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    setOptionsOpen(!optionsOpen);
  };

  return (
    <UploaderSection>
      Step 2: Find missing PDFs using your reference manager
      <div>
        <TertiaryButton onClick={handleClick}>
          Learn how to find PDFs
          <Icon
            className={classnames(
              styles.ReferenceManagerHelper__chevronDownIcon,
              {
                [styles.ReferenceManagerHelper__chevronDownIconRotated]: optionsOpen,
              }
            )}
            name="chevron-down"
            iconStyle={IconStyle.Solid}
          />
        </TertiaryButton>
        <div
          className={classnames(styles.ReferenceManagerHelper__options, {
            [styles.ReferenceManagerHelper__options_hidden]: !optionsOpen,
          })}
        >
          <ul>
            <li>
              <OptionLink href={ENDNOTE_URL}>Endnote guide</OptionLink>
            </li>
            <li>
              <OptionLink href={ZOTERO_URL}>Zotero guide</OptionLink>
            </li>
          </ul>
        </div>
      </div>
    </UploaderSection>
  );
};

export default ReferenceManagerHelper;
