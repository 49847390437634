import React, { useState, useEffect } from 'react';
import { CustomImportSource as CustomImportSourceType } from 'types/CitationImport';
import ReviewCitationSourcesModal from '../components/ReviewCitationSourcesModal';
import CitationImport from './components/CitationImport';
import styles from './CitationImportHistory.module.scss';
import { getCitationImports, getReviewImportSources } from 'query/review';
import { Pagination } from 'components/shared';
import { CitationImport as CitationType } from 'types';
import { Button, Icon, IconList } from 'components/core';

const getPage = (): number => {
  const params = new URLSearchParams(document.location.search);
  const page = Number(params.get('page'));

  if (!page || isNaN(Number(page))) return 1;
  return page;
};

const CitationImportHistory = () => {
  const [imports, setImports] = useState<CitationType[]>([]);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [reviewImportSources, setReviewImportSources] = useState<string[]>([]);
  const [customReviewImportSources, setCustomReviewImportSources] = useState<
    CustomImportSourceType[]
  >([]);
  const [isOpenSourceModal, setIsOpenSourceModal] = useState(false);

  useEffect(() => {
    getCitationImports(getPage()).then(({ total_pages, citation_imports }) => {
      setTotalPages(total_pages);
      setImports(citation_imports);
    });
  }, []);

  useEffect(() => {
    getReviewImportSources().then(
      ({ reviewImportSources, customReviewImportSources }) => {
        setReviewImportSources(reviewImportSources);
        setCustomReviewImportSources(customReviewImportSources);
      }
    );
  }, []);

  return (
    <div>
      <div className={styles.CitationImport_manageSourcesContainer}>
        <Button
          component="button"
          htmlType="button"
          variant="outline"
          onClick={() => setIsOpenSourceModal(true)}
        >
          <Icon icon={IconList.light.faGear} /> Manage sources
        </Button>
        <ReviewCitationSourcesModal
          isOpen={isOpenSourceModal}
          setIsOpen={setIsOpenSourceModal}
          reviewImportSources={reviewImportSources}
          setReviewImportSources={setReviewImportSources}
          customReviewImportSources={customReviewImportSources}
          setCustomReviewImportSources={setCustomReviewImportSources}
        />
      </div>
      <div className={styles.CitationImportHistory}>
        {imports.map((citationImport, index) => {
          const citationImportProps = {
            ...citationImport,
            reviewImportSources,
            setReviewImportSources: (sources: string[]) => {
              setReviewImportSources(sources);
            },
            customReviewImportSources,
            setCustomReviewImportSources,
          };

          return (
            <React.Fragment key={citationImport.id}>
              <CitationImport {...citationImportProps} />
              {index != imports.length - 1 ? <hr /> : undefined}
            </React.Fragment>
          );
        })}
        <Pagination page={getPage()} totalPages={totalPages} />
      </div>
    </div>
  );
};

export default CitationImportHistory;
