import shouldForwardProp from '@styled-system/should-forward-prop';
import {
  colorStyle,
  ColorStyleProps,
  compose,
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system';
import styled from '../styled';

type StyleProps = ColorStyleProps &
  FlexboxProps &
  LayoutProps &
  SpaceProps &
  TypographyProps;

const styleProps = compose(layout, space, typography, colorStyle, flexbox);

const Select = styled('select', { shouldForwardProp })<StyleProps>(
  {
    borderStyle: 'solid',
    borderWidth: 1,
  },
  styleProps
);

Select.defaultProps = {
  colors: 'onWhite',
  height: 4,
};

export default Select;

export const Option = styled('option', { shouldForwardProp })<StyleProps>();
