import React, { useState } from 'react';
import { QuestionAssignment } from '../QuestionAssignment/QuestionAssignment';
import { StudyModel } from 'containers/Guidelines/Triage/StudyModel';
import { PrimaryButton, SecondaryButton } from 'system/base/Button';
import styles from 'containers/Guidelines/Triage/Triage.module.css';
import {
  QuestionAssignmentOptions,
  QuestionsAssignmentActions,
} from 'containers/Guidelines/Triage/QuestionAssignmentOptions';

export interface SingleQuestionAssignmentProps {
  study: StudyModel;
  questionOptions: QuestionAssignmentOptions;
  actions: QuestionsAssignmentActions;
}

export function SingleQuestionAssignment({
  study,
  questionOptions,
  actions,
}: SingleQuestionAssignmentProps): JSX.Element {
  const [showForm, setShowForm] = useState(false);
  const [questionSelected, setQuestionSelected] = useState(false);

  const enableSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    const form = e.currentTarget;
    const questionAssignmentSelectNode = form.querySelector(
      "select[data-select='question']"
    ) as HTMLSelectElement;
    setQuestionSelected(questionAssignmentSelectNode.value !== '');
  };

  return showForm ? (
    <div>
      <form
        method="post"
        data-testid="single-assignment-form"
        action={actions.createGuidelineTriageQuestionAssignmentsUrl}
        onChange={enableSubmit}
      >
        <div className={styles.subformBackground}>
          <QuestionAssignment
            study={study}
            questionOptions={questionOptions}
            index={0}
          />
        </div>
        <PrimaryButton
          disabled={!questionSelected}
          type="submit"
          style={{ minWidth: '150px' }}
          data-testid="assign-studies-button"
        >
          Import study
        </PrimaryButton>
      </form>
    </div>
  ) : (
    <div>
      <SecondaryButton
        data-testid={`add-assignment-for-study-${study.id}`}
        iconName="plus"
        onClick={() => setShowForm(true)}
      >
        Assignment
      </SecondaryButton>
    </div>
  );
}
