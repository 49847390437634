import React from 'react';

interface Props {
  size?: 1 | 2 | 3;
}

const ActivitySpinner = ({ size = 1 }: Props) => (
  <i aria-hidden="true" className={`fas fa-fw fa-${size}x fa-sync fa-spin`} />
);

export default ActivitySpinner;
