import React, { PropsWithChildren, ReactNode, CSSProperties } from 'react';
import {
  Body,
  Cell,
  Foot,
  Head,
  HeadCell,
  Row,
  Table,
  Column,
} from 'system/base/Table';
import { ID, Optional } from 'util/types';

export interface HasID {
  id: ID | string | number;
}

export interface Column<T extends HasID> {
  key?: string | number;
  heading: string;
  render: (data: T) => ReactNode;
  primary?: boolean;
  width?: CSSProperties['width'];
}

export type Columns<T extends readonly HasID[]> = Column<T[number]>[];
export const Columns = {}; // dummy value export to suppress webpack warning

export interface Props<T extends HasID> {
  collection: Optional<readonly T[]>;
  columns: Columns<T[]>;
  body?: ReactNode;
  header?: ReactNode;
  footer?: ReactNode;
}

function RowSpan<T extends HasID>({
  children,
  columns,
}: PropsWithChildren<Pick<Props<T>, 'columns'>>) {
  return (
    <Row>
      <Cell colSpan={columns.length}>{children}</Cell>
    </Row>
  );
}

function CollectionHeaders<T extends HasID>({
  columns,
}: Pick<Props<T>, 'columns'>) {
  return (
    <>
      {columns.map((column) => (
        <HeadCell
          textStyle={column.primary ? 'primaryCell' : undefined}
          key={column.key || column.heading}
        >
          {column.heading}
        </HeadCell>
      ))}
    </>
  );
}

function CollectionRows<T extends HasID>({
  collection,
  columns,
}: Pick<Props<T>, 'collection' | 'columns'>) {
  return (
    <>
      {collection
        ? collection.map((row) => (
            <Row key={row.id}>
              {columns.map((column) => (
                <Cell
                  textStyle={column.primary ? 'primaryCell' : undefined}
                  key={column.key || column.heading}
                >
                  {column.render(row)}
                </Cell>
              ))}
            </Row>
          ))
        : []}
    </>
  );
}

function ColGroup<T extends HasID>({ columns }: Pick<Props<T>, 'columns'>) {
  return (
    <colgroup>
      {columns.map((column) => (
        <Column key={column.key || column.heading} width={column.width} />
      ))}
    </colgroup>
  );
}

function CollectionTable<T extends HasID>({
  columns,
  collection,
  body,
  header,
  footer,
}: Props<T>) {
  const hasWidths = columns.some((col) => col.width);

  return (
    <Table fixed={hasWidths}>
      {hasWidths && <ColGroup columns={columns} />}
      <Head>
        <Row>
          <CollectionHeaders columns={columns} />
        </Row>
        {header && <RowSpan columns={columns}>{header}</RowSpan>}
      </Head>
      {(collection || body) && (
        <Body>
          <CollectionRows collection={collection} columns={columns} />
          {body && <RowSpan columns={columns}>{body}</RowSpan>}
        </Body>
      )}
      {footer && (
        <Foot>
          <RowSpan columns={columns}>{footer}</RowSpan>
        </Foot>
      )}
    </Table>
  );
}

export default CollectionTable;
