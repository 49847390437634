import React, { useContext, useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { ComparisonRowProps } from '../../BlockRenderer';
import styles from 'concepts/Extraction/Compare/ComparisonTable/ComparisonTable.module.css';
import { BlockValue } from 'concepts/Extraction/BlocksData';
import { CompareButton } from 'concepts/Extraction/Compare/CompareButton';
import { CompareDecision } from 'concepts/Extraction/Compare/CompareDecision';
import { ComparisonTableContext } from 'concepts/Extraction/Compare/ComparisonTable/ComparisonTable';
import { CompareNotificationBorder } from 'concepts/Extraction/Compare/CompareNotificationBorder';
import { useDebouncedFunction } from 'hooks/useDebouncedFunction';

export type TextFieldComparisonRowProps = ComparisonRowProps;

export const TextFieldComparisonRow = ({
  block,
  reviewerRecords,
  onResolveValue,
  readonly,
}: TextFieldComparisonRowProps): JSX.Element => {
  const formContext = useFormContext();
  const { reviewerNames } = useContext(ComparisonTableContext);

  const resolvedDecidedValue = useWatch({
    control: formContext.control,
    name: `resolvedData.${block.id}`,
  });

  const resolutionState = useWatch({
    control: formContext.control,
    name: `blockResolutions.${block.id}`,
  });

  useEffect(() => {
    setConsensusValue(resolvedDecidedValue?.value || '');
  }, [resolvedDecidedValue]);

  const decidedValue = resolvedDecidedValue?.value || '';
  const [userResolved, setUserResolved] = useState<boolean>(false);
  const [consensusValue, setConsensusValue] = useState<BlockValue>(
    decidedValue
  );

  const debouncedResolve = useDebouncedFunction(
    (id, value) => onResolveValue(id, value),
    300
  );

  const handleResolveValue = (value: BlockValue, debounce = true) => {
    setConsensusValue(value);

    debounce
      ? debouncedResolve(block.id, value)
      : onResolveValue(block.id, value);
  };

  return (
    <tr className={styles.row}>
      <th role="rowheader" className={styles.labelCell}>
        {/* Space is here so that the cell is a consistent height, even if label is blank */}
        {block.label} &nbsp;
      </th>
      <td className={styles.decisionCell}>
        <CompareNotificationBorder
          notificationMessage="Check Decision"
          showNotification={
            !userResolved && resolutionState === 'supersededByExtractor'
          }
        >
          <CompareDecision
            readonly={readonly}
            label={block.label}
            resolutionState={resolutionState}
            value={consensusValue as string}
            onResolveValue={handleResolveValue}
          />
        </CompareNotificationBorder>
      </td>
      {reviewerRecords.map((records, index) => {
        const record = records?.[block.id];
        const value = record?.value ?? '';
        const comment = record?.comment ?? '';
        const changed = !!record?.changed;

        const handleClick = () => {
          setConsensusValue(value);
          onResolveValue(block.id, value);
          setUserResolved(true);
        };

        return (
          <td className={styles.reviewerCell} key={index}>
            <CompareNotificationBorder
              notificationMessage="Data edited"
              showNotification={changed}
            >
              <CompareButton
                disabled={readonly}
                onClick={handleClick}
                comment={
                  comment
                    ? { comment, commenter: reviewerNames[index] }
                    : undefined
                }
              >
                {value}
              </CompareButton>
            </CompareNotificationBorder>
          </td>
        );
      })}
    </tr>
  );
};
