import React, { ForwardRefRenderFunction, RefObject, forwardRef } from 'react';
import styles from '../CitationImportHistory.module.scss';

interface Props {
  header: React.ReactNode;
  value: React.ReactNode;
  solid?: boolean;
  clickable?: boolean;
  parentClick?: any;
}

export const StatBox: ForwardRefRenderFunction<HTMLDivElement | null, Props> = (
  { header, value, clickable = false, solid = false, parentClick, ...props },
  ref
) => {
  const classNames = () => {
    const classes = [styles.StatBox];
    if (solid) classes.push(styles.solid);
    if (clickable) classes.push(styles.clickable);

    return classes.join(' ');
  };

  const content = (
    <>
      <div className={styles.StatBox_header}>{header}</div>
      <div className={styles.StatBox_text}>{value}</div>
    </>
  );

  if (clickable) {
    return (
      <button
        {...props}
        ref={ref as RefObject<HTMLButtonElement>}
        onClick={parentClick}
        className={classNames()}
      >
        {content}
      </button>
    );
  }

  return (
    <div {...props} ref={ref} className={classNames()}>
      {content}
    </div>
  );
};

export default forwardRef(StatBox);
