import React from 'react';
import { HaltedStudyModel } from './StudyModel';
import {
  Pagination,
  PaginationMeta,
} from 'concepts/Guidelines/Pagination/Pagination';
import guidelineStyles from 'containers/Guidelines/Guidelines.module.css';
import { HaltedStudy } from 'concepts/Guidelines/Triage/HaltedStudy/HaltedStudy';
import { NoWorkToDo } from 'concepts/Guidelines/NoWorkToDo/NoWorkToDo';

export interface ViewHaltedStudiesProps {
  studies: HaltedStudyModel[];
  paginationMeta: PaginationMeta;
}

export function ViewHaltedStudies({
  studies,
  paginationMeta,
}: ViewHaltedStudiesProps): JSX.Element {
  return (
    <div>
      {studies.length > 0 ? (
        <>
          <article className={guidelineStyles.withShadow}>
            {studies.map((study) => (
              <HaltedStudy key={study.id} study={study} />
            ))}
          </article>
          <Pagination meta={paginationMeta} />
        </>
      ) : (
        <NoWorkToDo message="There is nothing to see here right now!" />
      )}
    </div>
  );
}
