import idx from 'idx';
import React from 'react';
import CancelInvitation from './CancelInvitation';
import ResendInvitation from './ResendInvitation';
import EmailAddress from 'components/EmailAddress';
import FormattedDate from 'components/FormattedDate';
import useCollectionQuery from 'hooks/useCollectionQuery';
import DataTable, { Columns } from 'system/data/DataTable';
import { InvitationsQuery, InvitationsQueryVariables } from 'types/graphql';
import { ID } from 'util/types';

interface Props {
  organizationId: ID;
  query: any;
}

const Invitations = ({ organizationId, query }: Props) => {
  const { data, loading, error, loadMore } = useCollectionQuery<
    InvitationsQuery,
    InvitationsQueryVariables
  >({
    query,
    variables: { organizationId },
    path: ['organization', 'invitations'],
  });

  const refetchQueries = [
    { query, variables: { organizationId, after: null } },
  ];

  const collection = idx(data, (_) => _.organization.invitations.nodes) || [];

  /* eslint-disable react/display-name */
  const columns: Columns<typeof collection> = [
    { heading: 'Name', primary: true, render: ({ name }) => name },
    {
      heading: 'Email Address',
      render: ({ email }) => <EmailAddress address={email} />,
    },
    {
      heading: 'Expiry',
      render: ({ expiresAt }) => <FormattedDate date={expiresAt} />,
    },
    {
      heading: 'Re-send invitation',
      render: (i) => (
        <ResendInvitation invitation={i} organizationId={organizationId} />
      ),
    },
    {
      heading: 'Cancel invitation',
      render: (i) => (
        <CancelInvitation invitation={i} refetchQueries={refetchQueries} />
      ),
    },
  ];
  /* eslint-enable react/display-name */

  return (
    <DataTable
      loading={loading}
      error={error}
      loadMore={loadMore}
      collection={collection}
      columns={columns}
      empty="There are no pending invitations"
    />
  );
};

export default Invitations;
