import React, { useState } from 'react';
import styles from './StudyList.module.scss';
import Collapser from './Collapser';
import {
  Button,
  Icon,
  IconList,
  Link,
  List,
  ListItem,
  Text,
} from 'components/core';
import { Reference as StudyReference, Study } from 'types';
import { useMoveToScreeningContext } from 'pages/AutoExclusions/AutoExclusionsPage';

type ReferenceProps = {
  reference: StudyReference;
};

const Reference = ({ reference }: ReferenceProps) => {
  const [showAbstract, setShowAbstract] = useState(true);
  if (!reference) {
    return null;
  }

  const referenceId = reference.ref_id;
  const referenceDOI = reference.doi;

  // Prepend DOI domain if it isn't present, else return the untouched url
  const ensureDomain = (doi: string): string => {
    const doiPrefix = 'https://dx.doi.org/';
    return doi.startsWith(doiPrefix) ? doi : `${doiPrefix}${doi}`;
  };

  return (
    <>
      <Text variant="weak" size="sm">
        {reference.authors}
      </Text>
      <Text variant="weak">{reference.journal_info}</Text>
      <Text variant="weak">{reference.publisher_info}</Text>

      <div className={styles.inlineText}>
        {referenceDOI && (
          <Text variant="weak" size="sm">
            DOI:{' '}
            <Link to={ensureDomain(referenceDOI)} variant="primary" external>
              {referenceDOI}
            </Link>
          </Text>
        )}
        {referenceId && referenceDOI && <span className={styles.dot}>·</span>}
        {referenceId && <Text variant="weak">Ref ID: {referenceId}</Text>}
      </div>

      {reference.abstract && (
        <div>
          <Button
            onClick={() => setShowAbstract(!showAbstract)}
            className={styles.abstractButton}
            variant="outline"
            size="xs"
          >
            Abstract
            <Icon
              icon={
                showAbstract
                  ? IconList.light.faAngleUp
                  : IconList.light.faAngleDown
              }
            />
          </Button>
          {showAbstract && (
            <Text variant="medium" size="sm" className={styles.abstract}>
              {reference.abstract}
            </Text>
          )}
        </div>
      )}
    </>
  );
};

type StudyListProps = {
  studies: Study[];
};

const StudyList = ({ studies }: StudyListProps) => {
  const { moveStudyToScreening } = useMoveToScreeningContext();
  const [collapseStudies, setCollapseStudies] = useState<
    Record<number, boolean>
  >({});

  return (
    <List className={styles.studyList} component="ol">
      {studies.map((study) => {
        const primaryReference = study.references && study.references[0];
        const readableStudyId = study.study_id;
        const studyId = study.id;
        const isCollapsing = collapseStudies[studyId];

        return (
          <ListItem key={study.id}>
            <Collapser
              collapse={isCollapsing}
              onAfterCollapse={() => moveStudyToScreening(studyId)}
            >
              <div className={styles.study}>
                <div className={styles.studyDescription}>
                  <Text className={styles.studyHeader} size="sm">
                    #{study.unique_index.toString()}
                    {readableStudyId ? ` - ${readableStudyId}` : ''}
                  </Text>
                  <Text component="h4" size="md" className={styles.studyTitle}>
                    {study.title}
                  </Text>

                  <Reference reference={primaryReference} />

                  <Button
                    variant="ghost"
                    type="brand"
                    size="xs"
                    isLoading={isCollapsing}
                    onClick={() =>
                      setCollapseStudies((studies) => ({
                        ...studies,
                        [studyId]: true,
                      }))
                    }
                    className={styles.moveToScreeningButton}
                  >
                    <Icon icon={IconList.light.faArrowLeftRotate} />
                    Move to screening
                  </Button>
                </div>
                <div className={styles.reasonContainer}>
                  <Text
                    variant="neutral"
                    size="sm"
                    className={styles.reasonHeader}
                  >
                    Reason
                  </Text>
                  {study.auto_exclusion_names.map((exclusion) => (
                    <Text size="sm" key={exclusion}>
                      {exclusion}
                    </Text>
                  ))}
                </div>
              </div>
              <hr className={styles.break} />
            </Collapser>
          </ListItem>
        );
      })}
    </List>
  );
};

export default StudyList;
