import React from 'react';
import { Text } from 'components/core/index';
import { Props as TextProps } from 'components/core/Typography/components/Text/Text';

const MenuHeadingHeading = ({
  size = 'xs',
  variant = 'weak',
  component = 'h3',
  ...props
}: Partial<TextProps>) => {
  return (
    <Text size={size} variant={variant} component={component} {...props} />
  );
};

export default MenuHeadingHeading;
