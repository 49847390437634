import React from 'react';
import { getIn } from 'timm';
import Dropdown, { OptionType } from 'components/Dropdown';
import { SetByKeyFn } from 'hooks/useCollectionQuery';
import LabelFor from 'system/elements/LabelFor';

interface Props<TVariables, TValue> {
  variables: TVariables;
  setVariable: SetByKeyFn<TVariables>;
  variableKey: keyof TVariables | string[]; // pathof T
  options: OptionType<TValue>[]; // TValue = valueOfPath<T, typeof key>
}

function FilterDropdown<TVariables, TValue>({
  variables,
  setVariable,
  variableKey: key,
  options,
}: Props<TVariables, TValue>) {
  const path = Array.isArray(key) ? key : ([key] as string[]);
  // return value of getIn is TVariables, which is incorrect:
  const selected = (getIn(variables, path as any) as unknown) as TValue;

  return (
    <LabelFor
      className="reviewFilter"
      input={
        <Dropdown
          options={options}
          onChange={(value) =>
            setVariable(
              path,
              (value as unknown) as TVariables[keyof TVariables]
            )
          }
          selected={selected}
        />
      }
    ></LabelFor>
  );
}

export default FilterDropdown;
