import React from 'react';

import { Link as RouterLink } from 'react-router-dom';
import { Props } from './Link.d';
import styles from './Link.module.scss';
import { Icon, IconList } from 'components/core';
import { useReactRouterLink, convertUrlToPath } from 'components/core/router';

export const Link = ({
  to,
  variant,
  onClick,
  component,
  className,
  htmlType,
  external = false,
  children,
  ...props
}: Props) => {
  const shouldUseRouterLink = useReactRouterLink(to);
  const path = convertUrlToPath(to);

  let componentType = component;
  if (!componentType)
    componentType = to ? (shouldUseRouterLink ? RouterLink : 'a') : 'button';

  const classes = [styles.Link];
  if (!to) classes.push(styles.LinkButton);
  if (variant) classes.push(styles[variant]);
  if (className) classes.push(className);

  return React.createElement<any>(
    componentType,
    {
      to: path,
      href: path,
      className: classes.join(' ') || '',
      onClick,
      type: htmlType,
      target: external ? '_blank' : undefined,
      rel: external ? 'noopener noreferrer' : undefined,
      ...props,
    },
    children,
    external ? (
      <Icon
        icon={IconList.light.faArrowUpRightFromSquare}
        className={styles.LinkIcon}
      />
    ) : (
      ''
    )
  );
};

export default Link;
