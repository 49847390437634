import React from 'react';
import { fullTextReviewVoteCounts } from '../ReviewersRequired/ReviewersRequired';
import styles from './ReviewersRequiredFTRConfirmationModal.module.scss';
import {
  Modal,
  ModalBody,
  ModalHeader,
  Text,
  Button,
  Message,
  ModalFooter,
} from 'components/core';
import { Stack } from 'components/layout';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onContinue: () => void;
  votes: fullTextReviewVoteCounts;
}

const ReviewersRequiredFTRConfirmationModal = ({
  votes,
  isOpen,
  onClose,
  onContinue,
}: Props) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalHeader>Switch to single reviewer</ModalHeader>
      <ModalBody>
        <Stack spacing={3}>
          <Message type="warning" variant="prominent" dismissible={false}>
            <Text>
              It is not possible to dual screen these studies once they have
              moved
            </Text>
          </Message>

          <Text size="sm">
            {votes.total.toString()} studies have votes from one reviewer.
          </Text>

          <div>
            <Text size="sm" className={styles.vote} data-testid="include-votes">
              <Text className={styles.voteType} component="span" variant="weak">
                Included:
              </Text>
              <Text className={styles.voteCount} component="span">
                {votes.include.toString()}
              </Text>
            </Text>

            <Text size="sm" className={styles.vote} data-testid="exclude-votes">
              <Text className={styles.voteType} component="span" variant="weak">
                Excluded:
              </Text>
              <Text className={styles.voteCount} component="span">
                {votes.exclude.toString()}
              </Text>
            </Text>
          </div>

          <Text size="sm">
            The studies with an &quot;Include&quot; vote will be{' '}
            <b>permanently</b> moved to Included. The studies with an
            &quot;Exclude&quot; vote will be <b>permanently</b> moved to
            Excluded. Once they’ve been moved forward they cannot be moved back
            in bulk.
          </Text>
        </Stack>
      </ModalBody>
      <ModalFooter>
        <Button
          htmlType="button"
          type="neutral"
          variant="outline"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          htmlType="submit"
          type="brand"
          variant="solid"
          onClick={onContinue}
        >
          Continue
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ReviewersRequiredFTRConfirmationModal;
