import React, { useEffect } from 'react';
import styles from './PrimaryNavigation.module.scss';
import { GrooveManager, HelpDropdown, ProfileDropdown } from './components';
import {
  Avatar,
  Link,
  Button,
  Icon,
  IconList,
  Text,
  Tooltip,
  TooltipTrigger,
  TooltipContent,
} from 'components/core';
import { SearchBar, searchStudies } from 'components/shared/SearchBar';
import CovidenceLogo from 'assets/images/logos/covidence-full-white.svg';

export interface Plan {
  name: string;
  expired?: boolean;
}

export interface Organization {
  id: string;
  name: string;
  isAdmin: boolean;
}

export interface Reviewer {
  account: { plan: Plan };
  organizations?: Organization[];
  first_name?: string;
  last_name?: string;
  email?: string;
  admin?: boolean;
  can_proxy?: boolean;
}
interface Props {
  myDiscussionPath?: string;
  logoPath: string;
  showGroove?: boolean;
  reviewPath?: string;
  reviewTitle?: string;
  reviewer?: Reviewer;
  searchPath?: string;
}

const PrimaryNavigation = ({
  myDiscussionPath,
  logoPath,
  searchPath,
  showGroove = true,
  reviewPath,
  reviewTitle,
  reviewer = { account: { plan: { name: '', expired: false } } },
}: Props) => {
  useEffect(() => {
    if (showGroove) {
      GrooveManager.getInstance().init();
    }
  }, [showGroove]);

  return (
    <nav className={styles.PrimaryNavigation} id="react-ds-primary-nav">
      <div className={styles.PrimaryNavigation_content}>
        <Link className={styles.PrimaryNavigation_logo} to={logoPath}>
          <img
            src={CovidenceLogo}
            alt="Covidence Logo"
            height="24"
            width="127"
          />
        </Link>
        {reviewPath && (
          <Button
            type="brand"
            size="sm"
            className={styles.PrimaryNavigation_reviewButton}
            to={reviewPath}
            isNaked
            data-testid={'primary-navigation'}
          >
            <Text
              component="span"
              className={styles.PrimaryNavigation_reviewButton_text}
            >
              {reviewTitle}
            </Text>
          </Button>
        )}
        <div className={styles.PrimaryNavigation_right}>
          {searchPath && (
            <SearchBar
              className={styles.xsHidden}
              onSearch={(term) => searchStudies(term, searchPath)}
            />
          )}
          {myDiscussionPath && (
            <Tooltip>
              <TooltipTrigger>
                <Button
                  to={myDiscussionPath}
                  iconOnly
                  size="md"
                  type="brand"
                  className={styles.PrimaryNavigation_iconButton}
                  aria-label="Discussions"
                  data-testid="discussionsLink"
                  data-name="my-discussions"
                >
                  <Icon icon={IconList.light.faMessage} />
                </Button>
              </TooltipTrigger>
              <TooltipContent>Discussions</TooltipContent>
            </Tooltip>
          )}
          <HelpDropdown>
            <Tooltip>
              <TooltipTrigger>
                <div className={styles.PrimaryNavigation_menuButtonContent}>
                  <Icon icon={IconList.light.faQuestionCircle} />
                </div>
              </TooltipTrigger>
              <TooltipContent>Help</TooltipContent>
            </Tooltip>
          </HelpDropdown>
          <ProfileDropdown reviewer={reviewer}>
            <Tooltip>
              <TooltipTrigger>
                <div className={styles.PrimaryNavigation_menuButtonContent}>
                  <Avatar
                    name={`${reviewer.first_name} ${reviewer.last_name}`}
                    size="sm"
                  />
                </div>
              </TooltipTrigger>
              <TooltipContent>{`${reviewer.first_name} ${reviewer.last_name}`}</TooltipContent>
            </Tooltip>
          </ProfileDropdown>
        </div>
      </div>
    </nav>
  );
};

export default PrimaryNavigation;
