export interface ImportJob {
  id: string;
  status: string;
  file_name: string;
  uploaded_by: string;
  uploaded_when: string;
  found: number;
  found_with_full_text: number;
  files_to_upload: number;
  files_uploaded: number;
  import_errors: string[];
  expected_files: ExpectedFile[];
  references_to_update: number;
}

export interface ExpectedFile {
  file_name: string;
  attachable_id: string;
  attachable_type: string;
}

export interface MatchedFile extends ExpectedFile {
  file: File;
}

export enum ImportError {
  FAILED_TO_PARSE = 1,
  NO_STUDIES_MISSING_FULL_TEXT,
  NO_STUDIES_MISSING_PDF,
}

export type ImportJobProgress =
  | 'not-started'
  | 'queued'
  | 'in-progress'
  | 'complete';
