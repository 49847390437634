import { gql } from '@apollo/client';

const DEMOTE_ORGANIZATION_ADMINSTRATOR_TO_MEMBER = gql`
  mutation demoteOrganizationAdministratorToMember(
    $organizationId: ID!
    $administratorId: ID!
  ) {
    demoteOrganizationAdministratorToMember: demoteOrganizationAdministratorToMember(
      input: {
        organizationId: $organizationId
        administratorId: $administratorId
      }
    ) {
      success
      errors {
        message
      }

      membership {
        id
        status
      }
    }
  }
`;

export default DEMOTE_ORGANIZATION_ADMINSTRATOR_TO_MEMBER;
