import React from 'react';
import styles from '../ModalHeader.module.scss';
import { Heading } from 'components/core';
import { Props as HeadingProps } from 'components/core/Typography/components/Heading/Heading';

interface ModalHeaderProps extends Omit<HeadingProps, 'type' | 'component'> {
  type?: HeadingProps['type'];
  component?: HeadingProps['component'];
}

const ModalHeaderHeading = ({
  className,
  type = 'h4',
  component = 'h2',
  ...props
}: ModalHeaderProps) => {
  const classes = [styles.ModalHeaderHeading];
  if (className) classes.push(className);

  return (
    <Heading
      type={type}
      component={component}
      className={classes.join(' ')}
      {...props}
    />
  );
};

export default ModalHeaderHeading;
