import React from 'react';

import cn from 'classnames';
import styles from './NavigationTabs.module.scss';

export const NavigationTabs = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className={styles.outerWrapper}>
      <div className={`${styles.statusBarContents}`}>{children}</div>
    </div>
  );
};

interface NavigationTabItemProps {
  name: string;
  count?: number;
  url: string;
  active?: boolean;
  countAriaLabel?: string;
  badge?: () => JSX.Element;
}

export const NavigationTabItem = (
  props: Omit<NavigationTabItemProps, 'badge'>
) => {
  return <NavigationTabItemWithBadge {...props} badge={undefined} />;
};

export const NavigationTabItemWithBadge = ({
  name,
  count,
  countAriaLabel,
  url,
  active,
  badge,
}: NavigationTabItemProps) => {
  const showCount = count || count === 0;
  return (
    <div className={styles.item}>
      <div className={cn(styles.activeWrapper, active && styles.active)}>
        <a
          className={cn(active && styles.activeText)}
          href={url}
          aria-label={countAriaLabel}
        >
          <div className={styles.link}>
            {name}
            {showCount && <span className={styles.count}>{count}</span>}
            {badge && badge()}
          </div>
        </a>
      </div>
    </div>
  );
};
