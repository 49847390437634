import React from 'react';
import styles from './HeadwayMessageCount.module.scss';

interface Props {
  count: number;
}

const userFriendlyCount = (count: number): string => {
  return count <= 9 ? count.toString() : '9+';
};

const HeadwayMessageCount = ({ count }: Props) => {
  if (count === 0) {
    return null;
  }

  return (
    <span className={styles.Count} data-headway-count>
      {userFriendlyCount(count)}
    </span>
  );
};

export default HeadwayMessageCount;
