import React, { FunctionComponent, ComponentPropsWithoutRef } from 'react';
import { background, BackgroundProps } from 'styled-system';
import styled from 'system/styled';
import Box from 'system/base/Box';

import LoadingImage from 'assets/images/loading.gif';
import LargeLoadingImage from 'assets/images/LoadingBig.gif';

const BackgroundBox = styled(Box)<BackgroundProps>(background);

interface Props
  extends Omit<ComponentPropsWithoutRef<typeof Box>, 'width' | 'height'> {
  size?: 'small' | 'large';
}

const Spinner: FunctionComponent<Props> = ({ size, ...props }) => (
  <BackgroundBox
    {...props}
    role="img"
    aria-label="Loading"
    backgroundRepeat="no-repeat"
    backgroundPosition="center"
    backgroundImage={`url(${
      size === 'large' ? LargeLoadingImage : LoadingImage
    })`}
    width={size === 'large' ? 5 : 4}
    height={size === 'large' ? 5 : 4}
  />
);

Spinner.defaultProps = {
  size: 'small',
};

export default Spinner;
