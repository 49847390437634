import React from 'react';
import { useFormContext } from 'react-hook-form';
import { BlockValue } from '../BlocksData';
import {
  updateTableResolutionState,
  updateResolutionsOnTableResolutionChange,
  TableResolutionState,
} from '../ViewModel/tables';
import { OnResolveTableCell } from './types';
import ComparisonTable from './ComparisonTable/ComparisonTable';
import { CompareFormProps } from 'containers/Extraction/Compare';

interface CompareDataExtractionFormProps {
  form?: CompareFormProps;
  completed: boolean;
}

export function CompareDataExtractionForm({
  form,
  completed,
}: CompareDataExtractionFormProps): JSX.Element {
  const formContext = useFormContext();

  if (form == null) {
    return <div>There is no template</div>;
  }

  const reviewers = form.reviewers;

  if (reviewers.length === 0) {
    return (
      <div>
        This study has not been reviewed. It must be reviewed by two people for
        it to be compared.
      </div>
    );
  }

  const onResolveTableCell: OnResolveTableCell = (tableBlockID) => (
    cellPosition
  ) => (data) => {
    const [
      resolvedData,
      unresolvedIDs,
      unresolvedTableCells,
      blockResolutions,
    ] = formContext.getValues([
      'resolvedData',
      'unresolvedIDs',
      'unresolvedTableCells',
      'blockResolutions',
    ]);

    const tableResolutionState: TableResolutionState = {
      resolvedCellData: resolvedData[tableBlockID].value,
      unresolvedCellIDs: unresolvedTableCells[tableBlockID],
    };

    const newTableResolutionState = updateTableResolutionState(
      tableResolutionState,
      cellPosition,
      data
    );

    const updatedResolutions = updateResolutionsOnTableResolutionChange(
      {
        resolvedData,
        unresolvedIDs,
        unresolvedTableCells,
        blockResolutions,
      },
      tableBlockID,
      newTableResolutionState
    );

    // We only want to update the blocks we touch. If we update the whole object it will cause additional rerenders

    formContext.setValue(
      `resolvedData.${tableBlockID}`,
      updatedResolutions.resolvedData[tableBlockID],
      { shouldDirty: true }
    );

    formContext.setValue(`unresolvedIDs`, updatedResolutions.unresolvedIDs, {
      shouldDirty: true,
    });

    formContext.setValue(
      `unresolvedTableCells.${tableBlockID}`,
      updatedResolutions.unresolvedTableCells[tableBlockID],
      {
        shouldDirty: true,
      }
    );
  };

  const onResolveValue = (blockID: string, value: BlockValue): void => {
    const resolvedData = formContext.getValues(`resolvedData.${blockID}`);
    const unresolvedIDs = formContext.getValues('unresolvedIDs');
    unresolvedIDs.delete(blockID);
    formContext.setValue(`unresolvedIDs`, unresolvedIDs, { shouldDirty: true });

    formContext.setValue(
      `blockResolutions.${blockID}`,
      'resolvedByConsensusReviewer',
      { shouldDirty: true }
    );

    formContext.setValue(
      `resolvedData.${blockID}`,
      { ...resolvedData, value: value },
      { shouldDirty: true }
    );
  };

  return (
    <ComparisonTable
      blocks={form.template.blocks}
      reviewers={form.reviewers}
      readonly={completed}
      onResolveTableCell={onResolveTableCell}
      onResolveValue={onResolveValue}
    />
  );
}
