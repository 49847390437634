import { gql } from '@apollo/client';

const PROMOTE_ORGANIZATION_MEMBER_TO_ADMINISTRATOR = gql`
  mutation promoteOrganizationMemberToAdministrator(
    $organizationId: ID!
    $memberId: ID!
  ) {
    promoteOrganizationMemberToAdministrator: promoteOrganizationMemberToAdministrator(
      input: { organizationId: $organizationId, memberId: $memberId }
    ) {
      success
      errors {
        message
      }

      membership {
        id
        status
      }
    }
  }
`;

export default PROMOTE_ORGANIZATION_MEMBER_TO_ADMINISTRATOR;
