import React, { ReactNode } from 'react';
import Dialog from 'system/base/Dialog';
import { DangerButton, NakedButton } from 'system/base/Button';

interface Props {
  title: string;
  children: ReactNode;
  confirmationLabel: string;
  onConfirm: () => void;
  onClose: () => void;
}

const ConfirmationModal = ({
  title,
  children,
  confirmationLabel,
  onConfirm,
  onClose,
}: Props) => {
  const footer = (
    <div>
      <DangerButton onClick={onConfirm} mr={2}>
        {confirmationLabel}
      </DangerButton>
      <NakedButton onClick={onClose}>Cancel</NakedButton>
    </div>
  );

  return (
    <Dialog title={title} onDismiss={onClose}>
      {children}
      {footer}
    </Dialog>
  );
};

export default ConfirmationModal;
