import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import {
  DomainBlock,
  readDomainSelectedValue,
} from '../Blocks/Domain/DomainBlock';
import {
  BlockRecords,
  BlockValue,
  readBlockValue,
  readBlockComment,
  BlockRecord,
} from '../BlocksData';
import Domain from '../Blocks/Domain/control/Domain';
import { visuallyHidden } from '../../../cssModules/base';
import styles from './CompareQualityAssessmentForm.module.css';
import { CompareNotificationBorder } from './CompareNotificationBorder';
import { CompareFormProps } from 'containers/Extraction/Compare';
import { TertiaryButton } from 'system/base/Button';
import { useLabelledBy, useDescribedBy } from 'hooks/useAccessibleName';

interface TermAndDefinitionProps {
  term: React.ReactNode;
  definition: React.ReactNode;
  termVisuallyHidden?: boolean;
}
function TermAndDefinition(props: TermAndDefinitionProps): JSX.Element {
  const [termID, labelledby] = useLabelledBy();

  return (
    <>
      <dt
        id={termID}
        className={props.termVisuallyHidden ? visuallyHidden : undefined}
      >
        {props.term}
      </dt>
      <dd {...labelledby}>{props.definition}</dd>
    </>
  );
}

type ReviewerID = string; // TODO: Put this somewhere common
export interface CompareQualityAssessmentDomainProps {
  block: DomainBlock;
  reviewers: Array<{
    id: ReviewerID;
    name: string;
    currentExtraction: { revisionID: string; values: BlockRecords };
  }>;
  reviewerRecords: Array<BlockRecords>;
  onChangeBlock: (
    id: string,
    changes: { value?: BlockValue; comments?: string }
  ) => void;
  readonly: boolean;
}

function displayBlockValue(
  block: DomainBlock,
  record: BlockRecord<BlockValue>
) {
  if (record === null) {
    return null;
  }
  const value = record?.value ?? null;

  return (
    block.judgements.find((judgement) => judgement.id === value)?.label || value
  );
}

function displayBlockComment(record: BlockRecord<BlockValue>) {
  if (record === null) {
    return null;
  }
  return record?.comment || null;
}

interface ViewRecordsProps {
  block: DomainBlock;
  record: BlockRecord<BlockValue>;
}
function ViewRecords({ block, record }: ViewRecordsProps): JSX.Element {
  return (
    <dl>
      <div className={styles.reviewerJudgement}>
        <TermAndDefinition
          term="Judgement:"
          termVisuallyHidden
          definition={displayBlockValue(block, record) || '(No judgement)'}
        />
      </div>
      <div className={styles.reviewerComments}>
        <TermAndDefinition
          term="Supporting text:"
          definition={displayBlockComment(record) || '(No comments)'}
        />
      </div>
    </dl>
  );
}

function CompareQualityAssessmentDomain({
  block,
  reviewers,
  reviewerRecords,
  onChangeBlock,
  readonly,
}: CompareQualityAssessmentDomainProps): JSX.Element {
  const formContext = useFormContext();
  const [labelID, labelledby] = useLabelledBy();
  const [descriptionID, describedby] = useDescribedBy();

  const record = useWatch({
    control: formContext.control,
    name: `resolvedData.${block.id}`,
  });

  const resolutionState = useWatch({
    control: formContext.control,
    name: `blockResolutions.${block.id}`,
  });

  const selected = readDomainSelectedValue(record);

  function RenderReviewer({
    reviewerRecords,
  }: {
    reviewerRecords: BlockRecords;
  }) {
    return (
      <div className={styles.reviewerCellWrapper}>
        <ViewRecords block={block} record={reviewerRecords[block.id]} />
        {!readonly && (
          <TertiaryButton
            onClick={() => {
              const value =
                readBlockValue(reviewerRecords, block.id) || undefined;
              const comments =
                readBlockComment(reviewerRecords, block.id) || undefined;

              onChangeBlock(block.id, { value, comments });
            }}
          >
            Use this judgement
          </TertiaryButton>
        )}
      </div>
    );
  }

  const highlight: boolean = !readonly && resolutionState === 'unresolved';

  return (
    <article {...labelledby} {...describedby} className={styles.block}>
      <h2 id={labelID} className={styles.blockHeading}>
        {block.label}
      </h2>
      <p id={descriptionID} className={styles.blockDescription}>
        {block.description}
      </p>

      {/* eslint-disable-next-line jsx-a11y/role-supports-aria-props */}
      <table
        role="grid"
        aria-invalid={highlight}
        className={`${styles.table} ${highlight ? styles.highlight : ''}`}
      >
        <thead>
          <tr>
            <th role="columnheader" className={styles.finalDecisionHeader}>
              Final Decision
            </th>
            {reviewers.map((reviewer, index) => (
              <th
                role="columnheader"
                className={styles.reviewerHeader}
                data-extracted-data-revision-id={
                  reviewer.currentExtraction.revisionID
                }
                key={index}
              >
                {reviewer.name}{' '}
                {reviewers.length > 1 ? (
                  <>
                    {' '}
                    <small>(reviewer {index + 1})</small>
                  </>
                ) : null}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className={styles.decisionCell}>
              {readonly ? (
                <ViewRecords block={block} record={record} />
              ) : (
                <CompareNotificationBorder
                  notificationMessage="Check decision"
                  showNotification={resolutionState === 'supersededByExtractor'}
                >
                  <Domain
                    editable
                    hideLabels
                    block={block as DomainBlock}
                    invalid={resolutionState === 'unresolved'}
                    selected={selected}
                    comments={record?.comment || null}
                    onChange={(newValue) => {
                      onChangeBlock(block.id, { value: newValue });
                    }}
                    onChangeComments={(newComment) => {
                      onChangeBlock(block.id, { comments: newComment });
                    }}
                    onClick={() => {
                      onChangeBlock(block.id, {}); //Just  set the resolutionState
                    }}
                  />
                </CompareNotificationBorder>
              )}
            </td>
            {reviewerRecords.map((reviewer: BlockRecords, index) => {
              const changed = !!reviewer[block.id]?.changed;
              return (
                <td className={styles.reviewerCell} key={index}>
                  <CompareNotificationBorder
                    notificationMessage="Data Edited"
                    showNotification={changed}
                  >
                    <RenderReviewer reviewerRecords={reviewer} />
                  </CompareNotificationBorder>
                </td>
              );
            })}
          </tr>
        </tbody>
      </table>
    </article>
  );
}

export interface CompareQualityAssessmentFormProps {
  form?: CompareFormProps;
  completed: boolean;
}

export function CompareQualityAssessmentForm({
  form,
  completed,
}: CompareQualityAssessmentFormProps): JSX.Element {
  const formContext = useFormContext();
  if (form == null) {
    return <div>There is no template</div>;
  }

  const reviewers = form.reviewers;

  if (reviewers.length === 0) {
    return (
      <div>
        This study has not been reviewed. It must be reviewed by two people for
        it to be compared.
      </div>
    );
  }

  return (
    <>
      {form.template.blocks.map((block) => {
        return (
          <CompareQualityAssessmentDomain
            key={block.id}
            block={block as DomainBlock}
            readonly={completed}
            reviewers={form.reviewers}
            reviewerRecords={reviewers.map(
              (reviewer) => reviewer.currentExtraction.values
            )}
            onChangeBlock={(blockID, { value, comments }) => {
              const resolvedData = formContext.getValues(
                `resolvedData.${blockID}`
              );

              const updatedBlock = {
                ...resolvedData,
              };

              if (value || comments) {
                if (value !== undefined) {
                  updatedBlock.value = value;
                }

                if (comments !== undefined) {
                  updatedBlock.comment = comments;
                }

                formContext.setValue(`resolvedData.${blockID}`, updatedBlock, {
                  shouldDirty: true,
                });
              }

              formContext.setValue('unresolvedIDs', new Set(), {
                shouldDirty: true,
              });

              formContext.setValue(
                `blockResolutions.${blockID}`,
                'resolvedByConsensusReviewer',
                {
                  shouldDirty: true,
                }
              );
            }}
          />
        );
      })}
    </>
  );
}
