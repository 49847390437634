import React, { useState, useEffect } from 'react';
import GrooveManager from './GrooveManager';

import styles from './GrooveWidget.module.scss';
import { Icon, IconList } from 'components/core/Icon';

interface Props {
  grooveManager: GrooveManager;
}

const GrooveButton = ({ grooveManager }: Props) => {
  const [isOpen, setOpen] = useState(grooveManager.isOpen);

  useEffect(() => {
    grooveManager.addListener((newState) => {
      setOpen(newState);
    });
  }, [grooveManager]);

  return (
    <button
      className={`${styles.grooveButton} ${isOpen ? styles.open : ''}`}
      onClick={(e) => {
        e.preventDefault();
        grooveManager.setOpen(!grooveManager.isOpen);
      }}
    >
      <Icon icon={IconList.light.faClose} className={styles.buttonIcon}>
        {isOpen ? 'Close' : undefined}
      </Icon>
      <div className={styles.buttonText} aria-hidden={isOpen}>
        Need help?
      </div>
    </button>
  );
};

export default GrooveButton;
