import React, { ReactNode } from 'react';
import styles from './BannerActions.module.scss';

interface BannerActionsProps {
  children: ReactNode;
  className?: string;
}

const BannerActions = ({ children, className }: BannerActionsProps) => {
  const classNames = `${styles.BannerActionsContainer} ${className}`;

  return <div className={classNames}>{children}</div>;
};

export default BannerActions;
