import React from 'react';
import styles from './FullTextImportJobHeader.module.css';
import Icon from 'system/base/Icon';

interface Props {
  fileName: string;
  uploadedBy?: string | null;
  uploadedWhen: string;
}

const FullTextImportJobHeader = ({
  fileName,
  uploadedBy,
  uploadedWhen,
}: Props) => {
  if (!uploadedBy) {
    return (
      <span className={styles.FullTextImportJobHeader__UploadedBy}>
        {`Uploaded ${uploadedWhen}`}
      </span>
    );
  }

  return (
    <div className={styles.FullTextImportJobHeader}>
      <span className={styles.FullTextImportJobHeader__fileName}>
        {fileName}
      </span>
      <br />
      <span className={styles.FullTextImportJobHeader__UploadedBy}>
        Uploaded by {`${uploadedBy}`}
        <Icon
          name="circle"
          className={styles.FullTextImportJobHeader__DotSeperator}
        />
        {uploadedWhen}
      </span>
    </div>
  );
};

export default FullTextImportJobHeader;
