import * as Requests from '../../query';
import {
  ImportJob,
  ImportJobProgress,
} from 'components/FullTextBulkUploader/types';

type UploadXMLToS3Args = {
  file: File;
  importJobId: string;
  reviewId: string;
  setUploadProgress: Callbacks['setUploadProgress'];
};

const uploadXMLToS3 = ({
  file,
  importJobId,
  reviewId,
  setUploadProgress,
}: UploadXMLToS3Args) => {
  return new Promise<string>((resolve, reject) => {
    Requests.uploadXMLToS3(file, reviewId, importJobId, {
      onProgress: (progress) => {
        setUploadProgress(progress);
      },
      onError: () => {
        reject({ message: `Failed to upload file to Amazon S3` });
      },
      onFinish: () => {
        resolve(importJobId);
      },
    });
  });
};

const saveDocument = (file: File, importJobId: string) => {
  return new Promise<string>((resolve) => {
    Requests.saveFulltextImportDocument(
      file,
      'Import::FulltextImport',
      importJobId
    ).then(() => {
      resolve(importJobId);
    });
  });
};

type PollCallbacks = Pick<
  Callbacks,
  | 'onPollingComplete'
  | 'stopPolling'
  | 'startPolling'
  | 'setImportJobProgress'
  | 'setSaveDocumentInProgress'
>;

interface PollImportJobStatusArgs {
  importJobId: string;
  reviewId: string;
  callbacks: PollCallbacks;
}

const pollImportJobStatus = (args: PollImportJobStatusArgs) => {
  const {
    reviewId,
    importJobId,
    callbacks: {
      onPollingComplete,
      stopPolling,
      startPolling,
      setImportJobProgress,
      setSaveDocumentInProgress,
    },
  } = args;

  return new Promise<boolean>((resolve) => {
    startPolling(() => {
      Requests.pollImportJobStatus(reviewId, importJobId).then(
        (job: ImportJob) => {
          setSaveDocumentInProgress(false);
          setImportJobProgress({
            importJobStatus: job.status === 'new' ? 'queued' : 'in-progress',
            importJob: job,
          });

          if (job.status !== 'new' && job.status !== 'processing') {
            stopPolling();
            resolve(true);
            onPollingComplete(job);
          }
        }
      );
    });
  });
};

export interface Callbacks {
  setUploadProgress: (progress: number) => void;
  onError: (message: string) => void;
  startPolling: (functionToPoll: () => void) => void;
  stopPolling: () => void;
  onPollingComplete: (job: ImportJob) => void;
  setSaveDocumentInProgress: (isInProgress: boolean) => void;
  setImportJobProgress: ({
    importJob,
    importJobStatus,
  }: {
    importJobStatus: ImportJobProgress;
    importJob?: ImportJob;
  }) => void;
}

export const createImportJob = (
  file: File,
  reviewId: string,
  callbacks: Callbacks
) => {
  const {
    setSaveDocumentInProgress,
    onError,
    setUploadProgress,
    setImportJobProgress,
  } = callbacks;
  setUploadProgress(0);

  return Requests.createImportJob(reviewId)
    .then((newImportJob: { id: string }) => {
      return uploadXMLToS3({
        file,
        importJobId: newImportJob.id,
        reviewId,
        setUploadProgress: callbacks.setUploadProgress,
      });
    })
    .then((importJobId: string) => {
      setUploadProgress(0);
      setSaveDocumentInProgress(true);
      setImportJobProgress({ importJobStatus: 'queued' });

      return saveDocument(file, importJobId);
    })
    .then((importJobId: string) => {
      return pollImportJobStatus({ reviewId, importJobId, callbacks });
    })
    .catch((error) => onError(`Internal server error: ${error.message}`));
};

export type createImportJobType = typeof createImportJob;
