import React, { useState } from 'react';
import styles from './BuildEditorInstructions.module.css';
import { NakedButton } from 'system/base/Button';
import { ResetAllWarningDialog } from 'concepts/Extraction/FormBuilder/ResetAllWarningDialog';

export interface BuildEditorInstructionsProps {
  progress: {
    started: number;
    completed: number;
  };
  onReset: () => void;
  showRevertButton: boolean;
  revertButtonOnClick: () => void;
}
export default function BuildEditorInstructions({
  progress,
  onReset,
  showRevertButton,
  revertButtonOnClick,
}: BuildEditorInstructionsProps): JSX.Element {
  const [
    showResetAllWarningDialog,
    setShowResetAllWarningDialog,
  ] = useState<boolean>(false);

  return (
    <aside className={styles.BuildEditorInstructions}>
      <NakedButton
        sx={{ textDecoration: 'underline', paddingRight: 2 }}
        onClick={() => {
          setShowResetAllWarningDialog(true);
        }}
      >
        I want to start from scratch
      </NakedButton>
      <ResetAllWarningDialog
        progress={progress}
        isOpen={showResetAllWarningDialog}
        onDismiss={() => {
          setShowResetAllWarningDialog(false);
        }}
        onConfirm={() => {
          setShowResetAllWarningDialog(false);
          onReset();
        }}
      />
      {showRevertButton && (
        <>
          |
          <div className={styles.revertButtonContainer}>
            <NakedButton
              sx={{
                textDecoration: 'underline',
              }}
              onClick={revertButtonOnClick}
            >
              Revert to published version
            </NakedButton>
          </div>
        </>
      )}
    </aside>
  );
}
