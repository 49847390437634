import React from 'react';
import Icon from 'system/base/Icon';

type CommentPopoverTriggerProps = {
  isPopoverOpen: boolean;
  variant: 'dark' | 'light';
};

/**
 * Currently necessary to forwardRef.
 * https://react-typescript-cheatsheet.netlify.app/docs/advanced/patterns_by_usecase/#wrappingmirroring-a-html-element
 */
export const CommentPopoverTrigger = ({
  isPopoverOpen,
  variant,
}: CommentPopoverTriggerProps): JSX.Element => (
  <Icon
    sx={
      variant === 'dark'
        ? { color: isPopoverOpen ? 'grey' : 'greyLight' }
        : { color: 'white', opacity: isPopoverOpen ? 1 : 0.6 }
    }
    name="comment-alt-lines"
  />
);
