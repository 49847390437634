import React from 'react';
import { VisuallyHidden } from '@reach/visually-hidden';
import { useUID } from 'react-uid';
import TextAreaOnDemand, {
  TextAreaOnDemandProps,
} from '../TextAreaOnDemand/TextAreaOnDemand';
import styles from './CompareDecision.module.css';
import { ResolutionState } from 'containers/Extraction/types';
import {
  CheckboxesBlock,
  CheckboxesBlockValue,
} from 'concepts/Extraction/Blocks/Checkboxes/CheckboxesBlock';
import {
  BaseCheckboxes,
  EditableBaseCheckboxesProps,
} from 'concepts/Extraction/Blocks/Checkboxes/BaseCheckboxes';
import { BlockValue } from 'concepts/Extraction/BlocksData';
import { CellValue } from 'concepts/Extraction/Blocks/Table/BaseTable/types';
import { getInitialCheckboxesBlockValue } from 'concepts/Extraction/Blocks/Checkboxes/utils';

export interface BaseCompareDecisionProps {
  readonly?: boolean;
  label: string;
  resolutionState: ResolutionState;
  onResolveValue:
    | ((value: BlockValue, debounce?: boolean) => void)
    | ((value: CellValue) => void);
}

type TextCompareDecisionProps = BaseCompareDecisionProps & {
  variant?: 'text';
  value: NonNullable<TextAreaOnDemandProps['value']>;
};

type CheckboxesCompareDecisionProps = BaseCompareDecisionProps & {
  variant: 'checkboxes';
  value: CheckboxesBlockValue;
  block: CheckboxesBlock;
};

export type CompareDecisionProps =
  | TextCompareDecisionProps
  | CheckboxesCompareDecisionProps;

const isCheckboxesVariant = (
  props: CompareDecisionProps
): props is CheckboxesCompareDecisionProps => props.variant === 'checkboxes';

interface ReadOnlyWrapperProps {
  children: React.ReactNode;
  label: string;
  id: string;
}

const ReadOnlyWrapper = ({
  children,
  label,
  id,
}: ReadOnlyWrapperProps): JSX.Element => (
  <>
    <VisuallyHidden id={id} data-readonly="true">
      {label}
    </VisuallyHidden>
    <span className={styles.readonlyValue} aria-labelledby={id}>
      {children}
    </span>
  </>
);

interface EditableWrapperProps {
  children: React.ReactNode;
  label: string;
  id: string;
  resolutionState: ResolutionState;
}

const EditableWrapper = ({
  children,
  id,
  label,
  resolutionState,
}: EditableWrapperProps): JSX.Element => (
  <label
    className={
      resolutionState == 'unresolved' ? styles.unresolved : styles.container
    }
  >
    <VisuallyHidden id={id}>{label}</VisuallyHidden>
    {children}
  </label>
);

export const CompareDecision = (props: CompareDecisionProps): JSX.Element => {
  const { readonly, label, onResolveValue, resolutionState } = props;

  const uid = `compare-decision-${useUID()}`;

  const isResolveOnFocus = ['unresolved', 'supersededByExtractor'].includes(
    resolutionState
  );

  if (isCheckboxesVariant(props)) {
    const handleCheckboxesFocus = () => {
      const valueToResolve =
        resolutionState === 'unresolved'
          ? getInitialCheckboxesBlockValue(props.block.options)
          : props.value;

      (onResolveValue as EditableBaseCheckboxesProps['onChange'])(
        valueToResolve
      );
    };

    return readonly ? (
      <ReadOnlyWrapper id={uid} label={label}>
        <BaseCheckboxes isDisabled block={props.block} value={props.value} />
      </ReadOnlyWrapper>
    ) : (
      <EditableWrapper id={uid} label={label} resolutionState={resolutionState}>
        <div
          onFocus={isResolveOnFocus ? handleCheckboxesFocus : undefined}
          className={styles.checkboxesWrapper}
        >
          {resolutionState === 'unresolved' && (
            <div className={styles.checkboxes_decisionRequiredText}>
              Decision required
            </div>
          )}
          <BaseCheckboxes
            aria-labelledby={uid}
            block={props.block}
            value={props.value}
            onChange={onResolveValue as EditableBaseCheckboxesProps['onChange']}
          />
        </div>
      </EditableWrapper>
    );
  }

  const handleFocus = () => {
    const valueToResolve = resolutionState === 'unresolved' ? '' : props.value;
    onResolveValue(valueToResolve, false);
  };

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    onResolveValue(e.target.value);
  };

  return readonly ? (
    <ReadOnlyWrapper id={uid} label={label}>
      {props.value}
    </ReadOnlyWrapper>
  ) : (
    <EditableWrapper id={uid} label={label} resolutionState={resolutionState}>
      <TextAreaOnDemand
        value={props.value}
        className={styles.input}
        aria-labelledby={uid}
        aria-invalid={
          resolutionState !== 'resolvedByConsensusReviewer' ? 'true' : 'false'
        }
        actionRequired={
          resolutionState === 'unresolved' ? 'Decision required' : undefined
        }
        onFocus={isResolveOnFocus ? handleFocus : undefined}
        onChange={handleChange}
      />
    </EditableWrapper>
  );
};
