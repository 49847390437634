import deepmerge from 'deepmerge';

export function formDataToQueryString(formData: FormData): string {
  const dataArray = Array.from(
    formData,
    ([key, value]) =>
      `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`
  );

  return dataArray.length > 0 ? '?' + dataArray.join('&') : '';
}

export function recordToQueryString(
  formData: Record<string, number | string>
): string {
  const dataArray = Object.entries(formData).map(
    ([key, value]) =>
      `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`
  );

  return dataArray.length > 0 ? '?' + dataArray.join('&') : '';
}

// really average coercion of param to javascript types
const coerceParamToType = (input: any) => {
  switch (input) {
    case 'null':
      return null;
    case 'undefined':
      return undefined;
    default:
      return input;
  }
};

interface FilterableVariables<TFilter> {
  filter?: TFilter;
}
export function queryStringToVariables<
  TVariables extends FilterableVariables<TVariables['filter']>
>(
  query: Partial<TVariables> & TVariables['filter'] = {},
  filterKeys: (keyof Exclude<TVariables['filter'], null | undefined>)[]
): Partial<TVariables> {
  // couldn't get the `key` in `.filter` to be indexed other than `string`, so
  // sadly we'll just `as any` the includes and assignment for now.
  const variables = Object.keys(query)
    .filter((key) => !filterKeys.includes(key as any))
    .reduce(
      (obj, key: any) =>
        // if all filter keys are passed in filterKeys, this assertion is true:
        Object.assign(obj, {
          [key]: coerceParamToType(query[key as keyof TVariables]),
        }),
      {} as Partial<TVariables>
    );

  variables.filter = Object.keys(query)
    .filter((key) => filterKeys.includes(key as any))
    .reduce(
      (obj, key) =>
        // if only valid keys of TVariables['filter'] are passed as filterKeys, this assertion is true:
        Object.assign(obj, {
          [key]: coerceParamToType(query[key as keyof TVariables['filter']]),
        }),
      {} as TVariables['filter']
    );

  return variables;
}

export function queryStringToVariablesDefaults<
  TVariables extends FilterableVariables<TVariables['filter']>
>(
  defaults: TVariables,
  query: Partial<TVariables> & TVariables['filter'] = {},
  filterKeys: (keyof Exclude<TVariables['filter'], null | undefined>)[]
): TVariables {
  return deepmerge(defaults, queryStringToVariables(query, filterKeys));
}
