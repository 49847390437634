import React from 'react';
import { Link, LinkProps } from 'theme-ui';
import Icon, { IconName } from './Icon';

export type IconProps = {
  iconName?: IconName;
  iconOnly?: boolean;
};

type InternalVariant =
  | 'Primary'
  | 'Secondary'
  | 'Tertiary'
  | 'Ghost'
  | 'Flat'
  | 'Cochrane';

type VariantProps = IconProps & {
  children: React.ReactNode;
};

export type CovidenceLinkProps = LinkProps & VariantProps;

export const renderVariant = (
  {
    iconName,
    iconOnly = false,
    ...props
  }: React.PropsWithoutRef<CovidenceLinkProps>,
  variant: InternalVariant
): JSX.Element => {
  const titleWhenIconOnly =
    iconOnly === true && typeof props.children === 'string'
      ? props.children
      : undefined;

  return (
    <Link
      {...props}
      variant={variant.toLowerCase()}
      title={titleWhenIconOnly}
      aria-label={titleWhenIconOnly}
    >
      {iconName && (
        <>
          <Icon name={iconName} marginLeft={-1} />{' '}
        </>
      )}
      {!iconOnly && props.children}
    </Link>
  );
};
