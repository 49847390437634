import React from 'react';
import styles from './QualityAssessmentTemplate.module.scss';
import { Button } from 'components/core';
import { IsolationPage } from 'components/shared';
import {
  EXTRACTION_STUDIES_LIST_ROUTE,
  QUALITY_ASSESSMENT_CUSTOM_TEMPLATE_CREATE_ROUTE,
  tokenizeRoute,
} from 'query/routes';
import { getReviewId } from 'query/review';
import {
  QualityAssessmentTemplate as QualityAssessmentTemplateType,
  QualityAssessmentTemplateFormat,
} from 'types/DataExtraction';

export type QualityAssessmentTemplateState = QualityAssessmentTemplateType;

const QualityAssessmentTemplate = () => {
  const reviewId = getReviewId();

  return (
    <IsolationPage
      back={tokenizeRoute(EXTRACTION_STUDIES_LIST_ROUTE, {
        review_id: reviewId,
      })}
      title="Quality assessment template"
    >
      <div className={styles.QualityAssessmentTemplate}>
        <div>
          <Button
            to={tokenizeRoute(QUALITY_ASSESSMENT_CUSTOM_TEMPLATE_CREATE_ROUTE, {
              review_id: reviewId,
              initial_format: QualityAssessmentTemplateFormat.ROB,
            })}
            type="brand"
          >
            Cochrane RoB1 template
          </Button>
          <Button
            to={tokenizeRoute(QUALITY_ASSESSMENT_CUSTOM_TEMPLATE_CREATE_ROUTE, {
              review_id: reviewId,
              initial_format: QualityAssessmentTemplateFormat.EMPTY,
            })}
            type="brand"
          >
            Custom template
          </Button>
        </div>
      </div>
    </IsolationPage>
  );
};

export default QualityAssessmentTemplate;
