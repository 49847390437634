import React, {
  ComponentProps,
  FunctionComponent,
  MouseEventHandler,
} from 'react';
import { Box, Flex } from 'theme-ui';
import { Theme } from '../theme';
import { colorStyles } from '../theme/colors';
import Icon, { IconName } from 'system/base/Icon';
import Space from 'system/utility/Space';
import { GhostButton } from 'system/base/Button';

export type NoticeType = 'success' | 'info' | 'warning' | 'error';

interface Props extends ComponentProps<typeof Flex> {
  type?: NoticeType;
  iconName?: IconName;
  icon?: boolean;
  onDismiss?: MouseEventHandler<HTMLButtonElement>;
}

const typeToColors = (type?: NoticeType): keyof Theme['colorStyles'] => {
  switch (type) {
    case 'success':
      return 'onSuccess';
    case 'error':
      return 'onDanger';
    case 'warning':
      return 'onSecondary';
    case 'info':
    default:
      return 'onPrimary';
  }
};

const typeToIcon = (type?: NoticeType): IconName => {
  switch (type) {
    case 'success':
      return 'check-circle';
    case 'error':
      return 'exclamation-triangle';
    case 'warning':
      return 'exclamation-circle';
    case 'info':
    default:
      return 'info-circle';
  }
};

const Notice: FunctionComponent<Props> = ({
  type,
  icon,
  iconName,
  children,
  onDismiss,
  ...props
}: Props) => (
  <Flex
    sx={{
      alignItems: 'center',
      bg: colorStyles[typeToColors(type)].backgroundColor,
      color: colorStyles[typeToColors(type)].color,
      border: 1,
    }}
    p={2}
    {...props}
  >
    {icon && (
      <Space marginRight={1}>
        <Icon name={iconName || typeToIcon(type)} />
      </Space>
    )}
    {children}
    {onDismiss && (
      <Box
        sx={{
          flexGrow: 2,
          textAlign: 'right',
        }}
      >
        <GhostButton onClick={onDismiss}>Dismiss</GhostButton>
      </Box>
    )}
  </Flex>
);

Notice.defaultProps = {
  type: 'info',
  icon: true,
};

export default Notice;
