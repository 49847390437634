import React, { useState } from 'react';
import idx from 'idx';
import { gql, useMutation, useQuery } from '@apollo/client';
import * as R from 'ramda';
import styles from './StudyListRoot.module.css';
import {
  StudyListQuery,
  ExtractionStudySort,
  MoveStudyToFullTextMutation,
  ExtractionStudiesFilter,
} from 'types/graphql';
import Errors from 'components/Errors';
import { errorMessagesFrom } from 'util/errorMessages';
import Spinner from 'system/elements/Spinner';
import { StudyList } from 'concepts/Extraction/StudyList/StudyList';
import { Study } from 'concepts/Extraction/StudyList/StudyTypes';

import { PrimaryLink } from 'system/base/Link';
import Icon from 'system/base/Icon';

export const STUDY_LIST_QUERY = gql`
  query StudyList(
    $id: ID!
    $count: Int!
    $sort: ExtractionStudySort
    $after: String
    $filter: ExtractionStudiesFilter
    $filterByTags: [String!]
    $studyId: ID
  ) {
    review: node(id: $id) {
      ... on Review {
        tags {
          name
        }
        studies: extractionStudies(
          studyId: $studyId
          filter: $filter
          filterByTags: $filterByTags
          first: $count
          sort: $sort
          sortDirection: ASC
          after: $after
        ) {
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
          nodes {
            id
            studyId
            tags
            notes {
              id
              note
              createdAt
              updatedAt
              author {
                id
                name
              }
            }
            covidenceNumber
            title
            authors
            references {
              nodes {
                id
                title
                authors
                fullText
                referenceUrl
                source
                publisherInfo
                journalInfo
                documents {
                  id
                  primaryDocument
                  url
                  fileName
                }
              }
            }
            actions {
              extractUrl
              compareUrl
              editCompareUrl
            }
            progress {
              status
              consensusSlot {
                assigned
                currentUser
                decider {
                  id
                  name
                }
                lastActivityAgo
                started
                completed
              }
              extractionSlots {
                assigned
                currentUser
                extractor {
                  id
                  name
                }
                lastActivityAgo
                completed
              }
            }
          }
        }
      }
    }
  }
`;

export const MOVE_STUDY_TO_FULL_TEXT_MUTATION = gql`
  mutation MoveStudyToFullText($input: RecordStudyVoteInput!) {
    recordStudyVote(input: $input) {
      success
      errors {
        message
      }
    }
  }
`;

export interface StudyListRootProps {
  currentUserId: string;
  exportPath: string;
  startDiscussionPath?: string;
  reviewId: string;
  filter?: ExtractionStudiesFilter;
  filterStudyId?: string;
}

export default function StudyListRoot({
  currentUserId,
  exportPath,
  startDiscussionPath,
  filter,
  reviewId,
  filterStudyId,
}: StudyListRootProps): JSX.Element {
  const [filterByTags, setFilterByTags] = useState<Array<string>>([]);
  const [sortBy, setSortBy] = useState(ExtractionStudySort.Author);
  const [selectedStudyIDs, updateSelectedStudyIDs] = useState<
    Array<Study['id']>
  >([]);

  const { loading, error, data, refetch, fetchMore } = useQuery<StudyListQuery>(
    STUDY_LIST_QUERY,
    {
      variables: {
        filter: filter,
        filterByTags: filterByTags,
        id: reviewId,
        studyId: filterStudyId,
        count: 25,
        sort: sortBy,
        after: null,
      },
    }
  );

  const [
    moveStudyToFullText,
    { error: moveStudyToFullTextError },
  ] = useMutation<MoveStudyToFullTextMutation>(
    MOVE_STUDY_TO_FULL_TEXT_MUTATION
  );

  let studies: Array<Study> = [];
  if (data?.review?.studies?.nodes) {
    studies = (data?.review?.studies?.nodes as unknown) as Array<Study>;
  }

  const availableTags = (idx(data, (_) => _.review.tags) || []).map(
    (tag) => tag.name
  );

  const updateStudyInStudies = (study: Study) => {
    studies = studies.map((studyItem) => {
      if (studyItem.id == study.id) {
        return study;
      } else {
        return studyItem;
      }
    });
  };

  const el = (
    <>
      <div className={styles.exportButtonContainer}>
        <PrimaryLink href={exportPath}>
          <Icon name="cloud-download" /> Export
        </PrimaryLink>
      </div>
      <div className={styles.container}>
        {error && <Errors errors={errorMessagesFrom(error)} />}
        {moveStudyToFullTextError && (
          <Errors errors={errorMessagesFrom(moveStudyToFullTextError)} />
        )}
        {loading && (
          <div className={styles.loading}>
            <Spinner /> Loading studies…
          </div>
        )}
        {!loading && studies && (
          <StudyList
            currentUserId={currentUserId}
            startDiscussionPath={startDiscussionPath}
            status={filter && filter.status}
            studies={studies}
            availableTags={availableTags}
            filteringByTags={filterByTags}
            onFilterTagChange={setFilterByTags}
            selectedStudyIDs={selectedStudyIDs}
            onUpdateSelectedStudyIDs={updateSelectedStudyIDs}
            hasNextPage={!!data?.review?.studies?.pageInfo?.hasNextPage}
            sortBy={sortBy}
            changeSort={setSortBy}
            updateStudyInStudies={updateStudyInStudies}
            onNextPage={() => {
              fetchMore({
                variables: {
                  after: data?.review?.studies.pageInfo.endCursor,
                },
                updateQuery: (prev, { fetchMoreResult }) => {
                  if (!fetchMoreResult) {
                    return prev;
                  }

                  const newNodes = [
                    ...(prev?.review?.studies.nodes || []),
                    ...(fetchMoreResult.review?.studies.nodes || []),
                  ];
                  return R.mergeDeepRight(fetchMoreResult, {
                    review: { studies: { nodes: newNodes } },
                  }) as any;
                },
              });
            }}
            refetchStudyList={() => {
              updateSelectedStudyIDs([]);
              refetch();
            }}
            onMoveBackToFullText={(studyId) => {
              moveStudyToFullText({
                variables: {
                  input: {
                    studyId: studyId,
                    vote: 'UNDO_CATEGORY',
                    category: 'IN',
                  },
                },
              }).then(() => {
                // Filter moved study out of selected study ids
                updateSelectedStudyIDs(
                  selectedStudyIDs.filter((id) => id !== studyId)
                );
                refetch();
              });
            }}
            reviewId={reviewId}
          />
        )}
      </div>
    </>
  );
  return el;
}
