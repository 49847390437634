import React from 'react';
import { Control, useWatch } from 'react-hook-form';
import { getUnresolvedDecisionsCount } from '../utils';
import { FormValues } from '../Compare';
import { Badge } from 'components/Badge';
interface UnresolvedDecisionsStatusProps {
  isSingleReviewer?: boolean;
  isAllDecisionsResolved: boolean;
  deControl: Control<FormValues>;
  qaControl: Control<FormValues>;
}

export const UnresolvedDecisionsStatus = ({
  isAllDecisionsResolved,
  isSingleReviewer = false,
  deControl,
  qaControl,
}: UnresolvedDecisionsStatusProps): JSX.Element => {
  const [deUnresolvedTableCells, deBlockResolutions] = useWatch({
    control: deControl,
    name: ['unresolvedTableCells', 'blockResolutions'],
  });
  const blockResolutions = useWatch({
    control: qaControl,
    name: 'blockResolutions',
  });

  const { total: unresolvedDecisions } = getUnresolvedDecisionsCount({
    dataExtraction: {
      unresolvedTableCells: deUnresolvedTableCells,
      blockResolutions: deBlockResolutions,
    },
    qualityAssessment: {
      blockResolutions: blockResolutions,
    },
  });

  const noActionRequiredText = isSingleReviewer
    ? 'No decisions required'
    : 'No conflicts to resolve';

  const unresolvedDecisionsAppendText = isSingleReviewer
    ? ` decision${unresolvedDecisions === 1 ? '' : 's'} required`
    : ` conflict${unresolvedDecisions === 1 ? '' : 's'} left to resolve`;

  const showStatus = () => {
    if (isAllDecisionsResolved) return 'Completed';
    if (unresolvedDecisions === 0) return noActionRequiredText;
    return (
      <Badge appendText={unresolvedDecisionsAppendText}>
        {unresolvedDecisions}
      </Badge>
    );
  };

  return <div role="status">{showStatus()}</div>;
};
