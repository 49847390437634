import { useReducer } from 'react';
import { DataType } from 'types';

const CREATING_DATA_TYPES = 'CREATING_DATA_TYPES';
const ERROR_CREATING_DATA_TYPES = 'ERROR_CREATING_DATA_TYPES';
const SUCCESS_CREATING_DATA_TYPES = 'SUCCESS_CREATING_DATA_TYPES';
const ADD_DATA_TYPE = 'ADD_DATA_TYPE';
const REMOVE_DATA_TYPE = 'REMOVE_DATA_TYPE';
const UPDATE_DATA_TYPE = 'UPDATE_DATA_TYPE';
const REMOVE_UNUSED_DATA_TYPES = 'REMOVE_UNUSED_DATA_TYPES';

type ReducerState = {
  creating: boolean;
  dataTypes: DataType[];
  errors: string[];
};

type ReducerAction = {
  type?: string;
  key?: string;
  index?: number;
  data?: any;
};

type ActionData = {
  review_id: string;
  outcome_type: string;
  fields: DataType[];
};

const initialState: ReducerState = {
  creating: false,
  dataTypes: [{}, {}, {}] as DataType[],
  errors: [],
};

function reducer(state: ReducerState, action: ReducerAction) {
  let dataTypes;
  let append;

  const updateIndex = (dataTypes: DataType[]) =>
    dataTypes.map((item, index) => {
      if (index === action.index) {
        return { ...item, ...action.data };
      }
      return item;
    });

  switch (action.type) {
    case CREATING_DATA_TYPES:
      return {
        ...state,
        errors: [],
        creating: true,
      };
    case ERROR_CREATING_DATA_TYPES:
      return {
        ...state,
        errors: action.data,
        creating: false,
      };
    case SUCCESS_CREATING_DATA_TYPES:
      return {
        ...state,
        errors: [],
        creating: true,
      };
    case ADD_DATA_TYPE:
      dataTypes = state.dataTypes;

      return {
        ...state,
        dataTypes: [...state.dataTypes, {}],
      };
    case REMOVE_DATA_TYPE:
      dataTypes = state.dataTypes;
      dataTypes = dataTypes.filter((_, i) => i !== action.data);

      return {
        ...state,
        dataTypes,
      };
    case UPDATE_DATA_TYPE:
      dataTypes = state.dataTypes;
      append = updateIndex(dataTypes);

      return {
        ...state,
        dataTypes: append,
      };
    case REMOVE_UNUSED_DATA_TYPES:
      dataTypes = state.dataTypes;
      dataTypes = dataTypes.filter((d) => Object.keys(d).length > 0);

      return {
        ...state,
        dataTypes,
      };
    default:
      return state;
  }
}

function postData(url: string, data: ActionData) {
  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  };

  return fetch(url, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(data),
  }).then((response) => {
    if (!response.ok) {
      return response.json().then((errorData) => {
        const errorMessage = errorData.errors.join(', ');
        throw new Error(errorMessage);
      });
    }
    return response.json();
  });
}

export function useEffectMeasureModel() {
  const [state, dispatch] = useReducer(reducer, initialState);

  async function createDataTypes(
    outcomeType: string,
    reviewId: string,
    dataTypes: DataType[],
    callback: (data: DataType[]) => void
  ) {
    dispatch({ type: CREATING_DATA_TYPES });
    dispatch({ type: REMOVE_UNUSED_DATA_TYPES });

    const newData = {
      review_id: reviewId,
      outcome_type: outcomeType,
      fields: dataTypes.filter((d: DataType) => Object.keys(d).length > 0),
    } as ActionData;

    await postData('/api/v1/data_types', newData)
      .then((data) => {
        callback(data);
        dispatch({ type: SUCCESS_CREATING_DATA_TYPES });
      })
      .catch((error: { message: string }) => {
        const message = error ? error?.message : 'An error occurred';
        dispatch({
          type: ERROR_CREATING_DATA_TYPES,
          data: new Array(message),
        });
      });
  }

  return [
    state,
    {
      addDataType() {
        dispatch({ type: ADD_DATA_TYPE });
      },
      removeDataType(data: DataType) {
        dispatch({
          type: REMOVE_DATA_TYPE,
          data,
        });
      },
      updateDataType(index: number, data: DataType) {
        dispatch({
          type: UPDATE_DATA_TYPE,
          index,
          data,
        });
      },
      createDataTypes,
    },
  ];
}
