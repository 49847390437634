import React from 'react';
import { MultipleChoiceBlock } from '../MultipleChoiceBlock';
import { InstructionsToExtractors } from '../../shared/InstructionsToExtractors';
import { AddOtherOptionCheckbox } from './settings/AddOtherOptionCheckbox';

export interface MultipleChoiceEditorSettingsProps {
  block: MultipleChoiceBlock;
  onChange: (updatedBlock: MultipleChoiceBlock) => void;
}

export const MultipleChoiceEditorSettings = ({
  block,
  onChange,
}: MultipleChoiceEditorSettingsProps): JSX.Element => (
  <>
    <InstructionsToExtractors block={block} onChange={onChange} />
    <hr />
    <AddOtherOptionCheckbox block={block} onChange={onChange} />
    <hr />
  </>
);
