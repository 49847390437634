import React, { ReactNode } from 'react';

import styles from './Banner.module.scss';

interface BannerProps {
  children: ReactNode;
  className?: string;
  type?: 'info' | 'success' | 'warning' | 'danger';
}

const Banner = ({ className, children, type }: BannerProps) => {
  const classes = [styles.Banner];

  if (type) classes.push(styles[type]);
  if (className) classes.push(className);

  return (
    <div className={classes.join(' ')} role="alert">
      {children}
    </div>
  );
};

export default Banner;
