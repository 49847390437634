import React from 'react';

import styles from './Action.module.css';

import { Stage } from './Home';
import { PrimaryLink, TertiaryLink } from 'system/base/Link';

interface ActionProps {
  title: string;
  url: string;
  stage: Stage;
  count: number;
  text: string;
  type: 'primary' | 'tertiary';
  dataTestId?: string;
}

const Action = ({
  stage,
  url,
  title,
  count,
  text,
  type,
  dataTestId,
}: ActionProps) => {
  const LinkComponent = type === 'primary' ? PrimaryLink : TertiaryLink;
  return (
    <div>
      <div className={styles.title}>{title}</div>
      <div className={styles.count}>{count}</div>
      <div>
        <LinkComponent
          className={styles.button}
          href={url}
          data-testid={dataTestId ? dataTestId : `stage-action-${stage}`}
        >
          {text}
        </LinkComponent>
      </div>
    </div>
  );
};

export default Action;
