import React, { useState } from 'react';
import { GuidelineStudy } from '../../GuidelineStudy/GuidelineStudy';
import { QuestionAssignment } from 'concepts/Guidelines/Triage/QuestionAssignment/QuestionAssignment';
import { StudyModel } from 'containers/Guidelines/Triage/StudyModel';
import { SecondaryButton } from 'system/base/Button';
import styles from 'containers/Guidelines/Triage/Triage.module.css';
import { QuestionAssignmentOptions } from 'containers/Guidelines/Triage/QuestionAssignmentOptions';

export interface QuestionAssignmentsProps {
  study: StudyModel;
  questionOptions: QuestionAssignmentOptions;
}

export function QuestionAssignments({
  study,
  questionOptions,
}: QuestionAssignmentsProps): JSX.Element {
  const [reviewCount, setReviewCount] = useState(1);

  const addForm = (e: React.MouseEvent) => {
    e.preventDefault();
    setReviewCount(reviewCount + 1);
  };

  return (
    <div
      key={study.id}
      data-testid={`assign-study-${study.id}`}
      className={styles.assignmentPanel}
    >
      <div className={styles.assignmentPanelLeft}>
        <GuidelineStudy study={study} />
      </div>
      <div className={styles.assignmentPanelRight}>
        {Array(reviewCount)
          .fill(undefined)
          .map((_, index) => (
            <div key={index}>
              <div className={styles.subformBackground}>
                <QuestionAssignment
                  study={study}
                  questionOptions={questionOptions}
                  index={index}
                />
              </div>
            </div>
          ))}
        <SecondaryButton
          data-testid={`add-assignment-for-study-${study.id}`}
          iconName="plus"
          onClick={addForm}
        >
          Assignment
        </SecondaryButton>
      </div>
    </div>
  );
}
