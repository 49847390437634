import React, { ChangeEvent, useState } from 'react';
import ReviewersRequiredScreenConfirmationModal from '.././ReviewersRequiredScreenConfirmationModal';
import ReviewersRequiredFTConfirmationModal from '../ReviewersRequiredFTRConfirmationModal';
import { FormControl, FormLabel, Select } from 'components/core';
import { Stack } from 'components/layout';

export interface fullTextReviewVoteCounts {
  total: number;
  include: number;
  exclude: number;
}

export interface screenVoteCounts {
  total: number;
  yes: number;
  maybe: number;
  no: number;
}

export interface ReviewersRequiredProps {
  defaultValues: {
    screenUsersRequired: number;
    fullTextReviewUsersRequired: number;
    extractionUsersRequired: number;
  };
  screenVoteCounts: screenVoteCounts;
  fullTextReviewVoteCounts: fullTextReviewVoteCounts;
}

const ReviewersRequired = ({
  defaultValues,
  screenVoteCounts,
  fullTextReviewVoteCounts,
}: ReviewersRequiredProps) => {
  const [screenMenuValue, setScreenMenuValue] = useState(
    defaultValues.screenUsersRequired.toString()
  );
  const [fullTextReviewMenuValue, setFullTextReviewMenuValue] = useState(
    defaultValues.fullTextReviewUsersRequired.toString()
  );
  const [
    isConfirmationScreenModalOpen,
    setIsConfirmationScreenModalOpen,
  ] = useState(false);
  const [isConfirmationFTModalOpen, setIsConfirmationFTModalOpen] = useState(
    false
  );

  const handleScreenMenuChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const requiredReviewers = e.currentTarget.value;
    setScreenMenuValue(requiredReviewers);

    if (requiredReviewers === '1' && screenVoteCounts.total > 0) {
      setIsConfirmationScreenModalOpen(true);
    }
  };

  const handleFTRMenuChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const requiredReviewers = e.currentTarget.value;
    setFullTextReviewMenuValue(requiredReviewers);

    if (requiredReviewers === '1' && fullTextReviewVoteCounts.total > 0) {
      setIsConfirmationFTModalOpen(true);
    }
  };

  const handleScreenValueCancel = () => {
    setScreenMenuValue('2');
    setIsConfirmationScreenModalOpen(false);
  };

  const handleFTRValueCancel = () => {
    setFullTextReviewMenuValue('2');
    setIsConfirmationFTModalOpen(false);
  };

  const handleFTValueContinue = () => {
    setIsConfirmationFTModalOpen(false); // Just close the modal
  };

  const handleScreenValueContinue = () => {
    setIsConfirmationScreenModalOpen(false); // Just close the modal
  };

  return (
    <Stack spacing={4}>
      <FormControl>
        <FormLabel>Reviewers required for screen</FormLabel>
        <Select
          id="screenMenu"
          // Required for rails simple form to pick up the values
          name="review[screen_users_required]"
          value={screenMenuValue}
          onChange={handleScreenMenuChange}
        >
          <option value="1">1</option>
          <option value="2">2</option>
        </Select>
      </FormControl>

      <FormControl>
        <FormLabel>Reviewers required for full text review</FormLabel>
        <Select
          id="fullTextReviewMenu"
          // Required for rails simple form to pick up the values
          name="review[select_users_required]"
          value={fullTextReviewMenuValue}
          onChange={handleFTRMenuChange}
        >
          <option value="1">1</option>
          <option value="2">2</option>
        </Select>
      </FormControl>

      <ReviewersRequiredScreenConfirmationModal
        isOpen={isConfirmationScreenModalOpen}
        onClose={handleScreenValueCancel}
        onContinue={handleScreenValueContinue}
        votes={screenVoteCounts}
      />
      <ReviewersRequiredFTConfirmationModal
        isOpen={isConfirmationFTModalOpen}
        onClose={handleFTRValueCancel}
        onContinue={handleFTValueContinue}
        votes={fullTextReviewVoteCounts}
      />
    </Stack>
  );
};

export default ReviewersRequired;
