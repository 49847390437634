import React, { useState, useCallback, useEffect } from 'react';
import pluralize from 'pluralize';
import { StudyModel, StudyStatusModel } from './StudyModel';
import { TopicModel } from './TopicModel';
import { useConfirmUnsaved } from 'hooks/useConfirmUnsaved';
import styles from 'containers/Guidelines/Triage/Triage.module.css';
import guidelineStyles from 'containers/Guidelines/Guidelines.module.css';
import { StudyForTopicAssignment } from 'concepts/Guidelines/Triage/StudyForTopicAssignment/StudyForTopicAssignment';
import { NoWorkToDo } from 'concepts/Guidelines/NoWorkToDo/NoWorkToDo';
import { PrimaryButton } from 'system/base/Button';

export interface AssignToTopicsProps {
  studies: StudyModel[];
  topics: TopicModel[];
  actions: TopicAssignmentActions;
  statuses: StudyStatusModel;
}

export interface TopicAssignmentActions {
  createGuidelineTriageTopicAssignmentsUrl: string;
}

export function AssignToTopics({
  studies,
  topics,
  actions,
  statuses,
}: AssignToTopicsProps): JSX.Element {
  const initialStudiesChanged = Object.fromEntries(
    studies.map((study) => [study.id, false])
  );
  const [studiesChanged, setStudiesChanged] = useState(initialStudiesChanged);
  const [clean, dirty] = useConfirmUnsaved();

  const countAssignedStudies = () =>
    Object.values(studiesChanged).filter((value) => value === true).length;

  const onStudyChange = useCallback(
    (studyId: number, hasChanged: boolean) => {
      setStudiesChanged({
        ...studiesChanged,
        [studyId]: hasChanged,
      });
    },
    [studiesChanged]
  );

  const decideCleanOrDirtyState = () => {
    countAssignedStudies() === 0 ? clean() : dirty();
  };

  useEffect(decideCleanOrDirtyState);

  return (
    <form
      data-testid="assignTopics"
      method="post"
      action={actions.createGuidelineTriageTopicAssignmentsUrl}
      onSubmit={() => {
        clean();
      }}
    >
      <div>
        <nav className={styles.listNav}>
          <PrimaryButton
            type="submit"
            disabled={countAssignedStudies() === 0}
            style={{ minWidth: '150px' }}
            data-testid="assign-studies-button"
          >
            {`Assign ${countAssignedStudies()} ${pluralize(
              'study',
              countAssignedStudies()
            )}`}
          </PrimaryButton>
        </nav>

        <article className={guidelineStyles.withShadow}>
          <div>
            {studies.map((study) => (
              <StudyForTopicAssignment
                key={study.id}
                study={study}
                topics={topics}
                statuses={statuses}
                onStudyChange={onStudyChange}
              />
            ))}
          </div>
          {studies.length === 0 && <NoWorkToDo />}
        </article>
      </div>
    </form>
  );
}
