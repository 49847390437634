import { gql, useMutation } from '@apollo/client';
import React, { useState } from 'react';
import idx from 'idx';
import styles from 'concepts/Guidelines/TopicExperts/TopicExperts.module.css';
import useCollectionQuery from 'hooks/useCollectionQuery';
import DataTable, { Columns } from 'system/data/DataTable';
import DataWithControls from 'system/layout/DataWithControls';
import { ID } from 'util/types';
import {
  GetTopicExpertsQuery,
  GetTopicExpertsQueryVariables,
  DeleteTopicExpertMutation,
} from 'types/graphql';
import { PrimaryLink } from 'system/base/Link';
import { DangerButton } from 'system/base/Button';
import SuccessMessage from 'components/SuccessMessage';
import ErrorMessage from 'components/ErrorMessage';

export const GET_TOPIC_EXPERTS = gql`
  query GetTopicExperts($guidelineId: ID!) {
    guideline: node(id: $guidelineId) {
      id
      ... on Guideline {
        id
        name
        actions {
          canInviteTopicExpert
          inviteTopicExpertUrl
        }
        topicExperts {
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
          nodes {
            id
            name
            email
            expertTopics {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const DELETE_TOPIC_EXPERT = gql`
  mutation deleteTopicExpert($input: DeleteTopicExpertInput!) {
    deleteTopicExpert(input: $input) {
      success
      errors {
        message
      }
    }
  }
`;
export interface TopicExpertsContext {
  guidelineId: ID;
}

export interface TopicExpertsProps {
  context: TopicExpertsContext;
}

export function TopicExperts({
  context: { guidelineId },
}: TopicExpertsProps): JSX.Element {
  const [successMessage, setSuccessMessage] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string>();

  const { data, loadMore, error, loading } = useCollectionQuery<
    GetTopicExpertsQuery,
    GetTopicExpertsQueryVariables
  >({
    query: GET_TOPIC_EXPERTS,
    variables: { guidelineId },
    path: ['guideline', 'topicExperts'],
  });

  const collection = idx(data, (_) => _.guideline.topicExperts.nodes) || [];
  const { canInviteTopicExpert, inviteTopicExpertUrl } =
    idx(data, (_) => _.guideline.actions) || {};

  const [deleteTopicExpertMutation] = useMutation<DeleteTopicExpertMutation>(
    DELETE_TOPIC_EXPERT
  );

  const confirmMessage =
    'Are you sure you want to remove this topic expert from the guideline?';
  const deleteTopicExpert = ({ id }: { id: ID }) => {
    if (!confirm(confirmMessage)) {
      return Promise.resolve();
    }

    setErrorMessage(undefined);
    setSuccessMessage(undefined);
    return deleteTopicExpertMutation({
      variables: {
        input: {
          id,
        },
      },
      refetchQueries: ['GetTopicExperts'],
    })
      .then((result) => {
        const { data } = result;
        if (data?.deleteTopicExpert?.success) {
          setSuccessMessage('Topic expert deleted successfully');
        } else {
          const errors = data?.deleteTopicExpert?.errors;
          setErrorMessage(
            errors
              ? errors.map((err) => err.message).join('; ')
              : 'Unknown error'
          );
        }
      })
      .catch((e) => {
        setErrorMessage(e.message);
      });
  };

  /* eslint-disable react/display-name */
  const columns: Columns<typeof collection> = [
    {
      heading: 'Name',
      primary: true,
      render: ({ id, name }) => (
        <span data-testid={`reviewer-name-${id}`}>{name}</span>
      ),
    },
    {
      heading: 'Email',
      render: ({ id, email }) => (
        <span data-testid={`reviewer-email-${id}`}>{email}</span>
      ),
    },
    {
      heading: 'Topics',
      render: ({ id, expertTopics }) => (
        <span data-testid={`topic-names-${id}`}>
          {expertTopics.map((expertTopic, i) => (
            <span data-testid="topic-name" key={i} className={styles.topicPill}>
              {expertTopic.name}
            </span>
          ))}
        </span>
      ),
    },
    {
      heading: 'Actions',
      render: ({ id }) => (
        <span data-testid={`topic-expert-actions-${id}`}>
          <DangerButton onClick={() => deleteTopicExpert({ id })}>
            Delete
          </DangerButton>
        </span>
      ),
    },
  ];
  /* eslint-enable react/display-name */

  return (
    <section className={styles.container} data-testid="topic-experts">
      <h1 className={styles.title}>Topic Experts</h1>
      <p className={styles.subtleDescriptor}>
        These guideline topic experts can be mentioned when creating comments.
        They will be notified that their input has been requested.
      </p>

      {canInviteTopicExpert && (
        <PrimaryLink data-testid="invitations-link" href={inviteTopicExpertUrl}>
          Manage invitations
        </PrimaryLink>
      )}

      {(errorMessage || successMessage) && (
        <div style={{ marginTop: '20px', marginBottom: '10px' }}>
          {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
          {!errorMessage && successMessage && (
            <SuccessMessage>
              <span className="notice alert">{successMessage}</span>
            </SuccessMessage>
          )}
        </div>
      )}

      <DataWithControls>
        <DataTable
          collection={collection}
          columns={columns}
          loadMore={loadMore}
          error={error}
          loading={loading}
        />
      </DataWithControls>
    </section>
  );
}
