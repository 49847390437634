export const getHttpErrorMessage = (status: number) => {
  const HTTP_ERROR_MESSAGES: { [keys: number]: string } = {
    400: "Oops! Something's wrong with the template data",
    401: 'Please log in again to access this template',
    403: "Sorry, but it looks like you don't have permission to update this template",
    404: "We're sorry, but the template you're looking for could not be found",
  };
  const GENERIC_ERROR_MESSAGE =
    "Oh no! We're experiencing some technical difficulties right now, please try again later";

  return HTTP_ERROR_MESSAGES[status] || GENERIC_ERROR_MESSAGE;
};

export * from './CitationImport';
export * from './Review';
export * from './ReviewImportSources';
export * from './DataExtractionTemplate';
export * from './CitationImportSources';
export * from './QualityAssessmentTemplate';
export * from './AutoExclusion';
export * from './Features';
