import React, { FunctionComponent, ReactNode, AriaAttributes } from 'react';
import styles from './Heading.module.scss';

export interface Props extends AriaAttributes {
  type: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  component?: string | FunctionComponent<any>;
  variant?:
    | 'neutral'
    | 'medium'
    | 'brand'
    | 'primary'
    | 'danger'
    | 'warning'
    | 'success';
  className?: string;
  children?: ReactNode;
}

export const Heading = ({
  type,
  component,
  variant,
  className,
  children,
  ...props
}: Props) => {
  const componentType = component || type;

  const classes = [styles.heading, styles[type]];
  if (variant) classes.push(styles[variant]);
  if (className) classes.push(className);

  return React.createElement<any>(
    componentType,
    {
      className: classes.join(' ') || '',
      ...props,
    },
    children
  );
};

export default Heading;
