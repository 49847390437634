import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  useClick,
  useDismiss,
  useRole,
  useInteractions,
  arrow,
} from '@floating-ui/react';
import { useMemo, useRef, useState } from 'react';
import { PopoverPlacement } from '../..';

interface UsePopoverConfig {
  placement: PopoverPlacement;
  controlledIsOpen?: boolean;
  closeOnBlur: boolean;
  onClose?: () => any;
}

export function usePopover({
  placement,
  closeOnBlur,
  controlledIsOpen,
  onClose,
}: UsePopoverConfig) {
  const arrowRef = useRef(null);
  const [isOpen, setIsOpen] = useState(controlledIsOpen);
  const open = controlledIsOpen ?? isOpen;
  const blurEventsToPrevent = ['focusin', 'pointerdown'];

  const data = useFloating({
    placement,
    open,
    onOpenChange: (isOpen, event) => {
      // Leave popover open if user focuses out or clicks elsewhere on the page
      if (!closeOnBlur && blurEventsToPrevent.includes(event?.type || '')) {
        return;
      }

      setIsOpen(isOpen);

      if (onClose && !isOpen) {
        onClose();
      }
    },
    // ensure the floating element remains anchored to the reference element when scrolling and resizing the screen
    whileElementsMounted: autoUpdate,
    middleware: [
      // Distance popover content is from trigger
      offset(10),
      flip({
        // Place popover content below if there is no room left/right/top
        fallbackAxisSideDirection: 'end',
        padding: 5,
      }),
      // Stops popover content from hitting edge of screen, instead leave a small gap
      shift({ padding: 5 }),
      // Needs to after shift, otherwise arrow won't appear correctly
      arrow({
        element: arrowRef,
      }),
    ],
  });

  const context = data.context;
  const click = useClick(context);
  const dismiss = useDismiss(context, {
    escapeKey: false,
    // Disable close when user clicks outside of popover if it's manually controlled
    outsidePress: !controlledIsOpen,
  });
  const role = useRole(context);

  const interactions = useInteractions([click, dismiss, role]);

  return useMemo(
    () => ({
      open,
      setIsOpen,
      arrowRef,
      ...interactions,
      ...data,
    }),
    [open, setIsOpen, interactions, data]
  );
}
