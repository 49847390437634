import { useCallback, useMemo } from 'react';
import { SetVariablesFn } from './useCollectionQuery';
import { Maybe } from 'util/types';

export interface SortableVariables {
  sort?: Maybe<string>;
  sortDirection?: Maybe<string>;
}

type SortKeys = 'sort' | 'sortDirection';
type IncludeSortKeys<K> = K extends SortKeys ? K : never;
type SortVariables<TVariables> = {
  [K in IncludeSortKeys<keyof TVariables>]: TVariables[K];
};
export type SetSortFn<T extends SortableVariables> = (
  sort: T['sort'],
  direction: T['sortDirection']
) => void;

function getSort<TVariables extends SortableVariables>(
  variables: TVariables
): SortVariables<TVariables> | null {
  if (!variables.sort || !variables.sortDirection) {
    return null;
  }
  return {
    sort: variables.sort,
    sortDirection: variables.sortDirection,
  } as SortVariables<TVariables>;
}

type UseSortResult<T extends SortableVariables> = [
  SortVariables<T> | null,
  SetSortFn<SortVariables<T>>
];

function useSortVariables<TVariables extends SortableVariables>(
  variables: TVariables,
  setVariables: SetVariablesFn<TVariables>
): UseSortResult<TVariables> {
  const sort = useMemo(() => getSort(variables), [variables]);

  const setSort = useCallback<SetSortFn<SortVariables<TVariables>>>(
    (sort, sortDirection) => {
      // Technically the following object literal depends on the actual type of
      // TVariables; we know setVariables will always expect a partial object
      // confirming to SortableVariables so we'll just assert that

      setVariables({
        sort,
        sortDirection,
      } as Partial<TVariables>);
    },
    [setVariables]
  );

  return [sort, setSort];
}

export default useSortVariables;
