import React from 'react';
import styles from './Documents.module.css';

import { Document as DocumentProps } from 'types/graphql';

import { DangerButton, TertiaryButton } from 'system/base/Button';

interface SuperDocumentProps extends DocumentProps {
  deleteDocument: (id: string) => void;
  markDocumentAsPrimary: (id: string) => void;
}

export function Document({
  id,
  url,
  fileName,
  primaryDocument,
  deleteDocument,
  markDocumentAsPrimary,
}: SuperDocumentProps) {
  const makePrimary = () => {
    markDocumentAsPrimary(id);
  };
  const removeDocument = () => {
    if (confirm('Are you sure you want to delete this document?')) {
      deleteDocument(id);
    }
  };

  return (
    <li className={styles.documentContainer}>
      <div>
        <a href={url} target="_blank" rel="noreferrer" className={styles.link}>
          {fileName}
        </a>
      </div>
      <div className={styles.actions}>
        <div>
          {primaryDocument ? (
            <span>
              <strong>Primary PDF</strong>
            </span>
          ) : (
            <TertiaryButton onClick={makePrimary}>Make primary</TertiaryButton>
          )}
        </div>
        <div className={styles.delete}>
          <DangerButton onClick={removeDocument}>Delete</DangerButton>
        </div>
      </div>
    </li>
  );
}
