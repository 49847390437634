import React, { RefCallback, TextareaHTMLAttributes } from 'react';
import styles from './TextArea.module.scss';

interface Props extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  type?: 'neutral' | 'success' | 'danger' | 'warning' | 'primary';
  variant?: 'outline';
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  disabled?: boolean;
  isError?: boolean;
  children?: React.ReactNode;
}

const TextArea = (
  {
    type = 'primary',
    variant = 'outline',
    size,
    isError,
    className,
    children,
    ...props
  }: Props,
  ref: React.ForwardedRef<HTMLTextAreaElement>
) => {
  const classes = [styles.TextArea, styles[type], styles[variant]];
  if (size) classes.push(styles[size]);
  if (isError) classes.push(styles.isError);
  if (className) classes.push(className);

  return (
    <textarea
      className={classes.join(' ')}
      ref={ref as React.RefObject<HTMLTextAreaElement>}
      {...props}
    >
      {children}
    </textarea>
  );
};

const forwardedInput = React.forwardRef<any, Props>(TextArea) as React.FC<
  Props & { ref?: React.RefObject<any> | RefCallback<any> }
>;

export default forwardedInput;
export { forwardedInput as TextArea };
