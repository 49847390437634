import React from 'react';

interface Props {
  children: JSX.Element | JSX.Element[] | string;
  className?: string;
  isOpen?: boolean;
}

const AccordionContent = ({
  children,
  className = '',
  isOpen = false,
}: Props) => {
  return isOpen ? <div className={className}>{children}</div> : <></>;
};

export default AccordionContent;
