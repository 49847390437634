/* A Flex container which maintains minimum space/margin between its children */
import React, { ComponentProps, FunctionComponent } from 'react';
import { margin, SpaceProps } from 'styled-system';
import styled from '../styled';
import StyledChildren from './StyledChildren';
import negativeMargin from './negativeMargin';
import Box from 'system/base/Box';
import Flex, { isDirectionVertical } from 'system/base/Flex';

interface Props extends ComponentProps<typeof Flex> {
  space?: ComponentProps<typeof Box>['marginX']; // or marginY
}

type MarginProps = Pick<SpaceProps, 'marginX' | 'marginY'>;
const NegativeMarginFlex = styled(Flex)<MarginProps>(negativeMargin);
const SpacedChildren = styled(StyledChildren)<MarginProps>(margin);

// Element to expand to the rest of the space
export const FlexSpacer = styled('hr')({
  margin: '0px !important',
  visibility: 'hidden',
  flexGrow: 2,
});

const SpacedFlex: FunctionComponent<Props> = ({
  children,
  space,
  justifyContent = 'space-between',
  flexDirection,
  ...props
}) => {
  const boxProps = isDirectionVertical(flexDirection)
    ? {
        marginY: space,
      }
    : { marginX: space };

  return (
    <NegativeMarginFlex
      justifyContent={justifyContent}
      flexDirection={flexDirection}
      {...boxProps}
      {...props}
    >
      <SpacedChildren {...boxProps}>{children}</SpacedChildren>
    </NegativeMarginFlex>
  );
};

SpacedFlex.defaultProps = {
  space: 2,
};

export default SpacedFlex;
