import * as React from 'react';
import { Placement } from '@floating-ui/react';
import { useTooltip } from './hooks/useTooltip/useTooltip';

type ContextType = ReturnType<typeof useTooltip> | null;

const TooltipContext = React.createContext<ContextType>(null);

export const useTooltipContext = () => {
  const context = React.useContext(TooltipContext);

  if (context == null) {
    throw new Error('Tooltip components must be wrapped in <Tooltip />');
  }

  return context;
};

export interface TooltipOptions {
  hasArrow?: boolean;
  placement?: Placement;
  open?: boolean;
  setControlledOpen?: (open: boolean) => void;
}

export function Tooltip({
  children,
  ...options
}: { children: React.ReactNode } & TooltipOptions) {
  // This can accept any props as options, e.g. `placement`,
  // or other positioning options.
  const tooltip = useTooltip(options);
  return (
    <TooltipContext.Provider value={tooltip}>
      {children}
    </TooltipContext.Provider>
  );
}
