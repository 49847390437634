import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getReviewerFeatures } from 'query/reviewer';

export function useReviewerFeatures(onError?: (error: any) => any) {
  const { isError, isLoading, error, data } = useQuery({
    queryKey: ['reviewerFeatures'],
    queryFn: getReviewerFeatures,
  });

  const hasFeature = (feature: string) => {
    const features = data?.features || [];
    return features.includes(feature);
  };

  useEffect(() => {
    if (onError && isError) {
      onError(error);
    }
  }, [isError, error, onError]);

  return {
    hasFeature,
    isLoading,
    isError,
    error,
  };
}
