import React from 'react';
import styles from '../Menu.module.scss';

interface Props {
  className?: string;
  children: React.ReactNode;
}

const MenuGroup = ({ className, children }: Props) => {
  const classes = [styles.MenuGroup];
  if (className) classes.push(className);

  return (
    <ul role="group" className={classes.join(' ')}>
      {children}
    </ul>
  );
};

export default MenuGroup;
