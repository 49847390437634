import React from 'react';
import styles from './Heading.module.css';
import { HeadingBlock } from 'concepts/Extraction/Blocks/Heading/HeadingBlock';

export type HeadingProps = {
  block: HeadingBlock;
};

export default function Heading(props: HeadingProps): JSX.Element {
  return (
    <h1 aria-level={1} className={styles.Heading}>
      {props.block.label}
    </h1>
  );
}
