import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Box, Grid, Input } from 'theme-ui';

import { PrimaryButton, DangerButton } from 'system/base/Button';

const propTypes = {
  currentItems: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.shape({
        isDeleteable: PropTypes.bool,
      }).isRequired,
    ])
  ),
  multiline: PropTypes.bool,
  newItemLabel: PropTypes.string,
  onAddItem: PropTypes.func,
  onDeleteItem: PropTypes.func,
  renderItem: PropTypes.func,
};

const defaultProps = {
  currentItems: [],
  multiline: false,
  newItemLabel: 'New item',
  onAddItem: null,
  onDeleteItem: null,
  renderItem: (item) => item.toString(),
};

class DataList extends Component {
  constructor() {
    super();
    this.state = {
      newItem: '',
      submitting: false,
    };
    this.handleDeleteItem = this.handleDeleteItem.bind(this);
    this.handleAddItem = this.handleAddItem.bind(this);
    this.handleNewItemChange = this.handleNewItemChange.bind(this);
  }

  handleNewItemChange(event) {
    this.setState({
      newItem: event.target.value,
    });
  }

  handleAddItem(e) {
    if (e) {
      e.preventDefault();
    }

    const cb = () => {
      this.setState({
        submitting: false,
        newItem: '',
      });
    };

    this.setState(
      {
        submitting: true,
      },
      () => {
        if (this.canAddItem()) {
          this.props.onAddItem(this.state.newItem, cb);
        } else {
          cb();
        }
      }
    );

    return false;
  }

  handleDeleteItem(item) {
    return (e) => {
      if (e) {
        e.preventDefault();
      }

      const cb = () => {
        this.setState({ submitting: false });
      };

      this.setState(
        {
          submitting: true,
        },
        () => {
          if (this.canDeleteItem(item)) {
            this.props.onDeleteItem(item, cb);
          } else {
            cb();
          }
        }
      );

      return false;
    };
  }

  canAddItem() {
    return typeof this.props.onAddItem === 'function';
  }

  canDelete() {
    return typeof this.props.onDeleteItem === 'function';
  }

  canDeleteItem(item) {
    return (
      this.canDelete() &&
      (typeof item.isDeleteable !== 'boolean' || item.isDeleteable)
    );
  }

  renderInput() {
    const { multiline, newItemLabel } = this.props;
    const { submitting } = this.state;

    if (multiline) {
      return (
        <textarea
          className="form-control lh-copy"
          disabled={submitting}
          name="value"
          onChange={this.handleNewItemChange}
          placeholder={newItemLabel}
          rows="3"
          title={newItemLabel}
          value={this.state.newItem}
        />
      );
    }

    return (
      <Input
        width="100%"
        disabled={submitting}
        name="value"
        onChange={this.handleNewItemChange}
        placeholder={newItemLabel}
        title={newItemLabel}
        type="text"
        value={this.state.newItem}
      />
    );
  }

  render() {
    const { currentItems, renderItem } = this.props;

    const { newItem, submitting } = this.state;

    return (
      <Box>
        <ul>
          {this.canAddItem() && (
            <li style={{ width: '100%' }}>
              <form>
                <Grid columns="3fr 1fr" sx={{ width: '100%' }}>
                  <div>{this.renderInput()}</div>

                  <div>
                    <PrimaryButton
                      disabled={submitting || (newItem || '').length === 0}
                      iconName="plus"
                      onClick={this.handleAddItem}
                    >
                      Add
                    </PrimaryButton>
                  </div>
                </Grid>
              </form>
            </li>
          )}

          <Box mt={3}>
            {currentItems.map((item, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <li key={i} style={{ width: '100%' }}>
                <form>
                  <Grid columns="3fr 1fr" py={1} sx={{ width: '100%' }}>
                    <div>{renderItem(item)}</div>
                    <div>
                      {this.canDelete() ? (
                        <DangerButton
                          disabled={!this.canDeleteItem(item) || submitting}
                          onClick={this.handleDeleteItem(item)}
                          iconName="trash"
                          iconOnly
                        >
                          Delete
                        </DangerButton>
                      ) : null}
                    </div>
                  </Grid>
                </form>
              </li>
            ))}
          </Box>
        </ul>
      </Box>
    );
  }
}

DataList.propTypes = propTypes;

DataList.defaultProps = defaultProps;

export default DataList;
