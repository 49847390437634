import React from 'react';
import { gql, useMutation } from '@apollo/client';
import { PrimaryButton } from 'system/base/Button';
import { EditNoteMutation } from 'types/graphql';

export const EDIT_NOTE_MUTATION = gql`
  mutation EditNote($input: EditNoteInput!) {
    editNote(input: $input) {
      success
      errors {
        message
      }
    }
  }
`;

export interface EditNoteControlProps {
  id: string;
  content: string;
  onSuccess: () => void;
  onFailure: () => void;
}

export default function EditNoteButton({
  id,
  content,
  onSuccess,
  onFailure,
}: EditNoteControlProps): JSX.Element {
  const [performEditNote] = useMutation<EditNoteMutation>(EDIT_NOTE_MUTATION);

  return (
    <PrimaryButton
      data-testid="save-edit-note"
      onClick={() => {
        performEditNote({
          variables: {
            input: {
              noteId: id,
              noteContent: content,
            },
          },
        })
          .then(onSuccess)
          .catch((e) => {
            MetaError.notify(e);
            onFailure;
          });
      }}
    >
      Save
    </PrimaryButton>
  );
}
