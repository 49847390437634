import React from 'react';
import triageStyles from 'containers/Guidelines/Triage/Triage.module.css';

export interface ImportedAssignmentProps {
  id: number;
  reviewTitle: string;
  reviewUrl: string;
  studyCategoryName: string;
  createdAgo: string;
}

export function ImportedAssignment({
  id,
  reviewTitle,
  reviewUrl,
  studyCategoryName,
  createdAgo,
}: ImportedAssignmentProps): JSX.Element {
  return (
    <div
      data-testid={`imported-question-assignment-${id}`}
      className={triageStyles.importedAssignment}
    >
      <article>
        <div style={{ marginBottom: '10px' }}>{createdAgo} ago</div>
        <div>
          <i>{studyCategoryName}</i> in
          <div>
            <i>
              <a href={reviewUrl}>{reviewTitle}</a>
            </i>
          </div>
        </div>
      </article>
    </div>
  );
}
