import React from 'react';
import styles from './PageHeader.module.scss';

const PageHeader = ({
  backLink,
  headerText,
  rightContent,
}: {
  backLink: string;
  headerText: string;
  rightContent?: () => JSX.Element;
}) => {
  return (
    <div className={styles.pageHeader}>
      <div className={styles.container}>
        <div className={styles.leftSection}>
          <a href={backLink}>
            <i
              aria-label="Navigate back"
              className={`fas fa-arrow-left ${styles.backLink}`}
            ></i>
          </a>
          <h2 className={styles.header}>{headerText}</h2>
        </div>
        <div className={styles.rightLink}>
          {rightContent ? rightContent() : null}
        </div>
      </div>
    </div>
  );
};

export default PageHeader;
