import React, { Fragment } from 'react';
import styles from './DocumentLinks.module.css';
import Icon, { IconStyle } from 'system/base/Icon';
import { Link } from 'system/base/Link';
import { Document } from 'types/graphql';

export interface DocumentLinksProps {
  documents: Array<Document>;
}

function renderDocumentLink(document: Document) {
  return (
    <div key={document.id} data-testid={`document-${document.id}`}>
      <Icon
        className={styles.icon}
        iconStyle={IconStyle.Regular}
        name="paperclip"
      />
      <Link href={document.url}>
        {document.fileName || `Document: ${document.id}`}
      </Link>
    </div>
  );
}

export function DocumentLinks({ documents }: DocumentLinksProps) {
  return (
    <Fragment>
      {documents.map((document) => {
        if (document) {
          return renderDocumentLink(document);
        }
      })}
    </Fragment>
  );
}

export default DocumentLinks;
