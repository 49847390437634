import React, { useEffect, useState } from 'react';
import { CustomImportSource as CustomImportSourceType } from 'types/CitationImport';
import CitationImportSourceDropDown from '../components/CitationImportSourceDropDown';
import ReviewCitationSourcesModal from '../components/ReviewCitationSourcesModal';
import styles from './CitationImportSource.module.scss';
import { Link, Text } from 'components/core';
import { getReviewImportSources } from 'query/review';

export const GetReviewImportSourcesErrorMessage =
  'Unable to load sources, please try again later';

interface Props {
  name: string;
}

const CitationImportSource = ({ name }: Props) => {
  const [isOpenSourceModal, setIsOpenSourceModal] = useState(false);
  const [reviewImportSources, setReviewImportSources] = useState<string[]>([]);
  const [getSourcesError, setGetSourcesError] = useState<string>();
  const [customReviewImportSources, setCustomReviewImportSources] = useState<
    CustomImportSourceType[]
  >([]);

  useEffect(() => {
    getReviewImportSources()
      .then(({ reviewImportSources, customReviewImportSources }) => {
        setReviewImportSources(reviewImportSources);
        setCustomReviewImportSources(customReviewImportSources);
      })
      .catch(() => {
        setGetSourcesError(GetReviewImportSourcesErrorMessage);
      });
  }, []);

  return (
    <>
      <CitationImportSourceDropDown
        name={name}
        options={reviewImportSources}
        error={getSourcesError}
      />
      <div className={styles.CitationManageSourcesText}>
        <Text component="span" variant="weak">
          Cannot find the source you need?{' '}
        </Text>
        <Link
          variant="primary"
          component="button"
          htmlType="button"
          onClick={() => setIsOpenSourceModal(true)}
        >
          Manage sources
        </Link>
      </div>

      {isOpenSourceModal && (
        <ReviewCitationSourcesModal
          isOpen={isOpenSourceModal}
          setIsOpen={setIsOpenSourceModal}
          reviewImportSources={reviewImportSources}
          setReviewImportSources={setReviewImportSources}
          customReviewImportSources={customReviewImportSources}
          setCustomReviewImportSources={setCustomReviewImportSources}
        />
      )}
    </>
  );
};

export default CitationImportSource;
