import React, { FunctionComponent, ReactNode } from 'react';
import Flex from 'system/base/Flex';
import SpacedFlex, { FlexSpacer } from 'system/utility/SpacedFlex';

interface Props {
  filters?: ReactNode;
  actions?: ReactNode;
}

const DataWithControls: FunctionComponent<Props> = ({
  filters,
  actions,
  children,
}) => (
  <Flex flexDirection="column">
    <SpacedFlex flexDirection="row" alignItems="flex-start" mb={3}>
      {filters}
      {filters && actions && <FlexSpacer />}
      {actions}
    </SpacedFlex>
    {children}
  </Flex>
);

export default DataWithControls;
