import { createContext, useContext } from 'react';
import { AccordionEvent, AccordionSubscriber } from './index';

export class AccordionEventHandler {
  subscribers: AccordionSubscriber[];
  allowMultiple: boolean;
  isAlwaysOpen: boolean;
  openCount: number;
  locked: boolean;

  constructor(allowMultiple = true, isAlwaysOpen = true) {
    this.allowMultiple = allowMultiple;
    this.isAlwaysOpen = isAlwaysOpen;
    this.subscribers = [];
    this.openCount = 0;
    this.locked = false;
  }

  subscribe(
    elem: any,
    reducer: (type: symbol, value?: boolean) => boolean
  ): void {
    this.subscribers.push({ element: elem, reducer });
  }

  unsubscribe(elem: any): void {
    this.subscribers = this.subscribers.filter(
      ({ element }) => element != elem
    );
  }

  lock(): void {
    this.locked = true;
  }

  unlock(): void {
    this.locked = false;
  }

  dispatch(type: symbol, value?: boolean): boolean {
    if (this.locked) return false;

    if (type === AccordionEvent.OPEN) {
      if (!this.allowMultiple) {
        this.dispatch(AccordionEvent.SET_OPEN, false);
        this.openCount = 1;
      } else {
        this.openCount += 1;
      }
    }

    if (type === AccordionEvent.CLOSE) {
      if (this.isAlwaysOpen && this.openCount === 1) {
        return false;
      } else {
        this.openCount -= 1;
      }
    }

    return this.subscribers.reduce(
      (val: boolean, { reducer }: AccordionSubscriber) =>
        val && reducer(type, value),
      true
    );
  }

  checkOpen(): void {
    this.openCount = this.subscribers.reduce((val, subscriber) => {
      if (subscriber.reducer(AccordionEvent.IS_OPEN)) val++;
      return val;
    }, 0);

    if (this.openCount === 0 && this.isAlwaysOpen) {
      this.subscribers[0]?.reducer(AccordionEvent.SET_OPEN, true, true);
      this.openCount = 1;
    }
  }
}

export const AccordionEventContext = createContext<
  AccordionEventHandler | undefined
>(undefined);

export const useAccordionController = (): AccordionEventHandler => {
  const controller = useContext(AccordionEventContext);
  if (!controller)
    throw new Error(
      'useAccordionController called outside of an Accordion context'
    );
  return controller;
};
