import React from 'react';
import { Box, Flex } from 'theme-ui';
import { ThemeUIStyleObject } from 'theme-ui';
import { Comment } from '../types';

type CommentInfoProps = Comment & { sx?: ThemeUIStyleObject };

const CommentInfo = ({
  comment,
  commenter,
  sx,
}: CommentInfoProps): JSX.Element => (
  <Box sx={sx}>
    {!!commenter && (
      <Box
        sx={{
          display: 'block',
          fontWeight: 'semibold',
          fontSize: 0,
          textTransform: 'uppercase',
        }}
      >
        {commenter}
      </Box>
    )}
    <Box
      sx={{
        color: 'greyDarker',
      }}
    >
      {comment}
    </Box>
  </Box>
);

type CommentPopoverDialogProps = React.ComponentPropsWithoutRef<'div'> & {
  comments: Comment[];
};

export const CommentPopoverDialog = ({
  comments,
}: CommentPopoverDialogProps): JSX.Element => {
  const isOnlyComment = comments.length === 1;

  const getMultiCommentStyle = (isFirstComment: boolean): ThemeUIStyleObject =>
    isFirstComment
      ? {
          paddingRight: 2,
        }
      : {
          borderLeft: 'panel',
          paddingLeft: 2,
        };

  return (
    <Flex sx={{ textAlign: 'left' }}>
      {comments.map(({ comment, commenter }, index) => (
        <CommentInfo
          key={index}
          comment={comment}
          commenter={commenter}
          sx={
            isOnlyComment
              ? undefined
              : {
                  minWidth: `calc(100%/${comments.length})`,
                  ...getMultiCommentStyle(index === 0),
                }
          }
        />
      ))}
    </Flex>
  );
};
