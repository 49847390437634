import React from 'react';
import {
  BlockRenderer,
  ControlProps,
  EditorProps,
  ComparisonRowProps,
} from './BlockRenderer';
import { UnknownBlockRenderer } from './UnknownBlockRenderer';
import { blockIsHeading } from './Heading/HeadingBlock';
import { HeadingBlockRenderer } from './Heading/HeadingRender';
import { SubheadingBlockRenderer } from './Subheading/SubheadingRender';
import { blockIsTable } from './Table/TableBlock';
import { TableBlockRenderer } from './Table/TableRender';
import { blockIsSubheading } from './Subheading/SubheadingBlock';
import { blockIsTable2 } from './Table2/utils/block';
import { Table2BlockRenderer } from './Table2/TableBlockRenderer';
import { TemplateBlock } from 'concepts/Extraction/Blocks/BaseBlock';
import { TextFieldBlockRenderer } from 'concepts/Extraction/Blocks/TextField/TextFieldRender';
import { blockIsTextField } from 'concepts/Extraction/Blocks/TextField/TextFieldBlock';
import { blockIsMultipleChoice } from 'concepts/Extraction/Blocks/MultipleChoice/MultipleChoiceBlock';
import { MultipleChoiceBlockRenderer } from 'concepts/Extraction/Blocks/MultipleChoice/MultipleChoiceRender';
import { blockIsCheckboxes } from 'concepts/Extraction/Blocks/Checkboxes/CheckboxesBlock';
import { CheckboxesBlockRenderer } from 'concepts/Extraction/Blocks/Checkboxes/CheckboxesRender';
import { blockIsDomain } from 'concepts/Extraction/Blocks/Domain/DomainBlock';
import { DomainBlockRenderer } from 'concepts/Extraction/Blocks/Domain/DomainRender';

export function Factory(block: TemplateBlock): BlockRenderer {
  if (blockIsTextField(block)) {
    return TextFieldBlockRenderer;
  }
  if (blockIsHeading(block)) {
    return HeadingBlockRenderer;
  }
  if (blockIsSubheading(block)) {
    return SubheadingBlockRenderer;
  }
  if (blockIsMultipleChoice(block)) {
    return MultipleChoiceBlockRenderer;
  }
  if (blockIsCheckboxes(block)) {
    return CheckboxesBlockRenderer;
  }
  if (blockIsDomain(block)) {
    return DomainBlockRenderer;
  }
  if (blockIsTable(block)) {
    return TableBlockRenderer;
  }
  if (blockIsTable2(block)) {
    return Table2BlockRenderer;
  }
  return UnknownBlockRenderer;
}

export function BlockControl(props: ControlProps): JSX.Element {
  const { Control } = Factory(props.block);
  return <Control {...props} />;
}

export function BlockEditor(props: EditorProps): JSX.Element {
  const { Editor } = Factory(props.block);
  return <Editor {...props} />;
}

export function ComparisonRow(props: ComparisonRowProps): JSX.Element {
  const { ComparisonRow } = Factory(props.block);
  return <ComparisonRow {...props} />;
}
