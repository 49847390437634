import { useState, useCallback, useMemo } from 'react';
import { isLocalStorageAvailable } from '../util/isLocalStorageAvailable';

// we only really need to calculate this once per pageload
const persist = isLocalStorageAvailable();

function usePersistedState<T>(persistedKey: string, defaultValue: T) {
  const persistedValue = useMemo(() => {
    const value = window.localStorage.getItem(persistedKey);
    return value !== null ? JSON.parse(value) : null;
  }, [persistedKey]);

  const [state, setState] = useState<T>(
    persistedValue === null ? defaultValue : persistedValue
  );

  const setValue = useCallback(
    (value: T) => {
      if (persist) {
        window.localStorage.setItem(persistedKey, JSON.stringify(value));
      }
      setState(value);
    },
    [persistedKey]
  );

  return [state, setValue] as const;
}

export default usePersistedState;
