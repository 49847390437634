import { Accessor, Column } from 'react-table';
import { Header, RowData } from '../types';
import { Cell } from '../Cell/Cell';

// getReactTableRowLabelColumn creates a react-table column object for the row
// labels.
export const getReactTableRowLabelColumn = (
  rowHeaders: Header[]
): Column<RowData> => {
  const accessor: Accessor<RowData> = ({ rowID }) => {
    const rowHeader = rowHeaders.find((row) => row.id === rowID);
    return rowHeader?.label;
  };

  return {
    Header: '', // Blank column title cell
    accessor, // Render column cells based on row props
    id: 'rowLabel',
    /**
     * @remarks
     * Cell is specified because otherwise EditableCell will be used as default
     * in DE forms. We don't want the row label cells to be editable.
     */
    Cell,
  };
};
