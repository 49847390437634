import React from 'react';
import classNames from 'classnames';
import { Box, BoxProps } from 'theme-ui';
import styles from './Badge.module.css';

type BadgeVariants = 'Primary' | 'Muted' | 'Success' | 'Warn';
interface BadgeProps extends BoxProps {
  children: number | string;
  size?: 'medium' | 'large';
  appendText?: string;
  className?: string;
  variant?: BadgeVariants;
}

export const Badge = ({
  children,
  size = 'large',
  className,
  appendText,
  variant = 'Primary',
  ...rest
}: BadgeProps): JSX.Element => (
  <Box {...rest}>
    <span
      className={classNames(
        styles.badge,
        size === 'large' ? styles['badge--large'] : styles['badge--medium'],
        styles[`badge--${variant.toLowerCase()}`],
        className
      )}
    >
      {typeof children === 'number' ? children.toString() : children}
    </span>
    {appendText}
  </Box>
);
