import * as R from 'ramda';
import { TemplateBlock } from '../BaseBlock';
import { generateBlockID } from '../generateBlockID';
import { JudgementBlock } from './JudgementBlock';
import { BlockRecord, BlockValue } from 'concepts/Extraction/BlocksData';

export type DomainBlockJudgements = JudgementBlock;

export interface DomainBlock extends TemplateBlock {
  type: 'Domain';
  description: string;
  judgements: Array<DomainBlockJudgements>;
}

export function blockIsDomain(block: TemplateBlock): block is DomainBlock {
  return block.type === 'Domain';
}

export function newDomainBlock(
  label: string,
  extraProps?: Partial<DomainBlock>
): DomainBlock {
  return {
    type: 'Domain',
    id: generateBlockID(),
    label,
    description: '',
    judgements: [],
    ...extraProps,
  };
}

export function updateLabel(domain: DomainBlock, label: string) {
  return { ...domain, label };
}

export function updateDescription(domain: DomainBlock, description: string) {
  return { ...domain, description };
}

export function addJudgement(
  domain: DomainBlock,
  judgement: DomainBlockJudgements,
  afterID: string | null
) {
  domain = R.clone(domain);
  if (afterID === null) {
    domain.judgements.unshift(judgement);
  } else {
    const index = domain.judgements.findIndex(
      (judgement) => judgement.id === afterID
    );
    if (index === -1) {
      throw new Error(`Cannot add judgement, invalid judgement id: ${afterID}`);
    }
    domain.judgements.splice(index + 1, 0, judgement);
  }
  return domain;
}

export function removeJudgement(domain: DomainBlock, id: string): DomainBlock {
  domain = R.clone(domain);
  const index = domain.judgements.findIndex((judgement) => judgement.id === id);

  if (index === -1) {
    throw new Error(`Cannot remove judgement, invalid judgement id: ${id}`);
  }

  domain.judgements.splice(index, 1);
  return domain;
}

export function moveJudgement(
  domain: DomainBlock,
  id: string,
  toIndex: number
): DomainBlock {
  const movedIndex = domain.judgements.findIndex(
    (judgement) => judgement.id === id
  );
  if (movedIndex === -1) {
    throw new Error(`Cannot move judgement, invalid judgement id: ${id}`);
  }

  const [moved] = domain.judgements.splice(movedIndex, 1);

  if (toIndex < 0 || toIndex >= domain.judgements.length) {
    throw new Error(`Cannot move judgement, invalid index: ${toIndex}`);
  }

  domain.judgements.splice(toIndex, 0, moved);
  return domain;
}

export function updateJudgement(
  domain: DomainBlock,
  newJudgement: DomainBlockJudgements
): DomainBlock {
  domain = R.clone(domain);
  const index = domain.judgements.findIndex(
    (judgement) => judgement.id === newJudgement.id
  );
  if (index === -1) {
    throw new Error(
      `Cannot update judgement, invalid judgement id: ${newJudgement.id}`
    );
  }
  domain.judgements.splice(index, 1, newJudgement);
  return domain;
}

export function readDomainSelectedValue(
  record: BlockRecord<BlockValue>
): string | null {
  return typeof record?.value === 'string' ? record.value : null;
}
