import {
  NonRetriableError,
  OfflineError,
  withRequestRetry,
  DEFAULT_REQUEST_TIMEOUT,
} from './DataExtraction';
import {
  INTERVENTION_CHARACTERISTIC_RESULTS_ROUTE,
  STUDY_ARM_ROUTE,
  tokenizeRoute,
} from 'query/routes';
import { getStudyId } from 'query/study/Study';
import { InterventionCharacteristicsResult } from 'types';

export const updateInterventionCharacteristicResult = async (
  intervention_characteristic_id: string,
  result: InterventionCharacteristicsResult,
  onError?: (error: Error) => Error
) => {
  return withRequestRetry(
    () =>
      _updateInterventionCharacteristicResultRequest(
        intervention_characteristic_id,
        result
      ),
    onError
  );
};

const _updateInterventionCharacteristicResultRequest = async (
  intervention_characteristic_id: string,
  result: InterventionCharacteristicsResult
) => {
  if (!navigator.onLine)
    throw new OfflineError('No network connection detected');

  const url = tokenizeRoute(INTERVENTION_CHARACTERISTIC_RESULTS_ROUTE, {
    review_study_id: getStudyId(),
    intervention_characteristic_id,
  });

  return fetch(url.toString(), {
    method: 'PUT',
    body: JSON.stringify(result),
    headers: { 'Content-Type': 'application/json' },
    signal: (AbortSignal as any).timeout(DEFAULT_REQUEST_TIMEOUT),
  }).then((response) => {
    if (response.status !== 200)
      throw new NonRetriableError(String(response.status));

    return response.json();
  });
};

export const updateInterventionGroup = (
  arm_id: string,
  intervention_group_name: string,
  onError?: (error: Error) => Error
) => {
  return withRequestRetry(
    () => _updateInterventionGroup(arm_id, intervention_group_name),
    onError
  );
};

const _updateInterventionGroup = async (
  arm_id: string,
  intervention_group_name: string
) => {
  const url = tokenizeRoute(STUDY_ARM_ROUTE, {
    arm_id: arm_id,
  });

  return fetch(url.toString(), {
    method: 'PUT',
    body: JSON.stringify({ intervention_group: intervention_group_name }),
    headers: { 'Content-Type': 'application/json' },
    signal: (AbortSignal as any).timeout(DEFAULT_REQUEST_TIMEOUT),
  }).then((response) => {
    if (response.status !== 200)
      throw new NonRetriableError(String(response.status));

    return response.json();
  });
};
